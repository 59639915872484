export default {
  dashboard: 'Bord',
  language: 'Limba',
  layoutBuilder: 'Constructor de layout',
  craft: 'Fabricat',
  pages: 'Pagini',
  profile: 'Profil',
  projects: 'Proiecte',
  campaigns: 'Campanii',
  documents: 'Documente',
  connections: 'Conexiuni',
  wizards: 'Wizards',
  horizontal: 'orizontal',
  vertical: 'vertical',
  account: 'cont',
  overview: 'prezentare generala',
  overviews: 'prezentari generale',
  settings: 'Setari',
  authentication: 'Autentificare',
  basicFlow: 'flux de baza',
  signIn: 'conectare',
  signUp: 'inscriere',
  passwordReset: 'resetare de parola',
  error: 'Eroare',
  warning: 'avertizare',
  error404: 'Eroare 404',
  error500: 'Eroare 500',
  apps: 'Aplicatii',
  chat: 'conversatie',
  privateChat: 'conversatie privata',
  groupChat: 'conversatie privata in grup',
  drawerChat: 'conversatie de grup,conversatie sertar',
  widgets: 'Widget-uri',
  widgetsLists: 'Lista',
  widgetsStatistics: 'Statistica',
  widgetsCharts: 'grafice',
  widgetsMixed: 'amestecat',
  widgetsTables: 'mese',
  widgetsFeeds: 'fluxuri',
  changelog: 'jurnal de modificari',
  docsAndComponents: 'Documente si componente',
  megaMenu: 'Mega meniu',
  exampleLink: 'Exemplu de link',
  modals: 'Modale',
  general: 'General',
  inviteFriends: 'Invita prieteni',
  viewUsers: 'vizualizati utilizatorii',
  upgradePlan: 'plan de modernizare ',
  shareAndEarn: 'distribuie si castiga',
  forms: 'Forme',
  newTarget: 'Noua tinta',
  newCard: 'Noul card',
  newAddress: 'Noua adresa',
  createAPIKey: 'Creati cheia API',
  twoFactorAuth: 'autentificare cu doi factori',
  createApp: 'Creati aplicatia',
  createAccount: 'Crearea noului cont',
  activity: 'Activitate',
  documentation: 'Documentatie',
  components: 'Componente',
  resources: 'Resurse',
  customers: 'Clienti',
  gettingStarted: 'notiuni de baza',
  customersListing: 'lista de clienti',
  customerDetails: 'detaliu client',
  calendarApp: 'Calendar aplicatie',
  subscriptions: 'abonati',
  getStarted: 'incepe',
  subscriptionList: 'lista de abonati',
  addSubscription: 'Adaugare abonat',
  viewSubscription: 'vizualizare abonat',
  allUsers: 'toti utilizatorii',
  createUser: 'Crearea unui cont',
  viewUserManagement: 'Managementul utilizatorilor',
  sureQuestionHeadline: 'Esti sigur?',
  cancel: 'Anulare',
  save: 'Salvati',
  saveChanges: 'Salvare',
  saving: 'Salvează ...',
  done: 'terminat',
  continue: 'continua',
  passwordsDoNotMatch: 'Parolele nu se potrivesc',
  passwordChanged: 'Parola schimbata',
  passwordTooShort: 'Parola prea scurta',
  loginWithNewCredentials: 'Acum vă puteți autentifica cu noile acreditări',
  clientVersion: 'Versiunea clientului',
  username: 'Nume de utilizator',
  viewOrgChart: 'Structura organizationala',
  tags: 'etichete',
  newNode: 'Mod nou',
  removeNode: 'Eliminati modul',
  remove: 'Sterge',
  responsiblePeople: 'Persoana responsabila',
  noResponsiblePeople: 'Fara responsabilitate',
  filterOptions: 'Optiuni de filtrare',
  filter: 'Filtru',
  resetFilters: 'Reseteaza filtrele',
  reset: 'Reseteaza',
  edit: 'Editeaza',
  loadingData: 'Incarcare date...',
  administration: 'Administrare',
  viewOPLOverview: 'Vizualizarea masurii',
  pleaseSelect: 'Te rog selecteaza',
  errorAlertCaption: 'S-a inregistrat o eroare',
  errorCaseNotFound: 'Eroarea nu a fost gasita',
  processInterruptionNotFound: 'Intreruperea procesului nu a fost gasita',
  openPointNotFound: 'Masura nu a fost gasit',
  send: 'Trimite',
  release: 'Eliberare',
  delete: 'Sterge',
  none: 'Nimeni',
  direct: 'Direct',
  depth: 'Adancime',
  selected: 'selectat',
  inMinutes: '(in cateva minute)',
  minutes: 'Minute',
  createdAt: 'Creat la',
  actions: 'Actiuni',
  accept: 'Acceptat',
  decline: 'respins',
  noOne: 'Nici unul',
  configuration: 'Configurat',
  deleted: 'Sterge',
  unknown: 'Necunoscut',
  backToStart: 'Inapoi la start',
  structure: 'Structura',
  all: 'Toate',
  xSelected: '{x} selectat',
  savesAutomatically: 'Salvează automat',
  noEntries: 'Fără intrări',
  tabs: {
    fes: 'Cazuri de Eroare',
    opl: 'Puncte Deschise',
    spc: 'SPC',
    cdf: 'CDF',
  },
  cdf: {
    nav: {
      orgChart: 'Organigrama',
    },
    orgUnit: {
      title: 'Numele',
      removeQuestion:
        'Doriți ca nodul {name}, toate conexiunile și nodurile copil să fie eliminate?',
      assignTagsDownwardsQuestion:
        'De asemenea, doriți să atribuiți etichetele {tags} tuturor elementelor de structură subiacente?',
    },
    orgChart: {
      responsibles: {
        singular: 'Persoana responsabila',
        plural: 'Persoane responsabile',
        none: 'Persoana fara responsabilitate',
      },
      tags: {
        singular: 'Eticheta',
        plural: 'Etichete',
        none: 'Nicio eticheta',
      },
      buttons: {
        openAllNodes: 'Deschide toate nodurile',
        closeAllNodes: 'Inchide toate nodurile',
      },
    },
    hierarchyNames: {
      headline: 'Titlu',
    },
  },
  fes: {
    nav: {
      dashboard: 'bord',
      start: 'Eroare',
      startPi: 'Masura intrerupta',
      catalogs: 'Cataloage',
      collection: 'Colectie',
      decisions: 'Colectie de decizii',
      riskrisks: 'Colectie de risc/riscuri',
      causes: 'Cauza colectiei',
      interuptions: 'Colectie intrerupta',
      overview: 'Prezentare generala',
      processInterruptions: 'Intreruperea procesului',
    },
    barcodeModal: {
      enableManualButton: 'Activare intrare manuala',
    },
    form: {
      riskLevel: 'Nivel de risc',
      new: 'Nou caz de eroare',
      errorLocation: 'Localizare eroare',
      barcode: 'Cod de bare',
      barcodes: 'Coduri de bare',
      noBarcodes: 'Fara coduri de bare',
      qrCode: 'QR-Cod',
      addCode: 'Adauga cod de bare',
      errorCase: 'Caz eroare',
      errorCaseDetail: 'Detalierea erorii',
      back: 'inapoi',
      next: 'urmatorul',
      continueWorkFinal: 'Puteti continua munca. Piesele sunt aprobate.',
      continueWork: 'Puteti continua munca.',
      messageAfterWorkerProcess:
        'Eroarea a fost salvata. Va rugam lipiti codul de bare pe partea defecta',
      messageNoBarcode: 'Trebuie adaugat cel putin 1 cod de bare',
      decisionEdits: 'Schimbarea contului: {count}',
      details: {
        heading: 'Informatatii despre partea defecta',
        subtext: 'Va rugam sa adaugati informatii despre partea defceta',
        partName: 'Numele piesei',
        partReference: 'Numarul piesei',
        jobReference: 'Numarul comenzii',
        amounts: 'Cantitate',
        amountTotal: 'Cantitate comanda',
        amountChecked: 'Cantitate verificata',
        amountFlawed: 'Cantitate defecta',
        materialName: 'Numele materialului',
        materialReference: 'Numarul materialului',
        faufReference: 'FA sau FAUF numar',
        faufReferenceShort: 'FA numar',
        customerReference: 'Date client',
        customerOrderReference: 'Date comanda',
        checkReference: 'Verificati referinta',
        sapReference: 'SAP referinta',
        errorCaseNumber: 'Numarul cazului de eroare',
      },
      moreDetails: {
        heading: 'Detalii despre eroare',
        subtext: 'Va rugam adaugati informatii despre eroare',
        category: 'Categoria erorii',
        description: 'Descrierea erorii',
        locationRiskCategory: 'Categoria erorii',
        errorPlace: 'Localizare eroare',
        errorStyle: 'Tipul de eroare',
        riskLevel: 'Nivel de risc',
        productCategory: 'Categoria de produs',
        productCategoryShort: 'Categoria',
        productSubCategory: 'Sub-categoria de produs',
        productSubCategoryShort: 'Sub-categoria',
      },
      processInterruption: {
        new: 'O noua intrerupere de proces',
        self: 'Intrerupere de proces',
        heading: 'Detalii despre intreruperea de proces',
        subtext: 'Va rugam adaugati detalii',
        category: 'Categoria principala',
        addCategory: 'Noua categorie principala',
        addSubCategory: 'Noua sub categorie',
        subCategory: '1. sub categorie',
        subSubCategory: '2. sub categorie',
        reason: 'Motivul intreruperii procesului ',
        none: 'Nici o intrerupere de proces',
        interruptionType: 'Tipul de intrerupere',
        downtimeMachine: ' Intreruperea de masina',
        downtimeWorker: 'Intreruperea operatorului',
        connectedError: 'Caz de eroare conectat',
        connectedErrorDetails: '#{id} cu {style} la {place}',
        interruptionTypes: {
          isMachineBlocked: 'Masina blocata',
          isWorkerBlocked: 'Operator blocat',
        },
      },
      productCategory: {
        addCategory: 'Noua categorie de produs',
        addSubCategory: 'Noua sub-categorie de produs',
      },
      decision: {
        title: 'Masuri si decizi',
        heading: 'Detalii despre decizi',
        subtext: 'Va rugam adaugati detaliile deciziei',
        decider: 'Cine a decis',
        decision: 'Decizie',
        user: 'Utilizator',
        name: 'Nume',
        department: 'Departament',
        phone: 'Telefon',
        mail: 'Adresa de mail',
      },
      causes: {
        heading: 'Detaliile despre cauza',
        subtext: 'Va rugam adaugati detalii despre cauza',
        cause: 'Cauza',
        causeReason: 'Persoana cauzatoare',
      },
      errors: {
        missingBarcode: 'Va rugam sa adaugati cel putin 1 cod de bare',
        duplicateBarcode: 'Codul de bare este deja in lista',
        duplicateBarcodeSaved: 'Acest cod de bare este deja in uz',
        barcodeNotFound: 'Codul de bare nu a fost gasit ',
        missingProductCategory: 'Va rugam selectati o categorie de produse',
        missingProductSubCategory:
          'Va rugam adaugati o sub-categorie de produs',
        missingLocationRiskCategory:
          'Va rugam sa adaugati o categorie de eroare ',
        missingErrorPlace: 'Va rugam sa adaugati localizarea erorii ',
        missingErrorStyle: 'Va rugam sa adaugati tipul erorii',
        missingDescription: 'Va rugam sa adaugati descrierea erorii',
        missingPartName: 'Va rugam sa adaugati denumirea piesei',
        missingPartReference: 'Va rugam adaugati referinta pentru piesa',
        missingJobReference: 'Va rugam adaugati referinta locului de munca',
        missingAmountTotal: 'Va rugam sa adaugati cantitatea totala',
        missingAmountChecked: 'va rugam sa adaugati cantitatea verificata',
        amountCheckedTooHigh:
          'Cantitatea verificata nu poate fi mai mare decat cantitatea totala',
        missingAmountFlawed:
          'Va rugam sa adaugati cantitatea totala a partilor defecte',
        amountFlawedTooHigh:
          'Cantitatea pieselor defecte nu poate fi mai mare decat cantitatea totaala verificata',
        missingDecision: 'Va rugam adaugati o decizie',
        missingCause: 'Va rugam adaugati o cauza',
        missingCauseReason: 'Va rugam sa adaugati persoana cauzatoare ',
        missingMaterialReference: 'Va rugam sa adaugati numarul materialului',
        missingFaufReference: 'Va rugam sa adaugati numarul FA sau FAUF',
        processInterruption: {
          missingCategory:
            'Va rugam adaugati grupul principal pentru intreruperea procesului',
          missingSubCategory:
            'Va rugam adaugati 1. sub-categorie pentru intreruperea procesului',
          missingSubSubCategory:
            'Va rugam adaugati sub-categoria 2. pentru intreruperea procesului',
          missingReason:
            'Va rugam adaugati un motiv pentru intreruperea procesului',
          missingDonwtimeMachine:
            'Va rugam adaugati timpul de intrerupere pentru masina',
          missingDowntimeWorker:
            'Va rugam adaugati timpul de intrerupere pentru operator',
        },
        missingComment: 'Va rugam adaugati un comentariu.',
      },
      barcodeHint: {
        button: 'Scanați codul de bare',
        heading: 'Scaneaza acum codul de bare',
        subtext: 'Va rugam scanati un cod de bare',
        generateLhdCode: 'Generarea numărului LHD',
      },
      steps: {
        1: {
          title: 'Informatii despre eroare',
          detail: 'Informatii despre partea defecta',
        },
        2: {
          title: 'Categoria de eroare',
          detail: 'Informații pentru eroare',
        },
        3: {
          title: 'Coduri de bare',
        },
        4: {
          title: 'Intreruperea procesului',
          detail: 'Detalii despre intreruperea procesului',
        },
        5: {
          title: 'Motiv si decizie',
          detail: 'Detalii despre motiv & decizie',
        },
        6: {
          title: 'Masuri',
          detail: 'Detalii despre masuri',
        },
      },
      scanMode: 'Modul de scanare',
      on: 'Pornit',
      off: 'Oprit',
      simpleLevelWarning: {
        title: 'Caz de eroare este eliberat',
        text: 'Vă rugăm să introduceți numele dvs. pe autocolantul roșu cu cod de bare sub Expozant și Aprobare.',
      },
      blockedAlert: 'Acest caz de eroare este în prezent editat de {name}.',
      releaseBlockedErrorButton: 'Eliberați',
    },
    catalog: {
      decisions: {
        subtext: 'Mai multe informatii despre catalogul de decizii',
        selectStructure: '1. Va rugam selectati o structura',
      },
    },
    config: {
      catalogs: 'Catalog',
      useFromTemplate: 'Utilizati un sablon',
      linkButtonNew: 'Atribuiti acum',
      noDecisionCatalog: 'Fara catalog de decizii',
      individualDecisionCatalog: 'Catalog de decizii individuale',
      selectedIndividualDecisionCatalog:
        'Catalogul de decizii #{id} din {date} cu {amount} deciziile disponibile',
      inheritDecisionCatalog: 'Catalog de decizii mostenite',
      availableDecisionsInCatalog: '',
      allDecisions: 'Deciziile disponibile în,',
      noCauseCatalog: 'Cauzele nu sunt descrise in catalog',
      individualCauseCatalog: 'Catalog pt. cauzator individual',
      selectedIndividualCauseCatalog:
        'Catalogul pt cauzator #{id} de la {date} pana la {amount} cauzator disponibil',
      inheritCauseCatalog: 'Catalog pt cauzatorul mostenit',
      availableCausesInCatalog: 'Cauzator disponibil in,',
      allCauses: 'Toti cauzatorii',
      noCauseReasonCatalog: 'Nici un cauzator in catalog',
      individualCauseReasonCatalog: 'Catalog pentru cauzatorii individuali',
      selectedIndividualCauseReasonCatalog:
        'Catalog cauzator #{id} de la {date} cu {amount} cauzator disponibil',
      inheritCauseReasonCatalog: 'Catalogul cauzator moștenit',
      availableCauseReasonsInCatalog: 'Cauzator disponibil în,',
      allCauseReasons: 'Toti cauzatori',
      noRiskCatalog: 'Fara catalog de riscuri',
      individualRiskCatalog: 'Catalog individual de riscuri',
      selectedIndividualRiskCatalog:
        'Catalog de riscuri #{id} de la {date} cu {amount} niveluri de risc disponibile',
      inheritRiskCatalog: 'Catalog de riscuri mostenite',
      missingCategoriesRiskCatalog:
        'Trebuie să selectați cel putin un catalog de riscuri.',
      missingErrorPlaceRiskCatalog:
        'Trebuie selectat cel putin un loc pentru eroare si un tip de eroare.',
      allErrorPlaces: 'Toate locurile pentru eroare',
      allErrorStyles: 'Toate tipurile de eroare',
      selectProcessInterruptionCatalogHeadline: 'Principale si sub-categorii',
      selectProductCategoryCatalogHeadline:
        'Produse principale și sub-categorii',
      noProcessInterruptionCatalog:
        'Niciun catalog pentru intreruperile de proces',
      individualProcessInterruptionCatalog:
        'Catalog individual pentru intreruperile de proces',
      inheritProcessInterruptionCatalog:
        'Catalogul pentru intreruperi ale procesului mostenit',
      noProductCategoryCatalog: 'Niciun catalog de categorii de produse',
      individualProductCategoryCatalog:
        'Catalog individual de categorii de produse',
      inheritProductCategoryCatalog: 'Catalog de categorii de produse mostenit',
      decisionCatalog: 'Catalog de decizii',
      causeCatalog: 'Catalog pentru cauza',
      causeReasonCatalog: 'Catalog pentru cauzator',
      riskCatalog: 'Catalog pentru riscuri',
      riskCatalogLevels: 'Atribuirea nivelului de risc',
      allErrorCategories: 'Toate categoriile de erori',
      errorCategoryNeeded: 'Trebuie sa selectati o categorie de eroare',
      processInterruptionCatalog: 'Catalog pentru intreruperi de proces',
      productCategoryCatalog: 'Catalogul categoriilor de produse',
      selectedProductCatalog:
        'Catalog al categoriilor de produse #{id} de la {date} cu {amount} categorie de produse disponibile',
      connect: 'Atribuie',
      connectAll: 'Toate atribuirile',
      disconnect: 'Deconectare',
      decisions: 'Deciziile',
      causes: 'Cauzele',
      riskLevel: 'Configurarea nivelului de risc',
      newDecision: 'Decizie noua',
      addDecision: 'Adaugati o noua decizie',
      addRiskLevel: 'Adaugati un nou nivel de risc',
      addCause: 'Adaugati o noua cauza',
      addCauseReason: 'Adaugati un nou cauzator',
      addErrorStyle: 'Adaugati un nou tip de eroare',
      addErrorPlace: 'Adaugati o noua localizarea a erorii',
      addLocationRiskCategory: 'Adaugati o noua categorie de eroare',
      removeRiskLevelQuestion: 'Sunteti sigur de stergerea nivelului de risc?',
      disconnectLocationRiskCategoryQuestion:
        'Daca stergeti aceasta categorie de eroare, fiecare nivel de risc din va fi sters. Sunteți sigur de stergerea categoriei de eroare?',
      disconnectErrorStyleQuestion:
        'Daca stergeti acest tip de eroare, fiecare nivel de risc va fi șters. Sunteti sigur de stergerea tipului de eroare?',
      disconnectErrorPlaceQuestion:
        'Daca stergeti aceasta localizare a erorii, fiecare nivel de risc din va fi șters. Sunteti sigur de stergerea localizarii erorii?',
      deleteDecisionQuestion:
        'Sunteti sigur ca doriti sa stergeti această decizie? Aceasta decizie va fi stearsa si in alte elemente, de asemenea.',
      deleteProcessInterruptionCategoryQuestion:
        'Sunteti sigur ca doriti sa stergeti acest grup?',
      deleteProductCategoryQuestion:
        'Sunteti sigur ca doriti sa stergeti aceasta categorie de produse?',
      deleteProcessInterruptionSubCategoryQuestion:
        'Sunteti sigur ca doriti sa stergeti acest sub-grup?',
      deleteProductSubCategoryQuestion:
        'Sunteti sigur ca doriti sa stergeti aceasta sub-categorie de produse?',
      deleteCauseQuestion:
        'Sunteti sigur ca doriti sa stergeti acest cauzator? Cauzatorul va fi sters si in alte elemente, de asemenea.',
      deleteCauseReasonQuestion:
        'Sunteti sigur ca doriti sa stergeti aceasta cauza? Aceasta cauza va fi stearsa si in alte elemente, de asemenea.',
      deleteErrorPlaceQuestion:
        'Sunteti sigur ca doriti sa stergeti acesta localizare a erorii? Localizarea erorii va fi stearsa si in alte elemete, de asemenea.',
      deleteErrorStyleQuestion:
        'Sunteti sigur ca doriti sa stergeti acest tip de eroare? Acest tip de eroare va fi sters si in alte elemte.',
      deleteConnectedNodeHint: 'Exista conexiuni cu {amount} elemente:',
      deleteDecisionCatalogQuestion:
        'Doriti sa stergeti acest catalog de decizii?.',
      deleteCauseCatalogQuestion: 'Doriti sa stergeti acest catalog de cauze?',
      deleteRiskCatalogQuestion: 'Doriti sa stergeti acest catalog de riscuri?',
      deleteProcessInterruptionCatalogQuestion:
        'Doriti sa stergeti acest catalog de intreruperi de proces.',
      decisionNeeded: 'Cauza si decizie necesare',
      locationRiskCategory: {
        add: 'Adaugati o noua categorie',
        edit: 'Editati categoria',
        newName: 'Nume nou',
      },
      noCauseReasonSelected: 'Niciun cauzator selectat.',
      noCauseSelected: 'Nicio cauza selectata',
      noDecisionSelected: 'Nicio decizie selectata.',
      connectAllErrorPlaces: 'Atribuiti toate categoriile de eroare',
      allProcessInterruptionsInCatalog: 'Toate intreruperile procesului in',
      noProcessInterruptionAssigned:
        'Nu au fost atribuite intreruperi de proces',
      allProductCategoriesInCatalog: 'Toate categoriile de produse.',
      noProductCategoryAssigned: 'Nu s-a alocat nici o categorie de produse',
      missing: {
        title: 'Lipseste configuratia',
        text: 'Intra in configurare si asigura-te ca exista o unitate organizationala complet configurata.',
      },
      openPointResponsibles: 'Responsabilii punctului deschis',
    },
    overview: {
      bulkDeleted: 'Elementele au fost sterse',
      risk: 'Risc',
      details: 'Detalii',
      amounts: 'Cantitati',
      location: 'Localizarea erorii',
      errorLocation: 'Localizarea erorii / tipul erorii',
      processInterruption: 'Intreruperea procesului',
      allProcessInterruptions: 'Toate intreruperile procesului',
      decision: 'Decizie',
      states: {
        open: 'Deschis',
        notNeeded: 'Nu este necesar',
      },
      all: 'Toate cazurile de eroare',
      lockedTooltip:
        'Acest folder de erori este în prezent procesat de un alt angajat.',
      newOpenPoint: 'Nou punct deschis pentru caz de eroare',
    },
    notifications: {
      finalizedError: {
        title: 'Terminat',
        text: 'Acest caz de eroare a fost decis.',
      },
    },
    processInterruption: {
      states: {
        open: 'Deschis',
        accepted: 'Acceptat',
        declined: 'Respins',
      },
      stateActions: {
        accept: 'Acceptat',
        decline: 'Respins',
      },
      form: {
        created: 'Intreruperea procesului a fost realizata.',
      },
      overview: {
        source: 'Sursa',
        category: 'Categorie',
        description: 'Descriere',
      },
    },
    opl: {
      overview: 'Toate masurile din FES',
    },
    noTaksOnErrorCase: 'Nu exista masuri pentru eroare',
  },
  opl: {
    form: {
      self: 'Masura',
      plural: 'Masuri',
      source: 'Sursa',
      title: 'Subiectul masurii',
      heading: 'Masura noua',
      headingSub: 'Noua sub-masura',
      subtext: 'Va rugam inregistrati toate masurile',
      none: 'Fara masuri',
      immediate: 'Masura imediata',
      immediateShort: 'IM',
      accidentPrevention: 'Prevenirea accidentelor',
      accidentPreventionShort: 'AP',
      assignee: 'Atribuiti',
      assignees: 'Atribuiri',
      assigneeMaxHint: 'Maxim 3 atribuiri',
      newAssignee: 'O noua atribuire',
      newAssignees: 'Un nou responsabil',
      watcher: 'Observator',
      dueDate: 'Termen de implementare',
      comment: 'Comentariu',
      comments: 'Comentarii',
      newComment: 'Creați un nou comentariu',
      attachments: 'Atasamente',
      additional: 'Masura suplimentara',
      additionalChild: 'Sub-masura suplimentara',
      assign: 'Atribuiti',
      postpone: 'Amana',
      complete: 'Complet',
      decline: 'Respins',
      messageHere: 'Va rugam adaugati mesajul dvs. aici.',
      create: 'Creati masura',
      new: 'Noua masura',
      createBtn: {
        single: 'Creati masura',
        plural: 'Creati masurile',
      },
      created: 'Masura a fost creata',
      reason: 'Motivul',
      activities: 'Activitati',
      lastEdit: 'Ultima activitate',
      children: {
        self: 'Sub masura',
        plural: 'Sub masuri',
      },
      parent: {
        self: 'Masura originala',
        plural: 'Masurile originale',
      },
      connectedErrorCase: {
        title: 'Caz de eroare conectat',
        text: 'Această măsură va fi asociată cu caz de eroare {errorCaseId}.',
      },
    },
    errors: {
      missingTitle: 'Vă rugam adaugati un subiect pentru masura.',
      missingComment: 'Vă rugam adaugati un comentariu',
      missingDueDate: 'Vă rugam adaugati o data limita.',
      missingAssignee: 'Vă rugam alocati cel putin un destinatar.',
      missingMessage: 'Vă rugăm să furnizați un mesaj.',
      missingReason: 'Vă rugam adaugati un motiv',
      sameAssignee: 'Atribuirea nu poate fii aceeasi',
      missingAttachment: 'Vă rugam sa lasati o singura imagine.',
      dueDateNotInFuture: 'Data limita trebuie sa fie in viitor.',
    },
    assigned: 'Sunteti responsabil pentru aceasta masura',
    assignedOther:
      '<span class="badge badge-primary me-3">{name}</span> este pentru responsabilul de masura.',
    assignedNone: 'Aceasta masura nu este atribuita',
    assignedInitial: 'Aceasta masura are urmatoarea atribuire',
    openOpenPoints: 'Masuri deschise',
    changeAssigneeHint: 'Aici puteti adauga un utilizator nou.',
    changeDueDateHint: 'Aici puteti selecta data scadenta.',
    declineHint: 'Va rugam adăugati aici motivul.',
    changes: {
      assignee:
        'Alocarea a fost modificată din <span class="badge badge-light-primary mx-3" title="{beforeUsername}">{before}</span> in <span class="badge badge-primary mx-3" title="{afterUsername}">{after}</span>.',
      additionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> a fost adăugat la măsură ca parte responsabilă.',
      removeAdditionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> a fost eliminat din acțiune ca parte responsabilă.',
      state:
        'Starea s-a schimbat de la <span class="badge {beforeClass} mx-3" title="{beforeUsername}">{before}</span> la <span class="badge {afterClass} mx-3" title="{afterUsername}">{after}</span>.',
      dueDate:
        'Data limita a fost schimbată din <span class="badge badge-light-primary mx-3" title="{beforeUsername}">{before}</span> in <span class="badge badge-primary mx-3" title="{afterUsername}">{after}</span>.',
    },
    states: {
      open: 'Deschis',
      progress: 'In curs',
      done: 'Terminat',
      closed: 'Inchis',
      declined: 'Respins',
      postponed: 'Amanat',
    },
    statesBadges: {
      open: 'primary',
      progress: 'warning',
      done: 'success',
      closed: 'light',
      declined: 'danger',
      postponed: 'warning',
    },
    stateChanges: {
      dueDate: 'Amanare',
      completed: 'Complet',
    },
    stateChangesDecisions: {
      waiting: '{type} nu a fost inca decis.',
      accepted: '{type} a fost acceptat la {date} de la {decider}.',
      declined: '{type} a fost eliminat la {date} de catre {decider}',
    },
    overview: {
      source: 'Sursa',
      creator: 'Creator',
      creatorFES: 'Creatorul cazului de eroare',
      comments: 'Comentarii',
      state: 'Stat',
      errorLocation: 'Localizarea erorii / Tipul erorii',
      processInterruption: 'Intreruperea procesului',
      decision: 'Decizia',
      states: {
        open: 'Deschis',
        notNeeded: 'Nu este necesar',
      },
      module: 'Modul',
    },
    watchers: {
      manage: {
        title: 'Gestiona observatorii',
        hint: 'Utilizatorii pot fi găsiți după numele lor. Alternativ, poate fi introdusă o adresă de e-mail.',
      },
    },
    goToErrorCaseLink: 'Vezi cazul de eroare',
  },
  spc: {
    nav: {
      overview: 'Prezentare generala',
      processconfirmation: 'confirmarea procesului',
      audit: 'Audit',
      collection: 'creare',
      auditOverview: 'Prezentare generala a auditului',
      auditPlans: 'audituri planificate',
      managementLevel: 'nivel managerial {level}',
    },
    processconfirmation: {
      audit: 'Audit',
      audits: 'Audituri',
      onlyAudit: 'Doar audituri',
      filterAll: 'Toate',
      name: 'Titlu',
      description: 'Descriere',
      new: 'Noua confirmare de proces ',
      newAudit: 'Audit nou',
      backToOverview: 'inapoi la prezentare generala',
      single: 'Confirmarea procesului',
      multiple: 'Confirmari de proces',
      onlySingle: 'Doar confirmarea procesului',
      location: 'Locatia',
      amountOfQuestions: 'Cantitatea de intrebarii',
      create: 'Creati confirmarea procesului',
      createAudit: 'Creati audit',
      created: 'Confirmarea procesului a fost creata',
      createdAudit: 'Auditul a fost creat',
      state: 'Statusul',
      comment: 'Comentariu',
      progress: 'Progres',
      managementLevel: 'Nivel managerial',
      openPointNeeded: 'Conectați măsura(ile)',
      newOpenPoints: 'Noua masura',
      connectExistingOpenPoint: 'selectati masura existenta',
      noOpenPointsConnected: 'Nicio masura conectata',
      connectedOpenPoints: 'Conectati masuriile',
      selectOpenPoints: 'Selectati masura',
      or: 'sau',
      and: 'si',
      deselectOpenPointTitle: 'Doriti sa stergeti masura din nou?',
      createOpenPointAndConnect: 'Creati si conectati masura(ile)',
      noComment: 'Fara comentarii',
      showOnlyErrors: 'Arata doar divergentele',
      amount: 'Cantitate',
      errors: {
        notFound: 'Confirmarea procesului nu a fost găsită',
        noDescription: 'Nu exista descriere.',
        missingName: 'Va rugam adaugati un nume',
        missingAmount: 'Va rugam adaugati o cantitate.',
        missingCatalog: 'Va rugam selectati un catalog.',
        missingManagementLevel: 'Va rugam selectati un nivel managerial',
        noOpenPoint: {
          title: 'Nicio masura conectata.',
          text: 'Va rugam conectati cel puțin o măsură.',
        },
      },
      states: {
        open: 'Deschis',
        progress: 'In progres',
        done: 'Terminat',
      },
      statesBadges: {
        open: 'primary',
        progress: 'warning',
        done: 'success',
      },
      answers: {
        yes: 'da',
        no: 'nu',
        none: 'Nu se aplica',
      },
      success: {
        started: {
          title: 'Inceput',
          text: 'Confirmarea procesului a fost incepută cu succes.',
          textAudit: 'Auditul a fost inceput cu succes.',
        },
        created: {
          title: 'Creata',
          text: 'Confirmarea procesului a fost creata cu succes.<br /><a href="{url}">Incepe acum</a>',
          textAudit:
            'Auditul a fost creat cu succes.<br /><a href="{url}">Incepe acum</a>',
        },
        createdMultiple: {
          title: 'Creata',
          text: 'Confirmarea de proces a fost creat cu succes',
          textAudit: 'Auditul a fost creat cu succes.',
        },
        completed: {
          title: 'Complet',
          text: 'Confirmarea procesului a fost realizat cu succes.',
          textAudit: 'Auditul a fost realizat cu succes.',
        },
      },
    },
    auditPlan: {
      intervalRangeSeparator: 'pana cand',
      dateRange: 'Perioada',
      createAuditPlanCheckbox: 'Creati {type} la intervale regulate',
      noEndDate: 'Fara data de incheiere',
      interval: 'Intervalul',
      errors: {
        missingStartDate: 'Va rugam introduceti o data de inceput',
        missingInterval: 'Va rugam introduceti intervalul.',
        loadingError: 'Planul de audit nu a putut fi încarcat.',
      },
      startDatePlaceholder: 'Data inceputa',
      endDatePlaceholder: 'Data de inchidere',
      intervals: {
        daily: 'Zilnic',
        weekly: 'Saptamanal',
        monthly: 'Lunar',
      },
      success: {
        created: {
          title: 'Creat',
          text: 'Planul de audit a fost creat cu succes.',
        },
        saved: {
          title: 'Salvat',
          text: 'Planul de audit a fost salvat cu succes.',
        },
        removed: {
          title: 'Sters',
          text: 'Planul de audit a fost sters cu succes.',
        },
      },
      calendar: 'Calendar',
      month: 'Luna',
      week: 'Saptamana',
      day: 'Ziua',
      new: 'Noul plan de audit',
      edit: 'Editati planul de audit',
      create: 'Creati un planul de audit',
      saveChanges: 'Salvati schimbarile',
    },
    questionCatalog: {
      all: 'Cataloage de intrebari',
      single: 'Catalog de intrebari',
      new: 'Noul catalog de intrebari',
      none: 'Niciun catalog de intrebari',
      questionSummarized: 'Diferite intrebari',
      questionTotal: 'Toate intrebarile',
      managementLevel: 'Nivel managerial',
      managementLevels: 'Niveluri manageriale',
      allManagementLevels: 'Toate nivelurile manageriale',
      noManagementLevelsTitle: 'Niciun nivel managerial',
      noManagementLevelsText: 'Va rugam selectati un nivel managerial.',
      noQuestionsTitle: 'Nicio intrebare',
      noQuestionsText: 'Va rugam selectati o intrebare.',
      noQuestionCatalogs: 'Catalog fara intrebari',
      managementLevelEdit: {
        add: 'Adaugati o noua categorie',
        edit: 'Editati categoria',
        newName: 'Nume nou',
      },
      disconnectManagementLevelQuestion:
        'Sunteti sigur ca doriti sa deconectati nivelurile de management?',
      addManagementLevel: 'Adaugati nivel de management',
      addQuestion: 'Adaugati intrebari',
      allQuestions: 'Toate intrebariile',
      deleteQuestionQuestion:
        'Sunteti sigur ca doriti sa stergeti aceasta intrebare?',
      deleteMangementLevelQuestion:
        'Sunteti sigur ca doriti sa stergeti acest nivel de management?',
      catalogByManagementLevel: 'Catalog de intrebari pe nivel de management',
      deleteQuestion:
        'Sunteti sigur ca doriti sa stergeti acest nivel de management?',
      image: 'Imagine',
      searchQuestion: 'Cauta intrebare...',
      searchUser: 'Cauta utilizator...',
      deactivated: 'Dezactivat',
      inherited: 'Mostenit',
      inheritedUntil: 'Mostenit pana la {orgUnitName}',
      managementLevelAmount: '{name} cu {amount} intrebari',
      overwrittenCatalog: 'Catalog suprascris',
      copiedAlert: {
        title: 'Catalog de intrebari mostenit',
        text: 'Sablonul acestui catalog de intrebări este din {catalog}.',
      },
      connectedCatalogs: 'Cataloage conectate',
    },
    userManagement: {
      responsibles: 'Responsabili',
      workers: 'Operatori',
      availableWorkers: 'Operatori disponibili',
      selectedWorkers: 'Operatori conectati',
      connectWorker: 'conectare',
      disconnectWorker: 'deconectare',
    },
    question: {
      all: 'Toate intrebariile',
      title: 'Intrebare',
      type: 'Tip de intrebare',
      multiple: 'Intrebari',
      images: 'Imagini',
      hint: 'Indiciu',
      externalLink: 'Link extern',
      noneAllowed: 'Nu se aplică',
      openPointNeeded: 'Masura necesara',
      onlyFromToDates: 'Period',
      onlyFromToDatesFrom: 'De la',
      onlyFromToDatesTo: 'La',
      types: {
        default: 'Intrebare obisnuita',
        mandatory: 'Intrebare obligatorie',
        pinned: 'Intrebare standard',
      },
      moreDetails: 'Mai multe informatii',
      editHint:
        'Aceasta intrebare este legata de urmatoarele cataloage de intrebari. Modificarile întrebarilor poate afecta continutul acestor cataloage.',
    },
  },
  state: {
    view: 'Statusul',
    active: 'Activ',
    inactive: 'Inactiv',
  },
  user: {
    new: 'Utilizator nou',
    mail: 'Mail',
    groups: 'Grupuri',
    idAndName: 'ID & Nume',
    removed: 'Utilizatorul a fost inlaturat',
    password: 'Parola',
    settings: 'Setarile utilizatorului',
    mailSettings: 'Setări de e-mail',
    mails: {
      newErrorCase: 'Caz nou de eroare',
      afterComplete: 'După finalizarea punctului deschis',
      afterCompleteAccept: 'După acceptarea finalizării punctului deschis',
      afterCompleteDecline: 'După declinul finalizării punctului deschis',
      afterDecline: 'După declinul punctului deschis',
      afterPostpone: 'După amânarea punctului deschis',
      afterPostponeAccept: 'După acceptarea amânării punctului deschis',
      afterPostponeDecline: 'După declinul amânării punctului deschis',
      afterRequestDecision: 'După cererea de decizie privind punctul deschis',
      afterResolve: 'După rezoluția punctului deschis',
      newAssignee: 'Destinatar nou',
      newComment: 'Noul comentariu pentru Open Point',
      newOpenPoint: 'Nou punct deschis',
      afterWatcherAssignment: 'După atribuirea rolului de observator',
      afterWatcherDismissal: 'După înlăturarea rolului de observator',
    },
  },
  errors: {
    unableToLoadUser: 'Utilizatorul nu a putut fi incarcat.',
    missingConfig: {
      title: 'Lipsește configurația',
      text: 'Intrați în configurare și asigurați-vă că există o unitate organizatorică complet configurată.',
    },
    missingManagementLevel: {
      title: 'Lipsește nivelul de management',
      text: 'Vă rugăm să contactați administratorul dvs. Trebuie să fie disponibil un nivel de management pentru a utiliza aplicația.',
    },
    missingManagementArea: {
      title: 'Lipsește suprafața alocată',
      text: 'Vă rugăm să contactați administratorul dvs. O zonă alocată trebuie să fie disponibilă pentru a utiliza aplicația.',
    },
    deleteManagementLevelNotAllowed: {
      title: 'Ștergerea nu este posibilă',
      text: 'Nivelurile de ghid care sunt încă conectate în alte cataloage nu pot fi șterse.',
    },
  },
  location: {
    selectAll: 'Toate',
  },
  dateFormat: 'DD.MM.YYYY',
};
