<template>
  <oplOpenPointOverview
    :title="$t('opl.openOpenPoints')"
    :onlyAllowed="true"
    :onlyOpen="true"
  />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import oplOpenPointOverview from '@/views/opl/OplOverview.vue';

  export default defineComponent({
    name: 'oplDashboard',
    components: {
      oplOpenPointOverview,
    },
    setup() {
      return {};
    },
  });
</script>

<style lang="scss">
  tbody tr {
    cursor: pointer;
  }
  img {
    max-height: 250px;
    max-width: 250px;
  }
</style>
