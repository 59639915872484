<template>
  <div class="d-inline-flex">
    <button
      class="btn btn-primary"
      @click="openModal"
      data-bs-toggle="modal"
      data-bs-target="#manageWatchersModal"
    >
      <i class="fa fa-users text-white" aria-hidden="true"></i>
      <span class="ms-2">{{ $t('opl.watchers.manage.title') }}</span>
    </button>

    <Teleport to="body">
      <div
        class="modal fade"
        tabindex="-1"
        id="manageWatchersModal"
        ref="modalRef"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <h4 class="mb-4">{{ $t('opl.watchers.manage.title') }}</h4>
              <Form @submit="saveChanges" @reset="closeModal">
                <watcher-multi-select v-model="watchers" />
                <p class="mt-2 mb-1 text-muted">
                  {{ $t('opl.watchers.manage.hint') }}
                </p>
                <div class="d-flex gap-2 mt-4 justify-content-end">
                  <button type="reset" class="btn btn-secondary btn-sm">
                    {{ $t('cancel') }}
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    :disabled="!canSave"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { OpenPointFormData, OpenPointWatcher } from '@/core/models/opl';
  import { Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import WatcherMultiSelect from '@/components/opl/WatcherMultiSelect.vue';
  import ApiService from '@/core/services/ApiService';

  const props = defineProps<{ modelValue: OpenPointFormData }>();
  const emit = defineEmits(['update:modelValue']);

  const modalRef = ref<HTMLElement | null>(null);

  const watchers = ref(props.modelValue.watchers?.slice() || []);

  function isSameWatcher(
    watcher: OpenPointWatcher,
    otherWatcher: OpenPointWatcher,
  ): boolean {
    return (
      watcher.email === otherWatcher.email &&
      watcher.userId === otherWatcher.userId
    );
  }

  const hasChanges = computed(
    () =>
      watchers.value.length !== props.modelValue.watchers.length ||
      !watchers.value.every((watcher) =>
        props.modelValue.watchers.some((otherWatcher) =>
          isSameWatcher(watcher, otherWatcher),
        ),
      ),
  );

  const canSave = computed(() => hasChanges.value && !!props.modelValue.id);

  async function saveChanges() {
    if (!canSave.value) {
      return;
    }
    const response = await ApiService.put(
      `/opl/${props.modelValue.id}/watchers`,
      {
        data: watchers.value,
      },
    );

    emit('update:modelValue', { ...props.modelValue, watchers: response.data });
    closeModal();
  }

  const isShown = ref(false);

  function openModal() {
    watchers.value = props.modelValue.watchers?.slice() || [];
    isShown.value = true;
  }

  function closeModal() {
    isShown.value = false;
    hideModal(modalRef.value);
  }
</script>
