import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-pill badge-primary"
}
const _hoisted_2 = {
  key: 1,
  class: "badge badge-pill badge-warning"
}
const _hoisted_3 = {
  key: 2,
  class: "badge badge-pill badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.computedRiskLevel === 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.computedRiskLevel), 1))
      : _createCommentVNode("", true),
    (_ctx.computedRiskLevel === 2)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.computedRiskLevel), 1))
      : _createCommentVNode("", true),
    (_ctx.computedRiskLevel > 2)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.computedRiskLevel), 1))
      : _createCommentVNode("", true)
  ]))
}