<template>
  <span>
    <span
      v-if="computedRiskLevel === 1"
      class="badge badge-pill badge-primary"
      >{{ computedRiskLevel }}</span
    >
    <span
      v-if="computedRiskLevel === 2"
      class="badge badge-pill badge-warning"
      >{{ computedRiskLevel }}</span
    >
    <span v-if="computedRiskLevel > 2" class="badge badge-pill badge-danger">{{
      computedRiskLevel
    }}</span>
  </span>
</template>
<script lang="ts">
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    name: 'riskLevelBadge',
    components: {},
    props: {
      riskLevel: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const computedRiskLevel = computed(() => {
        return props.riskLevel;
      });

      return {
        computedRiskLevel,
      };
    },
  });
</script>
