<template>
  <div class="card-body py-3">
    <div v-if="!data.isEdit">
      <div class="row mb-3">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.causes.cause')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div></div>
              <div>
                <span-with-deleted-state
                  :isDeleted="data.currentDecision.splitCause.isDeleted"
                  >{{
                    data.currentDecision.splitCause.name
                  }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.causes.causeReason')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div>
                <span-with-deleted-state
                  :isDeleted="data.currentDecision.splitCauseReason.isDeleted"
                  >{{
                    data.currentDecision.splitCauseReason.name
                  }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.decision.decision')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div>
                <span-with-deleted-state
                  :isDeleted="data.currentDecision.splitDecision.isDeleted"
                  >{{
                    data.currentDecision.splitDecision.name
                  }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.decision.splitAmountLabel')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div>
                {{ data.currentDecision.amount }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.decision.decider')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-start">
              <span class="badge badge-light-primary">
                <i class="bi bi-person-check me-2"></i>
                {{ data.currentDecision.decider.name }} ({{
                  data.currentDecision.decider.username
                }})
              </span>
              <span
                v-if="data.currentDecision.decisionDate"
                class="badge badge-light ms-3"
              >
                <i class="bi bi-clock-history me-2"></i>
                {{ computedTime(data.currentDecision.decisionDate) }}
              </span>
              <span
                v-if="data.currentDecision.decisionEdits > 0"
                class="badge badge-warning text-gray-600 ms-3"
              >
                <i class="bi bi-pencil-fill me-2"></i>
                {{ data.currentDecision.decisionEdits }}
              </span>
              <button
                @click="startDecisionEdit"
                v-if="computedDecisionReadyForEdit"
                class="btn btn-secondary btn-sm ms-auto"
                :disabled="computedDecisionBlockedDueDelete"
                type="button"
              >
                {{ $t('edit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <MyForm
        :validation-schema="splitDecisionValidator"
        @submit="handleDecisionUpdate"
      >
        <div class="row mb-3">
          <div class="col-3">
            <label class="col-form-label required fw-bold fs-6">{{
              $t('fes.form.causes.cause')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-start flex-column">
            <Field
              as="select"
              name="splitCause"
              class="form-select form-select-solid form-select-lg"
              v-model="data.currentEdit.splitCause.id"
            >
              <option value="" selected>
                {{ $t('pleaseSelect') }}
              </option>
              <option
                v-for="errorCaseCause in computedAvailableErrorCaseCauses"
                :key="errorCaseCause.id"
                :value="errorCaseCause.id"
              >
                {{ errorCaseCause.name }}
              </option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="splitCause" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3">
            <label class="col-form-label required fw-bold fs-6">{{
              $t('fes.form.causes.causeReason')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-start flex-column">
            <Field
              as="select"
              name="splitCauseReason"
              class="form-select form-select-solid form-select-lg"
              v-model="data.currentEdit.splitCauseReason.id"
            >
              <option value="" selected>
                {{ $t('pleaseSelect') }}
              </option>
              <option
                v-for="errorCaseCauseReason in computedAvailableErrorCaseCauseReasons"
                :key="errorCaseCauseReason.id"
                :value="errorCaseCauseReason.id"
              >
                {{ errorCaseCauseReason.name }}
              </option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="splitCauseReason" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3">
            <label class="col-form-label required fw-bold fs-6">{{
              $t('fes.form.decision.decision')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-start flex-column">
            <Field
              as="select"
              name="splitDecision"
              class="form-select form-select-solid form-select-lg"
              v-model="data.currentEdit.splitDecision.id"
            >
              <option value="" selected>
                {{ $t('pleaseSelect') }}
              </option>
              <option
                v-for="errorCaseDecision in computedAvailableErrorCaseDecisions"
                :key="errorCaseDecision.id"
                :value="errorCaseDecision.id"
              >
                {{ errorCaseDecision.name }}
              </option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="splitDecision" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label class="col-form-label required fw-bold fs-6">{{
              $t('fes.form.decision.splitAmountLabel')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-start flex-column">
            <Field
              name="amount"
              type="number"
              class="form-control form-control-lg form-control-solid mb-3"
              v-model="data.currentEdit.amount"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="amount" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-start">
            <span class="badge badge-light-primary">
              <i class="bi bi-person-check me-2"></i>
              {{ data.currentDecision.decider.name }} ({{
                data.currentDecision.decider.username
              }})
            </span>
          </div>
          <div class="col-6 text-end">
            <button
              @click="cancelDecisionEdit"
              class="btn btn-secondary btn-sm me-1"
              type="button"
            >
              {{ $t('cancel') }}
            </button>
            <button
              class="btn btn-sm btn-primary"
              v-if="computedDecisionReadyForEdit"
              type="submit"
            >
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </MyForm>
    </div>
  </div>
</template>

<script lang="ts">
  import { computedTime, getNearestCatalog } from '@/core/helpers/cdf';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import {
    CauseCatalog,
    CauseReasonCatalog,
    DecisionCatalog,
    ErrorCase,
    ErrorCaseCause,
    ErrorCaseCauseReason,
    ErrorCaseDecision,
    ErrorCaseSplitDecision,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/fes';
  import { User } from '@/store/modules/AuthModule';
  import {
    computed,
    ComputedRef,
    defineComponent,
    PropType,
    reactive,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import * as yup from 'yup';
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import { AxiosResponse } from 'axios';
  import Swal from 'sweetalert2';

  export default defineComponent({
    name: 'DecisionSingle',
    components: {
      Field,
      MyForm: Form,
      ErrorMessage,
    },
    props: {
      errorCase: {
        type: Object as PropType<ErrorCase>,
        required: true,
      },
      splitDecision: {
        type: Object as PropType<ErrorCaseSplitDecision>,
        required: true,
      },
      orgChart: {
        type: Object as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      orgLevels: {
        type: Array as PropType<string[]>,
        required: true,
      },
      availableOrgLevels: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const { t } = useI18n();

      const data = reactive({
        isEdit: false,
        currentDecision: Object.assign(
          {},
          props.splitDecision,
        ) as ErrorCaseSplitDecision,
        currentEdit: Object.assign(
          {},
          props.splitDecision,
        ) as ErrorCaseSplitDecision,
      });

      const startDecisionEdit = () => {
        data.isEdit = true;
      };

      const computedCurrentUser: ComputedRef<User> = computed(() => {
        return store.getters.currentUser;
      });

      const computedCurrentUserIsCurrentDecider = computed(() => {
        return (
          computedCurrentUser.value.id === data.currentDecision.decider.userId
        );
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      const computedDecisionReadyForEdit = computed(() => {
        if (
          computedCurrentUserIsCurrentDecider.value === true ||
          computedCurrentUserIsAdmin.value === true
        ) {
          return true;
        }
        return false;
      });

      const selectedOrgLevels = computed(() => {
        return props.orgLevels
          .map((orgLevelId, index) => {
            const currentOptions = computedOrgOptions(index);

            return currentOptions.find((orgOption) => {
              return orgLevelId === orgOption.id;
            });
          })
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          });
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(props.availableOrgLevels);
        const rawDataParsed = JSON.parse(rawData);
        return rawDataParsed;
      };

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return props.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const computedCurrentLocation = computed(() => {
        if (props.errorCase !== null && props.errorCase.locations.length > 0) {
          const lastLocation = props.errorCase.locations.slice(0).pop();
          if (lastLocation) {
            return props.orgChart.find(
              (orgUnit: OrgChartDataPairWithCatalogs) => {
                return lastLocation.locationId === orgUnit.id;
              },
            );
          }
        }
        return selectedOrgLevels.value
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          })
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      const computedAvailableErrorCaseCauses = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeCatalog: CauseCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'causeCatalog',
        );

        if (causeCatalog !== null) {
          return causeCatalog.causes.sort(
            (causeA: ErrorCaseCause, causeB: ErrorCaseCause) => {
              if (causeA.name < causeB.name) {
                return -1;
              }
              if (causeA.name > causeB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseCauseReasons = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeCatalog: CauseReasonCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'causeReasonCatalog',
        );

        if (causeCatalog !== null) {
          return causeCatalog.causeReasons.sort(
            (
              causeReasonA: ErrorCaseCauseReason,
              causeReasonB: ErrorCaseCauseReason,
            ) => {
              if (causeReasonA.name < causeReasonB.name) {
                return -1;
              }
              if (causeReasonA.name > causeReasonB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseDecisions = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const decisionCatalog: DecisionCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'decisionCatalog',
        );

        if (decisionCatalog !== null) {
          return decisionCatalog.decisions.sort(
            (decisionA: ErrorCaseDecision, decisionB: ErrorCaseDecision) => {
              if (decisionA.name < decisionB.name) {
                return -1;
              }
              if (decisionA.name > decisionB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const splitDecisionValidator = yup.object().shape({
        availableDecisionPoints: yup.number(),
        splitDecision: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingDecision'))
          .label(t('fes.form.decision.decision')),
        splitCause: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingCause'))
          .label(t('fes.form.causes.cause')),
        splitCauseReason: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingCauseReason'))
          .label(t('fes.form.causes.causeReason')),
        amount: yup
          .number()
          .min(1, t('fes.form.decision.splitAmountErrors.moreThanOne')),
      });

      function cancelDecisionEdit() {
        data.isEdit = false;
      }

      function handleDecisionUpdate() {
        try {
          if (!data.currentEdit.splitCause) return;
          const splitCause = data.currentEdit.splitCause;
          let currentSplitCauseId;

          if (
            typeof splitCause === 'object' &&
            splitCause !== null &&
            'id' in splitCause
          ) {
            currentSplitCauseId = splitCause.id as number;
            data.currentEdit.splitCause =
              computedAvailableErrorCaseCauses.value.find((cause) => {
                return cause.id === currentSplitCauseId;
              }) || '';
          }

          if (!data.currentEdit.splitCauseReason) return;
          const splitCauseReason = data.currentEdit.splitCauseReason;
          let currentSplitCauseReasonId;

          if (
            typeof splitCauseReason === 'object' &&
            splitCauseReason !== null &&
            'id' in splitCauseReason
          ) {
            currentSplitCauseReasonId = splitCauseReason.id as number;
            data.currentEdit.splitCauseReason =
              computedAvailableErrorCaseCauseReasons.value.find(
                (causeReason) => {
                  return causeReason.id === currentSplitCauseReasonId;
                },
              ) || '';
          }

          if (!data.currentEdit.splitDecision) return;
          const splitDecision = data.currentEdit.splitDecision;
          let currentSplitDecisionId;

          if (
            typeof splitDecision === 'object' &&
            splitDecision !== null &&
            'id' in splitDecision
          ) {
            currentSplitDecisionId = splitDecision.id as number;
            data.currentEdit.splitDecision =
              computedAvailableErrorCaseDecisions.value.find((decision) => {
                return decision.id === currentSplitDecisionId;
              }) || '';
          }

          const decisionUpdate: ErrorCaseSplitDecision = {
            id: data.currentDecision.id as number,
            amount: data.currentEdit.amount as number,
            decider: data.currentDecision.decider,
            splitDecision: data.currentEdit.splitDecision as ErrorCaseDecision,
            splitCause: data.currentEdit.splitCause as ErrorCaseCause,
            splitCauseReason: data.currentEdit
              .splitCauseReason as ErrorCaseCauseReason,
          };

          store.dispatch(Actions.START_LOADER);

          ApiService.post('fes/errors/decision/update', {
            data: decisionUpdate,
          })
            .then((response: AxiosResponse) => {
              data.currentDecision = response.data as ErrorCaseSplitDecision;
              data.currentEdit = response.data as ErrorCaseSplitDecision;
              data.isEdit = false;
            })
            .catch(() => {
              Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            })
            .finally(() => {
              store.dispatch(Actions.END_LOADER);
            });
        } catch (error) {
          console.error(error);
        }
        return false;
      }

      return {
        data,
        computedAvailableErrorCaseCauses,
        computedAvailableErrorCaseCauseReasons,
        computedAvailableErrorCaseDecisions,
        startDecisionEdit,
        computedDecisionReadyForEdit,
        computedTime,
        splitDecisionValidator,
        handleDecisionUpdate,
        cancelDecisionEdit,
      };
    },
  });
</script>
