import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.state === 'open')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.open')}`)
        }, _toDisplayString(_ctx.$t('opl.states.open')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'progress')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.progress')}`)
        }, _toDisplayString(_ctx.$t('opl.states.progress')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'done')
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.done')}`)
        }, _toDisplayString(_ctx.$t('opl.states.done')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'closed')
      ? (_openBlock(), _createElementBlock("span", {
          key: 3,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.closed')}`)
        }, _toDisplayString(_ctx.$t('opl.states.closed')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'declined')
      ? (_openBlock(), _createElementBlock("span", {
          key: 4,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.declined')}`)
        }, _toDisplayString(_ctx.$t('opl.states.declined')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'postponed')
      ? (_openBlock(), _createElementBlock("span", {
          key: 5,
          class: _normalizeClass(`badge badge-${_ctx.$t('opl.statesBadges.postponed')}`)
        }, _toDisplayString(_ctx.$t('opl.states.postponed')), 3))
      : _createCommentVNode("", true)
  ]))
}