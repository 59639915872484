export function pick<T extends object, K extends keyof T>(
  source: T,
  keys: K[],
): Pick<T, K> {
  return keys.reduce(
    (result, key) => {
      if (key in source) {
        result[key] = source[key];
      }
      return result;
    },
    {} as Pick<T, K>,
  );
}
