export type OpenPoint = {
  id?: number;
  externalId: string | null;
  title: string;
  state: OpenPointState;
  isUrgent: boolean;
  isAccidentPrevention: boolean;
  dueDate: string | null;
  comment: string;
  source: string | null;
  sourceId: string | null;
  assignee: OpenPointAssignee | null;
  initialAssignees: OpenPointInitialAssignee[];
  additionalAssignees: OpenPointAdditionalAssignee[];
  creator: OpenPointCreator | null;
  watchers: OpenPointWatcher[];
  attachments: OpenPointAttachment[];
  messages: OpenPointMessage[];
  createdAt: string;
  parent: OpenPoint | null;
  locations: OpenPointLocation[];
  children: OpenPoint[];
};

export type OpenPointLocation = {
  id?: number;
  locationIdentifier: string;
  locationName: string;
  locationId: string;
  pos: number;
};

export enum MessageChangeType {
  DUE_DATE = 'dueDate',
  COMPLETED = 'completed',
}

export type OpenPointChange = {
  id: number;
  field: string;
  fromValue: string;
  toValue: string;
  createdAt: string;
};

export enum OpenPointState {
  OPEN = 'open',
  IN_PROGRESS = 'progress',
  DONE = 'done',
  POSTPONED = 'postponed',
  CLOSED = 'closed',
  DECLINED = 'declined',
}

export enum OpenPointMessageState {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export type OpenPointMessage = {
  id: number;
  text: string;
  attachments: OpenPointAttachment[];
  author: OpenPointMessageAuthor;
  changes: OpenPointChange[];
  openPoint: OpenPoint;
  needsDecision: boolean;
  decision: OpenPointMessageDecision;
  createdAt: string;
};

export type OpenPointMessageDecision = {
  id: number;
  openPointMessage: OpenPointMessage;
  state: OpenPointMessageState;
  decider: OpenPointMessageDecider;
  createdAt: string;
};

export type OpenPointMessageDecider = {
  id: number;
  userId: string;
  name: string;
  email: string;
  decision: OpenPointMessageDecision;
};

export type OpenPointMessageWithConfig = OpenPointMessage & {
  autoSetProgress: boolean;
};

export type OpenPointMessageAuthor = {
  id?: number;
  userId: string;
  name: string;
  email: string;
};

export type OpenPointAssignee = {
  id?: number;
  userId: string;
  name: string;
  email: string;
};

export type OpenPointInitialAssignee = OpenPointAssignee;
export type OpenPointAdditionalAssignee = OpenPointAssignee;

export type OpenPointCreator = OpenPointAssignee;
export type OpenPointWatcher = {
  id?: number;
  userId?: string;
  name?: string;
  email: string;
};

export type OpenPointFormData = Omit<OpenPoint, 'children'> & {
  children: OpenPointFormData[];
};

export type OpenPointPostponeOptions = {
  dueDate: string;
  reason: string;
};
export type OpenPointCompleteOptions = {
  attachments: OpenPointAttachment[];
  reason: string;
};

export enum OpenPointAttachmentType {
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export type OpenPointAttachment = {
  id?: number;
  fileName: string;
  type: string;
};

export type OpenPointHierarchyName = {
  id?: number;
  name: string;
  pos: number;
};

export type OPLConfig = {
  hierarchyNames: OpenPointHierarchyName[];
};

export type OpenPointFilterConfig = {
  orgUnitId: string | null;
};
