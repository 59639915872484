<template>
  <div class="d-flex justify-content-start mb-10">
    <div class="d-flex flex-column align-items-start w-100">
      <div class="d-flex align-items-center mb-2 w-100">
        <user-icon :user="currentUser" />
        <div class="ms-4">
          <span class="fs-5 fw-bolder text-gray-900">{{
            currentUser.name
          }}</span>
        </div>
        <div class="flex-grow-1 text-end">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="emit('delete')"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div
        class="p-5 rounded bg-light-primary w-100 open-point-message-container border border-primary border-2"
      >
        <div class="row mb-3">
          <div class="col-3">
            <label class="col-form-label required fw-bold fs-6">{{
              $t('opl.form.title')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-start flex-column">
            <Field
              type="text"
              class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              :name="`${namePrefix}.title`"
              :model-value="modelValue.title"
              @update:modelValue="setModelValue('title', $event)"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage :name="`${namePrefix}.title`" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3"></div>
          <div class="col-9 d-flex align-items-center">
            <span class="me-3">
              <input
                class="form-check-input me-2"
                type="checkbox"
                :id="`${namePrefix}-isUrgent`"
                :checked="modelValue.isUrgent"
                @change="
                  setModelValue(
                    'isUrgent',
                    ($event.target as HTMLInputElement).checked,
                  )
                "
              />
              <Field
                v-show="false"
                :name="`${namePrefix}.isUrgent`"
                :model-value="modelValue.isUrgent"
                @update:modelValue="setModelValue('isUrgent', $event)"
              />
              <label class="form-check-label" :for="`${namePrefix}-isUrgent`">{{
                $t('opl.form.immediate')
              }}</label>
            </span>
            <span>
              <input
                class="form-check-input me-2"
                type="checkbox"
                :id="`${namePrefix}-isAccidentPrevention`"
                :checked="modelValue.isAccidentPrevention"
                @change="
                  setModelValue(
                    'isAccidentPrevention',
                    ($event.target as HTMLInputElement).checked,
                  )
                "
              />
              <label
                class="form-check-label"
                :for="`${namePrefix}-isAccidentPrevention`"
                >{{ $t('opl.form.accidentPrevention') }}</label
              >
            </span>
          </div>
        </div>
        <div class="row mb-3" v-if="!modelValue.isUrgent">
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-3 d-flex align-items-start">
                <label class="col-form-label required fw-bold fs-6">{{
                  $t('opl.form.dueDate')
                }}</label>
              </div>
              <div class="col-9 d-flex align-items-start flex-column">
                <div class="position-relative align-items-center w-100">
                  <div class="symbol symbol-20px me-4 position-absolute ms-4">
                    <span class="symbol-label bg-secondary">
                      <span class="svg-icon">
                        <inline-svg
                          src="media/icons/duotune/general/gen025.svg"
                        />
                      </span>
                    </span>
                  </div>

                  <el-date-picker
                    placeholder="Select a date"
                    class="w-100"
                    :name="`${namePrefix}.dueDate`"
                    :format="$t('dateFormat')"
                    :model-value="modelValue.dueDate"
                    @update:modelValue="setModelValue('dueDate', $event)"
                  />
                  <Field
                    v-show="false"
                    :name="`${namePrefix}.dueDate`"
                    :model-value="modelValue.dueDate"
                    @update:modelValue="setModelValue('dueDate', $event)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage :name="`${namePrefix}.dueDate`" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-3 d-flex align-items-start">
                <label
                  :class="`col-form-label ${
                    !modelValue.isUrgent ? 'required' : ''
                  } fw-bold fs-6`"
                  >{{ $t('opl.form.assignee') }}</label
                >
              </div>
              <div class="col-9 d-flex flex-column align-items-start">
                <Field
                  v-show="false"
                  :name="`${namePrefix}.initialAssignees`"
                  :model-value="modelValue.initialAssignees"
                  @update:modelValue="setModelValue('initialAssignees', $event)"
                />

                <assignee-multi-select
                  :model-value="modelValue.initialAssignees"
                  @update:modelValue="setModelValue('initialAssignees', $event)"
                />
                <div class="form-text">
                  {{ $t('opl.form.assigneeMaxHint') }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage :name="`${namePrefix}.initialAssignees`" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3">
            <label class="col-form-label fw-bold fs-6">{{
              $t('opl.form.watcher')
            }}</label>
          </div>
          <div class="col-9 d-flex align-items-center">
            <watcher-multi-select
              :model-value="modelValue.watchers"
              @update:modelValue="setModelValue('watchers', $event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label
              :class="`col-form-label ${
                !modelValue.isUrgent ? 'required' : ''
              } fw-bold fs-6`"
              >{{ $t('opl.form.comment') }}</label
            >
          </div>
          <div class="col-12 d-flex align-items-start flex-column">
            <Field
              as="text"
              class="form-control form-control-solid d-none"
              :name="`${namePrefix}.comment`"
              :model-value="modelValue.comment"
              @update:modelValue="setModelValue('comment', $event)"
            />

            <TinyMceEditor
              :language="currentLanguage"
              :model-value="modelValue.comment"
              @update:modelValue="setModelValue('comment', $event)"
            />

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage :name="`${namePrefix}.comment`" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="col-form-label fw-bold fs-6">{{
              $t('opl.form.attachments')
            }}</label>
          </div>
          <div class="col-12 d-flex flex-wrap">
            <div
              v-for="(attachment, aIndex) of modelValue.attachments"
              class="image-input image-input-outline me-5 mb-5"
              :key="aIndex"
            >
              <a
                v-if="attachment.type === 'image'"
                target="_blank"
                class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
                :href="attachment.fileName"
                :style="`background-image: url(${attachment.fileName})`"
              ></a>
              <a
                v-if="attachment.type === 'document'"
                target="_blank"
                class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center flex-column"
                :href="attachment.fileName"
              >
                <span
                  class="svg-icon svg-icon-4x svg-icon-primary"
                  :title="getFileName(attachment.fileName)"
                >
                  <inline-svg src="media/icons/duotune/files/fil003.svg" />
                </span>
                <span>{{ getFileExtension(attachment.fileName) }}</span>
              </a>
              <a
                href="javascript:"
                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                data-kt-image-input-action="change"
                @click="removeAttachment(aIndex)"
                ><i class="bi bi-x fs-2"></i
              ></a>
            </div>
            <div class="image-input image-input-outline me-5 mb-5">
              <button
                type="button"
                class="btn btn-primary me-2 w-100px h-100px d-flex justify-content-center align-items-center"
                @click="onAddAttachmentClick"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="col-12 d-flex align-items-start">
            <input
              v-show="false"
              type="file"
              ref="attachmentSelectRef"
              :name="`${namePrefix}[main].images`"
              :key="namePrefix"
              @change="addAttachment"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ErrorMessage, Field } from 'vee-validate';
  import UserIcon from '@/components/UserIcon.vue';
  import TinyMceEditor from '@/components/TinyMceEditor.vue';
  import WatcherMultiSelect from '@/components/opl/WatcherMultiSelect.vue';
  import AssigneeMultiSelect from '@/components/opl/AssigneeMultiSelect.vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { OpenPointFormData } from '@/core/models/opl';
  import { computed, ref } from 'vue';
  import { User } from '@/store/modules/AuthModule';
  import { Actions } from '@/store/enums/StoreEnums';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import ApiService from '@/core/services/ApiService';

  const store = useStore();
  const { t, locale } = useI18n();

  interface Props {
    modelValue: OpenPointFormData;
    namePrefix: string;
  }

  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'delete']);

  function setModelValue<K extends keyof OpenPointFormData>(
    key: K,
    value: OpenPointFormData[K],
  ) {
    emit('update:modelValue', {
      ...props.modelValue,
      [key]: value,
    });
  }

  const currentUser = computed<User>(() => store.getters.currentUser);

  const currentLanguage = computed(() => locale.value);

  const attachmentSelectRef = ref<HTMLInputElement | null>(null);

  function onAddAttachmentClick() {
    attachmentSelectRef.value?.click();
  }

  async function addAttachment(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }
    await store.dispatch(Actions.START_LOADER);

    const formData = new FormData();
    formData.append('attachment', file);

    try {
      const response = await axios.post('opl/attachment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!response.data) {
        return Swal.fire(t('error'), t('errorAlertCaption'), 'error');
      }
      setModelValue('attachments', [
        ...props.modelValue.attachments,
        {
          id: response.data.id,
          fileName:
            ApiService.vueInstance.axios.defaults.baseURL +
            '/opl/attachment/' +
            response.data.fileName,
          type: response.data.type,
        },
      ]);
    } catch (e) {
      await Swal.fire(t('error'), t('errorAlertCaption'), 'error');
    } finally {
      await store.dispatch(Actions.END_LOADER);
      if (attachmentSelectRef.value) {
        attachmentSelectRef.value.value = '';
      }
    }
  }

  async function removeAttachment(attachmentIndex: number) {
    await store.dispatch(Actions.START_LOADER);

    try {
      await ApiService.delete(
        `opl/attachment/${props.modelValue.attachments[attachmentIndex].id}`,
      );
      const copy = props.modelValue.attachments.slice();
      copy.splice(attachmentIndex, 1);
      setModelValue('attachments', copy);
    } catch (e) {
      await Swal.fire(t('error'), t('errorAlertCaption'), 'error');
    } finally {
      await store.dispatch(Actions.END_LOADER);
    }
  }

  function getFileName(fileName: string): string {
    return fileName.split('/').pop();
  }

  function getFileExtension(fileName: string): string {
    return fileName.split('.').pop().toUpperCase();
  }
</script>
