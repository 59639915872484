import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fes-config-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 mb-3" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  class: "card-header border-0",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#orgChartForm",
  "aria-expanded": "true",
  "aria-controls": "orgChartForm"
}
const _hoisted_7 = { class: "card-title align-items-center justify-content-start" }
const _hoisted_8 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_9 = {
  id: "orgChartForm",
  class: "collapse show"
}
const _hoisted_10 = { class: "card-body py-3" }
const _hoisted_11 = { class: "row mb-3" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_hierarchy_names = _resolveComponent("config-hierarchy-names")!
  const _component_org_unit_path = _resolveComponent("org-unit-path")!
  const _component_question_catalog_drawer = _resolveComponent("question-catalog-drawer")!
  const _component_side_drawer = _resolveComponent("side-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_config_hierarchy_names, {
          hierarchyNames: _ctx.data.hierarchyNames,
          moduleName: "spc",
          onUpdated: _ctx.updateOrgChartData
        }, null, 8, ["hierarchyNames", "onUpdated"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('viewOrgChart')) + " SPC", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary me-2",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.data.orgChart.expandAll && _ctx.data.orgChart.expandAll(...args)))
                  }, _toDisplayString(_ctx.$t('cdf.orgChart.buttons.openAllNodes')), 1),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary me-2",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.data.orgChart.collapseAll && _ctx.data.orgChart.collapseAll(...args)))
                  }, _toDisplayString(_ctx.$t('cdf.orgChart.buttons.closeAllNodes')), 1)
                ])
              ]),
              (_openBlock(), _createElementBlock("div", {
                id: "spcChart",
                key: _ctx.data.orgChartTmpId
              }))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_side_drawer, {
      id: "spcConfigDrawer",
      title: _ctx.computedDrawerTitle,
      node: _ctx.data.currentNode,
      width: "50vw",
      path: _ctx.computedNodePathString
    }, {
      default: _withCtx(() => [
        _createVNode(_component_org_unit_path, {
          nodePath: _ctx.computedNodePath,
          areaNames: _ctx.data.hierarchyNames
        }, null, 8, ["nodePath", "areaNames"]),
        _createVNode(_component_question_catalog_drawer, {
          "node-path": _ctx.computedNodePath,
          "area-names": _ctx.data.hierarchyNames,
          "update-function": _ctx.handleCatalogUpdate,
          "current-node": _ctx.data.currentNode,
          "org-chart-data": _ctx.data.orgChartData
        }, null, 8, ["node-path", "area-names", "update-function", "current-node", "org-chart-data"])
      ]),
      _: 1
    }, 8, ["title", "node", "path"])
  ]))
}