<template>
  <div>
    <button
      @click="openBarcodeModal"
      type="button"
      class="btn btn-ico btn-sm btn-light"
      data-bs-toggle="modal"
      data-bs-target="#barcodeModal"
    >
      <i class="fa fa-barcode" aria-hidden="true"></i>
      <span class="ms-1 me-2">{{ $t('fes.form.barcodeHint.button') }}</span>
    </button>

    <Teleport to="body">
      <div
        class="modal fade"
        tabindex="-1"
        id="barcodeModal"
        ref="barcodeModal"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div v-if="computedIsBarcodeModeActive">
                <div
                  class="alert bg-light-primary d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-3"
                >
                  <span class="svg-icon svg-icon-5tx svg-icon-primary mb-5">
                    <inline-svg
                      src="media/icons/duotune/ecommerce/ecm010.svg"
                    />
                  </span>
                  <!--begin::Content-->
                  <div class="text-center text-dark">
                    <h1 class="fw-bolder mb-5">
                      {{ $t('fes.form.barcodeHint.heading') }}
                    </h1>
                    <div
                      class="separator separator-dashed border-primary opacity-25 mb-5"
                    ></div>
                    <div class="mb-9">
                      {{ $t('fes.form.barcodeHint.subtext') }}
                    </div>
                  </div>
                  <!--end::Content-->
                </div>
                <button
                  class="btn btn-secondary w-100"
                  @click="enableManualMode"
                >
                  {{ $t('fes.barcodeModal.enableManualButton') }}
                </button>
              </div>
              <div v-else>
                <a
                  href="javascript:"
                  class="d-block mb-3"
                  @click="disableManualMode"
                >
                  <i class="fa fa-chevron-left"></i>
                  {{ $t('fes.form.back') }}</a
                >
                <form action="javascript:" class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.barcodeInput"
                  />
                  <button
                    class="btn btn-primary"
                    @click="handleBarcodeInput"
                    type="submit"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import ApiService from '@/core/services/ApiService';
  import { ErrorCase } from '@/core/models/fes';
  import { useRouter } from 'vue-router';
  import Swal from 'sweetalert2';
  import { useI18n } from 'vue-i18n';
  import { useKeypress } from 'vue3-keypress';
  import { hideModal } from '@/core/helpers/dom';

  export default defineComponent({
    name: 'BarcodeFilter',
    setup() {
      const store = useStore();
      const router = useRouter();
      const { t } = useI18n();

      const data: {
        testTimerStart: number;
        testTimerEnd: number;
        barcodeInput: string;
        manualInput: boolean;
      } = reactive({
        testTimerStart: 0,
        testTimerEnd: 0,
        barcodeInput: '',
        manualInput: false,
      });

      const barcodeModal = ref<null | HTMLElement>(null);

      const computedIsBarcodeModeActive = computed(() => {
        return data.manualInput === false;
      });

      const handleBarcodeInput = async () => {
        const barcodeInput = String(data.barcodeInput);
        if (barcodeInput.length < 5) return false;

        store.dispatch(Actions.START_LOADER);

        const foundErrorCaseResponse = await ApiService.get(
          'fes/errors/code/' + barcodeInput,
        );

        if (foundErrorCaseResponse.data) {
          hideModal(barcodeModal.value);
          const foundErrorCase = foundErrorCaseResponse.data as ErrorCase;
          router.push({
            name: 'fesErrorSingle',
            params: {
              id: foundErrorCase.id,
            },
          });
        } else {
          Swal.fire(t('error'), t('fes.form.errors.barcodeNotFound'), 'error');
        }

        store.dispatch(Actions.END_LOADER);
        data.barcodeInput = '';
      };

      const handleErrorCaseBarcodeInput = (event) => {
        if (data.manualInput === true) return false;

        const key = event.key;
        const keyCode = event.keyCode;

        if (typeof event.delegateTarget === 'undefined') {
          isBarcodeCodeScanActive.value = false;
          return false;
        }

        if (keyCode !== 13 && key.length !== 1) return true;

        if (keyCode === 13) {
          data.testTimerEnd = new Date().getTime();
          data.testTimerStart = 0;
          data.testTimerEnd = 0;
          handleBarcodeInput();
          return true;
        }

        if (data.barcodeInput.length === 0) {
          data.testTimerStart = new Date().getTime();
          setTimeout(() => {
            data.barcodeInput = '';
            isBarcodeCodeScanActive.value = false;
          }, 100);
        }

        data.barcodeInput += String.fromCharCode(keyCode);
        return true;
      };

      const isBarcodeCodeScanActive = ref(false);
      const onAnyKeyCallback = ({ event }) => {
        const preventDefault = handleErrorCaseBarcodeInput(event);
        if (preventDefault) event.preventDefault();
      };

      useKeypress({
        keyEvent: 'keydown',
        keyBinds: [],
        isActive: isBarcodeCodeScanActive,
        onAnyKey: onAnyKeyCallback,
      });

      function openBarcodeModal() {
        isBarcodeCodeScanActive.value = true;
      }

      function enableManualMode() {
        data.manualInput = true;
      }
      function disableManualMode() {
        data.manualInput = false;
      }

      return {
        computedIsBarcodeModeActive,
        openBarcodeModal,
        barcodeModal,
        enableManualMode,
        disableManualMode,
        handleBarcodeInput,
        data,
      };
    },
  });
</script>
