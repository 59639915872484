<template>
  <errorSingleView :id="props.id" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import errorSingleView from '../errors/FesErrorsSingle.vue';

  export default defineComponent({
    name: 'fesOplErrorSingleView',
    components: {
      errorSingleView,
    },
    props: {
      id: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      return {
        props,
      };
    },
  });
</script>

<style lang="scss">
  tbody tr {
    cursor: pointer;
  }
  img {
    max-height: 250px;
    max-width: 250px;
  }
</style>
