<template>
  <div
    class="d-flex justify-content-between align-items-center bg-lighten border px-5 py-5"
  >
    <div class="d-flex flex-column me-3">
      <span class="pb-2 fs-4 fw-bold">{{ question.name }}</span>
      <div class="d-flex">
        <div class="me-1">
          <span
            v-if="question.type === 'default'"
            class="badge badge-secondary"
            >{{ $t('spc.question.types.default') }}</span
          >
          <span
            v-if="question.type === 'mandatory'"
            class="badge badge-primary"
            >{{ $t('spc.question.types.mandatory') }}</span
          >
          <span v-if="question.type === 'pinned'" class="badge badge-info">{{
            $t('spc.question.types.pinned')
          }}</span>
        </div>
        <div class="me-1" v-if="computedAttachmentLength > 0">
          <span class="badge badge-secondary">
            <i class="fa fa-images fs-8 me-2"></i>
            {{ question.attachments.length }}
          </span>
        </div>
        <div class="me-1" v-if="question.externalLink">
          <span class="badge badge-light-primary">
            <i class="fa fa-link fs-8 text-primary me-2"></i>
          </span>
        </div>
        <div class="me-1" v-if="question.onlyFrom && question.onlyTo">
          <span class="badge badge-light-info">
            <i class="fa fa-clock fs-8 text-info me-2"></i>
            {{ computedOnlyDate(question.onlyFrom) }} -
            {{ computedOnlyDate(question.onlyTo) }}
          </span>
        </div>
        <div class="me-1" v-if="question.noneAllowed">
          <span class="badge badge-secondary">
            <i class="fa fa-clipboard-check fs-8 me-2"></i>
            {{ $t('spc.question.noneAllowed') }}</span
          >
        </div>
        <div class="me-1" v-if="question.openPointNeeded">
          <span class="badge badge-light-danger">
            <i class="fa fa-clipboard-check fs-8 me-2"></i>
            {{ $t('spc.question.openPointNeeded') }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex">
      <button
        type="button"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
        @click="editQuestion"
      >
        <span class="svg-icon svg-icon-1">
          <i class="fa fa-edit" aria-hidden="true"></i>
        </span>
      </button>
      <button
        v-if="computedIsAllowedToAdd"
        @click="handleConnectQuestion"
        type="button"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
      >
        <span class="svg-icon svg-icon-2">
          <i class="fa fa-plus"></i>
        </span>
      </button>
      <button
        v-if="computedIsAllowedToDisconnect"
        @click="handleDisconnectQuestion"
        type="button"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
      >
        <span class="svg-icon svg-icon-2">
          <i class="fa fa-minus"></i>
        </span>
      </button>
      <button
        v-if="computedIsAllowedToRankUp"
        @click="handleRankUpQuestion"
        type="button"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
      >
        <span class="svg-icon svg-icon-2">
          <i class="fa fa-arrow-up"></i>
        </span>
      </button>
      <button
        v-if="computedIsAllowedToRankDown"
        @click="handleRankDownQuestion"
        type="button"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
      >
        <span class="svg-icon svg-icon-2">
          <i class="fa fa-arrow-down"></i>
        </span>
      </button>
      <button
        v-if="computedIsAllowedToAdd"
        @click="handleConnectQuestionToAll"
        href="javascript:"
        class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
      >
        <span class="svg-icon svg-icon-2">
          <i class="fa fa-plus"></i>
        </span>
        <span class="position-absolute top-25 start-75 translate-middle"
          ><i class="fa fa-plus fs-9"></i
        ></span>
      </button>
      <button
        v-if="computedIsAllowedToDelete"
        @click="handleDeleteQuestion"
        type="button"
        class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
      >
        <span class="svg-icon svg-icon-1">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
  import { Question } from '@/core/models/spc';
  import moment from 'moment';
  import { computed, defineComponent, PropType, reactive } from 'vue';

  export default defineComponent({
    name: 'questionConfigContainer',
    components: {},
    emits: [
      'questionDeleteAtIndex',
      'questionConnect',
      'questionConnectToAll',
      'questionDisconnect',
      'questionRankUp',
      'questionRankDown',
      'editQuestion',
    ],
    props: {
      question: {
        type: Object as PropType<Question>,
        required: true,
      },
      appendAllowed: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      rankUpAllowed: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      rankDownAllowed: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      disconnectAllowed: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      deleteAllowed: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: true,
      },
    },
    setup(props, context) {
      // const { t } = useI18n();

      async function editQuestion() {
        context.emit('editQuestion', props.question);
      }

      const data: {
        question: Question;
      } = reactive({
        question: props.question,
      });

      const computedIsAllowedToAdd = computed(() => {
        return props.appendAllowed === true;
      });
      const computedIsAllowedToRankUp = computed(() => {
        return props.rankUpAllowed === true;
      });
      const computedIsAllowedToRankDown = computed(() => {
        return props.rankDownAllowed === true;
      });

      const computedIsAllowedToDisconnect = computed(() => {
        return props.disconnectAllowed === true;
      });

      const computedIsAllowedToDelete = computed(() => {
        return props.deleteAllowed === true;
      });

      async function handleDeleteQuestion() {
        context.emit('questionDeleteAtIndex', props.question);
      }

      async function handleDisconnectQuestion() {
        context.emit('questionDisconnect', props.question);
      }

      async function handleConnectQuestion() {
        context.emit('questionConnect', props.question);
      }
      async function handleConnectQuestionToAll() {
        context.emit('questionConnectToAll', props.question);
      }

      async function handleRankUpQuestion() {
        context.emit('questionRankUp', props.question);
      }
      async function handleRankDownQuestion() {
        context.emit('questionRankDown', props.question);
      }

      const computedOnlyDate = computed(() => {
        return (dayMonthString: string) => {
          const mmt = moment(dayMonthString, 'DDMM');
          return mmt.format('DD.MM.');
        };
      });

      const computedAttachmentLength = computed(() => {
        if (props.question === null) return 0;
        return props.question.attachments.length;
      });

      return {
        data,
        editQuestion,
        computedIsAllowedToAdd,
        computedIsAllowedToDelete,
        computedIsAllowedToDisconnect,
        handleDeleteQuestion,
        handleConnectQuestion,
        handleConnectQuestionToAll,
        handleRankUpQuestion,
        handleRankDownQuestion,
        handleDisconnectQuestion,
        computedIsAllowedToRankUp,
        computedIsAllowedToRankDown,
        computedOnlyDate,
        computedAttachmentLength,
      };
    },
  });
</script>
