import { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/Layout.vue';

import UserSettings from '@/views/user/UserSettings.vue';

export default {
  path: '/user',
  redirect: '/user/settings',
  component: DefaultLayout,
  meta: {
    app: 'User',
  },
  children: [
    // {
    //   path: '/cdf/dashboard',
    //   name: 'cdfDashboard',
    //   component: Dashboard,
    // },
    {
      path: '/user/settings',
      name: 'userSettings',
      component: UserSettings,
    },
  ],
} as RouteRecordRaw;
