<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">{{
            props.headline || $t('opl.form.heading')
          }}</span>
          <span class="text-muted fw-bold fs-7">{{
            $t('opl.form.subtext')
          }}</span>
        </h3>
      </div>
      <div class="card-body py-3">
        <div class="scroll-y me-n5 pe-5">
          <open-point-form
            v-for="(openPoint, index) of openPointFormData"
            :model-value="openPoint"
            :key="index"
            :name-prefix="`openPoints[${index}]`"
            @update:modelValue="handleOpenPointUpdate(index, $event)"
            @delete="removeOpenPoint(index)"
          />
        </div>
        <p class="py-5" v-if="!modelValue.length">
          {{ $t('opl.form.none') }}
        </p>
        <button
          type="button"
          class="btn btn-secondary mb-3"
          @click.prevent="addNewOpenPoint"
        >
          <i class="fa fa-plus"></i>
          {{ $t('opl.form.additional') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    OpenPoint,
    OpenPointCreator,
    OpenPointFormData,
    OpenPointState,
  } from '@/core/models/opl';
  import { useStore } from 'vuex';
  import { computed, onMounted } from 'vue';
  import { User } from '@/store/modules/AuthModule';
  import { pick } from '@/core/helpers/utils';
  import OpenPointForm from '@/components/opl/OpenPointForm.vue';

  const store = useStore();

  interface Props {
    modelValue: OpenPoint[];
    headline?: string;
    source?: string;
    sourceId?: string;
    openPointParent?: OpenPoint;
    addInitialValue?: boolean;
    childrenAllowed?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    addInitialValue: false,
    childrenAllowed: true,
    source: null,
    sourceId: null,
  });
  const emit = defineEmits(['update:modelValue']);

  const currentUser = computed<User>(() => store.getters.currentUser);
  const currentCreator = computed<OpenPointCreator>(() => ({
    userId: currentUser.value.id,
    name: currentUser.value.name,
    email: currentUser.value.mail,
  }));

  const openPointFormData = computed(() =>
    props.modelValue.map(formDataFromOpenPoint),
  );

  onMounted(() => {
    if (props.addInitialValue) {
      addNewOpenPoint();
    }
  });

  function formDataFromOpenPoint(openPoint: OpenPoint): OpenPointFormData {
    return {
      ...openPoint,
      assigneeOptions: {
        assignees: [],
        assigneeIds: [],
        assigneesToSelect: [],
        comment: '',
      },
      children: openPoint.children.map(formDataFromOpenPoint),
    };
  }

  function openPointFromFormData(formData: OpenPointFormData): OpenPoint {
    return {
      ...pick(formData, [
        'title',
        'state',
        'isUrgent',
        'isAccidentPrevention',
        'dueDate',
        'comment',
        'source',
        'sourceId',
        'initialAssignees',
        'creator',
        'watchers',
        'attachments',
        'locations',
        'parent',
      ]),
      externalId: null,
      assignee: null,
      children: formData.children.map(openPointFromFormData),
    } as OpenPoint;
  }

  function handleOpenPointUpdate(index: number, openPoint: OpenPointFormData) {
    const copy = props.modelValue.slice();
    copy.splice(index, 1, openPointFromFormData(openPoint));
    emit('update:modelValue', copy);
  }

  function addNewOpenPoint() {
    emit('update:modelValue', [
      ...props.modelValue,
      openPointFromFormData(createNewOpenPoint(props.openPointParent)),
    ]);
  }

  function removeOpenPoint(index: number) {
    const copy = props.modelValue.slice();
    copy.splice(index, 1);
    emit('update:modelValue', copy);
  }

  function createNewOpenPoint(parent?: OpenPoint): OpenPointFormData {
    const newOpenPoint: OpenPointFormData = {
      externalId: null,
      title: '',
      state: OpenPointState.OPEN,
      isUrgent: false,
      isAccidentPrevention: false,
      dueDate: '',
      comment: '',
      source: props.source || null,
      sourceId: props.sourceId || null,
      assignee: null,
      initialAssignees: [],
      additionalAssignees: [],
      creator: currentCreator.value,
      watchers: [],
      attachments: [],
      messages: [],
      createdAt: '',
      parent: null,
      locations: [],
      children: [],
      assigneeOptions: {
        assignees: [],
        assigneeIds: [],
        assigneesToSelect: [],
        comment: '',
      },
    };

    if (props.openPointParent) {
      newOpenPoint.locations = props.openPointParent.locations.map(
        (location) => ({ ...location, id: undefined }),
      );
    }
    if (parent) {
      newOpenPoint.parent = parent;
      newOpenPoint.locations = parent.locations.map((location) => ({
        ...location,
        id: undefined,
      }));
    }

    return newOpenPoint;
  }
</script>
