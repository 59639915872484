const DefaultMenuConfig = [
  // {
  //   route: '/',
  //   pages: [
  //     {
  //       heading: 'dashboard',
  //       route: '',
  //       svgIcon: 'media/icons/duotune/general/gen001.svg',
  //       fontIcon: 'bi-app-indicator',
  //     },
  //   ],
  // },
];

export default DefaultMenuConfig;
