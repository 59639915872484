<template>
  <div
    v-if="computedIsNodePathReady"
    class="alert alert-primary d-flex align-items-center p-5 mb-10"
  >
    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
      <inline-svg src="media/icons/duotune/maps/map003.svg" />
    </span>
    <div class="d-flex flex-column">
      <h4 class="mb-1 text-primary">
        {{ computedNodePathAreaTitle }}
      </h4>
      <div>
        <span v-for="(element, index) of computedNodePath" :key="element.id">
          <span v-if="index !== 0">
            <i class="fa fa-chevron-right mx-2"></i>
          </span>
          {{ element.name }}</span
        >
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
  import {
    HierarchyName,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/spc';
  import { computed, defineComponent, PropType } from 'vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'spcOrgUnitPath',
    components: {},
    props: {
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<HierarchyName[]>,
        required: true,
      },
      levelSubstract: {
        type: Number,
        default: 0,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const computedNodePathAreaTitle = computed(() => {
        const nodePath = props.nodePath.slice(0);
        const nodePathLength = nodePath.length;
        if (props.areaNames && props.areaNames.length) {
          const errorCaseCdfName = props.areaNames[nodePathLength - 1];
          if (errorCaseCdfName) {
            return errorCaseCdfName.name;
          }
        }
        return t('depth') + ' ' + (nodePathLength - props.levelSubstract);
      });

      const computedIsNodePathReady = computed(() => {
        return props.nodePath.length > 0;
      });

      const computedNodePath = computed(() => {
        if (props.nodePath.length > 0) {
          return props.nodePath;
        }
        return [];
      });

      // onMounted(() => {});

      return {
        computedNodePath,
        computedIsNodePathReady,
        computedNodePathAreaTitle,
      };
    },
  });
</script>
