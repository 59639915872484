<template>
  <user-multi-select
    create-enabled
    :modelValue="usersFromWatchers"
    @update:modelValue="handleUpdate"
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
  import { OpenPointWatcher } from '@/core/models/opl';
  import { computed, defineModel } from 'vue';
  import UserMultiSelect from '@/components/common/UserMultiSelect.vue';
  import { MinimalUser } from '@/store/modules/AuthModule';
  import { isEmail } from 'class-validator';

  const watchers = defineModel<OpenPointWatcher[]>({
    required: true,
    type: Array,
  });

  const usersFromWatchers = computed(() =>
    watchers.value.map<MinimalUser>((watcher) => ({
      id: watcher.userId,
      name: watcher.name,
      mail: watcher.email,
      watcherId: watcher.id,
    })),
  );

  function handleUpdate(newUsers: MinimalUser[]) {
    watchers.value = newUsers.map<OpenPointWatcher>((user) => ({
      id: user.watcherId,
      userId: user.id,
      name: user.name,
      email: user.mail,
    }));
  }

  // Allows adding emails without needing a user
  function handleCreate(user: MinimalUser) {
    if (!isEmail(user.name)) {
      return;
    }
    watchers.value.push({
      userId: null,
      name: null,
      email: user.name,
    });
  }
</script>
