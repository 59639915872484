import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-header border-0 pt-6" }
const _hoisted_5 = { class: "card-title align-items-start flex-column" }
const _hoisted_6 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_7 = { class: "fs-6 text-gray-400 fw-bold ms-1" }
const _hoisted_8 = { class: "card-toolbar" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "card-body pt-0" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = { class: "flex-grow-1" }
const _hoisted_13 = { class: "text-dark fw-bolder fs-6" }
const _hoisted_14 = ["onUpdate:modelValue", "onChange"]
const _hoisted_15 = { value: null }
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_18 = {
  key: 1,
  class: "badge badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t('allUsers')) + " ", 1),
              _createElementVNode("span", _hoisted_7, "(" + _toDisplayString(_ctx.data.users.length) + ")", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control form-control-solid",
              placeholder: _ctx.$t('spc.questionCatalog.searchUser'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.userSearch) = $event))
            }, null, 8, _hoisted_9), [
              [_vModelText, _ctx.data.userSearch]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.data.isLoaded === true)
            ? (_openBlock(), _createBlock(_component_KTDatatable, {
                key: _ctx.computedUsers.length,
                "table-data": _ctx.computedUsers,
                "table-header": _ctx.data.headerConfig,
                "enable-items-per-page-dropdown": true
              }, {
                "cell-name": _withCtx(({ row: user }) => [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(user.name), 1)
                    ])
                  ])
                ]),
                "cell-username": _withCtx(({ row: user }) => [
                  _createElementVNode("span", null, _toDisplayString(user.username), 1)
                ]),
                "cell-mail": _withCtx(({ row: user }) => [
                  _createElementVNode("span", null, _toDisplayString(user.mail), 1)
                ]),
                "cell-groups": _withCtx(({ row: user }) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.groups, (group) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: group.name,
                      class: "badge fs-8 fw-bolder badge-light-primary me-2"
                    }, _toDisplayString(group), 1))
                  }), 128))
                ]),
                "cell-managementLevel": _withCtx(({ row: user }) => [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": ($event: any) => ((user.managementLevel) = $event),
                    onChange: ($event: any) => (_ctx.updateUser(user))
                  }, [
                    _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t('none')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.managmentLevels, (managementLevel) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: managementLevel.id,
                        value: managementLevel
                      }, _toDisplayString(managementLevel.name), 9, _hoisted_16))
                    }), 128))
                  ], 40, _hoisted_14), [
                    [_vModelSelect, user.managementLevel]
                  ])
                ]),
                "cell-active": _withCtx(({ row: user }) => [
                  (user.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('state.active')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('state.inactive')), 1))
                ]),
                _: 1
              }, 8, ["table-data", "table-header"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}