<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div
        class="menu-content d-flex align-items-center justify-content-between px-3"
      >
        <!--begin::Username-->
        <div class="d-flex flex-column" v-if="hasCurrentUser">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ currentUser.name }}
          </div>
          <span class="fw-bold text-muted fs-7">{{
            currentUser.username
          }}</span>
          <div class="mt-2">
            <span v-if="computedShowManagementLevel">
              <span
                class="badge fs-8 fw-bolder badge-light-info me-2 text-truncate mw-225px"
              >
                {{
                  $t('spc.nav.managementLevel', {
                    level: computedUserManagementLevel.level.name,
                  })
                }}
              </span>
            </span>
          </div>
        </div>
        <router-link to="/user/settings">
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/icons/duotune/coding/cod001.svg" />
          </span>
        </router-link>
        <!--end::Username-->
      </div>
    </div>

    <div class="separator my-2"></div>

    <div
      class="menu-item px-5"
      v-for="(language, index) of computedCountries"
      :key="index"
    >
      <a
        href="javascript:"
        class="menu-link px-5"
        :class="{ active: currentLanguage(language.key) }"
        @click="setLang(language.key)"
      >
        <span class="menu-title position-relative">
          {{
            $t('language', 0, {
              locale: language.key,
            })
          }}
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
          >
            {{ language.name }}
            <img class="w-15px h-15px rounded-1 ms-2" :src="language.flag" />
          </span>
        </span>
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5"> Sign Out </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import ApiService from '@/core/services/ApiService';
  import { AxiosResponse } from 'axios';

  export default defineComponent({
    name: 'kt-user-menu',
    components: {},
    setup() {
      const router = useRouter();
      const i18n = useI18n();
      const store = useStore();

      const currentUser = store.getters.currentUser;
      const currentManagementLevel = store.getters.currentManagementLevel;

      const defaultLanguage = 'en';
      let currentUserLanguage = defaultLanguage;
      if (typeof currentUser.language !== 'undefined') {
        currentUserLanguage = currentUser.language;
      } else {
        const loadedLanguageFromClient = localStorage.getItem('lang');
        if (loadedLanguageFromClient !== null) {
          currentUserLanguage = loadedLanguageFromClient;
        }
      }

      i18n.locale.value = currentUserLanguage || defaultLanguage;

      const hasCurrentUser = computed(function () {
        return typeof currentUser.id !== 'undefined';
      });

      const computedCountries = computed(() => {
        return Object.keys(countries).map((countryKey: string) => {
          return {
            key: countryKey,
            ...countries[countryKey],
          };
        });
      });

      const countries = {
        en: {
          flag: 'media/flags/united-states.svg',
          name: 'English',
        },
        de: {
          flag: 'media/flags/germany.svg',
          name: 'German',
        },
        ro: {
          flag: 'media/flags/romania.svg',
          name: 'Romanian',
        },
      };

      const signOut = async () => {
        store.dispatch(Actions.START_LOADER);
        const logoutUrlResponse: AxiosResponse<{
          logoutUrl: string;
        }> = await ApiService.get('/auth/logout');
        // const idToken = idTokenResponse.data.id_token;
        // const postRedirectUri = 'http://localhost:3000';

        const redirectUri = logoutUrlResponse.data.logoutUrl;

        window.open(
          redirectUri,
          '_blank',
          'popup=true,width=400,height=400,left=100,top=100',
        );

        await store.dispatch(Actions.LOGOUT);

        setTimeout(() => {
          store.dispatch(Actions.END_LOADER);
          window.location.href = `${ApiService.vueInstance.axios.defaults.baseURL}/auth/login`;
        }, 500);
      };

      const setLang = (lang) => {
        localStorage.setItem('lang', lang);
        store.commit(Mutations.SET_LANGUAGE, lang);
        store.dispatch(Actions.UPDATE_USER, {
          language: lang,
        });
        i18n.locale.value = lang;
      };

      const currentLanguage = (lang) => {
        return i18n.locale.value === lang;
      };

      const currentLangugeLocale = computed(() => {
        return countries[i18n.locale.value];
      });

      const getReadableGroupName = (group) => {
        const unitSplit: string[] = group.split(',');
        const groupUnit = unitSplit.shift();
        const groupUnitSplit = groupUnit?.split('=');
        return groupUnitSplit?.pop();
      };

      const computedUserHasManagementLevel = computed(() => {
        if (currentManagementLevel === null) return false;
        return typeof currentManagementLevel.id !== 'undefined';
      });

      const computedShowManagementLevel = computed(() => {
        if (computedUserHasManagementLevel.value === false) return false;
        const currentRoute = router.currentRoute;
        if (
          typeof currentRoute.value.meta !== 'undefined' &&
          typeof currentRoute.value.meta.app !== 'undefined'
        ) {
          if (currentRoute.value.meta.app !== 'SPC') return false;
        }
        return true;
      });

      const computedUserManagementLevel = computed(() => {
        return currentManagementLevel;
      });

      return {
        signOut,
        setLang,
        currentLanguage,
        currentLangugeLocale,
        countries,
        currentUser,
        hasCurrentUser,
        getReadableGroupName,
        computedCountries,
        computedUserHasManagementLevel,
        computedUserManagementLevel,
        computedShowManagementLevel,
      };
    },
  });
</script>
