<template>
  <div class="d-flex flex-stack pt-10 px-9">
    <!--begin::Wrapper-->
    <div class="mr-2">
      <button
        v-if="backAllowed"
        type="button"
        class="btn btn-lg btn-light-primary me-3"
        @click="prevStepFunction"
      >
        <span class="svg-icon svg-icon-4 me-1">
          <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
        </span>
        {{ $t('fes.form.back') }}
      </button>
    </div>
    <!--end::Wrapper-->

    <!--begin::Wrapper-->
    <div>
      <button
        v-if="computedNextAllowed"
        type="submit"
        class="btn btn-lg btn-primary"
      >
        {{ $t('fes.form.next') }}
        <span class="svg-icon svg-icon-4 ms-1 me-0">
          <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
        </span>
      </button>
    </div>
    <!--end::Wrapper-->
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted } from 'vue';

  export default defineComponent({
    name: 'ErrorFormStepNavigation',
    components: {},
    props: {
      backAllowed: {
        type: Boolean,
        default: false,
      },
      nextAllowed: {
        type: Boolean,
        default: true,
      },
      prevStepFunction: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      onMounted(() => {
        // const store = useStore();
        // const currentUser = reactive({ ...store.getters.currentUser });
      });

      const computedNextAllowed = computed(() => {
        return props.nextAllowed;
      });

      return {
        ...props,
        computedNextAllowed,
      };
    },
  });
</script>
