<template>
  <div :class="props.class">
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestProcessInterruptionCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritProcessInterruptionCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestProcessInterruptionCatalogPath" />
      </div>
    </div>
    <div
      class="row"
      v-if="!data.currentNode || !data.currentNode.processInterruptionCatalog"
    >
      <a
        class="col d-flex justify-content-start align-items-center"
        href="javascript:"
        @click="openProcessInterruptionCatalog"
        :title="$t('fes.config.linkButtonNew')"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-2"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualProcessInterruptionCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualProcessInterruptionCatalog') }}
          </h4>
          <span
            >{{ $t('fes.config.processInterruptionCatalog') }} #{{
              data.currentNode.processInterruptionCatalog.id
            }}
            vom
            {{
              computedDate(
                data.currentNode.processInterruptionCatalog.createdAt,
              )
            }}
            mit
            {{
              data.currentNode.processInterruptionCatalog.subSubCategories
                .length
            }}
            vefügbaren Prozessunterbrechungen</span
          >
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openProcessInterruptionCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteProcessInterruptionCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerProcessInterruptionCatalog"
      :title="$t('fes.config.processInterruptionCatalog')"
      width="60vw"
    >
      <org-unit-path :nodePath="props.nodePath" :areaNames="props.areaNames" />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestProcessInterruptionCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritProcessInterruptionCatalog') }}
          </h4>
          <cdf-path
            :node-path="computedNearestProcessInterruptionCatalogPath"
          />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestProcessInterruptionCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h4 class="mb-5">
        {{ $t('fes.config.allProcessInterruptionsInCatalog') }}
        {{ computedNodePathLastElement }}
      </h4>
      <p v-if="!computedCatalogHasSubCategories" class="my-5">
        {{ $t('fes.config.noProcessInterruptionAssigned') }}
      </p>

      <div v-if="computedCatalogHasSubCategories">
        <div
          class="mb-3 d-flex align-items-sm-center border p-3"
          v-for="category of computedCurrentCatalogCategories"
          :key="category.id"
        >
          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <span class="text-gray-800 fs-5 fw-bolder">{{
                category.name
              }}</span
              ><span class="text-muted fw-bold d-block fs-7"
                >{{ category.category.name }}
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                {{ category.subCategory.name }}
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                {{ category.name }}</span
              >
            </div>
            <div>
              <a
                href="javascript:"
                class="btn btn-sm btn-icon btn-light-primary me-2"
                @click="disconnectSubSubCategoryWithCatalog(category)"
                :title="$t('fes.config.disconnect')"
              >
                <span class="svg-icon svg-icon-2">
                  <i class="fa fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-10" />

      <h4>{{ $t('fes.config.selectProcessInterruptionCatalogHeadline') }}</h4>
      <div v-if="data.categories.length">
        <div
          class="mb-4"
          v-for="(category, index) of computedCurrentCategories"
          :key="index"
        >
          <MyForm
            @submit="handleCategoryUpdate(category)"
            :validation-schema="nameValidator"
          >
            <div class="row d-flex mb-2 align-items-center">
              <div class="col-9">
                <a
                  href="javascript:"
                  @click="
                    category.showSubCategories = !category.showSubCategories
                  "
                  class="fs-3 text-dark d-block"
                  v-if="category.edit === false"
                  ><i
                    v-if="category.showSubCategories"
                    class="fa fa-chevron-up"
                    aria-hidden="true"
                  ></i
                  ><i v-else class="fa fa-chevron-down" aria-hidden="true"></i>
                  {{ category.name }}</a
                >
                <div v-else>
                  <Field
                    type="text"
                    class="form-control form-control-sm form-control-solid border border-primary"
                    name="name"
                    v-model="category.name"
                    :key="index"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="category.edit === false" class="col-3 text-end">
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                  @click="editCategory(category)"
                  :title="$t('edit')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-edit"></i>
                  </span>
                </a>
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                  @click="deleteCategory(category)"
                  :title="$t('remove')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-trash"></i>
                  </span>
                </a>
              </div>
              <div v-else class="col-3 text-end">
                <button
                  type="submit"
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                  :title="$t('save')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-save"></i>
                  </span>
                </button>
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                  @click="discardCategoryEdit(category)"
                  :title="$t('cancel')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-times"></i>
                  </span>
                </a>
              </div>
            </div>
          </MyForm>

          <div
            class="px-10 py-5 bg-light-info"
            v-show="category.id > 0 && category.showSubCategories === true"
          >
            <div v-if="category.subCategories.length > 0">
              <div
                v-for="(subCategory, subIndex) of category.subCategories"
                :key="subIndex"
              >
                <MyForm
                  @submit="handleCategoryUpdate(category)"
                  :validation-schema="nameValidator"
                >
                  <div class="row d-flex mb-2 align-items-center">
                    <div class="col">
                      <a
                        href="javascript:"
                        @click="
                          subCategory.showSubCategories =
                            !subCategory.showSubCategories
                        "
                        class="fs-4 text-dark d-block"
                        v-if="subCategory.edit === false"
                        ><i
                          v-if="subCategory.showSubCategories"
                          class="fa fa-chevron-up"
                          aria-hidden="true"
                        ></i
                        ><i
                          v-else
                          class="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                        {{ subCategory.name }}</a
                      >

                      <div v-else>
                        <Field
                          type="text"
                          class="form-control form-control-sm form-control-solid border border-primary"
                          name="name"
                          v-model="subCategory.name"
                          :key="subIndex"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="name" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="subCategory.edit === false" class="col text-end">
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        @click="connectSubCategoryWithCatalog(subCategory)"
                        :title="$t('fes.config.connect')"
                        v-if="subCategory.subSubCategories.length"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-plus"></i>
                        </span>
                      </a>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        @click="editCategory(subCategory)"
                        :title="$t('edit')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-edit"></i>
                        </span>
                      </a>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-danger"
                        @click="deleteSubCategory(subCategory)"
                        :title="$t('remove')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-trash"></i>
                        </span>
                      </a>
                    </div>
                    <div v-else class="col text-end">
                      <button
                        type="button"
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        :title="$t('save')"
                        @click="saveSubCategory(subCategory)"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-save"></i>
                        </span>
                      </button>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-danger"
                        @click="discardSubCategoryEdit(category, subCategory)"
                        :title="$t('cancel')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-times"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </MyForm>
                <div
                  class="px-10 py-5 bg-white mb-4"
                  v-if="
                    subCategory.id > 0 && subCategory.showSubCategories === true
                  "
                >
                  <div
                    v-if="
                      subCategory.subSubCategories &&
                      subCategory.subSubCategories.length > 0
                    "
                  >
                    <div
                      v-for="(
                        subSubCategory, subSubIndex
                      ) of subCategory.subSubCategories"
                      :key="subSubIndex"
                    >
                      <MyForm
                        class="row d-flex mb-1 align-items-center"
                        @submit="handleCategoryUpdate(category)"
                        :validation-schema="nameValidator"
                      >
                        <div class="col">
                          <span v-if="subSubCategory.edit === false">{{
                            subSubCategory.name
                          }}</span>
                          <div v-else>
                            <Field
                              type="text"
                              class="form-control form-control-sm form-control-solid border border-primary"
                              name="name"
                              v-model="subSubCategory.name"
                              :key="subSubIndex"
                            />
                            <div class="fv-plugins-message-container">
                              <div class="fv-help-block">
                                <ErrorMessage name="name" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="subSubCategory.edit === false"
                          class="col text-end"
                        >
                          <a
                            v-if="isSelectedSubSubCategory(subSubCategory)"
                            href="javascript:"
                            class="btn btn-sm btn-icon btn-light-primary me-2"
                            @click="
                              connectSubSubCategoryWithCatalog(subSubCategory)
                            "
                            :title="$t('fes.config.connect')"
                          >
                            <span class="svg-icon svg-icon-2">
                              <i class="fa fa-plus"></i>
                            </span>
                          </a>
                          <a
                            href="javascript:"
                            class="btn btn-sm btn-icon btn-light-primary me-2"
                            @click="editCategory(subSubCategory)"
                            :title="$t('edit')"
                          >
                            <span class="svg-icon svg-icon-2">
                              <i class="fa fa-edit"></i>
                            </span>
                          </a>
                          <a
                            href="javascript:"
                            class="btn btn-sm btn-icon btn-light-danger"
                            @click="deleteSubSubCategory(subSubCategory)"
                            :title="$t('remove')"
                          >
                            <span class="svg-icon svg-icon-2">
                              <i class="fa fa-trash"></i>
                            </span>
                          </a>
                        </div>
                        <div v-else class="col text-end">
                          <button
                            type="button"
                            href="javascript:"
                            class="btn btn-sm btn-icon btn-light-primary me-2"
                            :title="$t('save')"
                            @click="saveSubSubCategory(subSubCategory)"
                          >
                            <span class="svg-icon svg-icon-2">
                              <i class="fa fa-save"></i>
                            </span>
                          </button>
                          <a
                            href="javascript:"
                            class="btn btn-sm btn-icon btn-light-danger"
                            @click="
                              discardSubSubCategoryEdit(
                                subCategory,
                                subSubCategory,
                              )
                            "
                            :title="$t('cancel')"
                          >
                            <span class="svg-icon svg-icon-2">
                              <i class="fa fa-times"></i>
                            </span>
                          </a>
                        </div>
                      </MyForm>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <button
                        class="btn btn-secondary btn-sm"
                        @click="addNewSubSubCategory(subCategory)"
                        :disabled="computedHasSubCategoryEditsOpen(subCategory)"
                      >
                        <i class="fa fa-plus"></i>
                        {{ $t('fes.form.processInterruption.addSubCategory') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="addNewSubCategory(category)"
                  :disabled="computedHasCategoryEditsOpen(category)"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('fes.form.processInterruption.addSubCategory') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-secondary mb-10"
            @click="addNewCategory"
            :disabled="computedIsCategoryAddOpen"
          >
            <i class="fa fa-plus"></i>
            {{ $t('fes.form.processInterruption.addCategory') }}
          </button>
        </div>
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from '../fes/OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ProcessInterruptionCategory,
    ProcessInterruptionCategoryWithForm,
    ProcessInterruptionCatalog,
    ProcessInterruptionSubCategory,
    ProcessInterruptionSubCategoryWithForm,
    ProcessInterruptionSubSubCategory,
    ProcessInterruptionSubSubCategoryWithForm,
  } from '@/core/models/fes';

  export default defineComponent({
    name: 'processInterruptionCatalogDrawer',
    props: {
      class: {
        type: String,
        required: false,
      },
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs | null>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      // SimpleModal,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    computed: {
      // computedTitle() {
      //   return this.title;
      // },
      // computedWidth() {
      //   return this.width || '700px';
      // },
      // computedId() {
      //   return this.id;
      // },
      // computedPath() {
      //   return this.path || '';
      // },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs | null;
        categories: ProcessInterruptionCategory[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        categories: [] as ProcessInterruptionCategory[],
      });

      const computedNodePathAreaTitle = computed(() => {
        const nodePath = props.nodePath.slice(1);
        const nodePathLength = nodePath.length;
        const errorCaseCdfName = props.areaNames[nodePathLength - 1];
        if (errorCaseCdfName) {
          return errorCaseCdfName.name;
        }
        return '';
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const computedCatalogHasSubCategories = computed(() => {
        return (
          data.currentNode !== null &&
          data.currentNode.processInterruptionCatalog !== null &&
          typeof data.currentNode.processInterruptionCatalog
            .subSubCategories !== 'undefined' &&
          data.currentNode.processInterruptionCatalog.subSubCategories
            .length !== 0
        );
      });

      const computedCurrentCatalogCategories = computed(() => {
        if (!computedCatalogHasSubCategories.value) return [];

        const allSubCategories: ProcessInterruptionSubCategory[] = [];
        computedCurrentCategories.value.forEach(
          (processInterruptionCategory: ProcessInterruptionCategory) => {
            return processInterruptionCategory.subCategories.forEach(
              (subCategory: ProcessInterruptionSubCategory) => {
                allSubCategories.push(subCategory);
              },
            );
          },
        );

        return data.currentNode?.processInterruptionCatalog?.subSubCategories.map(
          (currentSubSubCategory: ProcessInterruptionSubSubCategory) => {
            const foundSubCategory = allSubCategories.find(
              (subCategory: ProcessInterruptionSubCategory) => {
                return (
                  subCategory.subSubCategories.findIndex(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      return currentSubSubCategory.id === subSubCategory.id;
                    },
                  ) !== -1
                );
              },
            );

            return {
              ...currentSubSubCategory,
              subCategory: {
                name: foundSubCategory?.name,
              },
              category: {
                name: foundSubCategory?.parent.name,
              },
            };
          },
        );

        // const resultArray: ProcessInterruptionCategory[] = [];
        // const resultArray: ProcessInterruptionCategory[] =
        //   computedCurrentCategories.value.map(
        //     (
        //       category: ProcessInterruptionCategory,
        //     ): ProcessInterruptionCategory => {
        //       const listCategory = Object.assign(
        //         {},
        //         category,
        //       ) as ProcessInterruptionCategory;
        //       listCategory.subCategories = listCategory.subCategories.filter(
        //         (subCategory: ProcessInterruptionSubCategory) => {
        //           return (
        //             computedCurrentCatalogSubSubCategories.value.findIndex(
        //               (
        //                 selectedSubSubCategory: ProcessInterruptionSubSubCategory,
        //               ) => {
        //                 return selectedSubSubCategory.id === subCategory.id;
        //               },
        //             ) !== -1
        //           );
        //         },
        //       );
        //       return listCategory;
        //     },
        //   );

        // return resultArray.filter((category: ProcessInterruptionCategory) => {
        //   return category.subCategories.length !== 0;
        // });
      });

      const computedCurrentCatalogSubSubCategories = computed(() => {
        if (data.currentNode === null) return [];
        if (data.currentNode.processInterruptionCatalog === null) return [];
        return data.currentNode.processInterruptionCatalog.subSubCategories;
      });

      const computedCurrentCategories = computed(() => {
        return data.categories;
      });

      const openProcessInterruptionCatalog = () => {
        openSideDrawer('fesConfigDrawerProcessInterruptionCatalog');
      };

      function isSelectedSubSubCategory(subSubCategory) {
        return (
          computedCurrentCatalogSubSubCategories.value.findIndex(
            (selectedSubSubCategory: ProcessInterruptionSubSubCategory) => {
              return selectedSubSubCategory.id === subSubCategory.id;
            },
          ) === -1
        );
      }

      function connectSubSubCategoryWithCatalog(
        subSubCategory: ProcessInterruptionSubSubCategoryWithForm,
      ) {
        if (data.currentNode === null) return;
        const newSubSubCategory: ProcessInterruptionSubSubCategory =
          Object.assign({}, subSubCategory);
        if (data.currentNode.processInterruptionCatalog === null) {
          data.currentNode.processInterruptionCatalog = {
            orgUnitId: data.currentNode.id,
            subSubCategories: [] as ProcessInterruptionSubSubCategory[],
          } as ProcessInterruptionCatalog;
        }
        data.currentNode.processInterruptionCatalog.subSubCategories.push(
          newSubSubCategory,
        );
        updateCurrentProcessInterruptionCatalog();
      }

      function connectSubCategoryWithCatalog(
        subCategory: ProcessInterruptionSubCategoryWithForm,
      ) {
        subCategory.subSubCategories.forEach((subSubCategory) => {
          if (data.currentNode === null) return;
          const newSubSubCategory: ProcessInterruptionSubSubCategory =
            Object.assign({}, subSubCategory);
          if (data.currentNode.processInterruptionCatalog === null) {
            data.currentNode.processInterruptionCatalog = {
              orgUnitId: data.currentNode.id,
              subSubCategories: [] as ProcessInterruptionSubSubCategory[],
            } as ProcessInterruptionCatalog;
          }
          data.currentNode.processInterruptionCatalog.subSubCategories.push(
            newSubSubCategory,
          );
        });

        updateCurrentProcessInterruptionCatalog();
      }

      function editCategory(category: ProcessInterruptionCategoryWithForm) {
        category.edit = true;
        category.name = category.nameBefore;
      }

      function discardCategoryEdit(
        category: ProcessInterruptionCategoryWithForm,
      ) {
        if (!category.id) return data.categories.pop();
        category.edit = false;
        category.name = category.nameBefore;
      }

      function discardSubCategoryEdit(
        category: ProcessInterruptionCategoryWithForm,
        subCategory: ProcessInterruptionSubCategoryWithForm,
      ) {
        if (!subCategory.id) return category.subCategories.pop();
        subCategory.edit = false;
        subCategory.name = subCategory.nameBefore;
      }

      function discardSubSubCategoryEdit(
        subCategory: ProcessInterruptionSubCategoryWithForm,
        subSubCategory: ProcessInterruptionSubSubCategoryWithForm,
      ) {
        if (!subSubCategory.id) return subCategory.subSubCategories.pop();
        subSubCategory.edit = false;
        subSubCategory.name = subSubCategory.nameBefore;
      }

      async function deleteSubCategory(
        subCategory: ProcessInterruptionSubCategoryWithForm,
      ) {
        const html = [
          t('fes.config.deleteProcessInterruptionSubCategoryQuestion'),
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const subCategoryId = subCategory.id;

        await ApiService.delete(
          `fes/config/processinterruption/subcategory/${subCategoryId}`,
        );

        data.categories = data.categories.map(
          (category: ProcessInterruptionCategory) => {
            category.subCategories = category.subCategories.filter(
              (categoryTmp1: ProcessInterruptionSubCategory) => {
                return categoryTmp1.id !== subCategoryId;
              },
            );
            return category;
          },
        );

        // data.orgChartData = await Promise.all(
        //   data.orgChartData.map(
        //     async (orgUnit: OrgChartDataPairWithCatalogs) => {
        //       if (
        //         orgUnit.decisionCatalog !== null &&
        //         typeof orgUnit.decisionCatalog.decisions !== 'undefined'
        //       ) {
        //         const filteredDecisions =
        //           orgUnit.decisionCatalog.decisions.filter(
        //             (decisionTmp2: ErrorCaseDecision) =>
        //               decisionTmp2.id !== decisionId,
        //           );

        //         if (
        //           filteredDecisions.length === 0 &&
        //           orgUnit.decisionCatalog.id
        //         ) {
        //           await deleteDecisionCatalog(orgUnit.decisionCatalog.id);
        //           orgUnit.decisionCatalog = null;
        //           return orgUnit;
        //         }
        //         orgUnit.decisionCatalog.decisions = filteredDecisions;
        //       }
        //       return orgUnit;
        //     },
        //   ),
        // );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function deleteSubSubCategory(
        subSubCategory: ProcessInterruptionSubSubCategoryWithForm,
      ) {
        const html = [
          t('fes.config.deleteProcessInterruptionSubCategoryQuestion'),
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const subSubCategoryId = subSubCategory.id;

        await ApiService.delete(
          `fes/config/processinterruption/subsubcategory/${subSubCategoryId}`,
        );

        data.categories = data.categories.map(
          (category: ProcessInterruptionCategory) => {
            category.subCategories = category.subCategories.map(
              (subCategory: ProcessInterruptionSubCategory) => {
                subCategory.subSubCategories =
                  subCategory.subSubCategories.filter(
                    (subSubCategoryTmp: ProcessInterruptionSubSubCategory) => {
                      return subSubCategoryTmp.id !== subSubCategoryId;
                    },
                  );

                return subCategory;
              },
            );
            return category;
          },
        );

        // data.orgChartData = await Promise.all(
        //   data.orgChartData.map(
        //     async (orgUnit: OrgChartDataPairWithCatalogs) => {
        //       if (
        //         orgUnit.decisionCatalog !== null &&
        //         typeof orgUnit.decisionCatalog.decisions !== 'undefined'
        //       ) {
        //         const filteredDecisions =
        //           orgUnit.decisionCatalog.decisions.filter(
        //             (decisionTmp2: ErrorCaseDecision) =>
        //               decisionTmp2.id !== decisionId,
        //           );

        //         if (
        //           filteredDecisions.length === 0 &&
        //           orgUnit.decisionCatalog.id
        //         ) {
        //           await deleteDecisionCatalog(orgUnit.decisionCatalog.id);
        //           orgUnit.decisionCatalog = null;
        //           return orgUnit;
        //         }
        //         orgUnit.decisionCatalog.decisions = filteredDecisions;
        //       }
        //       return orgUnit;
        //     },
        //   ),
        // );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function deleteCategory(
        category: ProcessInterruptionCategoryWithForm,
      ) {
        const processInterruptionCategoriesInTree = data.orgChartData
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.processInterruptionCatalog === null) return false;
            const catalog = orgUnit.processInterruptionCatalog;

            return catalog.subSubCategories.find(
              (item) => item.id === category.id,
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (processInterruptionCategoriesInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: processInterruptionCategoriesInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            processInterruptionCategoriesInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteProcessInterruptionCategoryQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        // const html = [
        //   t('fes.config.deleteProcessInterruptionCategoryQuestion'),
        // ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const categoryId = category.id;

        await ApiService.delete(
          `fes/config/processinterruption/category/${categoryId}`,
        );

        data.categories = data.categories.filter(
          (categoryTmp1: ProcessInterruptionCategory) => {
            return categoryTmp1.id !== categoryId;
          },
        );

        // data.orgChartData = await Promise.all(
        //   data.orgChartData.map(
        //     async (orgUnit: OrgChartDataPairWithCatalogs) => {
        //       if (
        //         orgUnit.decisionCatalog !== null &&
        //         typeof orgUnit.decisionCatalog.decisions !== 'undefined'
        //       ) {
        //         const filteredDecisions =
        //           orgUnit.decisionCatalog.decisions.filter(
        //             (decisionTmp2: ErrorCaseDecision) =>
        //               decisionTmp2.id !== decisionId,
        //           );

        //         if (
        //           filteredDecisions.length === 0 &&
        //           orgUnit.decisionCatalog.id
        //         ) {
        //           await deleteDecisionCatalog(orgUnit.decisionCatalog.id);
        //           orgUnit.decisionCatalog = null;
        //           return orgUnit;
        //         }
        //         orgUnit.decisionCatalog.decisions = filteredDecisions;
        //       }
        //       return orgUnit;
        //     },
        //   ),
        // );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteProcessInterruptionCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.processInterruptionCatalog === null) return;
        store.dispatch(Actions.START_LOADER);
        // const processInterruptionCatalogId =
        //   data.currentNode.processInterruptionCatalog.id;
        const processInterruptionCatalogId =
          id !== 0 ? id : data.currentNode.processInterruptionCatalog.id;

        await ApiService.delete(
          `fes/config/catalog/processinterruption/${processInterruptionCatalogId}`,
        );

        data.currentNode.processInterruptionCatalog = null;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteProcessInterruptionCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.processInterruptionCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteProcessInterruptionCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteProcessInterruptionCatalog(id);
      }

      async function disconnectSubSubCategoryWithCatalog(
        subCategory: ProcessInterruptionSubCategory,
      ) {
        if (data.currentNode === null) return;
        if (data.currentNode.processInterruptionCatalog !== null) {
          const filteredSubCategories =
            data.currentNode.processInterruptionCatalog?.subSubCategories.filter(
              (subCategoryTmp: ProcessInterruptionSubSubCategory) =>
                subCategoryTmp.id !== subCategory.id,
            );

          if (filteredSubCategories.length === 0) {
            return await deleteProcessInterruptionCatalog();
          }
          data.currentNode.processInterruptionCatalog.subSubCategories =
            filteredSubCategories;
          updateCurrentProcessInterruptionCatalog();
        }
      }

      function addNewCategory() {
        const newCategory: ProcessInterruptionCategoryWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
          subCategories: [] as ProcessInterruptionSubCategory[],
          showSubCategories: false,
        } as ProcessInterruptionCategoryWithForm;
        newCategory.name = '';
        data.categories.push(newCategory);
      }
      function addNewSubCategory(parentCategory: ProcessInterruptionCategory) {
        const newSubCategory: ProcessInterruptionSubCategoryWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
          subSubCategories: [] as ProcessInterruptionSubSubCategory[],
          showSubCategories: true,
          parent: {
            id: parentCategory.id,
            name: parentCategory.name,
          },
        } as ProcessInterruptionSubCategoryWithForm;
        newSubCategory.name = '';
        parentCategory.subCategories.push(newSubCategory);
      }
      function addNewSubSubCategory(
        parentCategory: ProcessInterruptionSubCategory,
      ) {
        const newSubSubCategory: ProcessInterruptionSubSubCategoryWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
          parent: {
            id: parentCategory.id,
            name: parentCategory.name,
          },
        } as ProcessInterruptionSubSubCategoryWithForm;
        newSubSubCategory.name = '';
        parentCategory.subSubCategories.push(newSubSubCategory);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function updateCurrentProcessInterruptionCatalog() {
        if (data.currentNode === null) return;
        if (
          data.currentNode.processInterruptionCatalog !== null &&
          data.currentNode.processInterruptionCatalog?.subSubCategories
            .length === 0
        ) {
          return Swal.fire(
            'Error',
            'Please add at least one process interruption.',
            'error',
          );
        }

        store.dispatch(Actions.START_LOADER);

        const processInterruptionCatalog: ProcessInterruptionCatalog =
          Object.assign({}, data.currentNode.processInterruptionCatalog);

        const newSavedProcessInterruptionCatalog = await ApiService.post(
          'fes/config/catalog/processinterruption',
          {
            data: processInterruptionCatalog,
          },
        );

        data.currentNode.processInterruptionCatalog =
          newSavedProcessInterruptionCatalog.data;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedCurrentCatalogDecisions = computed(() => {
        if (data.currentNode === null) return [];
        if (data.currentNode.decisionCatalog === null) return [];
        return data.currentNode.decisionCatalog.decisions;
      });

      async function updateAvailableProcessInterruptionCategories() {
        const loadedCategories = await ApiService.get(
          'fes/config/processinterruption/categories',
        );

        data.categories = loadedCategories.data
          .map((category: ProcessInterruptionCategory) => {
            const categoryWithForm: ProcessInterruptionCategoryWithForm = {
              id: category.id,
              name: category.name,
              nameBefore: category.name,
              showSubCategories: false,
              edit: false,
              subCategories: category.subCategories.map(
                (subCategory: ProcessInterruptionSubCategory) => {
                  return {
                    ...subCategory,
                    nameBefore: subCategory.name,
                    showSubCategories: true,
                    edit: false,
                    parent: category,
                    subSubCategories: subCategory.subSubCategories.map(
                      (subSubCategory: ProcessInterruptionSubSubCategory) => {
                        return {
                          ...subSubCategory,
                          nameBefore: subSubCategory.name,
                          edit: false,
                          parent: subCategory,
                        } as ProcessInterruptionSubSubCategoryWithForm;
                      },
                    ) as ProcessInterruptionSubSubCategoryWithForm[],
                  } as ProcessInterruptionSubCategoryWithForm;
                },
              ) as ProcessInterruptionSubCategoryWithForm[],
            } as ProcessInterruptionCategoryWithForm;
            return categoryWithForm;
          })
          .map((category: ProcessInterruptionCategory) => {
            category.subCategories.forEach(
              (subCategory: ProcessInterruptionSubCategory) => {
                subCategory.subSubCategories.sort(
                  (
                    categoryA: ProcessInterruptionSubSubCategory,
                    categoryB: ProcessInterruptionSubSubCategory,
                  ) => {
                    if (categoryA.name < categoryB.name) {
                      return -1;
                    }
                    if (categoryA.name > categoryB.name) {
                      return 1;
                    }
                    return 0;
                  },
                );
                return subCategory;
              },
            );

            category.subCategories.sort(
              (
                categoryA: ProcessInterruptionSubCategory,
                categoryB: ProcessInterruptionSubCategory,
              ) => {
                if (categoryA.name < categoryB.name) {
                  return -1;
                }
                if (categoryA.name > categoryB.name) {
                  return 1;
                }
                return 0;
              },
            );

            return category;
          })
          .sort(
            (
              categoryA: ProcessInterruptionCategory,
              categoryB: ProcessInterruptionCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
      }

      async function handleCategoryUpdate(
        category: ProcessInterruptionCategoryWithForm,
      ) {
        store.dispatch(Actions.START_LOADER);
        category.edit = false;
        category.nameBefore = category.name;

        const categoryUpdateObject: ProcessInterruptionCategory = {
          name: category.name,
        } as ProcessInterruptionCategory;

        if (category.id) {
          categoryUpdateObject.id = category.id;
        }

        const savedProcessInterruptionCategory: AxiosResponse<ProcessInterruptionCategory> =
          await ApiService.post('fes/config/processinterruption/category', {
            data: categoryUpdateObject,
          });

        if (!category.id) {
          category.id = savedProcessInterruptionCategory.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      async function saveSubCategory(
        subCategory: ProcessInterruptionSubCategoryWithForm,
      ) {
        subCategory.edit = false;
        subCategory.nameBefore = subCategory.name;

        store.dispatch(Actions.START_LOADER);
        subCategory.edit = false;
        subCategory.nameBefore = subCategory.name;

        const subCategoryUpdateObject: ProcessInterruptionSubCategory = {
          name: subCategory.name,
          parent: {
            id: subCategory.parent.id,
          },
        } as ProcessInterruptionSubCategory;

        if (subCategory.id) {
          subCategoryUpdateObject.id = subCategory.id;
        }

        const savedProcessInterruptionSubCategory: AxiosResponse<ProcessInterruptionSubCategory> =
          await ApiService.post('fes/config/processinterruption/subcategory', {
            data: subCategoryUpdateObject,
          });

        if (!subCategory.id) {
          subCategory.id = savedProcessInterruptionSubCategory.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      async function saveSubSubCategory(
        subSubCategory: ProcessInterruptionSubSubCategoryWithForm,
      ) {
        subSubCategory.edit = false;
        subSubCategory.nameBefore = subSubCategory.name;

        store.dispatch(Actions.START_LOADER);
        subSubCategory.edit = false;
        subSubCategory.nameBefore = subSubCategory.name;

        const subSubCategoryUpdateObject: ProcessInterruptionSubSubCategory = {
          name: subSubCategory.name,
          parent: {
            id: subSubCategory.parent.id,
          },
        } as ProcessInterruptionSubSubCategory;

        if (subSubCategory.id) {
          subSubCategoryUpdateObject.id = subSubCategory.id;
        }

        const savedProcessInterruptionSubSubCategory: AxiosResponse<ProcessInterruptionSubSubCategory> =
          await ApiService.post(
            'fes/config/processinterruption/subsubcategory',
            {
              data: subSubCategoryUpdateObject,
            },
          );

        if (!subSubCategory.id) {
          subSubCategory.id = savedProcessInterruptionSubSubCategory.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestProcessInterruptionCatalogPath = computed(() => {
        if (computedNearestProcessInterruptionCatalog.value === false)
          return '';
        const currentNearestProcessInterruptionCatalog: ProcessInterruptionCatalog =
          computedNearestProcessInterruptionCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.processInterruptionCatalog === null) return false;
            return (
              orgUnit.processInterruptionCatalog?.id ===
              currentNearestProcessInterruptionCatalog.id
            );
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedNearestProcessInterruptionCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentNode.processInterruptionCatalog !== null) return false;

        const nearestProcessInterruptionCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'processInterruptionCatalog',
        );

        if (nearestProcessInterruptionCatalog) {
          return nearestProcessInterruptionCatalog;
        }

        return false;
      });

      async function copyNearestProcessInterruptionCatalog() {
        if (data.currentNode === null) return;
        const currentNearestProcessInterruptionCatalog: ProcessInterruptionCatalog =
          computedNearestProcessInterruptionCatalog.value;
        const processInterruptionCatalogCopy: ProcessInterruptionCatalog = {
          orgUnitId: data.currentNode.id,
          subSubCategories:
            currentNearestProcessInterruptionCatalog.subSubCategories,
        } as ProcessInterruptionCatalog;
        data.currentNode.processInterruptionCatalog =
          processInterruptionCatalogCopy;
        await updateCurrentProcessInterruptionCatalog();
      }

      const computedHasCategoryEditsOpen = computed(() => {
        return (subCategory: ProcessInterruptionCategoryWithForm) => {
          return subCategory.subCategories.some(
            (subCategory: ProcessInterruptionSubCategoryWithForm) => {
              return subCategory.edit === true;
            },
          );
        };
      });

      const computedHasSubCategoryEditsOpen = computed(() => {
        return (subCategory: ProcessInterruptionSubCategoryWithForm) => {
          return subCategory.subSubCategories.some(
            (subSubCategory: ProcessInterruptionSubSubCategoryWithForm) => {
              return subSubCategory.edit === true;
            },
          );
        };
      });
      const computedIsCategoryAddOpen = computed(() => {
        return data.categories.some((category) => {
          return typeof category.id === 'undefined';
        });
      });

      watch(
        () => props.currentNode,
        () => {
          if (props.currentNode !== null) {
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;
            updateAvailableProcessInterruptionCategories();
          }
        },
      );

      return {
        data,
        computedNodePathAreaTitle,
        computedNodePathLastElement,
        computedCatalogHasSubCategories,
        // computedCategorySelects,
        computedCurrentCatalogDecisions,
        connectSubCategoryWithCatalog,
        connectSubSubCategoryWithCatalog,
        disconnectSubSubCategoryWithCatalog,
        addNewCategory,
        addNewSubCategory,
        addNewSubSubCategory,
        // handleDecisionSubmit,
        nameValidator,
        editCategory,
        discardCategoryEdit,
        discardSubCategoryEdit,
        discardSubSubCategoryEdit,
        deleteCategory,
        deleteSubCategory,
        deleteSubSubCategory,
        handleDeleteProcessInterruptionCatalog,
        openProcessInterruptionCatalog,
        props,
        computedDate,
        handleCategoryUpdate,
        saveSubCategory,
        saveSubSubCategory,
        computedNearestProcessInterruptionCatalog,
        computedNearestProcessInterruptionCatalogPath,
        copyNearestProcessInterruptionCatalog,
        updateAvailableProcessInterruptionCategories,
        computedCurrentCatalogSubSubCategories,
        computedCurrentCatalogCategories,
        isSelectedSubSubCategory,
        computedCurrentCategories,
        computedIsCategoryAddOpen,
        computedHasCategoryEditsOpen,
        computedHasSubCategoryEditsOpen,
      };
    },
  });
</script>
