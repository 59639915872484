<template>
  <div>
    <template v-if="computedIsMissingUserConfig">
      <div class="row">
        <div class="col">
          <div
            v-if="computedIsMissingManagementLevel"
            class="alert alert-danger d-flex align-items-center p-5 mb-10"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
              <inline-svg src="media/icons/duotune/general/gen044.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-danger">
                {{ $t('errors.missingManagementLevel.title') }}
              </h4>
              <span>{{ $t('errors.missingManagementLevel.text') }}</span>
            </div>
          </div>

          <div
            v-if="computedIsMissingManagementArea"
            class="alert alert-danger d-flex align-items-center p-5 mb-10"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
              <inline-svg src="media/icons/duotune/general/gen044.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-danger">
                {{ $t('errors.missingManagementArea.title') }}
              </h4>
              <span>{{ $t('errors.missingManagementArea.text') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="!data.location.isLoading" class="row mb-3">
        <div class="col" v-if="isLocationComplete">
          <org-unit-path
            v-if="selectedOrgLevels"
            :node-path="selectedOrgLevels"
            :area-names="data.hierarchyNames"
          >
            <button
              type="button"
              class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
              @click="releaseLocationForm"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </span>
            </button>
          </org-unit-path>
        </div>
        <div class="col" v-if="!isLocationComplete">
          <div class="card">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{
                  $t('spc.processconfirmation.location')
                }}</span>
              </h3>
            </div>

            <div class="card-body py-3">
              <div
                class="row mb-6"
                v-for="(orgLevel, index) of data.orgLevels"
                :key="index"
              >
                <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                  computedOrgLevelHierarchy(index)
                }}</label>

                <div class="col-lg-8 fv-row">
                  <Field
                    as="select"
                    name="orgUnit"
                    class="form-select form-select-solid form-select-lg"
                    v-model="data.orgLevels[index]"
                    @change="handleOrgUnitChange(index)"
                  >
                    <option
                      selected
                      value="all"
                      v-if="computedAllIsAllowed(index)"
                    >
                      {{ $t('location.selectAll') }}
                    </option>
                    <option
                      v-for="(orgOption, index) of computedOrgOptions(index)"
                      :key="index"
                      :value="orgOption.id"
                    >
                      {{ orgOption.name }}
                    </option>
                  </Field>
                </div>
              </div>
            </div>

            <div
              v-if="data.location.isReady && !data.location.isComplete"
              class="card-footer d-flex justify-content-end py-6 px-9"
            >
              <button
                @click="handleLocationChange"
                type="submit"
                class="btn btn-primary"
              >
                <span class="indicator-label"> {{ $t('continue') }}</span>
              </button>
            </div>

            <div v-if="data.location.isComplete" class="card-body py-3">
              <div
                class="row mb-6"
                v-for="(orgLevel, index) of selectedOrgLevels"
                :key="index"
              >
                <div class="col-lg-4 fw-bold fs-6">
                  {{ computedOrgLevelHierarchy(index) }}
                </div>

                <div v-if="orgLevel" class="col-lg-8 fv-row">
                  <span>{{ orgLevel.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="isLocationComplete">
        <div class="col">
          <!--begin::Tables Widget 9-->
          <div class="card">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{
                  computedCardTitle
                }}</span>
              </h3>

              <!--begin::Toolbar-->
              <div class="card-toolbar audit-switch">
                <el-switch
                  :disabled="data.isAuditPlan"
                  v-model="data.isAudit"
                  class="ml-2"
                  inline-prompt
                  style="
                    --el-switch-on-color: #009ef7;
                    --el-switch-off-color: #7239ea;
                  "
                  :active-text="$t('spc.processconfirmation.audit')"
                  :inactive-text="$t('spc.processconfirmation.single')"
                  @change="handleTypeChange"
                />
              </div>
              <!--end::Toolbar-->
            </div>

            <!--end::Header-->

            <!--begin::Body-->
            <MyForm
              @submit="handleProcessConfirmationSubmit"
              :validation-schema="processConfirmationCreateValidator"
              novalidate="novalidate"
              action="javascript:"
              ref="createForm"
            >
              <Field
                type="hidden"
                name="userType"
                v-model="computedCurrentUserManagementAreaType"
              />
              <div class="card-body py-3">
                <div class="col-12 mb-3">
                  <div class="row">
                    <div class="col-12 col-md-3 d-flex align-items-start">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('spc.processconfirmation.name')
                      }}</label>
                    </div>
                    <div
                      :class="`${
                        computedIsManager
                          ? 'col-12 col-md-7 d-flex flex-column align-items-start'
                          : 'col-12 col-md-9 d-flex flex-column align-items-start'
                      }`"
                    >
                      <Field
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        name="name"
                        v-model="data.processConfirmation.name"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-1 d-flex align-items-start"
                      v-if="computedIsManager"
                    >
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('spc.processconfirmation.amount')
                      }}</label>
                    </div>
                    <div
                      v-if="computedIsManager"
                      class="col-12 col-md-1 d-flex flex-column align-items-start"
                    >
                      <Field
                        type="number"
                        class="form-control form-control-lg form-control-solid"
                        name="amount"
                        v-model="data.form.amountToCreate"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="amount" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-10">
                  <div class="row">
                    <div class="col-12 col-md-3 d-flex align-items-start">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('spc.processconfirmation.description')
                      }}</label>
                    </div>
                    <div
                      class="col-12 col-md-9 d-flex flex-column align-items-start"
                    >
                      <Field
                        as="textarea"
                        class="form-control form-control-lg form-control-solid"
                        name="description"
                        v-model="data.processConfirmation.description"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="description" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3" v-if="computedIsManager">
                  <div class="row">
                    <div class="col-12 col-md-3 d-flex align-items-start">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('spc.questionCatalog.managementLevel')
                      }}</label>
                    </div>
                    <div
                      class="col-12 col-md-9 d-flex flex-column align-items-start"
                    >
                      <Field
                        v-show="0"
                        name="managementLevel"
                        v-model="data.form.managementLevelId"
                      />
                      <multiselect
                        mode="single"
                        v-model="data.form.managementLevelId"
                        :options="computedManagementLevelsOptions"
                        :searchable="true"
                        :createTag="false"
                        :create-option="false"
                        :filterResults="false"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        @change="handleManagementLevelChange"
                        :disabled="!computedIsManager"
                      >
                        <template v-slot:option="{ option }">
                          <span>{{ option.name }}</span>
                        </template>
                        <template v-slot:singlelabel="{ value }">
                          <div class="multiselect-single-label">
                            <span>{{ value.name }}</span>
                          </div>
                        </template>
                      </multiselect>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="managementLevel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3" v-show="computedHasManagementLevel">
                  <div class="row">
                    <div class="col-12 col-md-3 d-flex align-items-start">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('spc.questionCatalog.single')
                      }}</label>
                    </div>
                    <div class="col-12 col-md-9">
                      <Field
                        v-show="0"
                        name="questionCatalog"
                        v-model="data.form.questionCatalogId"
                      />
                      <div class="row g-5">
                        <div
                          class="col-6 col-md-3"
                          v-for="questionCatalog of computedFilteredQuestionCatalogOptions"
                          :key="questionCatalog.id"
                        >
                          <div class="d-block">
                            <button
                              type="button"
                              @click="
                                handleQuestionCatalogChange(questionCatalog.id)
                              "
                              :class="computedButtonClass(questionCatalog)"
                            >
                              <div>
                                <span
                                  class="svg-icon svg-icon-4hx svg-icon-primary"
                                  v-if="!questionCatalog.image"
                                >
                                  <inline-svg
                                    src="media/icons/duotune/general/gen046.svg"
                                  />
                                </span>
                                <img
                                  v-else
                                  class="mw-60px mh-60px"
                                  :src="
                                    computedFileUrl(
                                      questionCatalog.image.fileName,
                                    )
                                  "
                                />
                              </div>
                              <strong class="my-3">{{
                                questionCatalog.name
                              }}</strong>
                              <div>
                                <el-tooltip
                                  v-if="computedIsManager"
                                  class="box-item"
                                  effect="light"
                                  :content="
                                    $t('spc.questionCatalog.managementLevels')
                                  "
                                  placement="top"
                                >
                                  <span class="badge badge-light-primary me-3">
                                    <i
                                      class="fa fa-user fs-7 text-primary me-2"
                                    ></i>
                                    {{ questionCatalog.levels.length }}
                                  </span>
                                </el-tooltip>
                                <el-tooltip
                                  class="box-item"
                                  effect="light"
                                  :content="
                                    $t('spc.questionCatalog.questionTotal')
                                  "
                                  placement="top"
                                >
                                  <span class="badge badge-light-success">
                                    <i
                                      class="fa fa-question-circle fs-7 text-success me-2"
                                    ></i>
                                    {{
                                      computedQuestionCatalogQuestionAmount(
                                        questionCatalog,
                                      )
                                    }}
                                  </span>
                                </el-tooltip>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="questionCatalog" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 mb-3"
                  v-if="
                    computedHasQuestionCatalog && computedHasManagementLevel
                  "
                >
                  <div class="row">
                    <div class="col-12 col-md-3 d-flex align-items-start">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('spc.processconfirmation.amountOfQuestions')
                      }}</label>
                    </div>
                    <template v-if="data.isAudit">
                      <div class="col-12 col-md-9 d-flex align-items-center">
                        <span class="text-muted"
                          >{{ data.form.amountOfQuestions }} Fragen werden in
                          vorgegebener Reihenfolge gestellt.</span
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="col-12 col-md-9"
                        v-if="
                          computedMinAmountOfQuestions !==
                          computedMaxAmountOfQuestions
                        "
                      >
                        <el-slider
                          v-model="data.form.amountOfQuestions"
                          :min="computedMinAmountOfQuestions"
                          :max="computedMaxAmountOfQuestions"
                          :marks="computedMarks"
                          show-stops
                        />
                      </div>
                      <div v-else class="col-9 d-flex align-items-center">
                        <span class="text-muted"
                          >Nur {{ data.form.amountOfQuestions }} Frage(n)</span
                        >
                      </div>
                    </template>
                  </div>
                  <div class="row">
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="managementLevel" />
                      </div>
                    </div>
                  </div>
                </div>
                <Field
                  type="hidden"
                  name="isAuditPlan"
                  v-model="data.isAuditPlan"
                />
                <template v-if="computedIsManager">
                  <div class="col-12 mb-5">
                    <div class="row">
                      <div class="col-12 col-md-3"></div>
                      <div class="col-12 col-md-9 d-flex align-items-center">
                        <el-checkbox
                          :disabled="computedIsLoadedAuditPlan"
                          v-model="data.isAuditPlan"
                          :label="
                            $t('spc.auditPlan.createAuditPlanCheckbox', {
                              type: computedTypeName,
                            })
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3 mb-3" v-if="data.isAuditPlan">
                    <div class="row">
                      <div class="col-12 col-md-3">
                        <label class="col-form-label required fw-bold fs-6">{{
                          $t('spc.auditPlan.dateRange')
                        }}</label>
                      </div>
                      <div class="col-12 col-md-9">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <Field
                              type="hidden"
                              name="startDate"
                              v-model="data.auditPlan.startDate"
                            />
                            <el-date-picker
                              class="w-100"
                              v-model="data.auditPlan.startDate"
                              type="date"
                              :range-separator="
                                $t('spc.auditPlan.intervalRangeSeparator')
                              "
                              :format="$t('dateFormat')"
                              value-format="YYYY-MM-DD"
                              :placeholder="
                                $t('spc.auditPlan.startDatePlaceholder')
                              "
                            />
                          </div>
                          <div class="col-12 col-md-6">
                            <el-date-picker
                              class="w-100"
                              v-model="data.auditPlan.endDate"
                              type="date"
                              :range-separator="
                                $t('spc.auditPlan.intervalRangeSeparator')
                              "
                              :format="$t('dateFormat')"
                              value-format="YYYY-MM-DD"
                              :placeholder="
                                $t('spc.auditPlan.endDatePlaceholder')
                              "
                            />
                            <el-checkbox
                              v-if="0"
                              v-model="data.isAuditPlanWithoutEndDate"
                              :label="$t('spc.auditPlan.noEndDate')"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="startDate" />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-md-3">
                        <label class="col-form-label required fw-bold fs-6">{{
                          $t('spc.auditPlan.interval')
                        }}</label>
                      </div>
                      <div class="col-12 col-md-9">
                        <Field
                          as="select"
                          name="interval"
                          class="form-select form-select-solid form-select-lg"
                          v-model="data.auditPlan.interval"
                        >
                          <option
                            v-for="(
                              intervalData, index
                            ) of computedIntervalOptions"
                            :key="index"
                            :value="intervalData.value"
                          >
                            {{ intervalData.name }}
                          </option>
                        </Field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="interval" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <!--begin::Body-->

              <div class="card-footer row py-6 px-9">
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-light-danger"
                    v-if="computedIsLoadedAuditPlan"
                    @click="deleteCurrentAuditPlan"
                  >
                    <span class="indicator-label">
                      {{ $t('delete') }}
                    </span>
                  </button>
                </div>
                <div class="col-6 text-end">
                  <button type="submit" :class="computedSubmitButtonClass">
                    <span class="indicator-label">
                      {{ computedSubmitButtonCaption }}
                    </span>
                  </button>
                </div>
              </div>
            </MyForm>
          </div>
          <!--end::Tables Widget 9-->
        </div>
      </div>
    </template>
  </div>
  <!--begin::Dashboard-->
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
  import {
    calculateDepth,
    computedDate,
    parseCurrentOrgLevels,
  } from '@/core/helpers/cdf';
  import Multiselect from '@vueform/multiselect';
  import {
    AuditPlan,
    AuditPlanInterval,
    CatalogQuestion,
    HierarchyName,
    ManagementLevel,
    OrgChartDataPairWithCatalogs,
    ProcessConfirmation,
    ProcessConfirmationLocation,
    ProcessConfirmationState,
    ProcessConfirmationType,
    QuestionAnswer,
    QuestionCatalog,
    QuestionCatalogLevel,
    QuestionType,
    UserManagementAreaType,
    UserManagementAreaWithUser,
  } from '@/core/models/spc';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import ApiService from '@/core/services/ApiService';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { SPCConfig } from '@/core/models/spc';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import * as yup from 'yup';
  import {
    getAllChildrenSPC,
    getInheritQuestionCatalogsToTopWithoutDisabled,
    hasReadyChildrenSPCForUser,
    hasReadyParentsSPCForUser,
    isReadyOrgUnitSPCForUser,
    shuffle,
  } from '@/core/helpers/spc';
  import { SetErrorLocationContext } from '@/store/modules/AuthModule';
  import Swal from 'sweetalert2';
  import { ElNotification } from 'element-plus';
  import { AxiosResponse } from 'axios';
  import { useRouter } from 'vue-router';
  import { Mark } from 'element-plus/es/components/slider/src/composables';

  export default defineComponent({
    name: 'spcCreateProcessConfirmation',
    components: {
      MyForm: Form,
      Field,
      ErrorMessage,
      Multiselect,
      OrgUnitPath,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();

      const createForm = ref(Form);

      const data: {
        location: {
          isLoading: boolean;
          isReady: boolean;
          isComplete: boolean;
        };
        orgLevels: string[];
        orgChart: OrgChartDataPairWithCatalogs[];
        hierarchyNames: HierarchyName[];
        form: {
          questionCatalogId: number | null;
          questionCatalog: QuestionCatalog | null;
          managementLevelId: number | null;
          managementLevel: ManagementLevel | null;
          amountOfQuestions: number;
          amountToCreate: number;
        };
        auditPlan: AuditPlan;
        processConfirmation: ProcessConfirmation;
        isAudit: boolean;
        isAuditPlan: boolean;
        isAuditPlanWithoutEndDate: boolean;
        userManagementAreas: UserManagementAreaWithUser[];
      } = reactive({
        location: {
          isLoading: true,
          isReady: false,
          isComplete: false,
        },
        orgLevels: [],
        orgChart: [],
        hierarchyNames: [],
        form: {
          questionCatalogId: null,
          questionCatalog: null,
          managementLevelId: null,
          managementLevel: null,
          amountOfQuestions: 0,
          amountToCreate: 1,
        },
        auditPlan: {
          interval: AuditPlanInterval.DAILY,
          startDate: '',
          endDate: null,
        } as AuditPlan,
        processConfirmation: {
          type: ProcessConfirmationType.AUDIT,
        } as ProcessConfirmation,
        isAudit: true,
        isAuditPlan: false,
        isAuditPlanWithoutEndDate: true,
        userManagementAreas: [] as UserManagementAreaWithUser[],
      });

      const currentManagementLevel: QuestionCatalogLevel =
        store.getters.currentManagementLevel;

      const computedIsMissingUserConfig = computed(() => {
        return (
          computedIsMissingManagementLevel.value === true ||
          computedIsMissingManagementArea.value === true
        );
      });

      const computedIsMissingManagementArea = computed(() => {
        return data.userManagementAreas.length === 0;
      });

      const computedIsMissingManagementLevel = computed(() => {
        const currentManagementLevel = store.getters.currentManagementLevel;
        if (currentManagementLevel === null) return true;
        if (typeof currentManagementLevel.id === 'undefined') return true;
        return false;
      });

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        const spcConfig: SPCConfig = (await ApiService.get('spc/config')).data;
        data.hierarchyNames = spcConfig.hierarchyNames.slice(0);

        const loadedUserManagementAreasRequest = await ApiService.get(
          'spc/users/areas/self',
        );

        data.userManagementAreas =
          loadedUserManagementAreasRequest.data as UserManagementAreaWithUser[];

        if (data.userManagementAreas.length === 0) {
          store.dispatch(Actions.END_LOADER);
          return;
        }

        const orgChartDataResponse = await ApiService.get('spc/org-chart');
        data.orgChart = orgChartDataResponse.data.slice(0);
        data.orgChart = data.orgChart.map((orgUnit) => {
          return {
            ...orgUnit,
            depth: calculateDepth(orgUnit, data.orgChart),
            selectable: isReadyOrgUnitSPCForUser(
              orgUnit,
              data.orgChart,
              data.userManagementAreas,
            ),
            visible:
              isReadyOrgUnitSPCForUser(
                orgUnit,
                data.orgChart,
                data.userManagementAreas,
              ) ||
              hasReadyChildrenSPCForUser(
                orgUnit,
                data.orgChart,
                data.userManagementAreas,
              ) ||
              hasReadyParentsSPCForUser(
                orgUnit,
                data.orgChart,
                data.userManagementAreas,
              ),
          };
        });

        data.orgLevels = parseCurrentOrgLevels(
          data.orgChart,
          computedCurrentLocation.value,
          undefined,
          'currentOrgLevelsSPC',
        );

        data.location.isReady = true;
        if (data.orgLevels.length === 1 && data.orgLevels[0] === '') {
          data.location.isReady = false;
        }
        await handleLocationChange();

        const currentDeviceOrgLevels = window.localStorage.getItem(
          'currentOrgLevelsSPC',
        );

        if (currentDeviceOrgLevels !== null) {
          data.orgLevels.splice(0);
          JSON.parse(currentDeviceOrgLevels).forEach((orgLevel) => {
            data.orgLevels.push(orgLevel);
          });

          if (computedCurrentLocation.value) {
            const currentLocation =
              computedCurrentLocation.value as OrgChartDataPairWithCatalogs;
            if (!currentLocation) {
              data.location.isReady = false;
              data.orgLevels = [data.orgChart[0].id];
            } else {
              if (
                isReadyOrgUnitSPCForUser(
                  currentLocation,
                  data.orgChart,
                  data.userManagementAreas,
                )
              ) {
                data.location.isReady = true;
                await handleLocationChange();
              } else {
                data.location.isReady = false;
                data.location.isComplete = false;
                data.orgLevels = [data.orgChart[0].id];
              }
            }
          } else {
            const rootUnit = data.orgChart.find(
              (orgUnit: OrgChartDataPairWithCatalogs) => {
                return orgUnit.parentId === '';
              },
            );
            if (rootUnit) {
              data.orgLevels = [rootUnit.id];
            }
            // data.orgLevels = [data.orgChart[0].id];
            data.orgLevels.push('');
          }
        } else {
          if (data.orgLevels.length === 0) {
            data.orgLevels.push('');
          }
        }

        if (currentManagementLevel) {
          handleManagementLevelChange(currentManagementLevel.level.id);
        }

        const currentRoute = router.currentRoute.value;
        if (
          typeof currentRoute.params !== 'undefined' &&
          typeof currentRoute.params.id !== 'undefined'
        ) {
          await loadAuditPlan(currentRoute.params.id as string);
        }

        data.location.isLoading = false;
        store.dispatch(Actions.END_LOADER);
      });

      async function loadAuditPlan(id: string) {
        try {
          const loadedAuditPlanResponse = await ApiService.get(
            `spc/audit/plan/${id}`,
          );

          const loadedAuditPlan = loadedAuditPlanResponse.data as AuditPlan;
          // data.auditPlan = loadedAuditPlan.data as AuditPlan;
          data.processConfirmation = {
            id: loadedAuditPlan.id,
            assignee: null,
            completedAt: '',
            createdAt: '',
            name: loadedAuditPlan.name,
            description: loadedAuditPlan.description,
            answers: [],
            level: loadedAuditPlan.level,
            state: ProcessConfirmationState.OPEN,
            type: ProcessConfirmationType.AUDIT,
            locations: loadedAuditPlan.locations.sort((a, b) => {
              return a.pos - b.pos;
            }),
            questionCatalog: loadedAuditPlan.questionCatalog,
          } as ProcessConfirmation;

          // await handleLocationChange();
          data.orgLevels = data.processConfirmation.locations.map(
            (location: ProcessConfirmationLocation) => {
              return location.locationId;
            },
          );

          data.form.questionCatalogId = loadedAuditPlan.questionCatalog
            .id as number;
          data.form.questionCatalog = loadedAuditPlan.questionCatalog;
          data.form.managementLevelId = data.processConfirmation.level
            .id as number;
          data.form.managementLevel = data.processConfirmation.level;
          data.form.amountOfQuestions = computedMaxAmountOfQuestions.value;
          data.form.amountToCreate = loadedAuditPlan.amount;

          data.isAuditPlan = true;
          data.auditPlan.startDate = loadedAuditPlan.startDate;
          data.auditPlan.endDate = loadedAuditPlan.endDate;
          data.auditPlan.interval = loadedAuditPlan.interval;
        } catch (error) {
          ElNotification({
            title: t('error'),
            message: t('spc.auditPlan.errors.loadingError'),
            type: 'error',
          });
          router.push('/spc/audit/plans');
        }
      }

      const computedTypeName = computed(() => {
        if (data.form.amountToCreate > 1) {
          if (data.isAudit) {
            return t('spc.processconfirmation.audits');
          }
          return t('spc.processconfirmation.multiple');
        }

        if (data.isAudit) {
          return t('spc.processconfirmation.audit');
        }
        return t('spc.processconfirmation.single');
      });

      const computedCurrentQuestionCatalogs = computed(() => {
        if (!computedCurrentLocation.value) return [];
        return getInheritQuestionCatalogsToTopWithoutDisabled(
          computedCurrentLocation.value,
          data.orgChart,
        );
      });

      function handleQuestionCatalogChange(questionCatalogId: number) {
        if (!computedCurrentQuestionCatalogs.value) return;
        if (questionCatalogId === data.form.questionCatalogId) return;
        const foundQuestionCatalog = computedCurrentQuestionCatalogs.value.find(
          (questionCatalog) => {
            return questionCatalog.id === questionCatalogId;
          },
        );
        if (foundQuestionCatalog) {
          data.form.questionCatalogId = foundQuestionCatalog.id || null;
          data.form.questionCatalog = foundQuestionCatalog;
          data.form.amountOfQuestions = data.isAudit
            ? computedMaxAmountOfQuestions.value
            : computedMinAmountOfQuestions.value;
        } else {
          console.error('Question Catalog Not Found');
        }
      }

      function handleManagementLevelChange(managementLevelId) {
        if (!computedCurrentLocation.value) return;
        const foundManagementLevel = computedManagementLevelsOptions.value.find(
          (managementLevel: ManagementLevel) => {
            return managementLevel.id === managementLevelId;
          },
        ) as ManagementLevel;
        if (
          foundManagementLevel &&
          typeof foundManagementLevel.id !== 'undefined'
        ) {
          data.form.managementLevel = foundManagementLevel;
          data.form.managementLevelId = foundManagementLevel.id;
          data.processConfirmation.level = foundManagementLevel;
          data.form.questionCatalogId = null;
          data.form.questionCatalog = null;
          // data.auditPlan.startDate = '';
          // data.auditPlan.endDate = '';
          // data.auditPlan.interval = null;
        } else {
          console.error('Management Level Not Found');
        }
      }

      const computedOrgLevelHierarchy = (index): string => {
        return data.hierarchyNames[index]
          ? data.hierarchyNames[index].name
          : `Level ${index}`;
      };

      const computedQuestionCatalogOptions = computed(() => {
        if (typeof computedCurrentLocation.value === 'undefined') {
          return [];
        }
        return getInheritQuestionCatalogsToTopWithoutDisabled(
          computedCurrentLocation.value,
          data.orgChart,
        );
      });
      const computedFilteredQuestionCatalogOptions = computed(() => {
        return computedQuestionCatalogOptions.value.filter(
          (questionCatalog: QuestionCatalog) => {
            return questionCatalog.levels.some(
              (questionCatalogLevel: QuestionCatalogLevel) => {
                return (
                  questionCatalogLevel.level.id === data.form.managementLevelId
                );
              },
            );
          },
        );
      });

      const computedButtonClass = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          const defaultClasses =
            'btn btn-light w-100 d-flex flex-column align-items-center border border-white border-2';
          if (data.form.questionCatalogId === null) return defaultClasses;
          if (questionCatalog.id === data.form.questionCatalogId)
            return 'btn btn-light-primary btn-active-light-primary w-100 d-flex flex-column align-items-center border border-primary border-2';
          return defaultClasses;
        };
      });

      const computedManagementLevelsOptions = computed(() => {
        const unqiueManagementLevels: ManagementLevel[] = [];
        computedQuestionCatalogOptions.value.forEach(
          (questionCatalog: QuestionCatalog) => {
            questionCatalog.levels.forEach(
              (questionCatalogLevel: QuestionCatalogLevel) => {
                const alreadyInList = unqiueManagementLevels.findIndex(
                  (loopedManagementLevel: ManagementLevel) => {
                    return (
                      loopedManagementLevel.id === questionCatalogLevel.level.id
                    );
                  },
                );
                if (alreadyInList === -1) {
                  unqiueManagementLevels.push(questionCatalogLevel.level);
                }
              },
            );
          },
        );

        return unqiueManagementLevels;
      });

      const computedCurrentUserManagementAreaType = computed(() => {
        if (computedCurrentLocation.value === null) return null;
        return computedCurrentUserManagementArea.value?.type;
      });

      const computedIsManager = computed(() => {
        if (computedCurrentUserManagementArea.value) {
          return (
            computedCurrentUserManagementArea.value.type ===
            UserManagementAreaType.MANAGER
          );
        }
        return false;
      });

      const computedIsWorker = computed(() => {
        if (computedCurrentUserManagementArea.value) {
          return (
            computedCurrentUserManagementArea.value.type ===
            UserManagementAreaType.WORKER
          );
        }
        return false;
      });

      const processConfirmationCreateValidator = yup.object().shape({
        name: yup
          .string()
          .required(t('spc.processconfirmation.errors.missingName'))
          .typeError(t('spc.processconfirmation.errors.missingName')),
        userType: yup.string(),
        amount: yup
          .number()
          .when('userType', {
            is: UserManagementAreaType.MANAGER,
            then: yup
              .number()
              .required(t('spc.processconfirmation.errors.missingAmount'))
              .typeError(t('spc.processconfirmation.errors.missingAmount')),
          })
          .max(100)
          .typeError('XXX'),
        managementLevel: yup.string().when('userType', {
          is: UserManagementAreaType.MANAGER,
          then: yup
            .string()
            .typeError(
              t('spc.processconfirmation.errors.missingManagementLevel'),
            )
            .required(
              t('spc.processconfirmation.errors.missingManagementLevel'),
            ),
        }),
        questionCatalog: yup
          .string()
          .typeError(t('spc.processconfirmation.errors.missingCatalog'))
          .required(t('spc.processconfirmation.errors.missingCatalog')),
        startDate: yup.string().when('isAuditPlan', {
          is: true,
          then: yup
            .string()
            .typeError(t('spc.auditPlan.errors.missingStartDate'))
            .required(t('spc.auditPlan.errors.missingStartDate')),
        }),
        interval: yup.string().when('isAuditPlan', {
          is: true,
          then: yup
            .string()
            .typeError(t('spc.auditPlan.errors.missingInterval'))
            .required(t('spc.auditPlan.errors.missingInterval')),
        }),
      });

      const computedHasQuestionCatalog = computed(() => {
        return data.form.questionCatalog !== null;
      });
      const computedHasManagementLevel = computed(() => {
        return data.form.managementLevelId !== null;
      });

      const handleOrgUnitChange = (index) => {
        if (data.orgLevels.length > index + 1) {
          data.orgLevels.splice(index + 1, data.orgLevels.length - index - 1);
        }

        const currentId = String(data.orgLevels[index]);

        const rawDataParsed: OrgChartDataPairWithCatalogs[] = getRawOrgChart();
        const currentNode: OrgChartDataPairWithCatalogs | undefined =
          rawDataParsed.find(
            (element: OrgChartDataPairWithCatalogs) => element.id === currentId,
          );

        if (currentNode) {
          if (
            hasReadyChildrenSPCForUser(
              currentNode,
              data.orgChart,
              data.userManagementAreas,
            )
          ) {
            data.orgLevels.push('');
            data.location.isReady = false;
          } else {
            data.location.isReady = true;
          }
        } else {
          const parentId = data.orgLevels.slice(0)[data.orgLevels.length - 2];
          const parentNode: OrgChartDataPairWithCatalogs | undefined =
            rawDataParsed.find(
              (element: OrgChartDataPairWithCatalogs) =>
                element.id === parentId,
            );

          if (parentNode) {
            if (
              currentId === 'all' &&
              isReadyOrgUnitSPCForUser(
                parentNode,
                data.orgChart,
                data.userManagementAreas,
              )
            ) {
              data.location.isReady = true;
            } else {
              data.location.isReady = false;
            }
          }
        }
      };

      const releaseLocationForm = () => {
        try {
          data.location.isComplete = false;
          resetCreateForm();
        } catch (e) {
          data.orgLevels = [''];
          data.location.isComplete = false;
          data.location.isReady = false;
        }
      };

      const computedCurrentUserManagementArea = computed(
        (): UserManagementAreaWithUser | null => {
          let workerRole: UserManagementAreaWithUser | null = null;
          let managerRole: UserManagementAreaWithUser | null = null;

          const allRoles = data.userManagementAreas.filter(
            (userManagementArea: UserManagementAreaWithUser) => {
              const orgUnit = data.orgChart.find(
                (loopedOrgUnit: OrgChartDataPairWithCatalogs) => {
                  return loopedOrgUnit.id === userManagementArea.orgUnitId;
                },
              );

              if (orgUnit && computedCurrentLocation.value) {
                if (orgUnit.id === computedCurrentLocation.value.id)
                  return true;
                const children = getAllChildrenSPC(orgUnit, data.orgChart);
                return (
                  children.findIndex(
                    (orgUnit: OrgChartDataPairWithCatalogs) => {
                      return orgUnit.id === computedCurrentLocation.value?.id;
                    },
                  ) !== -1
                );
              }

              return false;
            },
          );

          allRoles.forEach((userManagementArea: UserManagementAreaWithUser) => {
            if (
              workerRole === null &&
              userManagementArea.type === UserManagementAreaType.WORKER
            ) {
              workerRole = userManagementArea;
            }
            if (
              managerRole === null &&
              userManagementArea.type === UserManagementAreaType.MANAGER
            ) {
              managerRole = userManagementArea;
            }
          });

          if (managerRole !== null) {
            return managerRole;
          }

          return workerRole;
        },
      );

      const handleLocationChange = async () => {
        if (typeof computedCurrentLocation.value === 'undefined') {
          return;
        }

        data.location.isComplete = true;

        // todo
        handleManagementLevelChange(currentManagementLevel.level.id);

        if (computedIsLoadedAuditPlan.value === false) {
          store.commit(Mutations.SET_ERRORLOCATION, {
            orgLevels: data.orgLevels,
            identifier: 'currentOrgLevelsSPC',
          } as SetErrorLocationContext);
        }
      };

      const isLocationComplete = computed(() => {
        return data.location.isComplete === true;
      });

      const computedIntervalOptions = computed(() => {
        const allIntervals: {
          name: string;
          value: string;
        }[] = [];
        for (const attr in AuditPlanInterval) {
          allIntervals.push({
            name: t('spc.auditPlan.intervals.' + AuditPlanInterval[attr]),
            value: AuditPlanInterval[attr],
          });
        }
        return allIntervals;
      });

      const computedCurrentLocation = computed(() => {
        return selectedOrgLevels.value
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          })
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      const selectedOrgLevels = computed(() => {
        return data.orgLevels
          .map((orgLevelId, index) => {
            const currentOptions = computedOrgOptions(index);

            return currentOptions.find((orgOption) => {
              return orgLevelId === orgOption.id;
            });
          })
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          });
      });

      const computedAvailableOrgLevels = computed(() => {
        return data.orgChart.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.visible;
        });
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(computedAvailableOrgLevels.value);
        const rawDataParsed = JSON.parse(rawData);
        return rawDataParsed;
      };

      const computedAllIsAllowed = computed(() => {
        return (index: number) => {
          if (index === 0) return false;

          const rawDataParsed: OrgChartDataPairWithCatalogs[] =
            getRawOrgChart();
          const parentId = data.orgLevels.slice(0)[data.orgLevels.length - 2];
          const parentNode: OrgChartDataPairWithCatalogs | undefined =
            rawDataParsed.find(
              (element: OrgChartDataPairWithCatalogs) =>
                element.id === parentId,
            );

          if (parentNode) {
            if (
              isReadyOrgUnitSPCForUser(
                parentNode,
                data.orgChart,
                data.userManagementAreas,
              )
            ) {
              return true;
            } else {
              return false;
            }
          } else return false;
        };
      });

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return data.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const computedQuestionCatalogQuestionAmount = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          if (
            data.form.managementLevel === null ||
            data.form.managementLevelId === null
          )
            return 0;
          const managementLevelId = data.form.managementLevelId;
          const currentQuestionCatalogLevel = questionCatalog.levels.find(
            (questionCatalogLevel) => {
              return questionCatalogLevel.level.id === managementLevelId;
            },
          );

          return typeof currentQuestionCatalogLevel !== 'undefined'
            ? currentQuestionCatalogLevel.catalogQuestions.length
            : 0;
        };
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/questioncatalog/image/' +
            fileName
          );
        };
      });

      const computedMinAmountOfQuestions = computed(() => {
        if (data.form.questionCatalog === null) return 1;
        if (data.form.managementLevel === null) return 1;

        const managementLevelId = data.form.managementLevelId;
        const currentQuestionCatalogLevel =
          data.form.questionCatalog.levels.find((questionCatalogLevel) => {
            return questionCatalogLevel.level.id === managementLevelId;
          });

        if (typeof currentQuestionCatalogLevel !== 'undefined') {
          const amountOfMinimumQuestions =
            currentQuestionCatalogLevel.catalogQuestions.filter(
              (catalogQuestion) => {
                return [QuestionType.MANDATORY, QuestionType.PINNED].includes(
                  catalogQuestion.question.type,
                );
              },
            ).length;
          return amountOfMinimumQuestions > 0 ? amountOfMinimumQuestions : 1;
        }

        return 1;
      });

      const computedMaxAmountOfQuestions = computed(() => {
        if (data.form.questionCatalog === null) return 1;
        if (data.form.managementLevel === null) return 1;

        const managementLevelId = data.form.managementLevelId;
        const currentQuestionCatalogLevel =
          data.form.questionCatalog.levels.find((questionCatalogLevel) => {
            return questionCatalogLevel.level.id === managementLevelId;
          });

        if (typeof currentQuestionCatalogLevel !== 'undefined') {
          const amountOfMinimumQuestions =
            currentQuestionCatalogLevel.catalogQuestions.length;
          return amountOfMinimumQuestions > 0 ? amountOfMinimumQuestions : 1;
        }

        return 1;
        // const managementLevelId = data.form.managementLevelId;
        // const currentQuestionCatalogLevel =
        //   data.form.questionCatalog.levels.find((questionCatalogLevel) => {
        //     return questionCatalogLevel.level.id === managementLevelId;
        //   });
        //   ? currentQuestionCatalogLevel.catalogQuestions.length
        //   : 1;
      });

      const computedCardTitle = computed(() => {
        if (data.isAuditPlan) {
          if (computedIsLoadedAuditPlan.value) {
            return t('spc.auditPlan.edit');
          } else {
            return t('spc.auditPlan.create');
          }
        }

        return data.isAudit
          ? t('spc.processconfirmation.newAudit')
          : t('spc.processconfirmation.new');
      });

      const computedQuestionsAmountForManagementLevel = computed(() => {
        return (managementLevel: ManagementLevel) => {
          const foundManagementLevel = data.form.questionCatalog?.levels.find(
            (catalogLevel: QuestionCatalogLevel) => {
              return catalogLevel.level.uId === managementLevel.uId;
            },
          );
          if (foundManagementLevel) {
            return foundManagementLevel.catalogQuestions.length;
          }
          return 0;
        };
      });

      function resetCreateForm() {
        data.form = Object.assign(
          {},
          {
            questionCatalogId: null,
            questionCatalog: null,
            managementLevelId: null,
            managementLevel: null,
            amountOfQuestions: 0,
            amountToCreate: 1,
          },
        );
        if (computedIsLoadedAuditPlan.value === false) {
          data.processConfirmation = Object.assign({
            type: ProcessConfirmationType.AUDIT,
          }) as ProcessConfirmation;
        }
        // createForm.value.resetForm();
      }

      function handleTypeChange() {
        if (data.isAudit) {
          data.processConfirmation.type = ProcessConfirmationType.AUDIT;
          data.form.amountOfQuestions = computedMaxAmountOfQuestions.value;
        } else {
          data.processConfirmation.type =
            ProcessConfirmationType.PROCESS_CONFIRMATION;
          data.form.amountOfQuestions = computedMinAmountOfQuestions.value;
        }
      }

      const computedMarks = computed(() => {
        const marks: Mark = {} as Mark;
        for (
          let i = computedMinAmountOfQuestions.value;
          i <= computedMaxAmountOfQuestions.value;
          i++
        ) {
          marks[i] = String(i);
        }
        return marks;
      });

      const computedSubmitButtonClass = computed(() => {
        if (data.isAudit === false) return 'btn btn-info';
        return 'btn btn-primary';
      });

      const computedIsLoadedAuditPlan = computed(() => {
        return typeof data.processConfirmation.id !== 'undefined';
      });

      const computedSubmitButtonCaption = computed(() => {
        if (data.isAuditPlan) {
          if (computedIsLoadedAuditPlan.value) {
            return t('spc.auditPlan.saveChanges');
          } else {
            return t('spc.auditPlan.create');
          }
        }

        return data.isAudit
          ? t('spc.processconfirmation.createAudit')
          : t('spc.processconfirmation.create');
      });

      async function deleteCurrentAuditPlan() {
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        await ApiService.delete(
          `spc/audit/plan/${data.processConfirmation.id}`,
        );

        ElNotification({
          title: t('spc.auditPlan.success.removed.title'),
          message: t('spc.auditPlan.success.removed.text'),
          type: 'success',
        });

        router.push('/spc/audit/plans');
      }

      async function handleAuditPlanSubmit() {
        if (data.form.managementLevel === null) return false;
        if (data.form.questionCatalog === null) return false;

        store.dispatch(Actions.START_LOADER);

        const newAuditPlan = Object.assign({}, data.auditPlan);

        if (typeof data.processConfirmation.id !== 'undefined') {
          newAuditPlan.id = data.processConfirmation.id;
        }

        newAuditPlan.locations =
          selectedOrgLevels.value.map((orgLevel, index) => {
            const orgLevelIdentifier = computedOrgLevelHierarchy(index);
            return {
              locationIdentifier: orgLevelIdentifier,
              locationName: String(orgLevel?.name),
              locationId: String(orgLevel?.id),
              pos: index,
            } as ProcessConfirmationLocation;
          }) || [];

        newAuditPlan.questionCatalog = Object.assign(
          {},
          data.form.questionCatalog,
        );

        newAuditPlan.level = Object.assign({}, data.form.managementLevel);

        newAuditPlan.name = data.processConfirmation.name;
        newAuditPlan.type = data.processConfirmation.type;
        newAuditPlan.description = data.processConfirmation.description;
        newAuditPlan.amount = data.form.amountToCreate;

        try {
          await ApiService.put(`spc/audit/plan`, {
            data: newAuditPlan,
          });

          if (typeof newAuditPlan.id === 'undefined') {
            ElNotification({
              title: t('spc.auditPlan.success.created.title'),
              message: t('spc.auditPlan.success.created.text'),
              type: 'success',
            });
            resetCreateForm();
            createForm.value.resetForm();
          } else {
            ElNotification({
              title: t('spc.auditPlan.success.saved.title'),
              message: t('spc.auditPlan.success.saved.text'),
              type: 'success',
            });
          }
        } catch (error) {
          console.error('ERROR while saving AuditPlan', error);
        }

        store.dispatch(Actions.END_LOADER);
      }

      async function handleProcessConfirmationSubmit() {
        if (data.form.managementLevel === null) return false;
        if (data.form.questionCatalog === null) return false;

        if (data.isAuditPlan === true) {
          await handleAuditPlanSubmit();
          return;
        }

        store.dispatch(Actions.START_LOADER);

        const amountToCreateCalculated =
          computedIsWorker.value === true ? 1 : data.form.amountToCreate;

        const auditsToCreate: ProcessConfirmation[] =
          [] as ProcessConfirmation[];
        for (let i = 0; i < amountToCreateCalculated; i++) {
          const newProcessConfirmation: ProcessConfirmation = Object.assign(
            {
              answers: [] as QuestionAnswer[],
            },
            data.processConfirmation,
          );

          if (data.isAudit === true) {
            // const auditAnswers = [] as QuestionAnswer[];

            const questionCatalogLevel = data.form.questionCatalog.levels.find(
              (questionCatalogLevel: QuestionCatalogLevel) => {
                return (
                  questionCatalogLevel.level.id === data.form.managementLevelId
                );
              },
            );
            if (questionCatalogLevel === undefined) return false;

            newProcessConfirmation.answers =
              questionCatalogLevel.catalogQuestions
                .sort(
                  (questionA: CatalogQuestion, questionB: CatalogQuestion) => {
                    if (questionA.pos < questionB.pos) {
                      return -1;
                    }
                    if (questionA.pos > questionB.pos) {
                      return 1;
                    }
                    return 0;
                  },
                )
                .map((catalogQuestion: CatalogQuestion): QuestionAnswer => {
                  const newAnswer: QuestionAnswer = {
                    question: catalogQuestion.question,
                    pos: catalogQuestion.pos + 1,
                  } as QuestionAnswer;
                  return newAnswer;
                });

            // newProcessConfirmation.answers = pinnedAnswers
            //   .concat(shuffle(mandatoryAnswers), defaultAnswers)
            //   .map((questionAnswer: QuestionAnswer, index: number) => {
            //     questionAnswer.pos = index + 1;
            //     return questionAnswer;
            //   });
          } else {
            let indexCounter = 0;

            const pinnedAnswers = [] as QuestionAnswer[];
            const mandatoryAnswers = [] as QuestionAnswer[];
            const defaultAnswers = [] as QuestionAnswer[];

            const questionCatalogLevel = data.form.questionCatalog.levels.find(
              (questionCatalogLevel: QuestionCatalogLevel) => {
                return (
                  questionCatalogLevel.level.id === data.form.managementLevelId
                );
              },
            );

            if (questionCatalogLevel === undefined) return false;

            questionCatalogLevel.catalogQuestions
              .filter((catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.type === QuestionType.PINNED;
              })
              .forEach((catalogQuestion: CatalogQuestion) => {
                const newAnswer: QuestionAnswer = {
                  question: catalogQuestion.question,
                } as QuestionAnswer;
                indexCounter++;
                pinnedAnswers.push(newAnswer);
              });

            questionCatalogLevel.catalogQuestions
              .filter((catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.type === QuestionType.MANDATORY;
              })
              .forEach((catalogQuestion: CatalogQuestion) => {
                const newAnswer: QuestionAnswer = {
                  question: catalogQuestion.question,
                } as QuestionAnswer;
                indexCounter++;
                mandatoryAnswers.push(newAnswer);
              });

            const defaultQuestions = shuffle(
              questionCatalogLevel.catalogQuestions.filter(
                (catalogQuestion: CatalogQuestion) => {
                  return catalogQuestion.question.type === QuestionType.DEFAULT;
                },
              ),
            );

            defaultQuestions.forEach((catalogQuestion: CatalogQuestion) => {
              if (indexCounter < data.form.amountOfQuestions) {
                const newAnswer: QuestionAnswer = {
                  question: catalogQuestion.question,
                } as QuestionAnswer;
                indexCounter++;
                defaultAnswers.push(newAnswer);
              }
            });

            newProcessConfirmation.answers = pinnedAnswers
              .concat(shuffle(mandatoryAnswers), defaultAnswers)
              .map((questionAnswer: QuestionAnswer, index: number) => {
                questionAnswer.pos = index + 1;
                return questionAnswer;
              });
          }

          newProcessConfirmation.locations =
            selectedOrgLevels.value.map((orgLevel, index) => {
              const orgLevelIdentifier = computedOrgLevelHierarchy(index);
              return {
                locationIdentifier: orgLevelIdentifier,
                locationName: String(orgLevel?.name),
                locationId: String(orgLevel?.id),
                pos: index,
              } as ProcessConfirmationLocation;
            }) || [];

          newProcessConfirmation.questionCatalog = Object.assign(
            {},
            data.form.questionCatalog,
          );

          auditsToCreate.push(newProcessConfirmation);
        }
        try {
          const newProcessConfirmationResponse: AxiosResponse<ProcessConfirmation> =
            await ApiService.put(`spc/audit`, {
              data: auditsToCreate,
            });

          if (computedIsWorker.value === true) {
            router.push(
              '/spc/audit/' + newProcessConfirmationResponse.data[0].id,
            );
            store.dispatch(Actions.END_LOADER);
            return;
          }

          if (data.form.amountToCreate > 1) {
            const textSlug =
              data.processConfirmation.type ===
              ProcessConfirmationType.PROCESS_CONFIRMATION
                ? t('spc.processconfirmation.success.createdMultiple.text')
                : t(
                    'spc.processconfirmation.success.createdMultiple.textAudit',
                  );

            ElNotification({
              title: t('spc.processconfirmation.success.createdMultiple.title'),
              message: textSlug,
              type: 'success',
            });
          } else {
            const textSlug =
              data.processConfirmation.type ===
              ProcessConfirmationType.PROCESS_CONFIRMATION
                ? t('spc.processconfirmation.success.created.text', {
                    url:
                      '#/spc/audit/' +
                      newProcessConfirmationResponse.data[0].id,
                  })
                : t('spc.processconfirmation.success.created.textAudit', {
                    url:
                      '#/spc/audit/' +
                      newProcessConfirmationResponse.data[0].id,
                  });

            ElNotification({
              title: t('spc.processconfirmation.success.created.title'),
              message: textSlug,
              dangerouslyUseHTMLString: true,
              type: 'success',
            });
          }

          resetCreateForm();
          createForm.value.resetForm();
        } catch (error) {
          console.error('ERROR while saving ProcessConfirmation', error);
        }

        store.dispatch(Actions.END_LOADER);

        // Swal.fire(t('done'), t('spc.processconfirmation.created'), 'success');
      }

      return {
        data,
        computedDate,
        computedOrgOptions,
        computedAvailableOrgLevels,
        computedOrgLevelHierarchy,
        handleOrgUnitChange,
        isLocationComplete,
        handleLocationChange,
        selectedOrgLevels,
        releaseLocationForm,
        processConfirmationCreateValidator,
        computedQuestionCatalogOptions,
        computedFilteredQuestionCatalogOptions,
        computedQuestionCatalogQuestionAmount,
        computedFileUrl,
        computedButtonClass,
        computedMarks,
        computedHasQuestionCatalog,
        computedHasManagementLevel,
        computedManagementLevelsOptions,
        handleQuestionCatalogChange,
        handleManagementLevelChange,
        computedMinAmountOfQuestions,
        computedMaxAmountOfQuestions,
        handleProcessConfirmationSubmit,
        computedQuestionsAmountForManagementLevel,
        createForm,
        handleTypeChange,
        computedCardTitle,
        computedSubmitButtonCaption,
        computedSubmitButtonClass,
        computedCurrentUserManagementArea,
        computedCurrentUserManagementAreaType,
        computedIsManager,
        computedIsWorker,
        computedAllIsAllowed,
        computedTypeName,
        computedIntervalOptions,
        computedIsMissingManagementLevel,
        computedIsMissingManagementArea,
        computedIsMissingUserConfig,
        computedIsLoadedAuditPlan,
        deleteCurrentAuditPlan,
      };
    },
  });
</script>

<style scoped></style>
