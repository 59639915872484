<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <div class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ $t('allUsers') }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ data.users.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->
          <div class="card-toolbar">
            <input
              type="text"
              class="form-control form-control-solid"
              :placeholder="$t('spc.questionCatalog.searchUser')"
              v-model="data.userSearch"
            />
          </div>
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <KTDatatable
            :key="computedUsers.length"
            v-if="data.isLoaded === true"
            :table-data="computedUsers"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="true"
          >
            <template v-slot:cell-name="{ row: user }">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <span class="text-dark fw-bolder fs-6">{{ user.name }}</span>
                </div>
              </div>
            </template>
            <template v-slot:cell-username="{ row: user }">
              <span>{{ user.username }}</span>
            </template>
            <template v-slot:cell-mail="{ row: user }">
              <span>{{ user.mail }}</span>
            </template>
            <template v-slot:cell-groups="{ row: user }">
              <span
                :key="group.name"
                class="badge fs-8 fw-bolder badge-light-primary me-2"
                v-for="group of user.groups"
                >{{ group }}</span
              >
            </template>
            <template v-slot:cell-managementLevel="{ row: user }">
              <select
                class="form-select form-select-solid"
                v-model="user.managementLevel"
                @change="updateUser(user)"
              >
                <option :value="null">{{ $t('none') }}</option>
                <option
                  v-for="managementLevel of data.managmentLevels"
                  :key="managementLevel.id"
                  :value="managementLevel"
                >
                  {{ managementLevel.name }}
                </option>
              </select>
            </template>
            <template v-slot:cell-active="{ row: user }">
              <span v-if="user.active" class="badge badge-success">{{
                $t('state.active')
              }}</span>
              <span v-else class="badge badge-danger">{{
                $t('state.inactive')
              }}</span>
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { useRouter } from 'vue-router';
  import KTDatatable, {
    IHeaderConfiguration,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { User } from '@/store/modules/AuthModule';
  import { ManagementLevel, SPC_User, SPC_View_User } from '@/core/models/spc';
  // import FilterOptions from '@/components/dlp/FilterOptions.vue';

  export default defineComponent({
    name: 'spcUsers',
    components: {
      KTDatatable,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();

      const data: {
        pageTitle: string;
        users: SPC_View_User[];
        checkedElements: string[];
        isLoaded: boolean;
        headerConfig: IHeaderConfiguration[];
        managmentLevels: ManagementLevel[];
        userSearch: string;
      } = reactive({
        pageTitle: t('viewUserManagement'),
        users: [],
        checkedElements: [],
        isLoaded: false,
        headerConfig: [
          {
            name: t('user.idAndName'),
            key: 'name',
            sortable: true,
          } as IHeaderConfiguration,
          {
            name: t('username'),
            key: 'username',
            sortable: true,
          },
          {
            name: t('user.mail'),
            key: 'mail',
            sortable: true,
          },
          {
            name: t('user.groups'),
            key: 'groups',
            sortable: true,
            sortingField: 'groups.length',
          },
          {
            name: t('spc.processconfirmation.managementLevel'),
            key: 'managementLevel',
            sortable: true,
            sortingField: 'managementLevel !== null',
          },
          {
            name: t('state.view'),
            key: 'active',
            sortable: true,
          },
        ],
        managmentLevels: [] as ManagementLevel[],
        userSearch: '',
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      const computedUsers = computed(() => {
        return data.users.filter((user: SPC_View_User) => {
          if (data.userSearch === '') {
            return true;
          }
          if (
            user.name.toLowerCase().includes(data.userSearch.toLowerCase()) ||
            user.username
              .toLowerCase()
              .includes(data.userSearch.toLowerCase()) ||
            user.mail.toLowerCase().includes(data.userSearch.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      const renderUserData = (apiUsers: SPC_User[]): SPC_View_User[] => {
        return apiUsers.map((user: SPC_User) => {
          const viewUser = Object.assign(user, {
            managementLevel: user.managementLevel
              ? user.managementLevel.level
              : null,
          }) as SPC_View_User;
          // let returnUser = { ...user };
          // if (user.managementLevel !== null) {
          //   returnUser = {
          //     ...returnUser,
          //     managementLevel: user.managementLevel.level,
          //   } as SPC_View_User;
          // }
          return viewUser;
        });
      };

      async function loadUsers() {
        if (data.users.length !== 0) return false;

        const loadConfig = {
          data: {},
        };

        const apiUsers = await ApiService.post('spc/users', loadConfig);
        data.users = renderUserData(apiUsers.data) as SPC_View_User[];
        data.isLoaded = true;
      }

      async function loadManagementLevels() {
        if (data.managmentLevels.length !== 0) return false;

        const managementLevels = await ApiService.get(
          'spc/config/managementlevel',
        );
        data.managmentLevels = managementLevels.data;

        data.isLoaded = true;
      }

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        await loadManagementLevels();
        await loadUsers();
        store.dispatch(Actions.END_LOADER);
      });

      const updatePassword = async (user) => {
        const passwordChangeFormResult = await Swal.fire({
          text: `Set a new password for ${user.mail}`,
          icon: 'question',
          input: 'password',
          inputAttributes: {
            minlength: '4',
          },
          showCancelButton: true,
          confirmButtonText: t('continue'),
          cancelButtonText: t('cancel'),
        });

        if (!passwordChangeFormResult.isConfirmed) {
          return false;
        }

        const newPassword = String(passwordChangeFormResult.value);

        if (newPassword.length < 4) {
          Swal.fire(t('error'), t('passwordTooShort'), 'error');
          return false;
        }

        const passwordChangeRepeatFormResult = await Swal.fire({
          text: `Repeat new password for ${user.mail}`,
          icon: 'question',
          input: 'password',
          showCancelButton: true,
          confirmButtonText: t('save'),
          cancelButtonText: t('cancel'),
        });

        if (!passwordChangeRepeatFormResult.isConfirmed) {
          return false;
        }

        const newPasswordRepeat = passwordChangeRepeatFormResult.value;
        const doPasswordsMatch = newPassword === newPasswordRepeat;

        if (!doPasswordsMatch) {
          Swal.fire(t('error'), t('passwordsDoNotMatch'), 'error');
          return false;
        }

        const userUpdateUrl = `users/${user.id}/password`;
        await ApiService.put(userUpdateUrl, {
          data: {
            password: newPassword,
          },
        });

        const currentUser = { ...store.getters.currentUser };
        if (user.id === currentUser.id) {
          store.commit(Mutations.PURGE_AUTH);
          router.push({ name: 'sign-in' });
          Swal.fire(t('done'), t('loginWithNewCredentials'), 'success');
        } else {
          Swal.fire(t('done'), t('passwordChanged'), 'success');
        }
      };

      async function updateUser(user: SPC_View_User) {
        store.dispatch(Actions.START_LOADER);
        await ApiService.put('spc/users/managementlevel', {
          data: user,
        });
        data.users = data.users.map((loopedUser: SPC_View_User) => {
          if (user.id === loopedUser.id) {
            loopedUser.managementLevel = user.managementLevel;
          }
          return loopedUser;
        });
        store.dispatch(Actions.END_LOADER);
      }

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  await ApiService.delete(`/users/${id}`);
                }),
              );

              data.users = data.users.filter((user: User) => {
                if (!user.id) return false;
                return !data.checkedElements.includes(user.id);
              });

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      return {
        data,
        deleteSelected,
        updatePassword,
        computedCurrentUserIsAdmin,
        updateUser,
        computedUsers,
      };
    },
  });
</script>
