export default {
  dashboard: 'Dashboard',
  language: 'Language',
  layoutBuilder: 'Layout builder',
  craft: 'Crafted',
  pages: 'Pages',
  profile: 'Profile',
  projects: 'Projects',
  campaigns: 'Campaigns',
  documents: 'Documents',
  connections: 'Connections',
  wizards: 'Wizards',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Account',
  overview: 'Overview',
  overviews: 'Overviews',
  settings: 'Settings',
  authentication: 'Authentication',
  basicFlow: 'Basic flow',
  signIn: 'Sign in',
  signUp: 'Sign up',
  passwordReset: 'Reset password',
  error: 'Error',
  warning: 'Warning',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Apps',
  chat: 'Chat',
  privateChat: 'Private chat',
  groupChat: 'Privater group chat',
  drawerChat: 'Groupchat drawer chat',
  widgets: 'Widgets',
  widgetsLists: 'Lists',
  widgetsStatistics: 'Statistics',
  widgetsCharts: 'Charts',
  widgetsMixed: 'Mixed',
  widgetsTables: 'Tables',
  widgetsFeeds: 'Feeds',
  changelog: 'Changelog',
  docsAndComponents: 'Documents and components',
  megaMenu: 'Mega menu',
  exampleLink: 'Example link',
  modals: 'Modals',
  general: 'General',
  inviteFriends: 'Invite friends',
  viewUsers: 'Show users',
  upgradePlan: 'Upgrade plan',
  shareAndEarn: 'Earn & Share',
  forms: 'Forms',
  newTarget: 'New target',
  newCard: 'New card',
  newAddress: 'New address',
  createAPIKey: 'Create API key',
  twoFactorAuth: '2-Factor authentication',
  createApp: 'Create app',
  createAccount: 'Create account',
  activity: 'Aktivity',
  documentation: 'Documentation',
  components: 'Components',
  resources: 'Resources',
  customers: 'Customers',
  gettingStarted: 'Getting started',
  customersListing: 'Customer listing',
  customerDetails: 'Customer details',
  calendarApp: 'Calendar',
  subscriptions: 'Subscriptions',
  getStarted: 'Entry',
  subscriptionList: 'Subscription list',
  addSubscription: 'Add subscription.',
  viewSubscription: 'View subscription.',
  allUsers: 'All users',
  createUser: 'Create account',
  viewUserManagement: 'User management',
  sureQuestionHeadline: 'Are you shure?',
  cancel: 'Cancel',
  save: 'Save',
  saveChanges: 'Save',
  saving: 'Saving ...',
  done: 'Done',
  continue: 'Continue',
  passwordsDoNotMatch: 'Passwords do not match',
  passwordChanged: 'Password changed',
  passwordTooShort: 'Passwort to short',
  loginWithNewCredentials: 'You can now login with your new credentials',
  clientVersion: 'Client Version',
  username: 'Username',
  viewOrgChart: 'Organisational structure',
  tags: 'Tags',
  newNode: 'New node',
  removeNode: 'Remove node',
  remove: 'Delete',
  responsiblePeople: 'Responsible persons',
  noResponsiblePeople: 'Non responsible persons',
  filterOptions: 'Filter options',
  filter: 'Filter',
  resetFilters: 'Reset filters',
  reset: 'Reset',
  edit: 'Edit',
  loadingData: 'Loading data...',
  administration: 'Administration',
  viewOPLOverview: 'Measure overview',
  pleaseSelect: 'Please select',
  errorAlertCaption: 'There was an error',
  errorCaseNotFound: 'Error case not found',
  processInterruptionNotFound: 'Process interruption not found',
  openPointNotFound: 'Measure not found',
  send: 'Send',
  release: 'Release',
  delete: 'Delete',
  none: 'None',
  direct: 'Direct',
  depth: 'Depth',
  selected: 'selected',
  inMinutes: '(in minutes)',
  minutes: 'Minutes',
  createdAt: 'Created at',
  actions: 'Actions',
  accept: 'Accept',
  decline: 'Decline',
  noOne: 'No one',
  configuration: 'Configuration',
  deleted: 'Deleted',
  unknown: 'Unknown',
  backToStart: 'Back to the start',
  structure: 'Structure',
  all: 'All',
  xSelected: '{x} selected',
  savesAutomatically: 'Saves automatically',
  noEntries: 'No entries',
  tabs: {
    fes: 'Error Cases',
    opl: 'Open Points',
    spc: 'SPC',
    cdf: 'CDF',
  },
  cdf: {
    nav: {
      orgChart: 'Organisational structure',
    },
    orgUnit: {
      title: 'Name',
      removeQuestion:
        'Do you want the node {name}, all its connections and child nodes to be removed?',
      assignTagsDownwardsQuestion:
        'Would you also like to assign the tags {tags} to all underlying structure elements?',
    },
    orgChart: {
      responsibles: {
        singular: 'Responsible person',
        plural: 'Responsible persons',
        none: 'Non Responsible persons',
      },
      tags: {
        singular: 'Tag',
        plural: 'Tags',
        none: 'No tags',
      },
      buttons: {
        openAllNodes: 'Open all nodes',
        closeAllNodes: 'Close all nodes',
      },
    },
    hierarchyNames: {
      headline: 'Headline',
    },
  },
  fes: {
    nav: {
      dashboard: 'FES Dashboard',
      start: 'Error',
      startPi: 'Measure interruption',
      catalogs: 'Catalogue',
      collection: 'Collection',
      decisions: 'Decision collection',
      riskrisks: 'Risk collection',
      causes: 'Cause collection',
      interuptions: 'Interruption collection',
      overview: 'Overviews',
      processInterruptions: 'Process interruptions',
    },
    barcodeModal: {
      enableManualButton: 'Activate manual input',
    },
    form: {
      riskLevel: 'Risk level',
      new: 'New error case',
      errorLocation: 'Error location',
      barcode: 'Barcode',
      barcodes: 'Barcodes',
      noBarcodes: 'No barcodes',
      qrCode: 'QR-Code',
      addCode: 'Add barcode',
      errorCase: 'Error case',
      errorCaseDetail: 'Error details',
      back: 'back',
      next: 'next',
      continueWorkFinal: 'Work can be continued. Parts are approved.',
      continueWork: 'Work can be continued.',
      messageAfterWorkerProcess:
        'Error was saved. Please stick the barcode to the defect part.',
      messageNoBarcode: 'Atleast 1 barcode must be added.',
      decisionEdits: 'Change count: {count}',
      details: {
        heading: 'Information to the defect part',
        subtext: 'Please add information for the defect part.',
        partName: 'Part name',
        partReference: 'Part number',
        jobReference: 'Order number',
        amounts: 'Amounts',
        amountTotal: 'Order amount',
        amountChecked: 'Checked amount',
        amountFlawed: 'Flawed amount',
        materialName: 'Material name',
        materialReference: 'Material number',
        faufReference: 'FA or FAUF number',
        faufReferenceShort: 'FA number',
        customerReference: 'Customer reference',
        customerOrderReference: 'Order reference',
        checkReference: 'Check reference',
        sapReference: 'SAP reference',
        errorCaseNumber: 'Error case number',
      },
      moreDetails: {
        heading: 'Details about the error',
        subtext: 'Please add the error information.',
        category: 'Error category',
        description: 'Error description',
        locationRiskCategory: 'Error category',
        errorPlace: 'Error place',
        errorStyle: 'Error kind',
        riskLevel: 'Risiko level',
        productCategory: 'Product category',
        productCategoryShort: 'Category',
        productSubCategory: 'Product sub category',
        productSubCategoryShort: 'Sub category',
      },
      processInterruption: {
        new: 'New process interruption',
        self: 'Process interruption',
        heading: 'Details about the process interruption',
        subtext: 'Please add the details.',
        category: 'Main category',
        addCategory: 'New main category',
        addSubCategory: 'New sub category',
        subCategory: '1. sub category',
        subSubCategory: '2. sub category',
        reason: 'Reason process interruption',
        none: 'No process interruption',
        interruptionType: 'Type of interruption',
        downtimeMachine: 'Machine interruption',
        downtimeWorker: 'Worker interruption',
        connectedError: 'Connected error case',
        connectedErrorDetails: '#{id} with {style} at {place}',
        interruptionTypes: {
          isMachineBlocked: 'Machine blocked',
          isWorkerBlocked: 'Worker blocked',
        },
      },
      productCategory: {
        addCategory: 'New product category',
        addSubCategory: 'New sub product category',
      },
      decision: {
        title: 'Measures & decisions',
        heading: 'Details about the decision',
        subtext: 'Please add details about the decision.',
        decider: 'Decider',
        decision: 'Decision',
        user: 'User',
        name: 'Name',
        department: 'Department',
        phone: 'Phome',
        mail: 'Mail',
      },
      causes: {
        heading: 'Details about the cause',
        subtext: 'Please add details about the cause.',
        cause: 'Cause',
        causeReason: 'Causative person',
      },
      errors: {
        missingBarcode: 'Please add atleast 1 barcode.',
        duplicateBarcode: 'The barcode is already in the list.',
        duplicateBarcodeSaved: 'This barcode is already in use.',
        barcodeNotFound: 'The barcode was nout found.',
        missingProductCategory: 'Please select a product category.',
        missingProductSubCategory: 'Please add a product subcategory',
        missingLocationRiskCategory: 'Please add a error category.',
        missingErrorPlace: 'Please add a error place.',
        missingErrorStyle: 'Please add a error type',
        missingDescription: 'Please add a error description.',
        missingPartName: 'Please add a part name.',
        missingPartReference: 'Please add a part reference.',
        missingJobReference: 'Please add a job reference.',
        missingAmountTotal: 'Please add a total amount.',
        missingAmountChecked: 'Please add the checked amount.',
        amountCheckedTooHigh:
          'The checked amount cannot be greater as the toal.',
        missingAmountFlawed: 'Please add the total flawed part amount.',
        amountFlawedTooHigh:
          'The flawed part amount cannot be greater as the total checked.',
        missingDecision: 'Please add a decision.',
        missingCause: 'Please add a cause.',
        missingCauseReason: 'Please add a causative person.',
        missingMaterialReference: 'Please add the material number.',
        missingFaufReference: 'Please add the FA or FAUF number.',
        processInterruption: {
          missingCategory:
            'Please add the main group for the process interruption.',
          missingSubCategory:
            'Please add the 1. subcategory for the process interruption.',
          missingSubSubCategory:
            'Please add the 2. subcategory for the process interruption.',
          missingReason: 'Please add a reason for the process interruption.',
          missingDonwtimeMachine:
            'Please add a interruption time for the machine.',
          missingDowntimeWorker:
            'Please add a interruption time for the worker.',
        },
        missingComment: 'Please add a comment.',
      },
      barcodeHint: {
        button: 'Scan Barcode',
        heading: 'Scan barcode now',
        subtext: 'Please scan a barcode',
        generateLhdCode: 'Generate LHD number',
      },
      steps: {
        1: {
          title: 'Error details',
          detail: 'Information for the flawed part',
        },
        2: {
          title: 'Error category',
          detail: 'Information for the error',
        },
        3: {
          title: 'Barcodes',
        },
        4: {
          title: 'Process interruption',
          detail: 'Details for the process interruption',
        },
        5: {
          title: 'Reason & Decision',
          detail: 'Details for the reason & decision',
        },
        6: {
          title: 'Measures',
          detail: 'Details for the measures',
        },
      },
      scanMode: 'Scan Mode',
      on: 'On',
      off: 'Off',
      simpleLevelWarning: {
        title: 'The Error Case is released',
        text: 'Please enter your name on the red barcode sticker under Exhibitor and Approval.',
      },
      blockedAlert: 'This The Error Case is currently being edited by {name}.',
      releaseBlockedErrorButton: 'Released',
    },
    catalog: {
      decisions: {
        subtext: 'More information about the decision catalogue',
        selectStructure: '1. Please select a structure.',
      },
    },
    config: {
      catalogs: 'Catalogue',
      useFromTemplate: 'Use as template',
      linkButtonNew: 'Assign now',
      noDecisionCatalog: 'No decision catalogue',
      individualDecisionCatalog: 'Individual decision catalogue',
      selectedIndividualDecisionCatalog:
        'Decision catalogue #{id} from {date} with {amount} available decisions',
      inheritDecisionCatalog: 'Inherited decision catalogue',
      availableDecisionsInCatalog: 'Available decisions in,',
      allDecisions: 'All decisions',
      noCauseCatalog: 'No cause catalogue',
      individualCauseCatalog: 'Individual cause catalogue',
      selectedIndividualCauseCatalog:
        'Ursachenkatalog #{id} from {date} with {amount} available causes',
      inheritCauseCatalog: 'Inherited cause catalogue',
      availableCausesInCatalog: 'Available casuses in,',
      allCauses: 'All causes',
      noCauseReasonCatalog: 'No causer catalog',
      individualCauseReasonCatalog: 'Individual causer catalog',
      selectedIndividualCauseReasonCatalog:
        'Causer catalog #{id} from {date} with {amount} available causer',
      inheritCauseReasonCatalog: 'Inherited causer catalog',
      availableCauseReasonsInCatalog: 'Available causer in,',
      allCauseReasons: 'All causers',
      noRiskCatalog: 'No risk catalog',
      individualRiskCatalog: 'Individual risk catalog',
      selectedIndividualRiskCatalog:
        'Risk catalog #{id} from {date} with {amount} available risk levels',
      inheritRiskCatalog: 'Inherited risk catalog',
      missingCategoriesRiskCatalog: 'You must select atleast one risk catalog.',
      missingErrorPlaceRiskCatalog:
        'Atleast one error place and one error type must be selected.',
      allErrorPlaces: 'All error places',
      allErrorStyles: 'All error types',
      selectProcessInterruptionCatalogHeadline: 'Main and subcategories',
      selectProductCategoryCatalogHeadline: 'Product main and subcategories',
      noProcessInterruptionCatalog: 'No process interruption catalog',
      individualProcessInterruptionCatalog:
        'Individual process interruption catalog',
      inheritProcessInterruptionCatalog:
        'Inherited process interruption catalog',
      noProductCategoryCatalog: 'No product category catalog',
      individualProductCategoryCatalog: 'Individual product category catalog',
      inheritProductCategoryCatalog: 'Inherited product category catalog',
      decisionCatalog: 'Decision catalog',
      causeCatalog: 'Cause catalog',
      causeReasonCatalog: 'Causer catalog',
      riskCatalog: 'Risk catalog',
      riskCatalogLevels: 'Risk level assigments',
      allErrorCategories: 'All error categories',
      errorCategoryNeeded: 'You must select a error category',
      processInterruptionCatalog: 'Process interruption catalog',
      productCategoryCatalog: 'Product category catalog',
      selectedProductCatalog:
        'Product category catalog #{id} from {date} with {amount} available product categories',
      connect: 'Assign',
      connectAll: 'All assigments',
      disconnect: 'Disconnet',
      decisions: 'Decisions',
      causes: 'Causes',
      riskLevel: 'Risk level configuration',
      newDecision: 'New decision',
      addDecision: 'Add new decision',
      addRiskLevel: 'Add new risk level',
      addCause: 'Add new cause',
      addCauseReason: 'Add new causer',
      addErrorStyle: 'Add new error type',
      addErrorPlace: 'Add new error place',
      addLocationRiskCategory: 'Add new error category',
      removeRiskLevelQuestion: 'Are you sure about the risk level deletion?',
      disconnectLocationRiskCategoryQuestion:
        'If you delete this error category, every risk level in will be deleted. Are you sure about the error category deletion?',
      disconnectErrorStyleQuestion:
        'If you delete this error type, every risk level in will be deleted. Are you sure about the error type deletion?',
      disconnectErrorPlaceQuestion:
        'If you delete this error place, every risk level in will be deleted. Are you sure about the error place deletion?',
      deleteDecisionQuestion:
        'Are you sure that you want to delete this decision? In other elements this decision will also be deleted.',
      deleteProcessInterruptionCategoryQuestion:
        'Are you sure that you want to delete this group?',
      deleteProductCategoryQuestion:
        'Are you sure that you want to delete this product category?',
      deleteProcessInterruptionSubCategoryQuestion:
        'Are you sure that you want to delete this sub group?',
      deleteProductSubCategoryQuestion:
        'Are you sure that you want to delete this product subcategory?',
      deleteCauseQuestion:
        'Are you sure that you want to delete this causer? In other elements this causer will also be deleted.',
      deleteCauseReasonQuestion:
        'Are you sure that you want to delete this cause? In other elements this cause will also be deleted.',
      deleteErrorPlaceQuestion:
        'Are you sure that you want to delete this error place? In other elements this error place will also be deleted.',
      deleteErrorStyleQuestion:
        'Are you sure that you want to delete this error type? In other elements this error type will also be deleted.',
      deleteConnectedNodeHint: 'There are connections to {amount} elements:',
      deleteDecisionCatalogQuestion:
        'Do you really want to delete this decision catalog?',
      deleteCauseCatalogQuestion:
        'Do you really want to delete this cause catalog?',
      deleteRiskCatalogQuestion:
        'Do you really want to delete this risk catalog?',
      deleteProcessInterruptionCatalogQuestion:
        'Do you really want to delete this process interruption catalog.',
      decisionNeeded: 'Cause & decision needed',
      locationRiskCategory: {
        add: 'Add new category',
        edit: 'Edit category',
        newName: 'New name',
      },
      noCauseReasonSelected: 'No causer selected.',
      noCauseSelected: 'No cause selected.',
      noDecisionSelected: 'No decision selected.',
      connectAllErrorPlaces: 'Assign to all error categories',
      allProcessInterruptionsInCatalog: 'All process interruptions in',
      noProcessInterruptionAssigned: 'No process interruptions assigned',
      allProductCategoriesInCatalog: 'All product categories.',
      noProductCategoryAssigned: 'No product category assigned',
      missing: {
        title: 'Missing configuration',
        text: 'Go into configuration and make sure there is a fully configured organisational unit.',
      },
      openPointResponsibles: 'Open point responsibles',
    },
    overview: {
      bulkDeleted: 'Elements were deleted',
      risk: 'Risk',
      details: 'Details',
      amounts: 'Amounts',
      location: 'Error place',
      errorLocation: 'Error place / Error type',
      processInterruption: 'Process interruption',
      allProcessInterruptions: 'All process interruptions',
      decision: 'Decision',
      states: {
        open: 'Open',
        notNeeded: 'Not needed',
      },
      all: 'All error cases',
      lockedTooltip:
        'This error case is currently being processed by another employee.',
      newOpenPoint: 'New open point for error case',
    },
    notifications: {
      finalizedError: {
        title: 'Done',
        text: 'This error case was decided.',
      },
    },
    processInterruption: {
      states: {
        open: 'Open',
        accepted: 'Accepted',
        declined: 'Declined',
      },
      stateActions: {
        accept: 'Accept',
        decline: 'Decline',
      },
      form: {
        created: 'The process interruption is created.',
      },
      overview: {
        source: 'Source',
        category: 'Categpry',
        description: 'Description',
      },
    },
    opl: {
      overview: 'All measures from FES',
    },
    noTaksOnErrorCase: 'No measures for the error',
  },
  opl: {
    form: {
      self: 'Measure',
      plural: 'Measure',
      source: 'Sources',
      title: 'Measure subject',
      heading: 'New measure',
      headingSub: 'New sub measure',
      subtext: 'Please record all measures',
      none: 'No measures',
      immediate: 'Immediate measure',
      immediateShort: 'IM',
      accidentPrevention: 'Accident prevention',
      accidentPreventionShort: 'AP',
      assignee: 'Assignee',
      assignees: 'Assignees',
      assigneeMaxHint: 'Max 3 assigments',
      newAssignee: 'New assignee',
      newAssignees: 'New assignees',
      watcher: 'Observer',
      dueDate: 'Implementation due',
      comment: 'Comment',
      comments: 'Comments',
      newComment: 'Create new comment',
      attachments: 'Attachments',
      additional: 'Additional measure',
      additionalChild: 'Addtional sub measure',
      assign: 'Assign',
      postpone: 'Postpone',
      complete: 'Complete',
      decline: 'Decline',
      messageHere: 'Please add your message here.',
      create: 'Create Measure',
      new: 'New Measure',
      createBtn: {
        single: 'Create measure',
        plural: 'Create measures',
      },
      created: 'Measure was created',
      reason: 'Reason',
      activities: 'Activites',
      lastEdit: 'Last activity',
      children: {
        self: 'Sub measure',
        plural: 'Sub measures',
      },
      parent: {
        self: 'origin measures',
        plural: 'origin measures',
      },
      connectedErrorCase: {
        title: 'Connected Error Case',
        text: 'This measure will be linked to the error case file {errorCaseId}.',
      },
    },
    errors: {
      missingTitle: 'Please add a subject for the measure.',
      missingComment: 'Please add a comment.',
      missingDueDate: 'Please add a due date.',
      missingAssignee: 'Please assign atleast one assignee.',
      missingMessage: 'Please provide a message.',
      missingReason: 'Please add a reason.',
      sameAssignee: 'The assignee cannot be the same.',
      missingAttachment: 'Please atleat one image.',
      dueDateNotInFuture: 'The due date needs to be in the future.',
    },
    assigned: 'You are responsible for this measure',
    assignedOther:
      '<span class="badge badge-primary me-3">{name}</span> is for this measure responsible.',
    assignedNone: 'This measure has no assignee',
    assignedInitial: 'The measure has the following assignees:',
    openOpenPoints: 'Open measures',
    changeAssigneeHint: 'Here you can add a new user.',
    changeDueDateHint: 'Here you can selected the due date.',
    declineHint: 'Please add the reason here.',
    changes: {
      assignee:
        'The assignee was changed from <span class="badge badge-light-primary mx-3">{before}</span> to <span class="badge badge-primary mx-3">{after}</span>.',
      additionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> has been added to the measure as an assignee.',
      removeAdditionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> has been removed from the action as an assignee.',
      state:
        'The status has changed from <span class="badge {beforeClass} mx-3">{before}</span> to <span class="badge {afterClass} mx-3">{after}</span>.',
      dueDate:
        'The due date was changed from <span class="badge badge-light-primary mx-3">{before}</span> to <span class="badge badge-primary mx-3">{after}</span>.',
    },
    states: {
      open: 'Open',
      progress: 'In progress',
      done: 'Done',
      closed: 'Closed',
      declined: 'Declined',
      postponed: 'Postponed',
    },
    statesBadges: {
      open: 'primary',
      progress: 'warning',
      done: 'success',
      closed: 'light',
      declined: 'danger',
      postponed: 'warning',
    },
    stateChanges: {
      dueDate: 'Postponement',
      completed: 'Completed',
    },
    stateChangesDecisions: {
      waiting: '{type} has yet to be decided.',
      accepted: '{type} was accepted on the {date} from {decider}.',
      declined: '{type} was delcined on the {date} from {decider}.',
    },
    overview: {
      source: 'Source',
      creator: 'Creator',
      creatorFES: 'Error case creator',
      comments: 'Comments',
      state: 'State',
      errorLocation: 'Error location / Error type',
      processInterruption: 'Process interruption',
      decision: 'Decision',
      states: {
        open: 'Open',
        notNeeded: 'Not needed',
      },
      module: 'Module',
    },
    watchers: {
      manage: {
        title: 'Manage observers',
        hint: 'Users can be found by their name. Alternatively, an email address can be entered.',
      },
    },
    goToErrorCaseLink: 'Go to Error Case',
  },
  spc: {
    nav: {
      overview: 'Overview',
      processconfirmation: 'process confirmation',
      audit: 'Audit',
      collection: 'creation',
      auditOverview: 'Audit overview',
      auditPlans: 'planned audits',
      managementLevel: 'management level {level}',
    },
    processconfirmation: {
      audit: 'Audit',
      audits: 'Audits',
      onlyAudit: 'Only audits',
      filterAll: 'All',
      name: 'Titel',
      description: 'Description',
      new: 'New process confirmation',
      newAudit: 'New audit',
      backToOverview: 'back to overview',
      single: 'Process confirmation',
      multiple: 'Process confirmations',
      onlySingle: 'Only process confirmation',
      location: 'Location',
      amountOfQuestions: 'Question amount',
      create: 'Create process confirmation',
      createAudit: 'Create audit',
      created: 'Process confirmation was created',
      createdAudit: 'Audit was created',
      state: 'State',
      comment: 'Comment',
      progress: 'Progress',
      managementLevel: 'Management level',
      openPointNeeded: 'Connect measure(s)',
      newOpenPoints: 'New measure',
      connectExistingOpenPoint: 'select existing measure',
      noOpenPointsConnected: 'No measure connected.',
      connectedOpenPoints: 'Conntected measures',
      selectOpenPoints: 'Select measure',
      or: 'or',
      and: 'and',
      deselectOpenPointTitle: 'Do you want to delete the measure again?',
      createOpenPointAndConnect: 'Create and connect measure(s)',
      noComment: 'No comments',
      showOnlyErrors: 'Show only divergences',
      amount: 'Amount',
      errors: {
        notFound: 'Process confirmation was not found',
        noDescription: 'No description existing.',
        missingName: 'Please enter a name.',
        missingAmount: 'Please enter a amount.',
        missingCatalog: 'Please select a catalogue.',
        missingManagementLevel: 'Please select a management level.',
        noOpenPoint: {
          title: 'No measure connected.',
          text: 'Please connect atleast one measure.',
        },
      },
      states: {
        open: 'Open',
        progress: 'In progress',
        done: 'Done',
      },
      statesBadges: {
        open: 'primary',
        progress: 'warning',
        done: 'success',
      },
      answers: {
        yes: 'yes',
        no: 'no',
        none: 'Not applicable',
      },
      success: {
        started: {
          title: 'Started',
          text: 'The process confirmation was successfully started.',
          textAudit: 'The audit was successfully started.',
        },
        created: {
          title: 'Created',
          text: 'The process confirmation was successfully created.<br /><a href="{url}">Start now</a>',
          textAudit:
            'The audit was successfully created.<br /><a href="{url}">Start now</a>',
        },
        createdMultiple: {
          title: 'Created',
          text: 'The process confirmations were successfully created',
          textAudit: 'The audits were successfully created.',
        },
        completed: {
          title: 'Completed',
          text: 'The process confirmation was successfully completed.',
          textAudit: 'The audit was successfully completed.',
        },
      },
    },
    auditPlan: {
      intervalRangeSeparator: 'until',
      dateRange: 'Period',
      createAuditPlanCheckbox: 'Create {type} at regular intervals',
      noEndDate: 'No end date',
      interval: 'Interval',
      errors: {
        missingStartDate: 'Please enter a start date.',
        missingInterval: 'Please enter a interval.',
        loadingError: 'The audit plan could not be loaded.',
      },
      startDatePlaceholder: 'Start date',
      endDatePlaceholder: 'End date',
      intervals: {
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
      },
      success: {
        created: {
          title: 'Created',
          text: 'The audit plan was successfully created.',
        },
        saved: {
          title: 'Saved',
          text: 'The audit plan was successfully saved.',
        },
        removed: {
          title: 'Deleted',
          text: 'The audit plan was successfully created.',
        },
      },
      calendar: 'Calendar',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      new: 'New audit plan',
      edit: 'Edit audit plan',
      create: 'Create audit plan',
      saveChanges: 'Save changes',
    },
    questionCatalog: {
      all: 'Question catalogs',
      single: 'Question catalog',
      new: 'New Question catalog',
      none: 'No Question catalogs',
      questionSummarized: 'Different questions',
      questionTotal: 'Total questions',
      managementLevel: 'Management level',
      managementLevels: 'Management levels',
      allManagementLevels: 'All management levels',
      noManagementLevelsTitle: 'No management levels',
      noManagementLevelsText: 'Please select a management level.',
      noQuestionsTitle: 'No questions',
      noQuestionsText: 'Please select a question.',
      noQuestionCatalogs: 'No question catalog',
      managementLevelEdit: {
        add: 'Add new category',
        edit: 'Edit category',
        newName: 'New name',
      },
      disconnectManagementLevelQuestion:
        'Are you sure that you want to disconnect the management levels?',
      addManagementLevel: 'Add management level',
      addQuestion: 'Add question',
      allQuestions: 'All questions',
      deleteQuestionQuestion:
        'Are you sure that you want to delete this question?',
      deleteMangementLevelQuestion:
        'Are you sure that you want to delete this management level?',
      catalogByManagementLevel: 'Question catalog by management level',
      deleteQuestion:
        'Are you sure that you want to delete this management level?',
      image: 'Image',
      searchQuestion: 'Search question...',
      searchUser: 'Search user...',
      deactivated: 'Deactivated',
      inherited: 'Inherited',
      inheritedUntil: 'Inherited until {orgUnitName}',
      managementLevelAmount: '{name} with {amount} questions',
      overwrittenCatalog: 'Overwritten catalog',
      copiedAlert: {
        title: 'Inherited question catalog',
        text: 'The template of this question catalog is from {catalog}.',
      },
      connectedCatalogs: 'Connected catalogs',
    },
    userManagement: {
      responsibles: 'Responsibles',
      workers: 'Workers',
      availableWorkers: 'Available workers',
      selectedWorkers: 'Connected workers',
      connectWorker: 'connect',
      disconnectWorker: 'disconnect',
    },
    question: {
      all: 'All questions',
      title: 'Question',
      type: 'Question type',
      multiple: 'Questions',
      images: 'Images',
      hint: 'Hint',
      externalLink: 'External link',
      noneAllowed: 'Not applicable allowed',
      openPointNeeded: 'Measure needed',
      onlyFromToDates: 'Period',
      onlyFromToDatesFrom: 'From',
      onlyFromToDatesTo: 'To',
      types: {
        default: 'Normal question',
        mandatory: 'Mandatory question',
        pinned: 'Standard question',
      },
      moreDetails: 'More information',
      editHint:
        'This question is connected with the following question catalogs. Changes in the question thus affect the content of these catalogs.',
    },
  },
  state: {
    view: 'State',
    active: 'Active',
    inactive: 'Inactive',
  },
  user: {
    new: 'New user',
    mail: 'Mail',
    groups: 'Groups',
    idAndName: 'ID & Name',
    removed: 'User was removed',
    password: 'Password',
    settings: 'User Settings',
    mailSettings: 'Mail Settings',
    mails: {
      newErrorCase: 'New Error Case',
      afterComplete: 'After Completion of Open Point',
      afterCompleteAccept: 'After Acceptance of Completion of Open Point',
      afterCompleteDecline: 'After Decline of Completion of Open Point',
      afterDecline: 'After Decline of Open Point',
      afterPostpone: 'After Postponement of Open Point',
      afterPostponeAccept: 'After Acceptance of Postponement of Open Point',
      afterPostponeDecline: 'After Decline of Postponement of Open Point',
      afterRequestDecision: 'After Request for Decision of Open Point',
      afterResolve: 'After Resolution of Open Point',
      newAssignee: 'New Assignee',
      newComment: 'New Comment of Open Point',
      newOpenPoint: 'New Open Point',
      afterWatcherAssignment: 'After assignment of observer-role',
      afterWatcherDismissal: 'After removal of observer-role',
    },
  },
  errors: {
    unableToLoadUser: 'User could not be loaded.',
    missingConfig: {
      title: 'Missing configuration',
      text: 'Go into configuration and make sure there is a fully configured organisational unit.',
    },
    missingManagementLevel: {
      title: 'Missing management level',
      text: 'Please contact your administrator. A management level must be available to use the app.',
    },
    missingManagementArea: {
      title: 'Missing allocated area',
      text: 'Please contact your administrator. An assigned area must be available to use the app.',
    },
    deleteManagementLevelNotAllowed: {
      title: 'Deletion not possible',
      text: 'Guide levels that are still connected in other catalogs cannot be deleted.',
    },
  },
  location: {
    selectAll: 'All',
  },
  dateFormat: 'DD.MM.YYYY',
};
