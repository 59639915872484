import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "page-title d-flex flex-column justify-content-center flex-wrap mb-5" }
const _hoisted_4 = { class: "page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserMailSettings = _resolveComponent("UserMailSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('user.settings')), 1)
      ]),
      _createVNode(_component_UserMailSettings)
    ])
  ]))
}