<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <div class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ $t('allUsers') }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ data.users.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Group actions-->
            <div
              v-if="data.checkedElements.length"
              class="d-flex justify-content-end align-items-center"
              data-kt-subscription-table-toolbar="selected"
            >
              <div class="fw-bolder me-5">
                <span
                  class="me-2"
                  data-kt-subscription-table-select="selected_count"
                  >{{ data.checkedElements.length }}</span
                >
                {{ $t('selected') }}
              </div>

              <button
                v-if="computedCurrentUserIsAdmin"
                type="button"
                class="btn btn-danger btn-sm"
                @click="deleteSelected"
              >
                {{ $t('remove') }}
              </button>
            </div>
            <div>
              <el-input
                v-model="data.currentSearch"
                @input="handleSearchChange"
              >
                <template #append>
                  <el-button @click="handleSearchReset">
                    <i class="fas fa-times-circle"></i>
                  </el-button>
                </template>
              </el-input>
            </div>
            <router-link
              to="/cdf/users/edit"
              class="btn btn-sm btn-primary align-self-center ms-3"
              >{{ $t('createUser') }}</router-link
            >
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <KTDatatable
            :key="data.searchCounter"
            v-if="data.isLoaded === true"
            :table-data="computedUsers"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="true"
          >
            <template v-slot:cell-checkbox="{ row: user }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="user.id"
                  v-model="data.checkedElements"
                />
              </div>
            </template>
            <template v-slot:cell-name="{ row: user }">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <span class="text-dark fw-bolder fs-6">{{ user.name }}</span>
                </div>
              </div>
            </template>
            <template v-slot:cell-username="{ row: user }">
              <span>{{ user.username }}</span>
            </template>
            <template v-slot:cell-mail="{ row: user }">
              <span>{{ user.mail }}</span>
            </template>
            <template v-slot:cell-groups="{ row: user }">
              <span
                :key="group.name"
                class="badge fs-8 fw-bolder badge-light-primary me-2"
                v-for="group of user.groups"
                >{{ group }}</span
              >
            </template>
            <template v-slot:cell-active="{ row: user }">
              <span v-if="user.active" class="badge badge-success">{{
                $t('state.active')
              }}</span>
              <span v-else class="badge badge-danger">{{
                $t('state.inactive')
              }}</span>
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { useRouter } from 'vue-router';
  import KTDatatable, {
    IHeaderConfiguration,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { User } from '@/store/modules/AuthModule';
  // import FilterOptions from '@/components/dlp/FilterOptions.vue';

  export default defineComponent({
    name: 'usersOverview',
    components: {
      KTDatatable,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();

      const data: {
        pageTitle: string;
        users: User[];
        checkedElements: string[];
        isLoading: boolean;
        isLoaded: boolean;
        headerConfig: IHeaderConfiguration[];
        currentSearch: string;
        searchCounter: number;
      } = reactive({
        pageTitle: t('viewUserManagement'),
        users: [],
        checkedElements: [],
        isLoading: false,
        isLoaded: false,
        headerConfig: [
          {
            name: t('user.idAndName'),
            key: 'name',
            sortable: true,
          } as IHeaderConfiguration,
          {
            name: t('username'),
            key: 'username',
            sortable: true,
          },
          {
            name: t('user.mail'),
            key: 'mail',
            sortable: true,
          },
          {
            name: t('user.groups'),
            key: 'groups',
            sortable: true,
            sortingField: 'groups.length',
          },
          {
            name: t('state.view'),
            key: 'active',
            sortable: true,
          },
        ],
        currentSearch: '',
        searchCounter: 1,
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      const renderUserData = (apiUsers) => {
        return apiUsers;
      };

      async function loadUsers() {
        if (data.users.length !== 0) return false;
        data.isLoading = true;

        const loadConfig = {
          data: {},
        };

        const apiUsers = await ApiService.post('users', loadConfig);
        data.users = renderUserData(apiUsers.data);

        data.isLoaded = true;
        data.isLoading = false;
      }

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        await loadUsers();
        store.dispatch(Actions.END_LOADER);
      });

      const updatePassword = async (user) => {
        const passwordChangeFormResult = await Swal.fire({
          text: `Set a new password for ${user.mail}`,
          icon: 'question',
          input: 'password',
          inputAttributes: {
            minlength: '4',
          },
          showCancelButton: true,
          confirmButtonText: t('continue'),
          cancelButtonText: t('cancel'),
        });

        if (!passwordChangeFormResult.isConfirmed) {
          return false;
        }

        const newPassword = String(passwordChangeFormResult.value);

        if (newPassword.length < 4) {
          Swal.fire(t('error'), t('passwordTooShort'), 'error');
          return false;
        }

        const passwordChangeRepeatFormResult = await Swal.fire({
          text: `Repeat new password for ${user.mail}`,
          icon: 'question',
          input: 'password',
          showCancelButton: true,
          confirmButtonText: t('save'),
          cancelButtonText: t('cancel'),
        });

        if (!passwordChangeRepeatFormResult.isConfirmed) {
          return false;
        }

        const newPasswordRepeat = passwordChangeRepeatFormResult.value;
        const doPasswordsMatch = newPassword === newPasswordRepeat;

        if (!doPasswordsMatch) {
          Swal.fire(t('error'), t('passwordsDoNotMatch'), 'error');
          return false;
        }

        const userUpdateUrl = `users/${user.id}/password`;
        await ApiService.put(userUpdateUrl, {
          data: {
            password: newPassword,
          },
        });

        const currentUser = { ...store.getters.currentUser };
        if (user.id === currentUser.id) {
          store.commit(Mutations.PURGE_AUTH);
          router.push({ name: 'sign-in' });
          Swal.fire(t('done'), t('loginWithNewCredentials'), 'success');
        } else {
          Swal.fire(t('done'), t('passwordChanged'), 'success');
        }
      };

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  await ApiService.delete(`/users/${id}`);
                }),
              );

              data.users = data.users.filter((user: User) => {
                if (!user.id) return false;
                return !data.checkedElements.includes(user.id);
              });

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedUsers = computed(() => {
        return data.users.filter((user: User) => {
          if (data.currentSearch === '') return true;
          const searchString = data.currentSearch.toLowerCase();
          if (user.name.toLowerCase().includes(searchString)) return true;
          if (user.mail.toLowerCase().includes(searchString)) return true;
          if (user.username.toLowerCase().includes(searchString)) return true;
          if (
            user.groups.some((group: string) => {
              return group.toLowerCase().includes(searchString);
            })
          )
            return true;
          return false;
        });
      });

      const handleSearchChange = () => {
        data.searchCounter++;
      };

      const handleSearchReset = () => {
        if (data.currentSearch !== '') {
          data.currentSearch = '';
          data.searchCounter++;
        }
      };

      return {
        data,
        computedUsers,
        deleteSelected,
        updatePassword,
        computedCurrentUserIsAdmin,
        handleSearchChange,
        handleSearchReset,
      };
    },
  });
</script>
