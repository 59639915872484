<template>
  <!--begin::Activities drawer-->
  <div
    :id="computedId"
    class="bg-white"
    data-kt-drawer="true"
    data-kt-drawer-name="edit"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    :data-kt-drawer-width="`{default:'${computedWidth}'}`"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_edit_toggle"
    :data-kt-drawer-close="`#${computedId}Close`"
  >
    <div class="card shadow-none w-100">
      <!--begin::Header-->
      <div
        class="card-header d-flex justify-content-between align-items-center"
        :id="`${computedId}-header`"
      >
        <h3
          class="card-title fw-bolder text-dark align-items-start flex-column mw-75"
        >
          <span class="card-label fw-bolder fs-3 mb-1">{{
            computedTitle
          }}</span>
        </h3>

        <div class="card-toolbar ms-auto">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            :id="`${computedId}Close`"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" :id="`${computedId}-body`">
        <!--begin::Content-->
        <div class="position-relative me-n5 pe-5">
          <slot></slot>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end::Activities drawer-->
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'sideDrawer',
    props: {
      id: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        required: false,
      },
      path: {
        type: String,
        required: false,
      },
    },
    components: {},
    computed: {
      computedTitle() {
        return this.title;
      },
      computedWidth() {
        return this.width || '700px';
      },
      computedId() {
        return this.id;
      },
      computedPath() {
        return this.path || '';
      },
    },
  });
</script>
