<template>
  <span>
    <span
      v-if="state === 'open'"
      :class="`badge badge-${$t('spc.processconfirmation.statesBadges.open')}`"
      >{{ $t('spc.processconfirmation.states.open') }}</span
    >
    <span
      v-if="state === 'progress'"
      :class="`badge badge-${$t(
        'spc.processconfirmation.statesBadges.progress',
      )}`"
      >{{ $t('spc.processconfirmation.states.progress') }}</span
    >
    <span
      v-if="state === 'done'"
      :class="`badge badge-${$t('spc.processconfirmation.statesBadges.done')}`"
      >{{ $t('spc.processconfirmation.states.done') }}</span
    >
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'processConfirmationStateBadge',
    props: {
      state: String,
    },
  });
</script>
