import store from '@/store';
import { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/Layout.vue';

import Dashboard from '@/views/spc/SpcDashboard.vue';
import Overview from '@/views/spc/SpcOverview.vue';
import AuditPlansCalendar from '@/views/spc/auditPlans/SpcAuditPlansCalendar.vue';
import Create from '@/views/spc/SpcCreate.vue';
import Single from '@/views/spc/SpcSingle.vue';
import Config from '@/views/spc/config/SpcConfigIndex.vue';
import SpcUserOverview from '@/views/spc/config/SpcConfigUsers.vue';
import { Actions } from '@/store/enums/StoreEnums';
import { SPC_ALLOWED_HOSTS } from '@/core/config/AppMenuConfig';

type RouteChild = {
  path: string;
  name: string;
  component: any;
  meta: {
    allowedHosts: string[];
    requiredGroup?: string;
  };
};

export default {
  path: '/spc',
  redirect: '/spc/dashboard',
  component: DefaultLayout,
  meta: {
    app: 'SPC',
    allowedHosts: SPC_ALLOWED_HOSTS,
  },
  children: [
    {
      path: '/spc/dashboard',
      name: 'spcDashboard',
      component: Dashboard,
    } as RouteChild,
    {
      path: '/spc/audit/overview',
      name: 'spcProcessConfirmationOverview',
      component: Overview,
    } as RouteChild,
    {
      path: '/spc/audit/plans',
      name: 'spcAuditPlansCalendar',
      component: AuditPlansCalendar,
    } as RouteChild,
    {
      path: '/spc/audit/create',
      name: 'spcProcessConfirmationCreate',
      component: Create,
    } as RouteChild,
    {
      path: '/spc/auditplan/:id',
      name: 'spcAuditPlanEdit',
      component: Create,
    } as RouteChild,
    {
      path: '/spc/audit/:id',
      name: 'processConfirmationSingle',
      component: Single,
    } as RouteChild,
    // {
    //   path: '/opl/create',
    //   name: 'oplCreate',
    //   component: Create,
    // },
    {
      path: '/spc/config',
      name: 'spcConfig',
      component: Config,
      meta: {
        requiredGroup: 'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
      },
    } as RouteChild,
    {
      path: '/spc/users/overview',
      name: 'spcUsers',
      component: SpcUserOverview,
      meta: {
        requiredGroup: 'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
      },
    } as RouteChild,
  ],
  beforeEnter: async (to, from, next) => {
    await store.dispatch(Actions.GET_MANAGEMENT_LEVEL);
    return next();
  },
} as RouteRecordRaw;
