<template>
  <div class="fes-config-container opl">
    <div class="row">
      <div class="col-12 mb-3">
        <config-hierarchy-names
          :hierarchyNames="data.hierarchyNames"
          moduleName="opl"
          @updated="updateOrgChartData"
        />
      </div>

      <div class="col-12">
        <div class="card">
          <div
            class="card-header border-0"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#orgChartForm"
            aria-expanded="true"
            aria-controls="orgChartForm"
          >
            <h3 class="card-title align-items-center justify-content-start">
              <span class="card-label fw-bolder fs-3 mb-1"
                >{{ $t('viewOrgChart') }} OPL</span
              >
            </h3>
          </div>
          <div id="orgChartForm" class="collapse show">
            <div class="card-body py-3">
              <div class="row mb-3">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.expandAll"
                  >
                    {{ $t('cdf.orgChart.buttons.openAllNodes') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.collapseAll"
                  >
                    {{ $t('cdf.orgChart.buttons.closeAllNodes') }}
                  </button>
                </div>
              </div>

              <div id="oplChart" :key="data.orgChartTmpId"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    ref,
  } from 'vue';
  // import moment from 'moment';
  // import { useRoute, useRouter } from 'vue-router';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { OrgChart } from 'd3-org-chart';
  import { DrawerComponent, MenuComponent } from '@/assets/ts/components/index';

  import ConfigHierarchyNames from '@/components/HierarchyNames.vue';

  // import { useRouter } from 'vue-router';
  // import * as yup from 'yup';
  // import { hideModal } from '@/core/helpers/dom';
  import {
    computedDate,
    calculateDepth,
    getNodePath,
  } from '@/core/helpers/cdf';
  import {
    OrgChartDataPairWithCatalogs,
    RiskLevel,
    RiskLevelEdit,
    RiskLevelWithUsers,
    RiskLevelCatalog,
  } from '@/core/models/fes';
  import { OrgChartDataPair } from '@/core/models/cdf';

  import { User } from '@/store/modules/AuthModule';
  import { OpenPointHierarchyName, OPLConfig } from '@/core/models/opl';

  export default defineComponent({
    name: 'viewOrgChart',
    components: {
      ConfigHierarchyNames,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();

      const riskLevelUserMultiselects = ref([]);

      const data: {
        pageTitle: string;
        orgChartData: OrgChartDataPairWithCatalogs[];
        orgChart: any;
        orgChartTmpId: number;
        currentNode: OrgChartDataPairWithCatalogs | null;
        hierarchyNames: OpenPointHierarchyName[];
        riskLevels: RiskLevelEdit[];
        currentRiskLevels: RiskLevelWithUsers[];
      } = reactive({
        pageTitle: t('viewOrgChart'),
        orgChartData: [] as OrgChartDataPairWithCatalogs[],
        orgChart: new OrgChart(),
        orgChartTmpId: 1,
        currentNode: null,
        hierarchyNames: [] as OpenPointHierarchyName[],
        riskLevels: [] as RiskLevelEdit[],
        currentRiskLevels: [] as RiskLevelWithUsers[],
      });

      const computedAvailableRiskLevels = computed(() => {
        return data.riskLevels
          .filter((riskLevel: RiskLevel) => {
            return riskLevel.needsDecision === true;
          })
          .map((riskLevel: RiskLevel) => {
            return {
              id: riskLevel.id,
              level: riskLevel.level,
              needsDecision: riskLevel.needsDecision,
              groupName: riskLevel.groupName,
              userIds: [],
              users: [],
              usersToSelect: [],
            } as RiskLevelWithUsers;
          });
      });

      const computedOrgChartData = computed(() => {
        return data.orgChartData.slice(0) || [];
      });

      function getInfoBox(item: OrgChartDataPairWithCatalogs, orgChartData) {
        // if (item.parentId === '') return '';
        return `<div class="info-box">
        <span class="badge badge-circle badge-light me-1" title="${t('depth')}">
          ${calculateDepth(item, orgChartData) + 1}
        </span>
        <span class="badge badge-circle badge-success">
          <i class="fa fa-check"></i>
        </span>
      </div>`;
      }

      async function renderChart() {
        data.orgChart
          .container('#oplChart')
          .data(computedOrgChartData.value.slice(0))
          .nodeWidth(() => 340)
          .nodeHeight(() => 150)
          .buttonContent(({ node }) => {
            return `<a href="javascript:" class="nodeMoreButton">${
              node.children
                ? `<i class="fas fa-chevron-up"></i>`
                : `<i class="fas fa-chevron-down"></i>`
            } ${node.data._directSubordinates}</a>`;
          })
          .nodeContent((d) => {
            const cdfNameHtml = getCdfNameHtml(d.data);

            return `<div class="orgChartNode"
    style="border-radius:2px;overflow:visible"
  >
    <div
      class="orgChartNodeContainer"
    >
      ${getInfoBox(d.data, data.orgChartData)}
      
      <div
        style="background-color:#00CCFF;height:10px;width:100%;border-radius:1px"
      ></div>

      ${cdfNameHtml}

      <div style="padding: 16px; text-align: center">
        <div style="color: #002B49; font-size: 16px; font-weight: bold">
          ${d.data.name}
        </div>
      </div>

    </div>
  </div>`;
          })
          .render();
      }

      function getCdfNameHtml(orgUnit: OrgChartDataPair) {
        const level = calculateDepth(orgUnit, data.orgChartData);

        if (typeof data.hierarchyNames[level] === 'undefined') return '';
        return `<div class="row nodeResponsibles">
        <div class="py-3 pb-0 col">
          <span class="badge badge-success">${data.hierarchyNames[level].name}</span>
        </div>
      </div>`;
      }

      async function updateOrgChartData() {
        data.orgChart.data(computedOrgChartData.value);
        data.orgChart.render();
      }

      const computedNodePathString = computed(() => {
        if (computedNodePath.value.length === 1) return '';
        return computedNodePath.value
          .map((node) => {
            return node.name;
          })
          .join(' > ');
      });

      const computedNodePath = computed((): OrgChartDataPairWithCatalogs[] => {
        if (data.currentNode === null) return [];
        return getNodePath(data.currentNode, data.orgChartData).sort(
          (
            orgUnit1: OrgChartDataPairWithCatalogs,
            orgUnit2: OrgChartDataPairWithCatalogs,
          ) => {
            return calculateDepth(orgUnit1, data.orgChartData) >
              calculateDepth(orgUnit2, data.orgChartData)
              ? 1
              : -1;
          },
        );
      });

      async function loadOrgChart() {
        store.dispatch(Actions.START_LOADER);

        try {
          data.orgChartData = await (
            await ApiService.get('opl/org-chart')
          ).data.slice(0);

          const allDepths = data.orgChartData.map((orgUnit) => {
            return calculateDepth(orgUnit, data.orgChartData);
          });

          const oplConfig: OPLConfig = await (
            await ApiService.get('opl/config')
          ).data;

          data.hierarchyNames = oplConfig.hierarchyNames.slice(0);

          const maxDepth = Math.max(...allDepths) + 1;

          // data.errorCaseCdfNames = [];
          data.hierarchyNames = oplConfig.hierarchyNames.slice(0);
          for (let i = 0; i < maxDepth; i++) {
            if (typeof data.hierarchyNames[i] === 'undefined') {
              data.hierarchyNames.push({
                name: '',
                pos: i + 1,
              });
            }
          }

          renderChart();
        } catch (error) {
          Swal.fire('Error', 'Not able to load Org Chart.', 'error');
        }

        store.dispatch(Actions.END_LOADER);
      }

      function initChart() {
        data.orgChartTmpId++;
        data.orgChartData = [];
        data.orgChart = new OrgChart();
        data.hierarchyNames = [] as OpenPointHierarchyName[];
      }

      function handleCatalogUpdate(
        orgChartData: OrgChartDataPairWithCatalogs[],
      ) {
        data.orgChartData = orgChartData;
        updateOrgChartData();
      }

      function handleUserAdd(userId: string, riskLevel: RiskLevelWithUsers) {
        const userObjectToAdd = riskLevel.usersToSelect.find((user: User) => {
          return user.id === userId;
        });
        if (userObjectToAdd) {
          riskLevel.users.push(userObjectToAdd);
        }
      }
      function handleUserRemove(userId: string, riskLevel: RiskLevelWithUsers) {
        // riskLevel.usersToSelect = [];
        riskLevel.userIds = riskLevel.userIds.filter((loopedUserId: string) => {
          return loopedUserId !== userId;
        });
        riskLevel.usersToSelect = riskLevel.users = riskLevel.users.filter(
          (user: User) => {
            return user.id !== userId;
          },
        );
      }

      async function handleRiskLevelChange(
        riskLevelCatalog: RiskLevelCatalog,
        index: number,
      ) {
        store.dispatch(Actions.START_LOADER);
        setTimeout(async () => {
          if (data.currentNode === null) return;
          const riskLevelCatalogToUpdate = {
            id: riskLevelCatalog.id,
            orgUnitId: data.currentNode?.id,
            riskValue: riskLevelCatalog.riskValue,
            userIds: riskLevelCatalog.userIds,
          };

          if (riskLevelCatalogToUpdate.userIds.length === 0) {
            if (riskLevelCatalog.id) {
              await ApiService.delete(
                `fes/config/catalog/risklevel/${riskLevelCatalog.id}`,
              );
            }

            const currentRiskCatalogs = data.currentNode.riskLevelCatalogs;
            data.currentNode.riskLevelCatalogs.forEach(
              (riskLevelCatalog: RiskLevelCatalog, loopedIndex: number) => {
                if (
                  index === loopedIndex &&
                  typeof currentRiskCatalogs[loopedIndex] !== 'undefined'
                ) {
                  currentRiskCatalogs[loopedIndex] = {
                    orgUnitId: riskLevelCatalog.orgUnitId,
                    riskValue: riskLevelCatalog.riskValue,
                    userIds: [],
                    users: [],
                    usersToSelect: [],
                  };
                }
              },
            );
          } else {
            const response = await ApiService.post(
              'fes/config/catalog/risklevel',
              {
                data: riskLevelCatalogToUpdate,
              },
            );

            data.currentNode.riskLevelCatalogs[index].id = response.data.id;
          }

          store.dispatch(Actions.END_LOADER);
          updateOrgChartData();
        }, 50);
      }

      async function handleUserSearch(
        search: string,
        riskLevel: RiskLevelWithUsers,
      ) {
        if (search.length < 1) return;

        const response = await ApiService.post('users/find', {
          data: {
            query: search,
          },
        });

        riskLevel.usersToSelect = [...riskLevel.users, ...response.data];

        riskLevelUserMultiselects.value.forEach(
          (riskLevelUserMultiselect: Ref) => {
            riskLevelUserMultiselect.value?.refreshOptions();
          },
        );
      }

      const computedIsNeededRiskLevel = computed(() => {
        return (riskLevelCatalog: RiskLevelCatalog) => {
          const riskLevel = data.riskLevels.find((riskLevel: RiskLevel) => {
            return riskLevel.level === riskLevelCatalog.riskValue;
          });

          if (riskLevel) {
            return riskLevel.needsDecision;
          }

          return false;
        };
      });

      // function initRiskLevelUsersOptions(users = [] as User[], index: number) {
      //   if (!data.currentOptions[index]) {
      //     data.currentOptions[index] = Object.assign({}, emptyOpenPointOptions);
      //   }
      //   data.currentOptions[index].assignees.length = 0;
      //   data.currentOptions[index].assignees = users || [];
      // }

      onMounted(async () => {
        initChart();
        DrawerComponent.reinitialization();
        MenuComponent.reinitialization();
        loadOrgChart();
      });

      return {
        data,
        handleUserAdd,
        handleUserRemove,
        handleRiskLevelChange,
        handleUserSearch,
        updateOrgChartData,
        computedOrgChartData,
        computedNodePath,
        computedNodePathString,
        handleCatalogUpdate,
        riskLevelUserMultiselects,
        // computedRiskLevelUserOptions,
        computedDate,
        calculateDepth,
        // computedCurrentResponsibles,
        loadOrgChart,
        computedAvailableRiskLevels,
        computedIsNeededRiskLevel,
      };
    },
  });
</script>

<style lang="scss">
  .fes-config-container {
    #oplChart {
      width: 100%;
      height: 800px;
      overflow: hidden;
      background-color: #fcfcfc;
    }
    .orgChartNode {
      width: 340px;
      height: 250px;
    }
    .orgChartNodeContainer {
      height: inherit;
      border-radius: 10px;
      overflow: hidden;
    }
    .nodeMoreButton {
      width: 100%;
      text-align: center;
      border-radius: 3px;
      padding: 10px 5px;
      font-size: 12px;
      margin: auto auto;
      background-color: white;
      border: 1px solid lightgray;
    }
    .node-button-foreign-object:hover a.nodeMoreButton {
      border-color: lightblue;
    }
    .orgChartNodeContainer {
      padding-top: 0px;
      background-color: white;
      border: 1px solid lightgray;
    }
    g {
      cursor: default;
    }
    g.node-button-g,
    .orgChartNode {
      cursor: pointer;
    }
    .nodeTags,
    .nodeResponsibles {
      padding: 0 10px;
    }
    .info-box {
      display: flex;
      justify-content: flex-end;
      width: 64px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
    }
    .badge.badge-circle.badge-warning i,
    .badge.badge-circle.badge-success i {
      color: white;
    }
    .multiselect {
      padding: 0.66rem;
    }
    .node-foreign-object[height='150'] .orgChartNode {
      height: 150px;
    }
    /* .ekNode {
    background-color: #002b49;
  }
  .pukNode {
    background-color: #df0024;
  }
  .rkNode {
    background-color: #00ccff;
  } */
  }
</style>
