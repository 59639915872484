<template>
  <MyForm
    @submit="handleConfigSubmit"
    :validation-schema="hierarchyNamesValidator"
    novalidate="novalidate"
    action="javascript:"
    class="card"
  >
    <div
      class="card-header border-0"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#hierarchyNamesForm"
      aria-expanded="false"
      aria-controls="hierarchyNamesForm"
    >
      <h3 class="card-title align-items-center justify-content-start">
        <span class="card-label fw-bolder fs-3 mb-1">{{
          $t('cdf.hierarchyNames.headline')
        }}</span>
      </h3>
    </div>
    <div id="hierarchyNamesForm" class="collapse">
      <div class="card-body py-3">
        <div v-for="name of data.names" :key="name.pos" class="row mb-6">
          <label class="col-lg-4 col-form-label fw-bold fs-6"
            >{{ $t('depth') }} {{ name.pos }}</label
          >

          <div class="col-lg-8 fv-row">
            <Field
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model="name.name"
              :name="`names[${name.pos}].name`"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage :name="`names[${name.pos}].name`" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end py-6 px-9">
        <button
          type="submit"
          id="kt_account_profile_details_submit"
          ref="submitButton"
          class="btn btn-primary"
        >
          <span class="indicator-label"> {{ $t('save') }} </span>
        </button>
      </div>
    </div>
  </MyForm>
</template>
<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import { defineComponent, reactive, watch } from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'configHierarchyNames',
    props: {
      hierarchyNames: {
        type: Array,
        required: true,
        default: () => [],
      },
      moduleName: {
        type: String,
        required: true,
      },
    },
    emits: ['updated'],
    components: {
      MyForm: Form,
      Field,
      ErrorMessage,
    },
    setup(props, { emit }) {
      const store = useStore();

      const data = reactive({
        names: props.hierarchyNames,
      });

      const hierarchyNamesValidator = yup.object().shape({
        names: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required().min(1).label('Name'),
            }),
          )
          .required(),
      });

      async function handleConfigSubmit() {
        store.dispatch(Actions.START_LOADER);
        await ApiService.post(`${props.moduleName}/config/names`, {
          data: data.names,
        });
        store.dispatch(Actions.END_LOADER);

        emit('updated');
      }

      watch(
        () => props.hierarchyNames,
        () => {
          data.names = props.hierarchyNames;
        },
      );

      return {
        data,
        handleConfigSubmit,
        hierarchyNamesValidator,
      };
    },
  });
</script>
