<template>
  <span>
    <span
      v-if="state === 'open'"
      :class="`badge badge-${$t('opl.statesBadges.open')}`"
      >{{ $t('opl.states.open') }}</span
    >
    <span
      v-if="state === 'progress'"
      :class="`badge badge-${$t('opl.statesBadges.progress')}`"
      >{{ $t('opl.states.progress') }}</span
    >
    <span
      v-if="state === 'done'"
      :class="`badge badge-${$t('opl.statesBadges.done')}`"
      >{{ $t('opl.states.done') }}</span
    >
    <span
      v-if="state === 'closed'"
      :class="`badge badge-${$t('opl.statesBadges.closed')}`"
      >{{ $t('opl.states.closed') }}</span
    >
    <span
      v-if="state === 'declined'"
      :class="`badge badge-${$t('opl.statesBadges.declined')}`"
      >{{ $t('opl.states.declined') }}</span
    >
    <span
      v-if="state === 'postponed'"
      :class="`badge badge-${$t('opl.statesBadges.postponed')}`"
      >{{ $t('opl.states.postponed') }}</span
    >
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'openPointStateBadge',
    props: {
      state: String,
    },
  });
</script>
