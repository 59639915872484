<template>
  <div :class="props.class">
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestDecisionCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritDecisionCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestDecisionCatalogPath" />
      </div>
    </div>
    <div
      class="row"
      v-if="!data.currentNode || !data.currentNode.decisionCatalog"
    >
      <a
        class="col d-flex justify-content-start align-items-center"
        href="javascript:"
        @click="openDecisionCatalog"
        :title="$t('fes.config.linkButtonNew')"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-3"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualDecisionCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualDecisionCatalog') }}
          </h4>
          <span>
            {{
              $t('fes.config.selectedIndividualDecisionCatalog', {
                id: data.currentNode.decisionCatalog.id,
                date: computedDate(data.currentNode.decisionCatalog.createdAt),
                amount: data.currentNode.decisionCatalog.decisions.length,
              })
            }}</span
          >
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openDecisionCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteDecisionCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerDescisionCatalog"
      :title="$t('fes.config.decisionCatalog')"
      width="60vw"
    >
      <org-unit-path :nodePath="props.nodePath" :areaNames="props.areaNames" />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestDecisionCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritDecisionCatalog') }}
          </h4>
          <cdf-path :node-path="computedNearestDecisionCatalogPath" />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestDecisionCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h4>
        {{ $t('fes.config.availableDecisionsInCatalog') }}
        {{ computedNodePathLastElement }}
      </h4>
      <p v-if="!computedCatalogHasDecisions" class="my-5">
        {{ $t('fes.config.noDecisionSelected') }}
      </p>

      <div v-if="computedCatalogHasDecisions">
        <div
          class="row d-flex mb-2 align-items-center"
          v-for="decision of computedCurrentCatalogDecisions"
          :key="decision.id"
        >
          <div class="col">{{ decision.name }}</div>
          <div class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="disconnectDecisionFromCatalog(decision)"
              :title="$t('fes.config.disconnect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-minus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <hr class="my-10" />
      <h4>{{ $t('fes.config.allDecisions') }}</h4>
      <div v-if="computedDecisionSelects.length" class="mb-4">
        <MyForm
          class="row d-flex mb-2 align-items-center"
          v-for="(decision, index) of computedDecisionSelects"
          :key="index"
          @submit="handleDecisionUpdate(decision)"
          :validation-schema="nameValidator"
        >
          <div class="col">
            <span v-if="decision.edit === false">{{ decision.name }}</span>
            <div v-else>
              <Field
                type="text"
                class="form-control form-control-sm form-control-solid"
                name="name"
                v-model="decision.name"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="decision.edit === false" class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="connectDecisionWithCatalog(decision)"
              :title="$t('fes.config.connect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-plus"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="editDecision(decision)"
              :title="$t('edit')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-edit"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="deleteDecision(decision)"
              :title="$t('remove')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-trash"></i>
              </span>
            </a>
          </div>
          <div v-else class="col text-end">
            <button
              type="submit"
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              :title="$t('save')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-save"></i>
              </span>
            </button>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="discardDecisionEdit(decision)"
              :title="$t('cancel')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-times"></i>
              </span>
            </a>
          </div>
        </MyForm>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-secondary"
            @click="addNewDecision"
            :disabled="computedIsNewFormOpen"
          >
            <i class="fa fa-plus"></i>
            {{ $t('fes.config.addDecision') }}
          </button>
        </div>
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from '../fes/OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ErrorCaseDecision,
    DecisionCatalog,
    ErrorCaseDecisionWithForm,
  } from '@/core/models/fes';

  export default defineComponent({
    name: 'decisionCatalogDrawer',
    props: {
      class: {
        type: String,
        required: false,
      },
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs | null>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      // SimpleModal,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    computed: {
      // computedTitle() {
      //   return this.title;
      // },
      // computedWidth() {
      //   return this.width || '700px';
      // },
      // computedId() {
      //   return this.id;
      // },
      // computedPath() {
      //   return this.path || '';
      // },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs | null;
        decisions: ErrorCaseDecisionWithForm[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        decisions: [] as ErrorCaseDecisionWithForm[],
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const computedCatalogHasDecisions = computed(() => {
        return (
          data.currentNode !== null &&
          data.currentNode.decisionCatalog !== null &&
          typeof data.currentNode.decisionCatalog.decisions !== 'undefined' &&
          data.currentNode.decisionCatalog.decisions.length !== 0
        );
      });

      const computedDecisionSelects = computed(() => {
        if (
          data.currentNode !== null &&
          data.currentNode.decisionCatalog !== null &&
          typeof data.currentNode.decisionCatalog.decisions !== 'undefined'
        ) {
          const currentNode = data.currentNode;
          return data.decisions.filter((decision) => {
            const isSelected = currentNode.decisionCatalog?.decisions.find(
              (item) => {
                return item.id === decision.id;
              },
            );
            if (isSelected) return false;
            return true;
          });
        }
        return data.decisions;
      });

      const openDecisionCatalog = () => {
        openSideDrawer('fesConfigDrawerDescisionCatalog');
      };

      function connectDecisionWithCatalog(decision: ErrorCaseDecision) {
        if (data.currentNode === null) return;
        const newDecision: ErrorCaseDecision = Object.assign({}, decision);
        if (data.currentNode.decisionCatalog === null) {
          data.currentNode.decisionCatalog = {
            orgUnitId: data.currentNode.id,
            decisions: [] as ErrorCaseDecision[],
          } as DecisionCatalog;
        }
        data.currentNode.decisionCatalog.decisions.push(newDecision);
        updateCurrentDecisionCatalog();
      }

      function editDecision(decision: ErrorCaseDecisionWithForm) {
        decision.edit = true;
        decision.name = decision.nameBefore;
      }

      function discardDecisionEdit(decision: ErrorCaseDecisionWithForm) {
        if (!decision.id) return data.decisions.pop();
        decision.edit = false;
        decision.name = decision.nameBefore;
      }

      async function deleteDecision(decision: ErrorCaseDecision) {
        const decisionsInTree = data.orgChartData
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.decisionCatalog === null) return false;
            const catalog = orgUnit.decisionCatalog;
            return catalog.decisions.find((item) => item.id === decision.id);
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (decisionsInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: decisionsInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            decisionsInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteDecisionQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const decisionId = decision.id;

        await ApiService.delete(`fes/config/decision/${decisionId}`);

        data.decisions = data.decisions.filter(
          (decisionTmp1: ErrorCaseDecision) => {
            return decisionTmp1.id !== decisionId;
          },
        );

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.decisionCatalog !== null &&
                typeof orgUnit.decisionCatalog.decisions !== 'undefined'
              ) {
                const filteredDecisions =
                  orgUnit.decisionCatalog.decisions.filter(
                    (decisionTmp2: ErrorCaseDecision) =>
                      decisionTmp2.id !== decisionId,
                  );

                if (
                  filteredDecisions.length === 0 &&
                  orgUnit.decisionCatalog.id
                ) {
                  await deleteDecisionCatalog(orgUnit.decisionCatalog.id);
                  orgUnit.decisionCatalog = null;
                  return orgUnit;
                }
                orgUnit.decisionCatalog.decisions = filteredDecisions;
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteDecisionCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.decisionCatalog === null) return;
        store.dispatch(Actions.START_LOADER);
        // const decisionCatalogId = data.currentNode.decisionCatalog.id;
        const decisionCatalogId =
          id !== 0 ? id : data.currentNode.decisionCatalog.id;

        await ApiService.delete(
          `fes/config/catalog/decision/${decisionCatalogId}`,
        );

        data.currentNode.decisionCatalog = null;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteDecisionCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.decisionCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteDecisionCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteDecisionCatalog(id);
      }

      async function disconnectDecisionFromCatalog(
        decision: ErrorCaseDecision,
      ) {
        if (data.currentNode === null) return;
        if (data.currentNode.decisionCatalog !== null) {
          const filteredDecisions =
            data.currentNode.decisionCatalog?.decisions.filter(
              (decisionTmp: ErrorCaseDecision) =>
                decisionTmp.id !== decision.id,
            );

          if (filteredDecisions.length === 0) {
            await deleteDecisionCatalog();
          } else {
            data.currentNode.decisionCatalog.decisions = filteredDecisions;
            updateCurrentDecisionCatalog();
          }
        }
      }

      function addNewDecision() {
        const newDecision: ErrorCaseDecisionWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
        };
        data.decisions.push(newDecision);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function updateCurrentDecisionCatalog() {
        if (data.currentNode === null) return;
        if (
          data.currentNode.decisionCatalog !== null &&
          data.currentNode.decisionCatalog?.decisions.length === 0
        ) {
          return Swal.fire(
            'Error',
            'Please add at least one decision.',
            'error',
          );
        }

        store.dispatch(Actions.START_LOADER);

        const decisionCatalog: DecisionCatalog = Object.assign(
          {},
          data.currentNode.decisionCatalog,
        );

        const newSavedDecisionCatalog = await ApiService.post(
          'fes/config/catalog/decision',
          {
            data: decisionCatalog,
          },
        );

        data.currentNode.decisionCatalog = newSavedDecisionCatalog.data;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedCurrentCatalogDecisions = computed(() => {
        if (data.currentNode === null) return [];
        if (data.currentNode.decisionCatalog === null) return [];
        return data.currentNode.decisionCatalog.decisions;
      });

      async function updateAvailableDecisions() {
        data.decisions = (await (
          await ApiService.get('fes/config/decisions')
        ).data.map((decision: ErrorCaseDecision) => {
          const decisionObject = Object.assign(
            {
              edit: false,
              nameBefore: decision.name,
            } as ErrorCaseDecisionWithForm,
            decision,
          );
          return decisionObject;
        })) as ErrorCaseDecisionWithForm[];
      }

      async function handleDecisionUpdate(decision: ErrorCaseDecisionWithForm) {
        store.dispatch(Actions.START_LOADER);
        decision.edit = false;
        decision.nameBefore = decision.name;

        const decisionUpdateObject: ErrorCaseDecision = {
          name: decision.name,
        };

        if (decision.id) {
          decisionUpdateObject.id = decision.id;
        }

        const savedDecision: AxiosResponse<ErrorCaseDecision> =
          await ApiService.post('fes/config/decision', {
            data: decisionUpdateObject,
          });

        if (!decision.id) {
          decision.id = savedDecision.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestDecisionCatalogPath = computed(() => {
        if (computedNearestDecisionCatalog.value === false) return '';
        const currentNearestDecisionCatalog: DecisionCatalog =
          computedNearestDecisionCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.decisionCatalog === null) return false;
            return (
              orgUnit.decisionCatalog?.id === currentNearestDecisionCatalog.id
            );
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedIsNewFormOpen = computed(() => {
        return (
          computedDecisionSelects.value.filter(
            (decision: ErrorCaseDecisionWithForm) => {
              return decision.edit === true;
            },
          ).length > 0
        );
      });

      const computedNearestDecisionCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentNode.decisionCatalog !== null) return false;

        const nearestDecisionCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'decisionCatalog',
        );

        if (nearestDecisionCatalog) {
          return nearestDecisionCatalog;
        }

        return false;
      });

      async function copyNearestDecisionCatalog() {
        if (data.currentNode === null) return;
        const currentNearestDecisionCatalog: DecisionCatalog =
          computedNearestDecisionCatalog.value;
        const decisionCatalogCopy: DecisionCatalog = {
          orgUnitId: data.currentNode.id,
          decisions: currentNearestDecisionCatalog.decisions,
        } as DecisionCatalog;
        data.currentNode.decisionCatalog = decisionCatalogCopy;
        await updateCurrentDecisionCatalog();
      }

      watch(
        () => props.currentNode,
        () => {
          if (props.currentNode !== null) {
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;
            updateAvailableDecisions();
          }
        },
      );

      return {
        data,
        computedNodePathLastElement,
        computedCatalogHasDecisions,
        computedDecisionSelects,
        computedCurrentCatalogDecisions,
        connectDecisionWithCatalog,
        disconnectDecisionFromCatalog,
        addNewDecision,
        // handleDecisionSubmit,
        nameValidator,
        editDecision,
        discardDecisionEdit,
        deleteDecision,
        handleDeleteDecisionCatalog,
        openDecisionCatalog,
        props,
        computedDate,
        handleDecisionUpdate,
        computedNearestDecisionCatalog,
        computedNearestDecisionCatalogPath,
        copyNearestDecisionCatalog,
        computedIsNewFormOpen,
      };
    },
  });
</script>
