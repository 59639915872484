<template>
  <div :class="props.class">
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestProductCategoryCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritProductCategoryCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestProductCategoryCatalogPath" />
      </div>
    </div>
    <div
      class="row"
      v-if="!data.currentNode || !data.currentNode.productCategoryCatalog"
    >
      <a
        class="col d-flex justify-content-start align-items-center"
        href="javascript:"
        @click="openProductCategoryCatalog"
        :title="$t('fes.config.linkButtonNew')"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-2"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualProductCategoryCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualProductCategoryCatalog') }}
          </h4>
          <span>
            {{
              $t('fes.config.selectedProductCatalog', {
                id: data.currentNode.productCategoryCatalog.id,
                date: computedDate(
                  data.currentNode.productCategoryCatalog.createdAt,
                ),
                amount:
                  data.currentNode.productCategoryCatalog.subCategories.length,
              })
            }}
          </span>
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openProductCategoryCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteProductCategoryCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerProductCategoryCatalog"
      :title="$t('fes.config.productCategoryCatalog')"
      width="60vw"
    >
      <org-unit-path :nodePath="props.nodePath" :areaNames="props.areaNames" />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestProductCategoryCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritProductCategoryCatalog') }}
          </h4>
          <cdf-path :node-path="computedNearestProductCategoryCatalogPath" />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestProductCategoryCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h4 class="mb-5">
        {{ $t('fes.config.allProductCategoriesInCatalog') }}
        {{ computedNodePathLastElement }}
      </h4>
      <p v-if="!computedCatalogHasSubCategories" class="my-5">
        {{ $t('fes.config.noProductCategoryAssigned') }}
      </p>

      <div v-if="computedCatalogHasSubCategories">
        <div
          class="mb-3 d-flex align-items-sm-center border p-3"
          v-for="category of computedCurrentCatalogCategories"
          :key="category.id"
        >
          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <span class="text-gray-800 fs-5 fw-bolder">{{
                category.name
              }}</span
              ><span class="text-muted fw-bold d-block fs-7"
                >{{ category.category.name }}
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                {{ category.subCategory.name }}</span
              >
            </div>
            <div>
              <a
                href="javascript:"
                class="btn btn-sm btn-icon btn-light-primary me-2"
                @click="disconnectSubCategoryWithCatalog(category)"
                :title="$t('fes.config.disconnect')"
              >
                <span class="svg-icon svg-icon-2">
                  <i class="fa fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-10" />

      <h4>{{ $t('fes.config.selectProductCategoryCatalogHeadline') }}</h4>
      <div v-if="data.categories.length">
        <div
          class="mb-4"
          v-for="(category, index) of computedCurrentCategories"
          :key="index"
        >
          <MyForm
            @submit="handleCategoryUpdate(category)"
            :validation-schema="nameValidator"
          >
            <div class="row d-flex mb-2 align-items-center">
              <div class="col-9">
                <a
                  href="javascript:"
                  @click="
                    category.showSubCategories = !category.showSubCategories
                  "
                  class="fs-3 text-dark d-block"
                  v-if="category.edit === false"
                  ><i
                    v-if="category.showSubCategories"
                    class="fa fa-chevron-up"
                    aria-hidden="true"
                  ></i
                  ><i v-else class="fa fa-chevron-down" aria-hidden="true"></i>
                  {{ category.name }}</a
                >
                <div v-else>
                  <Field
                    type="text"
                    class="form-control form-control-sm form-control-solid border border-primary"
                    name="name"
                    v-model="category.name"
                    :key="index"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="category.edit === false" class="col-3 text-end">
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                  @click="editCategory(category)"
                  :title="$t('edit')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-edit"></i>
                  </span>
                </a>
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                  @click="deleteCategory(category)"
                  :title="$t('remove')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-trash"></i>
                  </span>
                </a>
              </div>
              <div v-else class="col-3 text-end">
                <button
                  type="submit"
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                  :title="$t('save')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-save"></i>
                  </span>
                </button>
                <a
                  href="javascript:"
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                  @click="discardCategoryEdit(category)"
                  :title="$t('cancel')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-times"></i>
                  </span>
                </a>
              </div>
            </div>
          </MyForm>

          <div
            class="px-10 py-5 bg-light-info"
            v-show="category.id > 0 && category.showSubCategories === true"
          >
            <div v-if="category.subCategories.length > 0">
              <div
                v-for="(subCategory, subIndex) of category.subCategories"
                :key="subIndex"
              >
                <MyForm
                  @submit="handleCategoryUpdate(category)"
                  :validation-schema="nameValidator"
                >
                  <div class="row d-flex mb-2 align-items-center">
                    <div class="col">
                      <span v-if="subCategory.edit === false">{{
                        subCategory.name
                      }}</span>

                      <div v-else>
                        <Field
                          type="text"
                          class="form-control form-control-sm form-control-solid border border-primary"
                          name="name"
                          v-model="subCategory.name"
                          :key="subIndex"
                        />
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="name" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="subCategory.edit === false" class="col text-end">
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        @click="connectSubCategoryWithCatalog(subCategory)"
                        :title="$t('fes.config.connect')"
                        v-if="computedSubCategoryIsAbleToConnect(subCategory)"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-plus"></i>
                        </span>
                      </a>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        @click="editCategory(subCategory)"
                        :title="$t('edit')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-edit"></i>
                        </span>
                      </a>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-danger"
                        @click="deleteSubCategory(subCategory)"
                        :title="$t('remove')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-trash"></i>
                        </span>
                      </a>
                    </div>
                    <div v-else class="col text-end">
                      <button
                        type="button"
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-primary me-2"
                        :title="$t('save')"
                        @click="saveSubCategory(subCategory)"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-save"></i>
                        </span>
                      </button>
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-icon btn-light-danger"
                        @click="discardSubCategoryEdit(category, subCategory)"
                        :title="$t('cancel')"
                      >
                        <span class="svg-icon svg-icon-2">
                          <i class="fa fa-times"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </MyForm>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="addNewSubCategory(category)"
                  :disabled="computedHasCategoryEditsOpen(category)"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('fes.form.productCategory.addSubCategory') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-secondary mb-10"
            @click="addNewCategory"
            :disabled="computedIsCategoryAddOpen"
          >
            <i class="fa fa-plus"></i>
            {{ $t('fes.form.productCategory.addCategory') }}
          </button>
        </div>
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from './OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ProductCategoryCatalog,
    ProductSubCategory,
    ProductSubCategoryWithForm,
    ProductCategoryWithForm,
    ProductCategory,
  } from '@/core/models/fes';

  export default defineComponent({
    name: 'productCategoryCatalogDrawer',
    props: {
      class: {
        type: String,
        required: false,
      },
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs | null>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      // SimpleModal,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    computed: {
      // computedTitle() {
      //   return this.title;
      // },
      // computedWidth() {
      //   return this.width || '700px';
      // },
      // computedId() {
      //   return this.id;
      // },
      // computedPath() {
      //   return this.path || '';
      // },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs | null;
        categories: ProductCategory[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        categories: [] as ProductCategory[],
      });

      const computedNodePathAreaTitle = computed(() => {
        const nodePath = props.nodePath.slice(1);
        const nodePathLength = nodePath.length;
        const errorCaseCdfName = props.areaNames[nodePathLength - 1];
        if (errorCaseCdfName) {
          return errorCaseCdfName.name;
        }
        return '';
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const computedCatalogHasSubCategories = computed(() => {
        return (
          data.currentNode !== null &&
          data.currentNode.productCategoryCatalog !== null &&
          typeof data.currentNode.productCategoryCatalog.subCategories !==
            'undefined' &&
          data.currentNode.productCategoryCatalog.subCategories.length !== 0
        );
      });

      const computedCurrentCatalogCategories = computed(() => {
        if (!computedCatalogHasSubCategories.value) return [];

        const allSubCategories: ProductSubCategory[] = [];
        computedCurrentCategories.value.forEach(
          (productCategory: ProductCategory) => {
            return productCategory.subCategories.forEach(
              (subCategory: ProductSubCategory) => {
                allSubCategories.push(subCategory);
              },
            );
          },
        );

        return data.currentNode?.productCategoryCatalog?.subCategories.map(
          (currentSubCategory: ProductSubCategory) => {
            const foundSubCategory = allSubCategories.find(
              (subCategory: ProductSubCategory) => {
                return currentSubCategory.id === subCategory.id;
              },
            );

            return {
              ...currentSubCategory,
              subCategory: {
                name: foundSubCategory?.name,
              },
              category: {
                name: foundSubCategory?.parent.name,
              },
            };
          },
        );
      });

      const computedCurrentCategories = computed(() => {
        return data.categories;
      });

      const openProductCategoryCatalog = () => {
        openSideDrawer('fesConfigDrawerProductCategoryCatalog');
      };

      function connectSubCategoryWithCatalog(
        subCategory: ProductSubCategoryWithForm,
      ) {
        if (data.currentNode === null) return;
        const newSubCategory: ProductSubCategory = Object.assign(
          {},
          subCategory,
        );

        if (data.currentNode.productCategoryCatalog === null) {
          data.currentNode.productCategoryCatalog = {
            orgUnitId: data.currentNode.id,
            subCategories: [] as ProductSubCategory[],
          } as ProductCategoryCatalog;
        }

        data.currentNode.productCategoryCatalog.subCategories.push(
          newSubCategory,
        );

        updateCurrentProductCategoryCatalog();
      }

      function editCategory(category: ProductCategoryWithForm) {
        category.edit = true;
        category.name = category.nameBefore;
      }

      function discardCategoryEdit(category: ProductCategoryWithForm) {
        if (!category.id) return data.categories.pop();
        category.edit = false;
        category.name = category.nameBefore;
      }

      function discardSubCategoryEdit(
        category: ProductCategoryWithForm,
        subCategory: ProductSubCategoryWithForm,
      ) {
        if (!subCategory.id) return category.subCategories.pop();
        subCategory.edit = false;
        subCategory.name = subCategory.nameBefore;
      }

      async function deleteSubCategory(
        subCategory: ProductSubCategoryWithForm,
      ) {
        const html = [t('fes.config.deleteProductSubCategoryQuestion')].join(
          '',
        );

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const subCategoryId = subCategory.id;

        await ApiService.delete(
          `fes/config/products/subcategory/${subCategoryId}`,
        );

        data.categories = data.categories.map((category: ProductCategory) => {
          category.subCategories = category.subCategories.filter(
            (categoryTmp1: ProductSubCategory) => {
              return categoryTmp1.id !== subCategoryId;
            },
          );
          return category;
        });

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.productCategoryCatalog !== null &&
                typeof orgUnit.productCategoryCatalog.subCategories !==
                  'undefined'
              ) {
                const filteredCategories =
                  orgUnit.productCategoryCatalog.subCategories.filter(
                    (decisionTmp2: ProductSubCategory) =>
                      decisionTmp2.id !== subCategory.id,
                  );

                if (
                  filteredCategories.length === 0 &&
                  orgUnit.productCategoryCatalog.id
                ) {
                  await deleteProductCategoryCatalog(
                    orgUnit.productCategoryCatalog.id,
                  );
                  orgUnit.productCategoryCatalog = null;
                  return orgUnit;
                }
                orgUnit.productCategoryCatalog.subCategories =
                  filteredCategories;
              }
              return orgUnit;
            },
          ),
        );

        await updateCurrentProductCategoryCatalog();
        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function deleteCategory(category: ProductCategoryWithForm) {
        const productCategoryCategoriesInTree = data.orgChartData
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.productCategoryCatalog === null) return false;
            const catalog = orgUnit.productCategoryCatalog;

            return catalog.subCategories.find(
              (item) => item.id === category.id,
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (productCategoryCategoriesInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: productCategoryCategoriesInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            productCategoryCategoriesInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteProductCategoryQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        const categoryId = category.id;

        // await ApiService.delete(`fes/config/products/category/${categoryId}`);

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.productCategoryCatalog !== null &&
                typeof orgUnit.productCategoryCatalog.subCategories !==
                  'undefined'
              ) {
                const filteredCategories =
                  orgUnit.productCategoryCatalog.subCategories.filter(
                    (subCategory: ProductSubCategory) => {
                      return (
                        category.subCategories.findIndex(
                          (productSubCategory: ProductSubCategory) => {
                            return subCategory.id === productSubCategory.id;
                          },
                        ) === -1
                      );

                      // const isInDeletedParent = data.categories.filter((productCategory: ProductCategory) => {
                      //   return productCategory.subCategories.some((productSubCategory: ProductSubCategory) => {
                      //     return productSubCategory.
                      //   })
                      // })

                      // return decisionTmp2.parent.id !== category.id;
                    },
                  );

                if (
                  filteredCategories.length === 0 &&
                  orgUnit.productCategoryCatalog.id
                ) {
                  await deleteProductCategoryCatalog(
                    orgUnit.productCategoryCatalog.id,
                  );
                  orgUnit.productCategoryCatalog = null;
                  return orgUnit;
                }
                orgUnit.productCategoryCatalog.subCategories =
                  filteredCategories;
              }
              return orgUnit;
            },
          ),
        );

        data.categories = data.categories.filter(
          (categoryTmp1: ProductCategory) => {
            return categoryTmp1.id !== categoryId;
          },
        );

        await updateCurrentProductCategoryCatalog();

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteProductCategoryCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.productCategoryCatalog === null) return;
        store.dispatch(Actions.START_LOADER);
        // const productCategoryCatalogId =
        //   data.currentNode.productCategoryCatalog.id;
        const productCategoryCatalogId =
          id !== 0 ? id : data.currentNode.productCategoryCatalog.id;

        await ApiService.delete(
          `fes/config/catalog/productcategory/${productCategoryCatalogId}`,
        );

        data.currentNode.productCategoryCatalog = null;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteProductCategoryCatalog(id = 0) {
        if (data.currentNode === null) return;
        if (data.currentNode.productCategoryCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteProductCategoryCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteProductCategoryCatalog(id);
      }

      function addNewCategory() {
        const newCategory: ProductCategoryWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
          subCategories: [] as ProductSubCategory[],
          showSubCategories: false,
        } as ProductCategoryWithForm;
        newCategory.name = '';
        data.categories.push(newCategory);
      }
      function addNewSubCategory(parentCategory: ProductCategory) {
        const newSubCategory: ProductSubCategoryWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
          parent: {
            id: parentCategory.id,
            name: parentCategory.name,
          },
        } as ProductSubCategoryWithForm;
        newSubCategory.name = '';
        parentCategory.subCategories.push(newSubCategory);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function updateCurrentProductCategoryCatalog() {
        if (data.currentNode === null) return;
        if (data.currentNode.productCategoryCatalog === null) return;
        if (
          data.currentNode.productCategoryCatalog !== null &&
          data.currentNode.productCategoryCatalog?.subCategories.length === 0
        ) {
          return Swal.fire(
            'Error',
            'Please add at least one category.',
            'error',
          );
        }

        store.dispatch(Actions.START_LOADER);

        const productCategoryCatalog: ProductCategoryCatalog = Object.assign(
          {},
          data.currentNode.productCategoryCatalog,
        );

        const newSavedProductCategoryCatalog = await ApiService.post(
          'fes/config/catalog/productcategory',
          {
            data: productCategoryCatalog,
          },
        );

        data.currentNode.productCategoryCatalog =
          newSavedProductCategoryCatalog.data;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function updateAvailableProductCategories() {
        const loadedCategories = await ApiService.get(
          'fes/config/products/categories',
        );

        data.categories = loadedCategories.data
          .map((category: ProductCategory) => {
            const categoryWithForm: ProductCategoryWithForm = {
              id: category.id,
              name: category.name,
              nameBefore: category.name,
              showSubCategories: false,
              edit: false,
              subCategories: category.subCategories.map(
                (subCategory: ProductSubCategory) => {
                  return {
                    ...subCategory,
                    nameBefore: subCategory.name,
                    showSubCategories: true,
                    edit: false,
                    parent: category,
                  } as ProductSubCategoryWithForm;
                },
              ) as ProductSubCategoryWithForm[],
            } as ProductCategoryWithForm;
            return categoryWithForm;
          })
          .map((category: ProductCategoryWithForm) => {
            category.subCategories.sort(
              (
                categoryA: ProductSubCategoryWithForm,
                categoryB: ProductSubCategoryWithForm,
              ) => {
                if (categoryA.name < categoryB.name) {
                  return -1;
                }
                if (categoryA.name > categoryB.name) {
                  return 1;
                }
                return 0;
              },
            );

            return category;
          })
          .sort(
            (
              categoryA: ProductCategoryWithForm,
              categoryB: ProductCategoryWithForm,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
      }

      async function handleCategoryUpdate(category: ProductCategoryWithForm) {
        store.dispatch(Actions.START_LOADER);
        category.edit = false;
        category.nameBefore = category.name;

        const categoryUpdateObject: ProductCategory = {
          name: category.name,
        } as ProductCategory;

        if (category.id) {
          categoryUpdateObject.id = category.id;
        }

        const savedProductCategory: AxiosResponse<ProductCategory> =
          await ApiService.post('fes/config/products/category', {
            data: categoryUpdateObject,
          });

        if (!category.id) {
          category.id = savedProductCategory.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      async function saveSubCategory(subCategory: ProductSubCategoryWithForm) {
        subCategory.edit = false;
        subCategory.nameBefore = subCategory.name;

        store.dispatch(Actions.START_LOADER);
        subCategory.edit = false;
        subCategory.nameBefore = subCategory.name;

        const subCategoryUpdateObject: ProductSubCategory = {
          name: subCategory.name,
          parent: {
            id: subCategory.parent.id,
          },
        } as ProductSubCategory;

        if (subCategory.id) {
          subCategoryUpdateObject.id = subCategory.id;
        }

        const savedProductSubCategory: AxiosResponse<ProductSubCategory> =
          await ApiService.post('fes/config/products/subcategory', {
            data: subCategoryUpdateObject,
          });

        if (!subCategory.id) {
          subCategory.id = savedProductSubCategory.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestProductCategoryCatalogPath = computed(() => {
        if (computedNearestProductCategoryCatalog.value === false) return '';
        const currentNearestProductCategoryCatalog: ProductCategoryCatalog =
          computedNearestProductCategoryCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.productCategoryCatalog === null) return false;
            return (
              orgUnit.productCategoryCatalog?.id ===
              currentNearestProductCategoryCatalog.id
            );
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedNearestProductCategoryCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentNode.productCategoryCatalog !== null) return false;

        const nearestProductCategoryCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'productCategoryCatalog',
        );

        if (nearestProductCategoryCatalog) {
          return nearestProductCategoryCatalog;
        }

        return false;
      });

      async function copyNearestProductCategoryCatalog() {
        if (data.currentNode === null) return;
        const currentNearestProductCategoryCatalog: ProductCategoryCatalog =
          computedNearestProductCategoryCatalog.value;
        const productCategoryCatalogCopy: ProductCategoryCatalog = {
          orgUnitId: data.currentNode.id,
          subCategories: currentNearestProductCategoryCatalog.subCategories,
        } as ProductCategoryCatalog;
        data.currentNode.productCategoryCatalog = productCategoryCatalogCopy;
        await updateCurrentProductCategoryCatalog();
      }

      const computedHasCategoryEditsOpen = computed(() => {
        return (subCategory: ProductCategoryWithForm) => {
          return subCategory.subCategories.some(
            (subCategory: ProductSubCategoryWithForm) => {
              return subCategory.edit === true;
            },
          );
        };
      });

      const computedHasSubCategoryEditsOpen = computed(() => {
        return (subCategory: ProductSubCategoryWithForm) => {
          return subCategory.edit === true;
        };
      });
      const computedIsCategoryAddOpen = computed(() => {
        return data.categories.some((category) => {
          return typeof category.id === 'undefined';
        });
      });

      async function disconnectSubCategoryWithCatalog(
        subCategory: ProductSubCategory,
      ) {
        if (data.currentNode === null) return;
        if (data.currentNode.productCategoryCatalog !== null) {
          const filteredSubCategories =
            data.currentNode.productCategoryCatalog?.subCategories.filter(
              (subCategoryTmp: ProductSubCategory) =>
                subCategoryTmp.id !== subCategory.id,
            );

          if (filteredSubCategories.length === 0) {
            return await deleteProductCategoryCatalog();
          }
          data.currentNode.productCategoryCatalog.subCategories =
            filteredSubCategories;
          updateCurrentProductCategoryCatalog();
        }
      }

      const computedSubCategoryIsAbleToConnect = computed(() => {
        return (subCategory: ProductSubCategory) => {
          if (data.currentNode === null) return false;
          if (data.currentNode.productCategoryCatalog === null) return true;
          return (
            data.currentNode.productCategoryCatalog.subCategories.findIndex(
              (loopedSubCategory: ProductSubCategory) => {
                return loopedSubCategory.id === subCategory.id;
              },
            ) === -1
          );
        };
      });

      watch(
        () => props.currentNode,
        () => {
          if (props.currentNode !== null) {
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;
            updateAvailableProductCategories();
          }
        },
      );

      return {
        data,
        computedNodePathAreaTitle,
        computedNodePathLastElement,
        computedCatalogHasSubCategories,
        // computedCategorySelects,
        disconnectSubCategoryWithCatalog,
        connectSubCategoryWithCatalog,
        addNewCategory,
        addNewSubCategory,
        // handleDecisionSubmit,
        nameValidator,
        editCategory,
        discardCategoryEdit,
        discardSubCategoryEdit,
        deleteCategory,
        deleteSubCategory,
        handleDeleteProductCategoryCatalog,
        openProductCategoryCatalog,
        props,
        computedDate,
        handleCategoryUpdate,
        saveSubCategory,
        computedNearestProductCategoryCatalog,
        computedNearestProductCategoryCatalogPath,
        copyNearestProductCategoryCatalog,
        updateAvailableProductCategories,
        computedCurrentCatalogCategories,
        computedCurrentCategories,
        computedIsCategoryAddOpen,
        computedHasCategoryEditsOpen,
        computedHasSubCategoryEditsOpen,
        computedSubCategoryIsAbleToConnect,
      };
    },
  });
</script>
