<template>
  <div>
    <h2 class="fs-2 fw-bold mb-5">
      {{ $t('spc.questionCatalog.all') }}
    </h2>
    <div class="mb-10">
      <p class="my-6" v-if="computedCurrentQuestionCatalogs.length === 0">
        {{ $t('spc.questionCatalog.noQuestionCatalogs') }}
      </p>
      <div v-else class="mb-3">
        <div
          v-for="questionCatalog in computedCurrentQuestionCatalogs"
          :key="questionCatalog.id"
          :class="computedQuestionCatalogClasses(questionCatalog)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <span
              class="svg-icon svg-icon-2hx svg-icon-primary me-4"
              v-if="!questionCatalog.image"
            >
              <inline-svg src="media/icons/duotune/general/gen046.svg" />
            </span>
            <img
              v-else
              class="mw-30px mh-30px me-4"
              :src="computedFileUrl(questionCatalog.image.fileName)"
            />
            <div class="d-flex flex-column">
              <span
                >{{ questionCatalog.name }} vom
                {{ computedDate(questionCatalog.createdAt) }}</span
              >
              <div>
                <span
                  v-if="computedQuestionCatalogHasParent(questionCatalog)"
                  class="badge badge-light-info me-3"
                  :title="computedOverwrittenCatalogPath(questionCatalog)"
                >
                  <i class="fa fa-copy fs-7 text-info me-2"></i>
                  {{ $t('spc.questionCatalog.overwrittenCatalog') }}
                </span>
                <span class="badge badge-light-primary me-3">
                  <i class="fa fa-user fs-7 text-primary me-2"></i>
                  {{ questionCatalog.levels.length }}
                  {{ $t('spc.questionCatalog.managementLevels') }}
                </span>
                <span class="badge badge-light-success">
                  <i class="fa fa-question-circle fs-7 text-success me-2"></i>
                  {{ computedQuestionCatalogQuestionAmount(questionCatalog) }}
                  {{ $t('spc.question.multiple') }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span
              v-if="
                computedIsQuestionCatalogInherited(questionCatalog) &&
                !questionCatalog.disabledOrgUnitId
              "
              class="badge badge-light-primary me-3"
              >{{ $t('spc.questionCatalog.inherited') }}</span
            >
            <span
              v-if="
                computedIsQuestionCatalogInherited(questionCatalog) &&
                questionCatalog.disabledOrgUnitId
              "
              class="badge badge-light-primary me-3"
              >{{ $t('spc.questionCatalog.inherited') }}</span
            >
            <span
              v-if="computedIsQuestionCatalogAllowedToEnable(questionCatalog)"
              class="badge badge-danger me-3"
              >{{ $t('spc.questionCatalog.deactivated') }}</span
            >
            <button
              v-if="!computedIsQuestionCatalogInherited(questionCatalog)"
              type="button"
              class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
              @click="openQuestionCatalog(questionCatalog)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </span>
            </button>
            <button
              v-if="computedIsQuestionCatalogInherited(questionCatalog)"
              type="button"
              class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
              @click="copyQuestionCatalog(questionCatalog)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fas fa-clone"></i>
              </span>
            </button>
            <button
              v-if="computedIsQuestionCatalogAllowedToDisable(questionCatalog)"
              type="button"
              class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto"
              @click="disableQuestionCatalog(questionCatalog)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fa fa-ban" aria-hidden="true"></i>
              </span>
            </button>
            <button
              v-if="computedIsQuestionCatalogAllowedToEnable(questionCatalog)"
              type="button"
              class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto"
              @click="enableQuestionCatalog(questionCatalog)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fa fa-power-off" aria-hidden="true"></i>
              </span>
            </button>
            <button
              v-if="!computedIsQuestionCatalogInherited(questionCatalog)"
              type="button"
              class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
              @click="handleDeleteQuestionCatalog(questionCatalog)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="!data.currentNode || !data.currentNode.causeCatalog"
      >
        <a
          class="col d-flex justify-content-start align-items-center"
          @click="openEmptyQuestionCatalog"
          :title="$t('spc.questionCatalog.new')"
          href="javascript:"
        >
          <span
            class="btn btn btn-icon btn-bg-light btn-active-color-primary me-3"
          >
            <span class="svg-icon svg-icon-2">
              <i class="fa fa-plus"></i>
            </span>
          </span>
          <span>
            {{ $t('spc.questionCatalog.new') }}
          </span>
        </a>
      </div>

      <side-drawer
        id="spcConfigDrawerQuestionCatalog"
        :title="computedCatalogDrawerTitle"
        width="70vw"
      >
        <div
          v-if="computedCurrentQuestionCatalogHasParent"
          class="alert alert-info d-flex align-items-center p-5 mb-5"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-info me-4">
            <inline-svg src="media/icons/duotune/general/gen054.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-info">
              {{ $t('spc.questionCatalog.copiedAlert.title') }}
            </h4>
            <span v-if="computedQuestionCatalogParent">{{
              $t('spc.questionCatalog.copiedAlert.text', {
                catalog: computedQuestionCatalogParent.name,
              })
            }}</span>
          </div>
        </div>

        <org-unit-path
          :nodePath="props.nodePath"
          :areaNames="props.areaNames"
        />

        <MyForm
          @submit="handleQuestionCatalogSubmit"
          action="javascript:"
          :validation-schema="nameValidator"
        >
          <template v-if="data.currentCatalog">
            <div class="row mb-6">
              <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                $t('cdf.orgUnit.title')
              }}</label>

              <div class="col-lg-8 fv-row">
                <Field
                  type="text"
                  name="name"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Name"
                  v-model="data.currentCatalog.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                $t('spc.questionCatalog.image')
              }}</label>

              <div class="col-lg-8 fv-row d-flex flex-wrap">
                <div
                  class="image-input image-input-outline me-5 mb-5"
                  v-if="data.currentCatalog.image"
                >
                  <a
                    :href="computedFileUrl(data.currentCatalog.image.fileName)"
                    target="_blank"
                    class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
                    :style="`background-image: url(${computedFileUrl(
                      data.currentCatalog.image.fileName,
                    )})`"
                  ></a>
                  <a
                    href="javascript:"
                    @click="handleRemoveAttachment"
                    data-kt-image-input-action="change"
                    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                    ><i class="bi bi-x fs-2"></i
                  ></a>
                </div>
                <div v-else class="image-input image-input-outline me-5 mb-5">
                  <button
                    type="button"
                    class="btn btn-primary me-2 w-100px h-100px d-flex justify-content-center align-items-center"
                    @click="handleAddAttachmentClick"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <input
                  v-show="0"
                  ref="attachmentInput"
                  type="file"
                  name="images"
                  @change="handleAttachmentChange"
                />
              </div>
            </div>
          </template>

          <h1 class="fs-2 fw-bold my-2 mb-5">
            {{ $t('spc.questionCatalog.catalogByManagementLevel') }}
          </h1>

          <div
            v-if="!computedHasCurrentCatalogManagmentLevels"
            class="alert alert-warning d-flex align-items-center p-5"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
              <inline-svg src="media/icons/duotune/general/gen044.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-warning">
                {{ $t('spc.questionCatalog.noManagementLevelsTitle') }}
              </h4>
              <span>{{
                $t('spc.questionCatalog.noManagementLevelsText')
              }}</span>
            </div>
          </div>

          <ul
            v-if="computedHasCurrentCatalogManagmentLevels"
            class="nav nav-tabs nav-line-tabs mb-5 fs-5 nav-line-tabs-2x"
          >
            <li
              class="nav-item"
              v-for="(
                questionCatalogLevel, index
              ) of computedCurrentManagementLevels"
              :key="index"
            >
              <span class="d-flex align-items-center">
                <a
                  :class="`nav-link me-3 ${
                    data.currentManagementLevelId ===
                    questionCatalogLevel.level.uId
                      ? 'active'
                      : ''
                  }`"
                  href="javascript:"
                  @click="
                    handleManagementLevelChange(questionCatalogLevel.level)
                  "
                >
                  <span
                    >{{ questionCatalogLevel.level.name }} ({{
                      computedQuestionInManagementlevelAmount(
                        questionCatalogLevel,
                      )
                    }})</span
                  >
                </a>
                <a
                  href="javascript:"
                  class="btn-icon me-3"
                  @click="editManagementLevel(questionCatalogLevel.level)"
                  :title="$t('edit')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-edit fs-8"></i>
                  </span>
                </a>
                <a
                  href="javascript:"
                  class="btn-icon me-3"
                  @click="
                    disconnectManagementLevelWithCatalog(questionCatalogLevel)
                  "
                  :title="$t('remove')"
                >
                  <span class="svg-icon svg-icon-2">
                    <i class="fa fa-trash fs-8"></i>
                  </span>
                </a>
              </span>
            </li>
          </ul>

          <template v-if="computedHasCurrentCatalogManagmentLevels">
            <div
              v-if="!computedHasCurrentCatalogQuestions"
              class="alert alert-warning d-flex align-items-center p-5"
            >
              <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                <inline-svg src="media/icons/duotune/general/gen044.svg" />
              </span>
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-warning">
                  {{ $t('spc.questionCatalog.noQuestionsTitle') }}
                </h4>
                <span>{{ $t('spc.questionCatalog.noQuestionsText') }}</span>
              </div>
            </div>
            <div v-else class="row">
              <template
                v-for="(catalogLevel, index) of data.currentCatalog.levels"
                :key="index"
              >
                <template
                  v-if="
                    catalogLevel.level.uId === data.currentManagementLevelId
                  "
                >
                  <draggable
                    v-model="catalogLevel.catalogQuestions"
                    item-key="pos"
                    @change="handleDragEnd"
                    :move="onMoveCallback"
                  >
                    <template #item="{ element }">
                      <div class="col-12">
                        <question-config-container
                          :class="
                            computedQuestionStyleClasses(element.question)
                          "
                          :question="element.question"
                          :appendAllowed="false"
                          :rankUpAllowed="false"
                          :rankDownAllowed="false"
                          :deleteAllowed="false"
                          :disconnectAllowed="true"
                          @questionDisconnect="handleDisconnectQuestion"
                          @editQuestion="editQuestion"
                        />
                      </div>
                    </template>
                  </draggable>
                </template>
              </template>
            </div>
            <div class="row" v-if="computedIsAllowedToSave">
              <div class="col text-end">
                <button
                  type="submit"
                  href="javascript:"
                  class="btn btn-sm btn-primary"
                >
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </template>
        </MyForm>

        <hr class="my-10" />

        <div class="row">
          <div class="col-12 col-md-4">
            <h2 class="fs-2 fw-bold my-2">
              {{ $t('spc.questionCatalog.allManagementLevels') }}
              <span class="fs-6 text-gray-400 ms-1"
                >{{ computedCurrentManagementLevels.length }} ausgewählt</span
              >
            </h2>
            <div v-if="computedManagementLevelsAll.length">
              <MyForm
                class="row d-flex mb-2 align-items-center"
                v-for="(
                  managementLevel, managementLevelIndex
                ) of computedManagementLevelsAll"
                :key="managementLevelIndex"
                @submit="
                  (event) => {
                    handleManagementLevelUpdate(managementLevelIndex, event);
                  }
                "
                :validation-schema="nameValidator"
              >
                <div class="col">
                  <span v-if="managementLevel.edit === false">{{
                    managementLevel.name
                  }}</span>
                  <div v-else>
                    <Field
                      type="text"
                      class="form-control form-control-sm form-control-solid"
                      name="name"
                      v-model="managementLevel.name"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="managementLevel.edit === false" class="col text-end">
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="connectManagementLevelWithCatalog(managementLevel)"
                    :title="$t('fes.config.connect')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-plus"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="editEntity(managementLevel)"
                    :title="$t('edit')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-edit"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="deleteManagementLevel(managementLevel)"
                    :title="$t('remove')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-trash"></i>
                    </span>
                  </a>
                </div>
                <div v-else class="col text-end">
                  <button
                    type="submit"
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    :title="$t('save')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-save"></i>
                    </span>
                  </button>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="
                      discardEntityEdit(
                        managementLevel,
                        data.managementLevelsAll,
                      )
                    "
                    :title="$t('cancel')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-times"></i>
                    </span>
                  </a>
                </div>
              </MyForm>
            </div>

            <div class="row pt-3">
              <div class="col">
                <button
                  class="btn btn-secondary"
                  @click="addNewManagementLevel"
                  type="button"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('spc.questionCatalog.addManagementLevel') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="d-flex justify-content-between mb-3">
              <h2 class="fs-2 fw-bold my-2">
                {{ $t('spc.questionCatalog.allQuestions') }}
                <span class="fs-6 text-gray-400 ms-1"
                  >{{
                    computedCurrentConnectedCatalogQuestions.length
                  }}
                  ausgewählt</span
                >
              </h2>
              <div class="d-flex">
                <select
                  name="questionType"
                  class="form-select form-select-solid form-select-lg me-2"
                  v-model="data.filteredQuestionType"
                >
                  <option :value="null">{{ $t('spc.question.all') }}</option>
                  <option
                    v-for="(type, index) in computedQuestionTypes"
                    :key="index"
                    :value="type"
                  >
                    {{ $t(`spc.question.types.${type}`) }}
                  </option>
                </select>
                <input
                  type="text"
                  id="questionSearchInput"
                  class="form-control form-control-solid"
                  :placeholder="$t('spc.questionCatalog.searchQuestion')"
                  v-model="data.questionSearchInput"
                />
              </div>
            </div>

            <template
              v-for="(question, index) in computedQuestionsAll"
              :key="index"
            >
              <question-config-container
                class="mb-3"
                :question="question"
                :appendAllowed="computedHasCurrentCatalogManagmentLevels"
                :disconnectAllowed="false"
                @questionDeleteAtIndex="handleDeleteQuestion"
                @questionConnect="handleConnectQuestion"
                @questionConnectToAll="handleConnectQuestionToAll"
                @editQuestion="editQuestion"
              />
            </template>

            <div
              v-if="!computedQuestionsAll.length"
              class="alert alert-warning d-flex align-items-center p-5"
            >
              <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                <inline-svg src="media/icons/duotune/general/gen044.svg" />
              </span>
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-warning">
                  {{ $t('spc.questionCatalog.noQuestionsTitle') }}
                </h4>
              </div>
            </div>

            <div class="row pt-3">
              <div class="col">
                <button
                  class="btn btn-secondary"
                  @click="addNewQuestion"
                  type="button"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('spc.questionCatalog.addQuestion') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </side-drawer>
      <question-drawer
        :question="data.currentQuestion"
        @questionUpdate="handleQuestionUpdate"
        :org-chart-data="data.orgChartData"
      />
    </div>
    <div v-if="data.isLoaded === true" class="my-3 mb-10">
      <h2 class="fs-2 fw-bold mb-5">
        {{ $t('responsiblePeople') }}
      </h2>
      <div class="row mb-6" v-if="data.currentNode">
        <div class="col-12" v-if="computedInheritedUserManagementAreas.length">
          <el-collapse v-model="data.activeLevels">
            <el-collapse-item
              v-for="orgUnitWithManagementAreas of computedInheritedUserManagementAreasManager"
              :key="orgUnitWithManagementAreas.id"
            >
              <template v-slot:title>
                <span class="svg-icon me-3">
                  <inline-svg src="media/icons/duotune/maps/map003.svg" />
                </span>
                {{ orgUnitWithManagementAreas.name }}
              </template>
              <span
                class="badge badge-success me-3"
                v-for="managementArea of computedUserManagementAreasByType(
                  orgUnitWithManagementAreas.managementAreas,
                  'manager',
                )"
                :key="managementArea.id"
                >{{ managementArea.user.name }}</span
              >
            </el-collapse-item>
            <div class="el-collapse-item">
              <div class="el-collapse-item__header">
                <span class="svg-icon svg-icon-primary me-3">
                  <inline-svg src="media/icons/duotune/maps/map008.svg" />
                </span>
                <span class="me-3">{{ data.currentNode.name }}</span>
                <span class="badge badge-light-info">{{
                  $t('savesAutomatically')
                }}</span>
              </div>
            </div>
          </el-collapse>
        </div>
        <div class="col fv-row">
          <multiselect
            ref="responsibleMultiselect"
            mode="tags"
            v-model="data.userManagementAreasUserIds"
            :options="computedSelectableUsers"
            :searchable="true"
            :createTag="false"
            :filterResults="false"
            valueProp="id"
            label="name"
            trackBy="id"
            @search-change="handleUserSearch"
            @select="handleUserManagementAreaSelect"
            @deselect="handleUserManagementAreaDeselect"
          >
            <template v-slot:tag="{ option, handleTagRemove }">
              <div class="multiselect-tag is-user">
                {{ option.name }}
                <span
                  class="multiselect-tag-remove"
                  @mousedown.prevent="handleTagRemove(option, $event)"
                >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div v-if="data.isLoaded === true" class="my-3">
      <h2 class="fs-2 fw-bold mb-5">
        <span class="me-3">{{ $t('spc.userManagement.workers') }}</span>
      </h2>
      <div class="row mb-6" v-if="data.currentNode">
        <div class="col-12" v-if="computedInheritedUserManagementAreas.length">
          <el-collapse v-model="data.activeLevels">
            <el-collapse-item
              v-for="orgUnitWithManagementAreas of computedInheritedUserManagementAreasWorker"
              :key="orgUnitWithManagementAreas.id"
            >
              <template v-slot:title>
                <span class="svg-icon me-3">
                  <inline-svg src="media/icons/duotune/maps/map003.svg" />
                </span>
                {{ orgUnitWithManagementAreas.name }}
              </template>
              <span
                class="badge badge-light me-3"
                v-for="managementArea of computedUserManagementAreasByType(
                  orgUnitWithManagementAreas.managementAreas,
                  'worker',
                )"
                :key="managementArea.id"
                >{{ managementArea.user.name }}</span
              >
            </el-collapse-item>
            <div class="el-collapse-item">
              <div class="el-collapse-item__header">
                <span class="svg-icon svg-icon-primary me-3">
                  <inline-svg src="media/icons/duotune/maps/map008.svg" />
                </span>
                <span class="me-3">{{ data.currentNode.name }}</span>
                <span class="badge badge-light-info">{{
                  $t('savesAutomatically')
                }}</span>
              </div>
            </div>
          </el-collapse>
        </div>
        <div class="col fv-row">
          <el-transfer
            v-model="data.currentSelectedWorkers"
            filterable
            :filter-placeholder="$t('spc.questionCatalog.searchUser')"
            :data="computedSelectableWorkers"
            :titles="[
              $t('spc.userManagement.availableWorkers'),
              $t('spc.userManagement.selectedWorkers'),
            ]"
            :button-texts="[
              $t('spc.userManagement.disconnectWorker'),
              $t('spc.userManagement.connectWorker'),
            ]"
            style="
              --el-transfer-panel-width: 270px;
              --el-transfer-panel-body-height: 320px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
            @change="handleWorkerChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  // import * as _ from 'lodash';
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import draggable from 'vuedraggable';
  import Swal from 'sweetalert2';
  import {
    computed,
    defineComponent,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import OrgUnitPath from '../fes/OrgUnitPath.vue';
  import QuestionDrawer from './QuestionDrawer.vue';
  import { getFullOrgUnitPathSPC } from '@/core/helpers/spc';
  import { computedDate, openSideDrawer } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import axios, { AxiosResponse } from 'axios';
  import Multiselect from '@vueform/multiselect';
  import {
    ManagementLevel,
    QuestionCatalog,
    HierarchyName,
    OrgChartDataPairWithCatalogs,
    Question,
    CatalogQuestion,
    ManagementLevelWithForm,
    QuestionAttachment,
    QuestionCatalogLevel,
    QuestionCatalogImage,
    QuestionType,
    UserManagementArea,
    AddUserManagementAreaDto,
    UserManagementAreaType,
    UserManagementAreaWithUser,
  } from '@/core/models/spc';
  import QuestionConfigContainer from './QuestionConfigContainer.vue';
  import {
    getInheritQuestionCatalogsToTop,
    getNodePathSPC,
  } from '@/core/helpers/spc';
  import { User } from '@/store/modules/AuthModule';

  type QuestionCatalogConfig = {
    questions: Question[];
    managementLevels: ManagementLevel[];
  };

  type OrgUnitWithManagementArea = OrgChartDataPairWithCatalogs & {
    managementAreas: UserManagementAreaWithUser[];
  };

  interface Option {
    key: string;
    label: string;
  }

  export default defineComponent({
    name: 'questionCatalogDrawer',
    props: {
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<HierarchyName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs | null>,
        default: null,
        required: true,
      },
    },
    components: {
      SideDrawer,
      MyForm: Form,
      Field,
      ErrorMessage,
      OrgUnitPath,
      QuestionConfigContainer,
      QuestionDrawer,
      draggable,
      Multiselect,
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const responsibleMultiselect = ref({
        remove: () => null,
        deselect: () => null,
        clearSearch: () => null,
        clear: () => null,
        refreshOptions: () => null,
      });

      const attachmentInput: Ref<HTMLElement | null> = ref(null);

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs | null;
        currentCatalog: QuestionCatalog | null;
        currentManagementLevel: ManagementLevel | null;
        currentManagementLevelId: string | null;
        managementLevelsAll: ManagementLevelWithForm[];
        questionsAll: Question[];
        currentQuestion: Question | null;
        questionSearchInput: string;
        filteredQuestionType: QuestionType | null;
        userManagementAreasUserIds: string[];
        userManagementAreas: UserManagementAreaWithUser[];
        currentSelectedUsers: User[];
        currentSelectableUsers: User[];
        currentSelectedWorkers: string[];
        currentSelectableWorkers: Option[];
        currentSelectableWorkersOriginal: User[];
        isLoaded: boolean;
        activeLevels: string[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        currentCatalog: null,
        currentManagementLevel: null,
        currentManagementLevelId: null,
        managementLevelsAll: [] as ManagementLevelWithForm[],
        questionsAll: [] as Question[],
        currentQuestion: null,
        questionSearchInput: '',
        filteredQuestionType: null,
        userManagementAreasUserIds: [] as string[],
        userManagementAreas: [] as UserManagementAreaWithUser[],
        currentSelectedUsers: [] as User[],
        currentSelectableUsers: [] as User[],
        currentSelectedWorkers: [] as string[],
        currentSelectableWorkers: [] as Option[],
        currentSelectableWorkersOriginal: [] as User[],
        isLoaded: false,
        activeLevels: [],
      });

      const computedQuestionsAll = computed(() => {
        return data.questionsAll
          .filter((question: Question) => {
            if (data.questionSearchInput.length === 0) return true;

            if (
              question.name
                .toLowerCase()
                .indexOf(data.questionSearchInput.toLowerCase()) !== -1
            )
              return true;
            return false;
          })
          .filter((question: Question) => {
            if (data.filteredQuestionType) {
              return question.type === data.filteredQuestionType;
            }
            return true;
          })
          .filter((question: Question) => {
            return (
              computedCurrentConnectedCatalogQuestions.value.findIndex(
                (loopedCatalogQuestion: CatalogQuestion) => {
                  return loopedCatalogQuestion.question.uId === question.uId;
                },
              ) === -1
            );
          });
      });

      const computedQuestionCatalogHasParent = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          return questionCatalog.parent !== null;
        };
      });

      const computedInheritedUserManagementAreasManager = computed(() => {
        return computedInheritedUserManagementAreas.value
          .map((orgUnit: OrgUnitWithManagementArea) => {
            orgUnit.managementAreas = orgUnit.managementAreas.filter(
              (userManagementArea: UserManagementAreaWithUser) => {
                return userManagementArea.user !== null;
              },
            );
            return orgUnit;
          })
          .filter((orgUnit: OrgUnitWithManagementArea) => {
            return orgUnit.managementAreas.some(
              (userManagementArea: UserManagementAreaWithUser) => {
                return (
                  userManagementArea.type === UserManagementAreaType.MANAGER
                );
              },
            );
          });
      });

      const computedInheritedUserManagementAreasWorker = computed(() => {
        return computedInheritedUserManagementAreas.value
          .map((orgUnit: OrgUnitWithManagementArea) => {
            orgUnit.managementAreas = orgUnit.managementAreas.filter(
              (userManagementArea: UserManagementAreaWithUser) => {
                return userManagementArea.user !== null;
              },
            );
            return orgUnit;
          })
          .filter((orgUnit: OrgUnitWithManagementArea) => {
            return orgUnit.managementAreas.some(
              (userManagementArea: UserManagementAreaWithUser) => {
                return (
                  userManagementArea.type === UserManagementAreaType.WORKER
                );
              },
            );
          });
      });

      const computedInheritedUserManagementAreas = computed(
        (): OrgUnitWithManagementArea[] => {
          // return (orgUnit: OrgChartDataPairWithCatalogs) => {

          if (data.currentNode === null) return [];

          const orgUnit = data.currentNode;
          const pathToUnit = getNodePathSPC(orgUnit, data.orgChartData);
          pathToUnit.pop();

          return pathToUnit
            .map((orgUnit: OrgChartDataPairWithCatalogs) => {
              const orgUnitWithManagementAreas = Object.assign(
                {},
                orgUnit,
              ) as OrgUnitWithManagementArea;

              orgUnitWithManagementAreas.managementAreas =
                data.userManagementAreas.filter(
                  (userManagementArea: UserManagementArea) => {
                    return userManagementArea.orgUnitId === orgUnit.id;
                  },
                );
              return orgUnitWithManagementAreas;
            })
            .filter((orgUnitWithManagementAreas: OrgUnitWithManagementArea) => {
              return orgUnitWithManagementAreas.managementAreas.length > 0;
            })
            .sort(
              (
                orgUnitWithManagementAreasA: OrgUnitWithManagementArea,
                orgUnitWithManagementAreasB: OrgUnitWithManagementArea,
              ) => {
                if (typeof orgUnitWithManagementAreasA.depth === 'undefined')
                  return -1;
                if (typeof orgUnitWithManagementAreasB.depth === 'undefined')
                  return -1;
                if (
                  orgUnitWithManagementAreasA.depth <
                  orgUnitWithManagementAreasB.depth
                ) {
                  return -1;
                }
                if (
                  orgUnitWithManagementAreasA.depth >
                  orgUnitWithManagementAreasB.depth
                ) {
                  return 1;
                }
                return 0;
              },
            );
          // .map((orgUnitWithManagementAreas: OrgUnitWithManagementArea) => {
          //   return orgUnitWithManagementAreas.managementAreas;
          // });

          // return [];
          // };
        },
      );

      const computedOverwrittenCatalogPath = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          if (typeof questionCatalog === 'undefined') return '';
          if (questionCatalog.parent === null) return '';
          const orgUnit = computedOrgUnit.value(
            questionCatalog.parent.orgUnitId,
          ) as OrgChartDataPairWithCatalogs;
          return (
            getFullOrgUnitPathSPC(orgUnit, data.orgChartData) +
            ': ' +
            questionCatalog.parent.name
          );
        };
      });

      async function handleUserSearch(search: string) {
        if (search.length < 1) return;

        const response = await ApiService.post('users/find', {
          data: {
            query: search,
          },
        });

        data.currentSelectableUsers = [
          ...response.data,
          ...data.currentSelectedUsers,
        ];
      }

      const computedCatalogDrawerTitle = computed(() => {
        if (
          data.currentCatalog === null ||
          typeof data.currentCatalog.id === 'undefined'
        ) {
          return t('spc.questionCatalog.new');
        }
        return t('spc.questionCatalog.single') + ' ' + data.currentCatalog.name;
      });

      const computedHasCurrentCatalogManagmentLevels = computed(() => {
        if (data.currentCatalog === null) return false;
        return data.currentCatalog.levels.length > 0;
      });
      const computedHasCurrentCatalogQuestions = computed(() => {
        return (
          data.currentCatalog &&
          data.currentCatalog.levels.some(
            (catalogLevel: QuestionCatalogLevel) => {
              return catalogLevel.catalogQuestions.length > 0;
            },
          )
        );
      });

      function handleManagementLevelChange(managementLevel: ManagementLevel) {
        if (typeof managementLevel.uId === 'undefined') return;
        data.currentManagementLevel = managementLevel;
        data.currentManagementLevelId = managementLevel.uId;
      }

      async function editManagementLevel(managementLevel: ManagementLevel) {
        const { value: newCategoryName } = await Swal.fire({
          title: t('spc.questionCatalog.managementLevelEdit.edit'),
          input: 'text',
          inputValue: managementLevel.name,
          showCancelButton: true,
        });

        if (newCategoryName) {
          store.dispatch(Actions.START_LOADER);

          managementLevel.name = newCategoryName;

          const updateResponse = await ApiService.post(
            'spc/config/managementlevel',
            {
              data: managementLevel,
            },
          );

          managementLevel = updateResponse.data;

          data.orgChartData.forEach((orgUnit: OrgChartDataPairWithCatalogs) => {
            orgUnit.questionCatalogs.forEach(
              (questionCatalog: QuestionCatalog) => {
                questionCatalog.levels.forEach(
                  (questionCatalogLevel: QuestionCatalogLevel) => {
                    if (
                      questionCatalogLevel.level.uId === managementLevel.uId
                    ) {
                      questionCatalogLevel.level = managementLevel;
                    }
                  },
                );
              },
            );
          });

          store.dispatch(Actions.END_LOADER);
        }
      }

      function editEntity(entity: ManagementLevelWithForm) {
        entity.edit = true;
        entity.nameBefore = entity.name;
      }

      connectManagementLevelWithCatalog;
      function connectManagementLevelWithCatalog(
        managementLevel: ManagementLevelWithForm,
      ) {
        const questionCatalogLevel: QuestionCatalogLevel = {
          catalogQuestions: [] as CatalogQuestion[],
          level: managementLevel,
        };

        data.currentCatalog?.levels.push(questionCatalogLevel);
        if (data.currentManagementLevel === null) {
          setManagementLevelToFirstActive();
        }
      }

      async function disconnectManagementLevelWithCatalog(
        questionCatalogLevel: QuestionCatalogLevel,
      ) {
        if (data.currentCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('spc.questionCatalog.disconnectManagementLevelQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        data.currentCatalog.levels = data.currentCatalog.levels.filter(
          (catalogLevel: QuestionCatalogLevel) => {
            return catalogLevel.level.uId !== questionCatalogLevel.level.uId;
          },
        );
        setManagementLevelToFirstActive();

        // data.currentCatalog.catalogQuestions =
        //   data.currentCatalog.catalogQuestions.filter(
        //     (catalogQuestion: CatalogQuestion) => {
        //       return catalogQuestion.level.uId !== managementLevel.uId;
        //     },
        //   );

        // data.managementLevelsActive = data.managementLevelsActive.filter(
        //   (managementLevelActive: ManagementLevelWithForm) => {
        //     return managementLevelActive.uId !== managementLevel.uId;
        //   },
        // );

        // setManagementLevelToFirstActive();
      }

      const computedCurrentInheritedQuestionCatalogs = computed(() => {
        if (data.currentNode === null) return [];
        // if (data.currentNode.parentId) {
        //   const currentNodeParentId = data.currentNode.parentId;
        //   const parent = data.orgChartData.find((orgUnit) => {
        //     return orgUnit.id === currentNodeParentId;
        //   });
        //   if (parent) return parent.questionCatalogs;
        // }
        return getInheritQuestionCatalogsToTop(
          data.currentNode,
          computedOrgChartData.value,
        );
      });

      const computedCurrentQuestionCatalogs = computed(() => {
        if (data.currentNode === null) return [];
        return computedCurrentInheritedQuestionCatalogs.value
          .slice(0)
          .sort(
            (
              questionCatalogA: QuestionCatalog,
              questionCatalogB: QuestionCatalog,
            ) => {
              if (typeof questionCatalogA.id === 'undefined') return -1;
              if (typeof questionCatalogB.id === 'undefined') return -1;
              if (questionCatalogA.id < questionCatalogB.id) {
                return -1;
              }
              if (questionCatalogA.id > questionCatalogB.id) {
                return 1;
              }
              return 0;
            },
          )
          .filter((questionCatalog: QuestionCatalog) => {
            return !computedCurrentInheritedQuestionCatalogs.value.some(
              (loopedQuestionCatalog: QuestionCatalog) => {
                if (loopedQuestionCatalog.parent === null) return false;
                return loopedQuestionCatalog.parent.id === questionCatalog.id;
              },
            );
          });
      });

      const computedOrgChartData = computed(() => {
        return data.orgChartData;
      });

      const computedNodePathAreaTitle = computed(() => {
        const nodePath = props.nodePath.slice(1);
        const nodePathLength = nodePath.length;
        const errorCaseCdfName = props.areaNames[nodePathLength - 1];
        if (errorCaseCdfName) {
          return errorCaseCdfName.name;
        }
        return '';
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const openQuestionCatalog = (questionCatalog: QuestionCatalog) => {
        questionCatalog.levels.forEach(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            questionCatalogLevel.catalogQuestions.forEach(
              (catalogQuestion: CatalogQuestion, index: number) => {
                catalogQuestion.pos = index;
              },
            );
          },
        );
        data.currentCatalog = Object.assign(
          {},
          questionCatalog,
        ) as QuestionCatalog;
        setManagementLevelToFirstActive();
        openSideDrawer('spcConfigDrawerQuestionCatalog');
      };

      const openQuestionCatalogParent = (questionCatalog: QuestionCatalog) => {
        if (questionCatalog.parent === null) return false;
        return openQuestionCatalog(questionCatalog.parent);
      };

      const disableQuestionCatalog = async (
        questionCatalog: QuestionCatalog,
      ) => {
        if (data.currentNode === null) return;

        store.dispatch(Actions.START_LOADER);
        questionCatalog.disabledOrgUnitIds.push(data.currentNode.id);
        await ApiService.post('spc/questioncatalog/config', {
          data: questionCatalog,
        });
        emitUpdate();
        store.dispatch(Actions.END_LOADER);
      };

      const enableQuestionCatalog = async (
        questionCatalog: QuestionCatalog,
      ) => {
        if (data.currentNode === null) return;
        store.dispatch(Actions.START_LOADER);
        questionCatalog.disabledOrgUnitIds =
          questionCatalog.disabledOrgUnitIds.filter(
            (disabledOrgUnitId: string) => {
              return (
                data.currentNode && disabledOrgUnitId !== data.currentNode.id
              );
            },
          );
        await ApiService.post('spc/questioncatalog/config', {
          data: questionCatalog,
        });
        emitUpdate();
        store.dispatch(Actions.END_LOADER);
      };

      const computedCurrentManagementLevels = computed(
        (): QuestionCatalogLevel[] => {
          if (data.currentCatalog === null) return [];
          return data.currentCatalog.levels
            .slice(0)
            .sort(
              (
                questionCatalogLevelA: QuestionCatalogLevel,
                questionCatalogLevelB: QuestionCatalogLevel,
              ) => {
                if (
                  questionCatalogLevelA.level.name <
                  questionCatalogLevelB.level.name
                ) {
                  return -1;
                }
                if (
                  questionCatalogLevelA.level.name >
                  questionCatalogLevelB.level.name
                ) {
                  return 1;
                }
                return 0;
              },
            );
        },
      );

      const openEmptyQuestionCatalog = () => {
        if (data.currentNode === null) return;
        data.currentCatalog = Object.assign(
          {},
          {
            orgUnitId: data.currentNode.id,
            levels: [] as QuestionCatalogLevel[],
            parent: null,
          },
        ) as QuestionCatalog;
        data.currentManagementLevel = null;
        data.currentManagementLevelId = null;
        openSideDrawer('spcConfigDrawerQuestionCatalog');
      };

      async function copyQuestionCatalog(questionCatalog: QuestionCatalog) {
        if (data.currentNode === null) return;
        // const copiedQuestionCatalog = Object.assign(
        //   {},
        //   questionCatalog,
        // ) as QuestionCatalog;

        // delete copiedQuestionCatalog.id;
        // copiedQuestionCatalog.orgUnitId = data.currentNode.id;
        // copiedQuestionCatalog.levels = copiedQuestionCatalog.levels.map(
        //   (catalogLevel: QuestionCatalogLevel) => {
        //     const copiedCatalogLevel = Object.assign(
        //       {},
        //       catalogLevel,
        //     ) as QuestionCatalogLevel;
        //     delete copiedCatalogLevel.id;
        //     copiedCatalogLevel.catalogQuestions =
        //       copiedCatalogLevel.catalogQuestions.map(
        //         (catalogQuestion: CatalogQuestion) => {
        //           const copiedCatalogQuestion = Object.assign(
        //             {},
        //             catalogQuestion,
        //           ) as CatalogQuestion;
        //           // remove id from catalogQuestion and return new object
        //           delete copiedCatalogQuestion.id;
        //           return copiedCatalogQuestion;
        //         },
        //       );
        //     return copiedCatalogLevel;
        //   },
        // );

        // copiedQuestionCatalog.disabledOrgUnitIds = [];
        // copiedQuestionCatalog.parent = copiedQuestionCatalog;

        // data.currentCatalog = copiedQuestionCatalog;
        data.currentCatalog = Object.assign(
          {},
          {
            orgUnitId: data.currentNode.id,
            levels: questionCatalog.levels
              .map((catalogLevel: QuestionCatalogLevel) => {
                // remove id from catalogLevel and return new object
                delete catalogLevel.id;
                catalogLevel.catalogQuestions = catalogLevel.catalogQuestions
                  .map((catalogQuestion: CatalogQuestion) => {
                    // remove id from catalogQuestion and return new object
                    delete catalogQuestion.id;
                    return Object.assign({}, catalogQuestion);
                  })
                  .slice(0);
                return Object.assign({}, catalogLevel);
              })
              .slice(0),
            name: questionCatalog.name,
            disabledOrgUnitIds: [] as string[],
            image: questionCatalog.image,
            parent: questionCatalog,
          },
        ) as QuestionCatalog;
        data.currentManagementLevel = null;
        data.currentManagementLevelId = null;
        setManagementLevelToFirstActive();
        openSideDrawer('spcConfigDrawerQuestionCatalog');
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      type NameUpdate = {
        name: string;
      };

      const computedManagementLevelsAll = computed(() => {
        return data.managementLevelsAll.filter(
          (managementLevel: ManagementLevelWithForm) => {
            if (
              data.currentCatalog !== null &&
              data.currentCatalog.levels.findIndex(
                (questionCatalogLevel: QuestionCatalogLevel) => {
                  const managementLevelActive = questionCatalogLevel.level;
                  return managementLevelActive.uId === managementLevel.uId;
                },
              ) === -1
            ) {
              return true;
            }
            return false;
          },
        );
      });

      async function addNewManagementLevel() {
        const newManagementLevel = {
          name: '',
          nameBefore: '',
          edit: true,
        } as ManagementLevelWithForm;
        data.managementLevelsAll.push(newManagementLevel);
      }

      async function handleManagementLevelUpdate(
        index: number,
        change: NameUpdate,
      ) {
        store.dispatch(Actions.START_LOADER);

        const entity = computedManagementLevelsAll.value[
          index
        ] as ManagementLevelWithForm;

        if (entity) {
          entity.edit = false;
          entity.nameBefore = change.name;

          const updateObject: ManagementLevel = {
            name: change.name,
          } as ManagementLevel;

          if (entity.uId) {
            updateObject.uId = entity.uId;
          }

          const savedManagementLevel: AxiosResponse<ManagementLevel> =
            await ApiService.post('spc/config/managementlevel', {
              data: updateObject,
            });

          entity.id = savedManagementLevel.data.id;
          entity.uId = savedManagementLevel.data.uId;
          entity.name = savedManagementLevel.data.name;
        }
        store.dispatch(Actions.END_LOADER);
      }

      async function handleWorkerChange(
        newState: string[],
        dir: string,
        changes: string[],
      ) {
        if (data.currentNode === null) return;

        store.dispatch(Actions.START_LOADER);

        await Promise.all(
          changes.map(async (userId: string) => {
            const foundUser = data.currentSelectableWorkersOriginal.find(
              (worker: User) => {
                return worker.id === userId;
              },
            );

            if (foundUser) {
              if (data.currentNode === null) return;
              const addUserManagementAreaDto: AddUserManagementAreaDto = {
                data: {
                  user: foundUser,
                  orgUnitId: data.currentNode.id,
                  type: UserManagementAreaType.WORKER,
                },
              };

              if (dir === 'left') {
                // remove
                await ApiService.post(
                  'spc/users/area/delete',
                  addUserManagementAreaDto,
                );

                data.userManagementAreas = data.userManagementAreas.filter(
                  (userManagementArea: UserManagementAreaWithUser) => {
                    return !(
                      userManagementArea.userId === foundUser.id &&
                      userManagementArea.orgUnitId === data.currentNode?.id &&
                      userManagementArea.type === UserManagementAreaType.WORKER
                    );
                  },
                );
              } else {
                // add
                const savedUserManagementAreaResponse = await ApiService.put(
                  'spc/users/area',
                  addUserManagementAreaDto,
                );

                data.userManagementAreas.push(
                  savedUserManagementAreaResponse.data,
                );
              }
            }

            initSelectedWorkers();

            return true;
          }),
        );
        store.dispatch(Actions.END_LOADER);
      }

      async function updateUserManagementAreas() {
        const loadedUserManagementAreasRequest =
          await ApiService.get('spc/users/areas');

        const loadedUserManagementAreas: UserManagementAreaWithUser[] =
          loadedUserManagementAreasRequest.data as UserManagementAreaWithUser[];

        data.userManagementAreas =
          loadedUserManagementAreas as UserManagementAreaWithUser[];
      }

      async function updateAvailableOptions() {
        const loadedQuestionCatalogConfigResponse: AxiosResponse<QuestionCatalogConfig> =
          await ApiService.get('spc/questioncatalog/config');

        data.managementLevelsAll =
          loadedQuestionCatalogConfigResponse.data.managementLevels.map(
            (managementLevel: ManagementLevel) => {
              const managementLevelObject = Object.assign(
                {
                  edit: false,
                  nameBefore: managementLevel.name,
                } as ManagementLevelWithForm,
                managementLevel,
              );
              return managementLevelObject;
            },
          );

        data.questionsAll = loadedQuestionCatalogConfigResponse.data.questions;
      }

      async function handleQuestionCatalogSubmit() {
        if (data.currentNode === null) return;
        if (data.currentCatalog === null) return;
        store.dispatch(Actions.START_LOADER);

        const isNew = typeof data.currentCatalog.id === 'undefined';

        const questionCatalog: QuestionCatalog = Object.assign(
          {},
          data.currentCatalog,
        );

        questionCatalog.levels = questionCatalog.levels.filter(
          (catalogLevel: QuestionCatalogLevel) => {
            return catalogLevel.catalogQuestions.length !== 0;
          },
        );

        const newSavedQuestionCatalog = await ApiService.post(
          'spc/questioncatalog/config',
          {
            data: questionCatalog,
          },
        );

        if (
          questionCatalog.parent !== null &&
          !questionCatalog.parent.disabledOrgUnitIds.includes(
            data.currentNode.id,
          )
        ) {
          if (
            computedIsQuestionCatalogAllowedToDisable.value(
              questionCatalog.parent,
            )
          ) {
            await disableQuestionCatalog(questionCatalog.parent);
          }
        }

        data.currentCatalog = newSavedQuestionCatalog.data;

        if (isNew) {
          data.currentNode.questionCatalogs.push(newSavedQuestionCatalog.data);
        } else {
          data.currentNode.questionCatalogs =
            data.currentNode.questionCatalogs.map(
              (questionCatalog: QuestionCatalog) => {
                if (questionCatalog.id === newSavedQuestionCatalog.data.id) {
                  return newSavedQuestionCatalog.data;
                }
                return questionCatalog;
              },
            );
        }

        emitUpdate();

        store.dispatch(Actions.END_LOADER);
      }

      async function editQuestion(question: Question) {
        data.currentQuestion = null;
        data.currentQuestion = question;
        openSideDrawer('spcQuestionDrawer');
      }

      async function addNewQuestion() {
        data.currentQuestion = null;
        data.currentQuestion = {
          name: '',
          attachments: [] as QuestionAttachment[],
          hint: '',
          noneAllowed: false,
          openPointNeeded: false,
          type: QuestionType.DEFAULT,
        } as Question;
        openSideDrawer('spcQuestionDrawer');
      }

      async function handleQuestionUpdate(question: Question) {
        data.questionsAll.forEach((loopedQuestion: Question) => {
          if (loopedQuestion.uId === question.uId) {
            loopedQuestion = question;
          }
        });

        if (
          data.currentCatalog !== null &&
          data.currentCatalog.levels.length > 0
        ) {
          data.currentCatalog.levels.forEach(
            (questionCatalogLevel: QuestionCatalogLevel) => {
              if (questionCatalogLevel.catalogQuestions.length > 0) {
                questionCatalogLevel.catalogQuestions.forEach(
                  (catalogQuestion: CatalogQuestion) => {
                    if (catalogQuestion.question.uId === question.uId) {
                      catalogQuestion.question = question;
                    }
                  },
                );
              }
            },
          );
        }

        data.orgChartData.forEach((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.questionCatalogs.length > 0) {
            orgUnit.questionCatalogs.forEach(
              (questionCatalog: QuestionCatalog) => {
                if (questionCatalog.levels.length > 0) {
                  questionCatalog.levels.forEach(
                    (questionCatalogLevel: QuestionCatalogLevel) => {
                      if (questionCatalogLevel.catalogQuestions.length > 0) {
                        questionCatalogLevel.catalogQuestions.forEach(
                          (catalogQuestion: CatalogQuestion) => {
                            if (catalogQuestion.question.uId === question.uId) {
                              catalogQuestion.question = question;
                            }
                          },
                        );
                      }
                    },
                  );
                }
              },
            );
          }
        });

        const alreadyExistentQuestion = data.questionsAll.find(
          (loopedQuestion: Question) => {
            return loopedQuestion.uId === question.uId;
          },
        );

        if (alreadyExistentQuestion) {
          data.questionsAll = data.questionsAll.map(
            (loopedQuestion: Question) => {
              if (loopedQuestion.uId === question.uId) {
                return question;
              }
              return loopedQuestion;
            },
          );
        } else {
          data.questionsAll.push(question);
        }
      }

      function setManagementLevelToFirstActive() {
        if (computedHasCurrentCatalogManagmentLevels.value === false) {
          data.currentManagementLevel = null;
          data.currentManagementLevelId = null;
        } else {
          const newSelectedManagementLevel =
            computedCurrentManagementLevels.value[0].level;
          if (
            typeof newSelectedManagementLevel !== 'undefined' &&
            newSelectedManagementLevel !== null
          ) {
            data.currentManagementLevel = newSelectedManagementLevel;
            data.currentManagementLevelId = String(
              newSelectedManagementLevel.uId,
            );
          }
        }
      }

      async function handleDeleteQuestion(question: Question) {
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('spc.questionCatalog.deleteQuestionQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        const questionToDelete = data.questionsAll.find(
          (loopedQuestion: Question) => {
            return loopedQuestion.uId === question.uId;
          },
        );
        if (!questionToDelete) return;

        store.dispatch(Actions.START_LOADER);

        await ApiService.delete(`spc/config/question/${questionToDelete.uId}`);
        data.questionsAll = data.questionsAll.filter(
          (loopedQuestion: Question) => {
            return loopedQuestion.uId !== questionToDelete.uId;
          },
        );

        data.orgChartData.forEach((orgUnit: OrgChartDataPairWithCatalogs) => {
          orgUnit.questionCatalogs.forEach(
            (questionCatalog: QuestionCatalog) => {
              questionCatalog.levels.forEach(
                (questionCatalogLevel: QuestionCatalogLevel) => {
                  questionCatalogLevel.catalogQuestions =
                    questionCatalogLevel.catalogQuestions.filter(
                      (catalogQuestion: CatalogQuestion) => {
                        return (
                          catalogQuestion.question.uId !== questionToDelete.uId
                        );
                      },
                    );
                },
              );

              questionCatalog.levels = questionCatalog.levels.filter(
                (questionCatalogLevel: QuestionCatalogLevel) => {
                  return questionCatalogLevel.catalogQuestions.length > 0;
                },
              );
            },
          );

          orgUnit.questionCatalogs = orgUnit.questionCatalogs.filter(
            (questionCatalog: QuestionCatalog) => {
              return questionCatalog.levels.length > 0;
            },
          );

          //   if (orgUnit.questionCatalogs.length > 0) {
          //     orgUnit.questionCatalogs.forEach(
          //       (questionCatalog: QuestionCatalog) => {
          //         if (questionCatalog.levels.length > 0) {
          //           questionCatalog.levels.forEach(
          //             (questionCatalogLevel: QuestionCatalogLevel) => {
          //               if (questionCatalogLevel.catalogQuestions.length > 0) {
          //                 questionCatalogLevel.catalogQuestions = questionCatalogLevel.catalogQuestions.filter(
          //                   (catalogQuestion: CatalogQuestion) => {
          //                     return catalogQuestion.question.uId !== questionToDelete.uId;
          //                   },
          //                 );
          //               }
          //             },
          //           );
          //         }
          //       },
          //     );
          //   }
          // });
        });

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleConnectQuestion(
        question: Question,
        questionCatalogLevel: QuestionCatalogLevel | null = null,
        disableErrors = false,
      ) {
        if (data.currentCatalog === null) return;
        if (data.currentManagementLevel === null) return;
        if (!question.uId) return;

        if (questionCatalogLevel === null) {
          questionCatalogLevel =
            data.currentCatalog.levels.find(
              (loopedQuestionCatalogLevel: QuestionCatalogLevel) => {
                return (
                  loopedQuestionCatalogLevel.level.uId ===
                  data.currentManagementLevelId
                );
              },
            ) || null;
        }

        if (questionCatalogLevel !== null) {
          if (question.type === QuestionType.PINNED) {
            // TODO check for question in currentQuestionCatalogLevel if already question with type === QuestionType.PINNED

            const hasQuestionWithPinnedAlready =
              questionCatalogLevel.catalogQuestions.some(
                (catalogQuestion: CatalogQuestion) => {
                  return catalogQuestion.question.type === QuestionType.PINNED;
                },
              );

            if (hasQuestionWithPinnedAlready) {
              if (disableErrors === false) {
                Swal.fire(
                  t('error'),
                  'Die aktuelle Führungsebene hat bereits eine Standardfrage.',
                  'error',
                );
              }
              return false;
            }
          }

          const newPos = questionCatalogLevel.catalogQuestions.length + 1;

          const newCatalogQuestion = {
            question: question,
            pos: newPos,
          } as CatalogQuestion;

          if (question.type === QuestionType.PINNED) {
            questionCatalogLevel.catalogQuestions.unshift(newCatalogQuestion);
          } else {
            questionCatalogLevel.catalogQuestions.push(newCatalogQuestion);
          }

          let posCounter = 0;
          questionCatalogLevel.catalogQuestions.forEach(
            (catalogQuestion: CatalogQuestion) => {
              catalogQuestion.pos = posCounter;
              posCounter++;
            },
          );
        }

        // questionCatalog.levels.forEach(
        //   (questionCatalogLevel: QuestionCatalogLevel) => {
        //     questionCatalogLevel.catalogQuestions.forEach(
        //       (catalogQuestion: CatalogQuestion, index: number) => {
        //         catalogQuestion.pos = index;
        //       },
        //     );
        //   },
        // );

        // const newPos = computedCurrentConnectedQuestions.value.length + 1;

        // const newCatalogQuestion = {
        //   question: question,
        //   level: data.currentManagementLevel,
        //   pos: newPos,
        // } as CatalogQuestion;

        // data.currentCatalog.catalogQuestions.push(newCatalogQuestion);
      }

      async function handleDisconnectQuestion(question: Question) {
        if (data.currentCatalog === null) return;
        if (data.currentManagementLevel === null) return;
        if (!question.uId) return;

        const currentQuestionCatalogLevel = data.currentCatalog.levels.find(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            return (
              questionCatalogLevel.level.uId === data.currentManagementLevelId
            );
          },
        );

        if (currentQuestionCatalogLevel !== undefined) {
          currentQuestionCatalogLevel.catalogQuestions =
            currentQuestionCatalogLevel.catalogQuestions.filter(
              (catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.uId !== question.uId;
              },
            );

          let posCounter = 0;
          currentQuestionCatalogLevel.catalogQuestions.forEach(
            (catalogQuestion: CatalogQuestion) => {
              catalogQuestion.pos = posCounter;
              posCounter++;
            },
          );
        }
      }

      function isUserAlreadyAssignedAbove(
        user: User,
        orgUnit: OrgChartDataPairWithCatalogs,
        type: UserManagementAreaType,
        offset = 0,
      ) {
        const nodePath = getNodePathSPC(orgUnit, data.orgChartData).slice(
          offset,
        );
        nodePath.pop();
        return nodePath.some((orgUnit: OrgChartDataPairWithCatalogs) => {
          const usersForOrgUnit: string[] = data.userManagementAreas
            .filter((userManagementArea: UserManagementAreaWithUser) => {
              return (
                userManagementArea.orgUnitId === orgUnit.id &&
                userManagementArea.type === type
              );
            })
            .map((userManagementArea: UserManagementAreaWithUser) => {
              return userManagementArea.userId;
            });
          return usersForOrgUnit.includes(user.id);
        });
      }

      function isUserAlreadyAssigned(
        user: User,
        orgUnit: OrgChartDataPairWithCatalogs,
        type: UserManagementAreaType,
      ) {
        return data.userManagementAreas.some(
          (userManagementArea: UserManagementArea) => {
            return (
              userManagementArea.type === type &&
              userManagementArea.orgUnitId === orgUnit.id &&
              userManagementArea.userId === user.id
            );
          },
        );
      }

      const computedSelectableUsers = computed(() => {
        return [
          ...data.currentSelectableUsers.filter((user: User) => {
            if (data.currentNode !== null) {
              return !isUserAlreadyAssignedAbove(
                user,
                data.currentNode,
                UserManagementAreaType.MANAGER,
              );
            }
            return true;
          }),
          ...data.currentSelectedUsers,
        ];
      });

      const computedSelectableWorkers = computed(() => {
        return data.currentSelectableWorkers.filter((option: Option) => {
          if (data.currentNode === null) return true;
          return (
            !isUserAlreadyAssignedAbove(
              {
                id: option.key,
              } as User,
              data.currentNode,
              UserManagementAreaType.MANAGER,
            ) &&
            (!isUserAlreadyAssignedAbove(
              {
                id: option.key,
              } as User,
              data.currentNode,
              UserManagementAreaType.WORKER,
            ) ||
              isUserAlreadyAssigned(
                {
                  id: option.key,
                } as User,
                data.currentNode,
                UserManagementAreaType.WORKER,
              ))
          );
        });
      });

      async function handleConnectQuestionToAll(question: Question) {
        if (data.currentCatalog === null) return;
        if (data.currentManagementLevel === null) return;
        if (!question.uId) return;

        data.currentCatalog.levels.forEach(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            const isQuestionAlreadyInLevel =
              questionCatalogLevel.catalogQuestions.some(
                (catalogQuestion: CatalogQuestion) => {
                  return catalogQuestion.question.uId === question.uId;
                },
              );

            if (isQuestionAlreadyInLevel) return;

            if (question.type === QuestionType.PINNED) {
              const hasLevelAlreadyPinnedQuestion =
                questionCatalogLevel.catalogQuestions.some(
                  (catalogQuestion: CatalogQuestion) => {
                    return (
                      catalogQuestion.question.type === QuestionType.PINNED
                    );
                  },
                );

              if (hasLevelAlreadyPinnedQuestion) return;
            }

            handleConnectQuestion(question, questionCatalogLevel, true);

            // questionCatalogLevel.catalogQuestions;
          },
        );

        // TODO
        // data.managementLevelsActive.forEach(
        //   (managementLevel: ManagementLevel) => {
        //     const foundInCatalog =
        //       data.currentCatalog?.catalogQuestions.findIndex(
        //         (catalogQuestion: CatalogQuestion) => {
        //           return (
        //             catalogQuestion.question.uId === question.uId &&
        //             catalogQuestion.level.uId === managementLevel.uId
        //           );
        //         },
        //       );
        //     if (foundInCatalog === -1) {
        //       if (data.currentCatalog === null) return;
        //       const newPos =
        //         data.currentCatalog?.catalogQuestions.filter(
        //           (catalogQuestion: CatalogQuestion) => {
        //             return (
        //               catalogQuestion.question.uId === question.uId &&
        //               catalogQuestion.level.uId === managementLevel.uId
        //             );
        //           },
        //         ).length + 1;
        //       const newCatalogQuestion = {
        //         question: question,
        //         level: managementLevel,
        //         pos: newPos,
        //       } as CatalogQuestion;
        //       data.currentCatalog?.catalogQuestions.push(newCatalogQuestion);
        //     }
        //   },
        // );
      }

      const computedCurrentConnectedCatalogQuestions = computed(
        (): CatalogQuestion[] => {
          if (data.currentCatalog === null) return [];
          if (data.currentManagementLevel === null) return [];
          if (data.currentCatalog.levels.length === 0) return [];
          const currentLevelCatalog = data.currentCatalog.levels.find(
            (catalogLevel: QuestionCatalogLevel) => {
              return catalogLevel.level.uId === data.currentManagementLevelId;
            },
          );
          if (currentLevelCatalog === undefined) return [];
          return currentLevelCatalog.catalogQuestions;
          // .map((catalogQuestion: CatalogQuestion) => {
          //   return catalogQuestion.question;
          // });
        },
      );

      const computedIsAllowedToSave = computed((): boolean => {
        if (data.currentCatalog === null) return false;
        if (data.currentCatalog.levels.length === 0) return false;
        if (data.currentManagementLevel === null) return false;
        // return
        return data.currentCatalog.levels.some(
          (catalogLevel: QuestionCatalogLevel) => {
            if (data.currentManagementLevel === null) return false;
            return (
              data.currentManagementLevel.uId === catalogLevel.level.uId &&
              catalogLevel.catalogQuestions.length > 0
            );
          },
        );
      });

      const computedQuestionInManagementlevelAmount = computed(() => {
        return (questionCatalogLevel: QuestionCatalogLevel) => {
          return questionCatalogLevel.catalogQuestions.length;
          // return data.currentCatalog?.catalogQuestions.filter(
          //   (catalogQuestion: CatalogQuestion) => {
          //     return catalogQuestion.level.uId === managementLevel.uId;
          //   },
          // ).length;
        };
      });

      function handleRemoveAttachment() {
        if (data.currentCatalog === null) return;
        if (data.currentCatalog.image === null) return;

        data.currentCatalog.image = null;
        // store.dispatch(Actions.START_LOADER);

        // const deleteString = `spc/attachment/${data.currentCatalog.image.id}`;

        // ApiService.delete(deleteString)
        //   .then(() => {
        //     if (data.currentCatalog === null) return;
        //     data.currentCatalog.attachment = null;
        //   })
        //   .catch(() => {
        //     Swal.fire(t('error'), t('errorAlertCaption'), 'error');
        //   })
        //   .finally(() => {
        //     store.dispatch(Actions.END_LOADER);
        //   });
      }

      function handleAddAttachmentClick() {
        if (attachmentInput.value) {
          attachmentInput.value.click();
        }
      }

      function handleAttachmentChange(event) {
        const file: File = event.target.files[0];

        if (!file) {
          return false;
        }

        store.dispatch(Actions.START_LOADER);

        const formData = new FormData();
        formData.append('image', file);

        axios
          .post('spc/questioncatalog/image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.data === '')
              return Swal.fire(t('error'), t('errorAlertCaption'), 'error');

            if (data.currentCatalog === null) return;
            data.currentCatalog.image = {
              id: response.data.id,
              fileName: response.data.fileName,
            } as QuestionCatalogImage;
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedFileNameFromUrl = computed(() => {
        return (fileUrl) => {
          return fileUrl.split('/').pop();
        };
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/questioncatalog/image/' +
            fileName
          );
        };
      });

      async function handleDeleteQuestionCatalog(
        questionCatalog: QuestionCatalog,
      ) {
        if (!questionCatalog.id) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('spc.questionCatalog.deleteQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        await ApiService.delete(
          `spc/questioncatalog/config/${questionCatalog.id}`,
        );

        data.orgChartData = data.orgChartData.map(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            orgUnit.questionCatalogs = orgUnit.questionCatalogs.filter(
              (loopedQuestionCatalog: QuestionCatalog) => {
                return loopedQuestionCatalog.id !== questionCatalog.id;
              },
            );
            return orgUnit;
          },
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedUserManagementAreasByType = computed(() => {
        return (
          userManagementAreas: UserManagementArea[],
          type: UserManagementAreaType,
        ) => {
          return userManagementAreas.filter(
            (userManagementArea: UserManagementArea) => {
              return userManagementArea.type === type;
            },
          );
        };
      });

      async function deleteManagementLevel(
        managementLevel: ManagementLevelWithForm,
      ) {
        const isManagementLevelConnected = data.orgChartData.some(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            return orgUnit.questionCatalogs.some(
              (questionCatalog: QuestionCatalog) => {
                return questionCatalog.levels.some(
                  (catalogLevel: QuestionCatalogLevel) => {
                    return catalogLevel.level.uId === managementLevel.uId;
                  },
                );
              },
            );
          },
        );

        if (isManagementLevelConnected === true) {
          Swal.fire({
            title: t('errors.deleteManagementLevelNotAllowed.title'),
            text: t('errors.deleteManagementLevelNotAllowed.text'),
            icon: 'error',
          });
        } else {
          const result = await Swal.fire({
            title: t('sureQuestionHeadline'),
            text: t('spc.questionCatalog.deleteMangementLevelQuestion'),
            icon: 'question',
            showCancelButton: true,
          });

          if (!result.isConfirmed) return;

          const managementLevelToDelete = data.managementLevelsAll.find(
            (loopedManagementLevel: ManagementLevel) => {
              return managementLevel.id === loopedManagementLevel.id;
            },
          );
          if (!managementLevelToDelete) return;
          await ApiService.delete(
            `spc/config/managementlevel/${managementLevelToDelete.uId}`,
          );
          data.managementLevelsAll = data.managementLevelsAll.filter(
            (loopedManagementLevel: ManagementLevel) => {
              return loopedManagementLevel.uId !== managementLevelToDelete.uId;
            },
          );
        }
      }

      function discardEntityEdit(
        entity: ManagementLevelWithForm,
        entityArray: ManagementLevelWithForm[],
      ) {
        if (!entity.id) return entityArray.pop();
        entity.edit = false;
        entity.name = entity.nameBefore;
      }

      function handleRankUpQuestion(question: Question) {
        if (data.currentCatalog === null) return;

        const currentQuestionCatalogLevel = data.currentCatalog.levels.find(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            return (
              questionCatalogLevel.level.uId === data.currentManagementLevelId
            );
          },
        );

        if (currentQuestionCatalogLevel !== undefined) {
          const questionToRankUpIndex =
            currentQuestionCatalogLevel.catalogQuestions.findIndex(
              (catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.uId === question.uId;
              },
            );
          if (questionToRankUpIndex !== -1) {
            const questionToRankUp =
              currentQuestionCatalogLevel.catalogQuestions[
                questionToRankUpIndex
              ];
            const questionBefore =
              currentQuestionCatalogLevel.catalogQuestions[
                questionToRankUpIndex - 1
              ];
            if (questionBefore !== undefined) {
              if (questionBefore.question.type === QuestionType.PINNED) return;
              questionBefore.pos = questionBefore.pos + 1;
              questionToRankUp.pos = questionToRankUp.pos - 1;
            }
          }
        }
      }

      function handleRankDownQuestion(question: Question) {
        if (data.currentCatalog === null) return;

        const currentQuestionCatalogLevel = data.currentCatalog.levels.find(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            return (
              questionCatalogLevel.level.uId === data.currentManagementLevelId
            );
          },
        );

        if (currentQuestionCatalogLevel !== undefined) {
          const questionToRankDownIndex =
            currentQuestionCatalogLevel.catalogQuestions.findIndex(
              (catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.uId === question.uId;
              },
            );
          if (questionToRankDownIndex !== -1) {
            const questionToRankUp =
              currentQuestionCatalogLevel.catalogQuestions[
                questionToRankDownIndex
              ];
            const questionAfter =
              currentQuestionCatalogLevel.catalogQuestions[
                questionToRankDownIndex + 1
              ];
            if (questionAfter !== undefined) {
              questionAfter.pos = questionAfter.pos - 1;
              questionToRankUp.pos = questionToRankUp.pos + 1;
            }
          }
        }
      }

      const computedQuestionCatalogQuestionAmount = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          const uniqueQuestionIds: string[] = [];
          questionCatalog.levels.forEach(
            (questionCatalogLevel: QuestionCatalogLevel) => {
              questionCatalogLevel.catalogQuestions.forEach(
                (catalogQuestion: CatalogQuestion) => {
                  const questionUid = catalogQuestion.question.uId;
                  if (!uniqueQuestionIds.includes(questionUid)) {
                    uniqueQuestionIds.push(questionUid);
                  }
                },
              );
            },
          );
          return uniqueQuestionIds.length;
        };
      });

      const computedRankDownAllowed = computed(() => {
        return (question: Question, index: number) => {
          return (
            question.type !== QuestionType.PINNED &&
            index + 1 !== computedCurrentConnectedCatalogQuestions.value.length
          );
        };
      });

      const computedQuestionStyleClasses = computed(() => {
        return (question: Question) => {
          let classString = 'mb-3 w-100 drag-question';
          if (question.type === QuestionType.PINNED) {
            classString += ' border border-info no-drag';
          }
          return classString;
        };
      });

      const computedQuestionTypes = computed(() => {
        return Object.values(QuestionType);
      });

      function handleDragEnd(dragChangeEvent) {
        if (typeof dragChangeEvent.moved !== 'undefined') {
          const movedElement: CatalogQuestion = dragChangeEvent.moved.element;

          movedElement.pos = dragChangeEvent.moved.newIndex + 1;

          if (data.currentCatalog === null) return;
          if (data.currentManagementLevel === null) return;
          if (data.currentCatalog.levels.length === 0) return;
          const currentLevelCatalog = data.currentCatalog.levels.find(
            (catalogLevel: QuestionCatalogLevel) => {
              return catalogLevel.level.uId === data.currentManagementLevelId;
            },
          );
          if (currentLevelCatalog === undefined) return [];

          currentLevelCatalog.catalogQuestions =
            currentLevelCatalog.catalogQuestions.map(
              (catalogQuestion: CatalogQuestion, index: number) => {
                catalogQuestion.pos = index;
                return catalogQuestion;
              },
            );

          return true;
        }
      }

      function onMoveCallback(evt) {
        const indexOfElement = evt.draggedContext.index;
        const draggedCatalogQuestion: CatalogQuestion =
          computedCurrentConnectedCatalogQuestions.value[indexOfElement];
        if (typeof draggedCatalogQuestion !== 'undefined') {
          if (draggedCatalogQuestion.question.type === QuestionType.PINNED)
            return false;
          const futureIndex = evt.draggedContext.futureIndex;
          if (
            futureIndex === 0 &&
            computedCurrentConnectedCatalogQuestions.value.some(
              (catalogQuestion: CatalogQuestion) => {
                return catalogQuestion.question.type === QuestionType.PINNED;
              },
            )
          )
            return false;
          return true;
        }
        return false;
      }

      const computedIsQuestionCatalogInherited = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          if (data.currentNode === null) return false;
          return questionCatalog.orgUnitId !== data.currentNode.id;
        };
      });

      const computedIsQuestionCatalogDisabled = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          if (data.currentNode === null) return false;
          return questionCatalog.disabledOrgUnitIds.includes(
            data.currentNode.id,
          );
        };
      });

      const computedQuestionCatalogClasses = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          const styleClasses =
            'd-flex justify-content-between align-items-center bg-light border p-4 mb-3';

          if (
            data.currentNode &&
            questionCatalog.disabledOrgUnitIds.includes(data.currentNode.id)
          ) {
            return 'd-flex justify-content-between align-items-center border p-4 mb-3 border-dashed border-danger opacity-75';
          }

          if (computedIsQuestionCatalogInherited.value(questionCatalog)) {
            return styleClasses + ' border-dashed border-primary';
          }

          if (computedQuestionCatalogHasParent.value(questionCatalog)) {
            return styleClasses + ' border-dashed border-info';
          }
          return styleClasses;
        };
      });

      async function handleUserManagementAreaSelect(userId, user: User) {
        if (data.currentNode === null) return;

        store.dispatch(Actions.START_LOADER);

        const addUserManagementAreaDto: AddUserManagementAreaDto = {
          data: {
            user: user,
            orgUnitId: data.currentNode.id,
            type: UserManagementAreaType.MANAGER,
          },
        };

        const savedUserManagementAreaResponse = await ApiService.put(
          'spc/users/area',
          addUserManagementAreaDto,
        );

        data.userManagementAreas.push(savedUserManagementAreaResponse.data);
        data.currentSelectedUsers.push(user);
        store.dispatch(Actions.END_LOADER);
      }

      async function handleUserManagementAreaDeselect(userId, user: User) {
        if (data.currentNode === null) return;

        store.dispatch(Actions.START_LOADER);

        const addUserManagementAreaDto: AddUserManagementAreaDto = {
          data: {
            user: user,
            orgUnitId: data.currentNode.id,
            type: UserManagementAreaType.MANAGER,
          },
        };

        await ApiService.post(
          'spc/users/area/delete',
          addUserManagementAreaDto,
        );

        data.userManagementAreas = data.userManagementAreas.filter(
          (userManagementArea: UserManagementAreaWithUser) => {
            if (data.currentNode === null) return false;

            if (
              userManagementArea.user !== null &&
              userManagementArea.user.id === user.id &&
              data.currentNode.id === userManagementArea.orgUnitId &&
              userManagementArea.type === UserManagementAreaType.MANAGER
            ) {
              return false;
            }

            return true;
          },
        );
        data.userManagementAreasUserIds =
          data.userManagementAreasUserIds.filter((loopedUserId) => {
            return userId !== loopedUserId;
          });

        data.currentSelectedUsers = data.currentSelectableUsers =
          data.currentSelectedUsers.filter((user: User) => {
            return user.id !== userId;
          });
        store.dispatch(Actions.END_LOADER);
      }

      const computedOrgUnitName = computed(() => {
        return (orgUnitId: string) => {
          const foundOrgUnit = computedOrgUnit.value(orgUnitId);
          return foundOrgUnit ? foundOrgUnit.name : '';
        };
      });

      const computedOrgUnit = computed(() => {
        return (orgUnitId: string) => {
          const foundOrgUnit = computedOrgChartData.value.find(
            (orgUnit: OrgChartDataPairWithCatalogs) => {
              return orgUnit.id === orgUnitId;
            },
          );
          return foundOrgUnit;
        };
      });

      const computedIsQuestionCatalogAllowedToEnable = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          // if (questionCatalog.disabledOrgUnitId) return true;
          return (
            computedIsQuestionCatalogInherited.value(questionCatalog) &&
            computedIsQuestionCatalogDisabled.value(questionCatalog)
          );
        };
      });

      const computedIsQuestionCatalogAllowedToDisable = computed(() => {
        return (questionCatalog: QuestionCatalog) => {
          if (questionCatalog.orgUnitId === data.currentNode?.id) return false;
          if (questionCatalog.disabledOrgUnitIds.length === 0) return true;
          return (
            computedIsQuestionCatalogInherited.value(questionCatalog) &&
            !computedIsQuestionCatalogDisabled.value(questionCatalog)
          );
        };
      });

      async function updateWorkerSelect() {
        const response = await ApiService.post('users/find', {
          data: {
            query: '*',
          },
        });

        data.currentSelectableWorkersOriginal = response.data;
        data.currentSelectableWorkers = response.data.map(
          (user: User): Option => {
            return {
              key: user.id,
              label: user.name,
            };
          },
        );
      }

      const computedCurrentQuestionCatalogHasParent = computed(() => {
        if (data.currentCatalog === null) return false;
        return (
          typeof data.currentCatalog.parent !== 'undefined' &&
          data.currentCatalog.parent !== null
        );
      });

      const computedQuestionCatalogParent = computed(() => {
        if (data.currentCatalog === null) return null;
        if (data.currentCatalog.parent === null) return null;
        return data.currentCatalog.parent;
      });

      const computedQuestionCatalogParentLocation = computed(() => {
        if (data.currentCatalog === null) return null;
        if (data.currentCatalog.parent === null) return null;
        const orgUnit = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            return orgUnit.id === data.currentCatalog?.parent?.orgUnitId;
          },
        );
        return orgUnit ? getNodePathSPC(orgUnit, data.orgChartData) : null;
      });

      function initSelectedWorkers() {
        data.currentSelectedWorkers = data.userManagementAreas
          .filter((userManagementArea: UserManagementAreaWithUser) => {
            return userManagementArea.type === UserManagementAreaType.WORKER;
          })
          .filter((userManagementArea: UserManagementAreaWithUser) => {
            if (data.currentNode === null) return false;
            return userManagementArea.orgUnitId === data.currentNode.id;
          })
          .map((userManagementArea: UserManagementAreaWithUser) => {
            return userManagementArea.userId;
          });
      }

      watch(
        () => props.currentNode,
        async () => {
          if (props.currentNode !== null) {
            data.isLoaded = false;
            store.dispatch(Actions.START_LOADER);

            if (data.currentSelectableWorkers.length === 0) {
              await updateWorkerSelect();
            }

            if (data.userManagementAreas.length === 0) {
              await updateUserManagementAreas();
            }

            await updateAvailableOptions();
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;

            data.userManagementAreasUserIds = [];
            data.currentSelectedUsers = [];
            data.currentSelectableUsers = [];

            if (data.currentNode !== null) {
              data.currentSelectedUsers = data.userManagementAreas
                .filter((userManagementArea: UserManagementAreaWithUser) => {
                  return (
                    userManagementArea.type === UserManagementAreaType.MANAGER
                  );
                })
                .filter((userManagementArea: UserManagementAreaWithUser) => {
                  if (data.currentNode === null) return false;
                  return (
                    userManagementArea.orgUnitId === data.currentNode.id &&
                    userManagementArea.user !== null
                  );
                })
                .map((userManagementArea: UserManagementAreaWithUser): User => {
                  return userManagementArea.user;
                });

              data.userManagementAreasUserIds = data.currentSelectedUsers.map(
                (user: User) => {
                  return user.id;
                },
              );

              initSelectedWorkers();

              // data.currentSelectedWorkers = data.userManagementAreas
              //   .filter((userManagementArea: UserManagementAreaWithUser) => {
              //     return (
              //       userManagementArea.type === UserManagementAreaType.WORKER
              //     );
              //   })
              //   .filter((userManagementArea: UserManagementAreaWithUser) => {
              //     if (data.currentNode === null) return false;
              //     return userManagementArea.orgUnitId === data.currentNode.id;
              //   })
              //   .map((userManagementArea: UserManagementAreaWithUser) => {
              //     return userManagementArea.user.id;
              //   });

              // data.currentSelectedWorkers = data.currentSelectedUsers.map(
              //   (user: User) => {
              //     return user.id;
              //   },
              // );
            }

            store.dispatch(Actions.END_LOADER);
            data.isLoaded = true;
          }
        },
      );

      return {
        data,
        handleRemoveAttachment,
        handleAddAttachmentClick,
        handleAttachmentChange,
        computedNodePathAreaTitle,
        computedNodePathLastElement,
        nameValidator,
        openQuestionCatalog,
        openEmptyQuestionCatalog,
        props,
        computedDate,
        computedCurrentQuestionCatalogs,
        computedCatalogDrawerTitle,
        computedHasCurrentCatalogManagmentLevels,
        handleManagementLevelChange,
        editManagementLevel,
        disconnectManagementLevelWithCatalog,
        computedCurrentManagementLevels,
        computedManagementLevelsAll,
        addNewManagementLevel,
        handleManagementLevelUpdate,
        editEntity,
        connectManagementLevelWithCatalog,
        handleQuestionCatalogSubmit,
        addNewQuestion,
        editQuestion,
        handleQuestionUpdate,
        computedQuestionsAll,
        handleDeleteQuestion,
        handleConnectQuestion,
        handleConnectQuestionToAll,
        computedCurrentConnectedCatalogQuestions,
        computedIsAllowedToSave,
        computedHasCurrentCatalogQuestions,
        handleDisconnectQuestion,
        computedQuestionInManagementlevelAmount,
        attachmentInput,
        computedFileNameFromUrl,
        computedFileUrl,
        handleDeleteQuestionCatalog,
        deleteManagementLevel,
        discardEntityEdit,
        handleRankUpQuestion,
        handleRankDownQuestion,
        computedQuestionCatalogQuestionAmount,
        computedRankDownAllowed,
        computedQuestionStyleClasses,
        computedQuestionTypes,
        handleDragEnd,
        onMoveCallback,
        computedIsQuestionCatalogInherited,
        computedIsQuestionCatalogDisabled,
        computedIsQuestionCatalogAllowedToEnable,
        computedIsQuestionCatalogAllowedToDisable,
        computedQuestionCatalogClasses,
        disableQuestionCatalog,
        enableQuestionCatalog,
        computedOrgUnitName,
        copyQuestionCatalog,
        responsibleMultiselect,
        handleUserSearch,
        handleUserManagementAreaSelect,
        handleUserManagementAreaDeselect,
        computedSelectableUsers,
        computedSelectableWorkers,
        handleWorkerChange,
        computedQuestionCatalogHasParent,
        computedCurrentQuestionCatalogHasParent,
        computedQuestionCatalogParent,
        computedQuestionCatalogParentLocation,
        computedOverwrittenCatalogPath,
        openQuestionCatalogParent,
        computedInheritedUserManagementAreas,
        computedUserManagementAreasByType,
        computedInheritedUserManagementAreasManager,
        computedInheritedUserManagementAreasWorker,
      };
    },
  });
</script>

<style scoped>
  .drag-question:not(.no-drag) {
    cursor: move;
  }
</style>
