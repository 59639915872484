<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <div class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6" v-if="computedShowHeader">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ computedTitle }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ data.openPoints.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Group actions-->
            <div
              v-if="data.checkedElements.length"
              class="d-flex justify-content-end align-items-center"
              data-kt-subscription-table-toolbar="selected"
            >
              <div class="fw-bolder me-5">
                <span
                  class="me-2"
                  data-kt-subscription-table-select="selected_count"
                  >{{ data.checkedElements.length }}</span
                >
                {{ $t('selected') }}
              </div>

              <button
                v-if="computedCurrentUserIsAdmin"
                type="button"
                class="btn btn-danger btn-sm me-3"
                @click="deleteSelected"
              >
                {{ $t('remove') }}
              </button>
            </div>
            <router-link
              to="/opl/create"
              class="btn btn-sm btn-primary align-self-center ms-3"
              ><i class="fa fa-plus-square"></i>
              {{ $t('opl.form.new') }}
            </router-link>
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <KTDatatable
            @click:row="handleRowClick"
            v-if="data.isLoaded === true"
            :ignoreLocalStorage="ignoreLocalStorage"
            :table-data="computedOpenPoints"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="computedEnableItemsPerPageDropdown"
            :rows-per-page="computedRowsPerPage"
            :stickyHeader="computedStickyHeader"
            :tableFilterConfig="tableFilterConfig"
          >
            <template v-slot:cell-checkbox="{ row: openPoint }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="openPoint.id"
                  v-model="data.checkedElements"
                />
              </div>
            </template>
            <template v-slot:cell-source="{ row: openPoint }">
              <div v-if="openPoint.source && openPoint.sourceId">
                <router-link
                  v-if="openPoint.source === 'fes'"
                  :to="`/fes/overview/${openPoint.sourceId}`"
                >
                  <span clickable class="badge badge-success"
                    >{{ String(openPoint.source).toUpperCase() }} /
                    {{ openPoint.sourceId }}</span
                  >
                </router-link>
              </div>

              <div v-else>
                <span
                  class="badge badge-light-primary"
                  v-if="openPoint.parent !== null && 0"
                  >{{ $t('opl.form.children.self') }}</span
                >
                <span class="badge badge-primary">{{ $t('direct') }}</span>
              </div>
            </template>
            <template v-slot:cell-module="{ row: openPoint }">
              <span v-if="openPoint.source">
                <span class="badge badge-light-primary">
                  {{ String(openPoint.source).toUpperCase() }}
                </span>
              </span>
              <span v-else>
                <span class="badge badge-light">OPL</span>
              </span>
            </template>
            <template v-slot:cell-faufReference="{ row: openPoint }">
              <span v-if="openPoint.errorCase">
                {{ openPoint.errorCase.detail.faufReference }}
              </span>
              <span v-else class="badge badge-light">{{ $t('unknown') }}</span>
            </template>
            <template v-slot:cell-materialReference="{ row: openPoint }">
              <span v-if="openPoint.errorCase">
                {{ openPoint.errorCase.detail.materialReference }}
              </span>
              <span v-else class="badge badge-light">{{ $t('unknown') }}</span>
            </template>
            <template v-slot:cell-errorCaseNumber="{ row: openPoint }">
              <router-link
                v-if="openPoint.source === 'fes' && openPoint.errorCase"
                :to="`/fes/overview/${openPoint.errorCase.id}`"
              >
                <span clickable class="badge badge-success">
                  {{ openPoint.errorCase.id }}</span
                >
              </router-link>
            </template>
            <template v-slot:cell-barcodes="{ row: openPoint }">
              <div
                v-if="
                  openPoint.errorCase && openPoint.errorCase.barcodes.length > 0
                "
              >
                <span
                  class="badge badge-light"
                  v-for="barcode of openPoint.errorCase.barcodes"
                  :key="barcode"
                  >{{ barcode.code }}</span
                >
              </div>

              <span class="badge badge-light" v-else>{{ $t('none') }}</span>
            </template>
            <template v-slot:cell-title="{ row: openPoint }">
              <span class="d-block">{{ openPoint.title }}</span>

              <span
                v-if="openPoint.isUrgent"
                class="badge badge-light-danger me-3"
                >{{ $t('opl.form.immediate') }}</span
              >
              <span
                v-if="openPoint.isAccidentPrevention"
                class="badge badge-light-dark me-3"
                >{{ $t('opl.form.accidentPrevention') }}</span
              >
            </template>
            <template v-slot:cell-location="{ row: openPoint }">
              <div
                v-if="openPoint.locations.length !== 0"
                :title="openPoint.locationText"
              >
                <span>{{ openPoint.locationName }}</span
                ><br />
                <small>{{ openPoint.locationAreaName }}</small>
              </div>
              <div v-else>
                <span class="badge badge-light">{{ $t('none') }}</span>
              </div>
            </template>
            <template v-slot:cell-creator="{ row: openPoint }">
              <ul class="default-list" v-if="openPoint.creator">
                <li>{{ openPoint.creator.name }}</li>
              </ul>
              <span class="badge badge-light" v-else>{{ $t('noOne') }}</span>
            </template>
            <template v-slot:cell-assignee="{ row: openPoint }">
              <ul class="default-list" v-if="openPoint.assignee">
                <li>{{ openPoint.assignee.name }}</li>
              </ul>
              <div v-else>
                <ul
                  v-if="
                    openPoint.initialAssignees.length > 0 &&
                    computedOpenPointIsNotCompleted(openPoint)
                  "
                  class="default-list"
                >
                  <li
                    v-for="(
                      initialAssignee, index
                    ) of openPoint.initialAssignees"
                    :key="index"
                  >
                    {{ initialAssignee.name }}
                  </li>
                </ul>
                <span class="badge badge-light" v-else>{{ $t('noOne') }}</span>
              </div>
            </template>
            <template v-slot:cell-watchers="{ row: openPoint }">
              <ul class="default-list" v-if="openPoint.watchers.length">
                <li v-for="watcher of openPoint.watchers" :key="watcher.id">
                  {{ watcher.name || watcher.email }}
                </li>
              </ul>
              <span class="badge badge-light" v-else>{{ $t('none') }}</span>
            </template>
            <template v-slot:cell-dueDate="{ row: openPoint }">
              <span v-if="openPoint.dueDate">
                {{ computedDate(openPoint.dueDate) }}</span
              >
              <span class="badge badge-light" v-else>{{ $t('none') }}</span>
            </template>
            <template v-slot:cell-state="{ row: openPoint }">
              <open-point-state-badge :state="openPoint.state" />
            </template>
            <template v-slot:cell-createdAt="{ row: openPoint }">
              {{ computedDate(openPoint.createdAt) }}
            </template>
            <template v-slot:cell-productCategory="{ row: openPoint }">
              <span v-if="openPoint.errorCase?.productCategory?.name">
                {{ openPoint.errorCase.productCategory.name }}
              </span>
            </template>
            <template v-slot:cell-productSubCategory="{ row: openPoint }">
              <span v-if="openPoint.errorCase?.productSubCategory?.name">
                {{ openPoint.errorCase.productSubCategory.name }}
              </span>
            </template>
            <template v-slot:cell-reporterUser="{ row: openPoint }">
              {{ openPoint.errorCase?.reporterUser?.name }}
            </template>
            <template v-slot:cell-actions="{ row: openPoint }">
              <router-link
                :to="`/opl/overview/${openPoint.id}`"
                class="btn btn-icon btn-light btn-sm border-0"
              >
                <span class="svg-icon svg-icon-5 m-0">
                  <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                </span>
              </router-link>
              <!--end::Menu-->
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
  } from 'vue';
  import KTDatatable, {
    IHeaderConfiguration,
    TableFilterItem,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { computedDate, getFullOrgUnitPath } from '@/core/helpers/cdf';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import moment from 'moment';
  import {
    OpenPoint,
    OpenPointFilterConfig,
    OpenPointLocation,
    OpenPointMessage,
    OpenPointState,
  } from '@/core/models/opl';
  import { Actions } from '@/store/enums/StoreEnums';
  import OpenPointStateBadge from '@/components/opl/OpenPointStateBadge.vue';
  import { OrgChartDataPairWithCatalogs } from '@/core/models/fes';
  import { useRouter } from 'vue-router';
  import { User } from '@/store/modules/AuthModule';
  import * as _ from 'lodash';

  export default defineComponent({
    name: 'oplOverview',
    components: {
      KTDatatable,
      OpenPointStateBadge,
    },
    props: {
      app: {
        type: String,
      },
      title: {
        type: String,
      },
      translatedKey: {
        type: String,
        required: false,
      },
      onlyOpen: {
        type: Boolean,
        default: false,
      },
      onlyAllowed: {
        type: Boolean,
        default: false,
      },
      disableHeader: {
        type: Boolean,
        default: false,
      },
      disableCheckbox: {
        type: Boolean,
        default: false,
      },
      disableItemsPerPageDropdown: {
        type: Boolean,
        default: false,
      },
      ignoreLocalStorage: { type: Boolean, default: false },
      rowsPerPage: {
        type: Number,
        default: 10,
      },
      onClick: {
        type: Function,
        default: null,
      },
      filterFunction: {
        type: Function,
        default: null,
      },
      openPoints: {
        type: Array as PropType<OpenPoint[] | null>,
        default: null,
        required: false,
      },
      stickyHeader: { type: Boolean, default: true, required: false },
      cardClasses: { type: String, default: '', required: false },
      errorCaseId: { type: Number, default: null, required: false },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();

      const data: {
        openPoints: OpenPoint[];
        headerConfig: IHeaderConfiguration[];
        isLoaded: boolean;
        checkedElements: number[];
        filterConfig: OpenPointFilterConfig;
        orgChart: OrgChartDataPairWithCatalogs[];
      } = reactive({
        openPoints: props.openPoints || [],
        headerConfig: [
          {
            name: t('opl.overview.module'),
            key: 'module',
            sortable: false,
          } as IHeaderConfiguration,
          {
            name: t('fes.form.details.faufReferenceShort'),
            key: 'faufReference',
            sortable: true,
            sortingField: 'errorCase.detail.faufReference',
          },
          {
            name: t('fes.form.details.materialReference'),
            key: 'materialReference',
            sortable: true,
            sortingField: 'errorCase.detail.materialReference',
          },
          {
            name: t('fes.form.barcodes'),
            key: 'barcodes',
            sortable: false,
          },
          {
            name: t('fes.form.errorArea'),
            key: 'location',
            sortable: false,
          },
          {
            name: t('opl.form.title'),
            key: 'title',
            sortable: true,
          },
          // {
          //   name: t('opl.overview.creator'),
          //   key: 'creator',
          //   sortable: true,
          //   sortingField: 'creator.name',
          // },
          {
            name: t('opl.form.assignees'),
            key: 'assignee',
            sortable: true,
            sortingField: 'assignee.name',
          },
          {
            name: t('opl.overview.state'),
            key: 'state',
            sortable: true,
          },
          {
            name: t('opl.form.dueDate'),
            key: 'dueDate',
            sortable: true,
          },
          {
            name: t('fes.form.moreDetails.productCategoryShort'),
            key: 'productCategory',
            sortable: true,
            sortingField: 'errorCase.productCategory.name',
          },
          {
            name: t('fes.form.moreDetails.productSubCategoryShort'),
            key: 'productSubCategory',
            sortable: true,
            sortingField: 'errorCase.productSubCategory.name',
          },
          {
            name: t('opl.overview.creatorFES'),
            key: 'reporterUser',
            sortable: true,
            sortingField: 'errorCase.reporterUser.name',
          },
          {
            name: t('createdAt'),
            key: 'createdAt',
            sortable: true,
          },
        ],
        isLoaded: false,
        checkedElements: [],
        filterConfig: {
          orgUnitId: null,
        },
        orgChart: [],
      });

      const selectedOpenPointIds = computed(() =>
        props.openPoints?.map((openPoint) => openPoint.id),
      );

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };

        if (Object.keys(currentUser).length === 0) {
          store.dispatch(Actions.REDIRECT_TO_SIGNIN);
          return false;
        }
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  return await ApiService.delete(`/opl/${id}`);
                }),
              );

              const filteredOpenPoints = data.openPoints.filter(
                (openPoint: OpenPoint) => {
                  if (!openPoint.id) return false;
                  return !data.checkedElements.includes(openPoint.id);
                },
              );

              data.openPoints = [];
              data.openPoints = filteredOpenPoints;

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedAttachmentCount = computed(() => {
        return (openPoint: OpenPoint) => {
          return (
            openPoint.attachments.length +
            openPoint.messages
              .map((message: OpenPointMessage) => {
                return message.attachments.length;
              })
              .reduce((a, b) => a + b, 0)
          );
        };
      });

      const computedMessagesLength = computed(() => {
        return (messages: OpenPointMessage[]) => {
          return messages.length;
          // return messages.filter((message: OpenPointMessage) => {
          //   return message.text !== null;
          // }).length;
        };
      });

      const computedOpenPoints = computed(() => {
        return data.openPoints.filter((openPoint: OpenPoint) => {
          const isValidLocation = !data.filterConfig.orgUnitId
            ? true
            : openPoint.locations.some(
                (location) =>
                  location.locationId === data.filterConfig.orgUnitId,
              );
          const isSelected = !selectedOpenPointIds.value?.length
            ? true
            : selectedOpenPointIds.value.includes(openPoint.id);
          return isValidLocation && isSelected;
        });
      });

      function handleRowClick(openPoint: OpenPoint) {
        if (props.onClick) {
          props.onClick(openPoint);
          return;
        }
        router.push(`/opl/overview/${openPoint.id}`);
      }

      if (props.disableCheckbox === false) {
        if (
          computedCurrentUserIsAdmin.value === true &&
          data.headerConfig.find(
            (headerConfig: any) => headerConfig.key === 'checkbox',
          ) === undefined
        ) {
          data.headerConfig.unshift({
            key: 'checkbox',
          });
        }
      }

      const computedShowHeader = computed(() => {
        return props.disableHeader === false;
      });

      const computedTitle = computed(() => {
        return props.title ? props.title : t('viewOPLOverview');
      });

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);
        ApiService.post('/opl', {
          data: {
            onlyAllowed: props.onlyAllowed,
            onlyOpen: props.onlyOpen,
            withErrors: true,
          },
        }).then((response) => {
          data.openPoints = response.data.map((openPoint: OpenPoint) => {
            const parsedRow: any = Object.assign({}, openPoint);
            // const parsedRow: any = Object.assign(
            //   {
            //     activities: {
            //       lastEdit: moment(openPoint.createdAt),
            //       attachments: openPoint.attachments.length,
            //       messages: openPoint.messages.length,
            //     },
            //   },
            //   openPoint,
            // );

            // if (openPoint.messages.length) {
            //   for (const message of openPoint.messages) {
            //     const messageMoment = moment(message.createdAt);

            //     if (messageMoment.isAfter(parsedRow.activities.lastEdit)) {
            //       parsedRow.activities.lastEdit = messageMoment;
            //     }
            //   }
            // }

            if (openPoint.locations.length) {
              openPoint.locations = openPoint.locations.sort((a, b) => {
                return a.pos - b.pos;
              });

              parsedRow.locationText = openPoint.locations
                .map((location: OpenPointLocation) => {
                  return location.locationName;
                })
                .join(' > ');
              const lastLocation =
                openPoint.locations.find((location) => {
                  return location.locationIdentifier === 'Bereich';
                }) || openPoint.locations[openPoint.locations.length - 1];
              parsedRow.locationName = lastLocation.locationName;
              parsedRow.locationAreaName = lastLocation.locationIdentifier;
            }

            return parsedRow;
          });
          data.isLoaded = true;
          store.dispatch(Actions.END_LOADER);
        });
      });

      const computedEnableItemsPerPageDropdown = computed(() => {
        return props.disableItemsPerPageDropdown === false;
      });

      const computedRowsPerPage = computed(() => {
        return props.rowsPerPage;
      });

      const tableFilterConfig = computed((): TableFilterItem[] => {
        return [
          {
            filterKey: 'faufReference',
            filterValue: (openPoint: any) => {
              if (typeof openPoint.errorCase === 'undefined') return '';
              return openPoint.errorCase.detail.faufReference;
            },
            type: 'text',
          },
          {
            filterKey: 'materialReference',
            filterValue: (openPoint: any) => {
              if (typeof openPoint.errorCase === 'undefined') return '';
              return openPoint.errorCase.detail.materialReference;
            },
            type: 'text',
          },
          {
            filterKey: 'productCategory',
            filterValue: (openPoint: any) =>
              openPoint.errorCase?.productCategory?.name,
            type: 'text',
          },
          {
            filterKey: 'productSubCategory',
            filterValue: (openPoint: any) =>
              openPoint.errorCase?.productSubCategory?.name,
            type: 'text',
          },
          {
            filterKey: 'barcodes',
            filterValue: (openPoint: any) => {
              if (typeof openPoint.errorCase === 'undefined') return '';
              return openPoint.errorCase.barcodes
                .map((barcode) => {
                  return barcode.code;
                })
                .join(';');
            },
            type: 'text',
          },
          {
            filterKey: 'title',
            filterValue: (openPoint: OpenPoint) => {
              return openPoint.title;
            },
            type: 'text',
          },
          {
            filterKey: 'location',
            filterValue: (openPoint: any) => {
              return `${openPoint.locationName}/${openPoint.locationAreaName}`;
            },
            type: 'text',
          },
          {
            filterKey: 'creator',
            filterValue: (openPoint: OpenPoint) => {
              return openPoint.creator?.name;
            },
            type: 'text',
          },
          {
            filterKey: 'reporterUser',
            filterValue: (openPoint: any) =>
              openPoint.errorCase?.reporterUser?.name,
            type: 'text',
          },
          {
            filterKey: 'state',
            filterValue: (openPoint: OpenPoint) => {
              return t(`opl.states.${openPoint.state}`);
            },
            values: _.uniq(
              computedOpenPoints.value.map((openPoint) => {
                return t(`opl.states.${openPoint.state}`);
              }),
            ),
            type: 'select',
          },
          {
            filterKey: 'createdAt',
            filterValue: (openPoint: OpenPoint) => {
              return String(
                moment(openPoint.createdAt).format(t('dateFormat')),
              );
            },
            type: 'text',
          },
          {
            filterKey: 'dueDate',
            filterValue: (openPoint: OpenPoint) => {
              return openPoint.dueDate !== null
                ? String(moment(openPoint.dueDate).format(t('dateFormat')))
                : t('none');
            },
            type: 'text',
          },
          {
            filterKey: 'assignee',
            filterValue: (openPoint: OpenPoint) => {
              return openPoint.assignee
                ? openPoint.assignee.name
                : openPoint.initialAssignees
                    .map((assignee) => {
                      return assignee.name;
                    })
                    .join(', ');
            },
            type: 'text',
          },
        ];
      });

      const computedStickyHeader = computed(() => {
        return props.stickyHeader === true;
      });

      const computedOpenPointIsNotCompleted = computed(() => {
        return (openPoint: OpenPoint) => {
          return openPoint.state !== OpenPointState.CLOSED;
        };
      });

      const computedAssignees = computed(() => {
        return (openPoint: OpenPoint) => {
          const assignees: string[] = [];

          if (openPoint.assignee) {
            assignees.push(openPoint.assignee.name);
          } else {
            for (const assignee of openPoint.initialAssignees) {
              assignees.push(assignee.name);
            }
          }

          // unique names
          return [...new Set(assignees)];
        };
      });

      const computedHasAssignee = computed(() => {
        return (openPoint: OpenPoint) => {
          return computedAssignees.value(openPoint).length > 0;
        };
      });

      return {
        data,
        selectedOpenPointIds,
        computedDate,
        deleteSelected,
        computedAttachmentCount,
        computedMessagesLength,
        getFullOrgUnitPath,
        computedOpenPoints,
        handleRowClick,
        computedCurrentUserIsAdmin,
        tableFilterConfig,
        computedTitle,
        computedShowHeader,
        computedEnableItemsPerPageDropdown,
        computedRowsPerPage,
        computedStickyHeader,
        computedAssignees,
        computedHasAssignee,
        computedOpenPointIsNotCompleted,
      };
    },
  });
</script>

<style scoped>
  ul {
    margin-bottom: 0;
  }
</style>
