<template>
  <side-drawer id="spcQuestionDrawer" :title="computedTitle" width="75vw">
    <MyForm
      v-if="data.question"
      @submit="handleQuestionSubmit"
      action="javascript:"
      :validation-schema="nameValidator"
    >
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
          $t('spc.question.title')
        }}</label>

        <div class="col-lg-8 fv-row">
          <Field
            type="text"
            name="name"
            class="form-control form-control-lg form-control-solid"
            placeholder="Name"
            v-model="data.question.name"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
          $t('spc.question.type')
        }}</label>

        <div class="col-lg-8 fv-row">
          <Field
            as="select"
            name="questionType"
            class="form-select form-select-solid form-select-lg"
            v-model="data.question.type"
          >
            <option
              v-for="(type, index) in computedQuestionTypes"
              :key="index"
              :value="type"
            >
              {{ $t(`spc.question.types.${type}`) }}
            </option>
          </Field>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="questionType" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.images')
        }}</label>

        <div class="col-lg-8 fv-row d-flex flex-wrap">
          <div
            class="image-input image-input-outline me-5 mb-5"
            v-for="(attachment, index) of data.question.attachments"
            :key="index"
          >
            <a
              v-if="attachment.type === 'image'"
              :href="attachment.fileName"
              target="_blank"
              class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
              :style="`background-image: url(${computedFileUrl(
                attachment.fileName,
              )})`"
            ></a>
            <a
              v-if="attachment.type === 'document'"
              :href="attachment.fileName"
              target="_blank"
              class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
            >
              <span
                class="svg-icon svg-icon-4x svg-icon-primary"
                :title="computedFileNameFromUrl(attachment.fileName)"
              >
                <inline-svg src="media/icons/duotune/files/fil003.svg" />
              </span>
            </a>
            <a
              href="javascript:"
              @click="handleRemoveAttachment(index, subIndex)"
              data-kt-image-input-action="change"
              class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
              ><i class="bi bi-x fs-2"></i
            ></a>
          </div>
          <div class="image-input image-input-outline me-5 mb-5">
            <button
              type="button"
              class="btn btn-primary me-2 w-100px h-100px d-flex justify-content-center align-items-center"
              @click="handleAddAttachmentClick"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <input
            v-show="0"
            ref="attachmentInput"
            type="file"
            name="images"
            @change="handleAttachmentChange"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.hint')
        }}</label>

        <div class="col-lg-8 fv-row min-h-200px">
          <quill-editor
            v-model:value="data.question.hint"
            :options="data.editorOptions"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.externalLink')
        }}</label>

        <div class="col-lg-8 fv-row">
          <div class="d-flex justify-content-between">
            <Field
              type="text"
              name="externalLink"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('spc.question.externalLink')"
              v-model="data.question.externalLink"
            />
            <button
              type="button"
              class="ms-3 btn btn-primary"
              @click="openExternalLink"
            >
              <i class="fa fa-external-link-alt text-white"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.onlyFromToDates')
        }}</label>

        <div class="col-lg-4">
          <el-date-picker
            v-model="data.question.onlyFrom"
            class="w-100"
            popper-class="date-only-month"
            format="DD.MM."
            value-format="DDMM"
            :placeholder="$t('spc.question.onlyFromToDatesFrom')"
          />
        </div>
        <div class="col-lg-4">
          <el-date-picker
            v-model="data.question.onlyTo"
            class="w-100"
            popper-class="date-only-month"
            format="DD.MM."
            value-format="DDMM"
            :placeholder="$t('spc.question.onlyFromToDatesTo')"
          />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.noneAllowed')
        }}</label>

        <div class="col-lg-8 fv-row">
          <label
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <Field
              type="checkbox"
              class="form-check-input"
              name="noneAllowed"
              :value="true"
              :unchecked-value="false"
              v-model="data.question.noneAllowed"
            />
          </label>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">{{
          $t('spc.question.openPointNeeded')
        }}</label>

        <div class="col-lg-8 fv-row">
          <label
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <Field
              type="checkbox"
              class="form-check-input"
              name="openPointNeeded"
              :value="true"
              :unchecked-value="false"
              v-model="data.question.openPointNeeded"
            />
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col text-end">
          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton"
            class="btn btn-primary"
          >
            <span class="indicator-label"> {{ $t('save') }} </span>
          </button>
        </div>
      </div>
    </MyForm>

    <div class="row" v-if="data.connectedCatalogs.length > 0">
      <div class="col-12">
        <hr class="my-10" />
        <h2>
          {{ $t('spc.questionCatalog.connectedCatalogs') }}
          ({{ data.connectedCatalogs.length }})
        </h2>
        <span class="text-muted">{{ $t('spc.question.editHint') }}</span>
        <table
          class="table table-row-dashed table-row-gray-300 gy-7 cursor-default"
        >
          <thead>
            <tr class="fw-bolder fs-5 text-gray-800">
              <th>{{ $t('spc.questionCatalog.single') }}</th>
              <th>{{ $t('structure') }}</th>
              <th>{{ $t('spc.questionCatalog.managementLevel') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="connectedCatalog in data.connectedCatalogs"
              :key="connectedCatalog.id"
            >
              <td>{{ connectedCatalog.questionCatalog.name }}</td>
              <td>
                {{
                  computedCatalogPath(
                    connectedCatalog.questionCatalog.orgUnitId,
                  )
                }}
              </td>
              <td>
                <span
                  class="badge badge-info me-3"
                  :key="level.id"
                  v-for="level in connectedCatalog.levels"
                  >{{ level.name }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </side-drawer>
</template>
<script lang="ts">
  import {
    ManagementLevel,
    OrgChartDataPairWithCatalogs,
    Question,
    QuestionCatalog,
    QuestionCatalogLevel,
    QuestionType,
  } from '@/core/models/spc';
  import {
    computed,
    defineComponent,
    PropType,
    reactive,
    ref,
    Ref,
    watch,
  } from 'vue';
  import { openSideDrawer } from '@/core/helpers/cdf';
  import { useI18n } from 'vue-i18n';
  import SideDrawer from '../SideDrawer.vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { quillEditor } from 'vue3-quill';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import { getCurrentInstance } from 'vue';
  import * as yup from 'yup';
  import { getPathSPC } from '@/core/helpers/spc';

  type ConnectedCatalog = {
    questionCatalog: QuestionCatalog;
    levels: ManagementLevel[];
  };

  export default defineComponent({
    name: 'questionDrawer',
    components: {
      SideDrawer,
      MyForm: Form,
      Field,
      ErrorMessage,
      quillEditor,
    },
    emits: ['questionUpdate'],
    props: {
      question: {
        type: Object as PropType<Question | null>,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
    },
    setup(props, context) {
      const { t } = useI18n();
      const store = useStore();

      const attachmentInput: Ref<HTMLElement | null> = ref(null);

      const data = reactive({
        question: props.question,
        connectedCatalogs: [] as ConnectedCatalog[],
        editorOptions: {
          placeholder: '',
          modules: {
            toolbar: [['bold', 'italic', 'underline']],
          },
        },
      });

      async function editQuestion() {
        const instance = getCurrentInstance();
        if (instance?.parent) {
          openSideDrawer('spcQuestionDrawer');
        }
      }

      async function handleQuestionSubmit() {
        store.dispatch(Actions.START_LOADER);

        const questionToSave = Object.assign({}, data.question);
        questionToSave.hint =
          questionToSave.hint !== null && questionToSave.hint.length === 0
            ? null
            : questionToSave.hint;

        const questionUpdateResponse = await ApiService.post(
          'spc/config/question',
          {
            data: questionToSave,
          },
        );
        data.question = questionUpdateResponse.data as Question;
        context.emit('questionUpdate', data.question);
        store.dispatch(Actions.END_LOADER);
      }

      function handleRemoveAttachment(index: number) {
        store.dispatch(Actions.START_LOADER);

        const deleteString = `spc/attachment/${data.question?.attachments[index].id}`;

        ApiService.delete(deleteString)
          .then(() => {
            data.question?.attachments.splice(index, 1);
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      function handleAddAttachmentClick() {
        if (attachmentInput.value) {
          attachmentInput.value.click();
        }
      }

      function handleAttachmentChange(event) {
        const file: File = event.target.files[0];

        if (!file) {
          return false;
        }

        store.dispatch(Actions.START_LOADER);

        const formData = new FormData();
        formData.append('attachment', file);

        axios
          .post('spc/attachment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.data === '')
              return Swal.fire(t('error'), t('errorAlertCaption'), 'error');

            data.question?.attachments.push({
              id: response.data.id,
              fileName:
                ApiService.vueInstance.axios.defaults.baseURL +
                '/spc/attachment/' +
                response.data.fileName,
              type: response.data.type,
            });
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedTitle = computed(() => {
        return data.question && typeof data.question.uId !== 'undefined'
          ? 'Frage bearbeiten'
          : 'Neue Frage';
      });

      const computedFileNameFromUrl = computed(() => {
        return (fileUrl) => {
          return fileUrl.split('/').pop();
        };
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          const cleanedFileName = fileName.split('spc/attachment/').pop();
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/attachment/' +
            cleanedFileName
          );
        };
      });

      const computedQuestionTypes = computed(() => {
        return Object.values(QuestionType);
      });

      function openExternalLink() {
        if (data.question === null) return;

        try {
          const externalLink = data.question.externalLink;
          new URL(externalLink);
          window.open(externalLink, '_blank');
        } catch (e) {
          return;
        }
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
        questionType: yup.string().required().label('Typ'),
      });

      const loadConnectedCatalogs = async () => {
        if (data.question === null) return;

        const questionCatalogsResponse = await ApiService.get(
          'spc/config/question/catalogs/' + data.question.id,
        );

        data.connectedCatalogs = [];

        questionCatalogsResponse.data.forEach(
          (questionCatalogLevel: QuestionCatalogLevel) => {
            if (typeof questionCatalogLevel.questionCatalog === 'undefined')
              return;
            const alreadyExistentCatalogInList = data.connectedCatalogs.find(
              (loopedConnectedCatalog: ConnectedCatalog) => {
                return (
                  loopedConnectedCatalog.questionCatalog.id ===
                  questionCatalogLevel.questionCatalog?.id
                );
              },
            );
            if (alreadyExistentCatalogInList) {
              alreadyExistentCatalogInList.levels.push(
                questionCatalogLevel.level,
              );
            } else {
              const connectedCatalog = {
                questionCatalog: questionCatalogLevel.questionCatalog,
                levels: [questionCatalogLevel.level],
              } as ConnectedCatalog;
              data.connectedCatalogs.push(connectedCatalog);
            }
          },
        );

        data.connectedCatalogs = data.connectedCatalogs.map(
          (connectedCatalog: ConnectedCatalog) => {
            connectedCatalog.levels = connectedCatalog.levels.sort(
              (mgmtLvlA: ManagementLevel, mgmtLvlB: ManagementLevel) => {
                const mgmtLvlAint =
                  typeof mgmtLvlA.id !== 'undefined' ? mgmtLvlA.id : 0;
                const mgmtLvlBint =
                  typeof mgmtLvlB.id !== 'undefined' ? mgmtLvlB.id : 0;
                if (mgmtLvlAint < mgmtLvlBint) {
                  return -1;
                }
                if (mgmtLvlAint > mgmtLvlBint) {
                  return 1;
                }
                return 0;
              },
            );
            return connectedCatalog;
          },
        );

        // data.connectedCatalogs = questionCatalogsResponse.data.map(
        //   (questionCatalogLevel: QuestionCatalogLevel) => {
        //     return {};
        //   },
        // ) as ConnectedCatalog[];
      };

      const computedCatalogPath = computed(() => {
        return (orgUnitId) => {
          const orgUnit = props.orgChartData.find(
            (orgUnit) => orgUnit.id === orgUnitId,
          );
          if (orgUnit) {
            return getPathSPC(orgUnit, props.orgChartData);
          }
          return '?';
        };
      });

      watch(
        () => props.question,
        async () => {
          if (props.question !== null) {
            data.question = null;
            data.question = Object.assign({}, props.question) as Question;

            data.connectedCatalogs = [];
            loadConnectedCatalogs();
          }
        },
      );

      return {
        data,
        editQuestion,
        computedTitle,
        computedFileUrl,
        handleQuestionSubmit,
        computedFileNameFromUrl,
        handleRemoveAttachment,
        handleAddAttachmentClick,
        handleAttachmentChange,
        attachmentInput,
        nameValidator,
        computedQuestionTypes,
        openExternalLink,
        computedCatalogPath,
      };
    },
  });
</script>

<style scoped>
  .ql-container.ql-snow {
    height: 75%;
  }
</style>
