import store from '@/store';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { Mutations, Actions } from '@/store/enums/StoreEnums';

import AuthLayout from '@/components/page-layouts/Auth.vue';

import SignIn from '@/views/auth/SignIn.vue';

import Error404 from '@/views/Error404.vue';
import Error500 from '@/views/Error500.vue';

import cdfRouter from './cdf';
import spcRouter from './spc';
import fesRouter from './fes';
import oplRouter from './opl';
import userRouter from './user';

import { reactive } from 'vue';
import Swal from 'sweetalert2';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'start',
    path: '/',
    redirect: '/fes',
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: SignIn,
      },
    ],
  },
  cdfRouter,
  fesRouter,
  spcRouter,
  oplRouter,
  userRouter,
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: Error404,
  },
  {
    path: '/500',
    name: '500',
    component: Error500,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const isAuthenticated = await store.dispatch(Actions.VERIFY_AUTH);
  if (isAuthenticated === false && to.name !== 'sign-in') {
    store.dispatch(Actions.REDIRECT_TO_SIGNIN);
    return false;
  }

  const currentUser = reactive({ ...store.getters.currentUser });

  if (
    typeof currentUser.groups !== 'undefined' &&
    !currentUser.groups.includes('Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local')
  ) {
    try {
      let showError = false;
      if (typeof to.meta.requiredGroup !== 'undefined') {
        const isAllowed = currentUser.groups.includes(to.meta.requiredGroup);
        if (!isAllowed) showError = true;
      }
      if (typeof to.meta.allowedGroups !== 'undefined') {
        const allowedGroups = to.meta.allowedGroups as string[];
        const isAllowed = allowedGroups.some((group) =>
          currentUser.groups.includes(group),
        );
        if (!isAllowed) showError = true;
      }
      if (typeof to.meta.allowedHosts !== 'undefined') {
        const allowedHosts = to.meta.allowedHosts as string[];
        const isAllowed = allowedHosts.some((host) =>
          window.location.host.includes(host),
        );
        if (!isAllowed) showError = true;
      }
      if (showError === true) {
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_MEASURE_local',
          )
        ) {
          return router.push('/opl');
        }
        throw new Error('User is not allowed.');
      }
    } catch (e) {
      console.error('Error after Login', e);
      Swal.fire({
        text: 'Unauthorized',
        icon: 'error',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-danger',
        },
      });
      router.push('/');
      return false;
    }
  }

  store.commit(Mutations.SET_APP, to.meta.app);

  // Scroll page to top on every route change
  setTimeout(() => {
    if (to.name !== 'sign-in') {
      const loadedRedirectUrl = window.localStorage.getItem('redirectUrl');
      if (loadedRedirectUrl !== null) {
        window.localStorage.removeItem('redirectUrl');
        window.location.hash = loadedRedirectUrl;
      }
    }
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
