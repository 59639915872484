import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fes-config-container opl" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 mb-3" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = {
  class: "card-header border-0",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#orgChartForm",
  "aria-expanded": "true",
  "aria-controls": "orgChartForm"
}
const _hoisted_7 = { class: "card-title align-items-center justify-content-start" }
const _hoisted_8 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_9 = {
  id: "orgChartForm",
  class: "collapse show"
}
const _hoisted_10 = { class: "card-body py-3" }
const _hoisted_11 = { class: "row mb-3" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_hierarchy_names = _resolveComponent("config-hierarchy-names")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_config_hierarchy_names, {
          hierarchyNames: _ctx.data.hierarchyNames,
          moduleName: "opl",
          onUpdated: _ctx.updateOrgChartData
        }, null, 8, ["hierarchyNames", "onUpdated"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('viewOrgChart')) + " OPL", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary me-2",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.data.orgChart.expandAll && _ctx.data.orgChart.expandAll(...args)))
                  }, _toDisplayString(_ctx.$t('cdf.orgChart.buttons.openAllNodes')), 1),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary me-2",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.data.orgChart.collapseAll && _ctx.data.orgChart.collapseAll(...args)))
                  }, _toDisplayString(_ctx.$t('cdf.orgChart.buttons.closeAllNodes')), 1)
                ])
              ]),
              (_openBlock(), _createElementBlock("div", {
                id: "oplChart",
                key: _ctx.data.orgChartTmpId
              }))
            ])
          ])
        ])
      ])
    ])
  ]))
}