<template>
  <div :class="props.class">
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestCauseCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritCauseCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestCauseCatalogPath" />
      </div>
    </div>
    <div class="row" v-if="!data.currentNode || !data.currentNode.causeCatalog">
      <a
        class="col d-flex justify-content-start align-items-center"
        @click="openCauseCatalog"
        :title="$t('fes.config.linkButtonNew')"
        href="javascript:"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-3"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualCauseCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualCauseCatalog') }}
          </h4>
          <span>
            {{
              $t('fes.config.selectedIndividualCauseCatalog', {
                id: data.currentNode.causeCatalog.id,
                date: computedDate(data.currentNode.causeCatalog.createdAt),
                amount: data.currentNode.causeCatalog.causes.length,
              })
            }}</span
          >
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openCauseCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteCauseCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerCauseCatalog"
      :title="$t('fes.config.causeCatalog')"
      width="60vw"
    >
      <org-unit-path :nodePath="props.nodePath" :areaNames="props.areaNames" />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestCauseCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritCauseCatalog') }}
          </h4>
          <cdf-path :node-path="computedNearestCauseCatalogPath" />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestCauseCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h4>
        {{ $t('fes.config.availableCausesInCatalog') }}
        {{ computedNodePathLastElement }}
      </h4>
      <p v-if="!computedCatalogHasCauses" class="my-5">
        {{ $t('fes.config.noCauseSelected') }}
      </p>

      <div v-if="computedCatalogHasCauses">
        <div
          class="row d-flex mb-2 align-items-center"
          v-for="cause of computedCurrentCatalogCauses"
          :key="cause.id"
        >
          <div class="col">{{ cause.name }}</div>
          <div class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="disconnectCauseFromCatalog(cause)"
              :title="$t('fes.config.disconnect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-minus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <hr class="my-10" />
      <h4>{{ $t('fes.config.allCauses') }}</h4>
      <div v-if="computedCauseSelects.length" class="mb-4">
        <MyForm
          class="row d-flex mb-2 align-items-center"
          v-for="(cause, index) of computedCauseSelects"
          :key="index"
          @submit="handleCauseUpdate(cause)"
          :validation-schema="nameValidator"
        >
          <div class="col">
            <span v-if="cause.edit === false">{{ cause.name }}</span>
            <div v-else>
              <Field
                type="text"
                class="form-control form-control-sm form-control-solid"
                name="name"
                v-model="cause.name"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="cause.edit === false" class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="connectCauseWithCatalog(cause)"
              :title="$t('fes.config.connect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-plus"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="editCause(cause)"
              :title="$t('edit')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-edit"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="deleteCause(cause)"
              :title="$t('remove')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-trash"></i>
              </span>
            </a>
          </div>
          <div v-else class="col text-end">
            <button
              type="submit"
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              :title="$t('save')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-save"></i>
              </span>
            </button>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="discardCauseEdit(cause)"
              :title="$t('cancel')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-times"></i>
              </span>
            </a>
          </div>
        </MyForm>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-secondary"
            @click="addNewCause"
            :disabled="computedIsNewFormOpen"
          >
            <i class="fa fa-plus"></i>
            {{ $t('fes.config.addCause') }}
          </button>
        </div>
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from '../fes/OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ErrorCaseCause,
    CauseCatalog,
    ErrorCaseCauseWithForm,
  } from '@/core/models/fes';

  export default defineComponent({
    name: 'causeCatalogDrawer',
    props: {
      class: {
        type: String,
        required: false,
      },
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      // SimpleModal,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    computed: {
      // computedTitle() {
      //   return this.title;
      // },
      // computedWidth() {
      //   return this.width || '700px';
      // },
      // computedId() {
      //   return this.id;
      // },
      // computedPath() {
      //   return this.path || '';
      // },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs;
        causes: ErrorCaseCauseWithForm[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        causes: [] as ErrorCaseCauseWithForm[],
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const computedCatalogHasCauses = computed(() => {
        return (
          data.currentNode !== null &&
          data.currentNode.causeCatalog !== null &&
          typeof data.currentNode.causeCatalog.causes !== 'undefined' &&
          data.currentNode.causeCatalog.causes.length !== 0
        );
      });

      const computedCauseSelects = computed(() => {
        if (
          data.currentNode !== null &&
          data.currentNode.causeCatalog !== null &&
          typeof data.currentNode.causeCatalog.causes !== 'undefined'
        ) {
          return data.causes.filter((cause: ErrorCaseCauseWithForm) => {
            const isSelected = data.currentNode.causeCatalog?.causes.find(
              (item) => {
                return item.id === cause.id;
              },
            );
            if (isSelected) return false;
            return true;
          });
        }
        return data.causes;
      });

      const openCauseCatalog = () => {
        openSideDrawer('fesConfigDrawerCauseCatalog');
      };

      function connectCauseWithCatalog(cause: ErrorCaseCause) {
        const newCause: ErrorCaseCause = Object.assign({}, cause);
        if (data.currentNode.causeCatalog === null) {
          data.currentNode.causeCatalog = {
            orgUnitId: data.currentNode.id,
            causes: [] as ErrorCaseCause[],
          } as CauseCatalog;
        }
        data.currentNode.causeCatalog.causes.push(newCause);
        updateCurrentCauseCatalog();
      }

      function editCause(cause: ErrorCaseCauseWithForm) {
        cause.edit = true;
        cause.name = cause.nameBefore;
      }

      function discardCauseEdit(cause: ErrorCaseCauseWithForm) {
        if (!cause.id) return data.causes.pop();
        cause.edit = false;
        cause.name = cause.nameBefore;
      }

      async function deleteCause(cause: ErrorCaseCause) {
        const causesInTree = data.orgChartData
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.causeCatalog === null) return false;
            const catalog = orgUnit.causeCatalog;
            return catalog.causes.find((item) => item.id === cause.id);
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (causesInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: causesInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            causesInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteCauseQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        // const decisionId = decision.id;

        await ApiService.delete(`fes/config/cause/${cause.id}`);

        data.causes = data.causes.filter((loopedErrorCause: ErrorCaseCause) => {
          return loopedErrorCause.id !== cause.id;
        });

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.causeCatalog !== null &&
                typeof orgUnit.causeCatalog.causes !== 'undefined'
              ) {
                const filteredCauses = orgUnit.causeCatalog.causes.filter(
                  (loopedErrorCause: ErrorCaseCause) =>
                    loopedErrorCause.id !== cause.id,
                );

                if (filteredCauses.length === 0 && orgUnit.causeCatalog.id) {
                  await deleteCauseCatalog(orgUnit.causeCatalog.id);
                  orgUnit.causeCatalog = null;
                  return orgUnit;
                }
                orgUnit.causeCatalog.causes = filteredCauses;
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteCauseCatalog(id = 0) {
        if (data.currentNode.causeCatalog === null) return;
        store.dispatch(Actions.START_LOADER);
        const causeCatalogId = id !== 0 ? id : data.currentNode.causeCatalog.id;

        await ApiService.delete(`fes/config/catalog/cause/${causeCatalogId}`);

        data.currentNode.causeCatalog = null;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteCauseCatalog(id = 0) {
        if (data.currentNode.causeCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteCauseCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteCauseCatalog(id);
      }

      async function disconnectCauseFromCatalog(cause: ErrorCaseCause) {
        if (data.currentNode.causeCatalog !== null) {
          const filteredCauses = data.currentNode.causeCatalog?.causes.filter(
            (loopedErrorCause: ErrorCaseCause) =>
              loopedErrorCause.id !== cause.id,
          );

          if (filteredCauses.length === 0) {
            await deleteCauseCatalog();
          }
          data.currentNode.causeCatalog.causes = filteredCauses;
          updateCurrentCauseCatalog();
        }
      }

      function addNewCause() {
        const newCause: ErrorCaseCauseWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
        };
        data.causes.push(newCause);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function updateCurrentCauseCatalog() {
        if (
          data.currentNode.causeCatalog !== null &&
          data.currentNode.causeCatalog?.causes.length === 0
        ) {
          return Swal.fire('Error', 'Please add at least one cause.', 'error');
        }

        store.dispatch(Actions.START_LOADER);

        const causeCatalog: CauseCatalog = Object.assign(
          {},
          data.currentNode.causeCatalog,
        );

        const newSavedCauseCatalog = await ApiService.post(
          'fes/config/catalog/cause',
          {
            data: causeCatalog,
          },
        );

        data.currentNode.causeCatalog = newSavedCauseCatalog.data;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedCurrentCatalogCauses = computed(() => {
        if (data.currentNode.causeCatalog === null) return [];
        return data.currentNode.causeCatalog.causes;
      });

      async function updateAvailableCauses() {
        data.causes = (await (
          await ApiService.get('fes/config/causes')
        ).data.map((cause: ErrorCaseCause) => {
          const causeObject = Object.assign(
            {
              edit: false,
              nameBefore: cause.name,
            } as ErrorCaseCauseWithForm,
            cause,
          );
          return causeObject;
        })) as ErrorCaseCauseWithForm[];
      }

      async function handleCauseUpdate(cause: ErrorCaseCauseWithForm) {
        store.dispatch(Actions.START_LOADER);
        cause.edit = false;
        cause.nameBefore = cause.name;

        const causeUpdateObject: ErrorCaseCause = {
          name: cause.name,
        };

        if (cause.id) {
          causeUpdateObject.id = cause.id;
        }

        const savedCause: AxiosResponse<ErrorCaseCause> = await ApiService.post(
          'fes/config/cause',
          {
            data: causeUpdateObject,
          },
        );

        if (!cause.id) {
          cause.id = savedCause.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestCauseCatalogPath = computed(() => {
        if (computedNearestCauseCatalog.value === false) return '';
        const currentNearestCauseCatalog: CauseCatalog =
          computedNearestCauseCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.causeCatalog === null) return false;
            return orgUnit.causeCatalog?.id === currentNearestCauseCatalog.id;
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedNearestCauseCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentNode.causeCatalog !== null) return false;

        const nearestCauseCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'causeCatalog',
        );

        if (nearestCauseCatalog) {
          return nearestCauseCatalog;
        }

        return false;
      });

      const computedIsNewFormOpen = computed(() => {
        return (
          computedCauseSelects.value.filter((cause: ErrorCaseCauseWithForm) => {
            return cause.edit === true;
          }).length > 0
        );
      });

      async function copyNearestCauseCatalog() {
        const currentNearestCauseCatalog: CauseCatalog =
          computedNearestCauseCatalog.value;
        const causeCatalogCopy: CauseCatalog = {
          orgUnitId: data.currentNode.id,
          causes: currentNearestCauseCatalog.causes,
        } as CauseCatalog;
        data.currentNode.causeCatalog = causeCatalogCopy;
        await updateCurrentCauseCatalog();
      }

      watch(
        () => props.currentNode,
        () => {
          if (props.currentNode !== null) {
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;
            updateAvailableCauses();
          }
        },
      );

      return {
        data,
        computedNodePathLastElement,
        computedCatalogHasCauses,
        computedCauseSelects,
        computedCurrentCatalogCauses,
        connectCauseWithCatalog,
        disconnectCauseFromCatalog,
        addNewCause,
        // handleDecisionSubmit,
        nameValidator,
        editCause,
        discardCauseEdit,
        deleteCause,
        handleDeleteCauseCatalog,
        openCauseCatalog,
        props,
        computedDate,
        handleCauseUpdate,
        computedNearestCauseCatalog,
        computedNearestCauseCatalogPath,
        copyNearestCauseCatalog,
        computedIsNewFormOpen,
      };
    },
  });
</script>
