<template>
  <!--begin::Dashboard-->
  <div v-if="data.isLoaded" class="row">
    <div class="col">
      <div v-if="computedIsMissingUserConfig">
        <div
          v-if="computedIsMissingManagementLevel"
          class="alert alert-danger d-flex align-items-center p-5 mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
            <inline-svg src="media/icons/duotune/general/gen044.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-danger">
              {{ $t('errors.missingManagementLevel.title') }}
            </h4>
            <span>{{ $t('errors.missingManagementLevel.text') }}</span>
          </div>
        </div>

        <div
          v-if="computedIsMissingManagementArea"
          class="alert alert-danger d-flex align-items-center p-5 mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
            <inline-svg src="media/icons/duotune/general/gen044.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-danger">
              {{ $t('errors.missingManagementArea.title') }}
            </h4>
            <span>{{ $t('errors.missingManagementArea.text') }}</span>
          </div>
        </div>
      </div>

      <div v-else class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ computedTitle }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ data.processConfirmations.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Group actions-->

            <div
              v-if="data.checkedElements.length"
              class="d-flex justify-content-end align-items-center"
              data-kt-subscription-table-toolbar="selected"
            >
              <div class="fw-bolder me-5">
                <span
                  class="me-2"
                  data-kt-subscription-table-select="selected_count"
                  >{{ data.checkedElements.length }}</span
                >
                {{ $t('selected') }}
              </div>

              <button
                v-if="computedCurrentUserIsAdmin"
                type="button"
                class="btn btn-danger btn-sm"
                @click="deleteSelected"
              >
                {{ $t('remove') }}
              </button>
            </div>
            <router-link
              to="/spc/audit/create"
              class="btn btn-sm btn-primary align-self-center ms-md-3 order-md-2 order-1 mb-3 mb-md-0"
              ><i class="fa fa-plus-square"></i>
              {{ $t('spc.processconfirmation.newAudit') }}</router-link
            >
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <KTDatatable
            @click:row="handleRowClick"
            :key="data.tableUpdateCounter"
            v-if="data.isLoaded === true"
            :table-data="computedProcessConfirmations"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="true"
            :emptyTableText="$t('noEntries')"
            order="asc"
            sortLabel="unixCreatedAt"
          >
            <template v-slot:filters>
              <el-radio-group
                v-if="computedHasMultipleTypes"
                class="ms-md-3 order-md-1 order-2 mb-3 mb-md-0"
                v-model="data.filteredType"
                size="small"
                @change="handleFilterTypeChange"
              >
                <el-radio-button
                  :label="$t('spc.processconfirmation.filterAll')"
                  selected
                />
                <el-radio-button
                  class="pcCheckbox"
                  :label="$t('spc.processconfirmation.onlySingle')"
                />
                <el-radio-button
                  class="auditCheckbox"
                  :label="$t('spc.processconfirmation.onlyAudit')"
                />
              </el-radio-group>
            </template>
            <template v-slot:cell-checkbox="{ row: processConfirmation }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="processConfirmation.id"
                  v-model="data.checkedElements"
                />
              </div>
            </template>
            <template v-slot:cell-name="{ row: processConfirmation }">
              <div class="d-flex align-items-center">
                <span
                  v-if="processConfirmation.type === 'audit'"
                  class="badge badge-primary badge-circle w-10px h-10px me-2"
                ></span>
                <span
                  v-if="processConfirmation.type === 'process_confirmation'"
                  class="badge badge-info badge-circle w-10px h-10px me-2"
                ></span>
                <span class="d-block">{{ processConfirmation.name }}</span>
              </div>
            </template>
            <template v-slot:cell-level="{ row: processConfirmation }">
              <span class="d-block">{{ processConfirmation.level.name }}</span>
            </template>
            <template v-slot:cell-assignee="{ row: processConfirmation }">
              <ul class="default-list" v-if="processConfirmation.assignee">
                <li>{{ processConfirmation.assignee.name }}</li>
              </ul>
              <span class="badge badge-light" v-else>{{ $t('noOne') }}</span>
            </template>
            <template v-slot:cell-location="{ row: processConfirmation }">
              <div
                v-if="processConfirmation.locations.length !== 0"
                :title="processConfirmation.locationText"
              >
                <span>{{ processConfirmation.locationName }}</span
                ><br />
                <small>{{ processConfirmation.locationAreaName }}</small>
              </div>
              <div v-else>
                <span class="badge badge-light">{{ $t('none') }}</span>
              </div>
            </template>
            <template v-slot:cell-catalog="{ row: processConfirmation }">
              <div
                class="d-flex align-items-center"
                v-if="processConfirmation.questionCatalog !== null"
              >
                <span
                  class="svg-icon svg-icon-2hx svg-icon-primary me-4"
                  v-if="!processConfirmation.questionCatalog.image"
                >
                  <inline-svg src="media/icons/duotune/general/gen046.svg" />
                </span>
                <img
                  v-else
                  class="mw-30px mh-30px me-4"
                  :src="
                    computedFileUrl(
                      processConfirmation.questionCatalog.image.fileName,
                    )
                  "
                />
                <span>{{ processConfirmation.questionCatalog.name }}</span>
              </div>
              <div v-else>
                <span class="badge badge-light">{{ $t('none') }}</span>
              </div>
            </template>
            <template v-slot:cell-state="{ row: processConfirmation }">
              <process-confirmation-state-badge
                :state="processConfirmation.state"
              />
            </template>
            <template v-slot:cell-progress="{ row: processConfirmation }">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="`${computedPercentageWithNo(
                  processConfirmation,
                )} % Nein`"
                placement="top"
              >
                <div>
                  <el-progress :percentage="processConfirmation.progress">
                    <span
                      >{{ processConfirmation.answeredQuestions }} /
                      {{ processConfirmation.totalQuestions }}</span
                    >
                  </el-progress>
                  <el-progress
                    :stroke-width="4"
                    :percentage="computedPercentageWithNo(processConfirmation)"
                    color="#f1416c"
                  >
                    <span class="fs-8"
                      >{{
                        computedPercentageWithNo(processConfirmation)
                      }}
                      %</span
                    >
                  </el-progress>
                </div>
              </el-tooltip>

              <span
                v-if="0 && computedShowNoBadge(processConfirmation)"
                class="badge badge-light-danger"
                :style="`opacity: ${computedOpacityNoBadge(
                  processConfirmation,
                )}`"
                :title="`${computedPercentageWithNo(
                  processConfirmation,
                )} % Nein`"
                >{{ computedPercentageWithNo(processConfirmation) }} %
                Nein</span
              >
            </template>
            <template v-slot:cell-createdAt="{ row: openPoint }">
              {{ computedDate(openPoint.createdAt) }}
            </template>
            <template v-slot:cell-actions="{ row: openPoint }">
              <router-link
                :to="`/opl/overview/${openPoint.id}`"
                class="btn btn-icon btn-light btn-sm border-0"
              >
                <span class="svg-icon svg-icon-5 m-0">
                  <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                </span>
              </router-link>
              <!--end::Menu-->
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import KTDatatable, {
    IHeaderConfiguration,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { computedDate } from '@/core/helpers/cdf';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { Actions } from '@/store/enums/StoreEnums';
  import ProcessConfirmationStateBadge from '../../components/spc/ProcessConfirmationStateBadge.vue';
  import { getFullOrgUnitPath } from '@/core/helpers/cdf';
  import { OrgChartDataPairWithCatalogs } from '@/core/models/fes';
  import { useRouter } from 'vue-router';
  import { User } from '@/store/modules/AuthModule';
  import {
    ProcessConfirmation,
    ProcessConfirmationLocation,
    ProcessConfirmationState,
    ProcessConfirmationType,
    QuestionAnswer,
    QuestionAnswerValue,
    UserManagementAreaWithUser,
  } from '@/core/models/spc';

  type ProcessConfirmationView = ProcessConfirmation & {
    progress: number;
    answeredQuestions: number;
    totalQuestions: number;
    locationText: string;
    locationName: string;
    locationAreaName: string;
  };

  type ProcessConfirmationViewConfig = {
    onlyOpen: boolean;
  };

  export default defineComponent({
    name: 'spcProcessConfirmationOverview',
    components: {
      KTDatatable,
      ProcessConfirmationStateBadge,
    },
    props: {
      app: {
        type: String,
      },
      title: {
        type: String,
      },
      onlyOpen: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();
      const router = useRouter();

      const data: {
        processConfirmations: ProcessConfirmationView[];
        headerConfig: IHeaderConfiguration[];
        isLoaded: boolean;
        checkedElements: number[];
        orgChart: OrgChartDataPairWithCatalogs[];
        filteredType: string;
        tableUpdateCounter: number;
        userManagementAreas: UserManagementAreaWithUser[];
      } = reactive({
        processConfirmations: [] as ProcessConfirmationView[],
        headerConfig: [
          {
            name: t('spc.processconfirmation.name') as string,
            key: 'name',
            sortable: true,
          } as IHeaderConfiguration,
          {
            name: t('spc.processconfirmation.managementLevel') as string,
            key: 'level',
            sortable: false,
          } as IHeaderConfiguration,
          {
            name: t('spc.questionCatalog.single') as string,
            key: 'catalog',
            sortable: false,
          } as IHeaderConfiguration,
          {
            name: t('spc.processconfirmation.location') as string,
            key: 'location',
            sortable: false,
          } as IHeaderConfiguration,
          {
            name: t('spc.processconfirmation.state') as string,
            key: 'state',
            sortable: true,
          } as IHeaderConfiguration,
          {
            name: t('spc.processconfirmation.progress') as string,
            key: 'progress',
            sortable: true,
            sortingField: 'progress',
          } as IHeaderConfiguration,
          {
            name: t('opl.form.assignee') as string,
            key: 'assignee',
            sortable: true,
            sortingField: 'assignee.name',
          } as IHeaderConfiguration,
          {
            name: t('createdAt') as string,
            key: 'createdAt',
            sortable: true,
            sortingField: 'unixCreatedAt',
          } as IHeaderConfiguration,
        ],
        isLoaded: false,
        checkedElements: [],
        orgChart: [],
        filteredType: t('spc.processconfirmation.filterAll'),
        tableUpdateCounter: 0,
        userManagementAreas: [] as UserManagementAreaWithUser[],
      });

      const computedHasMultipleTypes = computed(() => {
        const hasProcessConfirmations = data.processConfirmations.some(
          (processConfirmation: ProcessConfirmation) => {
            return (
              processConfirmation.type ===
              ProcessConfirmationType.PROCESS_CONFIRMATION
            );
          },
        );
        const hasAudits = data.processConfirmations.some(
          (processConfirmation: ProcessConfirmation) => {
            return processConfirmation.type === ProcessConfirmationType.AUDIT;
          },
        );
        return hasProcessConfirmations && hasAudits;
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/questioncatalog/image/' +
            fileName
          );
        };
      });

      const computedOpacityNoBadge = computed(() => {
        return (processConfirmation: ProcessConfirmation) => {
          const percentage =
            computedPercentageWithNo.value(processConfirmation) / 100;
          return percentage > 0.3 ? percentage : 0.3;
        };
      });

      const computedShowNoBadge = computed(() => {
        return (processConfirmation: ProcessConfirmation) => {
          return (
            processConfirmation.state === ProcessConfirmationState.DONE &&
            computedPercentageWithNo.value(processConfirmation) > 0
          );
        };
      });

      const computedProgressColors = computed(() => {
        return (processConfirmation: ProcessConfirmation) => {
          const yesPercentage =
            100 - computedPercentageWithNo.value(processConfirmation);
          return [
            {
              color: '#f1416c',
              percentage: computedPercentageWithNo.value(processConfirmation),
            },
            {
              color: '#50CD89',
              percentage: yesPercentage,
            },
          ];
        };
      });

      const computedPercentageWithNo = computed(() => {
        return (processConfirmation: ProcessConfirmation) => {
          const noAnswers = processConfirmation.answers.filter(
            (questionAnswer: QuestionAnswer) => {
              return questionAnswer.answer === QuestionAnswerValue.NO;
            },
          );
          return Math.round(
            (noAnswers.length / processConfirmation.answers.length) * 100,
          );
        };
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      const computedTitle = computed(() => {
        return props.title ? props.title : t('spc.nav.audit');
      });

      function handleFilterTypeChange() {
        data.tableUpdateCounter++;
      }

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  return await ApiService.delete(`/spc/audit/${id}`);
                }),
              );

              const filteredProcessConfirmations =
                data.processConfirmations.filter(
                  (processConfirmation: ProcessConfirmation) => {
                    if (!processConfirmation.id) return false;
                    return !data.checkedElements.includes(
                      processConfirmation.id,
                    );
                  },
                );

              data.processConfirmations = [];
              data.processConfirmations = filteredProcessConfirmations;

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');

              data.tableUpdateCounter++;
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      function handleRowClick(processConfirmation: ProcessConfirmation) {
        router.push(`/spc/audit/${processConfirmation.id}`);
      }

      const computedProcessConfirmations = computed(() => {
        return data.processConfirmations.filter(
          (processConfirmation: ProcessConfirmation) => {
            if (data.filteredType === t('spc.processconfirmation.filterAll')) {
              return true;
            }

            if (
              data.filteredType === t('spc.processconfirmation.onlyAudit') &&
              processConfirmation.type === ProcessConfirmationType.AUDIT
            )
              return true;
            if (
              data.filteredType === t('spc.processconfirmation.onlySingle') &&
              processConfirmation.type ===
                ProcessConfirmationType.PROCESS_CONFIRMATION
            )
              return true;
            return false;
          },
        );
      });

      function calculateProgress(processConfirmation: ProcessConfirmation) {
        let answeredQuestions = 0;

        processConfirmation.answers.forEach(
          (questionAnswer: QuestionAnswer) => {
            if (questionAnswer.answer !== null) answeredQuestions++;
          },
        );

        return Math.round(
          (answeredQuestions / processConfirmation.answers.length) * 100,
        );
      }

      const computedIsMissingUserConfig = computed(() => {
        return (
          computedIsMissingManagementLevel.value === true ||
          computedIsMissingManagementArea.value === true
        );
      });

      const computedIsMissingManagementArea = computed(() => {
        if (data.isLoaded === false) return false;
        return data.userManagementAreas.length === 0;
      });

      const computedIsMissingManagementLevel = computed(() => {
        const currentManagementLevel = store.getters.currentManagementLevel;
        if (currentManagementLevel === null) return true;
        if (typeof currentManagementLevel.id === 'undefined') return true;
        return false;
      });

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        try {
          const loadedUserManagementAreasRequest = await ApiService.get(
            'spc/users/areas/self',
          );

          data.userManagementAreas =
            loadedUserManagementAreasRequest.data as UserManagementAreaWithUser[];

          if (data.userManagementAreas.length === 0) {
            data.isLoaded = true;
            store.dispatch(Actions.END_LOADER);
            return;
          }

          data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);

          const viewConfig: ProcessConfirmationViewConfig = {
            onlyOpen: props.onlyOpen || false,
          };

          const processConfirmationsResponse = await ApiService.post(
            '/spc/audit',
            {
              data: viewConfig,
            },
          );
          const processConfirmations: ProcessConfirmation[] =
            processConfirmationsResponse.data;

          data.processConfirmations = processConfirmations
            .slice(0)
            .map((processConfirmation: ProcessConfirmation) => {
              const lastLocation =
                processConfirmation.locations[
                  processConfirmation.locations.length - 1
                ];
              return {
                ...processConfirmation,
                progress: calculateProgress(processConfirmation),
                answeredQuestions: processConfirmation.answers.filter(
                  (answer) => answer.answer !== null,
                ).length,
                totalQuestions: processConfirmation.answers.length,
                locationText: processConfirmation.locations
                  .map((location: ProcessConfirmationLocation) => {
                    return location.locationName;
                  })
                  .join(' > '),
                locationName: lastLocation ? lastLocation.locationName : null,
                locationAreaName: lastLocation
                  ? lastLocation.locationIdentifier
                  : null,
              } as ProcessConfirmationView;
            });
        } catch (error) {
          console.error('ERROR While loading Process Confirmations', error);
        }

        data.tableUpdateCounter++;

        data.isLoaded = true;
        store.dispatch(Actions.END_LOADER);
      });

      return {
        data,
        computedDate,
        computedTitle,
        deleteSelected,
        getFullOrgUnitPath,
        handleRowClick,
        computedCurrentUserIsAdmin,
        computedProcessConfirmations,
        computedFileUrl,
        computedPercentageWithNo,
        computedProgressColors,
        computedShowNoBadge,
        computedOpacityNoBadge,
        handleFilterTypeChange,
        computedIsMissingManagementLevel,
        computedIsMissingManagementArea,
        computedIsMissingUserConfig,
        computedHasMultipleTypes,
      };
    },
  });
</script>

<style scoped>
  ul {
    margin-bottom: 0;
  }
</style>
