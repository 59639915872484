import { User } from '@/store/modules/AuthModule';
import { OrgChartDataPair } from './cdf';
import { OpenPoint } from './opl';

export type ErrorCaseDecisionWithForm = ErrorCaseDecision & {
  edit: boolean;
  nameBefore: string;
};
export type ErrorCaseCauseWithForm = ErrorCaseCause & {
  edit: boolean;
  nameBefore: string;
};
export type ErrorCaseCauseReasonWithForm = ErrorCaseCauseReason & {
  edit: boolean;
  nameBefore: string;
};

export type DecisionCatalog = {
  id?: number;
  orgUnitId: string;
  decisions: ErrorCaseDecision[];
  createdAt: string;
};

export type CauseCatalog = {
  id?: number;
  orgUnitId: string;
  causes: ErrorCaseCause[];
  createdAt: string;
};

export type CauseReasonCatalog = {
  id?: number;
  orgUnitId: string;
  causeReasons: ErrorCaseCauseReason[];
  createdAt: string;
};

export type ProcessInterruptionCategory = {
  id: number;
  name: string;
  subCategories: ProcessInterruptionSubCategory[];
  createdAt: string;
};

export type ProcessInterruptionCategoryWithForm =
  ProcessInterruptionCategory & {
    subCategories: ProcessInterruptionSubCategoryWithForm[];
    showSubCategories: boolean;
    edit: boolean;
    nameBefore: string;
  };

export type ProcessInterruptionSubCategory = {
  id: number;
  name: string;
  subSubCategories: ProcessInterruptionSubSubCategory[];
  parent: ProcessInterruptionCategory;
};

export type ProcessInterruptionSubSubCategory = {
  id: number;
  name: string;
  parent: ProcessInterruptionSubCategory;
};

export type ProcessInterruptionSubCategoryWithForm =
  ProcessInterruptionSubCategory & {
    subSubCategories: ProcessInterruptionSubSubCategoryWithForm[];
    showSubCategories: boolean;
    edit: boolean;
    nameBefore: string;
  };

export type ProductCategoryWithForm = ProductCategory & {
  subCategories: ProcessInterruptionSubCategoryWithForm[];
  showSubCategories: boolean;
  edit: boolean;
  nameBefore: string;
};
export type ProductSubCategoryWithForm = ProductSubCategory & {
  edit: boolean;
  nameBefore: string;
};

export type ProcessInterruptionSubSubCategoryWithForm =
  ProcessInterruptionSubSubCategory & {
    edit: boolean;
    nameBefore: string;
  };

export type ProcessInterruptionCatalog = {
  id?: number;
  orgUnitId: string;
  subSubCategories: ProcessInterruptionSubSubCategory[];
  createdAt: string;
};

export type ProductCategoryCatalog = {
  id?: number;
  orgUnitId: string;
  subCategories: ProductSubCategory[];
  createdAt: string;
};

export type ProductCategory = {
  id: number;
  name: string;
  subCategories: ProductSubCategory[];
  createdAt: string;
};
export type ProductSubCategory = {
  id: number;
  name: string;
  parent: ProductCategory;
};

export type ErrorCaseCDFName = {
  id?: number;
  name: string;
  pos: number;
};

export type OrgChartDataPairWithCatalogs = OrgChartDataPair & {
  riskCatalog: RiskCatalog | null;
  processInterruptionCatalog: ProcessInterruptionCatalog | null;
  productCategoryCatalog: ProductCategoryCatalog | null;
  decisionCatalog: DecisionCatalog | null;
  causeCatalog: CauseCatalog | null;
  causeReasonCatalog: CauseReasonCatalog | null;
  riskLevelCatalogs: RiskLevelCatalog[];
  depth?: number;
  selectable?: boolean;
  visible?: boolean;
  userAreas: string[];
};

export type ErrorStyleWithForm = ErrorStyle & {
  edit: boolean;
  nameBefore: string;
};
export type LocationRiskCategoryWithForm = LocationRiskCategory & {
  edit: boolean;
  nameBefore: string;
};

export type ErrorPlaceWithForm = ErrorPlace & {
  edit: boolean;
  nameBefore: string;
};

export type RiskCatalog = {
  id?: number;
  orgUnitId: string;
  locationRisks: ErrorLocationRiskWithCategory[];
  createdAt: string;
};

export type RiskLevelCatalog = {
  id?: number;
  orgUnitId: string;
  riskValue: number;
  userIds: string[];
  users: User[];
  usersToSelect: User[];
  createdAt?: string;
};

export type FESConfig = {
  hierarchyLevels: ErrorCaseCDFName[];
  riskLevels: RiskLevel[];
  allGroups: string[];
};

export type QRCodeData = {
  partName: string;
  partReference: string;
  jobReference: string;
};

export type Barcode = {
  id?: number;
  code: string;
};

export type ErrorLocationRisk = {
  id: number;
  place: ErrorPlace;
  style: ErrorStyle;
  riskValue: number;
};

export type ErrorLocationRiskWithCategory = ErrorLocationRisk & {
  category: LocationRiskCategory;
};

export type ErrorPlace = {
  id: number;
  name: string;
};

export type ErrorStyle = {
  id: number;
  name: string;
  category: LocationRiskCategory;
};

export type ErrorCaseDecision = {
  id?: number;
  name: string;
};

export type ErrorCaseCause = {
  id?: number;
  name: string;
};
export type ErrorCaseCauseReason = {
  id?: number;
  name: string;
};

export enum ProcessInterruptionState {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export type ProcessInterruption = {
  id?: number;
  category: ProcessInterruptionCategory;
  subCategory: ProcessInterruptionSubCategory;
  subSubCategory: ProcessInterruptionSubSubCategory;
  downtimeMachine: number | null;
  downtimeWorker: number | null;
  locations: ErrorCaseLocation[];
  error: ErrorCase | null;
  state: ProcessInterruptionState;
  reason: string;
};

export type ProcessInterruptionWithPermission = ProcessInterruption & {
  allowedToAccept: boolean;
};

export type ErrorCaseDecisionDto = {
  id: number;
  decision: ErrorCaseDecision | null;
  responsibleUserId: string;
  cause: ErrorCaseCause | null;
  causeReason: ErrorCaseCauseReason | null;
  splitDecisions: ErrorCaseSplitDecision[] | null;
  openPoints: OpenPoint[];
};
export type ErrorCaseDecisionUpdateDto = {
  id: number;
  decision: ErrorCaseDecision;
  cause: ErrorCaseCause;
  causeReason: ErrorCaseCauseReason;
};
export type ErrorCaseDecider = {
  id?: number;
  userId: string;
  name: string;
  mail: string;
  username: string;
};
export type ErrorCaseSplitDecider = {
  id?: number;
  userId: string;
  name: string;
  mail: string;
  username: string;
};

export type ErrorCaseSplitDecision = {
  id?: number;
  amount: number;
  splitDecision: ErrorCaseDecision | string;
  splitCause: ErrorCaseCause | string;
  splitCauseReason: ErrorCaseCauseReason | string;
  decider: ErrorCaseSplitDecider;
  errorCase?: ErrorCase;
  decisionDate?: string | null;
};

export type ErrorCase = {
  id?: number;
  detail: ErrorCaseDetail;
  blockedUserId: string | null;
  blockedUser: User | null;
  barcodes: Barcode[];
  locations: ErrorCaseLocation[];
  processInterruption: ProcessInterruption | null;
  decision: ErrorCaseDecision | null;
  decisionDate: string | null;
  decider: ErrorCaseDecider | null;
  splitDecisions: ErrorCaseSplitDecision[];
  cause: ErrorCaseCause | null;
  causeReason: ErrorCaseCauseReason | null;
  responsibleUserId: string;
  responsibleUser: User;
  reporterUserId: string;
  reporterUser: User;
  locationRisk: ErrorLocationRisk;
  productCategory: ProductCategory | null;
  productSubCategory: ProductSubCategory | null;
  openPoints: OpenPoint[] | null;
  createdAt: string;
};

export type ErrorCaseLocation = {
  id?: number;
  locationIdentifier: string;
  locationName: string;
  locationId: string;
  pos: number;
};

export type ErrorCaseDetail = {
  id?: number;
  materialReference: string;
  faufReference: string;
  materialName: string | null;
  customerReference: string | null;
  customerOrderReference: string | null;
  checkReference: string | null;
  sapReference: string | null;
  amountTotal: number;
  amountChecked: number;
  amountFlawed: number;
  description: string | null;
};

export type RiskLevel = {
  id: number;
  level: number;
  groupName: string | null;
  needsDecision: boolean;
};

export type RiskLevelEdit = RiskLevel & {
  isNew: boolean;
};

export type RiskLevelWithUsers = RiskLevel & {
  users: User[];
  userIds: string[];
  usersToSelect: User[];
};

export type LocationRiskCategory = {
  id?: number;
  name: string;
  errorStyles: ErrorStyle[];
  createdAt: string;
};

export type ErrorCaseFilterConfig = {
  riskLevel: number;
  orgUnitId: string | null;
};

export type ProcessInterruptionFilterConfig = {
  orgUnitId: string | null;
};

export enum BlockErrorCaseResponseStates {
  ASSIGNED,
  BLOCKED,
  RELEASED,
}

export type BlockErrorCaseResponse = {
  state: BlockErrorCaseResponseStates;
  blockedUserId: string;
  blockedUser: User;
};

export type ErrorCaseWithProgress = ErrorCase & {
  openPointsCount: number | null;
  openPointsDone: number | null;
};
