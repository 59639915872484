<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1"
          >Made with ❤ Join GmbH &copy; 2022 - {{ currentYear }}</span
        >
      </div>
      <a
        href="https://inside.bosch.com/irj/portal/?NavigationTarget=HLPFS:%2f%2fwcms_corpfunc_Bosch_20GlobalNet%2fwcms_corpfunc_02_20Organization%2fwcms_corpfunc_corporate_functions%2fwcms_corpfunc_c_cm%2fwcms_corpfunc_organization_256%2fwcms_corpfunc_c_isp_1%2fwcms_corpfunc_topics_2%2fwcms_corpfunc_transparency"
        target="_blank"
        >Datenschutzhinweise</a
      >
      <!--end::Copyright-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { footerWidthFluid } from '@/core/helpers/config';

  export default defineComponent({
    name: 'KTFooter',
    setup() {
      const currentYear = new Date().getFullYear();
      return {
        footerWidthFluid,
        currentYear,
      };
    },
  });
</script>
