<template>
  <div class="fes-config-container">
    <div class="row">
      <div class="col-12 mb-3">
        <config-hierarchy-names
          :hierarchyNames="data.hierarchyNames"
          moduleName="spc"
          @updated="updateOrgChartData"
        />
      </div>

      <div class="col-12">
        <div class="card">
          <div
            class="card-header border-0"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#orgChartForm"
            aria-expanded="true"
            aria-controls="orgChartForm"
          >
            <h3 class="card-title align-items-center justify-content-start">
              <span class="card-label fw-bolder fs-3 mb-1"
                >{{ $t('viewOrgChart') }} SPC</span
              >
            </h3>
          </div>
          <div id="orgChartForm" class="collapse show">
            <div class="card-body py-3">
              <div class="row mb-3">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.expandAll"
                  >
                    {{ $t('cdf.orgChart.buttons.openAllNodes') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.collapseAll"
                  >
                    {{ $t('cdf.orgChart.buttons.closeAllNodes') }}
                  </button>
                </div>
              </div>

              <div id="spcChart" :key="data.orgChartTmpId"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <side-drawer
      id="spcConfigDrawer"
      :title="computedDrawerTitle"
      :node="data.currentNode"
      width="50vw"
      :path="computedNodePathString"
    >
      <org-unit-path
        :nodePath="computedNodePath"
        :areaNames="data.hierarchyNames"
      />

      <question-catalog-drawer
        :node-path="computedNodePath"
        :area-names="data.hierarchyNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
      />
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
  // import moment from 'moment';
  // import { useRoute, useRouter } from 'vue-router';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { OrgChart } from 'd3-org-chart';
  import { DrawerComponent, MenuComponent } from '@/assets/ts/components/index';
  import SideDrawer from '@/components/SideDrawer.vue';
  import QuestionCatalogDrawer from '@/components/spc/QuestionCatalogDrawer.vue';
  import OrgUnitPath from '@/components/spc/OrgUnitPath.vue';

  import ConfigHierarchyNames from '@/components/HierarchyNames.vue';

  // import { useRouter } from 'vue-router';
  // import * as yup from 'yup';
  // import { hideModal } from '@/core/helpers/dom';
  import {
    computedDate,
    calculateDepth,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import {
    getInheritQuestionCatalogsToTopWithoutDisabled,
    getNodePathSPC,
  } from '@/core/helpers/spc';
  import {
    RiskLevel,
    RiskLevelEdit,
    RiskLevelWithUsers,
  } from '@/core/models/fes';

  import {
    HierarchyName,
    SPCConfig,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/spc';

  export default defineComponent({
    name: 'spcConfig',
    components: {
      ConfigHierarchyNames,
      SideDrawer,
      OrgUnitPath,
      QuestionCatalogDrawer,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();

      const riskLevelUserMultiselects = ref([]);

      const computedDrawerTitle = computed(() => {
        if (data.currentNode === null) return '';
        return data.currentNode.name;
      });

      const data: {
        pageTitle: string;
        orgChartData: OrgChartDataPairWithCatalogs[];
        orgChart: any;
        orgChartTmpId: number;
        currentNode: OrgChartDataPairWithCatalogs | null;
        hierarchyNames: HierarchyName[];
        riskLevels: RiskLevelEdit[];
        currentRiskLevels: RiskLevelWithUsers[];
      } = reactive({
        pageTitle: t('viewOrgChart'),
        orgChartData: [] as OrgChartDataPairWithCatalogs[],
        orgChart: new OrgChart(),
        orgChartTmpId: 1,
        currentNode: null,
        hierarchyNames: [] as HierarchyName[],
        riskLevels: [] as RiskLevelEdit[],
        currentRiskLevels: [] as RiskLevelWithUsers[],
      });

      const activeNames = ref(['1']);

      const computedAvailableRiskLevels = computed(() => {
        return data.riskLevels
          .filter((riskLevel: RiskLevel) => {
            return riskLevel.needsDecision === true;
          })
          .map((riskLevel: RiskLevel) => {
            return {
              id: riskLevel.id,
              level: riskLevel.level,
              needsDecision: riskLevel.needsDecision,
              groupName: riskLevel.groupName,
              userIds: [],
              users: [],
              usersToSelect: [],
            } as RiskLevelWithUsers;
          });
      });

      const computedOrgChartData = computed(() => {
        return data.orgChartData.slice(0) || [];
      });

      function getInfoBox(item: OrgChartDataPairWithCatalogs, orgChartData) {
        // if (item.parentId === '') return '';
        return `<div class="info-box">
        <span class="badge badge-circle badge-light me-1" title="${t('depth')}">
          ${calculateDepth(item, orgChartData) + 1}
        </span>
      </div>`;
      }

      async function renderChart() {
        data.orgChart
          .container('#spcChart')
          .data(computedOrgChartData.value.slice(0))
          .nodeWidth(() => 340)
          .nodeHeight(() => 200)
          .buttonContent(({ node }) => {
            return `<a href="javascript:" class="nodeMoreButton">${
              node.children
                ? `<i class="fas fa-chevron-up"></i>`
                : `<i class="fas fa-chevron-down"></i>`
            } ${node.data._directSubordinates}</a>`;
          })
          .onNodeClick((d) => handleNodeClick(d.id))
          .nodeContent((d) => {
            const cdfNameHtml = getCdfNameHtml(d.data);
            const questionCatalogHtml = getQuestionCatalogsHtml(d.data);

            return `<div class="orgChartNode"
    style="border-radius:2px;overflow:visible"
  >
    <div
      class="orgChartNodeContainer"
    >
      ${getInfoBox(d.data, data.orgChartData)}
      
      <div
        style="background-color:#00CCFF;height:10px;width:100%;border-radius:1px"
      ></div>

      ${cdfNameHtml}

      <div style="padding: 16px; text-align: center">
        <div style="color: #002B49; font-size: 16px; font-weight: bold">
          ${d.data.name}
        </div>
      </div>

      ${
        questionCatalogHtml !== ''
          ? `<div class="row nodeResponsibles">
              <div class="py-3 col">
                ${questionCatalogHtml}
              </div>
            </div>`
          : ''
      }

    </div>
  </div>`;
          })
          .render();
      }

      function getCdfNameHtml(orgUnit: OrgChartDataPairWithCatalogs) {
        const level = calculateDepth(orgUnit, data.orgChartData);

        if (typeof data.hierarchyNames[level] === 'undefined') return '';
        return `<div class="row nodeResponsibles">
        <div class="py-3 pb-0 col text-center">
          <span class="badge badge-light-primary">${data.hierarchyNames[level].name}</span>
        </div>
      </div>`;
      }
      function getQuestionCatalogsHtml(orgUnit: OrgChartDataPairWithCatalogs) {
        const totalQuestionCatalogs =
          getInheritQuestionCatalogsToTopWithoutDisabled(
            orgUnit,
            data.orgChartData,
          );

        if (totalQuestionCatalogs.length === 0)
          return `<div class="row nodeResponsibles">
        <div class="py-3 pb-0 col">
          <span class="badge badge-secondary">${t(
            'spc.questionCatalog.none',
          )}</span>
        </div>
      </div>`;

        return `<div class="row nodeResponsibles">
        <div class="py-3 pb-0 col">
          <span class="badge badge-success">${totalQuestionCatalogs.length} ${
            totalQuestionCatalogs.length === 1
              ? t('spc.questionCatalog.single')
              : t('spc.questionCatalog.all')
          }</span>
        </div>
      </div>`;
      }

      async function updateOrgChartData() {
        data.orgChart.data(computedOrgChartData.value);
        data.orgChart.render();
      }

      const computedNodePathString = computed(() => {
        if (computedNodePath.value.length === 1) return '';
        return computedNodePath.value
          .map((node) => {
            return node.name;
          })
          .join(' > ');
      });

      const computedNodePath = computed((): OrgChartDataPairWithCatalogs[] => {
        if (data.currentNode === null) return [];
        return getNodePathSPC(data.currentNode, data.orgChartData).sort(
          (
            orgUnit1: OrgChartDataPairWithCatalogs,
            orgUnit2: OrgChartDataPairWithCatalogs,
          ) => {
            return calculateDepth(orgUnit1, data.orgChartData) >
              calculateDepth(orgUnit2, data.orgChartData)
              ? 1
              : -1;
          },
        );
      });

      async function loadOrgChart() {
        store.dispatch(Actions.START_LOADER);

        try {
          const loadedOrgChartData = await (
            await ApiService.get('spc/org-chart')
          ).data;

          data.orgChartData = loadedOrgChartData
            .slice(0)
            .map((orgUnit: OrgChartDataPairWithCatalogs) => {
              return {
                ...orgUnit,
                depth: calculateDepth(orgUnit, loadedOrgChartData),
              };
            });

          const allDepths = data.orgChartData.map((orgUnit) => {
            return calculateDepth(orgUnit, data.orgChartData);
          });

          const spcConfig: SPCConfig = await (
            await ApiService.get('spc/config')
          ).data;

          // data.hierarchyNames = spcConfig.hierarchyNames.slice(0);

          const maxDepth = Math.max(...allDepths) + 1;

          // data.errorCaseCdfNames = [];
          data.hierarchyNames = spcConfig.hierarchyNames.slice(0, maxDepth);
          for (let i = 0; i < maxDepth; i++) {
            if (typeof data.hierarchyNames[i] === 'undefined') {
              data.hierarchyNames.push({
                name: '',
                pos: i + 1,
              });
            }
          }

          renderChart();
        } catch (error) {
          Swal.fire('Error', 'Not able to load Org Chart.', 'error');
        }

        store.dispatch(Actions.END_LOADER);
      }

      function initChart() {
        data.orgChartTmpId++;
        data.orgChartData = [];
        data.orgChart = new OrgChart();
        data.hierarchyNames = [] as HierarchyName[];
      }

      async function handleNodeClick(nodeId) {
        const currentNode = data.orgChartData.find(
          (item: OrgChartDataPairWithCatalogs) => {
            return item.id === nodeId;
          },
        );

        if (typeof currentNode !== 'undefined') {
          data.currentNode = {} as OrgChartDataPairWithCatalogs;
          if (typeof currentNode !== 'undefined') {
            data.currentNode = currentNode;
          }
        }

        openSideDrawer('spcConfigDrawer');
      }

      function handleCatalogUpdate(
        orgChartData: OrgChartDataPairWithCatalogs[],
      ) {
        data.orgChartData = orgChartData;
        updateOrgChartData();
      }

      onMounted(async () => {
        initChart();
        DrawerComponent.reinitialization();
        MenuComponent.reinitialization();
        loadOrgChart();
      });

      return {
        data,
        updateOrgChartData,
        computedOrgChartData,
        computedNodePath,
        computedNodePathString,
        handleCatalogUpdate,
        riskLevelUserMultiselects,
        // computedRiskLevelUserOptions,
        computedDate,
        calculateDepth,
        // computedCurrentResponsibles,
        loadOrgChart,
        computedAvailableRiskLevels,
        computedDrawerTitle,
        handleNodeClick,
        activeNames,
      };
    },
  });
</script>

<style lang="scss">
  .fes-config-container {
    #spcChart {
      width: 100%;
      height: 800px;
      overflow: hidden;
      background-color: #fcfcfc;
    }
    .orgChartNode {
      width: 340px;
      height: 250px;
    }
    .orgChartNodeContainer {
      height: inherit;
      border-radius: 10px;
      overflow: hidden;
    }
    .nodeMoreButton {
      width: 100%;
      text-align: center;
      border-radius: 3px;
      padding: 10px 5px;
      font-size: 12px;
      margin: auto auto;
      background-color: white;
      border: 1px solid lightgray;
    }
    .node-button-foreign-object:hover a.nodeMoreButton {
      border-color: lightblue;
    }
    .orgChartNodeContainer {
      padding-top: 0px;
      background-color: white;
      border: 1px solid lightgray;
    }
    g {
      cursor: default;
    }
    g.node-button-g,
    .orgChartNode {
      cursor: pointer;
    }
    .nodeTags,
    .nodeResponsibles {
      padding: 0 10px;
    }
    .info-box {
      display: flex;
      justify-content: flex-end;
      width: 64px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
    }
    .badge.badge-circle.badge-warning i,
    .badge.badge-circle.badge-success i {
      color: white;
    }
    .multiselect {
      padding: 0.66rem;
    }
    .node-foreign-object[height='200'] .orgChartNode {
      height: 200px;
    }
    /* .ekNode {
    background-color: #002b49;
  }
  .pukNode {
    background-color: #df0024;
  }
  .rkNode {
    background-color: #00ccff;
  } */
  }
</style>
