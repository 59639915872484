import {
  OrgChartDataPairWithCatalogs,
  QuestionCatalog,
  UserManagementArea,
} from '../models/spc';

const getNearestCatalogSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  name: 'questionCatalog' | string,
) => {
  if (orgUnit[name] !== null) {
    return orgUnit[name];
  }

  if (orgUnit.parentId) {
    const parent = orgUnits.find((parentOrgUnit) => {
      return parentOrgUnit.id === orgUnit.parentId;
    });

    if (parent) {
      return getNearestCatalogSPC(parent, orgUnits, name);
    }
  }

  return false;
};

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getInheritQuestionCatalogsToTop(
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): QuestionCatalog[] {
  if (orgUnit.parentId === '')
    return orgUnit.questionCatalogs
      .slice(0)
      .filter((questionCatalog: QuestionCatalog) => {
        return !questionCatalog.disabledOrgUnitIds.includes(orgUnit.id);
      })
      .sort(
        (
          questionCatalogA: QuestionCatalog,
          questionCatalogB: QuestionCatalog,
        ) => {
          if (questionCatalogA.name < questionCatalogB.name) {
            return -1;
          }
          if (questionCatalogA.name > questionCatalogB.name) {
            return 1;
          }
          return 0;
        },
      );

  const parent = orgUnits.find(
    (orgUnitTmp) => orgUnitTmp.id === orgUnit.parentId,
  ) as OrgChartDataPairWithCatalogs;

  return orgUnit.questionCatalogs
    .slice(0)
    .concat(getInheritQuestionCatalogsToTop(parent, orgUnits))
    .filter((questionCatalog: QuestionCatalog) => {
      return !questionCatalog.disabledOrgUnitIds.includes(orgUnit.parentId);
    })
    .sort(
      (
        questionCatalogA: QuestionCatalog,
        questionCatalogB: QuestionCatalog,
      ) => {
        if (questionCatalogA.name < questionCatalogB.name) {
          return -1;
        }
        if (questionCatalogA.name > questionCatalogB.name) {
          return 1;
        }
        return 0;
      },
    );
}

function getInheritQuestionCatalogsToTopWithoutDisabled(
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) {
  return getInheritQuestionCatalogsToTop(orgUnit, orgUnits).filter(
    (questionCatalog: QuestionCatalog) => {
      return !questionCatalog.disabledOrgUnitIds.includes(orgUnit.id);
    },
  );
}

const getNodePathSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): OrgChartDataPairWithCatalogs[] => {
  if (orgUnit.parentId === '') return [orgUnit];

  const parent = orgUnits.find(
    (orgUnitTmp) => orgUnitTmp.id === orgUnit.parentId,
  ) as OrgChartDataPairWithCatalogs;

  return [orgUnit].concat(getNodePathSPC(parent, orgUnits)).reverse().slice(0);
};

const getPathSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): string => {
  if (orgUnit.parentId === null) {
    return orgUnit.name;
  }

  const parent = orgUnits.find((item: OrgChartDataPairWithCatalogs) => {
    return item.id === orgUnit.parentId;
  });

  if (!parent) {
    return orgUnit.name;
  }

  return getPathSPC(parent, orgUnits) + ' > ' + orgUnit.name;
};

const isReadyOrgUnitSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  const hasQuestionCatalog =
    getInheritQuestionCatalogsToTopWithoutDisabled(orgUnit, orgUnits).length >
    0;
  if (!hasQuestionCatalog) return false;

  // const currentUser = { ...store.getters.currentUser };
  // const allowedLocations: string[] = [] as string[];

  // userManagementAreas.forEach((userManagementArea: UserManagementArea) => {

  //   con

  //   const nodePath = getNodePathSPC(orgUnit, orgUnits);
  // })

  return true;
};

const isReadyOrgUnitSPCForUser = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  userManagementAreas: UserManagementArea[],
) => {
  const isReadyInGeneral = isReadyOrgUnitSPC(orgUnit, orgUnits);
  if (isReadyInGeneral === false) return false;

  const allowedLocations: string[] = [] as string[];
  userManagementAreas.forEach((userManagementArea: UserManagementArea) => {
    const orgUnit = orgUnits.find(
      (loopedOrgUnit: OrgChartDataPairWithCatalogs) => {
        return loopedOrgUnit.id === userManagementArea.orgUnitId;
      },
    );

    if (orgUnit) {
      const nodePath = getAllChildrenSPC(orgUnit, orgUnits);
      nodePath.forEach((orgUnit: OrgChartDataPairWithCatalogs) => {
        if (!allowedLocations.includes(orgUnit.id)) {
          allowedLocations.push(orgUnit.id);
        }
      });
    }
  });

  return allowedLocations.includes(orgUnit.id);
};

const getAllChildrenSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): OrgChartDataPairWithCatalogs[] => {
  const children = orgUnits
    .filter((item: OrgChartDataPairWithCatalogs) => {
      return item.parentId === orgUnit.id;
    })
    .map((item: OrgChartDataPairWithCatalogs) => {
      return getAllChildrenSPC(item, orgUnits);
    })
    .reduce(
      (
        acc: OrgChartDataPairWithCatalogs[],
        curr: OrgChartDataPairWithCatalogs[],
      ) => {
        return acc.concat(curr);
      },
      [],
    );
  return [orgUnit].concat(children);
};

const hasReadyChildrenSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  const hasChildren = orgUnits.filter((child) => {
    return child.parentId === orgUnit.id;
  });

  if (hasChildren.length) {
    return hasChildren.some((child) => {
      return isReadyOrgUnitSPC(child, orgUnits);
    });
  } else {
    return false;
  }
};

const hasReadyChildrenSPCForUser = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  userManagementAreas: UserManagementArea[],
) => {
  const hasChildren = orgUnits.filter((child) => {
    return child.parentId === orgUnit.id;
  });

  if (hasChildren.length) {
    return getAllChildrenSPC(orgUnit, orgUnits).some((child) => {
      return isReadyOrgUnitSPCForUser(child, orgUnits, userManagementAreas);
    });
  } else {
    return false;
  }
};

const hasReadyParentsSPC = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): boolean => {
  if (orgUnit.parentId === null) {
    return false;
  }

  const parent = orgUnits.find((loopedOrgUnit) => {
    return loopedOrgUnit.id === orgUnit.parentId;
  });

  if (parent) {
    return (
      isReadyOrgUnitSPC(parent, orgUnits) ||
      hasReadyParentsSPC(parent, orgUnits)
    );
  }
  return false;
};

const hasReadyParentsSPCForUser = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  userManagementAreas: UserManagementArea[],
): boolean => {
  if (orgUnit.parentId === null) {
    return false;
  }

  const parent = orgUnits.find((loopedOrgUnit) => {
    return loopedOrgUnit.id === orgUnit.parentId;
  });

  if (parent) {
    return (
      isReadyOrgUnitSPCForUser(parent, orgUnits, userManagementAreas) ||
      hasReadyParentsSPCForUser(parent, orgUnits, userManagementAreas)
    );
  }
  return false;
};

function getFullOrgUnitPathSPC(
  orgUnit: OrgChartDataPairWithCatalogs,
  orgChart: OrgChartDataPairWithCatalogs[],
) {
  const path = getNodePathSPC(orgUnit, orgChart);
  return path
    .map((orgUnit: OrgChartDataPairWithCatalogs) => {
      return orgUnit.name;
    })
    .join(' > ');
}

export {
  getNearestCatalogSPC,
  isReadyOrgUnitSPC,
  isReadyOrgUnitSPCForUser,
  getNodePathSPC,
  getPathSPC,
  hasReadyChildrenSPC,
  hasReadyChildrenSPCForUser,
  hasReadyParentsSPC,
  hasReadyParentsSPCForUser,
  getFullOrgUnitPathSPC,
  getAllChildrenSPC,
  getInheritQuestionCatalogsToTop,
  getInheritQuestionCatalogsToTopWithoutDisabled,
  shuffle,
};
