import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack pt-10 px-9" }
const _hoisted_2 = { class: "mr-2" }
const _hoisted_3 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_4 = {
  key: 0,
  type: "submit",
  class: "btn btn-lg btn-primary"
}
const _hoisted_5 = { class: "svg-icon svg-icon-4 ms-1 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.backAllowed)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-lg btn-light-primary me-3",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevStepFunction && _ctx.prevStepFunction(...args)))
          }, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr063.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('fes.form.back')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.computedNextAllowed)
        ? (_openBlock(), _createElementBlock("button", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t('fes.form.next')) + " ", 1),
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}