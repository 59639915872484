<template>
  <MyForm
    @submit="handleRiskLevelConfigSubmit"
    novalidate="novalidate"
    action="javascript:"
    class="card"
  >
    <div
      class="card-header border-0"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#riskLevelForm"
      aria-expanded="false"
      aria-controls="riskLevelForm"
    >
      <h3 class="card-title align-items-center justify-content-start">
        <span class="card-label fw-bolder fs-3 mb-1">{{
          $t('fes.form.riskLevel')
        }}</span>
      </h3>
    </div>
    <div id="riskLevelForm" class="collapse">
      <div class="card-body py-3">
        <div class="row" v-if="data.levels.length === 0">
          <div class="col">
            <p>Keine Risko Level hinterlegt.</p>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(riskLevel, index) of data.levels"
            :key="index"
            class="row mb-6"
          >
            <div class="col-3">
              <label class="form-label">{{ $t('fes.form.riskLevel') }}</label>
              <Field
                as="select"
                :name="`riskLevelValue[${index}]`"
                class="form-select form-select-solid form-select-lg"
                v-model="riskLevel.level"
              >
                <option
                  v-for="(riskLevelValue, index) of computedRiskLevelOptions(
                    riskLevel,
                  )"
                  :key="index"
                  :value="riskLevelValue"
                >
                  {{ riskLevelValue }}
                </option>
              </Field>
            </div>

            <div class="col-4 d-flex pt-6">
              <label
                class="form-check form-switch form-check-custom form-check-solid"
              >
                <Field
                  type="checkbox"
                  class="form-check-input"
                  :name="`riskLevelNeedsDecision[${index}]`"
                  :value="true"
                  :unchecked-value="false"
                  v-model="riskLevel.needsDecision"
                />
                <span class="form-check-label fw-bold">
                  {{ $t('fes.config.decisionNeeded') }}
                </span>
              </label>
            </div>
            <div class="col-4">
              <div v-if="riskLevel.needsDecision">
                <label class="form-label">Entscheider Gruppe</label>
                <Field
                  as="select"
                  :name="`riskLevelGroupName[${index}]`"
                  class="form-select form-select-solid"
                  v-model="riskLevel.groupName"
                >
                  <option
                    v-for="(groupName, index) of computedGroupNameOptions"
                    :key="index"
                    :value="groupName"
                  >
                    {{ groupName }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="locationRiskCategory" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1 d-flex align-items-end">
              <button
                type="button"
                class="btn btn-danger me-2"
                @click="handleRiskLevelRemove(riskLevel, index)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div v-if="data.levels.length < 10" class="row">
          <div class="col">
            <button
              type="button"
              class="btn btn-secondary"
              @click="addNewRiskLevel"
            >
              <i class="fa fa-plus"></i>
              {{ $t('fes.config.addRiskLevel') }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end py-6 px-9">
        <button
          type="submit"
          id="kt_account_profile_details_submit"
          ref="submitButton"
          class="btn btn-primary"
          :disabled="data.levels.length === 0"
        >
          <span class="indicator-label"> {{ $t('save') }} </span>
        </button>
      </div>
    </div>
  </MyForm>
</template>
<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form } from 'vee-validate';
  import { useStore } from 'vuex';
  import { RiskLevel, RiskLevelEdit } from '@/core/models/fes';
  import Swal from 'sweetalert2';
  import { useI18n } from 'vue-i18n';
  import { AxiosResponse } from 'axios';

  export default defineComponent({
    name: 'configRiskLevels',
    props: {
      riskLevels: {
        type: Array as PropType<RiskLevelEdit[]>,
        required: true,
        default: () => [],
      },
      allGroups: {
        type: Array as PropType<string[]>,
        required: true,
        default: () => [],
      },
    },
    emits: ['updated'],
    components: {
      MyForm: Form,
      Field,
    },
    setup(props, { emit }) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        levels: RiskLevelEdit[];
        allGroups: string[];
      } = reactive({
        levels: props.riskLevels,
        allGroups: props.allGroups as string[],
      });

      const computedGroupNameOptions = computed(() => {
        return data.allGroups;
      });

      const computedRiskLevelOptions = computed(() => {
        const maxLevels = Array.from({ length: 10 }, (_, i) => i + 1);
        return (inputRiskLevel) => {
          const alreadySelectedLevels = data.levels.map(
            (riskLevel: RiskLevelEdit) => {
              return riskLevel.level;
            },
          );

          return maxLevels.filter((level) => {
            if (inputRiskLevel.level === level) return true;
            return !alreadySelectedLevels.includes(level);
          });
        };
      });

      const emptyRiskLevel: RiskLevelEdit = {
        isNew: true,
      } as RiskLevelEdit;

      function addNewRiskLevel() {
        const riskLevelWithForm = Object.assign(
          {},
          emptyRiskLevel,
        ) as RiskLevelEdit;
        data.levels.push(riskLevelWithForm);
      }

      async function handleRiskLevelRemove(
        riskLevel: RiskLevelEdit,
        index: number,
      ) {
        if (!riskLevel.isNew) {
          const result = await Swal.fire({
            title: t('sureQuestionHeadline'),
            text: t('fes.config.removeRiskLevelQuestion'),
            icon: 'question',
            showCancelButton: true,
          });

          if (!result.isConfirmed) return;
          store.dispatch(Actions.START_LOADER);
          await ApiService.delete(`fes/config/risklevel/${riskLevel.id}`);
          store.dispatch(Actions.END_LOADER);
        }
        data.levels = data.levels.filter(
          (riskLevel: RiskLevelEdit, loopedIndex: number) => {
            return index !== loopedIndex;
          },
        );
        // data.levels.splice(index, 1);
      }

      async function handleRiskLevelConfigSubmit() {
        store.dispatch(Actions.START_LOADER);
        const riskLevelUpdateResult: AxiosResponse<RiskLevelEdit[]> =
          await ApiService.post('fes/config/risklevels', {
            data: data.levels.map((riskLevel: RiskLevel) => {
              if (riskLevel.needsDecision === false) {
                riskLevel.groupName = null;
              }
              return riskLevel;
            }),
          });
        store.commit(Mutations.SET_FES_RISK_LEVELS, riskLevelUpdateResult.data);
        store.dispatch(Actions.END_LOADER);
        emit('updated');
      }

      watch(
        () => props.riskLevels,
        () => {
          data.levels = props.riskLevels;
        },
      );

      watch(
        () => props.allGroups,
        () => {
          data.allGroups = props.allGroups;
        },
      );

      return {
        data,
        props,
        computedRiskLevelOptions,
        computedGroupNameOptions,
        handleRiskLevelConfigSubmit,
        handleRiskLevelRemove,
        addNewRiskLevel,
      };
    },
  });
</script>
