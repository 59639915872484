import { computed } from 'vue';
import moment from 'moment';
import { DrawerComponent } from '@/assets/ts/components';
import {
  OrgChartDataPairWithCatalogs,
  RiskLevel,
  RiskLevelCatalog,
} from '../models/fes';
import { isProcessInterruptionsEnabled } from '../services/ApiService';

const computedDate = computed(() => {
  return (date: string) => {
    return moment(date).format('DD.MM.YYYY');
  };
});
const computedTime = computed(() => {
  return (date: string) => {
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  };
});
const computedAgo = computed(() => {
  return (date: string, locale = 'de') => {
    return moment(date).locale(locale).fromNow();
  };
});

const openSideDrawer = (id: string) => {
  const drawerInstance = DrawerComponent.getInstance(id);
  if (typeof drawerInstance !== 'undefined') {
    drawerInstance.show();
  }
};
const closeSideDrawer = (id: string) => {
  const drawerInstance = DrawerComponent.getInstance(id);
  if (typeof drawerInstance !== 'undefined') {
    drawerInstance.hide();
  }
};

type SimpleOrgUnit = {
  id: string;
  parentId: string;
};

const calculateDepth = (
  orgUnit: SimpleOrgUnit,
  orgUnits: SimpleOrgUnit[],
): number => {
  let maxDepth = 0;

  if (orgUnit.parentId) {
    const parent = orgUnits.find(
      (orgUnitTmp) => orgUnitTmp.id === orgUnit.parentId,
    );

    if (parent) {
      maxDepth = calculateDepth(parent, orgUnits) + 1;
    }
  }
  return maxDepth;
};

const getNearestRiskLevelCatalogs = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  if (orgUnit.riskLevelCatalogs.length > 0) {
    return orgUnit.riskLevelCatalogs;
  }

  if (orgUnit.parentId) {
    const parent = orgUnits.find((parentOrgUnit) => {
      return parentOrgUnit.id === orgUnit.parentId;
    });

    if (parent) {
      return getNearestRiskLevelCatalogs(parent, orgUnits);
    }
  }

  return false;
};

const getNearestCatalog = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  name:
    | 'decisionCatalog'
    | 'causeCatalog'
    | 'riskCatalog'
    | 'processInterruptionCatalog'
    | string,
) => {
  if (orgUnit[name] !== null) {
    return orgUnit[name];
  }

  if (orgUnit.parentId) {
    const parent = orgUnits.find((parentOrgUnit) => {
      return parentOrgUnit.id === orgUnit.parentId;
    });

    if (typeof parent !== 'undefined' && parent) {
      return getNearestCatalog(parent, orgUnits, name);
    }
  }

  return false;
};

// const gerParentNodePath = (currentNode: OrgChartDataPairWithCatalogs, orgChartData: OrgChartDataPairWithCatalogs[]) => {
//   if (!parent) return [];
//   if (parent.parentId) {
//     const parentNode = data.orgChartData.find(
//       (orgUnitTmp) => orgUnitTmp.id === parent.parentId,
//     );
//     if (parentNode) {
//       return [parent].concat(gerParentNodePath(parentNode));
//     }
//   }
//   return [parent];
// }

const getNodePath = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): OrgChartDataPairWithCatalogs[] => {
  if (orgUnit.parentId === '') return [orgUnit];

  const parent = orgUnits.find(
    (orgUnitTmp) => orgUnitTmp.id === orgUnit.parentId,
  ) as OrgChartDataPairWithCatalogs;

  return [orgUnit].concat(getNodePath(parent, orgUnits)).reverse().slice(0);
};

const getPath = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): string => {
  if (orgUnit.parentId === null) {
    return orgUnit.name;
  }

  const parent = orgUnits.find((item: OrgChartDataPairWithCatalogs) => {
    return item.id === orgUnit.parentId;
  });

  if (!parent) {
    return orgUnit.name;
  }

  return getPath(parent, orgUnits) + ' > ' + orgUnit.name;
};

const isReadyOrgUnitPI = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  const hasProcessInterruptionCatalog = getNearestCatalog(
    orgUnit,
    orgUnits,
    'processInterruptionCatalog',
  );

  if (hasProcessInterruptionCatalog) return true;
  return false;
};

const isReadyOrgUnit = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  riskLevels: RiskLevel[],
) => {
  // if (calculateDepth(orgUnit, orgUnits) === 0) return true;
  const hasDecisionCatalog = getNearestCatalog(
    orgUnit,
    orgUnits,
    'decisionCatalog',
  );
  const hasCauseCatalog = getNearestCatalog(orgUnit, orgUnits, 'causeCatalog');
  const hasCauseReasonCatalog = getNearestCatalog(
    orgUnit,
    orgUnits,
    'causeReasonCatalog',
  );
  const hasRiskCatalog = getNearestCatalog(orgUnit, orgUnits, 'riskCatalog');
  const hasProductCategoryCatalog = getNearestCatalog(
    orgUnit,
    orgUnits,
    'productCategoryCatalog',
  );

  const riskLevelCatalogs = getNearestRiskLevelCatalogs(orgUnit, orgUnits);
  const riskLevelsWithDecision = riskLevels.filter(
    (loopedRiskLevel: RiskLevel) => {
      return loopedRiskLevel.needsDecision === true;
    },
  );

  const activeRiskLevels: number[] = riskLevels
    .filter((loopedRiskLevel: RiskLevel) => {
      return loopedRiskLevel.needsDecision === true;
    })
    .map((riskLevel: RiskLevel) => {
      return riskLevel.level;
    });

  let hasFilledRiskLevelCatalogs = true;
  if (
    riskLevelCatalogs !== false &&
    riskLevels.filter((loopedRiskLevel: RiskLevel) => {
      return loopedRiskLevel.needsDecision === true;
    }).length ===
      riskLevelCatalogs.filter((riskLevelCatalog: RiskLevelCatalog) => {
        return riskLevelsWithDecision
          .map((riskLevel: RiskLevel) => riskLevel.level)
          .includes(riskLevelCatalog.riskValue);
      }).length
  ) {
    hasFilledRiskLevelCatalogs = riskLevelCatalogs.every(
      (riskLevelCatalog: RiskLevelCatalog) => {
        if (!activeRiskLevels.includes(riskLevelCatalog.riskValue)) return true;
        return riskLevelCatalog.userIds.length > 0;
      },
    );
  } else {
    hasFilledRiskLevelCatalogs = false;
  }

  if (isProcessInterruptionsEnabled()) {
    const hasProcessInterruptionCatalog = getNearestCatalog(
      orgUnit,
      orgUnits,
      'processInterruptionCatalog',
    );

    return (
      hasDecisionCatalog &&
      hasRiskCatalog &&
      hasCauseCatalog &&
      hasCauseReasonCatalog &&
      hasProductCategoryCatalog &&
      hasProcessInterruptionCatalog &&
      hasFilledRiskLevelCatalogs
    );
  } else {
    return (
      hasDecisionCatalog &&
      hasRiskCatalog &&
      hasCauseCatalog &&
      hasCauseReasonCatalog &&
      hasProductCategoryCatalog &&
      hasFilledRiskLevelCatalogs
    );
  }
};

const hasReadyChildrenPI = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  if (isReadyOrgUnitPI(orgUnit, orgUnits)) {
    return true;
  }

  const hasChildren = orgUnits.filter((child) => {
    return child.parentId === orgUnit.id;
  });

  if (hasChildren) {
    return hasChildren.some((child) => {
      return hasReadyChildrenPI(child, orgUnits);
    });
  } else {
    return false;
  }
};

const getChildren = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
) => {
  return orgUnits.filter((child) => {
    return child.parentId === orgUnit.id;
  });
};

const hasReadyChildren = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  riskLevels: RiskLevel[],
): boolean => {
  if (isReadyOrgUnit(orgUnit, orgUnits, riskLevels)) {
    return true;
  }

  const hasChildren = orgUnits.filter((child) => {
    return child.parentId === orgUnit.id;
  });

  if (hasChildren) {
    return hasChildren.some((child) => {
      return hasReadyChildren(child, orgUnits, riskLevels);
    });
  } else {
    return false;
  }
};

const hasReadyParents = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
  riskLevels: RiskLevel[],
): boolean => {
  if (orgUnit.parentId === null) {
    return false;
  }

  const parent = orgUnits.find((loopedOrgUnit) => {
    return loopedOrgUnit.id === orgUnit.parentId;
  });

  if (parent) {
    return (
      isReadyOrgUnit(parent, orgUnits, riskLevels) ||
      hasReadyParents(parent, orgUnits, riskLevels)
    );
  }
  return false;
};

const hasReadyParentsPI = (
  orgUnit: OrgChartDataPairWithCatalogs,
  orgUnits: OrgChartDataPairWithCatalogs[],
): boolean => {
  if (orgUnit.parentId === null) {
    return false;
  }

  const parent = orgUnits.find((loopedOrgUnit) => {
    return loopedOrgUnit.id === orgUnit.parentId;
  });

  if (parent) {
    return (
      isReadyOrgUnitPI(parent, orgUnits) || hasReadyParentsPI(parent, orgUnits)
    );
  }
  return false;
};

const parseCurrentOrgLevels = (
  orgChart: any[],
  computedCurrentLocation: any | undefined,
  currentRiskLevels: RiskLevel[] | undefined,
  localStorageIdentifier: string,
): string[] => {
  const currentDeviceOrgLevels = window.localStorage.getItem(
    localStorageIdentifier,
  );

  if (currentDeviceOrgLevels !== null) {
    const currentOrgLevels: string[] = [];
    const parsedCurrentOrgLevels = JSON.parse(currentDeviceOrgLevels);

    let hasValidPath = parsedCurrentOrgLevels.every((orgLevel: string) => {
      if (orgLevel === 'all') return true;
      const foundOrgUnit = orgChart.find(
        (orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.id === orgLevel;
        },
      );

      if (typeof foundOrgUnit === 'undefined') return false;

      return true;
    });

    if (hasValidPath) {
      const lastOrgUnitId = parsedCurrentOrgLevels.slice(0).pop();
      const lastOrgUnit = orgChart.find(
        (orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.id === lastOrgUnitId;
        },
      );
      if (typeof lastOrgUnit === 'undefined' && lastOrgUnitId !== 'all') {
        hasValidPath = false;
      } else if (typeof lastOrgUnit !== 'undefined') {
        switch (localStorageIdentifier) {
          case 'currentOrgLevelsFES':
            if (typeof currentRiskLevels !== 'undefined') {
              hasValidPath = isReadyOrgUnit(
                lastOrgUnit,
                orgChart,
                currentRiskLevels,
              );
            }
            break;
          case 'currentOrgLevelsPI':
            hasValidPath = isReadyOrgUnitPI(lastOrgUnit, orgChart);
        }
      }
    }

    if (hasValidPath) {
      parsedCurrentOrgLevels.forEach((orgLevel) => {
        currentOrgLevels.push(orgLevel);
      });

      if (computedCurrentLocation) {
        const currentLocation =
          computedCurrentLocation as OrgChartDataPairWithCatalogs;

        switch (localStorageIdentifier) {
          case 'currentOrgLevelsFES': {
            if (
              currentRiskLevels &&
              !isReadyOrgUnit(currentLocation, orgChart, currentRiskLevels)
            ) {
              return [orgChart[0].id];
            } else {
              return parsedCurrentOrgLevels;
            }
          }
          case 'currentOrgLevelsPI': {
            if (!isReadyOrgUnitPI(currentLocation, orgChart)) {
              return [orgChart[0].id];
            } else {
              return parsedCurrentOrgLevels;
            }
          }
          case 'currentOrgLevelsOPL': {
            return parsedCurrentOrgLevels;
          }
        }
      } else {
        return parsedCurrentOrgLevels;
      }
    } else {
      window.localStorage.removeItem(localStorageIdentifier);
    }
  }
  return [''];
};

function getFullOrgUnitPath(
  orgUnit: OrgChartDataPairWithCatalogs,
  orgChart: OrgChartDataPairWithCatalogs[],
) {
  const path = getNodePath(orgUnit, orgChart);
  return path
    .map((orgUnit: OrgChartDataPairWithCatalogs) => {
      return orgUnit.name;
    })
    .join(' > ');
}

export {
  computedDate,
  computedTime,
  computedAgo,
  openSideDrawer,
  closeSideDrawer,
  calculateDepth,
  getNearestCatalog,
  getNearestRiskLevelCatalogs,
  isReadyOrgUnit,
  isReadyOrgUnitPI,
  getNodePath,
  getPath,
  hasReadyChildren,
  hasReadyChildrenPI,
  hasReadyParents,
  hasReadyParentsPI,
  parseCurrentOrgLevels,
  getFullOrgUnitPath,
  getChildren,
};
