<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <div class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ computedTitle }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ computedErrorCases.length }} /
                {{ data.errorCases.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Actions-->
            <!--begin::Group actions-->
            <div
              v-if="data.checkedElements.length"
              class="d-flex justify-content-end align-items-center"
              data-kt-subscription-table-toolbar="selected"
            >
              <div class="fw-bolder me-5">
                <span
                  class="me-2"
                  data-kt-subscription-table-select="selected_count"
                  >{{ data.checkedElements.length }}</span
                >
                {{ $t('selected') }}
              </div>

              <button
                v-if="computedCurrentUserIsAdmin"
                type="button"
                class="btn btn-danger btn-sm me-3"
                @click="deleteSelected"
              >
                {{ $t('remove') }}
              </button>
            </div>
            <!--end::Actions-->
            <barcode-filter />

            <router-link
              to="/fes/start"
              class="btn btn-sm btn-primary align-self-center ms-3"
              ><i class="fa fa-plus-square"></i>
              {{ $t('fes.form.new') }}
            </router-link>
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <div v-if="0" class="filters d-flex">
            <div class="mb-5 me-3">
              <label class="form-label fw-bold"
                >{{ $t('fes.form.moreDetails.riskLevel') }}:</label
              >
              <div>
                <el-select
                  class="form-select-solid w-100"
                  placeholder="Select option"
                  v-model="data.filterConfig.riskLevel"
                >
                  <el-option :label="$t('none')" :value="-1">
                    {{ $t('pleaseSelect') }}
                  </el-option>
                  <el-option
                    v-for="riskLevel of data.riskLevels"
                    :key="riskLevel.level"
                    :label="`${$t('fes.form.moreDetails.riskLevel')} ${
                      riskLevel.level
                    }`"
                    :value="riskLevel.level"
                  >
                    {{ $t('fes.form.moreDetails.riskLevel') }}
                    <risk-level-badge :riskLevel="riskLevel.level" />
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="mb-5 min-w-500px">
              <label class="form-label fw-bold"
                >{{ $t('fes.overview.location') }}:</label
              >
              <multiselect
                mode="single"
                v-model="data.filterConfig.orgUnitId"
                :options="data.orgChart"
                :searchable="true"
                :create-option="false"
                valueProp="id"
                label="name"
              >
                <template v-slot:singlelabel="{ value }">
                  <div
                    class="multiselect-single-label"
                    :title="getFullOrgUnitPath(value, data.orgChart)"
                  >
                    {{ value.name }}
                  </div>
                </template>
                <template v-slot:option="{ option }">
                  {{ getFullOrgUnitPath(option, data.orgChart) }}
                </template>
              </multiselect>
            </div>
          </div>

          <KTDatatable
            @click:row="handleErrorCaseClick"
            @click-middle:row="handleErrorCaseClickNewWindow"
            :key="computedErrorCases.length"
            v-if="data.isLoaded === true"
            :table-data="computedErrorCases"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="true"
            :tableFilterConfig="tableFilterConfig"
          >
            <template v-slot:cell-checkbox="{ row: errorCase }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="errorCase.id"
                  v-model="data.checkedElements"
                />
              </div>
            </template>
            <template v-slot:cell-faufReference="{ row: errorCase }">
              {{ errorCase.detail.faufReference }}

              <el-tooltip
                v-if="computedIsLockedByOtherUser(errorCase)"
                :content="$t('fes.overview.lockedTooltip')"
              >
                <i class="fa fa-lock"></i>
              </el-tooltip>
            </template>
            <template v-slot:cell-materialReference="{ row: errorCase }">
              {{ errorCase.detail.materialReference }}
            </template>
            <template v-slot:cell-productCategory="{ row: errorCase }">
              {{ errorCase.productCategory?.name }}
            </template>
            <template v-slot:cell-productSubCategory="{ row: errorCase }">
              {{ errorCase.productSubCategory?.name }}
            </template>
            <template v-slot:cell-errorCaseNumber="{ row: errorCase }">
              {{ errorCase.id }}
            </template>
            <template v-slot:cell-barcodes="{ row: errorCase }">
              <div v-if="errorCase.barcodes.length > 0">
                <span
                  class="badge badge-light"
                  v-for="barcode of errorCase.barcodes"
                  :key="barcode"
                  >{{ barcode.code }}</span
                >
              </div>

              <span class="badge badge-light" v-else>{{ $t('none') }}</span>
            </template>
            <template v-slot:cell-locationRisk="{ row: errorCase }">
              <risk-level-badge :riskLevel="errorCase.locationRisk.riskValue" />
            </template>
            <template v-slot:cell-name="{ row: errorCase }">
              <div
                class="fw-bolder fs-5"
                v-if="errorCase.detail.materialName"
                :title="$t('fes.form.details.materialName')"
              >
                {{ errorCase.detail.materialName }}
              </div>
              <div class="fs-6">
                <span
                  :title="$t('fes.form.details.materialReference')"
                  class="me-3 text-nowrap"
                >
                  <i class="bi bi-layers-fill"></i>
                  {{ errorCase.detail.materialReference }}
                </span>
                <span
                  class="text-nowrap"
                  :title="$t('fes.form.details.faufReference')"
                >
                  <i class="bi bi-file-binary-fill"></i>
                  {{ errorCase.detail.faufReference }}</span
                >
              </div>
              <div
                class="fs-7"
                v-if="
                  errorCase.detail.checkReference ||
                  errorCase.detail.sapReference
                "
              >
                <span
                  class="me-3"
                  v-if="errorCase.detail.checkReference"
                  :title="$t('fes.form.details.checkReference')"
                >
                  <i class="bi bi-file-earmark-binary"></i>
                  {{ errorCase.detail.checkReference }}
                </span>
                <span
                  v-if="errorCase.detail.sapReference"
                  :title="$t('fes.form.details.sapReference')"
                >
                  <span class="svg-icon svg-icon-1">
                    <inline-svg src="media/icons/sap.svg" />
                  </span>
                  {{ errorCase.detail.sapReference }}</span
                >
              </div>
            </template>
            <template v-slot:cell-amounts="{ row: errorCase }">
              <span
                :title="`${$t('fes.form.details.amountTotal')} / ${$t(
                  'fes.form.details.amountChecked',
                )} / ${$t('fes.form.details.amountFlawed')}`"
                >{{ errorCase.detail.amountTotal }}/{{
                  errorCase.detail.amountChecked
                }}/{{ errorCase.detail.amountFlawed }}</span
              ><br />
              <small>{{ errorCase.amountFlawedPercentage }}%</small>
            </template>
            <template v-slot:cell-location="{ row: errorCase }">
              <div v-if="errorCase.locationText">
                <div :title="errorCase.locationText">
                  <span>{{ errorCase.locationName }}</span
                  ><br />
                  <small>{{ errorCase.locationAreaName }}</small>
                </div>
              </div>
              <span v-else class="badge badge-light">{{ $t('unknown') }}</span>
            </template>
            <template v-slot:cell-errorLocation="{ row: errorCase }">
              <span v-if="errorCase.locationRisk.place">{{
                errorCase.locationRisk.place.name
              }}</span>
              <span v-else>{{ $t('unknown') }}</span>
              /
              <span v-if="errorCase.locationRisk.style">{{
                errorCase.locationRisk.style.name
              }}</span>
              <span v-else>{{ $t('unknown') }}</span>
            </template>
            <template v-slot:cell-processInterruption="{ row: errorCase }">
              <div v-if="errorCase.processInterruption !== null">
                <span>{{ errorCase.processInterruption.category.name }}</span
                ><br />
                <small
                  >{{ $t('fes.form.processInterruption.downtimeMachine') }}:
                  {{ errorCase.processInterruption.downtimeMachine }}
                  {{ $t('minutes') }}</small
                ><br />
                <small
                  >{{ $t('fes.form.processInterruption.downtimeWorker') }}:
                  {{ errorCase.processInterruption.downtimeWorker }}
                  {{ $t('minutes') }}</small
                >
              </div>
              <span class="badge badge-light" v-else>{{ $t('none') }}</span>
            </template>
            <template v-slot:cell-decision="{ row: errorCase }">
              <div
                v-if="errorCase.decision !== null"
                class="d-flex flex-column"
              >
                <span>{{ errorCase.decision.name }}</span>
                <small
                  >{{ $t('fes.form.causes.cause') }}:
                  <span>{{ errorCase.cause.name }}</span></small
                >
                <small v-if="errorCase.causeReason"
                  >{{ $t('fes.form.causes.causeReason') }}:
                  <span>{{ errorCase.causeReason.name }}</span></small
                >
              </div>
              <span
                class="badge badge-light-primary"
                v-if="errorCase.splitDecisionsAmount > 0"
                >{{ errorCase.splitDecisionsAmount }}
                {{ $t('fes.config.decisions') }}</span
              >
              <span
                class="badge badge-primary"
                v-if="computedErrorCaseNeedsDecision(errorCase)"
                >{{ $t('fes.overview.states.open') }}</span
              >
              <span
                class="badge badge-light"
                v-if="!errorCase.needsDecision && errorCase.decision === null"
                >{{ $t('fes.overview.states.notNeeded') }}</span
              >
            </template>
            <template v-slot:cell-progress="{ row: errorCase }">
              <div v-if="errorCase.decision !== null">
                <el-progress :percentage="errorCase.doneOpenPointsPercentage">
                  <span
                    >{{ errorCase.openPointsDone }} /
                    {{ errorCase.openPointsCount }}</span
                  >
                </el-progress>
                <el-progress
                  :stroke-width="4"
                  :percentage="errorCase.doneOpenPointsPercentage"
                  color="#f1416c"
                >
                  <span class="fs-8"
                    >{{ errorCase.doneOpenPointsPercentage }} %</span
                  >
                </el-progress>
              </div>
              <template v-else>
                <span
                  class="badge badge-light"
                  v-if="!errorCase.needsDecision"
                  >{{ $t('fes.overview.states.notNeeded') }}</span
                >
                <span v-else class="badge badge-primary">{{
                  $t('fes.overview.states.open')
                }}</span>
              </template>
            </template>
            <template v-slot:cell-creator="{ row: errorCase }">
              <span>{{ errorCase.reporterUser?.name }}</span>
            </template>
            <template v-slot:cell-createdAt="{ row: errorCase }">
              {{ computedDate(errorCase.createdAt) }}
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import KTDatatable, {
    IHeaderConfiguration,
    TableFilterItem,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { computedDate, getNodePath } from '@/core/helpers/cdf';
  import {
    ErrorCase,
    ErrorCaseFilterConfig,
    ErrorCaseLocation,
    ErrorCaseWithProgress,
    FESConfig,
    OrgChartDataPairWithCatalogs,
    RiskLevel,
  } from '@/core/models/fes';
  import moment from 'moment';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n';
  import RiskLevelBadge from '@/components/fes/RiskLevelBadge.vue';
  import BarcodeFilter from '@/components/fes/BarcodeFilter.vue';
  import Multiselect from '@vueform/multiselect';
  import { getFullOrgUnitPath } from '@/core/helpers/cdf';
  import { useRouter } from 'vue-router';
  import { User } from '@/store/modules/AuthModule';
  import * as _ from 'lodash';

  export default defineComponent({
    name: 'fesErrorCaseOverview',
    components: {
      KTDatatable,
      RiskLevelBadge,
      Multiselect,
      BarcodeFilter,
    },
    props: {
      title: {
        type: String,
        required: false,
      },
      url: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      const store = useStore();
      const router = useRouter();
      const { t } = useI18n();
      // onMounted(() => {});

      const data: {
        errorCases: ErrorCase[];
        headerConfig: IHeaderConfiguration[];
        isLoaded: boolean;
        checkedElements: number[];
        riskLevels: RiskLevel[];
        filterConfig: ErrorCaseFilterConfig;
        orgChart: OrgChartDataPairWithCatalogs[];
      } = reactive({
        errorCases: [],
        headerConfig: [
          {
            name: t('fes.overview.risk'),
            key: 'locationRisk',
            sortable: true,
            sortingField: 'plainRiskLevel',
          } as IHeaderConfiguration,
          {
            name: t('fes.form.details.faufReferenceShort'),
            key: 'faufReference',
            sortable: true,
            sortingField: 'detail.faufReference',
          },
          {
            name: t('fes.form.details.materialReference'),
            key: 'materialReference',
            sortable: true,
            sortingField: 'detail.materialReference',
          },
          // {
          //   name: t('fes.form.details.errorCaseNumber'),
          //   key: 'errorCaseNumber',
          //   sortable: true,
          //   sortingField: 'id',
          // },
          {
            name: t('fes.form.barcodes'),
            key: 'barcodes',
            sortable: false,
          },
          {
            name: t('fes.overview.amounts'),
            key: 'amounts',
            sortable: true,
            sortingField: 'amountFlawedPercentage',
          },
          {
            name: t('fes.overview.location'),
            key: 'location',
            sortable: false,
          },
          {
            name: t('fes.overview.decision'),
            key: 'decision',
            sortable: true,
            sortingField: 'hasDecision',
          },
          // {
          //   name: t('fes.overview.details'),
          //   key: 'name',
          //   sortable: false,
          // },
          // {
          //   name: t('fes.overview.errorLocation'),
          //   key: 'errorLocation',
          //   sortable: false,
          // },
          // {
          //   name: t('fes.overview.processInterruption'),
          //   key: 'processInterruption',
          //   sortable: true,
          //   sortingField: 'totalProcessInterruptionMinutes',
          // },
          // {
          //   name: t('fes.overview.progress'),
          //   key: 'progress',
          //   sortable: true,
          //   sortingField: 'doneOpenPointsPercentage',
          // },
          {
            name: t('fes.form.moreDetails.productCategoryShort'),
            key: 'productCategory',
            sortable: true,
          },
          {
            name: t('fes.form.moreDetails.productSubCategoryShort'),
            key: 'productSubCategory',
            sortable: true,
          },
          {
            name: t('opl.overview.creatorFES'),
            key: 'creator',
            sortable: true,
          },
          {
            name: t('createdAt'),
            key: 'createdAt',
            sortable: true,
          },
        ],
        isLoaded: false,
        checkedElements: [],
        riskLevels: [],
        filterConfig: {
          riskLevel: -1,
          orgUnitId: null,
        },
        orgChart: [],
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };

        if (Object.keys(currentUser).length === 0) {
          store.dispatch(Actions.REDIRECT_TO_SIGNIN);
          return false;
        }

        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  await ApiService.delete(`/fes/errors/${id}`);
                }),
              );

              data.errorCases = data.errorCases.filter(
                (errorCase: ErrorCase) => {
                  return (
                    errorCase.id && !data.checkedElements.includes(errorCase.id)
                  );
                },
              );

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedErrorCases = computed(() => {
        return data.errorCases
          .slice(0)
          .filter((errorCase: ErrorCase) => {
            if (data.filterConfig.riskLevel !== -1) {
              return (
                errorCase.locationRisk.riskValue === data.filterConfig.riskLevel
              );
            }

            return true;
          })
          .filter((errorCase: ErrorCase) => {
            if (data.filterConfig.orgUnitId !== null) {
              return errorCase.locations.some((location: ErrorCaseLocation) => {
                return location.locationId === data.filterConfig.orgUnitId;
              });
            }
            return true;
          });
      });

      const computedOrgUnitPath = computed(() => {
        return (orgUnit: OrgChartDataPairWithCatalogs) => {
          const path = getNodePath(orgUnit, data.orgChart);
          return path
            .map((orgUnit: OrgChartDataPairWithCatalogs) => {
              return orgUnit.name;
            })
            .join(' > ');
        };
      });

      const computedOrgUnitPathById = computed(() => {
        return (orgUnitId: string) => {
          const orgUnit = data.orgChart.find(
            (loopedOrgUnit: OrgChartDataPairWithCatalogs) => {
              return loopedOrgUnit.id === orgUnitId;
            },
          );

          if (!orgUnit) return 'Unknown';
          return computedOrgUnitPath.value(orgUnit);
        };
      });

      const computedIsLockedByOtherUser = computed(() => {
        return (errorCase: ErrorCase) => {
          if (errorCase.blockedUserId === null) return false;
          if (errorCase.blockedUserId === store.getters.currentUser.id)
            return false;
          return true;
        };
      });

      const computedErrorCaseNeedsDecision = computed(() => {
        return (errorCase: any) => {
          if (errorCase.needsDecision === null) return false;

          if (errorCase.decision !== null) return false;

          if (
            parseInt(errorCase.splitDecisionsAmount) > 0 &&
            parseInt(errorCase.detail.amountFlawed) ===
              parseInt(errorCase.splitDecisionPoints)
          ) {
            return false;
          }

          return true;
        };
      });

      const handleErrorCaseClick = (errorCase) => {
        router.push(`/fes/overview/${errorCase.id}`);
      };

      const handleErrorCaseClickNewWindow = (errorCase) => {
        // router.push(`/fes/overview/${errorCase.id}`);
        const routeData = router.resolve(`/fes/overview/${errorCase.id}`);
        window.open(routeData.href, '_blank');
      };

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);

        data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);
        const fesConfig: FESConfig = await store.dispatch(
          Actions.GET_FES_CONFIG,
        );
        data.riskLevels = fesConfig.riskLevels;

        ApiService.get(props.url || '/fes/errors').then((response) => {
          data.errorCases = response.data.map(
            (errorCase: ErrorCaseWithProgress) => {
              const parsedError: any = Object.assign({}, errorCase);
              parsedError.plainRiskLevel = errorCase.locationRisk.riskValue;
              parsedError.plainCreatedTime = moment(errorCase.createdAt).unix();
              parsedError.amountFlawedPercentage = Math.round(
                (errorCase.detail.amountFlawed /
                  errorCase.detail.amountChecked) *
                  100,
              );
              parsedError.hasDecision = errorCase.decision !== null ? 1 : 0;

              if (parsedError.hasDecision) {
                parsedError.doneOpenPointsPercentage = 0;
                if (
                  errorCase.openPointsDone !== null &&
                  errorCase.openPointsCount !== null
                ) {
                  parsedError.doneOpenPointsPercentage = Math.round(
                    (errorCase.openPointsDone / errorCase.openPointsCount) *
                      100,
                  );
                }
              } else {
                parsedError.doneOpenPointsPercentage = -1;
              }

              parsedError.needsDecision =
                data.riskLevels.filter((riskLevel: RiskLevel) => {
                  return (
                    riskLevel.needsDecision === true &&
                    riskLevel.level === errorCase.locationRisk.riskValue
                  );
                }).length > 0;

              errorCase.locations = errorCase.locations.sort((a, b) => {
                return a.pos - b.pos;
              });

              if (errorCase.locations.length > 0) {
                parsedError.locationText = errorCase.locations
                  .map((location: ErrorCaseLocation) => {
                    return location.locationName;
                  })
                  .join(' > ');
                const lastLocation =
                  parsedError.locations.find((location) => {
                    return location.locationIdentifier === 'Bereich';
                  }) || parsedError.locations[parsedError.locations.length - 1];
                parsedError.locationName = lastLocation.locationName;
                parsedError.locationAreaName = lastLocation.locationIdentifier;
              }

              return parsedError;
            },
          );
          data.isLoaded = true;
          store.dispatch(Actions.END_LOADER);
        });
      });

      const computedTitle = computed(() => {
        return props.title || t('fes.overview.all');
      });

      const tableFilterConfig = computed((): TableFilterItem[] => {
        return [
          {
            filterKey: 'locationRisk',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.locationRisk.riskValue;
            },
            values: _.uniq(
              computedErrorCases.value
                .map((errorCase) => {
                  return errorCase.locationRisk.riskValue;
                })
                .sort((a, b) => {
                  return a - b;
                }),
            ),
            type: 'select',
          },
          {
            filterKey: 'faufReference',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.detail.faufReference;
            },
            type: 'text',
          },
          {
            filterKey: 'materialReference',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.detail.materialReference;
            },
            type: 'text',
          },
          {
            filterKey: 'productCategory',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.productCategory?.name;
            },
            type: 'text',
          },
          {
            filterKey: 'productSubCategory',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.productSubCategory?.name;
            },
            type: 'text',
          },
          {
            filterKey: 'decision',
            filterValue: (errorCase: ErrorCase) => {
              if (errorCase.decision === null) {
                if (errorCase['needsDecision'] && errorCase.decision === null)
                  return t('fes.overview.states.open');
                else return t('fes.overview.states.notNeeded');
              }
              return [
                errorCase.decision?.name || '',
                errorCase.cause?.name || '',
                errorCase.causeReason?.name || '',
              ].join(' ');
            },
            type: 'text',
          },
          {
            filterKey: 'createdAt',
            filterValue: (errorCase: ErrorCase) => {
              return String(
                moment(errorCase.createdAt).format(t('dateFormat')),
              );
            },
            type: 'text',
          },
          {
            filterKey: 'creator',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.reporterUser?.name;
            },
            type: 'text',
          },
          {
            filterKey: 'progress',
            filterValue: (errorCase: any) => {
              if (errorCase.decision === null) {
                if (errorCase.needsDecision && errorCase.decision === null)
                  return t('fes.overview.states.open');
                else return t('fes.overview.states.notNeeded');
              }
              return `${errorCase.openPointsDone}/${errorCase.openPointsCount} ${errorCase.doneOpenPointsPercentage}%`;
            },
            type: 'text',
          },
          {
            filterKey: 'barcodes',
            filterValue: (errorCase: ErrorCase) => {
              return errorCase.barcodes
                .map((barcode) => {
                  return barcode.code;
                })
                .join(';');
            },
            type: 'text',
          },
          {
            filterKey: 'amounts',
            filterValue: (errorCase: ErrorCase) => {
              return `${errorCase.detail.amountTotal}/${errorCase.detail.amountChecked}/${errorCase.detail.amountFlawed}`;
            },
            type: 'text',
          },
          {
            filterKey: 'location',
            filterValue: (errorCase: ErrorCase) => {
              const parsedErrorCase: any = errorCase;
              return `${parsedErrorCase.locationName}/${parsedErrorCase.locationAreaName}`;
            },
            type: 'text',
          },
        ];
      });

      return {
        data,
        computedDate,
        computedTitle,
        // computedTime,
        deleteSelected,
        computedErrorCases,
        computedOrgUnitPath,
        computedOrgUnitPathById,
        getFullOrgUnitPath,
        handleErrorCaseClick,
        handleErrorCaseClickNewWindow,
        computedCurrentUserIsAdmin,
        computedIsLockedByOtherUser,
        computedErrorCaseNeedsDecision,
        tableFilterConfig,
      };
    },
  });
</script>
