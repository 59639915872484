import {
  FES_DECIDER_ROLES,
  isProcessInterruptionsEnabled,
} from '../services/ApiService';

type PageEntry = {
  heading: string;
  route: string;
  svgIcon: string;
  allowedRoles?: string[];
};

const fesStartRoutes = {
  route: '/fes',
  heading: 'fes.nav.collection',
  pages: [
    {
      heading: 'fes.nav.start',
      route: '/fes/start',
      svgIcon: 'media/icons/duotune/ecommerce/ecm010.svg',
    },
  ] as PageEntry[],
};

const fesOverviewRoutes = {
  route: '/fes',
  heading: 'fes.nav.overview',
  pages: [
    {
      heading: 'fes.nav.start',
      route: '/fes/overview',
      svgIcon: 'media/icons/duotune/coding/cod009.svg',
    },
  ] as PageEntry[],
};

if (isProcessInterruptionsEnabled()) {
  fesStartRoutes.pages.push({
    heading: 'fes.nav.startPi',
    route: '/fes/processinterruptions/start',
    svgIcon: 'media/icons/duotune/ecommerce/ecm010.svg',
  });
  fesOverviewRoutes.pages.push({
    heading: 'fes.nav.processInterruptions',
    route: '/fes/processinterruptions/overview',
    svgIcon: 'media/icons/duotune/communication/com004.svg',
  });
}

fesOverviewRoutes.pages.push({
  heading: 'opl.form.plural',
  route: '/fes/opl/overview',
  svgIcon: 'media/icons/duotune/communication/com005.svg',
  allowedRoles: [
    'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
    ...FES_DECIDER_ROLES,
  ],
});

const FESMenuConfig = [
  {
    route: '/fes',
    pages: [
      {
        heading: 'dashboard',
        route: '/fes/dashboard',
        svgIcon: 'media/icons/duotune/general/gen001.svg',
        allowedRoles: [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          ...FES_DECIDER_ROLES,
        ],
      },
    ],
  },
  fesStartRoutes,
  fesOverviewRoutes,
  {
    route: '/fes',
    heading: 'administration',
    pages: [
      {
        heading: 'configuration',
        route: '/fes/config',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        allowedRoles: ['Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local'],
      },
    ],
    allowedRoles: ['Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local'],
  },
];

export default FESMenuConfig;
