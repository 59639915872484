<template>
  <!--begin::Aside-->
  <div
    id="kt_aside"
    class="aside aside-hoverable"
    :class="[
      asideTheme === 'light' && 'aside-light',
      asideTheme === 'dark' && 'aside-dark',
    ]"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <!--begin::Logo-->
      <img alt="Logo" :src="lightLogo" class="h-40px logo" />
      <!--end::Logo-->
    </div>
    <!--end::Brand-->

    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <KTMenu></KTMenu>
    </div>
    <!--end::Aside menu-->

    <!--begin::Footer-->
    <div
      class="aside-footer flex-column-auto pt-5 pb-5 px-2"
      id="kt_aside_footer"
    >
      <div class="menu-item" v-if="0">
        <div class="menu-content">
          <div class="alert alert-warning d-flex align-items-center p-5 mb-5">
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-warning">Achtung Vorabversion</h4>
              <span
                >Es handelt sich bei dieser Software um eine Vorabversion. Alle
                Daten können durch ein Update zu jeder Zeit gelöscht
                werden.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="menu-item">
        <div class="menu-content">
          <span class="menu-title fs-9"
            >{{ $t('clientVersion') }} {{ appVersion }}</span
          >
        </div>
      </div>
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Aside-->
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import KTMenu from '@/layout/aside/Menu.vue';
  import { asideTheme } from '@/core/helpers/config';
  import { appVersion } from '@/core/helpers/config';

  export default defineComponent({
    name: 'KTAside',
    components: {
      KTMenu,
    },
    props: {
      lightLogo: String,
      darkLogo: String,
    },
    setup() {
      const { t } = useI18n();

      return {
        asideTheme,
        appVersion,
        t,
      };
    },
  });
</script>
