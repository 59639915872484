import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_oplOpenPointOverview = _resolveComponent("oplOpenPointOverview")!

  return (_openBlock(), _createBlock(_component_oplOpenPointOverview, {
    title: _ctx.$t('opl.openOpenPoints'),
    onlyAllowed: true,
    onlyOpen: true
  }, null, 8, ["title"]))
}