enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  START_LOADER = 'startLoader',
  END_LOADER = 'endLoader',
  REDIRECT_TO_SIGNIN = 'redirectToSignIn',
  GET_ORG_CHART = 'getOrgChart',
  GET_FES_CONFIG = 'getFesConfig',
  GET_OPL_CONFIG = 'getOplConfig',
  GET_QUESTION = 'getQuestion',
  GET_MANAGEMENT_LEVEL = 'getManagementLevel',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_MANAGEMENT_LEVEL = 'setManagementLevel',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  SET_LANGUAGE = 'setLanguage',
  SET_APP = 'setApp',
  SET_ERRORLOCATION = 'setErrorLocation',
  ADD_LOADER = 'addLoader',
  REMOVE_LOADER = 'removeLoader',
  SET_ORG_CHART = 'setOrgChart',
  SET_FES_CONFIG = 'setFesConfig',
  SET_FES_RISK_LEVELS = 'setFesRiskLevels',
  SET_OPL_CONFIG = 'setOplConfig',
  SET_QUESTION = 'setQuestion',
}

export { Actions, Mutations };
