<template>
  <div class="card-body py-3">
    <div v-if="!data.isEdit">
      <div class="row mb-3">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.causes.cause')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div></div>
              <div>
                <span-with-deleted-state
                  :isDeleted="data.errorCase.cause.isDeleted"
                  >{{ data.errorCase.cause.name }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="data.errorCase.causeReason">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.causes.causeReason')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div>
                <span-with-deleted-state
                  :isDeleted="data.errorCase.causeReason.isDeleted"
                  >{{
                    data.errorCase.causeReason.name
                  }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <label class="col-form-label fw-bold fs-6">{{
                $t('fes.form.decision.decision')
              }}</label>
            </div>
            <div class="col-8 d-flex align-items-center pt-3">
              <div>
                <span-with-deleted-state
                  :isDeleted="data.errorCase.decision.isDeleted"
                  >{{ data.errorCase.decision.name }}</span-with-deleted-state
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        @click="startDecisionEdit"
        v-if="computedDecisionReadyForEdit"
        class="btn btn-secondary btn-sm"
        :disabled="computedDecisionBlockedDueDelete"
      >
        {{ $t('edit') }}
      </button>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-3">
          <label class="col-form-label required fw-bold fs-6">{{
            $t('fes.form.causes.cause')
          }}</label>
        </div>
        <div class="col-9 d-flex align-items-start flex-column">
          <Field
            as="select"
            name="cause"
            class="form-select form-select-solid form-select-lg"
            v-model="data.currentEdit.cause.id"
          >
            <option value="" selected>
              {{ $t('pleaseSelect') }}
            </option>
            <option
              v-for="errorCaseCause in computedAvailableErrorCaseCauses"
              :key="errorCaseCause.id"
              :value="errorCaseCause.id"
            >
              {{ errorCaseCause.name }}
            </option>
          </Field>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="cause" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label class="col-form-label required fw-bold fs-6">{{
            $t('fes.form.causes.causeReason')
          }}</label>
        </div>
        <div class="col-9 d-flex align-items-start flex-column">
          <Field
            as="select"
            name="causeReason"
            class="form-select form-select-solid form-select-lg"
            v-model="data.currentEdit.causeReason.id"
          >
            <option value="" selected>
              {{ $t('pleaseSelect') }}
            </option>
            <option
              v-for="errorCaseCauseReason in computedAvailableErrorCaseCauseReasons"
              :key="errorCaseCauseReason.id"
              :value="errorCaseCauseReason.id"
            >
              {{ errorCaseCauseReason.name }}
            </option>
          </Field>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="causeReason" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <label class="col-form-label required fw-bold fs-6">{{
            $t('fes.form.decision.decision')
          }}</label>
        </div>
        <div class="col-9 d-flex align-items-start flex-column">
          <Field
            as="select"
            name="decision"
            class="form-select form-select-solid form-select-lg"
            v-model="data.currentEdit.decision.id"
          >
            <option value="" selected>
              {{ $t('pleaseSelect') }}
            </option>
            <option
              v-for="errorCaseDecision in computedAvailableErrorCaseDecisions"
              :key="errorCaseDecision.id"
              :value="errorCaseDecision.id"
            >
              {{ errorCaseDecision.name }}
            </option>
          </Field>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="decision" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-start">
          <span class="badge badge-light-primary">
            <i class="bi bi-person-check me-2"></i>
            {{ data.errorCase.decider.name }} ({{
              data.errorCase.decider.username
            }})
          </span>
        </div>
        <div class="col-6 text-end">
          <button
            @click="cancelDecisionEdit"
            class="btn btn-secondary btn-sm me-1"
            type="button"
          >
            {{ $t('cancel') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="handleDecisionUpdate"
            v-if="computedDecisionReadyForEdit"
            type="button"
          >
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { getNearestCatalog } from '@/core/helpers/cdf';
  import { Field } from 'vee-validate';
  import {
    CauseCatalog,
    CauseReasonCatalog,
    DecisionCatalog,
    ErrorCase,
    ErrorCaseCause,
    ErrorCaseCauseReason,
    ErrorCaseDecision,
    ErrorCaseDecisionUpdateDto,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/fes';
  import { User } from '@/store/modules/AuthModule';
  import {
    computed,
    ComputedRef,
    defineComponent,
    PropType,
    reactive,
  } from 'vue';
  import { useStore } from 'vuex';
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import { AxiosResponse } from 'axios';
  import Swal from 'sweetalert2';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'DecisionSingleDeprecated',
    components: {
      Field,
    },
    props: {
      errorCase: {
        type: Object as PropType<ErrorCase>,
        required: true,
      },
      orgChart: {
        type: Object as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      orgLevels: {
        type: Array as PropType<string[]>,
        required: true,
      },
      availableOrgLevels: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data = reactive({
        isEdit: false,
        errorCase: props.errorCase as ErrorCase,
        currentEdit: {
          cause: props.errorCase.cause,
          causeReason: props.errorCase.causeReason,
          decision: props.errorCase.decision,
        },
      });

      const startDecisionEdit = () => {
        data.isEdit = true;
      };

      const cancelDecisionEdit = () => {
        data.isEdit = false;
      };

      const computedCurrentUser: ComputedRef<User> = computed(() => {
        return store.getters.currentUser;
      });

      const computedCurrentUserIsCurrentDecider = computed(() => {
        if (data.errorCase === null) return false;
        if (data.errorCase.decision === null) return false;
        if (data.errorCase.decider === null) return false;
        return computedCurrentUser.value.id === data.errorCase.decider.userId;
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      const computedDecisionReadyForEdit = computed(() => {
        if (
          computedCurrentUserIsCurrentDecider.value === true ||
          computedCurrentUserIsAdmin.value === true
        ) {
          if (typeof data.errorCase?.decider?.id !== 'undefined') {
            return true;
          }
        }
        return false;
      });

      const selectedOrgLevels = computed(() => {
        return props.orgLevels
          .map((orgLevelId, index) => {
            const currentOptions = computedOrgOptions(index);

            return currentOptions.find((orgOption) => {
              return orgLevelId === orgOption.id;
            });
          })
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          });
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(props.availableOrgLevels);
        const rawDataParsed = JSON.parse(rawData);
        return rawDataParsed;
      };

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return props.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const computedCurrentLocation = computed(() => {
        if (data.errorCase !== null && data.errorCase.locations.length > 0) {
          const lastLocation = data.errorCase.locations.slice(0).pop();
          if (lastLocation) {
            return props.orgChart.find(
              (orgUnit: OrgChartDataPairWithCatalogs) => {
                return lastLocation.locationId === orgUnit.id;
              },
            );
          }
        }
        return selectedOrgLevels.value
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          })
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      const computedAvailableErrorCaseCauses = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeCatalog: CauseCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'causeCatalog',
        );

        if (causeCatalog !== null) {
          return causeCatalog.causes.sort(
            (causeA: ErrorCaseCause, causeB: ErrorCaseCause) => {
              if (causeA.name < causeB.name) {
                return -1;
              }
              if (causeA.name > causeB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseCauseReasons = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeCatalog: CauseReasonCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'causeReasonCatalog',
        );

        if (causeCatalog !== null) {
          return causeCatalog.causeReasons.sort(
            (
              causeReasonA: ErrorCaseCauseReason,
              causeReasonB: ErrorCaseCauseReason,
            ) => {
              if (causeReasonA.name < causeReasonB.name) {
                return -1;
              }
              if (causeReasonA.name > causeReasonB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseDecisions = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const decisionCatalog: DecisionCatalog = getNearestCatalog(
          currentOrgUnit,
          props.orgChart,
          'decisionCatalog',
        );

        if (decisionCatalog !== null) {
          return decisionCatalog.decisions.sort(
            (decisionA: ErrorCaseDecision, decisionB: ErrorCaseDecision) => {
              if (decisionA.name < decisionB.name) {
                return -1;
              }
              if (decisionA.name > decisionB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      function handleDecisionUpdate() {
        if (data.errorCase === null) return;
        if (!data.errorCase.id) return;
        if (!data.errorCase.decision) return;
        if (!data.errorCase.cause) return;
        if (!data.errorCase.causeReason) return;

        const decisionUpdate: ErrorCaseDecisionUpdateDto = {
          id: data.errorCase.id,
          decision: computedAvailableErrorCaseDecisions.value.find(
            (decision) => {
              return decision.id === data.currentEdit.decision?.id;
            },
          ) as ErrorCaseDecision,
          cause: computedAvailableErrorCaseCauses.value.find((cause) => {
            return cause.id === data.currentEdit.cause?.id;
          }) as ErrorCaseDecision,
          causeReason: computedAvailableErrorCaseCauseReasons.value.find(
            (causeReason) => {
              return causeReason.id === data.currentEdit.causeReason?.id;
            },
          ) as ErrorCaseCauseReason,
        };

        store.dispatch(Actions.START_LOADER);

        ApiService.post('fes/errors/decision/update/deprecated', {
          data: decisionUpdate,
        })
          .then((response: AxiosResponse) => {
            data.isEdit = false;
            const finalizedError = response.data as ErrorCase;
            data.errorCase = finalizedError;
            data.currentEdit.cause = data.errorCase.cause;
            data.currentEdit.causeReason = data.errorCase.causeReason;
            data.currentEdit.decision = data.errorCase.decision;
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      return {
        data,
        computedAvailableErrorCaseCauses,
        computedAvailableErrorCaseCauseReasons,
        computedAvailableErrorCaseDecisions,
        startDecisionEdit,
        computedDecisionReadyForEdit,
        cancelDecisionEdit,
        handleDecisionUpdate,
      };
    },
  });
</script>
