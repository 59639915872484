<template>
  <div>
    <div v-if="computedIsLoadedProcessInterruption">
      <div class="col-12">
        <div class="d-flex align-items-center mb-6">
          <div class="d-block me-3">
            <router-link
              :to="computedBackLink"
              class="btn btn-secondary btn-sm"
            >
              <i class="fa fa-chevron-left"></i>
            </router-link>
          </div>
          <div>
            <h3 class="fw-bolder my-2">
              {{ $t('fes.form.processInterruption.self') }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >#{{ data.processInterruption.id }}</span
              >
            </h3>
            <small>{{
              computedTime(data.processInterruption.createdAt)
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!data.errorLocation.isLoading" class="row mb-3">
      <div
        class="col"
        v-if="isLocationComplete && !computedIsLoadedProcessInterruption"
      >
        <org-unit-path
          :node-path="selectedOrgLevels"
          :area-names="data.cdfNames"
        >
          <button
            v-if="!computedIsProcessInterruptionFinalized"
            type="button"
            class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
            @click="releaseLocationForm"
          >
            <span class="svg-icon svg-icon-1">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </span>
          </button>
        </org-unit-path>
      </div>
      <div
        class="col"
        v-if="!computedHasProcessInterruptionIdParam && !isLocationComplete"
      >
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">{{
                $t('fes.form.errorLocation')
              }}</span>
            </h3>
          </div>

          <div class="card-body py-3">
            <div class="row" v-if="computedOrgOptions(0).length === 0">
              <div class="col">
                <div
                  class="alert alert-danger d-flex align-items-center p-5 mb-10"
                >
                  <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                    <inline-svg src="media/icons/duotune/general/gen025.svg" />
                  </span>
                  <div class="d-flex flex-column">
                    <h4 class="mb-1 text-danger">
                      {{ $t('fes.config.missing.title') }}
                    </h4>
                    <span>{{ $t('fes.config.missing.text') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="row mb-6"
              v-for="(orgLevel, index) of data.orgLevels"
              :key="index"
            >
              <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                computedOrgLevelHierarchy(index)
              }}</label>

              <div class="col-lg-8 fv-row">
                <Field
                  as="select"
                  name="orgUnit"
                  class="form-select form-select-solid form-select-lg"
                  v-model="data.orgLevels[index]"
                  @change="handleOrgUnitChange(index)"
                >
                  <option
                    v-for="(orgOption, index) of computedOrgOptions(index)"
                    :key="index"
                    :value="orgOption.id"
                  >
                    {{ orgOption.name }}
                  </option>
                </Field>
              </div>
            </div>
          </div>

          <div
            v-if="data.errorLocation.isReady && !data.errorLocation.isComplete"
            class="card-footer d-flex justify-content-end py-6 px-9"
          >
            <button
              @click="handleLocationChange"
              :disabled="!computedIsOrgUnitSelectable"
              type="submit"
              id="kt_account_profile_details_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Weiter</span
              ><span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span
              ></span>
            </button>
          </div>

          <div v-if="data.errorLocation.isComplete" class="card-body py-3">
            <div
              class="row mb-6"
              v-for="(orgLevel, index) of selectedOrgLevels"
              :key="index"
            >
              <div class="col-lg-4 fw-bold fs-6">
                {{ computedOrgLevelHierarchy(index) }}
              </div>

              <div class="col-lg-8 fv-row">
                <span>{{ orgLevel.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isLocationComplete || computedIsLoadedProcessInterruption"
      class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
    >
      <!--begin::Aside-->
      <div
        class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9"
      >
        <!--begin::Wrapper-->
        <div class="px-6 px-lg-10 px-xxl-12 py-20">
          <!--begin::Nav-->
          <div class="stepper-nav col-xxl-3 position-xxl-fixed w-xxl-300px">
            <a
              v-for="step of data.steps"
              :key="step.id"
              href="javascript:"
              @click="goToStep(step.id, false)"
              :class="computedStepNavClass(step.id)"
              data-kt-stepper-element="nav"
            >
              <!--begin::Line-->
              <div class="stepper-line w-40px"></div>
              <!--end::Line-->

              <!--begin::Icon-->
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">{{
                  computedStepIndexNumber(step.id)
                }}</span>
              </div>
              <!--end::Icon-->

              <!--begin::Label-->
              <div class="stepper-label">
                <h3 class="stepper-title">{{ step.name }}</h3>

                <div class="stepper-desc fw-bold">
                  {{ step.detail }}
                </div>
              </div>
              <!--end::Label-->
            </a>
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--begin::Aside-->

      <div class="w-100">
        <div class="col-12" v-if="computedIsLoadedProcessInterruption">
          <simple-org-unit-path
            :node-path="computedProcessInterruptionLocations"
            :area-names="data.cdfNames"
          />
        </div>

        <div class="col-12" v-if="computedProcessInterruptionHasError">
          <div
            class="alert bg-light-primary border border-primary d-flex align-items-center p-5 mb-10"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
              <inline-svg src="media/icons/duotune/coding/cod009.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-primary">
                {{ $t('fes.form.processInterruption.connectedError') }}
              </h4>
              <span>{{
                $t('fes.form.processInterruption.connectedErrorDetails', {
                  id: data.processInterruption.error.id,
                  style: data.processInterruption.error.locationRisk.style.name,
                  place: data.processInterruption.error.locationRisk.place.name,
                })
              }}</span>
            </div>

            <router-link
              :to="`/fes/overview/${data.processInterruption.error.id}`"
              class="btn btn-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
            >
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
              </span>
            </router-link>
          </div>
        </div>

        <div
          v-if="hasCompletedStep1"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{
                  $t('fes.form.steps.4.title')
                }}</span>
              </h3>
              <div
                v-if="
                  data.processInterruption !== null &&
                  isCurrentUserDeciderForLocation
                "
                class="card-toolbar"
              >
                <span
                  v-if="data.processInterruption.state === 'open'"
                  class="badge badge-primary"
                  >{{ $t('fes.processInterruption.states.open') }}</span
                >
                <span
                  v-if="data.processInterruption.state === 'accepted'"
                  class="badge badge-success"
                  >{{ $t('fes.processInterruption.states.accepted') }}</span
                >
                <span
                  v-if="data.processInterruption.state === 'declined'"
                  class="badge badge-danger"
                  >{{ $t('fes.processInterruption.states.declined') }}</span
                >
                <div
                  class="ms-3"
                  v-if="data.processInterruption.state === 'open'"
                >
                  <button
                    @click="acceptProcessInterruption()"
                    class="btn btn-icon btn-light btn-sm border-0 btn-active-light-primary"
                    :title="$t('fes.processInterruption.stateActions.accept')"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-check" />
                    </span>
                  </button>
                  <button
                    @click="declineProcessInterruption()"
                    class="btn btn-icon btn-light btn-sm border-0 ms-3 btn-active-light-primary"
                    :title="$t('fes.processInterruption.stateActions.decline')"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-times" />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body py-3 pb-5">
              <div class="row" v-if="data.processInterruption !== null">
                <div class="col-4">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.processInterruption.category')
                  }}</label>
                </div>
                <div class="col-8 d-flex align-items-center">
                  <span v-if="data.processInterruption.category !== null">{{
                    data.processInterruption.category.name
                  }}</span>
                  <i class="fa fa-chevron-right mx-2"></i>
                  <span> {{ data.processInterruption.subCategory.name }}</span>
                  <i class="fa fa-chevron-right mx-2"></i>
                  <span>
                    {{ data.processInterruption.subSubCategory.name }}</span
                  >
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.downtimeMachine')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <div>
                        {{ data.processInterruption.downtimeMachine }}
                        Minuten
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.downtimeWorker')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <div>
                        {{ data.processInterruption.downtimeWorker }}
                        Minuten
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.reason')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        {{ data.processInterruption.reason }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col">
                  <span>{{ $t('fes.form.processInterruption.none') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-row-fluid align-content-start bg-white rounded"
          v-if="!computedIsProcessInterruptionFinalized"
        >
          <MyForm
            :validation-schema="processInterruptionStep1Validator"
            @submit="handleStep1Submit"
            v-if="!computedIsLoadedProcessInterruption"
            v-show="data.currentStep === 1"
            novalidate="novalidate"
            action="javascript:"
            method="post"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0 pt-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3">{{
                      $t('fes.form.steps.4.title')
                    }}</span>
                    <span class="text-muted fw-bold fs-7">{{
                      $t('fes.form.steps.4.detail')
                    }}</span>
                  </h3>
                </div>

                <div class="card-body py-3">
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.category')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-start flex-column">
                      <Field
                        tabindex="9"
                        as="select"
                        name="processInterruptionCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.processInterruptionForm
                            .processInterruptionCategoryId
                        "
                        @change="handleProcessInterruptionCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('pleaseSelect') }}
                        </option>
                        <option
                          v-for="processInterruptionCategory in computedProcessInterruptionCategories"
                          :key="processInterruptionCategory.id"
                          :value="processInterruptionCategory.id"
                        >
                          {{ processInterruptionCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="processInterruptionCategory" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="computedFilledProcessInterruptionCategory"
                  >
                    <div class="col-12">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.subCategory')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-start flex-column">
                      <Field
                        tabindex="9"
                        as="select"
                        name="processInterruptionSubCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.processInterruptionForm
                            .processInterruptionSubCategoryId
                        "
                        @change="handleProcessInterruptionSubCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('pleaseSelect') }}
                        </option>
                        <option
                          v-for="processInterruptionSubCategory in computedProcessInterruptionSubCategories"
                          :key="processInterruptionSubCategory.id"
                          :value="processInterruptionSubCategory.id"
                        >
                          {{ processInterruptionSubCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="processInterruptionSubCategory" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="computedFilledProcessInterruptionSubCategory"
                  >
                    <div class="col-12">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.subSubCategory')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-start flex-column">
                      <Field
                        tabindex="10"
                        as="select"
                        name="processInterruptionSubSubCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.processInterruptionForm
                            .processInterruptionSubSubCategoryId
                        "
                        @change="handleProcessInterruptionSubSubCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('pleaseSelect') }}
                        </option>
                        <option
                          v-for="processInterruptionSubSubCategory in computedProcessInterruptionSubSubCategories"
                          :key="processInterruptionSubSubCategory.id"
                          :value="processInterruptionSubSubCategory.id"
                        >
                          {{ processInterruptionSubSubCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage
                            name="processInterruptionSubSubCategory"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="
                      computedFilledProcessInterruptionCategory &&
                      computedFilledProcessInterruptionSubCategory &&
                      computedFilledProcessInterruptionSubSubCategory
                    "
                  >
                    <div class="col-6">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.reason')
                      }}</label>
                      <Field
                        tabindex="10"
                        as="textarea"
                        name="reason"
                        class="form-control form-control-solid"
                        v-model="data.processInterruption.reason"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="reason" />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-8">
                          <label class="col-form-label required fw-bold fs-6"
                            >{{
                              $t('fes.form.processInterruption.downtimeMachine')
                            }}
                            {{ $t('inMinutes') }}</label
                          >
                        </div>
                        <div class="col-4">
                          <Field
                            type="number"
                            name="processInterruptionDowntimeMachine"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.processInterruption.downtimeMachine"
                          />
                        </div>
                        <div class="col-12">
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                name="processInterruptionDowntimeMachine"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <label class="col-form-label required fw-bold fs-6"
                            >{{
                              $t('fes.form.processInterruption.downtimeWorker')
                            }}
                            {{ $t('inMinutes') }}</label
                          >
                        </div>
                        <div class="col-4">
                          <Field
                            type="number"
                            name="processInterruptionDowntimeWorker"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.processInterruption.downtimeWorker"
                          />
                        </div>
                        <div class="col-12">
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                name="processInterruptionDowntimeWorker"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <error-form-step-navigation
              :back-allowed="false"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <div
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            v-show="
              data.currentStep === LAST_STEP_INDEX &&
              !computedIsLoadedProcessInterruption
            "
          >
            <div data-kt-stepper-element="content" class="current">
              <!--begin::Wrapper-->
              <div class="w-100">
                <!--begin::Heading-->
                <div class="pb-8 pb-lg-10">
                  <!--begin::Title-->
                  <h2 class="fw-bolder text-dark">Erledigt!</h2>
                </div>
                <!--end::Heading--><!--begin::Body-->
                <div class="mb-5">
                  <!--begin::Text-->
                  <div class="fs-6 text-gray-600 mb-5">
                    {{ computedFinalStepMessage }}
                  </div>
                </div>

                <button
                  class="btn btn-primary"
                  @click="resetProcessInterruptionForm"
                >
                  Zurück zum Start
                </button>
                <!--end::Body-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import ApiService from '@/core/services/ApiService';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n';
  import Swal from 'sweetalert2';
  import * as yup from 'yup';
  import { configure } from 'vee-validate';
  import {
    computedDate,
    computedTime,
    getNearestRiskLevelCatalogs,
    parseCurrentOrgLevels,
  } from '../../../core/helpers/cdf';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import SimpleOrgUnitPath from '@/components/fes/SimpleOrgUnitPath.vue';
  import ErrorFormStepNavigation from '@/components/fes/errorForm/StepNavigation.vue';
  import {
    ErrorCase,
    ErrorCaseCDFName,
    FESConfig,
    OrgChartDataPairWithCatalogs,
    ProcessInterruption,
    ProcessInterruptionCatalog,
    ProcessInterruptionCategory,
    ProcessInterruptionState,
    ProcessInterruptionSubCategory,
    ProcessInterruptionSubSubCategory,
    RiskLevel,
    RiskLevelCatalog,
  } from '@/core/models/fes';
  import {
    calculateDepth,
    getNearestCatalog,
    hasReadyChildrenPI,
    hasReadyParentsPI,
    isReadyOrgUnitPI,
  } from '@/core/helpers/cdf';
  import { useRoute, useRouter } from 'vue-router';
  import { SetErrorLocationContext } from '@/store/modules/AuthModule';

  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });

  type Step = {
    id: number;
    name: string;
    detail?: string;
  };

  export default defineComponent({
    name: 'fesProcessInterruptionsSingle',
    components: {
      Field,
      MyForm: Form,
      ErrorMessage,
      OrgUnitPath,
      SimpleOrgUnitPath,
      ErrorFormStepNavigation,
    },
    setup() {
      const store = useStore();
      const { t } = useI18n();
      const route = useRoute();
      const router = useRouter();

      const LAST_STEP_INDEX = 2;

      const data: {
        currentStep: number;
        currentHighestStep: number;
        orgChart: OrgChartDataPairWithCatalogs[];
        orgLevels: string[];
        errorLocation: {
          isLoading: boolean;
          isReady: boolean;
          isComplete: boolean;
        };
        processInterruption: ProcessInterruption | null;
        processInterruptionForm: {
          processInterruptionCategory: ProcessInterruptionCategory;
          processInterruptionSubCategory: ProcessInterruptionSubCategory;
          processInterruptionSubSubCategory: ProcessInterruptionSubSubCategory;
          processInterruptionCategoryId: string;
          processInterruptionSubCategoryId: string;
          processInterruptionSubSubCategoryId: string;
          errorCaseDetailsReady: boolean;
        };
        selects: {
          processInterruptionCategories: ProcessInterruptionCategory[];
        };
        cdfNames: ErrorCaseCDFName[];
        currentRiskLevels: RiskLevel[];
        currentProcessInterruptionCategory: ProcessInterruptionCategory | null;
        steps: Step[];
        isLoadedProcessInterruption: boolean;
      } = reactive({
        currentStep: 1,
        currentHighestStep: 0,
        orgChart: [],
        orgLevels: [],
        errorLocation: {
          isLoading: true,
          isReady: false,
          isComplete: false,
        },
        processInterruption: null,
        processInterruptionForm: {
          processInterruptionCategory: {} as ProcessInterruptionCategory,
          processInterruptionSubCategory: {} as ProcessInterruptionSubCategory,
          processInterruptionSubSubCategory:
            {} as ProcessInterruptionSubSubCategory,
          processInterruptionCategoryId: '',
          processInterruptionSubCategoryId: '',
          processInterruptionSubSubCategoryId: '',
          errorCaseDetailsReady: false,
        },
        selects: {
          processInterruptionCategories: [],
        },
        barcodeInput: '',
        cdfNames: [] as ErrorCaseCDFName[],
        currentRiskLevels: [] as RiskLevel[],
        currentProcessInterruptionCategory: null,
        steps: [
          {
            id: 1,
            name: t('fes.form.processInterruption.self'),
            detail: t('fes.form.processInterruption.heading'),
          } as Step,
          {
            id: 2,
            name: 'Erledigt',
          },
        ],
        isLoadedProcessInterruption: false,
      });

      const processInterruptionStep1Validator = computed(() => {
        return yup.object().shape({
          reason: yup
            .string()
            .min(1)
            .required(t('fes.form.errors.processInterruption.missingReason'))
            .label(t('fes.form.processInterruption.reason'))
            .typeError(t('fes.form.errors.processInterruption.missingReason')),
          processInterruptionCategory: yup
            .number()
            .required()
            .label(t('fes.form.processInterruption.category'))
            .typeError(
              t('fes.form.errors.processInterruption.missingCategory'),
            ),
          processInterruptionSubCategory: yup
            .number()
            .required()
            .label(t('fes.form.processInterruption.subCategory'))
            .typeError(
              t('fes.form.errors.processInterruption.missingSubCategory'),
            ),
          processInterruptionSubSubCategory: yup
            .number()
            .required()
            .label(t('fes.form.processInterruption.subSubCategory'))
            .typeError(
              t('fes.form.errors.processInterruption.missingSubSubCategory'),
            ),
          processInterruptionDowntimeMachine: yup
            .number()
            .required()
            .label(t('fes.form.processInterruption.downtimeMachine'))
            .typeError(
              t('fes.form.errors.processInterruption.missingDonwtimeMachine'),
            ),
          processInterruptionDowntimeWorker: yup
            .number()
            .required()
            .label(t('fes.form.processInterruption.downtimeWorker'))
            .typeError(
              t('fes.form.errors.processInterruption.missingDowntimeWorker'),
            ),
        });
      });

      const computedCurrentLocation = computed(() => {
        return selectedOrgLevels.value
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      watch(
        () => data.currentStep,
        (newValue) => {
          if (newValue > data.currentHighestStep) {
            data.currentHighestStep = newValue;
          }
        },
      );

      const computedStepNavClass = computed(() => {
        return (step) => {
          const classes = ['stepper-item'];

          if (
            data.currentHighestStep >= step &&
            data.currentStep !== step &&
            data.currentStep !== LAST_STEP_INDEX &&
            !computedIsLoadedProcessInterruption.value
          ) {
            classes.push('cursor-pointer');
          }

          if (data.currentStep > step && data.currentStep !== step) {
            classes.push('completed');
          } else if (data.currentStep === step) {
            classes.push('current');
            classes.push('cursor-default');
          }

          if (
            !classes.includes('cursor-default') &&
            !classes.includes('cursor-pointer')
          ) {
            classes.push('cursor-default');
          }

          return classes.join(' ');
        };
      });

      const computedStepIndexNumber = computed(() => {
        return (stepIndex) => {
          if (stepIndex <= 2) {
            return stepIndex;
          }

          return data.steps.slice(0, stepIndex).length;
        };
      });

      const selectedOrgLevels = computed(() => {
        return data.orgLevels.map((orgLevelId, index) => {
          const currentOptions = computedOrgOptions(index);

          return currentOptions.find((orgOption) => {
            return orgLevelId === orgOption.id;
          });
        });
      });

      const getFirstLevelElementsOfParent = (parentId, currentTree) => {
        const result: OrgChartDataPairWithCatalogs[] = [];
        for (const element of currentTree) {
          if (element.parentId === parentId) {
            result.push(element);
          }
        }
        return result;
      };

      const computedProcessInterruptionCategories = computed(
        (): ProcessInterruptionCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionCategoriesToSelect: ProcessInterruptionCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        processInterruptionCategoriesToSelect.findIndex(
                          (
                            processInterruptionCategory: ProcessInterruptionCategory,
                          ) => {
                            return (
                              category.id === processInterruptionCategory.id
                            );
                          },
                        ) === -1
                      ) {
                        processInterruptionCategoriesToSelect.push(category);
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionCategory,
              categoryB: ProcessInterruptionCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const computedProcessInterruptionSubCategories = computed(
        (): ProcessInterruptionSubCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionSubCategoriesToSelect: ProcessInterruptionSubCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        category.id ===
                          parseInt(
                            data.processInterruptionForm
                              .processInterruptionCategoryId,
                          ) &&
                        processInterruptionSubCategoriesToSelect.findIndex(
                          (
                            processInterruptionSubCategory: ProcessInterruptionSubCategory,
                          ) => {
                            return (
                              subCategory.id ===
                              processInterruptionSubCategory.id
                            );
                          },
                        ) === -1
                      ) {
                        processInterruptionSubCategoriesToSelect.push(
                          subCategory,
                        );
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionSubCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionSubCategory,
              categoryB: ProcessInterruptionSubCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const computedProcessInterruptionSubSubCategories = computed(
        (): ProcessInterruptionSubSubCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionSubSubCategoriesToSelect: ProcessInterruptionSubSubCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        subCategory.id ===
                          parseInt(
                            data.processInterruptionForm
                              .processInterruptionSubCategoryId,
                          )
                      ) {
                        processInterruptionSubSubCategoriesToSelect.push(
                          subSubCategory,
                        );
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionSubSubCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionSubSubCategory,
              categoryB: ProcessInterruptionSubSubCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const handleLocationChange = async () => {
        if (
          data.errorLocation.isReady === false ||
          typeof computedCurrentLocation.value === 'undefined'
        ) {
          return;
        }

        data.errorLocation.isComplete = true;
        store.commit(Mutations.SET_ERRORLOCATION, {
          orgLevels: data.orgLevels,
          identifier: 'currentOrgLevelsPI',
        } as SetErrorLocationContext);
      };

      const releaseLocationForm = () => {
        data.errorLocation.isComplete = false;
        resetProcessInterruptionForm();
      };

      const isLocationComplete = computed(() => {
        return data.errorLocation.isComplete === true;
      });

      const isProcessInterruptionNull = computed(() => {
        return data.processInterruption === null;
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(computedAvailableOrgLevels.value);
        const rawDataParsed = JSON.parse(rawData);
        return rawDataParsed;
      };

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return data.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const hasOrgUnitChildren = (
        orgUnit: OrgChartDataPairWithCatalogs,
      ): boolean => {
        const rawDataParsed = getRawOrgChart();
        return rawDataParsed.some((element) => {
          return element.parentId === orgUnit.id;
        });
      };

      const handleOrgUnitChange = (index) => {
        if (data.orgLevels.length > index + 1) {
          data.orgLevels.splice(index + 1, data.orgLevels.length - index - 1);
        }

        const currentId = String(data.orgLevels[index]);

        const rawDataParsed: OrgChartDataPairWithCatalogs[] = getRawOrgChart();
        const currentNode = rawDataParsed.find(
          (element: OrgChartDataPairWithCatalogs) => element.id === currentId,
        );

        if (currentNode) {
          if (hasOrgUnitChildren(currentNode)) {
            data.orgLevels.push('');
            data.errorLocation.isReady = false;
          } else {
            data.errorLocation.isReady = true;
          }
        }
      };

      // const computedOrgLevelHierarchy = (index): string => {
      //   return data.cdfNames[index].name;
      // };

      const computedOrgLevelHierarchy = (index): string => {
        return data.cdfNames[index]
          ? data.cdfNames[index].name
          : `${t('depth')} ${index + 1}`;
      };

      const handleProcessInterruptionCategoryChange = () => {
        if (data.processInterruptionForm.processInterruptionCategoryId === '') {
          data.processInterruption = null;
        } else {
          const foundProcessInterruptionCategory =
            data.selects.processInterruptionCategories.find(
              (element: ProcessInterruptionCategory) => {
                return (
                  String(element.id) ===
                  String(
                    data.processInterruptionForm.processInterruptionCategoryId,
                  )
                );
              },
            );
          if (foundProcessInterruptionCategory) {
            data.processInterruptionForm.processInterruptionCategory =
              foundProcessInterruptionCategory;
          }
        }
        data.processInterruptionForm.processInterruptionSubCategoryId = '';
        data.processInterruptionForm.processInterruptionSubSubCategoryId = '';
      };

      const handleProcessInterruptionSubCategoryChange = () => {
        if (
          data.processInterruptionForm.processInterruptionSubCategoryId === ''
        ) {
          data.processInterruption = null;
        } else {
          const foundProcessInterruptionSubCategory =
            computedProcessInterruptionSubCategories.value.find(
              (element: ProcessInterruptionSubCategory) => {
                return (
                  String(element.id) ===
                  String(
                    data.processInterruptionForm
                      .processInterruptionSubCategoryId,
                  )
                );
              },
            );
          if (foundProcessInterruptionSubCategory) {
            data.processInterruptionForm.processInterruptionSubCategory =
              foundProcessInterruptionSubCategory;
          }
        }
        data.processInterruptionForm.processInterruptionSubSubCategoryId = '';
      };

      const handleProcessInterruptionSubSubCategoryChange = () => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) return;

        const processInterruptionCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'processInterruptionCatalog',
        ) as ProcessInterruptionCatalog;

        const foundProcessInterruptionSubSubCategory =
          processInterruptionCatalog.subSubCategories.find(
            (element: ProcessInterruptionSubSubCategory) => {
              return (
                String(element.id) ===
                String(
                  data.processInterruptionForm
                    .processInterruptionSubSubCategoryId,
                )
              );
            },
          );
        if (foundProcessInterruptionSubSubCategory) {
          data.processInterruptionForm.processInterruptionSubSubCategory =
            foundProcessInterruptionSubSubCategory;
          data.processInterruption = {
            category: data.processInterruptionForm.processInterruptionCategory,
            subCategory:
              data.processInterruptionForm.processInterruptionSubCategory,
            subSubCategory: foundProcessInterruptionSubSubCategory,
            downtimeMachine: null,
            downtimeWorker: null,
            reason: '',
            locations: selectedOrgLevels.value.map((orgLevel, index) => {
              const orgLevelIdentifier = computedOrgLevelHierarchy(index);
              return {
                locationIdentifier: orgLevelIdentifier,
                locationName: String(orgLevel?.name),
                locationId: String(orgLevel?.id),
                pos: index,
              };
            }),
            error: null,
            state: ProcessInterruptionState.OPEN,
          };
        }
      };

      const previousStep = () => {
        data.currentStep--;

        if (data.currentStep <= 0) {
          resetProcessInterruptionForm();
        }
      };

      const computedFilledProcessInterruptionCategory = computed(() => {
        if (data.processInterruptionForm.processInterruptionCategoryId === '') {
          return false;
        }
        return true;
      });

      const computedFilledProcessInterruptionSubCategory = computed(() => {
        if (
          data.processInterruptionForm.processInterruptionSubCategoryId === ''
        ) {
          return false;
        }
        return true;
      });
      const computedFilledProcessInterruptionSubSubCategory = computed(() => {
        if (
          data.processInterruptionForm.processInterruptionSubSubCategoryId !==
            '' &&
          data.processInterruption !== null
        )
          return true;
        return false;
      });

      const handleStep1Submit = async () => {
        if (data.processInterruption === null) return false;

        store.dispatch(Actions.START_LOADER);

        ApiService.post('fes/processinterruptions/save', {
          data: data.processInterruption,
        })
          .then((result) => {
            const processInterruption = result.data as ProcessInterruption;
            router.push({
              name: 'fesProcessInterruptionsSingleLoaded',
              params: { id: processInterruption.id },
            });
            // data.processInterruption = result.data as ProcessInterruption;
            // data.currentStep = LAST_STEP_INDEX;
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      };

      const resetProcessInterruptionForm = () => {
        data.processInterruption = null;
        data.processInterruptionForm.processInterruptionCategory =
          {} as ProcessInterruptionCategory;
        data.processInterruptionForm.processInterruptionSubCategory =
          {} as ProcessInterruptionSubCategory;
        data.processInterruptionForm.processInterruptionSubSubCategory =
          {} as ProcessInterruptionSubSubCategory;
        data.processInterruptionForm.processInterruptionCategoryId = '';
        data.processInterruptionForm.processInterruptionSubCategoryId = '';
        data.processInterruptionForm.processInterruptionSubSubCategoryId = '';
        data.processInterruptionForm.errorCaseDetailsReady = false;

        data.currentStep = 1;
        data.currentHighestStep = 1;
      };

      const computedAvailableOrgLevels = computed(() => {
        return data.orgChart.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.visible;
        });
      });

      const computedIsOrgUnitSelectable = computed(() => {
        return computedCurrentLocation.value?.selectable === true;
      });

      const computedCurrentUser = computed(() => {
        return store.getters.currentUser;
      });

      const computedCurrentUserRiskLevels = computed(() => {
        return [];
      });

      const hasCompletedStep1 = computed(() => {
        return data.currentStep > 1 && data.processInterruption !== null;
      });

      const goToStep = (step: number, force = false) => {
        if (data.currentStep === LAST_STEP_INDEX) return;
        if (step === data.currentStep) return;
        if (step > data.currentHighestStep) return;
        if (computedIsLoadedProcessInterruption.value && !force) return;
        data.currentStep = step;
      };

      const computedProcessInterruptionCategoryName = computed(() => {
        if (data.processInterruption === null) return '';
        if (data.processInterruption.category === null) return '';
        const currentProcessInterruptionSubCategoryId =
          data.processInterruption.category.id;
        const foundProcessInterruptionCategory =
          data.selects.processInterruptionCategories.find(
            (processInterruptionCategory: ProcessInterruptionCategory) => {
              return processInterruptionCategory.subCategories.some(
                (subCategory) => {
                  return (
                    subCategory.id === currentProcessInterruptionSubCategoryId
                  );
                },
              );
            },
          );
        return foundProcessInterruptionCategory
          ? foundProcessInterruptionCategory.name
          : '';
      });

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);

        const fesConfig: FESConfig = (await ApiService.get('fes/config')).data;
        const processInterruptionCategories: ProcessInterruptionCategory[] = (
          await ApiService.get('fes/config/processinterruption/categories')
        ).data;

        data.cdfNames = fesConfig.hierarchyLevels.slice(0);
        data.currentRiskLevels = fesConfig.riskLevels.slice(0);
        data.selects.processInterruptionCategories =
          processInterruptionCategories.slice(0);

        data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);
        data.orgChart = data.orgChart.map((orgUnit) => {
          return {
            ...orgUnit,
            depth: calculateDepth(orgUnit, data.orgChart),
            selectable: isReadyOrgUnitPI(orgUnit, data.orgChart),
            visible:
              isReadyOrgUnitPI(orgUnit, data.orgChart) ||
              hasReadyChildrenPI(orgUnit, data.orgChart) ||
              hasReadyParentsPI(orgUnit, data.orgChart),
          };
        });

        data.orgLevels = parseCurrentOrgLevels(
          data.orgChart,
          computedCurrentLocation.value,
          data.currentRiskLevels,
          'currentOrgLevelsPI',
        );

        data.errorLocation.isReady = true;
        await handleLocationChange();

        const processInterruptionLoadId = route.params.id;
        if (processInterruptionLoadId) {
          await loadProcessInterruption(processInterruptionLoadId);
          data.currentStep = LAST_STEP_INDEX;
        }

        data.errorLocation.isLoading = false;
        store.dispatch(Actions.END_LOADER);
      });

      const computedIsProcessInterruptionFinalized = computed(() => {
        if (data.processInterruption === null) return false;
        if (typeof data.processInterruption.id === 'undefined') return false;
        return true;
      });

      async function loadProcessInterruption(id) {
        try {
          const loadedProcessInterruption: ProcessInterruption = await (
            await ApiService.get(`fes/processInterruptions/${id}`)
          ).data;
          data.processInterruption = Object.assign(
            {},
            loadedProcessInterruption,
          ) as ProcessInterruption;
          data.isLoadedProcessInterruption = true;
        } catch (error) {
          router.push('/fes/processinterruptions/overview');
          return Swal.fire(
            t('error'),
            t('processInterruptionNotFound'),
            'error',
          );
        }
      }

      const computedIsLoadedProcessInterruption = computed(() => {
        return data.isLoadedProcessInterruption === true;
      });

      const computedFinalStepMessage = computed(() => {
        return t('fes.processInterruption.form.created');
      });

      const computedBackLink = computed(() => {
        if (window.history && window.history.state) {
          if (window.history.state.back !== null) {
            return window.history.state.back;
          }
        }

        return '/fes/processinterruptions/overview';
      });

      const computedHasProcessInterruptionIdParam = computed(() => {
        if (typeof route.params.id === 'undefined') return false;
        return true;
      });

      async function acceptProcessInterruption() {
        if (data.processInterruption === null) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;
        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/accept/${data.processInterruption.id}`,
          {},
        );
        data.processInterruption.state = ProcessInterruptionState.ACCEPTED;
        store.dispatch(Actions.END_LOADER);
      }

      async function declineProcessInterruption() {
        if (data.processInterruption === null) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;
        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/decline/${data.processInterruption.id}`,
          {},
        );
        data.processInterruption.state = ProcessInterruptionState.DECLINED;
        store.dispatch(Actions.END_LOADER);
      }

      const isCurrentUserDeciderForLocation = computed(() => {
        if (data.processInterruption === null) return false;

        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (!currentOrgUnit) return false;

        let errorCase = null as ErrorCase | null;
        if (data.processInterruption.error !== null) {
          errorCase = data.processInterruption.error as ErrorCase;
        }

        // sortedLocations.pop();
        const riskLevelCatalogs: RiskLevelCatalog[] =
          getNearestRiskLevelCatalogs(currentOrgUnit, data.orgChart);

        if (riskLevelCatalogs.length === 0) return false;

        if (
          riskLevelCatalogs.filter((riskLevelCatalog: RiskLevelCatalog) => {
            if (errorCase !== null) {
              return (
                riskLevelCatalog.userIds.includes(
                  computedCurrentUser.value.id,
                ) &&
                riskLevelCatalog.riskValue === errorCase.locationRisk.riskValue
              );
            }

            return riskLevelCatalog.userIds.includes(
              computedCurrentUser.value.id,
            );
          }).length > 0
        ) {
          return true;
          // return processInterruptionWithPermission;
        }
        return false;
      });

      const computedProcessInterruptionLocations = computed(() => {
        if (data.processInterruption === null) return [];
        if (computedProcessInterruptionHasError.value === true)
          return data.processInterruption.error?.locations
            .slice(0)
            .sort((a, b) => a.pos - b.pos);
        return data.processInterruption.locations
          ?.slice(0)
          .sort((a, b) => a.pos - b.pos);
      });

      const computedProcessInterruptionHasError = computed(() => {
        if (data.processInterruption === null) return false;
        return data.processInterruption.error !== null;
      });

      return {
        data,
        getFirstLevelElementsOfParent,
        computedOrgLevelHierarchy,
        computedOrgOptions,
        handleOrgUnitChange,
        handleLocationChange,
        releaseLocationForm,
        selectedOrgLevels,
        computedFilledProcessInterruptionSubCategory,
        computedFilledProcessInterruptionSubSubCategory,
        handleProcessInterruptionCategoryChange,
        resetProcessInterruptionForm,
        computedFilledProcessInterruptionCategory,
        processInterruptionStep1Validator,
        computedAvailableOrgLevels,
        computedCurrentLocation,
        computedIsOrgUnitSelectable,
        computedCurrentUserRiskLevels,
        computedCurrentUser,
        computedProcessInterruptionCategories,
        computedProcessInterruptionSubCategories,
        computedProcessInterruptionSubSubCategories,
        handleProcessInterruptionSubCategoryChange,
        handleProcessInterruptionSubSubCategoryChange,
        isLocationComplete,
        isProcessInterruptionNull,
        previousStep,
        handleStep1Submit,
        hasCompletedStep1,
        computedStepNavClass,
        computedStepIndexNumber,
        computedIsProcessInterruptionFinalized,
        goToStep,
        computedIsLoadedProcessInterruption,
        computedProcessInterruptionCategoryName,
        LAST_STEP_INDEX,
        computedDate,
        computedTime,
        computedFinalStepMessage,
        computedBackLink,
        computedHasProcessInterruptionIdParam,
        acceptProcessInterruption,
        declineProcessInterruption,
        isCurrentUserDeciderForLocation,
        computedProcessInterruptionLocations,
        computedProcessInterruptionHasError,
      };
    },
  });
</script>

<style scoped>
  .stepper {
    min-height: 640px;
  }
  a.stepper-item.cursor-pointer:hover .stepper-title,
  a.stepper-item.cursor-pointer:hover .stepper-desc {
    color: #009ef7 !important;
  }
</style>
