<template>
  <div class="mw-1200px">
    <div v-if="!data.location.isLoading && !computedHasParent" class="row">
      <div class="col" v-if="isLocationComplete">
        <org-unit-path
          v-if="selectedOrgLevels"
          :node-path="selectedOrgLevels"
          :area-names="data.hierarchyNames"
        >
          <button
            v-if="data.openPoints.length === 0 && !computedHasErrorCaseSource"
            type="button"
            class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
            @click="releaseErrorLocationForm"
          >
            <span class="svg-icon svg-icon-1">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </span>
          </button>
        </org-unit-path>
      </div>
      <div class="col" v-if="!isLocationComplete">
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">{{
                $t('fes.form.errorLocation')
              }}</span>
            </h3>
          </div>

          <div class="card-body py-3">
            <div class="row" v-if="computedOrgOptions(0).length === 0">
              <div class="col">
                <div
                  class="alert alert-danger d-flex align-items-center p-5 mb-3"
                >
                  <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                    <inline-svg src="media/icons/duotune/general/gen025.svg" />
                  </span>
                  <div class="d-flex flex-column">
                    <h4 class="mb-1 text-danger">
                      {{ $t('fes.config.missing.title') }}
                    </h4>
                    <span>{{ $t('fes.config.missing.text') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="row mb-6"
              v-for="(orgLevel, index) of data.orgLevels"
              :key="index"
            >
              <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                computedOrgLevelHierarchy(index)
              }}</label>

              <div class="col-lg-8 fv-row">
                <Field
                  as="select"
                  name="orgUnit"
                  class="form-select form-select-solid form-select-lg"
                  v-model="data.orgLevels[index]"
                  @change="handleOrgUnitChange(index)"
                >
                  <option selected value="all" v-if="index !== 0">
                    {{ $t('all') }}
                  </option>
                  <option
                    v-for="(orgOption, indexInner) of computedOrgOptions(index)"
                    :key="indexInner"
                    :value="orgOption.id"
                  >
                    {{ orgOption.name }}
                  </option>
                </Field>
              </div>
            </div>
          </div>

          <div
            v-if="data.location.isReady && !data.location.isComplete"
            class="card-footer d-flex justify-content-end py-6 px-9"
          >
            <button
              @click="handleErrorLocationChange"
              type="submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> {{ $t('continue') }}</span>
            </button>
          </div>

          <div v-if="data.location.isComplete" class="card-body py-3">
            <div
              class="row mb-6"
              v-for="(orgLevel, index) of selectedOrgLevels"
              :key="index"
            >
              <div class="col-lg-4 fw-bold fs-6">
                {{ computedOrgLevelHierarchy(index) }}
              </div>

              <div class="col-lg-8 fv-row">
                <span>{{ orgLevel.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="alert alert-primary d-flex align-items-center p-5 mb-3"
      v-if="computedHasErrorCaseSource"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/files/fil013.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('opl.form.connectedErrorCase.title') }}
        </h4>
        <span>{{
          $t('opl.form.connectedErrorCase.text', {
            errorCaseId: data.currentSourceId,
          })
        }}</span>
      </div>

      <router-link
        class="ms-auto"
        v-if="data.currentSource === 'fes'"
        :to="`/opl/fes/${data.currentSourceId}`"
      >
        <el-button size="small" type="primary">
          <i class="bi bi-folder-symlink text-white me-3"></i>
          {{ $t('opl.goToErrorCaseLink') }}
        </el-button>
      </router-link>
    </div>

    <MyForm
      v-if="isLocationComplete"
      :validation-schema="createOpenPointValidator"
      @submit="handleSubmit"
      novalidate="novalidate"
      action="javascript:"
      method="post"
    >
      <open-point-collection-form
        :headline="data.headline"
        :source="data.currentSource"
        :sourceId="data.currentSourceId"
        :openPointParent="computedParent"
        :model-value="data.openPoints"
        @update:modelValue="handleUpdateOpenPoints"
      />
      <!-- <Field v-show="0" v-model="data.errorCase.openPoints" name="openPoints" /> -->

      <div v-if="data.openPoints.length" class="d-flex flex-stack pt-10 px-9">
        <div>
          <button type="submit" class="btn btn-lg btn-primary">
            <span v-if="data.openPoints.length === 1"
              >1 {{ $t('opl.form.createBtn.single') }}</span
            >
            <span v-else
              >{{ data.openPoints.length }}
              {{ $t('opl.form.createBtn.plural') }}</span
            >

            <span class="svg-icon svg-icon-4 ms-1 me-0">
              <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
            </span>
          </button>
        </div>
      </div>
    </MyForm>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
  } from 'vue';
  import * as yup from 'yup';
  import OpenPointCollectionForm from '@/components/opl/OpenPointCollectionForm.vue';
  import {
    OpenPoint,
    OpenPointHierarchyName,
    OpenPointLocation,
    OPLConfig,
  } from '@/core/models/opl';
  import { Field, Form } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import ApiService from '@/core/services/ApiService';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import Swal from 'sweetalert2';
  import {
    ErrorCase,
    ErrorCaseLocation,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/fes';
  import { calculateDepth, parseCurrentOrgLevels } from '@/core/helpers/cdf';
  import { SetErrorLocationContext } from '@/store/modules/AuthModule';
  import { useRoute } from 'vue-router';

  export default defineComponent({
    name: 'oplCreateForm',
    components: {
      OpenPointCollectionForm,
      MyForm: Form,
      Field,
      OrgUnitPath,
    },
    props: {
      headline: {
        type: String,
        required: false,
      },
      oplParent: {
        type: Object as PropType<OpenPoint | null>,
        required: false,
        default: null,
      },
    },
    emits: ['created'],
    setup(props, { emit }) {
      const { t } = useI18n();
      const store = useStore();
      const currentRoute = useRoute();

      const data: {
        headline: string;
        openPoints: OpenPoint[];
        openPointsToSave: OpenPoint[];
        location: {
          isLoading: boolean;
          isReady: boolean;
          isComplete: boolean;
        };
        orgLevels: string[];
        orgChart: OrgChartDataPairWithCatalogs[];
        hierarchyNames: OpenPointHierarchyName[];
        currentSource: string | null;
        currentSourceId: string | null;
      } = reactive({
        headline: props.headline || t('opl.form.heading'),
        openPoints: [],
        openPointsToSave: [],
        location: {
          isLoading: true,
          isReady: false,
          isComplete: false,
        },
        orgLevels: [],
        orgChart: [],
        hierarchyNames: [],
        currentSource: null,
        currentSourceId: null,
      });

      const createOpenPointValidator = yup.object().shape({
        openPoints: yup.array().of(
          yup.object().shape({
            title: yup
              .string()
              .min(1, t('opl.errors.missingTitle'))
              .required(t('opl.errors.missingTitle'))
              .typeError(t('opl.errors.missingTitle')),
            comment: yup
              .string()
              .when('isUrgent', {
                is: (value) => {
                  return !value;
                },
                then: yup
                  .string()
                  .min(1, t('opl.errors.missingComment'))
                  .required(t('fes.form.errors.missingComment')),
              })

              .typeError(t('opl.errors.missingComment')),
            initialAssignees: yup.array().when('isUrgent', {
              is: false,
              then: yup
                .array()
                .min(1, t('opl.errors.missingAssignee'))
                .required(t('opl.errors.missingAssignee'))
                .typeError(t('opl.errors.missingAssignee')),
            }),
            isUrgent: yup.boolean(),
            dueDate: yup
              .string()
              .label(t('opl.form.dueDate'))
              .when('isUrgent', {
                is: false,
                then: yup
                  .string()
                  .required(t('opl.errors.missingDueDate'))
                  .typeError(t('opl.errors.missingDueDate')),
              })
              .test(
                'isDateInFuture',
                t('opl.errors.dueDateNotInFuture'),
                (value) => {
                  if (value) {
                    const date = new Date(value);
                    date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
                    const now = new Date();
                    now.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
                    return date >= now; // >= instead of > to allow same day
                  }
                  return true;
                },
              ),
            // children: yup.array().of(
            //   yup.object().shape({
            //     title: yup
            //       .string()
            //       .min(1)
            //       .required(t('opl.errors.missingTitle'))
            //       .typeError(t('opl.errors.missingTitle')),
            //     comment: yup
            //       .string()
            //       .when('isUrgent', {
            //         is: (value) => {
            //           return !value;
            //         },
            //         then: yup
            //           .string()
            //           .min(1)
            //           .required(t('fes.form.errors.missingComment')),
            //       })

            //       .typeError(t('opl.errors.missingComment')),
            //     initialAssignees: yup.array().when('isUrgent', {
            //       is: false,
            //       then: yup
            //         .array()
            //         .min(1, t('opl.errors.missingAssignee'))
            //         .required(t('opl.errors.missingAssignee'))
            //         .typeError(t('opl.errors.missingAssignee')),
            //     }),
            //     isUrgent: yup.boolean(),
            //     dueDate: yup
            //       .string()
            //       .label(t('opl.form.dueDate'))
            //       .when('isUrgent', {
            //         is: false,
            //         then: yup
            //           .string()
            //           .required(t('opl.errors.missingDueDate'))
            //           .typeError(t('opl.errors.missingDueDate')),
            //       }),
            //   }),
            // ),
          }),
        ),
      });

      async function handleSubmit() {
        if (data.openPoints.length === 0) return false;
        store.dispatch(Actions.START_LOADER);
        ApiService.post('opl/create', {
          data: data.openPoints,
        })
          .then((response) => {
            emit('created', response.data.slice(0));
            data.openPoints = [] as OpenPoint[];
            data.openPointsToSave = [] as OpenPoint[];
            if (!computedHasParent.value) {
              Swal.fire(t('done'), t('opl.form.created'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      function handleUpdateOpenPoints(openPoints: OpenPoint[]) {
        if (computedHasParent.value) {
          data.openPoints = openPoints.map((openPoint: OpenPoint) => {
            return {
              ...openPoint,
              locations: computedParent.value?.locations.map<OpenPointLocation>(
                (location: OpenPointLocation) => {
                  delete location.id;
                  return location;
                },
              ),
            };
          });
          return;
        }

        const currentLocations = selectedOrgLevels.value.map(
          (orgLevel, index) => {
            const orgLevelIdentifier = computedOrgLevelHierarchy(index);
            return {
              locationIdentifier: orgLevelIdentifier,
              locationName: String(orgLevel?.name),
              locationId: String(orgLevel?.id),
              pos: index,
            };
          },
        );
        data.openPoints = openPoints.map((openPoint: OpenPoint) => {
          openPoint.locations = currentLocations.slice(0);
          openPoint.children.map((openPointChild: OpenPoint) => {
            openPointChild.locations = currentLocations.slice(0);
            return openPointChild;
          });
          return openPoint;
        });
      }

      const isLocationComplete = computed(() => {
        return data.location.isComplete === true;
      });

      const computedCurrentLocation = computed(() => {
        return selectedOrgLevels.value
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          })
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      const selectedOrgLevels = computed(() => {
        return data.orgLevels
          .map((orgLevelId, index) => {
            const currentOptions = computedOrgOptions(index);
            return currentOptions.find((orgOption) => {
              return orgLevelId === orgOption.id;
            });
          })
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          });
      });

      const handleErrorLocationChange = async () => {
        if (typeof computedCurrentLocation.value === 'undefined') {
          return;
        }

        data.location.isComplete = true;
        store.commit(Mutations.SET_ERRORLOCATION, {
          orgLevels: data.orgLevels,
          identifier: 'currentOrgLevelsOPL',
        } as SetErrorLocationContext);
      };

      const computedAvailableOrgLevels = computed(() => {
        return data.orgChart.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.visible;
        });
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(computedAvailableOrgLevels.value);
        const rawDataParsed = JSON.parse(rawData);
        return rawDataParsed;
      };

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return data.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const computedOrgLevelHierarchy = (index): string => {
        return data.hierarchyNames[index]
          ? data.hierarchyNames[index].name
          : `Level ${index}`;
      };

      const hasOrgUnitChildren = (
        orgUnit: OrgChartDataPairWithCatalogs,
      ): boolean => {
        const rawDataParsed = getRawOrgChart();
        return rawDataParsed.some((element) => {
          return element.parentId === orgUnit.id;
        });
      };

      const handleOrgUnitChange = (index) => {
        if (data.orgLevels.length > index + 1) {
          data.orgLevels.splice(index + 1, data.orgLevels.length - index - 1);
        }

        const currentId = String(data.orgLevels[index]);

        const rawDataParsed: OrgChartDataPairWithCatalogs[] = getRawOrgChart();
        const currentNode = rawDataParsed.find(
          (element: OrgChartDataPairWithCatalogs) => element.id === currentId,
        );

        if (currentNode) {
          if (hasOrgUnitChildren(currentNode)) {
            data.orgLevels.push('');
            data.location.isReady = false;
          } else {
            data.location.isReady = true;
          }
        } else {
          if (currentId === 'all') {
            data.location.isReady = true;
          } else {
            data.location.isReady = false;
          }
        }
      };

      const releaseErrorLocationForm = () => {
        try {
          data.location.isComplete = false;
        } catch (e) {
          data.orgLevels = [''];
          data.location.isComplete = false;
          data.location.isReady = false;
        }
      };

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        const oplConfig: OPLConfig = (await ApiService.get('opl/config')).data;
        data.hierarchyNames = oplConfig.hierarchyNames.slice(0);

        const orgChartDataResponse = await ApiService.get('opl/org-chart');
        data.orgChart = orgChartDataResponse.data.slice(0);
        data.orgChart = data.orgChart.map((orgUnit) => {
          return {
            ...orgUnit,
            depth: calculateDepth(orgUnit, data.orgChart),
            selectable: true,
            visible: true,
          };
        });

        data.orgLevels = parseCurrentOrgLevels(
          data.orgChart,
          computedCurrentLocation.value,
          undefined,
          'currentOrgLevelsOPL',
        );

        if (props.oplParent === null) {
          data.location.isReady = true;
          if (data.orgLevels.length === 1 && data.orgLevels[0] === '') {
            data.location.isReady = false;
          }
          await handleErrorLocationChange();

          const currentDeviceOrgLevels = window.localStorage.getItem(
            'currentOrgLevelsOPL',
          );

          if (currentDeviceOrgLevels !== null) {
            data.orgLevels.splice(0);
            JSON.parse(currentDeviceOrgLevels).forEach((orgLevel) => {
              data.orgLevels.push(orgLevel);
            });

            const maxDepth = Math.max(
              ...data.orgChart.map(
                (orgUnit: OrgChartDataPairWithCatalogs): number => {
                  return orgUnit.depth ? orgUnit.depth : 0;
                },
              ),
            );
            if (data.orgLevels.length !== maxDepth) {
              if (data.orgLevels.slice(0).pop() !== 'all') {
                data.orgLevels.push('all');
              }
            }
            if (computedCurrentLocation.value) {
              const currentLocation =
                computedCurrentLocation.value as OrgChartDataPairWithCatalogs;
              if (!currentLocation) {
                data.orgLevels = [data.orgChart[0].id];
              } else {
                data.location.isReady = true;
                await handleErrorLocationChange();
              }
            } else {
              const rootUnit = data.orgChart.find(
                (orgUnit: OrgChartDataPairWithCatalogs) => {
                  return orgUnit.parentId === '';
                },
              );
              if (rootUnit) {
                data.orgLevels = [rootUnit.id];
              }
              // data.orgLevels = [data.orgChart[0].id];
              data.orgLevels.push('');
            }
          } else {
            if (data.orgLevels.length === 0) {
              data.orgLevels.push('');
            }
          }
        } else {
          data.location.isComplete = true;
        }

        if (
          typeof currentRoute.params.source !== 'undefined' &&
          typeof currentRoute.params.sourceId !== 'undefined'
        ) {
          data.currentSource = currentRoute.params.source as string;
          data.currentSourceId = currentRoute.params.sourceId as string;

          if (data.currentSource === 'fes') {
            const loadedErrorCase: ErrorCase = await (
              await ApiService.get(`fes/errors/${currentRoute.params.sourceId}`)
            ).data;

            if (loadedErrorCase.locations !== null) {
              data.orgLevels = loadedErrorCase.locations.map(
                (location: ErrorCaseLocation) => {
                  return String(location.locationId);
                },
              );
              data.location.isComplete = true;
            }
          }
        }

        data.location.isLoading = false;
        store.dispatch(Actions.END_LOADER);
      });

      const computedHasParent = computed(() => {
        return props.oplParent !== null;
      });

      const computedParent = computed(() => {
        return props.oplParent;
      });

      const computedHasErrorCaseSource = computed(() => {
        return data.currentSource !== null && data.currentSourceId !== null;
      });

      return {
        data,
        computedParent,
        handleSubmit,
        handleUpdateOpenPoints,
        createOpenPointValidator,
        isLocationComplete,
        computedOrgOptions,
        computedOrgLevelHierarchy,
        handleOrgUnitChange,
        handleErrorLocationChange,
        computedCurrentLocation,
        selectedOrgLevels,
        releaseErrorLocationForm,
        computedHasParent,
        computedHasErrorCaseSource,
      };
    },
  });
</script>

<style lang="scss">
  tbody tr {
    cursor: pointer;
  }

  img {
    max-height: 250px;
    max-width: 250px;
  }
</style>
