<template>
  <span>
    <span v-if="processInterruption.category">{{
      processInterruption.category.name
    }}</span>
    <span v-else class="badge badge-light-dark">{{ $t('unknown') }}</span>
    <i class="fa fa-chevron-right mx-2"></i>
    <span v-if="processInterruption.subCategory">
      {{ processInterruption.subCategory.name }}</span
    >
    <span v-else class="badge badge-light-dark">{{ $t('unknown') }}</span>
    <i class="fa fa-chevron-right mx-2"></i>
    <span v-if="processInterruption.subSubCategory">
      {{ processInterruption.subSubCategory.name }}</span
    >
    <span v-else class="badge badge-light-dark">{{ $t('unknown') }}</span>
  </span>
</template>
<script lang="ts">
  import { ProcessInterruption } from '@/core/models/fes';
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    name: 'processInterruptionPath',
    components: {},
    props: {
      processInterruption: {
        type: Object as PropType<ProcessInterruption>,
        required: true,
      },
    },
    setup(props) {
      return {
        ...props,
      };
    },
  });
</script>
