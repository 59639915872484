<template>
  <span class="filter-options">
    <button
      type="button"
      class="btn btn btn-flex btn-light btn-active-primary fw-bolder position-relative"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-start"
      data-kt-menu-flip="top-end"
    >
      <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
        <inline-svg src="media/icons/duotune/general/gen031.svg" />
      </span>
      {{ $t('filter') }}
      <span
        v-if="data.currentTags.length !== 0"
        class="bullet bullet-dot bg-primary h-10px w-10px position-absolute translate-middle top-0 start-100"
      ></span>
    </button>
    <div
      class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div class="px-7 py-5">
        <div class="fs-5 text-dark fw-bolder">{{ $t('filterOptions') }}</div>
      </div>

      <div class="separator border-gray-200"></div>

      <div class="px-7 py-5">
        <div class="mb-10">
          <label class="form-label fw-bold">{{ $t('tags') }}:</label>

          <multiselect
            ref="tagMultiselect"
            mode="tags"
            v-model="data.currentTags"
            :options="computedTagsOptions"
            :searchable="true"
            :minChars="1"
            :createTag="false"
            @search-change="handleTagSearch"
            @change="handleTagSelectChange"
          />
        </div>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  import Multiselect from '@vueform/multiselect';
  import ApiService from '@/core/services/ApiService';

  export default defineComponent({
    name: 'filterOptions',
    emits: ['filterOptions'],
    components: {
      Multiselect,
    },
    setup(params, context) {
      const data = ref({
        currentTags: [],
        currentTagsOptions: [],
      });

      const computedTagsOptions = computed(() => {
        return [...data.value.currentTags, ...data.value.currentTagsOptions];
      });

      const updateFilterOptions = () => {
        context.emit('filterOptions', data.value.currentTags);
      };

      async function handleTagSearch(search: string) {
        if (search.length < 1) return;

        const response = await ApiService.post('org-chart/tags', {
          data: {
            query: search,
          },
        });

        data.value.currentTagsOptions = response.data;
      }

      function handleTagSelectChange(value) {
        data.value.currentTags = value;
        context.emit('filterOptions', data.value.currentTags);
      }

      const resetFilterOptions = () => {
        data.value.currentTags = [];
        context.emit('filterOptions', data.value.currentTags);
      };

      return {
        data,
        computedTagsOptions,
        resetFilterOptions,
        updateFilterOptions,
        handleTagSelectChange,
        handleTagSearch,
      };
    },
  });
</script>
