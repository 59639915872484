<template>
  <div :class="props.class">
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestCauseReasonCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritCauseCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestCauseReasonCatalogPath" />
      </div>
    </div>
    <div
      class="row"
      v-if="!data.currentNode || !data.currentNode.causeReasonCatalog"
    >
      <a
        class="col d-flex justify-content-start align-items-center"
        @click="openCauseReasonCatalog"
        :title="$t('fes.config.linkButtonNew')"
        href="javascript:"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-3"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualCauseReasonCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualCauseReasonCatalog') }}
          </h4>
          <span>
            {{
              $t('fes.config.selectedIndividualCauseReasonCatalog', {
                id: data.currentNode.causeReasonCatalog.id,
                date: computedDate(
                  data.currentNode.causeReasonCatalog.createdAt,
                ),
                amount: data.currentNode.causeReasonCatalog.causeReasons.length,
              })
            }}
          </span>
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openCauseReasonCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteCauseReasonCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerCauseReasonCatalog"
      :title="$t('fes.config.causeReasonCatalog')"
      width="60vw"
    >
      <org-unit-path :nodePath="props.nodePath" :areaNames="props.areaNames" />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestCauseReasonCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritCauseCatalog') }}
          </h4>
          <cdf-path :node-path="computedNearestCauseReasonCatalogPath" />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestCauseReasonCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h4>
        {{ $t('fes.config.availableCauseReasonsInCatalog') }}
        {{ computedNodePathLastElement }}
      </h4>
      <p v-if="!computedCatalogHasCauseReasons" class="my-5">
        {{ $t('fes.config.noCauseReasonSelected') }}
      </p>

      <div v-if="computedCatalogHasCauseReasons">
        <div
          class="row d-flex mb-2 align-items-center"
          v-for="causeReason of computedCurrentCatalogCauseReasons"
          :key="causeReason.id"
        >
          <div class="col">{{ causeReason.name }}</div>
          <div class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="disconnectCauseReasonFromCatalog(causeReason)"
              :title="$t('fes.config.disconnect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-minus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <hr class="my-10" />
      <h4>{{ $t('fes.config.allCauseReasons') }}</h4>
      <div v-if="computedCauseReasonSelects.length" class="mb-4">
        <MyForm
          class="row d-flex mb-2 align-items-center"
          v-for="(causeReason, index) of computedCauseReasonSelects"
          :key="index"
          @submit="handleCauseReasonUpdate(causeReason)"
          :validation-schema="nameValidator"
        >
          <div class="col">
            <span v-if="causeReason.edit === false">{{
              causeReason.name
            }}</span>
            <div v-else>
              <Field
                type="text"
                class="form-control form-control-sm form-control-solid"
                name="name"
                v-model="causeReason.name"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="causeReason.edit === false" class="col text-end">
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="connectCauseReasonWithCatalog(causeReason)"
              :title="$t('fes.config.connect')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-plus"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              @click="editCauseReason(causeReason)"
              :title="$t('edit')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-edit"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="deleteCauseReason(causeReason)"
              :title="$t('remove')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-trash"></i>
              </span>
            </a>
          </div>
          <div v-else class="col text-end">
            <button
              type="submit"
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
              :title="$t('save')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-save"></i>
              </span>
            </button>
            <a
              href="javascript:"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
              @click="discardCauseReasonEdit(causeReason)"
              :title="$t('cancel')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-times"></i>
              </span>
            </a>
          </div>
        </MyForm>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-secondary"
            @click="addNewCauseReason"
            :disabled="computedIsNewFormOpen"
          >
            <i class="fa fa-plus"></i>
            {{ $t('fes.config.addCauseReason') }}
          </button>
        </div>
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import { computed, defineComponent, PropType, reactive, watch } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from './OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ErrorCaseCause,
    ErrorCaseCauseWithForm,
    ErrorCaseCauseReasonWithForm,
    ErrorCaseCauseReason,
    CauseReasonCatalog,
  } from '@/core/models/fes';

  export default defineComponent({
    name: 'causeReasonCatalogDrawer',
    props: {
      class: {
        type: String,
        required: false,
      },
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      // SimpleModal,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    computed: {
      // computedTitle() {
      //   return this.title;
      // },
      // computedWidth() {
      //   return this.width || '700px';
      // },
      // computedId() {
      //   return this.id;
      // },
      // computedPath() {
      //   return this.path || '';
      // },
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs;
        causeReasons: ErrorCaseCauseReasonWithForm[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        causeReasons: [] as ErrorCaseCauseReasonWithForm[],
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const computedCatalogHasCauseReasons = computed(() => {
        return (
          data.currentNode !== null &&
          data.currentNode.causeReasonCatalog !== null &&
          typeof data.currentNode.causeReasonCatalog.causeReasons !==
            'undefined' &&
          data.currentNode.causeReasonCatalog.causeReasons.length !== 0
        );
      });

      const computedCauseReasonSelects = computed(() => {
        if (
          data.currentNode !== null &&
          data.currentNode.causeReasonCatalog !== null &&
          typeof data.currentNode.causeReasonCatalog.causeReasons !==
            'undefined'
        ) {
          return data.causeReasons.filter(
            (cause: ErrorCaseCauseReasonWithForm) => {
              const isSelected =
                data.currentNode.causeReasonCatalog?.causeReasons.find(
                  (item) => {
                    return item.id === cause.id;
                  },
                );
              if (isSelected) return false;
              return true;
            },
          );
        }
        return data.causeReasons;
      });

      const openCauseReasonCatalog = () => {
        openSideDrawer('fesConfigDrawerCauseReasonCatalog');
      };

      function connectCauseReasonWithCatalog(
        causeReason: ErrorCaseCauseReason,
      ) {
        const newCause: ErrorCaseCause = Object.assign({}, causeReason);
        if (data.currentNode.causeReasonCatalog === null) {
          data.currentNode.causeReasonCatalog = {
            orgUnitId: data.currentNode.id,
            causeReasons: [] as ErrorCaseCauseReason[],
          } as CauseReasonCatalog;
        }
        data.currentNode.causeReasonCatalog.causeReasons.push(newCause);
        updateCurrentCauseReasonCatalog();
      }

      function editCause(cause: ErrorCaseCauseWithForm) {
        cause.edit = true;
        cause.name = cause.nameBefore;
      }

      function discardCauseReasonEdit(cause: ErrorCaseCauseReasonWithForm) {
        if (!cause.id) return data.causeReasons.pop();
        cause.edit = false;
        cause.name = cause.nameBefore;
      }

      async function deleteCauseReason(causeReason: ErrorCaseCauseReason) {
        const causeReasonsInTree = data.orgChartData
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.causeReasonCatalog === null) return false;
            const catalog = orgUnit.causeReasonCatalog;
            return catalog.causeReasons.find(
              (item) => item.id === causeReason.id,
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (causeReasonsInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: causeReasonsInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            causeReasonsInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteCauseReasonQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        // const decisionId = decision.id;

        await ApiService.delete(`fes/config/causereason/${causeReason.id}`);

        data.causeReasons = data.causeReasons.filter(
          (loopedErrorCauseReason: ErrorCaseCauseReason) => {
            return loopedErrorCauseReason.id !== causeReason.id;
          },
        );

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.causeReasonCatalog !== null &&
                typeof orgUnit.causeReasonCatalog.causeReasons !== 'undefined'
              ) {
                const filteredCauseReasons =
                  orgUnit.causeReasonCatalog.causeReasons.filter(
                    (loopedErrorCauseReason: ErrorCaseCauseReason) =>
                      loopedErrorCauseReason.id !== causeReason.id,
                  );

                if (
                  filteredCauseReasons.length === 0 &&
                  orgUnit.causeReasonCatalog.id
                ) {
                  await deleteCauseReasonCatalog(orgUnit.causeReasonCatalog.id);
                  orgUnit.causeReasonCatalog = null;
                  return orgUnit;
                }
                orgUnit.causeReasonCatalog.causeReasons = filteredCauseReasons;
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteCauseReasonCatalog(id = 0) {
        if (data.currentNode.causeReasonCatalog === null) return;
        store.dispatch(Actions.START_LOADER);
        // const causeReasonCatalogId = data.currentNode.causeReasonCatalog.id;
        const causeReasonCatalogId =
          id !== 0 ? id : data.currentNode.causeReasonCatalog.id;

        await ApiService.delete(
          `fes/config/catalog/causereason/${causeReasonCatalogId}`,
        );

        data.currentNode.causeReasonCatalog = null;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteCauseReasonCatalog(id = 0) {
        if (data.currentNode.causeReasonCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteCauseReasonCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteCauseReasonCatalog(id);
      }

      async function disconnectCauseReasonFromCatalog(
        causeReason: ErrorCaseCauseReason,
      ) {
        if (data.currentNode.causeReasonCatalog !== null) {
          const filteredCauseReasons =
            data.currentNode.causeReasonCatalog?.causeReasons.filter(
              (loopedErrorCauseReason: ErrorCaseCauseReason) =>
                loopedErrorCauseReason.id !== causeReason.id,
            );

          if (filteredCauseReasons.length === 0) {
            await deleteCauseReasonCatalog();
          }
          data.currentNode.causeReasonCatalog.causeReasons =
            filteredCauseReasons;
          updateCurrentCauseReasonCatalog();
        }
      }

      function addNewCauseReason() {
        const newCauseReason: ErrorCaseCauseReasonWithForm = {
          name: '',
          nameBefore: '',
          edit: true,
        };
        data.causeReasons.push(newCauseReason);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function updateCurrentCauseReasonCatalog() {
        if (
          data.currentNode.causeReasonCatalog !== null &&
          data.currentNode.causeReasonCatalog?.causeReasons.length === 0
        ) {
          return Swal.fire(
            'Error',
            'Please add at least one cause reason.',
            'error',
          );
        }

        store.dispatch(Actions.START_LOADER);

        const causeReasonCatalog: CauseReasonCatalog = Object.assign(
          {},
          data.currentNode.causeReasonCatalog,
        );

        const newSavedCauseReasonCatalog = await ApiService.post(
          'fes/config/catalog/causereason',
          {
            data: causeReasonCatalog,
          },
        );

        data.currentNode.causeReasonCatalog = newSavedCauseReasonCatalog.data;

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedCurrentCatalogCauseReasons = computed(() => {
        if (data.currentNode.causeReasonCatalog === null) return [];
        return data.currentNode.causeReasonCatalog.causeReasons;
      });

      async function updateAvailableCauseReasons() {
        data.causeReasons = (await (
          await ApiService.get('fes/config/causereasons')
        ).data.map((causeReason: ErrorCaseCauseReason) => {
          const causeObject = Object.assign(
            {
              edit: false,
              nameBefore: causeReason.name,
            } as ErrorCaseCauseReasonWithForm,
            causeReason,
          );
          return causeObject;
        })) as ErrorCaseCauseReasonWithForm[];
      }

      async function handleCauseReasonUpdate(
        causeReason: ErrorCaseCauseReasonWithForm,
      ) {
        store.dispatch(Actions.START_LOADER);
        causeReason.edit = false;
        causeReason.nameBefore = causeReason.name;

        const causeReasonUpdateObject: ErrorCaseCauseReason = {
          name: causeReason.name,
        };

        if (causeReason.id) {
          causeReasonUpdateObject.id = causeReason.id;
        }

        const savedCauseReason: AxiosResponse<ErrorCaseCauseReason> =
          await ApiService.post('fes/config/causereason', {
            data: causeReasonUpdateObject,
          });

        if (!causeReason.id) {
          causeReason.id = savedCauseReason.data.id;
        }

        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestCauseReasonCatalogPath = computed(() => {
        if (computedNearestCauseReasonCatalog.value === false) return '';
        const currentNearestCauseReasonCatalog: CauseReasonCatalog =
          computedNearestCauseReasonCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.causeReasonCatalog === null) return false;
            return (
              orgUnit.causeReasonCatalog?.id ===
              currentNearestCauseReasonCatalog.id
            );
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedNearestCauseReasonCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentNode.causeReasonCatalog !== null) return false;

        const nearestCauseReasonCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'causeReasonCatalog',
        );

        if (nearestCauseReasonCatalog) {
          return nearestCauseReasonCatalog;
        }

        return false;
      });

      const computedIsNewFormOpen = computed(() => {
        return (
          computedCauseReasonSelects.value.filter(
            (causeReason: ErrorCaseCauseReasonWithForm) => {
              return causeReason.edit === true;
            },
          ).length > 0
        );
      });

      async function copyNearestCauseReasonCatalog() {
        const currentNearestCauseReasonCatalog: CauseReasonCatalog =
          computedNearestCauseReasonCatalog.value;
        const causeReasonCatalogCopy: CauseReasonCatalog = {
          orgUnitId: data.currentNode.id,
          causeReasons: currentNearestCauseReasonCatalog.causeReasons,
        } as CauseReasonCatalog;
        data.currentNode.causeReasonCatalog = causeReasonCatalogCopy;
        await updateCurrentCauseReasonCatalog();
      }

      watch(
        () => props.currentNode,
        () => {
          if (props.currentNode !== null) {
            data.currentNode = props.currentNode;
            data.orgChartData = props.orgChartData;
            updateAvailableCauseReasons();
          }
        },
      );

      return {
        data,
        computedNodePathLastElement,
        computedCatalogHasCauseReasons,
        computedCauseReasonSelects,
        computedCurrentCatalogCauseReasons,
        connectCauseReasonWithCatalog,
        disconnectCauseReasonFromCatalog,
        addNewCauseReason,
        // handleDecisionSubmit,
        nameValidator,
        editCause,
        discardCauseReasonEdit,
        deleteCauseReason,
        handleDeleteCauseReasonCatalog,
        openCauseReasonCatalog,
        props,
        computedDate,
        handleCauseReasonUpdate,
        computedNearestCauseReasonCatalog,
        computedNearestCauseReasonCatalogPath,
        copyNearestCauseReasonCatalog,
        computedIsNewFormOpen,
      };
    },
  });
</script>
