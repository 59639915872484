<template>
  <KTLoader v-if="loaderEnabled" :logo="loaderLogo"></KTLoader>

  <!-- begin:: Body -->
  <div class="page d-flex flex-row flex-column-fluid">
    <!-- begin:: Aside Left -->
    <KTAside
      v-if="asideEnabled"
      :lightLogo="themeLightLogo"
      :darkLogo="themeDarkLogo"
    ></KTAside>
    <!-- end:: Aside Left -->

    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <KTHeader :title="pageTitle"></KTHeader>

      <!-- begin:: Content -->
      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <!-- begin:: Content Body -->
        <div class="post d-flex flex-column-fluid">
          <div
            id="kt_content_container"
            :class="{
              'container-fluid': contentWidthFluid,
              'container-xxl': !contentWidthFluid,
            }"
          >
            <router-view :key="$route.fullPath" />
          </div>
        </div>
        <!-- end:: Content Body -->
      </div>
      <!-- end:: Content -->
      <KTFooter></KTFooter>
    </div>
  </div>
  <!-- end:: Body -->
  <KTScrollTop></KTScrollTop>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, reactive, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import KTAside from '@/layout/aside/Aside.vue';
  import KTHeader from '@/layout/header/Header.vue';
  import KTFooter from '@/layout/footer/Footer.vue';
  import HtmlClass from '@/core/services/LayoutService';
  import KTScrollTop from '@/layout/extras/ScrollTop.vue';
  import KTLoader from '@/components/Loader.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import { MenuComponent } from '@/assets/ts/components/index';
  import {
    initializeComponents,
    reinitializeComponents,
  } from '@/core/plugins/keenthemes';
  import {
    toolbarDisplay,
    loaderEnabled,
    contentWidthFluid,
    loaderLogo,
    asideEnabled,
    subheaderDisplay,
    themeLightLogo,
    themeDarkLogo,
  } from '@/core/helpers/config';
  import { removeModalBackdrop } from '@/core/helpers/dom';

  export default defineComponent({
    name: 'Layout',
    components: {
      KTAside,
      KTHeader,
      KTFooter,
      KTScrollTop,
      KTLoader,
    },
    setup() {
      const store = useStore();
      const route = useRoute();

      // show page loading
      store.dispatch(Actions.START_LOADER);

      // initialize html element classes
      HtmlClass.init();

      const pageTitle = computed(() => {
        return store.getters.pageTitle;
      });

      const breadcrumbs = computed(() => {
        return store.getters.pageBreadcrumbPath;
      });

      const data = reactive({
        menuInitialized: false,
      });

      onMounted(async () => {
        const isAuthenticated = await store.dispatch(Actions.VERIFY_AUTH);
        if (isAuthenticated === false) {
          store.dispatch(Actions.REDIRECT_TO_SIGNIN);
          return false;
        }
        if (data.menuInitialized === false) {
          initializeComponents();
          data.menuInitialized = true;
        }
        store.dispatch(Actions.END_LOADER);
      });

      watch(
        () => route.path,
        async () => {
          MenuComponent.hideDropdowns(undefined);
          reinitializeComponents();
          removeModalBackdrop();
        },
      );

      return {
        toolbarDisplay,
        loaderEnabled,
        contentWidthFluid,
        loaderLogo,
        asideEnabled,
        subheaderDisplay,
        pageTitle,
        breadcrumbs,
        themeLightLogo,
        themeDarkLogo,
      };
    },
  });
</script>
