<template>
  <div>
    <div
      class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-3"
      v-if="computedNearestRiskCatalog"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('fes.config.inheritRiskCatalog') }}
        </h4>
        <cdf-path :node-path="computedNearestRiskCatalogPath" />
      </div>
    </div>
    <div class="row" v-if="!computedCurrentNodeRiskCatalog">
      <a
        class="col d-flex justify-content-start align-items-center"
        href="javascript:"
        :title="$t('fes.config.linkButtonNew')"
        @click="openRiskCatalog"
      >
        <span
          class="btn btn btn-icon btn-bg-light btn-active-color-primary me-3"
        >
          <span class="svg-icon svg-icon-2">
            <i class="fa fa-plus"></i>
          </span>
        </span>
        <span>
          {{ $t('fes.config.individualRiskCatalog') }}
        </span>
      </a>
    </div>
    <div class="mt-3" v-else>
      <div class="alert alert-success d-flex align-items-center p-5 mb-10">
        <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
          <inline-svg src="media/icons/duotune/abstract/abs014.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-success">
            {{ $t('fes.config.individualRiskCatalog') }}
          </h4>
          <span>
            {{
              $t('fes.config.selectedIndividualRiskCatalog', {
                id: data.currentNode.riskCatalog.id,
                date: computedDate(data.currentNode.riskCatalog.createdAt),
                amount: data.currentNode.riskCatalog.locationRisks.length,
              })
            }}</span
          >
        </div>
        <button
          type="button"
          class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
          @click="openRiskCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-edit" aria-hidden="true"></i>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-light-danger btn-icon position-absolute position-sm-relative top-0 end-0"
          @click="handleDeleteRiskCatalog()"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawerRiskCatalog"
      :title="$t('fes.config.riskCatalog')"
      width="90vw"
    >
      <org-unit-path
        :nodePath="props.nodePath"
        :areaNames="props.areaNames"
        :levelSubstract="1"
      />

      <div
        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
        v-if="computedNearestRiskCatalog"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <div class="d-flex flex-column">
          <h4 class="mb-1 text-primary">
            {{ $t('fes.config.inheritRiskCatalog') }}
          </h4>
          <cdf-path :node-path="computedNearestRiskCatalogPath" />
        </div>
        <button
          type="button"
          class="btn btn-light-primary position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
          @click="copyNearestRiskCatalog"
        >
          <span class="svg-icon svg-icon-1">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </span>
          {{ $t('fes.config.useFromTemplate') }}
        </button>
      </div>

      <h1 class="fs-2 fw-bold my-2 mb-5">
        {{ $t('fes.config.riskCatalogLevels') }}
      </h1>

      <p v-if="computedLocationRiskCategoriesActive.length === 0">
        {{ $t('fes.config.missingCategoriesRiskCatalog') }}
      </p>

      <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5 nav-line-tabs-2x">
        <li
          class="nav-item"
          v-for="(
            locationRiskCategory, index
          ) of computedLocationRiskCategoriesActive"
          :key="index"
        >
          <span class="d-flex align-items-center">
            <a
              :class="`nav-link me-3 ${
                data.currentLocationRiskCategoryId === locationRiskCategory.id
                  ? 'active'
                  : ''
              }`"
              href="javascript:"
              @click="handleLocationRiskCategoryChange(locationRiskCategory)"
            >
              <span
                v-if="
                  computedLocationRiskCategoryErrorStyleAmount(
                    locationRiskCategory,
                  ) === 0
                "
                >{{ locationRiskCategory.name }}</span
              >
              <strong v-else
                >{{ locationRiskCategory.name }} ({{
                  computedLocationRiskCategoryErrorStyleAmount(
                    locationRiskCategory,
                  )
                }})</strong
              >
            </a>
            <a
              href="javascript:"
              class="btn-icon me-3"
              @click="editLocationRiskCategory(locationRiskCategory)"
              :title="$t('edit')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-edit fs-8"></i>
              </span>
            </a>
            <a
              href="javascript:"
              class="btn-icon me-3"
              @click="
                disconnectLocationRiskCategoryWithCatalog(locationRiskCategory)
              "
              :title="$t('remove')"
            >
              <span class="svg-icon svg-icon-2">
                <i class="fa fa-trash fs-8"></i>
              </span>
            </a>
          </span>
        </li>
      </ul>

      <MyForm @submit="handleRiskCatalogSubmit" action="javascript:">
        <div
          v-for="(
            locationRiskCategory, index
          ) of computedLocationRiskCategoriesActive"
          :key="index"
          :id="`#categoryTab${locationRiskCategory.id}`"
          v-show="
            data.currentLocationRiskCategoryId === locationRiskCategory.id
          "
        >
          <table
            class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
          >
            <thead>
              <tr>
                <th width="20%">
                  <span
                    >{{ $t('fes.form.moreDetails.errorPlace') }}
                    <i class="fa fa-arrow-down"></i
                  ></span>
                  /
                  <span
                    >{{ $t('fes.form.moreDetails.errorStyle') }}
                    <i class="fa fa-arrow-right"></i
                  ></span>
                </th>
                <th
                  v-show="computedIsTableFormReady"
                  v-for="(
                    selectedErrorStyle, errorStyleIndex
                  ) of computedCurrentErrorStylesForCategory"
                  :key="errorStyleIndex"
                >
                  <span class="me-2">{{ selectedErrorStyle.name }}</span>

                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="disconnectErrorStyleWithCatalog(selectedErrorStyle)"
                    :title="$t('fes.config.disconnect')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-minus"></i>
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody v-if="computedIsTableFormReady">
              <tr
                v-for="(
                  selectedErrorPlace, errorPlaceIndex
                ) of computedCurrentErrorPlacesForCategory"
                :key="errorPlaceIndex"
              >
                <td>
                  <span class="me-2">{{ selectedErrorPlace.name }}</span>

                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="
                      disconnectErrorPlaceWithCategory(selectedErrorPlace)
                    "
                    :title="$t('fes.config.disconnect')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-minus"></i>
                    </span>
                  </a>
                </td>
                <td
                  v-for="(
                    selectedErrorStyle, errorStyleIndex
                  ) in computedCurrentErrorStylesForCategory"
                  :key="errorStyleIndex"
                >
                  <div
                    v-for="(
                      errorRiskLocation, errorLocationRiskIndex
                    ) of computedCurrentLocationRisks"
                    :key="errorLocationRiskIndex"
                  >
                    <div
                      v-if="
                        selectedErrorStyle.id === errorRiskLocation.style.id &&
                        selectedErrorPlace.id === errorRiskLocation.place.id
                      "
                    >
                      <select
                        class="form-select form-select-solid"
                        v-model="errorRiskLocation.riskValue"
                        :name="`riskValue[${errorLocationRiskIndex}]`"
                        @change="handleRiskValueChange(errorRiskLocation)"
                      >
                        <option value="0">X</option>
                        <option
                          :key="riskLevelValueIndex"
                          v-for="(
                            riskLevelValue, riskLevelValueIndex
                          ) in computedRiskLevels"
                          :value="riskLevelValue"
                        >
                          {{ riskLevelValue }}
                        </option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="!computedIsTableFormReady">
            {{ $t('fes.config.missingErrorPlaceRiskCatalog') }}
          </p>
        </div>

        <div class="row">
          <div class="col text-end">
            <button
              type="submit"
              :disabled="!computedIsTableFormReady"
              href="javascript:"
              class="btn btn-sm btn-primary"
            >
              {{ $t('save') }}
            </button>
          </div>
        </div>

        <hr class="my-10" />

        <div class="row">
          <div class="col-12 col-md-4">
            <h2 class="fs-2 fw-bold my-2">
              {{ $t('fes.config.allErrorCategories') }}
              <span class="fs-6 text-gray-400 ms-1">
                {{
                  $t('xSelected', {
                    x: computedLocationRiskCategoriesActive.length,
                  })
                }}</span
              >
            </h2>
            <div v-if="computedLocationRiskCategoriesAll.length">
              <MyForm
                class="row d-flex mb-2 align-items-center"
                v-for="(
                  locationRiskCategory, locationRiskCategoryIndex
                ) of computedLocationRiskCategoriesAll"
                :key="locationRiskCategoryIndex"
                @submit="
                  (event) => {
                    handleLocationRiskCategoryUpdate(
                      locationRiskCategoryIndex,
                      event,
                    );
                  }
                "
                :validation-schema="nameValidator"
              >
                <div class="col">
                  <span v-if="locationRiskCategory.edit === false">{{
                    locationRiskCategory.name
                  }}</span>
                  <div v-else>
                    <Field
                      type="text"
                      class="form-control form-control-sm form-control-solid"
                      name="name"
                      v-model="locationRiskCategory.name"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="locationRiskCategory.edit === false"
                  class="col text-end"
                >
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="
                      connectLocationRiskCategoryWithCatalog(
                        locationRiskCategory,
                      )
                    "
                    :title="$t('fes.config.connect')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-plus"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="editEntity(locationRiskCategory)"
                    :title="$t('edit')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-edit"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="deleteLocationRiskCategory(locationRiskCategory)"
                    :title="$t('remove')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-trash"></i>
                    </span>
                  </a>
                </div>
                <div v-else class="col text-end">
                  <button
                    type="submit"
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    :title="$t('save')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-save"></i>
                    </span>
                  </button>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="
                      discardEntityEdit(
                        locationRiskCategory,
                        data.locationRiskCategoriesAll,
                      )
                    "
                    :title="$t('cancel')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-times"></i>
                    </span>
                  </a>
                </div>
              </MyForm>
            </div>

            <div class="row pt-3">
              <div class="col">
                <button
                  class="btn btn-secondary"
                  @click="
                    addNewLocationRiskCategory(data.locationRiskCategoriesAll)
                  "
                  :disabled="computedIsNewLocationRiskCategoryFormOpen"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('fes.config.addLocationRiskCategory') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <h2 class="fs-2 fw-bold my-2">
              {{ $t('fes.config.allErrorPlaces') }}
              <span class="fs-6 text-gray-400 ms-1">
                {{
                  $t('xSelected', {
                    x: computedCurrentErrorPlacesForCategory.length,
                  })
                }}</span
              >
            </h2>
            <div v-if="computedErrorPlacesSelects.length">
              <div
                v-for="(
                  errorPlace, errorPlaceIndex
                ) of computedErrorPlacesSelects"
                :key="errorPlaceIndex"
              >
                <MyForm
                  class="row d-flex mb-2 align-items-center"
                  @submit="
                    (event) => {
                      handleErrorPlaceUpdate(errorPlaceIndex, event);
                    }
                  "
                  :validation-schema="nameValidator"
                >
                  <div class="col">
                    <span v-if="errorPlace.edit === false">{{
                      errorPlace.name
                    }}</span>
                    <div v-else>
                      <Field
                        type="text"
                        class="form-control form-control-sm form-control-solid"
                        name="name"
                        v-model="errorPlace.name"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="errorPlace.edit === false" class="col text-end">
                    <a
                      v-if="computedLocationRiskCategoriesActive.length"
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                      @click="connectErrorPlaceWithCategory(errorPlace)"
                      :title="$t('fes.config.connect')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-plus"></i>
                      </span>
                    </a>

                    <a
                      v-if="computedLocationRiskCategoriesActive.length > 1"
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary position-relative me-2"
                      @click="connectErrorPlaceWithAllCategories(errorPlace)"
                      :title="$t('fes.config.connectAllErrorPlaces')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-plus"></i>
                      </span>
                      <span
                        class="position-absolute top-25 start-75 translate-middle"
                        ><i class="fa fa-plus fs-9"></i
                      ></span>
                    </a>
                    <a
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                      @click="editEntity(errorPlace)"
                      :title="$t('edit')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-edit"></i>
                      </span>
                    </a>
                    <a
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                      @click="deleteErrorPlace(errorPlace)"
                      :title="$t('remove')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-trash"></i>
                      </span>
                    </a>
                  </div>
                  <div v-else class="col text-end">
                    <button
                      type="submit"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                      :title="$t('save')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-save"></i>
                      </span>
                    </button>
                    <a
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                      @click="discardEntityEdit(errorPlace, data.errorPlaces)"
                      :title="$t('cancel')"
                    >
                      <span class="svg-icon svg-icon-2">
                        <i class="fa fa-times"></i>
                      </span>
                    </a>
                  </div>
                </MyForm>
              </div>
            </div>

            <div class="row pt-3">
              <div class="col">
                <button
                  class="btn btn-secondary"
                  @click="addNewErrorPlace(data.errorPlaces)"
                  :disabled="computedIsNewErrorPlaceFormOpen"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('fes.config.addErrorPlace') }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="data.currentLocationRiskCategoryId === null"
            class="col-12 col-md-4"
          >
            <h2 class="fs-2 fw-bold my-2">Fehlerarten</h2>
            <p>{{ $t('fes.config.errorCategoryNeeded') }}</p>
          </div>
          <div v-else class="col-12 col-md-4">
            <h2 class="fs-2 fw-bold my-2">
              Fehlerarten in
              {{ data.currentLocationRiskCategory.name }}
              <span class="fs-6 text-gray-400 ms-1">
                {{
                  $t('xSelected', {
                    x: computedCurrentErrorStylesForCategory.length,
                  })
                }}</span
              >
            </h2>
            <div v-if="computedErrorStylesSelects.length">
              <MyForm
                class="row d-flex mb-2 align-items-center"
                v-for="(
                  errorStyle, errorStyleIndex
                ) of computedErrorStylesSelects"
                :key="errorStyleIndex"
                @submit="
                  (event) => {
                    handleErrorStyleUpdate(errorStyleIndex, event);
                  }
                "
                :validation-schema="nameValidator"
              >
                <div class="col">
                  <span v-if="errorStyle.edit === false">{{
                    errorStyle.name
                  }}</span>
                  <div v-else>
                    <Field
                      type="text"
                      class="form-control form-control-sm form-control-solid"
                      name="name"
                      v-model="errorStyle.name"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="errorStyle.edit === false" class="col text-end">
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="connectErrorStyleWithCatalog(errorStyle)"
                    :title="$t('fes.config.connect')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-plus"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    @click="editEntity(errorStyle)"
                    :title="$t('edit')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-edit"></i>
                    </span>
                  </a>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="deleteErrorStyle(errorStyle)"
                    :title="$t('remove')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-trash"></i>
                    </span>
                  </a>
                </div>
                <div v-else class="col text-end">
                  <button
                    type="submit"
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    :title="$t('save')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-save"></i>
                    </span>
                  </button>
                  <a
                    href="javascript:"
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
                    @click="discardEntityEdit(errorStyle, data.errorStyles)"
                    :title="$t('cancel')"
                  >
                    <span class="svg-icon svg-icon-2">
                      <i class="fa fa-times"></i>
                    </span>
                  </a>
                </div>
              </MyForm>
            </div>
            <div class="row pt-3">
              <div class="col">
                <button
                  class="btn btn-secondary"
                  @click="addNewErrorStyle(data.errorStyles)"
                  :disabled="computedIsNewErrorStyleFormOpen"
                >
                  <i class="fa fa-plus"></i>
                  {{ $t('fes.config.addErrorStyle') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </MyForm>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  // import * as _ from 'lodash';
  import ApiService from '@/core/services/ApiService';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2';
  import {
    computed,
    defineComponent,
    PropType,
    reactive,
    ref,
    watch,
  } from 'vue';
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import SideDrawer from '../SideDrawer.vue';
  import CdfPath from '../cdf/CdfPath.vue';
  import OrgUnitPath from '../fes/OrgUnitPath.vue';
  import {
    computedDate,
    getNearestCatalog,
    getNodePath,
    getPath,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import * as yup from 'yup';
  import { AxiosResponse } from 'axios';
  import {
    ErrorStyleWithForm,
    ErrorPlaceWithForm,
    RiskCatalog,
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    ErrorLocationRisk,
    ErrorPlace,
    ErrorStyle,
    RiskLevel,
    LocationRiskCategory,
    LocationRiskCategoryWithForm,
    ErrorLocationRiskWithCategory,
  } from '@/core/models/fes';

  type LocationRiskConfig = {
    categories: LocationRiskCategory[];
    errorPlaces: ErrorPlace[];
  };

  export default defineComponent({
    name: 'riskCatalogDrawer',
    props: {
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      areaNames: {
        type: Array as PropType<ErrorCaseCDFName[]>,
        required: true,
      },
      updateFunction: {
        type: Function,
        required: true,
      },
      orgChartData: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
      currentNode: {
        type: Object as PropType<OrgChartDataPairWithCatalogs>,
        required: true,
      },
      riskLevels: {
        type: Object as PropType<RiskLevel[]>,
        required: true,
      },
    },
    components: {
      SideDrawer,
      MyForm: Form,
      Field,
      ErrorMessage,
      CdfPath,
      OrgUnitPath,
    },
    setup(props) {
      const { t } = useI18n();
      const store = useStore();

      const editDecisonModal = ref({
        hide: () => null,
      });

      const data: {
        orgChartData: OrgChartDataPairWithCatalogs[];
        currentNode: OrgChartDataPairWithCatalogs;
        errorStyles: ErrorStyleWithForm[];
        errorPlaces: ErrorPlaceWithForm[];
        currentRiskCatalog: RiskCatalog | null;
        currentLocationRiskCategory: LocationRiskCategory | null;
        currentLocationRiskCategoryId: number | null;
        locationRiskCategoriesAll: LocationRiskCategoryWithForm[];
        locationRiskCategoriesActive: LocationRiskCategoryWithForm[];
      } = reactive({
        orgChartData: props.orgChartData,
        currentNode: props.currentNode,
        errorStyles: [] as ErrorStyleWithForm[],
        errorPlaces: [] as ErrorPlaceWithForm[],
        currentRiskCatalog: null,
        currentLocationRiskCategory: null,
        currentLocationRiskCategoryId: null,
        locationRiskCategoriesAll: [] as LocationRiskCategoryWithForm[],
        locationRiskCategoriesActive: [] as LocationRiskCategoryWithForm[],
      });

      const computedOrgChartData = computed(() => {
        return data.orgChartData.map(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.riskCatalog !== null) {
              orgUnit.riskCatalog.locationRisks =
                orgUnit.riskCatalog.locationRisks.map(
                  (locationRisk: ErrorLocationRisk) => {
                    return Object.assign(
                      {
                        category: locationRisk.style.category,
                      },
                      locationRisk,
                    ) as ErrorLocationRiskWithCategory;
                  },
                );
            }
            return orgUnit;
          },
        );
      });

      const computedRiskLevels = computed(() => {
        if (props.riskLevels.length === 0) {
          return [];
        }

        return props.riskLevels.map((riskLevel: RiskLevel) => {
          return riskLevel.level;
        });
      });

      const computedNodePathAreaTitle = computed(() => {
        const nodePath = props.nodePath.slice(1);
        const nodePathLength = nodePath.length;
        const errorCaseCdfName = props.areaNames[nodePathLength - 1];
        if (errorCaseCdfName) {
          return errorCaseCdfName.name;
        }
        return '';
      });

      const computedNodePathLastElement = computed(() => {
        const lastPathElement = props.nodePath.slice(0).pop();
        return lastPathElement?.name;
      });

      const checkIfLocationRiskComplete = (locationRisk: ErrorLocationRisk) => {
        return (
          Object.keys(locationRisk.place).length > 0 &&
          Object.keys(locationRisk.style).length > 0
        );
      };

      const checkIfLocationRisksComplete = (
        locationRisks: ErrorLocationRisk[],
      ) => {
        return (
          locationRisks
            .filter((locationRisk: ErrorLocationRisk) => {
              return (
                locationRisk.style.category &&
                locationRisk.style.category.id ===
                  data.currentLocationRiskCategoryId
              );
            })
            .filter(checkIfLocationRiskComplete).length > 0
        );
      };

      const computedIsTableFormReady = computed(() => {
        if (data.currentRiskCatalog === null) return false;
        return checkIfLocationRisksComplete(
          data.currentRiskCatalog.locationRisks,
        );
      });

      const computedCurrentNodeRiskCatalog = computed(() => {
        return (
          data.currentNode !== null && data.currentNode.riskCatalog !== null
        );
      });

      const computedCurrentLocationRisks = computed(
        (): ErrorLocationRiskWithCategory[] => {
          if (data.currentRiskCatalog === null) return [];
          return data.currentRiskCatalog.locationRisks.map(
            (locationRisk: ErrorLocationRisk) => {
              return Object.assign(
                {
                  category: locationRisk.style.category,
                },
                locationRisk,
              ) as ErrorLocationRiskWithCategory;
            },
          ) as ErrorLocationRiskWithCategory[];
        },
      );

      const computedCurrentErrorStylesForCategoryByCategory = computed(() => {
        return (category: LocationRiskCategory) => {
          return computedCurrentErrorStyles.value.filter(
            (errorStyle: ErrorStyle) => {
              return (
                errorStyle.category && errorStyle.category.id === category.id
              );
            },
          );
        };
      });

      const computedCurrentErrorStylesForCategory = computed(() => {
        if (data.currentLocationRiskCategory === null) return [];
        return computedCurrentErrorStylesForCategoryByCategory.value(
          data.currentLocationRiskCategory,
        );
      });

      const computedCurrentErrorStyles = computed(() => {
        const locationRisks: ErrorLocationRiskWithCategory[] =
          computedCurrentLocationRisks.value;

        if (!locationRisks) return [];
        const allErrorStyles: ErrorStyle[] = locationRisks
          .filter((locationRisk: ErrorLocationRiskWithCategory) => {
            return Object.keys(locationRisk.style).length !== 0;
          })
          .map((locationRisk: ErrorLocationRiskWithCategory) => {
            return locationRisk.style;
          });

        const uniqueCache: number[] = [];
        return allErrorStyles
          .filter((errorStyle: ErrorStyle) => {
            const errorStyleId = errorStyle.id;
            // if (errorStyle.category.id !== data.currentLocationRiskCategoryId)
            //   return false;
            if (uniqueCache.includes(errorStyleId)) return false;
            uniqueCache.push(errorStyleId);
            return true;
          })
          .sort((errorStyleA: ErrorStyle, errorStyleB: ErrorStyle) => {
            if (errorStyleA.name < errorStyleB.name) {
              return -1;
            }
            if (errorStyleA.name > errorStyleB.name) {
              return 1;
            }
            return 0;
          });
      });

      const computedCurrentErrorPlaces = computed(() => {
        const locationRisks: ErrorLocationRisk[] =
          computedCurrentLocationRisks.value;

        if (!locationRisks) return [];
        const allErrorPlaces: ErrorPlace[] = locationRisks
          .filter((locationRisk: ErrorLocationRisk) => {
            return Object.keys(locationRisk.place).length !== 0;
          })
          .map((locationRisk: ErrorLocationRisk) => {
            return locationRisk.place;
          });

        const uniqueCache: number[] = [];
        return allErrorPlaces
          .filter((errorPlace: ErrorPlace) => {
            const errorPlaceId = errorPlace.id;
            if (uniqueCache.includes(errorPlaceId)) return false;
            uniqueCache.push(errorPlaceId);
            return true;
          })
          .sort((errorPlaceA: ErrorPlace, errorPlaceB: ErrorPlace) => {
            if (errorPlaceA.name < errorPlaceB.name) {
              return -1;
            }
            if (errorPlaceA.name > errorPlaceB.name) {
              return 1;
            }
            return 0;
          });
      });

      const computedCurrentErrorPlacesForCategoryByCategory = computed(() => {
        return (category: LocationRiskCategory) => {
          return computedCurrentLocationRisks.value
            .filter((locationRisk: ErrorLocationRiskWithCategory) => {
              return (
                Object.keys(locationRisk.place).length !== 0 &&
                locationRisk.category.id === category.id
              );
            })
            .map((locationRisk: ErrorLocationRiskWithCategory) => {
              return locationRisk.place;
            });
        };
      });

      const computedCurrentErrorPlacesForCategory = computed(() => {
        const locationRisks: ErrorLocationRiskWithCategory[] =
          computedCurrentLocationRisks.value;

        if (!locationRisks) return [];
        const allErrorPlaces: ErrorPlace[] = locationRisks
          .filter((locationRisk: ErrorLocationRiskWithCategory) => {
            return (
              locationRisk.category.id === data.currentLocationRiskCategoryId
            );
          })
          .map((locationRisk: ErrorLocationRisk) => {
            return locationRisk.place;
          });

        const uniqueCache: number[] = [];
        return allErrorPlaces
          .filter((errorPlace: ErrorPlace) => {
            const errorPlaceId = errorPlace.id;
            if (uniqueCache.includes(errorPlaceId)) return false;
            uniqueCache.push(errorPlaceId);
            return true;
          })
          .sort((errorPlaceA: ErrorPlace, errorPlaceB: ErrorPlace) => {
            if (errorPlaceA.name < errorPlaceB.name) {
              return -1;
            }
            if (errorPlaceA.name > errorPlaceB.name) {
              return 1;
            }
            return 0;
          });
      });

      const computedErrorStylesSelects = computed(() => {
        if (data.currentNode !== null) {
          return data.errorStyles.filter((errorStyle: ErrorStyle) => {
            return (
              computedCurrentErrorStyles.value.findIndex(
                (currentErrorStyle: ErrorStyle) => {
                  return currentErrorStyle.id === errorStyle.id;
                },
              ) === -1 &&
              errorStyle.category.id === data.currentLocationRiskCategoryId
            );
          });
        }

        return data.errorStyles;
      });

      const computedErrorPlacesSelects = computed(() => {
        if (data.currentNode !== null) {
          return data.errorPlaces.filter((errorPlace: ErrorPlace) => {
            return (
              computedCurrentErrorPlacesForCategory.value.findIndex(
                (currentErrorPlace: ErrorPlace) => {
                  return currentErrorPlace.id === errorPlace.id;
                },
              ) === -1
            );
          });
        }

        return data.errorPlaces;
      });

      const openRiskCatalog = () => {
        openSideDrawer('fesConfigDrawerRiskCatalog');
        setLocationRiskCategoryToFirstFilled();
      };

      async function disconnectErrorStyleWithCatalog(
        errorStyle: ErrorStyleWithForm,
      ) {
        if (data.currentRiskCatalog === null) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.disconnectErrorStyleQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        data.currentRiskCatalog.locationRisks =
          data.currentRiskCatalog.locationRisks.filter(
            (locationRisk: ErrorLocationRisk) => {
              if (
                locationRisk.style !== null &&
                locationRisk.style.category.id !==
                  data.currentLocationRiskCategoryId
              ) {
                return true;
              }

              return (
                Object.keys(locationRisk.style).length !== 0 &&
                locationRisk.style.id !== errorStyle.id
              );
              // if (
              //   Object.keys(locationRisk.style).length !== 0 &&
              //   locationRisk.style.id !== errorStyle.id
              // ) {
              //   locationRisk.style = {} as ErrorStyle;
              // }
              // return locationRisk;
            },
          );

        // locationRisksToRemove.forEach((locationRisk: ErrorLocationRisk) => {
        //   locationRisk.style = {} as ErrorStyle;
        // });
      }

      async function disconnectErrorPlaceWithCategory(
        errorPlace: ErrorPlaceWithForm,
      ) {
        if (data.currentRiskCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.disconnectErrorPlaceQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        data.currentRiskCatalog.locationRisks =
          data.currentRiskCatalog.locationRisks.filter(
            (locationRisk: ErrorLocationRisk) => {
              if (
                locationRisk.style !== null &&
                locationRisk.style.category.id !==
                  data.currentLocationRiskCategoryId
              ) {
                return true;
              }

              return (
                Object.keys(locationRisk.place).length !== 0 &&
                locationRisk.place.id !== errorPlace.id
              );
            },
          );
      }

      function connectErrorStyleWithCatalog(errorStyle: ErrorStyleWithForm) {
        if (data.currentRiskCatalog === null) {
          data.currentRiskCatalog = createEmptyRiskCatalog();
        }

        if (computedCurrentErrorPlacesForCategory.value.length === 0) {
          const newLocationRisk = {
            place: {} as ErrorPlace,
            style: errorStyle as ErrorStyle,
            riskValue: 0,
            category: data.currentLocationRiskCategory,
          } as ErrorLocationRiskWithCategory;
          data.currentRiskCatalog.locationRisks.push(newLocationRisk);
          return;
        } else {
          const locationRiskWithoutErrorStyleFunction = (
            selectedLocationRisk: ErrorLocationRisk,
          ) => {
            return Object.keys(selectedLocationRisk.style).length === 0;
          };

          if (
            data.currentRiskCatalog.locationRisks
              .filter((locationRisk: ErrorLocationRiskWithCategory) => {
                return (
                  locationRisk.category &&
                  locationRisk.category.id ===
                    data.currentLocationRiskCategoryId
                );
              })
              .some(locationRiskWithoutErrorStyleFunction)
          ) {
            data.currentRiskCatalog.locationRisks =
              data.currentRiskCatalog.locationRisks.map(
                (currentLocationRisk: ErrorLocationRiskWithCategory) => {
                  if (
                    locationRiskWithoutErrorStyleFunction(
                      currentLocationRisk,
                    ) &&
                    currentLocationRisk.category.id ===
                      data.currentLocationRiskCategoryId
                  ) {
                    currentLocationRisk.style = errorStyle as ErrorStyle;
                  }
                  return currentLocationRisk;
                },
              );
          } else {
            computedCurrentErrorPlacesForCategory.value.forEach(
              (errorPlace: ErrorPlace) => {
                const newLocationRisk = {
                  place: errorPlace as ErrorPlace,
                  style: errorStyle as ErrorStyle,
                  riskValue: 0,
                  category: data.currentLocationRiskCategory,
                } as ErrorLocationRiskWithCategory;
                data.currentRiskCatalog?.locationRisks.push(newLocationRisk);
              },
            );
          }

          // const locationRiskCategory = errorStyle.category;

          // const locationRisksWithCategory =
          //   computedCurrentErrorStyles.value.filter(
          //     (currentErrorStyle: ErrorStyle) => {
          //       return (
          //         currentErrorStyle.category.id === locationRiskCategory.id
          //       );
          //     },
          //   );

          // data.currentRiskCatalog?.locationRisks.forEach(
          //   (currentLocationRisk: ErrorLocationRisk) => {
          //     if (
          //       currentLocationRisk.style.category.id ===
          //       locationRiskCategory.id
          //     )
          //       currentLocationRisk.style = errorStyle as ErrorStyle;
          //   },
          // );
        }
      }

      function connectLocationRiskCategoryWithCatalog(
        locationRiskCategory: LocationRiskCategoryWithForm,
      ) {
        const newLocationRiskCategory = Object.assign(
          {},
          locationRiskCategory,
        ) as LocationRiskCategoryWithForm;
        data.locationRiskCategoriesActive.push(newLocationRiskCategory);
        if (data.currentLocationRiskCategory === null) {
          setLocationRiskCategoryToFirstFilled();
        }
      }

      function createEmptyRiskCatalog(): RiskCatalog {
        return {
          orgUnitId: data.currentNode.id,
          locationRisks: [] as ErrorLocationRisk[],
        } as RiskCatalog;
      }

      function connectErrorPlaceWithCategory(errorPlace: ErrorPlaceWithForm) {
        if (data.currentLocationRiskCategory === null) return false;
        connectErrorPlaceWithCategoryByCategory(
          errorPlace,
          data.currentLocationRiskCategory,
        );
      }

      function connectErrorPlaceWithCategoryByCategory(
        errorPlace: ErrorPlaceWithForm,
        category: LocationRiskCategory,
      ) {
        if (data.currentRiskCatalog === null) {
          data.currentRiskCatalog = createEmptyRiskCatalog();
        }

        if (
          computedCurrentErrorPlacesForCategoryByCategory
            .value(category)
            .findIndex((loopedErrorPlace: ErrorPlace) => {
              return loopedErrorPlace.id === errorPlace.id;
            }) !== -1
        ) {
          return;
        }

        if (
          computedCurrentErrorStylesForCategoryByCategory.value(category)
            .length === 0
        ) {
          const newLocationRisk = {
            place: errorPlace as ErrorPlace,
            style: {} as ErrorStyle,
            riskValue: 0,
            category: category,
          } as ErrorLocationRiskWithCategory;
          data.currentRiskCatalog.locationRisks.push(newLocationRisk);
        } else {
          const locationRiskWithoutErrorPlaceFunction = (
            selectedLocationRisk: ErrorLocationRisk,
          ) => {
            return Object.keys(selectedLocationRisk.place).length === 0;
          };

          if (
            data.currentRiskCatalog.locationRisks
              .filter((locationRisk: ErrorLocationRiskWithCategory) => {
                return (
                  locationRisk.category &&
                  locationRisk.category.id === category.id
                );
              })
              .some(locationRiskWithoutErrorPlaceFunction)
          ) {
            data.currentRiskCatalog.locationRisks =
              data.currentRiskCatalog.locationRisks.map(
                (currentLocationRisk: ErrorLocationRiskWithCategory) => {
                  if (
                    locationRiskWithoutErrorPlaceFunction(
                      currentLocationRisk,
                    ) &&
                    currentLocationRisk.category.id === category.id
                  ) {
                    currentLocationRisk.place = errorPlace as ErrorPlace;
                  }
                  return currentLocationRisk;
                },
              );
          } else {
            computedCurrentErrorStylesForCategoryByCategory
              .value(category)
              .forEach((errorStyle: ErrorStyle) => {
                if (errorStyle.category.id === category.id) {
                  const newLocationRisk = {
                    place: errorPlace as ErrorPlace,
                    style: errorStyle as ErrorStyle,
                    riskValue: 0,
                    category: category,
                  } as ErrorLocationRiskWithCategory;
                  data.currentRiskCatalog?.locationRisks.push(newLocationRisk);
                }
              });
          }
        }
      }
      function connectErrorPlaceWithAllCategories(
        errorPlace: ErrorPlaceWithForm,
      ) {
        if (data.currentRiskCatalog === null) {
          data.currentRiskCatalog = createEmptyRiskCatalog();
        }

        computedLocationRiskCategoriesActive.value.forEach(
          (locationRiskCategory: LocationRiskCategory) => {
            connectErrorPlaceWithCategoryByCategory(
              errorPlace,
              locationRiskCategory,
            );
          },
        );

        // if (computedCurrentErrorStyles.value.length === 0) {
        //   const newLocationRisk = {
        //     place: errorPlace as ErrorPlace,
        //     style: {} as ErrorStyle,
        //     riskValue: 0,
        //   } as ErrorLocationRiskWithCategory;
        //   data.currentRiskCatalog.locationRisks.push(newLocationRisk);
        //   return;
        // } else {
        //   computedCurrentErrorStyles.value.forEach((errorStyle: ErrorStyle) => {
        //     const newLocationRisk = {
        //       place: errorPlace as ErrorPlace,
        //       style: errorStyle as ErrorStyle,
        //       riskValue: 0,
        //     } as ErrorLocationRiskWithCategory;
        //     data.currentRiskCatalog?.locationRisks.push(newLocationRisk);
        //   });
        // }
      }

      function editEntity(entity: ErrorStyleWithForm | ErrorPlaceWithForm) {
        entity.edit = true;
        entity.nameBefore = entity.name;
      }

      function discardEntityEdit(
        entity:
          | ErrorStyleWithForm
          | ErrorPlaceWithForm
          | LocationRiskCategoryWithForm,
        entityArray:
          | ErrorStyleWithForm[]
          | ErrorPlaceWithForm[]
          | LocationRiskCategoryWithForm[],
      ) {
        if (!entity.id) return entityArray.pop();
        entity.edit = false;
        entity.name = entity.nameBefore;
      }

      async function emitUpdate() {
        props.updateFunction(data.orgChartData);
      }

      async function deleteRiskCatalog(riskCatalog: RiskCatalog) {
        if (!riskCatalog.id) return;
        await ApiService.delete(`fes/config/catalog/risk/${riskCatalog.id}`);
      }

      async function deleteCurrentRiskCatalog() {
        if (data.currentNode.riskCatalog === null) return;
        store.dispatch(Actions.START_LOADER);

        await deleteRiskCatalog(data.currentNode.riskCatalog);

        data.currentNode.riskCatalog = null;
        data.currentRiskCatalog = null;

        resetLocationRiskCategory();

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function handleDeleteRiskCatalog() {
        if (data.currentNode.riskCatalog === null) return;

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.deleteRiskCatalogQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;
        return await deleteCurrentRiskCatalog();
      }

      function addNewErrorPlace(entites: ErrorPlaceWithForm[]) {
        const newErrorPlace = {
          name: '',
          nameBefore: '',
          edit: true,
        } as ErrorPlaceWithForm;
        entites.push(newErrorPlace);
      }

      function addNewErrorStyle(entites: ErrorStyleWithForm[]) {
        const newErrorStyle = {
          name: '',
          nameBefore: '',
          edit: true,
          category: data.currentLocationRiskCategory,
        } as ErrorStyleWithForm;
        entites.push(newErrorStyle);
      }

      function addNewLocationRiskCategory(
        entites: LocationRiskCategoryWithForm[],
      ) {
        const newLocationRiskCategory = {
          name: '',
          nameBefore: '',
          edit: true,
        } as LocationRiskCategoryWithForm;
        entites.push(newLocationRiskCategory);
      }

      const nameValidator = yup.object().shape({
        name: yup.string().required().min(1).label('Name'),
      });

      async function handleRiskCatalogSubmit() {
        if (computedIsTableFormReady.value === false) return false;

        store.dispatch(Actions.START_LOADER);

        const riskCatalog: RiskCatalog = Object.assign(
          {},
          data.currentRiskCatalog,
        );

        try {
          const newSavedRiskCatalog = await ApiService.post(
            'fes/config/catalog/risk',
            {
              data: riskCatalog,
            },
          );

          newSavedRiskCatalog.data.locationRisks =
            newSavedRiskCatalog.data.locationRisks.map(
              (locationRisk: ErrorLocationRisk) => {
                return Object.assign(
                  {
                    category: locationRisk.style.category,
                  },
                  locationRisk,
                ) as ErrorLocationRiskWithCategory;
              },
            );

          data.currentNode.riskCatalog = data.currentRiskCatalog =
            newSavedRiskCatalog.data;

          emitUpdate();
        } catch (error) {
          console.error('Error while saving risk catalog', error);
          Swal.fire(t('error'), t('errorAlertCaption'), 'error');
        }
        store.dispatch(Actions.END_LOADER);
      }

      async function updateAvailableOptions() {
        const loadedLocationRiskConfigResponse: AxiosResponse<LocationRiskConfig> =
          await ApiService.get('fes/config/locationrisk');
        // const loadedLocationRiskCategories: LocationRiskCategory[] =
        //   loadedLocationRiskCategoriesResponse.data;

        // data.locationRiskCategories =
        //   loadedLocationRiskConfigResponse.data.categories;
        data.locationRiskCategoriesAll = [];
        data.locationRiskCategoriesAll =
          loadedLocationRiskConfigResponse.data.categories
            .map((locationRiskCategory: LocationRiskCategory) => {
              const locationRiskCategoryObject = Object.assign(
                {
                  edit: false,
                  nameBefore: locationRiskCategory.name,
                } as LocationRiskCategoryWithForm,
                locationRiskCategory,
              );
              return locationRiskCategoryObject;
            })
            .slice(0)
            .sort(
              (
                locationRiskCategoryA: LocationRiskCategoryWithForm,
                locationRiskCategoryB: LocationRiskCategoryWithForm,
              ) => {
                if (locationRiskCategoryA.name < locationRiskCategoryB.name) {
                  return -1;
                }
                if (locationRiskCategoryA.name > locationRiskCategoryB.name) {
                  return 1;
                }
                return 0;
              },
            );

        data.errorPlaces = [];
        data.errorPlaces = loadedLocationRiskConfigResponse.data.errorPlaces
          .map((errorPlace: ErrorPlace) => {
            const errorPlaceObject = Object.assign(
              {
                edit: false,
                nameBefore: errorPlace.name,
              } as ErrorPlaceWithForm,
              errorPlace,
            );
            return errorPlaceObject;
          })
          .slice(0)
          .sort((errorPlaceA: ErrorPlace, errorPlaceB: ErrorPlace) => {
            if (errorPlaceA.name < errorPlaceB.name) {
              return -1;
            }
            if (errorPlaceA.name > errorPlaceB.name) {
              return 1;
            }
            return 0;
          });

        // data.errorPlaces = data.errorPlaces.sort(
        //   (errorPlaceA: ErrorPlace, errorPlaceB: ErrorPlace) => {
        //     if (errorPlaceA.name < errorPlaceB.name) {
        //       return -1;
        //     }
        //     if (errorPlaceA.name > errorPlaceB.name) {
        //       return 1;
        //     }
        //     return 0;
        //   },
        // );

        data.errorStyles = [];
        data.locationRiskCategoriesAll.forEach(
          (locationRiskCategory: LocationRiskCategory) => {
            locationRiskCategory.errorStyles.forEach(
              (errorStyle: ErrorStyle) => {
                const errorStyleObject = Object.assign(
                  {
                    edit: false,
                    nameBefore: errorStyle.name,
                  } as ErrorStyleWithForm,
                  errorStyle,
                );
                data.errorStyles.push(errorStyleObject);
              },
            );
          },
        );

        data.errorStyles = data.errorStyles
          .slice(0)
          .sort((errorStyleA: ErrorStyle, errorStyleB: ErrorStyle) => {
            if (errorStyleA.name < errorStyleB.name) {
              return -1;
            }
            if (errorStyleA.name > errorStyleB.name) {
              return 1;
            }
            return 0;
          });

        setLocationRiskCategoryToFirstFilled();
      }

      function setLocationRiskCategoryToFirstFilled() {
        let locationRiskCategoryFilledId = 0;

        computedLocationRiskCategoriesActive.value.forEach(
          (locationRiskCategory: LocationRiskCategory) => {
            if (
              locationRiskCategoryFilledId === 0 &&
              data.currentRiskCatalog?.locationRisks.filter(
                (locationRisk: ErrorLocationRisk) => {
                  return (
                    locationRisk.style.category.id === locationRiskCategory.id
                  );
                },
              ).length
            ) {
              locationRiskCategoryFilledId = locationRiskCategory.id as number;
            }
          },
        );

        if (locationRiskCategoryFilledId > 0) {
          setLocationRiskCategoryById(locationRiskCategoryFilledId);
        }
        if (computedLocationRiskCategoriesActive.value.length) {
          setLocationRiskCategoryById(
            computedLocationRiskCategoriesActive.value[0].id as number,
          );
        }
      }

      function resetLocationRiskCategory() {
        data.currentLocationRiskCategory = null;
        data.currentLocationRiskCategoryId = null;
        data.locationRiskCategoriesActive =
          [] as LocationRiskCategoryWithForm[];
      }

      function setLocationRiskCategoryById(id: number) {
        const newLocationRiskCategory = data.locationRiskCategoriesActive.find(
          (locationRiskCategory: LocationRiskCategory) => {
            return locationRiskCategory.id === id;
          },
        );

        if (newLocationRiskCategory) {
          data.currentLocationRiskCategory =
            newLocationRiskCategory as LocationRiskCategory;
          data.currentLocationRiskCategoryId =
            newLocationRiskCategory.id as number;
        }
      }

      // function setLocationRiskCategory(index: number) {
      //   // const newLocationRiskCategory = data.locationRiskCategories.find(
      //   //   (locationRiskCategory: LocationRiskCategory) => {
      //   //     return locationRiskCategory.id === id;
      //   //   },
      //   // );

      //   if (
      //     typeof computedLocationRiskCategories.value[index] !== 'undefined'
      //   ) {
      //     data.currentLocationRiskCategory = computedLocationRiskCategories
      //       .value[index] as LocationRiskCategory;
      //     data.currentLocationRiskCategoryId = data.currentLocationRiskCategory
      //       .id as number;
      //   }
      // }

      type NameUpdate = {
        name: string;
      };

      async function handleErrorPlaceUpdate(index: number, change: NameUpdate) {
        store.dispatch(Actions.START_LOADER);

        const entity = computedErrorPlacesSelects.value[index];

        if (entity) {
          entity.edit = false;
          entity.nameBefore = change.name;

          const updateObject: ErrorPlace = {
            name: change.name,
          } as ErrorPlace;

          if (entity.id) {
            updateObject.id = entity.id;
          }

          const savedErrorPlace: AxiosResponse<ErrorPlace> =
            await ApiService.post('fes/config/errorplace', {
              data: updateObject,
            });

          entity.id = savedErrorPlace.data.id;
          entity.name = savedErrorPlace.data.name;
        }
        store.dispatch(Actions.END_LOADER);
      }

      async function handleErrorStyleUpdate(index: number, change: NameUpdate) {
        store.dispatch(Actions.START_LOADER);

        const entity = computedErrorStylesSelects.value[index];

        if (entity) {
          entity.edit = false;
          entity.nameBefore = change.name;

          const updateObject: ErrorStyle = {
            name: change.name,
            category: entity.category,
          } as ErrorStyle;

          if (entity.id) {
            updateObject.id = entity.id;
          }

          const savedErrorStyle: AxiosResponse<ErrorStyle> =
            await ApiService.post('fes/config/errorstyle', {
              data: updateObject,
            });

          entity.id = savedErrorStyle.data.id;
          entity.name = savedErrorStyle.data.name;
        }
        store.dispatch(Actions.END_LOADER);
      }

      async function handleLocationRiskCategoryUpdate(
        index: number,
        change: NameUpdate,
      ) {
        store.dispatch(Actions.START_LOADER);

        const entity = computedLocationRiskCategoriesAll.value[index];

        if (entity) {
          entity.edit = false;
          entity.nameBefore = change.name;

          const updateObject: LocationRiskCategory = {
            name: change.name,
          } as LocationRiskCategory;

          if (entity.id) {
            updateObject.id = entity.id;
          }

          const savedLocationRiskCategory: AxiosResponse<LocationRiskCategory> =
            await ApiService.post('fes/config/locationrisk', {
              data: updateObject,
            });

          entity.id = savedLocationRiskCategory.data.id;
          entity.name = savedLocationRiskCategory.data.name;
        }
        store.dispatch(Actions.END_LOADER);
      }

      const computedNearestRiskCatalogPath = computed(() => {
        if (computedNearestRiskCatalog.value === false) return '';
        const currentNearestRiskCatalog: RiskCatalog =
          computedNearestRiskCatalog.value;
        const currentInheritNode = data.orgChartData.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.riskCatalog === null) return false;
            return orgUnit.riskCatalog?.id === currentNearestRiskCatalog.id;
          },
        );
        if (currentInheritNode) {
          return getNodePath(currentInheritNode, data.orgChartData);
        }
        return '';
      });

      const computedNearestRiskCatalog = computed(() => {
        if (data.currentNode === null) return false;
        if (data.currentRiskCatalog !== null) return false;

        const nearestRiskCatalog = getNearestCatalog(
          data.currentNode,
          data.orgChartData,
          'riskCatalog',
        );

        if (nearestRiskCatalog) {
          return nearestRiskCatalog;
        }

        return false;
      });

      function copyNearestRiskCatalog() {
        const currentNearestRiskCatalog: RiskCatalog =
          computedNearestRiskCatalog.value;
        const riskCatalogCopy: RiskCatalog = {
          orgUnitId: data.currentNode.id,
          locationRisks: currentNearestRiskCatalog.locationRisks.map(
            (locationRisk: ErrorLocationRisk) => {
              return {
                place: locationRisk.place,
                style: locationRisk.style,
                riskValue: locationRisk.riskValue,
                category: locationRisk.style.category,
              } as ErrorLocationRiskWithCategory;
            },
          ),
        } as RiskCatalog;
        data.currentRiskCatalog = riskCatalogCopy;
        initLocationRiskCategories();
        setLocationRiskCategoryToFirstFilled();
      }

      function initLocationRiskCategories() {
        data.locationRiskCategoriesActive = [];
        data.currentRiskCatalog?.locationRisks.forEach(
          (locationRisk: ErrorLocationRisk) => {
            const category = Object.assign(
              {
                edit: false,
                nameBefore: locationRisk.style.category.name,
              },
              locationRisk.style.category,
            ) as LocationRiskCategoryWithForm;
            if (
              data.locationRiskCategoriesActive.findIndex(
                (riskCategory: LocationRiskCategoryWithForm) => {
                  return riskCategory.id === category.id;
                },
              ) === -1
            ) {
              data.locationRiskCategoriesActive.push(category);
            }
          },
        );
      }

      async function deleteErrorPlace(errorPlace: ErrorPlace) {
        const errorPlacesInTree = computedOrgChartData.value
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.riskCatalog === null) return false;
            const catalog = orgUnit.riskCatalog;
            return (
              catalog.locationRisks.filter(
                (locationRisk: ErrorLocationRisk) => {
                  return locationRisk.place.id === errorPlace.id;
                },
              ).length > 0
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (errorPlacesInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: errorPlacesInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            errorPlacesInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteErrorPlaceQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);

        await ApiService.delete(`fes/config/errorplace/${errorPlace.id}`);

        data.errorPlaces = data.errorPlaces.filter(
          (loopedErrorPlace: ErrorPlace) => {
            return loopedErrorPlace.id !== errorPlace.id;
          },
        );

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.riskCatalog !== null &&
                typeof orgUnit.riskCatalog.locationRisks !== 'undefined'
              ) {
                const locationRisksBeforeUpdate =
                  orgUnit.riskCatalog.locationRisks.slice(0);

                orgUnit.riskCatalog.locationRisks =
                  orgUnit.riskCatalog.locationRisks.filter(
                    (locationRisk: ErrorLocationRisk) => {
                      return locationRisk.place.id !== errorPlace.id;
                    },
                  );

                if (
                  locationRisksBeforeUpdate.length !==
                  orgUnit.riskCatalog.locationRisks.length
                ) {
                  if (
                    !checkIfLocationRisksComplete(
                      orgUnit.riskCatalog.locationRisks,
                    )
                  ) {
                    await deleteRiskCatalog(orgUnit.riskCatalog);
                    orgUnit.riskCatalog = null;
                  } else {
                    await ApiService.post(`fes/config/catalog/risk`, {
                      data: orgUnit.riskCatalog,
                    });
                  }
                }
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedIsNewErrorPlaceFormOpen = computed(() => {
        return (
          computedErrorPlacesSelects.value.filter(
            (errorPlace: ErrorPlaceWithForm) => {
              return errorPlace.edit === true;
            },
          ).length > 0
        );
      });

      const computedIsNewErrorStyleFormOpen = computed(() => {
        return (
          computedErrorStylesSelects.value.filter(
            (errorStyle: ErrorStyleWithForm) => {
              return errorStyle.edit === true;
            },
          ).length > 0
        );
      });

      const computedIsNewLocationRiskCategoryFormOpen = computed(() => {
        return (
          computedLocationRiskCategoriesAll.value.filter(
            (locationRiskCategory: LocationRiskCategoryWithForm) => {
              return locationRiskCategory.edit === true;
            },
          ).length > 0
        );
      });

      function handleLocationRiskCategoryChange(
        locationRiskCategory: LocationRiskCategory,
      ) {
        data.currentLocationRiskCategory = locationRiskCategory;
        data.currentLocationRiskCategoryId = locationRiskCategory.id as number;
      }

      async function removeLocationRiskCategory(
        locationRiskCategory: LocationRiskCategory,
      ) {
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);

        await ApiService.delete(
          `fes/config/locationrisk/${locationRiskCategory.id}`,
        );

        if (data.currentRiskCatalog?.locationRisks.length) {
          data.currentRiskCatalog.locationRisks =
            data.currentRiskCatalog.locationRisks.filter(
              (locationRisk: ErrorLocationRisk) => {
                if (locationRisk.style) {
                  if (
                    locationRisk.style.category.id === locationRiskCategory.id
                  ) {
                    return false;
                  }
                }
                return true;
              },
            );
        }

        data.locationRiskCategoriesAll = data.locationRiskCategoriesAll
          .filter(
            (loopedLocationRiskCategory: LocationRiskCategoryWithForm) => {
              return loopedLocationRiskCategory.id !== locationRiskCategory.id;
            },
          )
          .slice(0)
          .sort(
            (
              locationRiskCategoryA: LocationRiskCategoryWithForm,
              locationRiskCategoryB: LocationRiskCategoryWithForm,
            ) => {
              if (locationRiskCategoryA.name < locationRiskCategoryB.name) {
                return -1;
              }
              if (locationRiskCategoryA.name > locationRiskCategoryB.name) {
                return 1;
              }
              return 0;
            },
          );

        if (data.locationRiskCategoriesAll.length) {
          setLocationRiskCategoryToFirstFilled();
        }

        store.dispatch(Actions.END_LOADER);
      }

      async function disconnectLocationRiskCategoryWithCatalog(
        locationRiskCategory: LocationRiskCategoryWithForm,
      ) {
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('fes.config.disconnectLocationRiskCategoryQuestion'),
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        if (data.currentRiskCatalog !== null) {
          data.currentRiskCatalog.locationRisks =
            data.currentRiskCatalog.locationRisks.filter(
              (selectedLocationRisk: ErrorLocationRisk) => {
                if (typeof selectedLocationRisk.style === 'undefined')
                  return false;
                if (typeof selectedLocationRisk.style.category === 'undefined')
                  return false;
                return (
                  selectedLocationRisk.style.category.id !==
                  locationRiskCategory.id
                );
              },
            );
        }

        // if (data.currentLocationRiskCategoryId === locationRiskCategory.id) {
        //   if (data.locationRiskCategoriesActive.length) {
        //     setLocationRiskCategoryToFirstFilled();
        //   } else {
        //   }
        // }
        data.locationRiskCategoriesActive =
          data.locationRiskCategoriesActive.filter(
            (activeLocationRiskCategory: LocationRiskCategoryWithForm) => {
              return activeLocationRiskCategory.id !== locationRiskCategory.id;
            },
          );

        if (data.locationRiskCategoriesActive.length === 0) {
          resetLocationRiskCategory();
          deleteCurrentRiskCatalog();
        } else {
          if (data.currentLocationRiskCategoryId === locationRiskCategory.id) {
            setLocationRiskCategoryToFirstFilled();
          }
        }
      }

      async function editLocationRiskCategory(
        locationRiskCategory: LocationRiskCategory,
      ) {
        const { value: newCategoryName } = await Swal.fire({
          title: t('fes.config.locationRiskCategory.edit'),
          input: 'text',
          inputValue: locationRiskCategory.name,
          showCancelButton: true,
        });

        if (newCategoryName) {
          store.dispatch(Actions.START_LOADER);

          locationRiskCategory.name = newCategoryName;

          await ApiService.post('fes/config/locationrisk', {
            data: locationRiskCategory,
          });

          store.dispatch(Actions.END_LOADER);
        }
      }

      async function deleteErrorStyle(errorStyle: ErrorPlace) {
        const errorStylesInTree = computedOrgChartData.value
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.riskCatalog === null) return false;
            const catalog = orgUnit.riskCatalog;
            return (
              catalog.locationRisks.filter(
                (locationRisk: ErrorLocationRisk) => {
                  return locationRisk.style.id === errorStyle.id;
                },
              ).length > 0
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (errorStylesInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: errorStylesInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            errorStylesInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteErrorStyleQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);

        await ApiService.delete(`fes/config/errorstyle/${errorStyle.id}`);

        data.errorStyles = data.errorStyles.filter(
          (loopedErrorStyle: ErrorStyle) => {
            return loopedErrorStyle.id !== errorStyle.id;
          },
        );

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.riskCatalog !== null &&
                typeof orgUnit.riskCatalog.locationRisks !== 'undefined'
              ) {
                const locationRisksBeforeUpdate =
                  orgUnit.riskCatalog.locationRisks.slice(0);

                orgUnit.riskCatalog.locationRisks =
                  orgUnit.riskCatalog.locationRisks.filter(
                    (locationRisk: ErrorLocationRisk) => {
                      return locationRisk.style.id !== errorStyle.id;
                    },
                  );

                if (
                  locationRisksBeforeUpdate.length !==
                  orgUnit.riskCatalog.locationRisks.length
                ) {
                  if (
                    !checkIfLocationRisksComplete(
                      orgUnit.riskCatalog.locationRisks,
                    )
                  ) {
                    await deleteRiskCatalog(orgUnit.riskCatalog);
                    orgUnit.riskCatalog = null;
                  } else {
                    await ApiService.post(`fes/config/catalog/risk`, {
                      data: orgUnit.riskCatalog,
                    });
                  }
                }
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      async function deleteLocationRiskCategory(
        locationRiskCategory: LocationRiskCategory,
      ) {
        const unitsWithCategoryInTree = computedOrgChartData.value
          .filter((orgUnit: OrgChartDataPairWithCatalogs) => {
            if (orgUnit.riskCatalog === null) return false;
            const catalog = orgUnit.riskCatalog;
            return (
              catalog.locationRisks.filter(
                (locationRisk: ErrorLocationRisk) => {
                  return (
                    locationRisk.style.category.id === locationRiskCategory.id
                  );
                },
              ).length > 0
            );
          })
          .map((orgUnit: OrgChartDataPairWithCatalogs) => {
            return getPath(orgUnit, data.orgChartData);
          });

        let connectedNodesHint: string[] = [];
        if (unitsWithCategoryInTree.length > 0) {
          connectedNodesHint = [
            '<small>' +
              t('fes.config.deleteConnectedNodeHint', {
                amount: unitsWithCategoryInTree.length,
              }) +
              '</small><br />',
            '<p class="text-start">',
            unitsWithCategoryInTree
              .map((elem) => {
                return `<small class="d-inline-block pb-3">${elem}</small>`;
              })
              .join(''),
          ];
        }

        const html = [
          t('fes.config.deleteErrorStyleQuestion') + '<br />',
          ...connectedNodesHint,
          '</p>',
        ].join('');

        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          html,
          icon: 'question',
          showCancelButton: true,
        });

        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);

        await ApiService.delete(
          `fes/config/locationrisk/${locationRiskCategory.id}`,
        );

        data.locationRiskCategoriesAll = data.locationRiskCategoriesAll.filter(
          (loadedLocationRiskCategory: LocationRiskCategory) => {
            return loadedLocationRiskCategory.id !== locationRiskCategory.id;
          },
        );

        data.orgChartData = await Promise.all(
          data.orgChartData.map(
            async (orgUnit: OrgChartDataPairWithCatalogs) => {
              if (
                orgUnit.riskCatalog !== null &&
                typeof orgUnit.riskCatalog.locationRisks !== 'undefined'
              ) {
                const locationRisksBeforeUpdate =
                  orgUnit.riskCatalog.locationRisks.slice(0);

                orgUnit.riskCatalog.locationRisks =
                  orgUnit.riskCatalog.locationRisks.filter(
                    (locationRisk: ErrorLocationRisk) => {
                      return (
                        locationRisk.style.category.id ===
                        locationRiskCategory.id
                      );
                    },
                  );

                if (
                  locationRisksBeforeUpdate.length !==
                  orgUnit.riskCatalog.locationRisks.length
                ) {
                  if (
                    !checkIfLocationRisksComplete(
                      orgUnit.riskCatalog.locationRisks,
                    )
                  ) {
                    await deleteRiskCatalog(orgUnit.riskCatalog);
                    orgUnit.riskCatalog = null;
                  } else {
                    await ApiService.post(`fes/config/catalog/risk`, {
                      data: orgUnit.riskCatalog,
                    });
                  }
                }
              }
              return orgUnit;
            },
          ),
        );

        store.dispatch(Actions.END_LOADER);
        emitUpdate();
      }

      const computedLocationRiskCategoryErrorStyleAmount = computed(() => {
        return (locationRiskCategory: LocationRiskCategory) => {
          let counter = 0;
          computedCurrentErrorStyles.value.forEach((errorStyle: ErrorStyle) => {
            if (errorStyle.category.id === locationRiskCategory.id) {
              counter++;
            }
          });
          return counter;
        };
      });

      const computedLocationRiskCategoryIds = computed(() => {
        return data.locationRiskCategoriesAll.map(
          (locationRiskCategory: LocationRiskCategory) => {
            return locationRiskCategory.id;
          },
        );
      });

      const computedLocationRiskCategoriesAll = computed(() => {
        return data.locationRiskCategoriesAll.filter(
          (locationRiskCategory: LocationRiskCategoryWithForm) => {
            if (
              data.locationRiskCategoriesActive.findIndex(
                (locationRiskCategoryActive: LocationRiskCategory) => {
                  return (
                    locationRiskCategoryActive.id === locationRiskCategory.id
                  );
                },
              ) === -1
            ) {
              return true;
            }
            return false;
          },
        );
      });

      async function handleRiskValueChange(
        errorRiskLocation: ErrorLocationRiskWithCategory,
      ) {
        data.currentRiskCatalog?.locationRisks.forEach(
          (locationRisk: ErrorLocationRisk) => {
            if (
              errorRiskLocation.style.id === locationRisk.style.id &&
              errorRiskLocation.place.id === locationRisk.place.id
            ) {
              locationRisk.riskValue = errorRiskLocation.riskValue;
            }
          },
        );
      }

      const computedLocationRiskCategoriesActive = computed(() => {
        return data.locationRiskCategoriesActive
          .slice(0)
          .sort(
            (
              locationRiskCategoryA: LocationRiskCategoryWithForm,
              locationRiskCategoryB: LocationRiskCategoryWithForm,
            ) => {
              if (locationRiskCategoryA.name < locationRiskCategoryB.name) {
                return -1;
              }
              if (locationRiskCategoryA.name > locationRiskCategoryB.name) {
                return 1;
              }
              return 0;
            },
          );
      });

      watch(
        () => props.currentNode,
        async () => {
          if (props.currentNode !== null) {
            resetLocationRiskCategory();
            await updateAvailableOptions();
            data.currentNode = props.currentNode;
            if (props.currentNode.riskCatalog !== null) {
              data.currentRiskCatalog = props.currentNode.riskCatalog;
              data.currentRiskCatalog.locationRisks =
                data.currentRiskCatalog.locationRisks.filter(
                  (locationRisk: ErrorLocationRisk) => {
                    return computedLocationRiskCategoryIds.value.includes(
                      locationRisk.style.category.id,
                    );
                  },
                );
              // data.currentRiskCategories =
              initLocationRiskCategories();

              if (data.locationRiskCategoriesActive.length) {
                handleLocationRiskCategoryChange(
                  data.locationRiskCategoriesActive[0],
                );
              }
            } else {
              data.currentRiskCatalog = null;
            }
            data.orgChartData = props.orgChartData;
          }
        },
      );

      return {
        data,
        computedNodePathAreaTitle,
        computedNodePathLastElement,
        computedCurrentNodeRiskCatalog,
        connectErrorStyleWithCatalog,
        disconnectErrorStyleWithCatalog,
        connectErrorPlaceWithCategory,
        connectErrorPlaceWithAllCategories,
        disconnectErrorPlaceWithCategory,
        connectLocationRiskCategoryWithCatalog,
        disconnectLocationRiskCategoryWithCatalog,
        addNewErrorPlace,
        addNewErrorStyle,
        addNewLocationRiskCategory,
        nameValidator,
        editEntity,
        discardEntityEdit,
        handleDeleteRiskCatalog,
        editDecisonModal,
        openRiskCatalog,
        props,
        computedDate,
        computedErrorStylesSelects,
        computedErrorPlacesSelects,
        handleErrorPlaceUpdate,
        handleErrorStyleUpdate,
        handleLocationRiskCategoryUpdate,
        computedCurrentErrorStyles,
        computedCurrentErrorStylesForCategory,
        computedCurrentErrorPlaces,
        computedCurrentErrorPlacesForCategory,
        computedIsTableFormReady,
        computedCurrentLocationRisks,
        handleRiskCatalogSubmit,
        computedNearestRiskCatalog,
        computedNearestRiskCatalogPath,
        deleteErrorPlace,
        deleteErrorStyle,
        deleteLocationRiskCategory,
        copyNearestRiskCatalog,
        computedIsNewErrorPlaceFormOpen,
        computedIsNewErrorStyleFormOpen,
        computedRiskLevels,
        handleLocationRiskCategoryChange,
        editLocationRiskCategory,
        removeLocationRiskCategory,
        computedLocationRiskCategoriesActive,
        computedLocationRiskCategoriesAll,
        computedLocationRiskCategoryErrorStyleAmount,
        computedIsNewLocationRiskCategoryFormOpen,
        handleRiskValueChange,
      };
    },
  });
</script>
