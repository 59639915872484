import { createI18n } from 'vue-i18n';
import de from './lang/de';
import en from './lang/en';
import ro from './lang/ro';

const messages = {
  en: en,
  de: de,
  ro: ro,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
