<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">{{ $t('user.mailSettings') }}</h3>
    </div>
    <div class="card-body">
      <div v-for="(category, index) of computedMailCategories" :key="index">
        <div class="fs-4 fw-bold text-dark mb-3">
          {{ $t(`tabs.${category}`) }}
        </div>

        <div v-for="(mail, index) of computedMails(category)" :key="index">
          <div class="form-check form-check-custom form-check-solid mb-3 ms-4">
            <input
              v-if="!isMandatory(mail)"
              class="form-check-input"
              type="checkbox"
              v-model="data.disabledMails[mail]"
              :id="`user.mails.${mail}`"
            />
            <input
              v-else
              class="form-check-input"
              type="checkbox"
              v-model="data.disabledMails[mail]"
              :id="`user.mails.${mail}`"
              disabled
            />
            <label class="form-check-label" :for="`user.mails.${mail}`">{{
              $t(`user.mails.${formattedMail(mail)}`)
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-between">
      <div
        v-if="0"
        class="form-check form-check-custom form-check-solid mb-3 ms-4"
      >
        <input
          class="form-check-input"
          type="checkbox"
          v-model="data.disableAllMails"
          id="disableAllMails"
        />
        <label class="form-check-label" for="disableAllMails"
          >Alle Mails deaktivieren</label
        >
      </div>
      <button
        @click="saveMailSettings"
        type="button"
        class="btn btn-sm btn-primary"
        :data-kt-indicator="data.isSaving ? 'on' : 'off'"
      >
        <span class="indicator-label"> {{ $t('save') }} </span>
        <span class="indicator-progress">
          {{ $t('saving') }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, reactive } from 'vue';
  import { useStore } from 'vuex';
  import ApiService from '@/core/services/ApiService';
  import { AxiosResponse } from 'axios';
  import { Actions } from '@/store/enums/StoreEnums';

  type LoadedMessagesConfig = {
    disabledMails: string[];
    availableMails: string[];
  };

  type DisabledMails = {
    [key: string]: boolean;
  };

  export default defineComponent({
    name: 'userMailSettings',
    components: {},
    setup() {
      const store = useStore();
      const data = reactive({
        disabledMails: {} as DisabledMails,
        availableMails: [] as string[],
        isSaving: false,
        disableAllMails: false,
        mandatoryMails: ['fes/newErrorCase', 'opl/newOpenPoint'],
      });

      store.dispatch(Actions.START_LOADER);
      ApiService.get('users/settings/messages').then(
        (loadedMessagesConfig: AxiosResponse<LoadedMessagesConfig>) => {
          data.availableMails = loadedMessagesConfig.data.availableMails;
          loadedMessagesConfig.data.availableMails.forEach((availableMail) => {
            data.disabledMails[availableMail] =
              !loadedMessagesConfig.data.disabledMails.includes(availableMail);
          });
          // data.disabledMails = loadedMessagesConfig.data.disabledMails;
          store.dispatch(Actions.END_LOADER);

          if (
            data.availableMails.length ===
            Object.keys(data.disabledMails).filter((mailKey) => {
              return data.disabledMails[mailKey] === false;
            }).length
          ) {
            data.disableAllMails = true;
          }
        },
      );

      const computedMailCategories = computed(() => {
        const categories = [] as string[];
        for (const mail of data.availableMails) {
          const category = mail.split('/')[0];
          if (!categories.includes(category)) {
            categories.push(category);
          }
        }
        return categories;
      });

      const formattedMail = (mail: string) => {
        return mail.split('/')[1];
      };

      const computedMails = computed(() => {
        return (category: string) => {
          return data.availableMails.filter((mail) => {
            return mail.startsWith(category);
          });
        };
      });

      const saveMailSettings = () => {
        data.isSaving = true;
        const disabledMails = [] as string[];
        for (const mailKey in data.disabledMails) {
          if (data.disabledMails[mailKey] === false) {
            disabledMails.push(mailKey);
          }
        }
        ApiService.put('users/settings/messages', {
          data: disabledMails,
        }).then(() => {
          data.isSaving = false;
        });
      };

      const isMandatory = (mailName) => {
        return data.mandatoryMails.includes(mailName);
      };

      return {
        data,
        computedMailCategories,
        computedMails,
        formattedMail,
        saveMailSettings,
        isMandatory,
      };
    },
  });
</script>
