<template>
  <el-config-provider :locale="data.locale">
    <router-view />
  </el-config-provider>
</template>

<style lang="scss">
  @import '~bootstrap-icons/font/bootstrap-icons.css';
  @import '~sweetalert2/dist/sweetalert2.css';
  @import '~@fortawesome/fontawesome-free/css/all.min.css';
  @import '~line-awesome/dist/line-awesome/css/line-awesome.css';
  @import '~@vueform/multiselect/themes/default.css';
  @import '~element-plus/theme-chalk/index.css';
  @import 'vue-loading-overlay/dist/css/index.css';

  @import 'assets/sass/plugins';
  @import 'assets/sass/style';
</style>

<script lang="ts">
  import { defineComponent, onMounted, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { Mutations } from '@/store/enums/StoreEnums';
  import deDe from 'element-plus/es/locale/lang/de';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'app',
    setup() {
      const store = useStore();
      const i18n = useI18n();

      const loadedLanguage = localStorage.getItem('lang');
      if (loadedLanguage) {
        store.commit(Mutations.SET_LANGUAGE, loadedLanguage);
        i18n.locale.value = loadedLanguage;
      }

      const data = reactive({
        locale: deDe,
      });

      onMounted(() => {
        store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      });

      return {
        data,
      };
    },
  });
</script>
