<template>
  <!--begin::Dashboard-->
  <div v-if="computedIsLoaded" class="row mw-1200px">
    <simple-org-unit-path
      :node-path="data.openPoint.locations"
      :area-names="data.errorCaseCdfNames"
    />
    <div
      class="alert alert-primary d-flex align-items-center p-5 mb-3"
      v-if="data.openPoint.source === 'fes'"
    >
      <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
        <inline-svg src="media/icons/duotune/files/fil013.svg" />
      </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">
          {{ $t('opl.form.connectedErrorCase.title') }}
        </h4>
        <span>{{
          $t('opl.form.connectedErrorCase.text', {
            errorCaseId: data.openPoint.sourceId,
          })
        }}</span>
      </div>

      <router-link
        class="ms-auto"
        v-if="data.openPoint.source === 'fes'"
        :to="`/opl/fes/${data.openPoint.sourceId}`"
      >
        <el-button size="small" type="primary" class="p-5">
          <i class="bi bi-folder-symlink text-white me-3"></i>
          {{ $t('opl.goToErrorCaseLink') }}
        </el-button>
      </router-link>
      <router-link
        class="ms-1"
        v-if="data.openPoint.source === 'fes'"
        :to="`/opl/create/fes/${data.openPoint.sourceId}`"
      >
        <el-button size="small" type="success" class="p-5">
          <i class="fa fa-plus-square text-white me-3"></i>
          {{ $t('fes.overview.newOpenPoint') }}
        </el-button>
      </router-link>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center mb-6">
        <div class="d-flex align-items-center">
          <div class="d-block me-3" v-if="computedShowBackLink">
            <router-link
              :to="computedBackLink"
              class="btn btn-secondary btn-sm"
            >
              <i class="fa fa-chevron-left"></i>
            </router-link>
          </div>
          <div>
            <h3 class="fw-bolder my-2">{{ data.openPoint.title }}</h3>
            <small>{{ computedTime(data.openPoint.createdAt) }}</small>
          </div>
        </div>
        <manage-watchers-modal
          v-if="computedIsAbleToChangeWatchers && !disableEdit"
          v-model="data.openPoint"
        />
      </div>
    </div>
    <div class="col-12">
      <div class="d-flex flex-column flex-lg-row">
        <div class="flex-lg-row-fluid">
          <div :class="computedCardClass">
            <div class="card-header row">
              <div
                class="col-lg-9 col-md-8 d-flex justify-content-center align-items-start flex-column"
              >
                <div class="card-title" v-if="computedShowAssignee">
                  <div class="d-flex justify-content-center flex-column me-3">
                    <div class="mb-0 lh-1" v-if="computedIsCurrentUserAssignee">
                      <span
                        class="badge badge-success badge-circle w-10px h-10px me-2"
                      ></span
                      ><span class="fs-7 fw-bold text-gray-400">{{
                        $t('opl.assigned')
                      }}</span>
                    </div>
                    <div class="mb-0 lh-1" v-else>
                      <span
                        v-if="computedCurrentUserAssignee"
                        v-html="
                          $t('opl.assignedOther', {
                            name: computedCurrentUserAssignee.name,
                          })
                        "
                        class="fs-7 fw-bold text-gray-400"
                      />
                      <span v-else class="fs-7 fw-bold text-gray-400">{{
                        $t('opl.assignedNone')
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-title" v-if="computedShowInitialAssignees">
                  <div class="d-flex justify-content-center flex-column me-3">
                    <div class="mb-0 lh-1">
                      <span class="fs-7 fw-bold text-gray-400 me-2">{{
                        $t('opl.assignedInitial')
                      }}</span>
                      <span
                        v-for="(initialAssignee, index) in data.openPoint
                          .initialAssignees"
                        :key="index"
                        :class="computedInitialAssigneeClass(initialAssignee)"
                      >
                        {{ initialAssignee.name }}
                      </span>
                    </div>
                  </div>
                </div>
                <span>
                  <open-point-state-badge :state="data.openPoint.state" />
                  <span class="fs-7 fw-bold text-gray-400 ms-3"
                    >ist der aktuelle Status.</span
                  >
                </span>
                <div class="mt-3">
                  <span
                    v-if="data.openPoint.isUrgent"
                    class="badge badge-light-danger me-3"
                    >{{ $t('opl.form.immediate') }}</span
                  >
                  <span
                    v-if="data.openPoint.isAccidentPrevention"
                    class="badge badge-light-dark"
                    >{{ $t('opl.form.accidentPrevention') }}</span
                  >
                </div>
              </div>
            </div>
            <div class="card-body" id="kt_chat_messenger_body">
              <div class="me-n5 pe-5">
                <div
                  :class="`d-flex flex-column min-w-600px ${computedInitClass} mb-5`"
                >
                  <!--begin::User-->
                  <div
                    class="d-flex align-items-center mb-2 min-w-600px chat-message-user"
                  >
                    <user-icon :user="data.openPoint.creator" />
                    <!--begin::Details-->
                    <div
                      class="ms-4 d-flex align-items-center chat-message-user-name"
                    >
                      <span class="fs-5 fw-bolder text-gray-900 me-1">{{
                        data.openPoint.creator.name
                      }}</span>
                      <span
                        class="text-muted fs-7"
                        :title="computedTime(data.openPoint.createdAt)"
                        >{{
                          computedAgo(
                            data.openPoint.createdAt,
                            computedCurrentLanguage,
                          )
                        }}</span
                      >
                    </div>
                    <!--end::Details-->
                  </div>
                  <router-link
                    :to="`/opl/overview/${data.openPoint.parent.id}`"
                    v-if="computedHasVisibleParent"
                    class="p-3 my-3 rounded text-dark fw-bold min-w-lg-700px mw-700px chat-message-content border border-primary border-dashed d-flex align-items-center bg-light bg-hover-white"
                  >
                    <span class="badge badge-light-primary me-3">{{
                      $t('opl.form.parent.self')
                    }}</span>
                    <open-point-state-badge
                      :state="data.openPoint.parent.state"
                    />
                    <span
                      v-if="data.openPoint.parent.isAccidentPrevention"
                      class="badge badge-light-dark ms-3"
                      >{{ $t('opl.form.accidentPreventionShort') }}</span
                    >
                    <span class="flex-grow-1 me-3 ms-3">{{
                      data.openPoint.parent.title
                    }}</span>
                    <div
                      v-if="data.openPoint.parent.assignee"
                      class="text-muted me-3 self-end text-nowrap"
                    >
                      <i class="fa fa-user"></i>
                      {{ data.openPoint.parent.assignee.name }}
                    </div>
                    <div
                      v-if="data.openPoint.parent.dueDate"
                      class="text-muted text-nowrap"
                    >
                      <i class="fa fa-clock"></i>
                      {{ computedDate(data.openPoint.parent.dueDate) }}
                    </div>
                    <span
                      v-if="data.openPoint.parent.isUrgent"
                      class="badge badge-light-danger me-3"
                      >{{ $t('opl.form.immediate') }}</span
                    >
                  </router-link>

                  <div
                    class="p-5 rounded chat-message-content min-w-600px"
                    v-if="computedHasInitialMessageData"
                  >
                    <div
                      v-if="!data.openPoint.isUrgent && data.openPoint.dueDate"
                      class="row"
                    >
                      <div class="col-3">
                        <span class="col-form-label fw-bold fs-6">{{
                          $t('opl.form.dueDate')
                        }}</span>
                      </div>
                      <div
                        class="col-9 d-flex align-items-center justify-content-start"
                      >
                        <span class="fs-6 me-3">{{
                          computedDate(data.openPoint.dueDate)
                        }}</span>
                        <span class="text-muted fs-7">{{
                          computedAgo(
                            data.openPoint.dueDate,
                            computedCurrentLanguage,
                          )
                        }}</span>
                      </div>
                    </div>
                    <div v-if="data.openPoint.watchers.length" class="row mt-3">
                      <div class="col-3">
                        <span class="col-form-label fw-bold fs-6">{{
                          $t('opl.form.watcher')
                        }}</span>
                      </div>
                      <div
                        class="col-9 d-flex align-items-center justify-content-start"
                      >
                        <ul
                          class="default-list"
                          v-if="data.openPoint.watchers.length"
                        >
                          <li
                            v-for="watcher of data.openPoint.watchers"
                            :key="watcher.id"
                          >
                            <span v-if="watcher.name"
                              >{{ watcher.name }}
                              <small class="text-muted">{{
                                watcher.email
                              }}</small></span
                            >
                            <span v-else>{{ watcher.email }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mt-3" v-if="data.openPoint.comment">
                      <div class="col-3">
                        <span class="col-form-label fw-bold fs-6">{{
                          $t('opl.form.comment')
                        }}</span>
                      </div>
                      <div
                        class="col-9 d-flex align-items-center justify-content-start"
                      >
                        <span
                          class="fs-6 me-3"
                          v-html="data.openPoint.comment"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="row mt-3"
                      v-if="data.openPoint.attachments.length"
                    >
                      <div class="col-3">
                        <span class="col-form-label fw-bold fs-6">{{
                          $t('opl.form.attachments')
                        }}</span>
                      </div>
                      <div
                        class="col-9 d-flex align-items-center justify-content-start image-input"
                      >
                        <template
                          v-for="attachment of data.openPoint.attachments"
                          :key="attachment.id"
                        >
                          <a
                            v-if="attachment.type === 'image'"
                            :href="computedFileUrl(attachment.fileName)"
                            target="_blank"
                            class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center me-2"
                            :style="`background-image: url(${computedFileUrl(
                              attachment.fileName,
                            )})`"
                          ></a>
                          <a
                            v-if="attachment.type === 'document'"
                            :href="computedFileUrl(attachment.fileName)"
                            target="_blank"
                            class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center flex-column border border-1 border-primary me-2"
                          >
                            <span
                              class="svg-icon svg-icon-4x svg-icon-primary"
                              :title="
                                computedFileNameFromUrl(attachment.fileName)
                              "
                            >
                              <inline-svg
                                src="media/icons/duotune/files/fil003.svg"
                              />
                            </span>
                            <span>{{
                              computedFileEnding(attachment.fileName)
                            }}</span>
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div v-if="computedVisibleChildren">
                    <router-link
                      v-for="child in computedVisibleChildren"
                      :key="child.id"
                      :to="`/opl/overview/${child.id}`"
                      class="p-3 my-3 rounded text-dark fw-bold min-w-lg-700px mw-700px chat-message-content border border-primary border-dashed d-flex align-items-center bg-light bg-hover-white"
                    >
                      <span class="badge badge-light-primary me-3">{{
                        $t('opl.form.children.self')
                      }}</span>
                      <open-point-state-badge :state="child.state" />
                      <span
                        v-if="child.isAccidentPrevention"
                        class="badge badge-light-dark ms-3"
                        >{{ $t('opl.form.accidentPreventionShort') }}</span
                      >
                      <span class="flex-grow-1 me-3 ms-3">{{
                        child.title
                      }}</span>
                      <div
                        v-if="child.assignee"
                        class="text-muted me-3 self-end text-nowrap"
                      >
                        <i class="fa fa-user"></i>
                        {{ child.assignee.name }}
                      </div>
                      <div v-if="child.dueDate" class="text-muted text-nowrap">
                        <i class="fa fa-clock"></i>
                        {{ computedDate(child.dueDate) }}
                      </div>
                      <span
                        v-if="child.isUrgent"
                        class="badge badge-light-danger me-3"
                        >{{ $t('opl.form.immediate') }}</span
                      >
                    </router-link>
                  </div>

                  <!--end::Text-->
                </div>

                <hr
                  v-if="
                    data.openPoint.messages.length || computedHasChangeActions
                  "
                  class="bg-primary"
                />

                <div
                  v-for="message of data.openPoint.messages"
                  :key="message.id"
                  :class="`d-flex flex-column ${computedMessageClass(
                    message,
                  )} my-7`"
                >
                  <!--begin::User-->
                  <div class="d-flex align-items-center mb-2 chat-message-user">
                    <user-icon :user="message.author" />
                    <!--begin::Details-->
                    <div
                      class="ms-4 d-flex align-items-center chat-message-user-name"
                    >
                      <span class="fs-5 fw-bolder text-gray-900 me-1">{{
                        message.author.name
                      }}</span>
                      <span
                        class="text-muted fs-7"
                        :title="computedTime(message.createdAt)"
                        >{{
                          computedAgo(
                            message.createdAt,
                            computedCurrentLanguage,
                          )
                        }}</span
                      >
                    </div>
                    <!--end::Details-->
                  </div>
                  <div class="mb-2" v-if="message.needsDecision">
                    <span
                      class="badge badge-secondary"
                      v-if="message.decision === null"
                    >
                      <i class="fa fa-clock"></i>
                      {{
                        $t('opl.stateChangesDecisions.waiting', {
                          type: computedStateChangeType(message),
                        })
                      }}</span
                    >
                    <span
                      class="badge badge-success"
                      v-if="
                        message.decision &&
                        message.decision.state === 'accepted'
                      "
                      >{{
                        $t('opl.stateChangesDecisions.accepted', {
                          type: computedStateChangeType(message),
                          date: computedDate(message.decision.createdAt),
                          decider: data.openPoint.creator.name,
                        })
                      }}</span
                    >
                    <div
                      v-if="
                        message.decision &&
                        message.decision.state === 'declined'
                      "
                    >
                      <span class="badge badge-danger">{{
                        $t('opl.stateChangesDecisions.declined', {
                          type: computedStateChangeType(message),
                          date: computedDate(message.decision.createdAt),
                          decider: data.openPoint.creator.name,
                        })
                      }}</span>
                      <div
                        class="p-5 rounded text-dark mw-lg-700px text-start chat-message-content mt-2"
                        v-if="message.decision.reason"
                      >
                        <strong>{{ $t('opl.form.reason') }}:</strong>
                        {{ message.decision.reason }}
                      </div>
                    </div>
                  </div>
                  <!--end::User--><!--begin::Text-->
                  <div
                    v-if="message.changes.length !== 0"
                    class="p-3 rounded text-dark fw-bold mw-lg-700px text-start chat-message-content bg-light-dark border border-primary border-dashed d-grid gap-2"
                  >
                    <div v-for="change of message.changes" :key="change.id">
                      <div v-if="change.field === 'assignee'">
                        <p
                          class="mb-0"
                          v-html="
                            $t('opl.changes.assignee', {
                              before: change.fromValueParsed
                                ? change.fromValueParsed.name
                                : $t('noOne'),
                              beforeUsername: change.fromValueParsed
                                ? change.fromValueParsed.enail
                                : $t('noOne'),
                              after: change.toValueParsed
                                ? change.toValueParsed.name
                                : $t('noOne'),
                              afterUsername: change.toValueParsed
                                ? change.toValueParsed.enail
                                : $t('noOne'),
                            })
                          "
                        ></p>
                      </div>
                      <div v-if="change.field === 'additionalAssignee'">
                        <p
                          class="mb-0"
                          v-html="
                            $t('opl.changes.additionalAssignee', {
                              name: change.fromValueParsed
                                ? change.fromValueParsed.name
                                : $t('noOne'),
                              email: change.fromValueParsed
                                ? change.fromValueParsed.email
                                : $t('noOne'),
                            })
                          "
                        ></p>
                      </div>
                      <div v-if="change.field === 'removeAdditionalAssignee'">
                        <p
                          class="mb-0"
                          v-html="
                            $t('opl.changes.removeAdditionalAssignee', {
                              name: change.fromValueParsed
                                ? change.fromValueParsed.name
                                : $t('noOne'),
                              email: change.fromValueParsed
                                ? change.fromValueParsed.email
                                : $t('noOne'),
                            })
                          "
                        ></p>
                      </div>
                      <div v-if="change.field === 'dueDate'">
                        <p
                          class="mb-0"
                          v-html="
                            $t('opl.changes.dueDate', {
                              before: computedDate(change.fromValue),
                              after: computedDate(change.toValue),
                            })
                          "
                        ></p>
                      </div>
                      <div v-if="change.field === 'state'">
                        <p
                          class="mb-0"
                          v-html="
                            $t('opl.changes.state', {
                              before: $t(`opl.states.${change.fromValue}`),
                              beforeClass: `badge-${$t(
                                `opl.statesBadges.${change.fromValue}`,
                              )}`,
                              after: $t(`opl.states.${change.toValue}`),
                              afterClass: `badge-${$t(
                                `opl.statesBadges.${change.toValue}`,
                              )}`,
                            })
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="p-5 rounded text-dark fw-bold mw-lg-700px text-start chat-message-content mt-2"
                    v-if="message.text !== null"
                  >
                    <p
                      class="mb-0"
                      v-html="computedMessageText(message.text)"
                    />

                    <div class="row mb-3" v-if="message.attachments.length">
                      <div
                        class="col-12 d-flex align-items-center justify-content-start image-input"
                      >
                        <template
                          v-for="attachment of message.attachments"
                          :key="attachment.id"
                        >
                          <a
                            v-if="attachment.type === 'image'"
                            :href="computedFileUrl(attachment.fileName)"
                            target="_blank"
                            class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center me-2"
                            :style="`background-image: url(${computedFileUrl(
                              attachment.fileName,
                            )})`"
                          ></a>
                          <a
                            v-if="attachment.type === 'document'"
                            :href="computedFileUrl(attachment.fileName)"
                            target="_blank"
                            class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center flex-column border border-1 border-primary me-2"
                          >
                            <span
                              class="svg-icon svg-icon-4x svg-icon-primary"
                              :title="
                                computedFileNameFromUrl(attachment.fileName)
                              "
                            >
                              <inline-svg
                                src="media/icons/duotune/files/fil003.svg"
                              />
                            </span>
                            <span>{{
                              computedFileEnding(attachment.fileName)
                            }}</span>
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!--end::Text-->
                  <div
                    class="d-flex flex-column chat-else my-7"
                    v-if="
                      computedIsMessageWithDecision(message) &&
                      computedIsCurrentUserCreator &&
                      message.decision === null
                    "
                  >
                    <div
                      class="d-flex align-items-center mb-2 chat-message-user"
                    >
                      <user-icon :user="currentUser" />
                      <div class="d-flex">
                        <button
                          class="btn btn-success ms-2"
                          type="button"
                          @click="handleOpenPointChangeAccept(message)"
                        >
                          {{ $t('accept') }}
                        </button>
                        <button
                          class="btn btn-danger ms-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#declineModal"
                          @click="handleOpenPointChangeDeclineClick(message)"
                        >
                          {{ $t('decline') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex flex-column chat-self my-7"
                  v-if="computedHasChangeActions"
                >
                  <div class="d-flex align-items-center mb-2 chat-message-user">
                    <user-icon :user="currentUser" />
                    <div class="d-flex">
                      <button
                        class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#changeAssigneeModal"
                        v-if="computedIsAbleToChangeAssignee"
                        @click="handleChangeAssigneeClick"
                      >
                        {{ $t('opl.form.assign') }}
                      </button>
                      <button
                        class="btn btn-outline btn-outline-dashed btn-outline-warning btn-active-light-warning ms-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#postponeModal"
                        v-if="computedIsAbleToPostpone"
                        @click="handlePostponeClick"
                      >
                        {{ $t('opl.form.postpone') }}
                      </button>
                      <button
                        class="btn btn-light-danger ms-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#declineModal"
                        v-if="computedIsAbleToDecline"
                        @click="handleDeclineClick"
                      >
                        {{ $t('opl.form.decline') }}
                      </button>

                      <button
                        id="completeButton"
                        class="btn btn-light-success ms-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#completeModal"
                        v-if="computedIsAbleToComplete"
                        @click="handleCompleteClick"
                      >
                        {{ $t('opl.form.complete') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!disableEdit"
              class="card-footer pt-4"
              id="kt_chat_messenger_footer"
            >
              <button
                v-if="!addNewComment"
                class="btn btn-primary btn-lg"
                @click="addNewComment = true"
              >
                {{ $t('opl.form.newComment') }}
              </button>

              <MyForm
                v-else
                v-show="computedIsAbleToAddMessage"
                id="sendMessageForm"
                class="form"
                novalidate="novalidate"
                @submit="sendMessage"
                @reset="cancelMessage"
                method="post"
                action="javascript:"
                :validation-schema="messageValidator"
                ref="messageForm"
              >
                <Field
                  type="text"
                  as="textarea"
                  name="message"
                  class="form-control form-control-flush mb-3 border d-none"
                  v-model="data.currentMessage.text"
                  :placeholder="$t('opl.form.messageHere')"
                  ref="textInput"
                />

                <TinyMceEditor
                  :language="computedCurrentLanguage"
                  v-model="data.currentMessage.text"
                />

                <div class="fv-plugins-message-container ps-4 mb-3">
                  <div class="fv-help-block">
                    <ErrorMessage name="message" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="col-form-label fw-bold fs-6">{{
                      $t('opl.form.attachments')
                    }}</label>
                  </div>
                  <div class="col-12 d-flex flex-wrap">
                    <div
                      class="image-input image-input-outline me-5 mb-5"
                      v-for="(attachment, index) of data.currentMessage
                        .attachments"
                      :key="index"
                    >
                      <a
                        :href="computedFileUrl(attachment.fileName)"
                        target="_blank"
                        class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
                        :style="`background-image: url(${computedFileUrl(
                          attachment.fileName,
                        )})`"
                      ></a>
                      <a
                        href="javascript:"
                        @click="handleRemoveAttachment(index)"
                        data-kt-image-input-action="change"
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        ><i class="bi bi-x fs-2"></i
                      ></a>
                    </div>

                    <div class="image-input image-input-outline me-5 mb-5">
                      <button
                        type="button"
                        v-if="1"
                        class="btn btn-primary me-2 w-100px h-100px d-flex justify-content-center align-items-center"
                        @click="handleAddAttachmentClick"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-start">
                    <input
                      v-show="0"
                      ref="attachmentInput"
                      type="file"
                      @change="handleAttachmentChange"
                    />
                  </div>
                </div>

                <div class="d-flex justify-content-start mb-3">
                  <div class="d-flex align-items-center me-2" v-if="0">
                    <button
                      class="btn btn-sm btn-icon btn-active-light-primary me-1"
                      type="button"
                      @click="handleAddAttachmentClick"
                    >
                      <i class="bi bi-paperclip fs-3"></i>
                    </button>
                  </div>
                  <div
                    class="form-check form-check-custom form-check-solid"
                    v-if="computedAutoSetProgressAvailable"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="data.currentMessage.autoSetProgress"
                      id="flexCheckDefault"
                      :checked-value="true"
                      :unchecked-value="false"
                    />
                    <label class="form-check-label" for="flexCheckDefault"
                      >automatisch auf
                      <strong>In Bearbeitung</strong> setzen</label
                    >
                  </div>
                </div>

                <button
                  class="btn btn-secondary me-2"
                  type="reset"
                  data-kt-element="reset"
                >
                  {{ $t('cancel') }}
                </button>
                <button
                  class="btn btn-primary"
                  type="submit"
                  data-kt-element="send"
                >
                  {{ $t('send') }}
                </button>
              </MyForm>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="changeAssigneeModal"
      ref="changeAssigneeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <!--begin::Modal title-->
            <h2>{{ $t('opl.form.assign') }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Form-->
          <MyForm
            class="form"
            @submit="handleChangeAssignee"
            :validation-schema="assignValidator"
          >
            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Input group-->

              <p class="text-gray-500 mb-5">
                {{ $t('opl.changeAssigneeHint') }}
              </p>

              <div class="fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">{{
                  $t('opl.form.newAssignees')
                }}</label>
                <!--end::Label-->
                <Field
                  name="newAssignee"
                  v-show="0"
                  v-model="data.currentAssignees"
                />
                <assignee-multi-select v-model="data.currentAssignees" />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="newAssignee" />
                  </div>
                </div>
                <!--end::Input-->
              </div>

              <div class="fv-row">
                <label class="col-form-label required fw-bold fs-6">{{
                  $t('opl.form.reason')
                }}</label>

                <Field
                  as="textarea"
                  name="reason"
                  class="form-control form-control-solid"
                  v-model="data.currentAssigneeComment"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="reason" />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-end">
              <!--begin::Button-->
              <button class="btn btn-primary me-2" type="submit">
                {{ $t('opl.form.assign') }}
              </button>
            </div>
            <!--end::Modal footer-->
          </MyForm>
          <!--end::Form-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>

    <div
      v-if="data.openPoint.dueDate"
      class="modal fade"
      id="postponeModal"
      ref="postponeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <!--begin::Modal title-->
            <h2>{{ $t('opl.form.postpone') }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Form-->
          <MyForm
            class="form"
            @submit="handlePostpone"
            :validation-schema="postponeValidator"
          >
            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Input group-->

              <p class="text-gray-500 mb-5">
                {{ $t('opl.changeDueDateHint') }}
              </p>

              <div class="fv-row">
                <label class="col-form-label required fw-bold fs-6">{{
                  $t('opl.form.dueDate')
                }}</label>

                <el-date-picker
                  v-model="data.currentDueDateOptions.dueDate"
                  placeholder="Select a date"
                  name="dueDate"
                  class="w-100"
                  :format="$t('dateFormat')"
                />
                <Field
                  v-show="0"
                  name="dueDate"
                  v-model="data.currentDueDateOptions.dueDate"
                />

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="dueDate" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <div class="fv-row">
                <label class="col-form-label required fw-bold fs-6">{{
                  $t('opl.form.reason')
                }}</label>

                <Field
                  as="textarea"
                  name="reason"
                  class="form-control form-control-solid"
                  v-model="data.currentDueDateOptions.reason"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="reason" />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-end">
              <!--begin::Button-->
              <button class="btn btn-primary me-2" type="submit">
                {{ $t('opl.form.postpone') }}
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </MyForm>
          <!--end::Form-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>

    <div
      class="modal fade"
      id="declineModal"
      ref="declineModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <!--begin::Modal title-->
            <h2>{{ $t('opl.form.decline') }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Form-->
          <MyForm
            class="form"
            @submit="handleDecline"
            :validation-schema="declineValidator"
          >
            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Input group-->

              <p class="text-gray-500 mb-5">
                {{ $t('opl.declineHint') }}
              </p>

              <div class="fv-row">
                <label class="col-form-label required fw-bold fs-6">{{
                  $t('opl.form.reason')
                }}</label>

                <Field
                  as="textarea"
                  name="reason"
                  class="form-control form-control-solid"
                  v-model="data.currentDeclineReason"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="reason" />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-end">
              <!--begin::Button-->
              <button class="btn btn-primary me-2" type="submit">
                {{ $t('opl.form.decline') }}
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </MyForm>
          <!--end::Form-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>

    <div
      class="modal fade"
      id="completeModal"
      ref="completeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <!--begin::Modal title-->
            <h2>{{ $t('opl.form.complete') }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Form-->
          <MyForm
            class="form"
            @submit="handleComplete"
            :validation-schema="completeValidator"
          >
            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Input group-->
              <div class="fv-row">
                <div class="col-12">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('opl.form.attachments')
                  }}</label>
                </div>
                <div class="col-12 d-flex flex-wrap">
                  <div
                    class="image-input image-input-outline me-5 mb-5"
                    v-for="(attachment, index) of data.currentCompleteOptions
                      .attachments"
                    :key="index"
                  >
                    <a
                      :href="computedFileUrl(attachment.fileName)"
                      target="_blank"
                      class="image-input-wrapper w-100px h-100px d-flex justify-content-center align-items-center"
                      :style="`background-image: url(${computedFileUrl(
                        attachment.fileName,
                      )})`"
                    ></a>
                    <a
                      href="javascript:"
                      @click="handleRemoveModalAttachment(index)"
                      data-kt-image-input-action="change"
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                      ><i class="bi bi-x fs-2"></i
                    ></a>
                  </div>
                  <div class="image-input image-input-outline me-5 mb-5">
                    <button
                      type="button"
                      v-if="1"
                      class="btn btn-primary me-2 w-100px h-100px d-flex justify-content-center align-items-center"
                      @click="handleAddModalAttachmentClick(index)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="col-12 d-flex align-items-start">
                  <input
                    v-show="0"
                    ref="modalAttachmentInput"
                    type="file"
                    @change="handleModalAttachmentChange"
                  />
                  <Field
                    v-show="0"
                    as="textarea"
                    name="attachments"
                    v-model="data.currentCompleteOptions.attachments"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="attachments" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="fv-row">
                <label
                  :class="`col-form-label fw-bold fs-6 ${
                    computedMessageRequiredForComplete ? 'required' : ''
                  }`"
                  >{{ $t('opl.form.comment') }}</label
                >

                <Field
                  as="textarea"
                  name="reason"
                  class="form-control form-control-solid"
                  v-model="data.currentCompleteOptions.reason"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="reason" />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-end">
              <!--begin::Button-->
              <button class="btn btn-primary me-2" type="submit">
                {{ $t('opl.form.complete') }}
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </MyForm>
          <!--end::Form-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
  </div>
  <!--end::Dashboard-->
</template>
<script lang="ts">
  import {
    MessageChangeType,
    OpenPoint,
    OpenPointAssignee,
    OpenPointAttachment,
    OpenPointAttachmentType,
    OpenPointChange,
    OpenPointCompleteOptions,
    OpenPointFormData,
    OpenPointInitialAssignee,
    OpenPointMessage,
    OpenPointMessageAuthor,
    OpenPointMessageState,
    OpenPointMessageWithConfig,
    OpenPointPostponeOptions,
    OpenPointState,
  } from '@/core/models/opl';
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    Ref,
  } from 'vue';
  import { computedAgo, computedDate, computedTime } from '@/core/helpers/cdf';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { Actions } from '@/store/enums/StoreEnums';
  import UserIcon from '../../components/UserIcon.vue';
  import OpenPointStateBadge from '../../components/opl/OpenPointStateBadge.vue';
  import SimpleOrgUnitPath from '../../components/fes/SimpleOrgUnitPath.vue';
  import TinyMceEditor from '@/components/TinyMceEditor.vue';
  import { useI18n } from 'vue-i18n';
  import { User } from '@/store/modules/AuthModule';
  import { configure, ErrorMessage, Field, Form } from 'vee-validate';
  import * as Yup from 'yup';
  import axios from 'axios';
  import { hideModal } from '@/core/helpers/dom';
  import {
    ErrorCaseCDFName,
    FESConfig,
    OrgChartDataPairWithCatalogs,
  } from '@/core/models/fes';
  import ManageWatchersModal from '@/components/opl/ManageWatchersModal.vue';
  import AssigneeMultiSelect from '@/components/opl/AssigneeMultiSelect.vue';
  // import VueEasyLightbox from 'vue-easy-lightbox';

  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: false,
  });

  export enum DeclineChangeType {
    FULL = 'full',
    DECISION = 'decision',
  }

  export default defineComponent({
    name: 'oplSingle',
    components: {
      AssigneeMultiSelect,
      ManageWatchersModal,
      UserIcon,
      MyForm: Form,
      ErrorMessage,
      Field,
      OpenPointStateBadge,
      SimpleOrgUnitPath,
      TinyMceEditor,
      // VueEasyLightbox,
    },
    props: {
      openPointId: {
        type: Number,
        required: false,
      },
      backAction: {
        type: Function,
        required: false,
      },
      disableEdit: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore();
      const router = useRouter();
      const { t, locale } = useI18n();

      const computedCurrentLanguage = computed(() => {
        return locale.value;
      });

      // const textInput: Ref<HTMLElement | null> = ref(null);
      const attachmentInput: Ref<HTMLElement | null> = ref(null);
      const modalAttachmentInput: Ref<HTMLElement | null> = ref(null);
      const changeAssigneeModal = ref<null | HTMLElement>(null);
      const postponeModal = ref<null | HTMLElement>(null);
      const declineModal = ref<null | HTMLElement>(null);
      const completeModal = ref<null | HTMLElement>(null);
      const messageForm = ref(Form);

      const addNewComment = ref(false);

      const currentUser: User = { ...store.getters.currentUser };

      const data: {
        openPoint: OpenPointFormData;
        isLoaded: boolean;
        currentMessage: OpenPointMessageWithConfig;
        lightboxVisible: boolean;
        currentAssignees: OpenPointAssignee[];
        currentAssigneeComment: string;
        currentDueDateOptions: OpenPointPostponeOptions;
        currentCompleteOptions: OpenPointCompleteOptions;
        currentDeclineReason: string;
        currentDeclineType: DeclineChangeType;
        currentDeclineMessage: OpenPointMessage | null;
        orgLevels: string[];
        orgChart: OrgChartDataPairWithCatalogs[];
        errorCaseCdfNames: ErrorCaseCDFName[];
        currentUserAreas: string[];
      } = reactive({
        openPoint: {} as OpenPointFormData,
        isLoaded: false,
        currentMessage: {
          attachments: [] as OpenPointAttachment[],
          changes: [] as OpenPointChange[],
          autoSetProgress: true,
        } as OpenPointMessageWithConfig,
        lightboxVisible: false,
        currentAssigneeComment: '',
        currentAssignees: [],
        currentDueDateOptions: {
          dueDate: '',
          reason: '',
        },
        currentCompleteOptions: {
          attachments: [],
          reason: '',
        },
        currentDeclineReason: '',
        currentDeclineType: DeclineChangeType.FULL,
        currentDeclineMessage: null,
        orgLevels: [],
        orgChart: [],
        errorCaseCdfNames: [] as ErrorCaseCDFName[],
        currentUserAreas: [] as string[],
      });

      const computedMessageText = computed(() => {
        return (text) => {
          return text.replace(/\r\n|\r|\n/g, '<br />');
        };
      });

      const computedIsAllowedToCreateSubOpenPoint = computed(() => {
        return (
          data.openPoint.parent === null &&
          (computedIsCurrentUserAssignee.value ||
            computedIsCurrentUserCreator.value)
        );
      });

      const computedHasChangeActions = computed(() => {
        return (
          (computedIsAbleToChangeAssignee.value ||
            computedIsAbleToPostpone.value ||
            computedIsAbleToComplete.value ||
            computedIsAbleToDecline.value) &&
          !computedHasMessageWithOpenDecision.value &&
          !props.disableEdit
        );
      });
      const computedIsAbleToChangeAssignee = computed(() => {
        // if (currentUser && currentUser.id === data.openPoint.creator?.userId)
        //   return true;

        if (
          ![OpenPointState.CLOSED].includes(data.openPoint.state) &&
          computedIsCurrentUserCreator.value
        )
          return true;

        return (
          ![OpenPointState.CLOSED].includes(data.openPoint.state) &&
          computedIsCurrentUserAssignee.value &&
          data.openPoint.messages.filter((message: OpenPointMessage) => {
            return (
              message.changes.length === 1 &&
              message.changes.filter((change: OpenPointChange) => {
                return change.field === 'assignee';
              }).length > 0
            );
          }).length === 0
        );
      });

      const computedIsAbleToChangeWatchers = computed(() => {
        return (
          ![OpenPointState.CLOSED].includes(data.openPoint.state) &&
          computedIsCurrentUserAssignee.value
        );
      });

      const computedIsAbleToAddMessage = computed(() => {
        return true;
      });

      const computedIsCurrentUserLastPostponeUser = computed(() => {
        const allPostponeChanges = data.openPoint.messages.filter(
          (message: OpenPointMessage) => {
            if (message.changes.length !== 0) {
              for (const change of message.changes) {
                if (change.field === 'dueDate') return true;
              }
            }
            return false;
          },
        );

        if (allPostponeChanges.length === 0) return false;

        const lastPostpone = allPostponeChanges.slice(0).pop();
        return lastPostpone?.author.userId === currentUser.id;
      });

      const computedIsAbleToPostpone = computed(() => {
        return (
          data.openPoint.dueDate !== null &&
          data.openPoint.state === OpenPointState.OPEN &&
          computedIsCurrentUserAssignee.value &&
          !computedIsCurrentUserLastPostponeUser.value
        );
      });

      const computedIsAbleToComplete = computed(() => {
        return (
          ![OpenPointState.CLOSED].includes(data.openPoint.state) &&
          (computedIsCurrentUserAssignee.value ||
            computedIsCurrentUserCreator.value)
        );
      });

      const computedIsAbleToDecline = computed(() => {
        return (
          data.openPoint.state === OpenPointState.OPEN &&
          computedIsCurrentUserAssignee.value &&
          currentUser.id !== data.openPoint.creator?.userId
        );
      });

      const computedShowBackLink = computed(() => {
        return typeof props.backAction === 'undefined';
      });

      const computedBackLink = computed(() => {
        if (window.history && window.history.state) {
          if (window.history.state.back !== null) {
            return window.history.state.back;
          }
        }

        return '/opl/overview';
      });

      function handleChangeAssigneeClick() {
        data.currentAssigneeComment = '';
        data.currentAssignees = !data.openPoint.assignee
          ? []
          : data.openPoint.initialAssignees;
      }

      function handlePostponeClick() {
        if (!data.openPoint.dueDate) return false;
        data.currentDueDateOptions.dueDate = data.openPoint.dueDate;
        data.currentDueDateOptions.reason = '';
      }

      function handleDeclineClick() {
        data.currentDeclineReason = '';
        data.currentDeclineType = DeclineChangeType.FULL;
        data.currentDeclineMessage = null;
      }

      function handleCompleteClick() {
        data.currentCompleteOptions = {
          attachments: [],
          reason: '',
        };
      }

      async function loadOpenPoint(openPointId) {
        try {
          data.openPoint = await (
            await ApiService.get(`opl/${openPointId}`)
          ).data;
          // await loadOpenPointUserAreas(data.openPoint);

          try {
            data.currentUserAreas = await (
              await ApiService.post('fes/userareas', {
                data: {
                  locations: data.openPoint.locations.map((location) => {
                    return location.locationId;
                  }),
                },
              })
            ).data;
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          await router.push('/opl/overview');
          return Swal.fire(t('error'), t('errorCaseNotFound'), 'error');
        }
      }

      const computedIsLoaded = computed(() => {
        return typeof data.openPoint.id !== 'undefined';
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/opl/attachment/' +
            fileName
          );
        };
      });

      const computedFileEnding = computed(() => {
        return (fileName) => {
          return String(fileName.split('.').pop()).toUpperCase();
        };
      });

      const computedIsCurrentUserCreator = computed(() => {
        return currentUser.id === data.openPoint.creator?.userId;
      });

      const computedInitClass = computed(() => {
        return computedIsCurrentUserCreator.value ? 'chat-self' : 'chat-else';
      });

      const computedMessageClass = computed(() => {
        return (message: OpenPointMessage) => {
          return currentUser.id === message.author.userId
            ? 'chat-self'
            : 'chat-else';
        };
      });

      const messageValidator = reactive(
        Yup.object().shape({
          message: Yup.string()
            .required()
            .label('Message')
            .required(t('opl.errors.missingMessage'))
            .typeError(t('opl.errors.missingMessage')),
        }),
      );

      const assignValidator = reactive(
        Yup.object().shape({
          newAssignee: Yup.array()
            .min(1)
            .required(t('opl.errors.missingAssignee'))
            .typeError(t('opl.errors.missingAssignee')),
          reason: Yup.string()
            .min(1)
            .required(t('opl.errors.missingReason'))
            .typeError(t('opl.errors.missingReason')),
        }),
      );

      const postponeValidator = reactive(
        Yup.object().shape({
          dueDate: Yup.string()
            .min(1)
            .required(t('opl.errors.missingDueDate'))
            .typeError(t('opl.errors.missingDueDate')),
          reason: Yup.string()
            .min(1)
            .required(t('opl.errors.missingReason'))
            .typeError(t('opl.errors.missingReason')),
        }),
      );

      const declineValidator = reactive(
        Yup.object().shape({
          reason: Yup.string()
            .min(1)
            .required(t('opl.errors.missingReason'))
            .typeError(t('opl.errors.missingReason')),
        }),
      );

      const completeValidator = reactive(
        Yup.object().shape({
          reason: Yup.string().when('reasonNeeded', {
            is: () => {
              return computedMessageRequiredForComplete.value;
            },
            then: Yup.string()
              .min(1)
              .required(t('opl.errors.missingReason'))
              .typeError(t('opl.errors.missingReason')),
            otherwise: Yup.string().notRequired(),
          }),
        }),
      );

      async function sendMessage() {
        await store.dispatch(Actions.START_LOADER);

        const newMessageResponse = await ApiService.post('opl/message', {
          data: data.currentMessage,
        });

        data.openPoint = newMessageResponse.data;
        data.currentMessage.openPoint = data.openPoint;

        data.currentMessage.text = '';
        data.currentMessage.attachments = [] as OpenPointAttachment[];
        await store.dispatch(Actions.END_LOADER);
        messageForm.value.resetForm();
        addNewComment.value = false;
      }

      function cancelMessage() {
        addNewComment.value = false;
        data.currentMessage = {
          attachments: [],
          changes: [],
          autoSetProgress: true,
        } as OpenPointMessageWithConfig;
      }

      const computedIsCurrentUserAssignee = computed(() => {
        if (data.currentUserAreas.includes(currentUser.id)) return true;

        if (
          data.openPoint.initialAssignees.length &&
          data.openPoint.assignee === null
        ) {
          return (
            data.openPoint.initialAssignees.findIndex(
              (initialAssignee: OpenPointInitialAssignee) => {
                return initialAssignee.userId === currentUser.id;
              },
            ) !== -1
          );
        }
        return data.openPoint.assignee?.userId === currentUser.id;
      });

      const computedCurrentUserAssignee = computed(() => {
        return data.openPoint.assignee;
      });
      const computedCurrentUserCreator = computed(() => {
        return data.openPoint.creator;
      });

      const computedAttachments = computed(() => {
        return (attachments: OpenPointAttachment[]) => {
          return attachments.map((attachment: OpenPointAttachment) => {
            return computedFileUrl.value(attachment.fileName);
          });
        };
      });

      function handleAddAttachmentClick() {
        attachmentInput.value?.click();
      }

      function handleAddModalAttachmentClick() {
        modalAttachmentInput.value?.click();
      }

      function handleAttachmentChange(event) {
        const file: File = event.target.files[0];

        if (!file) {
          return false;
        }

        store.dispatch(Actions.START_LOADER);

        const formData = new FormData();
        formData.append('attachment', file);

        axios
          .post('opl/attachment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (typeof data.currentMessage.attachments === 'undefined') {
              data.currentMessage.attachments = [];
            }
            data.currentMessage.attachments.push({
              id: response.data.id,
              fileName: response.data.fileName,
              type: OpenPointAttachmentType.IMAGE,
            });
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      function handleModalAttachmentChange(event) {
        const file: File = event.target.files[0];

        if (!file) {
          return false;
        }

        store.dispatch(Actions.START_LOADER);

        const formData = new FormData();
        formData.append('attachment', file);

        axios
          .post('opl/attachment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (
              typeof data.currentCompleteOptions.attachments === 'undefined'
            ) {
              data.currentCompleteOptions.attachments = [];
            }
            data.currentCompleteOptions.attachments.push({
              id: response.data.id,
              fileName: response.data.fileName,
              type: OpenPointAttachmentType.IMAGE,
            });
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      function onOpenPointUpdate(openPoint: OpenPoint) {
        data.openPoint = openPoint;
        data.currentMessage.openPoint = data.openPoint;
      }

      async function handleChangeAssignee() {
        hideModal(changeAssigneeModal.value);
        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/assign', {
          data: {
            id: data.openPoint.id,
            initialAssignees: data.currentAssignees,
            comment: data.currentAssigneeComment,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      async function handlePostpone() {
        hideModal(postponeModal.value);
        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/postpone', {
          data: {
            id: data.openPoint.id,
            dueDate: data.currentDueDateOptions.dueDate,
            reason: data.currentDueDateOptions.reason,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      async function handleDecline() {
        hideModal(declineModal.value);

        if (
          data.currentDeclineType === DeclineChangeType.DECISION &&
          data.currentDeclineMessage !== null
        ) {
          return handleOpenPointChangeDecline(data.currentDeclineMessage);
        }

        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/decline', {
          data: {
            id: data.openPoint.id,
            reason: data.currentDeclineReason,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      async function handleComplete() {
        hideModal(completeModal.value);
        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/complete', {
          data: {
            id: data.openPoint.id,
            attachments: data.currentCompleteOptions.attachments,
            reason: data.currentCompleteOptions.reason,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      function handleOpenPointChangeDeclineClick(message: OpenPointMessage) {
        data.currentDeclineReason = '';
        data.currentDeclineType = DeclineChangeType.DECISION;
        data.currentDeclineMessage = message;
      }

      async function handleOpenPointChangeAccept(
        openPointMessage: OpenPointMessage,
      ) {
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;

        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/message/decide', {
          data: {
            id: openPointMessage.id,
            state: OpenPointMessageState.ACCEPTED,
            reason: data.currentDeclineReason,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      async function handleOpenPointChangeDecline(
        openPointMessage: OpenPointMessage,
      ) {
        await store.dispatch(Actions.START_LOADER);
        const updatedOpenPoint = await ApiService.post('opl/message/decide', {
          data: {
            id: openPointMessage.id,
            state: OpenPointMessageState.DECLINED,
            reason: data.currentDeclineReason,
          },
        });
        onOpenPointUpdate(updatedOpenPoint.data);
        await store.dispatch(Actions.END_LOADER);
      }

      const computedShowAssignee = computed(() => {
        if (data.openPoint === null) return false;
        return data.openPoint.assignee !== null;
      });

      const computedShowInitialAssignees = computed(() => {
        return (
          data.openPoint.assignee === null &&
          data.openPoint.state !== OpenPointState.CLOSED &&
          data.openPoint.initialAssignees.length > 0
        );
      });

      const computedAutoSetProgressAvailable = computed(() => {
        return (
          currentUser.id === data.openPoint.assignee?.userId &&
          currentUser.id !== data.openPoint.creator?.userId &&
          data.openPoint.state === OpenPointState.OPEN
        );
      });

      function handleRemoveAttachment(index: number) {
        if (typeof data.currentMessage.attachments[index] === 'undefined') {
          return false;
        }

        store.dispatch(Actions.START_LOADER);

        const deleteString = `opl/attachment/${data.currentMessage.attachments[index].id}`;

        ApiService.delete(deleteString)
          .then(() => {
            data.currentMessage.attachments.splice(index, 1);
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      function handleRemoveModalAttachment(index: number) {
        if (
          typeof data.currentCompleteOptions.attachments[index] === 'undefined'
        ) {
          return false;
        }

        const attachmentToDelete: OpenPointAttachment =
          data.currentCompleteOptions.attachments[index];

        store.dispatch(Actions.START_LOADER);

        const deleteString = `opl/attachment/${attachmentToDelete.id}`;

        ApiService.delete(deleteString)
          .then(() => {
            data.currentCompleteOptions.attachments.splice(index, 1);
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedMessageRequiredForComplete = computed(() => {
        if (data.openPoint === null) return false;
        if (data.openPoint.messages.length === 0) return false;

        let lastIndexWithChange = 0;

        data.openPoint.messages.forEach(
          (message: OpenPointMessage, index: number) => {
            if (message.changes.length !== 0) {
              lastIndexWithChange = index;
            }
          },
        );

        const messagesAfterLastChange = data.openPoint.messages.slice(
          lastIndexWithChange + 1,
        );
        return !messagesAfterLastChange.some((message: OpenPointMessage) => {
          return message.text !== null && message.text !== '';
        });
      });

      const computedAttachmentsRequiredForComplete = computed(() => {
        if (data.openPoint === null) return false;
        if (data.openPoint.messages.length === 0) return false;

        let lastIndexWithChange = 0;

        data.openPoint.messages.forEach(
          (message: OpenPointMessage, index: number) => {
            if (message.changes.length !== 0) {
              lastIndexWithChange = index;
            }
          },
        );

        const messagesAfterLastChange = data.openPoint.messages.slice(
          lastIndexWithChange + 1,
        );
        return !messagesAfterLastChange.some((message: OpenPointMessage) => {
          return message.attachments.length > 0;
        });
      });

      const computedCardClass = computed(() => {
        const classes = ['card'];

        if (computedIsCurrentUserAssignee.value === true) {
          classes.push('border border-primary border-2');
        }

        return classes.join(' ');
      });

      const computedHasMessageWithOpenDecision = computed(() => {
        for (const message of data.openPoint.messages) {
          if (
            computedIsMessageWithDecision.value(message) === true &&
            message.decision === null
          ) {
            return true;
          }
        }
        return false;
      });

      const computedIsMessageWithDecision = computed(() => {
        return (message: OpenPointMessage) => message.needsDecision;
      });

      const computedInitialAssigneeClass = computed(() => {
        return (initialAssignee: OpenPointInitialAssignee) => {
          if (initialAssignee.userId === currentUser.id)
            return 'badge badge-primary me-2';
          return `badge badge-secondary me-2`;
        };
      });

      function calculateChangeTypeInMessage(
        message: OpenPointMessage,
      ): MessageChangeType | null {
        if (message.changes.length) {
          for (const change of message.changes) {
            if (change.field === 'dueDate') return MessageChangeType.DUE_DATE;
            if (
              change.field === 'state' &&
              change.toValue === OpenPointState.DONE
            )
              return MessageChangeType.COMPLETED;
          }
        }

        return null;
      }

      const computedStateChangeType = computed(() => {
        return (message: OpenPointMessage) => {
          const type = calculateChangeTypeInMessage(message);
          return t(`opl.stateChanges.${type}`);
        };
      });

      const computedHasVisibleParent = computed(() => {
        if (data.openPoint.parent !== null) {
          if (
            computedCurrentUserAssignee.value?.userId === currentUser.id ||
            computedCurrentUserCreator.value?.userId === currentUser.id
          ) {
            return true;
          }
        }
        return false;
      });

      const computedVisibleChildren = computed(() => {
        if (data.openPoint.children.length === 0) return [];
        return data.openPoint.children.filter((child: OpenPoint) => {
          return (
            child.assignee?.userId === currentUser.id ||
            child.creator?.userId === currentUser.id
          );
        });
      });

      const handleSubOpenPointCreation = (children: OpenPointFormData[]) => {
        // await loadOpenPoint(data.openPoint.id);
        // const newChildren = children.slice(0);
        // data.openPoint.children = [] as OpenPoint[];
        children.forEach((newChild: OpenPointFormData) => {
          data.openPoint.children.push(Object.assign({}, newChild));
        });
        // data.openPoint.children = data.openPoint.children.concat(
        //   children.slice(0),
        // );
        return;
      };

      const computedFileNameFromUrl = computed(() => {
        return (fileUrl) => {
          return fileUrl.split('/').pop();
        };
      });

      const computedHasInitialMessageData = computed(() => {
        // return (
        //   !data.openPoint.isUrgent &&

        // )
        if (data.openPoint.comment !== null && data.openPoint.comment !== '') {
          return true;
        }
        if (data.openPoint.dueDate !== null) {
          return true;
        }
        return data.openPoint.attachments.length > 0;
      });

      const route = useRoute();

      onMounted(async () => {
        await store.dispatch(Actions.START_LOADER);

        const fesConfig: FESConfig = (await ApiService.get('fes/config')).data;
        data.errorCaseCdfNames = fesConfig.hierarchyLevels.slice(0);

        let openPointId = route.params.id;
        if (props.openPointId) {
          openPointId = String(props.openPointId);
        }

        await loadOpenPoint(openPointId);

        data.currentMessage.openPoint = data.openPoint;
        data.currentMessage.author = {
          userId: currentUser.id,
          name: currentUser.name,
          email: currentUser.mail,
        } as OpenPointMessageAuthor;

        await store.dispatch(Actions.END_LOADER);
      });

      return {
        data,
        computedBackLink,
        computedShowBackLink,
        computedTime,
        computedDate,
        computedAgo,
        computedIsLoaded,
        computedFileUrl,
        computedFileEnding,
        computedAttachments,
        computedInitClass,
        computedMessageClass,
        messageValidator,
        assignValidator,
        postponeValidator,
        declineValidator,
        completeValidator,
        computedIsCurrentUserAssignee,
        computedCurrentUserAssignee,
        computedIsCurrentUserCreator,
        computedCurrentUserCreator,
        sendMessage,
        cancelMessage,
        handleAddAttachmentClick,
        handleAddModalAttachmentClick,
        handleAttachmentChange,
        attachmentInput,
        modalAttachmentInput,
        handleChangeAssignee,
        handlePostpone,
        handleDecline,
        handleComplete,
        computedIsAbleToChangeAssignee,
        computedIsAbleToChangeWatchers,
        computedIsAbleToAddMessage,
        computedIsAbleToPostpone,
        computedIsAbleToComplete,
        computedIsAbleToDecline,
        changeAssigneeModal,
        postponeModal,
        declineModal,
        completeModal,
        handleChangeAssigneeClick,
        handlePostponeClick,
        handleDeclineClick,
        handleCompleteClick,
        handleOpenPointChangeDeclineClick,
        computedShowAssignee,
        currentUser,
        computedHasChangeActions,
        computedAutoSetProgressAvailable,
        computedFileNameFromUrl,
        handleRemoveAttachment,
        handleRemoveModalAttachment,
        handleModalAttachmentChange,
        computedMessageRequiredForComplete,
        computedAttachmentsRequiredForComplete,
        computedCardClass,
        computedIsMessageWithDecision,
        computedHasMessageWithOpenDecision,
        handleOpenPointChangeAccept,
        computedStateChangeType,
        computedHasVisibleParent,
        computedVisibleChildren,
        handleSubOpenPointCreation,
        computedHasInitialMessageData,
        computedIsAllowedToCreateSubOpenPoint,
        computedShowInitialAssignees,
        computedInitialAssigneeClass,
        computedMessageText,
        messageForm,
        computedCurrentLanguage,
        addNewComment,
      };
    },
  });
</script>

<style lang="css" scoped>
  :deep(.popper) {
    background: white;
    padding: 20px;
    border-radius: 10px;
    color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  :deep(.popper #arrow::before) {
    background: white;
  }

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background: white;
  }

  .add-opl-btn {
    padding: 4px 10px;
    font-size: 11px;
  }
</style>
