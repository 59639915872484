<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div :class="computedHeaderClasses">
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
      >
        <slot name="filters"></slot>
      </div>
      <div
        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
      >
        <el-pagination
          v-model:current-page="pagination.page"
          @current-change="currentPageChange"
          :page-size="pagination.rowsPerPage"
          layout="prev, pager, next"
          :total="pagination.total"
          :hide-on-single-page="true"
          background
        >
        </el-pagination>
        <div
          v-if="enableItemsPerPageDropdown"
          class="dataTables_length"
          id="kt_customers_table_length"
        >
          <label
            ><select
              name="kt_customers_table_length"
              class="form-select form-select-sm form-select-solid"
              @change="setItemsPerPage"
              v-model="pagination.rowsPerPage"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select></label
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table
        :class="[loading && 'overlay overlay-block']"
        class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover table-responsive"
        id="kt_customers_table"
        role="grid"
      >
        <!--begin::Table head-->
        <thead :class="computedTableClasses">
          <!--begin::Table row-->
          <tr
            class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 align-middle"
            role="row"
          >
            <template v-for="(cell, i) in tableHeader" :key="i">
              <th
                @click="
                  sort(
                    cell.sortingField ? cell.sortingField : cell.key,
                    cell.sortable,
                  )
                "
                :class="[
                  cell.name && 'min-w-125px',
                  cell.sortable !== false && 'sorting',
                  tableHeader.length - 1 === i && 'text-end',
                  currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}desc` &&
                    'sorting_asc',
                  currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}asc` &&
                    'sorting_desc',
                ]"
                tabindex="0"
                rowspan="1"
                colspan="1"
                style="cursor: pointer"
              >
                {{ cell.name }}
              </th>
            </template>
          </tr>
          <tr
            v-if="computedTableFilterConfig.length"
            class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 align-middle"
            role="row"
          >
            <template v-for="(cell, i) in tableHeader" :key="i">
              <th rowspan="1" colspan="1">
                <div v-if="i > 0 && isKeyInFilterConfig(cell.key)">
                  <div v-if="getSingleFilter(cell.key).type === 'select'">
                    <el-select
                      v-model="tableFilters[cell.key]"
                      multiple
                      :placeholder="cell.name"
                      size="small"
                      @change="handleFilterUpdate"
                    >
                      <el-option
                        v-for="(item, index) in getSingleFilter(cell.key)
                          .values"
                        :key="index"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </div>
                  <div v-if="getSingleFilter(cell.key).type === 'text'">
                    <el-input
                      v-model="tableFilters[cell.key]"
                      :placeholder="cell.name"
                      size="small"
                      @change="handleFilterUpdate"
                    />
                  </div>
                </div>
                <el-button
                  size="small"
                  v-if="i === 0 && computedHasFilters"
                  :title="$t('resetFilters')"
                  @click="resetFilters"
                  round
                >
                  <span class="fa fa-filter line-through"></span>
                </el-button>
              </th>
            </template>
          </tr>
          <!--end::Table row-->
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody class="fw-bold text-gray-600">
          <template v-if="getItems.length">
            <template v-for="(item, i) in getItems" :key="i">
              <tr
                class="odd"
                @click="
                  (e) => {
                    emitClickEvent(item, e as PointerEvent);
                  }
                "
                v-on:click.middle="
                  (e) => {
                    emitClickEventMiddle(item, e as PointerEvent);
                  }
                "
              >
                <template v-for="(cell, i) in tableHeader" :key="i">
                  <td :class="{ 'text-end': tableHeader.length - 1 === i }">
                    <slot :name="`cell-${cell.key}`" :row="item">
                      {{ item[prop] }}
                    </slot>
                  </td>
                </template>
                <!--end::Item=-->
              </tr>
            </template>
          </template>
          <template v-else>
            <tr class="odd">
              <td :colspan="tableHeader.length" class="dataTables_empty">
                {{ $t('noEntries') }}
              </td>
            </tr>
          </template>
        </tbody>
        <div
          v-if="loading"
          class="overlay-layer card-rounded bg-dark bg-opacity-5"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!--end::Table body-->
      </table>
    </div>
    <div class="row">
      <div
        class="col-12 d-flex align-items-center justify-content-center justify-content-md-end"
      >
        <el-pagination
          v-model:current-page="pagination.page"
          @current-change="currentPageChange"
          :page-size="pagination.rowsPerPage"
          layout="prev, pager, next"
          :total="pagination.total"
          :hide-on-single-page="true"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    onMounted,
    ref,
    watch,
  } from 'vue';
  import arraySort from 'array-sort';
  import { useRoute } from 'vue-router';

  interface IPagination {
    page: number;
    total: number;
    rowsPerPage: number;
  }

  export interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string | undefined;
    sortable?: boolean;
  }

  export type TableFilterItem = {
    filterKey: string;
    filterValue: (item: any) => any;
    type: string;
    values?: any[];
  };

  export default defineComponent({
    name: 'kt-datatable',
    emit: ['current-change', 'sort', 'items-per-page-change'],
    props: {
      tableHeader: {
        type: Array as () => IHeaderConfiguration[],
        required: true,
      },
      tableData: { type: Array, required: true },
      loading: { type: Boolean, default: false },
      currentPage: { type: Number, default: 1 },
      enableItemsPerPageDropdown: { type: Boolean, default: true },
      total: { type: Number, default: 0 },
      rowsPerPage: { type: Number, default: 10 },
      order: { type: String, default: 'asc' },
      sortLabel: { type: String, default: '' },
      stickyHeader: { type: Boolean, default: true },
      tableFilterConfig: {
        type: Array as () => TableFilterItem[],
        default: () => [],
      },
      ignoreLocalStorage: { type: Boolean, default: false },
    },
    components: {},
    setup(props, { emit }) {
      const data = ref(props.tableData);
      const currentSort = ref<string>('');
      const order = ref(props.order);
      const label = ref(props.sortLabel);
      const pagination = ref<IPagination>({
        page: 1,
        total: props.total,
        rowsPerPage: props.rowsPerPage,
      });

      const currentRoute = useRoute();
      const currentRouteName = String(currentRoute.name);
      const tableConfigIdentifierPage = `tblCfgPage_${currentRouteName}`;
      const tableConfigIdentifierSort = `tblCfgSort_${currentRouteName}`;

      const vnodeProps = getCurrentInstance()?.vnode.props || {};

      const tableFilters = ref({});

      const headersReady = ref(false);
      props.tableHeader.forEach((header) => {
        tableFilters[
          typeof header.sortingField !== 'undefined'
            ? header.sortingField
            : header.key
        ] = null;
      });
      headersReady.value = true;

      const getLocalStorageItem = (key: string): string | null =>
        (!props.ignoreLocalStorage && localStorage.getItem(key)) || null;

      const setLocalStorageItem = (key: string, value: string) => {
        if (props.ignoreLocalStorage) {
          return;
        }
        localStorage.setItem(key, value);
      };

      const readPageFromLocalStorage = (): number => {
        if (typeof localStorage !== 'undefined') {
          const page = getLocalStorageItem(tableConfigIdentifierPage) || '1';
          return parseInt(page);
        }
        return 1;
      };

      const setPageInLocalStorage = (page: number): void => {
        if (typeof localStorage !== 'undefined') {
          setLocalStorageItem(tableConfigIdentifierPage, String(page));
        }
      };

      watch(
        () => props.tableData,
        (newData) => {
          data.value = newData;
        },
      );

      watch(
        data.value,
        () => {
          if ('onCurrentChange' in vnodeProps) {
            currentSort.value = label.value + order.value;
          } else {
            pagination.value.total = data.value.length;
          }
        },
        {
          deep: true,
        },
      );

      onMounted(() => {
        // currentSort.value = label.value + order.value;
        pagination.value.total = props.total ? props.total : data.value.length;
        pagination.value.rowsPerPage = props.rowsPerPage;

        const rowsFromStorage = getLocalStorageItem('cdfRowsPerPage');
        if (rowsFromStorage !== null) {
          const rowsFromStorageInt = parseInt(rowsFromStorage);

          if (rowsFromStorageInt > 0) {
            pagination.value.rowsPerPage = rowsFromStorageInt;
          }
        }

        const tableConfigAtRoutePage = readPageFromLocalStorage();
        pagination.value.page = tableConfigAtRoutePage;
        setPageInLocalStorage(tableConfigAtRoutePage);

        const tableConfigAtRouteSort = readSortFromLocalStorage();

        const tableConfigSplitted = tableConfigAtRouteSort.split('_');

        const tableFiltersFromStorage = getLocalStorageItem(
          computedPagePathName.value,
        );
        if (tableFiltersFromStorage !== null) {
          tableFilters.value = JSON.parse(tableFiltersFromStorage);
        }

        if (tableConfigSplitted.length === 2) {
          currentSort.value = tableConfigSplitted.join('');
          order.value = tableConfigSplitted[1];
          sort(tableConfigSplitted[0], true);
          setSortInLocalStorage(tableConfigSplitted[0], tableConfigSplitted[1]);
        }
      });

      const computedItems = computed(() => {
        return computedFilteredData.value(data.value);
      });

      const getItems = computed(() => {
        if ('onCurrentChange' in vnodeProps) {
          return computedItems.value;
        } else {
          const clone = JSON.parse(JSON.stringify(computedItems.value));
          const startFrom =
            pagination.value.page * pagination.value.rowsPerPage -
            pagination.value.rowsPerPage;
          return clone.splice(startFrom, pagination.value.rowsPerPage);
        }
      });

      const currentPageChange = (val) => {
        if ('onCurrentChange' in vnodeProps) {
          emit('current-change', val);
        } else {
          pagination.value.page = val;
          setPageInLocalStorage(val);
        }
      };

      const sort = (columnName, sortable) => {
        if (sortable === false) {
          return;
        }

        if ('onSort' in vnodeProps) {
          if (order.value === 'asc') {
            order.value = 'desc';
            emit('sort', { columnName: columnName, order: 'desc' });
          } else {
            order.value = 'asc';
            emit('sort', { columnName: columnName, order: 'asc' });
          }
        } else {
          if (order.value === 'asc') {
            order.value = 'desc';
            arraySort(data.value, columnName, { reverse: false });
          } else {
            order.value = 'asc';
            arraySort(data.value, columnName, { reverse: true });
          }
        }
        currentSort.value = columnName + order.value;
        setSortInLocalStorage(
          columnName,
          order.value === 'asc' ? 'desc' : 'asc',
        );
      };

      const readSortFromLocalStorage = (): string => {
        if (typeof localStorage !== 'undefined') {
          return getLocalStorageItem(tableConfigIdentifierSort) || '';
        }
        return '';
      };

      const setSortInLocalStorage = (col: string, val: string) => {
        if (typeof localStorage !== 'undefined') {
          setLocalStorageItem(tableConfigIdentifierSort, [col, val].join('_'));
        }
      };

      const setItemsPerPage = (event) => {
        if ('onItemsPerPageChange' in vnodeProps) {
          emit('items-per-page-change', parseInt(event.target.value));
        } else {
          pagination.value.rowsPerPage = parseInt(event.target.value);
        }
        setLocalStorageItem('cdfRowsPerPage', String(event.target.value));
      };

      const emitClickEvent = (item, e: PointerEvent) => {
        const lastElement = e.target as HTMLElement;

        if (
          lastElement !== null &&
          typeof lastElement.tagName !== 'undefined' &&
          (lastElement.tagName === 'INPUT' ||
            lastElement.attributes.getNamedItem('clickable') !== null)
        ) {
          return false;
        }
        emit('click:row', item);
      };
      const emitClickEventMiddle = (item, e: PointerEvent) => {
        const lastElement = e.target as HTMLElement;

        if (
          lastElement !== null &&
          typeof lastElement.tagName !== 'undefined' &&
          (lastElement.tagName === 'INPUT' ||
            lastElement.attributes.getNamedItem('clickable') !== null)
        ) {
          return false;
        }
        emit('click-middle:row', item);
      };

      const computedTableClasses = computed(() => {
        const classes: string[] = [];
        if (props.stickyHeader) {
          classes.push('sticky-header');
        }
        return classes.join(' ');
      });

      const computedHeaderClasses = computed(() => {
        const classes: string[] = ['row'];
        if (props.stickyHeader) {
          classes.push('row-with-sticky-header');
        }
        return classes.join(' ');
      });

      const computedHasFilters = computed(() => {
        const filterFields = Object.keys(tableFilters.value);
        return filterFields.some((field) => {
          return isNotEmpty(tableFilters.value[field]);
        });
      });

      const computedTableFilterConfig = computed((): TableFilterItem[] => {
        return props.tableFilterConfig;
      });

      const isKeyInFilterConfig = (key: string) => {
        return computedTableFilterConfig.value.some(
          (filter: any) => filter.filterKey === key,
        );
      };

      const getSingleFilter = (key: string) => {
        return computedTableFilterConfig.value.find(
          (filter: any) => filter.filterKey === key,
        );
      };

      const isNotEmpty = (data: any) => {
        if (typeof data === 'object') {
          return data.length > 0;
        }

        if (typeof data === 'string') {
          return data !== '';
        }

        return !data;
      };

      const updatePagination = () => {
        pagination.value.total = computedItems.value.length;
        // currentPageChange(1);
      };

      const computedFilteredData = computed(() => {
        return (allItems) => {
          const filterFields = Object.keys(tableFilters.value);

          const filledFilters = filterFields.filter((filterField) => {
            if (typeof tableFilters.value[filterField] === 'object') {
              return tableFilters.value[filterField].length > 0;
            }
            return tableFilters.value[filterField] !== '';
          });

          if (filledFilters.length > 0) {
            const selectedFiltersConfig =
              computedTableFilterConfig.value.filter((tableFilterItem) => {
                return (
                  isNotEmpty(tableFilters.value[tableFilterItem.filterKey]) &&
                  filterFields.includes(tableFilterItem.filterKey)
                );
              });

            if (selectedFiltersConfig.length) {
              const filterResult = allItems.filter((item) => {
                let isItemValid = true;
                for (const selectedFilter of selectedFiltersConfig) {
                  const valueToCheck = selectedFilter.filterValue(item);

                  if (selectedFilter.type === 'select') {
                    const selectedFilterItems =
                      tableFilters.value[selectedFilter.filterKey];
                    if (!selectedFilterItems.includes(valueToCheck)) {
                      isItemValid = false;
                    }
                  }

                  if (selectedFilter.type === 'text') {
                    const isValid = String(valueToCheck)
                      .toLowerCase()
                      .includes(
                        tableFilters.value[
                          selectedFilter.filterKey
                        ].toLowerCase(),
                      );
                    if (!isValid) {
                      isItemValid = false;
                    }
                  }
                }

                return isItemValid;
              });
              return filterResult;
            }
          }

          return allItems;
        };
      });

      watch(() => getItems.value, updatePagination, { deep: true });

      const computedPagePathName = computed(() => {
        return `tableFilters/${currentRouteName}`;
      });

      const updateLatestFilters = () => {
        setLocalStorageItem(
          computedPagePathName.value,
          JSON.stringify(tableFilters.value),
        );
      };

      const resetFilters = () => {
        const filterFields = Object.keys(tableFilters.value);
        filterFields.forEach((filterField) => {
          tableFilters.value[filterField] =
            typeof tableFilters.value[filterField] === 'object' ? [] : '';
        });
        updateLatestFilters();
      };

      const handleFilterUpdate = () => {
        updateLatestFilters();
      };

      return {
        data,
        pagination,
        currentPageChange,
        getItems,
        sort,
        currentSort,
        setItemsPerPage,
        emitClickEvent,
        emitClickEventMiddle,
        computedTableClasses,
        computedHeaderClasses,
        tableFilters,
        computedFilteredData,
        computedItems,
        computedTableFilterConfig,
        isKeyInFilterConfig,
        getSingleFilter,
        computedHasFilters,
        resetFilters,
        handleFilterUpdate,
      };
    },
  });
</script>

<style lang="scss">
  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
  }

  table.dataTable > thead {
    th.sorting {
      position: relative;
    }

    .sorting:after {
      position: absolute;
    }
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background: none;
    border-radius: 0.475rem;
    font-weight: 500;
    font-size: 1.075rem;
    font-family: Poppins, Helvetica, sans-serif;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #009ef7;
  }

  table.dataTable td.dataTables_empty,
  table.dataTable th.dataTables_empty {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .line-through {
    text-decoration: line-through;
  }
</style>
