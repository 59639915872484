<template>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header">
      <h2 class="card-title fw-bolder">{{ $t('spc.auditPlan.calendar') }}</h2>

      <div class="card-toolbar">
        <router-link
          to="/spc/audit/create"
          class="btn btn-sm btn-primary align-self-center ms-md-3 order-md-2 order-1 mb-3 mb-md-0"
          ><i class="fa fa-plus-square"></i>
          {{ $t('spc.processconfirmation.newAudit') }}</router-link
        >
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body">
      <!--begin::Calendar-->
      <FullCalendar
        class="demo-app-calendar"
        :options="calendarOptions"
      ></FullCalendar>
      <!--end::Calendar-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import FullCalendar, {
    CalendarOptions,
    LocaleInput,
  } from '@fullcalendar/vue3';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import listPlugin from '@fullcalendar/list';
  import interactionPlugin from '@fullcalendar/interaction';
  // import events from '@/core/data/events';
  // import { TODAY } from '@/core/data/events';
  import moment, { Moment } from 'moment';
  import { AuditPlan, AuditPlanInterval } from '@/core/models/spc';
  import ApiService from '@/core/services/ApiService';
  import deLocale from '@fullcalendar/core/locales/de';
  import enLocale from '@fullcalendar/core/locales/en-gb';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'calendarAuditPlans',
    components: {
      FullCalendar,
    },
    setup() {
      const newEvent = () => {
        // const modal = new Modal(document.getElementById('kt_modal_add_event'));
        // modal.show();
      };

      const { t } = useI18n();
      // const currentLanguage = { ...store.getters.currentLanguageString };

      // function getRangeFromAuditPlan(startDate: Moment, endDate: Moment, type) {
      //   let fromDate = startDate.clone();
      //   let toDate = endDate.clone();
      //   let diff = toDate.diff(fromDate, type);
      //   let range: Moment[] = [];
      //   for (let i = 0; i < diff; i++) {
      //     range.push(fromDate.add(i, type));
      //   }
      //   return range;
      // }
      function getRangeFromAuditPlan(startDate, endDate, type) {
        const fromDate = moment(startDate);
        const toDate = moment(endDate);
        const diff = toDate.diff(fromDate, type);
        const range: Moment[] = [];
        for (let i = 0; i <= diff; i++) {
          range.push(moment(startDate).add(i, type));
        }
        return range;
      }

      function getIntervalFromAuditPlan(auditPlan: AuditPlan) {
        switch (auditPlan.interval) {
          case AuditPlanInterval.DAILY:
            return getRangeFromAuditPlan(
              auditPlan.startDate,
              auditPlan.endDate
                ? auditPlan.endDate
                : moment().add(1, 'year').toISOString(),
              'days',
            );
          case AuditPlanInterval.WEEKLY:
            return getRangeFromAuditPlan(
              auditPlan.startDate,
              auditPlan.endDate
                ? auditPlan.endDate
                : moment().add(1, 'year').toISOString(),
              'weeks',
            );
          case AuditPlanInterval.MONTHLY:
            return getRangeFromAuditPlan(
              auditPlan.startDate,
              auditPlan.endDate
                ? auditPlan.endDate
                : moment().add(1, 'year').toISOString(),
              'months',
            );
        }
      }

      const computedEvents = computed(() => {
        const allEvents: any[] = [];

        data.loadedAuditPlans.forEach((auditPlan: AuditPlan) => {
          const interval = getIntervalFromAuditPlan(auditPlan);

          if (interval) {
            interval.forEach((date: Moment) => {
              const eventToAdd = {
                url: `/#/spc/auditplan/${auditPlan.id}`,
                title: `${auditPlan.amount}x ${auditPlan.name}`,
                start: date.toISOString(),
                allDay: true,
              };
              allEvents.push(eventToAdd);
            });
          }

          if (auditPlan.endDate === null) {
            // const ranges = getRangeFromAuditPlan(
            //   auditPlan.startDate,
            //   moment().add(1, 'year').toISOString(),
            // );
          }
        });

        return allEvents;

        // return [
        //   {
        //     title: '11 Geplante Audits',
        //     start: moment('01.08.2022', 'DD.MM.YYYY').toISOString(),
        //     allDay: true,
        //   },
        //   {
        //     title: '4 Geplante Audits',
        //     start: moment('28.07.2022', 'DD.MM.YYYY').toISOString(),
        //     allDay: true,
        //   },
        // ];
      });

      const computedCurrentLocale = computed((): LocaleInput => {
        const loadedLanguage = localStorage.getItem('lang');
        if (loadedLanguage === 'de') {
          return deLocale;
        } else {
          return enLocale;
        }
      });

      const data: {
        loadedAuditPlans: AuditPlan[];
      } = reactive({
        loadedAuditPlans: [] as AuditPlan[],
      });

      const calendarOptions: CalendarOptions = reactive({
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek',
        },
        locale: computedCurrentLocale,
        initialDate: moment().toISOString(),
        initialView: 'dayGridMonth',
        navLinks: true, // can click day/week names to navigate views
        selectable: true,
        selectMirror: true,
        validRange: {
          start: moment().toISOString(),
          end: moment().add(1, 'year').toISOString(),
        },
        views: {
          dayGridMonth: { buttonText: t('spc.auditPlan.month') },
          timeGridWeek: { buttonText: t('spc.auditPlan.week') },
        },

        editable: false,
        dayMaxEvents: true, // allow "more" link when too many events
        events: computedEvents,
        dateClick: newEvent,
        eventClick: newEvent,
      });

      // const calendarOptions = computed(() => {
      //   const allEvents: any[] = [];

      //   data.loadedAuditPlans.forEach((auditPlan: AuditPlan) => {
      //     const eventToAdd = {
      //       title: `${auditPlan.amount}x ${auditPlan.name}`,
      //       start: moment(auditPlan.startDate).toISOString(),
      //       allDay: true,
      //     };

      //     allEvents.push(eventToAdd);
      //   });

      //   return;
      // });

      onMounted(async () => {
        const loadedAuditPlansResponse =
          await ApiService.get(`spc/audit/plans`);
        data.loadedAuditPlans = loadedAuditPlansResponse.data as AuditPlan[];
      });

      return {
        data,
        newEvent,
        calendarOptions,
      };
    },
  });
</script>

<style lang="scss">
  .fc .fc-button {
    padding: 0.75rem 1.25rem;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0.475rem;
    vertical-align: middle;
    font-weight: 500;
    text-transform: capitalize;
  }
</style>
