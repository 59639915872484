import { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/Layout.vue';

import UserOverview from '@/views/cdf/users/CdfUsersOverview.vue';
import UserEdit from '@/views/cdf/users/CdfUsersEdit.vue';

import OrgChartConfig from '@/views/cdf/org-chart/CdfOrgChartView.vue';

export default {
  path: '/cdf',
  redirect: '/cdf/org-chart',
  component: DefaultLayout,
  meta: {
    app: 'CDF',
    requiredGroup: 'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
  },
  children: [
    {
      path: '/cdf/users/overview',
      name: 'usersOverview',
      component: UserOverview,
    },
    {
      path: '/cdf/users/edit/:id?',
      name: 'userEdit',
      component: UserEdit,
    },
    {
      path: '/cdf/org-chart',
      name: 'cdfOrgChartView',
      component: OrgChartConfig,
    },
  ],
} as RouteRecordRaw;
