import { FES_DECIDER_ROLES } from '../services/ApiService';

export const SPC_ALLOWED_HOSTS = ['localhost', 'cdf.dev.jointest.de'];

const AppMenuConfig = [
  {
    pages: [
      {
        heading: 'tabs.fes',
        route: '/fes',
        allowedRoles: [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_USER_local',
          ...FES_DECIDER_ROLES,
        ],
      },
      {
        heading: 'tabs.opl',
        route: '/opl',
        allowedRoles: [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_MEASURE_local',
        ],
      },
      {
        heading: 'tabs.spc',
        route: '/spc',
        allowedRoles: [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_USER_local',
        ],
        allowedHosts: SPC_ALLOWED_HOSTS,
      },
      {
        heading: 'tabs.cdf',
        route: '/cdf',
        allowedRoles: ['Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local'],
      },
    ],
  },
];

export default AppMenuConfig;
