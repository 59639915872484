<template>
  <Editor
    ref="editor"
    v-model="content"
    @input="onEditorInput"
    tinymceScriptSrc="/tinymce/tinymce.min.js"
    :init="editorInit"
    toolbar="undo redo | bold italic underline forecolor backcolor | outdent indent | bullist numlist | removeformat"
    plugins="link autolink lists wordcount"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import Editor from '@tinymce/tinymce-vue';

  export default defineComponent({
    name: 'TinyMceEditor',
    components: {
      Editor,
    },
    data: () => ({
      content: '',
    }),
    props: {
      modelValue: {
        type: String as PropType<string>,
        default: '',
      },
      language: {
        type: String,
        required: true,
      },
    },
    computed: {
      editorInit() {
        return {
          language: this.language,
          promotion: false,
          branding: false,
          width: '100%',
          base_url: '/tinymce',
          license_key: 'gpl',
          relative_urls: false,
          remove_script_host: false,
          setup: (editor: any) => {
            editor.on('Change KeyUp Undo Redo', () => {
              this.$emit('update:modelValue', editor.getContent());
            });
            editor.on('KeyDown', (ev) => {
              if (ev.key !== 'Tab') {
                return true;
              }
              // Allow tab-indentation
              if (ev.shiftKey) {
                editor.execCommand('Outdent');
              } else {
                editor.execCommand('Indent');
              }
              ev.preventDefault();
              return false;
            });
          },
        };
      },
    },
    methods: {
      onEditorInput(value: string) {
        this.$emit('update:modelValue', value);
      },
      reloadEditor() {
        this.$refs.editor?.rerender(this.editorInit);
      },
    },
    watch: {
      language(newLang, oldLang) {
        if (newLang === oldLang) {
          return;
        }
        this.reloadEditor();
      },
      modelValue(value) {
        this.content = value;
      },
    },
  });
</script>
