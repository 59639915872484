import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createBlock(_component_Editor, {
    ref: "editor",
    modelValue: _ctx.content,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
    onInput: _ctx.onEditorInput,
    tinymceScriptSrc: "/tinymce/tinymce.min.js",
    init: _ctx.editorInit,
    toolbar: "undo redo | bold italic underline forecolor backcolor | outdent indent | bullist numlist | removeformat",
    plugins: "link autolink lists wordcount"
  }, null, 8, ["modelValue", "onInput", "init"]))
}