const UserMenuConfig = [
  {
    pages: [
      {
        heading: 'user.settings',
        route: '/user/settings',
        svgIcon: 'media/icons/duotune/coding/cod001.svg',
        fontIcon: 'bi-sticky',
      },
    ],
  },
];

export default UserMenuConfig;
