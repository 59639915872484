<template>
  <div class="row cdf-config-container">
    <div class="col">
      <div class="card">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-center justify-content-start">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t('viewOrgChart')
            }}</span>
          </h3>
        </div>
        <div class="card-body py-3">
          <div class="row mb-3">
            <div class="col">
              <button
                type="button"
                class="btn btn-secondary me-2"
                @click="data.orgChart.expandAll"
              >
                {{ $t('cdf.orgChart.buttons.openAllNodes') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary me-2"
                @click="data.orgChart.collapseAll"
              >
                {{ $t('cdf.orgChart.buttons.closeAllNodes') }}
              </button>
              <filter-options @filterOptions="handleTagFilter" />
            </div>
          </div>

          <div id="orgChartContainer" :key="data.orgChartTmpId"></div>
        </div>
      </div>
    </div>
    <side-drawer
      id="nodeEditPanel"
      :title="computedEditPanelTitle"
      :node="data.currentNode"
      width="700px"
    >
      <MyForm
        v-if="computedHasCurrentNode"
        id="editNodeForm"
        ref="editNodeForm"
        class="form"
        novalidate="novalidate"
        @submit="saveCurrentNode(true)"
        method="post"
        :validation-schema="nodeValidator.config"
      >
        <div class="row mb-6" v-if="data.currentNode.parent">
          <label class="col-lg-4 col-form-label required fw-bold fs-6"
            >Parent</label
          >

          <div class="col-lg-8 fv-row d-flex">
            <multiselect
              v-if="computedShowParentSelect"
              mode="single"
              v-model="data.currentParent.value"
              :options="computedParentSelectOptions"
              :searchable="true"
            />

            <input
              v-else
              type="text"
              class="form-control form-control-lg form-control-solid"
              :value="data.currentNode.parent.name"
              disabled="disabled"
            />
            <div class="d-flex align-items-center ms-3">
              <button
                v-if="computedShowParentSelect"
                type="button"
                class="btn btn-primary btn-sm"
                @click="handleParentChange"
                :disabled="data.currentParent.value === null"
              >
                {{ $t('save') }}
              </button>
              <button
                v-if="computedShowParentSelect"
                type="button"
                class="btn btn-secondary btn-sm ms-1"
                @click="cancelParentEdit"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-secondary btn-sm"
                @click="startParentEdit"
              >
                {{ $t('edit') }}
              </button>
            </div>
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
            $t('cdf.orgUnit.title')
          }}</label>

          <div class="col-lg-8 fv-row">
            <Field
              type="text"
              name="name"
              class="form-control form-control-lg form-control-solid"
              placeholder="Name"
              v-model="data.currentNode.name"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="name" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="computedAttributeEditAllowed" class="row mb-6">
          <label class="col-lg-4 col-form-label fw-bold fs-6">{{
            $t('tags')
          }}</label>
          <div class="col-lg-8 fv-row">
            <multiselect
              ref="tagMultiselect"
              mode="tags"
              v-model="data.currentNode.tags"
              :options="data.availableTags"
              :searchable="true"
              :createTag="false"
            />
          </div>
        </div>

        <div
          class="row mb-6"
          v-if="computedAttributeEditAllowed && data.currentNode"
        >
          <label class="col-lg-4 col-form-label fw-bold fs-6">{{
            $t('responsiblePeople')
          }}</label>
          <div class="col-lg-8 fv-row">
            <multiselect
              ref="responsibleMultiselect"
              mode="tags"
              v-model="computedCurrentResponsibles"
              :options="currentOptionsResponsibles"
              :searchable="true"
              :createTag="false"
              :filterResults="false"
              valueProp="id"
              label="name"
              trackBy="id"
              @search-change="handleResponsibleSearch"
            >
              <template v-slot:tag="{ option, handleTagRemove }">
                <div class="multiselect-tag is-user">
                  {{ option.name }}
                  <span
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="card-footer row py-5 px-0">
          <div class="col">
            <button
              v-if="!isNewNode"
              type="button"
              class="btn btn-secondary btn-sm me-2"
              @click="openCreateNodeForm"
            >
              <span class="indicator-label"> {{ $t('newNode') }} </span>
            </button>
            <button
              v-if="!isNewNode"
              type="button"
              class="btn btn-danger btn-sm"
              @click="removeCurrentNode"
            >
              <span class="indicator-label"> {{ $t('removeNode') }} </span>
            </button>
          </div>
          <div class="col text-end">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton1"
              class="btn btn-primary"
            >
              <span class="indicator-label"> {{ $t('saveChanges') }} </span>
            </button>
          </div>
        </div>
      </MyForm>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
  // import { useRoute, useRouter } from 'vue-router';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { OrgChart } from 'd3-org-chart';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { DrawerComponent, MenuComponent } from '@/assets/ts/components/index';
  import FilterOptions from '@/components/cdf/FilterOptions.vue';
  import * as Yup from 'yup';

  import SideDrawer from '../../../components/SideDrawer.vue';
  import Multiselect from '@vueform/multiselect';
  import { OrgChartDataPair, Responsible, Tag } from '@/core/models/cdf';
  import { OrgChartDataPairWithCatalogs } from '@/core/models/fes';
  import { getChildren, getPath } from '@/core/helpers/cdf';

  type ParentMultiselectOption = {
    value: string;
    label: string;
  };

  export default defineComponent({
    name: 'viewOrgChart',
    components: {
      FilterOptions,
      SideDrawer,
      MyForm: Form,
      Field,
      ErrorMessage,
      Multiselect,
    },
    setup() {
      const { t } = useI18n();
      // const route = useRoute();
      const store = useStore();
      // const tagMultiselect = ref(null);
      const tagMultiselect = ref({
        remove: () => null,
        deselect: () => null,
        clearSearch: () => null,
        clear: () => null,
      });
      const responsibleMultiselect = ref({
        remove: () => null,
        deselect: () => null,
        clearSearch: () => null,
        clear: () => null,
        refreshOptions: () => null,
      });

      const editNodeForm = ref({
        reset: () => null,
      });

      // const orgChartContainer = ref<null | { innerHTML: string }>(null);

      const data: {
        pageTitle: string;
        orgChartData: OrgChartDataPair[];
        orgChartDataFiltered: OrgChartDataPair[];
        orgChart: any;
        orgChartTmpId: number;
        currentNode: OrgChartDataPair;
        currentParent: ParentMultiselectOption | null;
        filteredTags: string[];
        availableTags: string[];
        tagsBeforeEditSave: string[];
      } = reactive({
        pageTitle: t('viewOrgChart'),
        orgChartData: [],
        orgChartDataFiltered: [],
        orgChart: new OrgChart(),
        orgChartTmpId: 1,
        currentNode: {} as OrgChartDataPair,
        currentParent: null,
        filteredTags: [],
        availableTags: [],
        tagsBeforeEditSave: [],
      });

      const isNewNode = computed(() => {
        return data.currentNode.id === '';
      });
      const computedEditPanelTitle = computed(() => {
        if (data.currentNode.id !== '') {
          return data.currentNode.name;
        }
        return t('newNode');
      });

      function idsToResponsibles(ids: string[]): Responsible[] {
        return ids.map((id) => {
          const responsible = currentOptionsResponsibles.find(
            (node) => node.id === id,
          );
          if (responsible) {
            return {
              id: responsible.id,
              name: responsible.name,
              mail: responsible.mail,
              username: responsible.username,
            } as Responsible;
          }
          return {} as Responsible;
        });
      }

      const computedCurrentResponsibles = computed({
        get: () => {
          if (typeof data.currentNode.responsibles === 'undefined') {
            return [];
          }
          return data.currentNode.responsibles.map(
            (responsible: Responsible) => {
              return responsible.id;
            },
          );
        },
        set: (value) => {
          data.currentNode.responsibles = idsToResponsibles(value);
        },
      });

      const currentOptionsTags = reactive([] as string[]);
      const currentOptionsResponsibles = reactive([] as Responsible[]);

      function showEditPanel() {
        const nodeEditPanelInstance =
          DrawerComponent.getInstance('nodeEditPanel');
        if (typeof nodeEditPanelInstance !== 'undefined') {
          nodeEditPanelInstance.show();
        }
      }
      function hideEditPanel() {
        const nodeEditPanelInstance =
          DrawerComponent.getInstance('nodeEditPanel');
        if (typeof nodeEditPanelInstance !== 'undefined') {
          nodeEditPanelInstance.hide();
        }
      }

      async function handleNodeClick(nodeId) {
        const isAuthenticated = await store.dispatch(Actions.VERIFY_AUTH);
        if (isAuthenticated === false) {
          store.dispatch(Actions.REDIRECT_TO_SIGNIN);
          return false;
        }

        resetEditNodeForm();
        showEditPanel();
        const currentNode = data.orgChartData.find((item: OrgChartDataPair) => {
          return item.id === nodeId;
        });

        if (typeof currentNode !== 'undefined') {
          data.currentNode = {} as OrgChartDataPair;
          if (typeof currentNode !== 'undefined') {
            if (currentNode.parentId !== '') {
              currentNode.parent = data.orgChartData.find(
                (item: OrgChartDataPair) => {
                  return item.id === currentNode.parentId;
                },
              );
            }

            data.currentNode = Object.assign(
              {} as OrgChartDataPair,
              currentNode,
            );

            data.tagsBeforeEditSave = data.currentNode.tags.slice(0);
          }

          initTags();
          initResponsibles();
        }
      }

      function initTags(moreTags = [] as string[]) {
        currentOptionsTags.length = 0;
        if (data.currentNode !== null) {
          if (moreTags.length === 0) {
            data.currentNode.tags.forEach((tag: string) => {
              !currentOptionsTags.includes(tag)
                ? currentOptionsTags.push(tag)
                : null;
            });
          } else {
            moreTags.forEach((tag: string) => {
              !currentOptionsTags.includes(tag)
                ? currentOptionsTags.push(tag)
                : null;
            });
          }
        }
      }

      function initResponsibles(moreResponsibles = [] as Responsible[]) {
        currentOptionsResponsibles.length = 0;

        if (data.currentNode !== null) {
          data.currentNode.responsibles.forEach((responsible: Responsible) => {
            const isInOptions = currentOptionsResponsibles.find(
              (item: Responsible) => {
                return item.id === responsible.id;
              },
            );

            if (typeof isInOptions === 'undefined') {
              currentOptionsResponsibles.push(Object.assign({}, responsible));
            }
          });
        }
        moreResponsibles.forEach((responsible: Responsible) => {
          const isInOptions = currentOptionsResponsibles.find(
            (item: Responsible) => {
              return item.id === responsible.id;
            },
          );

          if (typeof isInOptions === 'undefined') {
            currentOptionsResponsibles.push(Object.assign({}, responsible));
          }
        });
        return currentOptionsResponsibles;
      }

      function getTags(tags: Tag[]): string {
        if (tags.length === 0)
          return `<span class="badge badge-light-primary">${t(
            'cdf.orgChart.tags.none',
          )}</span>`;

        return tags.length === 1
          ? `<span class="badge badge-primary">1 ${t(
              'cdf.orgChart.tags.singular',
            )}</span>`
          : `<span class="badge badge-primary">${tags.length} ${t(
              'cdf.orgChart.tags.plural',
            )}</span>`;
      }

      function getResponsibles(responsibles: Responsible[]): string {
        if (responsibles.length === 0)
          return `<span class="badge badge-light-info">${t(
            'cdf.orgChart.responsibles.none',
          )}</span>`;

        return responsibles.length === 1
          ? `<span class="badge badge-info">1 ${t(
              'cdf.orgChart.responsibles.singular',
            )}</span>`
          : `<span class="badge badge-info">${responsibles.length} ${t(
              'cdf.orgChart.responsibles.plural',
            )}</span>`;
      }

      const computedOrgChartData = computed(() => {
        if (data.orgChartDataFiltered.length !== 0) {
          return data.orgChartDataFiltered.slice(0);
        }

        return data.orgChartData.slice(0) || [];
      });

      async function renderChart() {
        data.orgChart
          .container('#orgChartContainer')
          .data(computedOrgChartData.value.slice(0))
          .nodeWidth(() => 320)
          .nodeHeight(() => 160)
          .buttonContent(({ node }) => {
            return `<a href="javascript:" class="nodeMoreButton">${
              node.children
                ? `<i class="fas fa-chevron-up"></i>`
                : `<i class="fas fa-chevron-down"></i>`
            } ${node.data._directSubordinates}</a>`;
          })
          .onNodeClick((d) => handleNodeClick(d.id))
          .nodeContent((d) => {
            const tagsHtml = d.depth === 0 ? '' : getTags(d.data.tags);
            const responsiblesHtml =
              d.depth === 0 ? '' : getResponsibles(d.data.responsibles);

            return `<div class="orgChartNode"
    style="border-radius:2px;overflow:visible"
  >
    <div
      class="orgChartNodeContainer"
    >
      <div
        style="background-color:#00CCFF;height:10px;width:100%;border-radius:1px"
      ></div>

      <div style="padding: 20px; text-align: center">
        <div style="color: #002B49; font-size: 16px; font-weight: bold">
          ${d.data.name}
        </div>
      </div>

      <div class="row nodeTags">
        <div class="py-3 col">
          <span class="me-3">${tagsHtml}</span>
          <span>${responsiblesHtml}</span>
        </div>
      </div>

    </div>
  </div>`;
          })
          .render();
      }

      async function handleTagSearch(search: string) {
        if (search.length < 1) return;

        const response = await ApiService.post('org-chart/tags', {
          data: {
            query: search,
          },
        });

        initTags(response.data);
      }

      async function handleResponsibleSearch(search: string) {
        if (search.length < 1) return;

        const response = await ApiService.post('org-chart/responsibles', {
          data: {
            query: search,
          },
        });

        initResponsibles(response.data);
        responsibleMultiselect.value?.refreshOptions();
      }

      async function updateOrgChartData() {
        data.orgChart.data(computedOrgChartData.value);
        data.orgChart.render();
      }

      async function handleParentChange() {
        if (data.currentParent === null) return;

        const newParentId = data.currentParent.value;

        data.currentNode.parentId = newParentId;
        data.currentNode.parent = data.orgChartData.find(
          (item: OrgChartDataPair) => {
            return item.id === data.currentNode.parentId;
          },
        );

        data.currentParent = null;
        saveCurrentNode(false);
      }

      async function assignTagsDownwards(tags: string[]) {
        const orgUnitIds = getChildren(
          data.currentNode as OrgChartDataPairWithCatalogs,
          data.orgChartData as OrgChartDataPairWithCatalogs[],
        ).map((orgUnit: OrgChartDataPair) => {
          return orgUnit.id;
        });
        const requestUrl = `org-chart/update/tags`;
        await ApiService.put(requestUrl, {
          data: {
            orgUnitIds: orgUnitIds,
            tags: tags,
          },
        }).catch(() => {
          Swal.fire(t('error'), t('errorAlertCaption'), 'error');
        });

        data.orgChartData.forEach((orgUnit: OrgChartDataPair) => {
          if (orgUnitIds.includes(orgUnit.id)) {
            orgUnit.tags = orgUnit.tags.concat(tags);
          }
        });
        updateOrgChartData();
      }

      async function saveCurrentNode(hidePanel = true) {
        if (hidePanel === true) {
          const currentTags: string[] = data.currentNode.tags.slice(0);
          const newAddedTags: string[] = currentTags.filter((tag: string) => {
            return data.tagsBeforeEditSave.includes(tag) === false;
          });

          if (newAddedTags.length > 0) {
            const result = await Swal.fire({
              text: t('cdf.orgUnit.assignTagsDownwardsQuestion', {
                tags: newAddedTags.join(', '),
              }),
              icon: 'question',
              showCancelButton: true,
            });

            if (result.isConfirmed) {
              await assignTagsDownwards(newAddedTags);
            }
          }
        }

        let nodeInView: any;
        data.orgChartData.forEach((node: OrgChartDataPair) => {
          if (data.currentNode?.id === node.id) {
            nodeInView = node;
          }
        });

        store.dispatch(Actions.START_LOADER);
        const requestUrl = `org-chart/${nodeInView ? 'update' : 'create'}`;

        ApiService.put(requestUrl, {
          data: data.currentNode,
        })
          .then((orgUnit) => {
            if (!nodeInView) {
              data.orgChartData.push(orgUnit.data);
            } else {
              for (const key in data.currentNode) {
                nodeInView[key] = data.currentNode[key];
              }
            }

            if (hidePanel === true) {
              hideEditPanel();
            }
            updateOrgChartData();
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      async function loadOrgChart() {
        store.dispatch(Actions.START_LOADER);
        ApiService.get('org-chart')
          .then(async (result) => {
            data.orgChartData = result.data;
            renderChart();
          })
          .catch(() => {
            Swal.fire('Error', 'Not able to load Org Chart.', 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      async function removeCurrentNode() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          text: t('cdf.orgUnit.removeQuestion', {
            name: data.currentNode.name,
          }),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              const removeRequest = await ApiService.delete(
                `org-chart/${data.currentNode.id}`,
              );

              const removedChildren = removeRequest.data;
              data.orgChartData = data.orgChartData.filter(
                (node: OrgChartDataPair) => {
                  return (
                    node.id !== data.currentNode.id &&
                    !removedChildren.includes(node.id)
                  );
                },
              );
              data.currentNode = {} as OrgChartDataPair;

              hideEditPanel();
              updateOrgChartData();
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          });
      }

      function resetEditNodeForm() {
        data.currentParent = null;
        data.currentNode = {
          id: '',
          name: '',
          parentId: data.currentNode.id,
          parent: data.currentNode,
          tags: [],
          responsibles: [],
        } as OrgChartDataPair;
      }

      async function openCreateNodeForm() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          text: `Do you want to create the new node as child of ${data.currentNode.name}?`,
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              resetEditNodeForm();
              tagMultiselect.value?.clearSearch();
              tagMultiselect.value?.clear();
              editNodeForm.value.reset();
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          });
      }

      const computedAttributeEditAllowed = computed(() => {
        const fullNode = data.orgChartData.find((node: OrgChartDataPair) => {
          return node.id === data.currentNode.id;
        });

        if (typeof fullNode !== 'undefined') {
          return fullNode.parentId !== '';
        }

        return true;
      });

      async function handleTagFilter(tags) {
        data.filteredTags = tags;
        const response = await ApiService.post('org-chart', {
          data: {
            tags: data.filteredTags,
          },
        });
        data.orgChartDataFiltered = response.data;
        updateOrgChartData();
      }

      function initChart() {
        data.orgChartTmpId++;
        data.orgChartData = [];
        data.orgChartDataFiltered = [];
        data.orgChart = new OrgChart();
        data.currentNode = {} as OrgChartDataPair;
        data.filteredTags = [];
      }

      const computedShowParentSelect = computed(() => {
        return data.currentParent !== null;
      });

      const loadAvailableTags = async () => {
        const response = await ApiService.post('org-chart/tags', {
          data: {
            query: '',
          },
        });
        data.availableTags = response.data;
      };

      const computedAvailableTags = computed(() => {
        return data.availableTags;
      });

      const computedHasCurrentNode = computed(() => {
        return Object.keys(data.currentNode).length > 0;
      });

      const startParentEdit = () => {
        if (
          typeof data.currentNode.parent !== 'undefined' &&
          data.currentNode.parent !== null
        ) {
          data.currentParent = {
            value: data.currentNode.parent.id,
            label: getPath(
              data.currentNode.parent as OrgChartDataPairWithCatalogs,
              data.orgChartData as OrgChartDataPairWithCatalogs[],
            ),
          };
        }
      };

      const cancelParentEdit = () => {
        data.currentParent = null;
      };

      const computedParentSelectOptions = computed(() => {
        const res = data.orgChartData.map((orgUnit: OrgChartDataPair) => {
          return {
            value: orgUnit.id,
            label: getPath(
              orgUnit as OrgChartDataPairWithCatalogs,
              data.orgChartData as OrgChartDataPairWithCatalogs[],
            ),
          } as ParentMultiselectOption;
        });
        return res;
      });

      onMounted(async () => {
        await initChart();
        DrawerComponent.reinitialization();
        MenuComponent.reinitialization();
        await loadOrgChart();
        await loadAvailableTags();
      });

      const nodeValidator = reactive({
        config: Yup.object().shape({
          name: Yup.string().required().min(1).label('Name'),
        }),
      });

      return {
        data,
        nodeValidator,
        saveCurrentNode,
        handleTagSearch,
        handleResponsibleSearch,
        currentOptionsTags,
        currentOptionsResponsibles,
        updateOrgChartData,
        tagMultiselect,
        openCreateNodeForm,
        computedEditPanelTitle,
        computedCurrentResponsibles,
        removeCurrentNode,
        handleTagFilter,
        isNewNode,
        computedOrgChartData,
        computedAttributeEditAllowed,
        computedAvailableTags,
        computedHasCurrentNode,
        computedShowParentSelect,
        startParentEdit,
        cancelParentEdit,
        computedParentSelectOptions,
        handleParentChange,
      };
    },
  });
</script>

<style lang="scss">
  .cdf-config-container {
    #orgChartContainer {
      height: 1200px;
      overflow: hidden;
      background-color: #fcfcfc;
    }
    .orgChartNode {
      width: 320px;
      height: 160px;
    }
    .orgChartNodeContainer {
      height: inherit;
      border-radius: 10px;
      overflow: hidden;
    }
    .nodeMoreButton {
      width: 100%;
      text-align: center;
      border-radius: 3px;
      padding: 10px 5px;
      font-size: 12px;
      margin: auto auto;
      background-color: white;
      border: 1px solid lightgray;
    }
    .node-button-foreign-object:hover a.nodeMoreButton {
      border-color: lightblue;
    }
    .orgChartNodeContainer {
      padding-top: 0px;
      background-color: white;
      border: 1px solid lightgray;
    }
    g {
      cursor: default;
    }
    g.node-button-g,
    .orgChartNode {
      cursor: pointer;
    }
    .nodeTags,
    .nodeResponsibles {
      padding: 0 10px;
    }
  }
</style>
