<template>
  <user-multi-select
    :max="3"
    :modelValue="usersFromAssignees"
    @update:modelValue="handleUpdate"
  ></user-multi-select>
</template>

<script setup lang="ts">
  import { OpenPointAssignee } from '@/core/models/opl';
  import { computed, defineModel } from 'vue';
  import UserMultiSelect from '@/components/common/UserMultiSelect.vue';
  import { MinimalUser } from '@/store/modules/AuthModule';

  const assignees = defineModel<OpenPointAssignee[]>({
    required: true,
    type: Array,
  });

  const usersFromAssignees = computed(() =>
    assignees.value.map<MinimalUser>((assignee) => ({
      id: assignee.userId,
      name: assignee.name,
      mail: assignee.email,
      assigneeId: assignee.id,
    })),
  );

  function handleUpdate(newUsers: MinimalUser[]) {
    assignees.value = newUsers.map<OpenPointAssignee>((user) => ({
      id: user.assigneeId,
      userId: user.id,
      name: user.name,
      email: user.mail,
    }));
  }
</script>
