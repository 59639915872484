<template>
  <div class="symbol symbol-30px symbol-md-40px">
    <span class="userIconWithInitials">{{ computedInitials }}</span>
  </div>
</template>
<script lang="ts">
  import { User } from '@/store/modules/AuthModule';
  import { computed, defineComponent, PropType } from 'vue';

  export default defineComponent({
    name: 'userIcon',
    components: {},
    props: {
      user: {
        type: Object as PropType<User | null>,
        required: true,
      },
    },
    setup(props) {
      const computedInitials = computed(() => {
        if (props.user === null) return 'XX';
        const nameSplit = props.user.name.split(' ');
        const initialsArray = nameSplit.map((name) =>
          String(name[0]).toUpperCase(),
        );
        return initialsArray.join('');
      });

      return {
        computedInitials,
      };
    },
  });
</script>

<style scoped>
  .userIconWithInitials {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    font-family: Poppins, Helvetica, 'sans-serif';
    color: #5a7c91;
    text-align: center;
    background-color: #a3bac8;
    line-height: 39px;
    display: block;
    margin: auto;
  }
</style>
