<template>
  <div class="page-loader flex-column">
    <div
      class="loader-bubble d-flex justify-content-center flex-column align-items-center"
    >
      <div class="d-flex align-items-center">
        <img class="spinner" src="media/icons/loader.gif" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'Loader',
    props: {
      logo: String,
    },
    setup() {
      const store = useStore();

      const loaderType = computed(() => {
        return store.getters.layoutConfig('loader.type');
      });

      return {
        loaderType,
      };
    },
  });
</script>
