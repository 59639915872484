<template>
  <span class="d-flex align-items-center">
    <span>
      <slot></slot>
    </span>
    <span v-if="isDeleted" class="ms-3 badge badge-sm badge-light-danger">{{
      $t('deleted')
    }}</span>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SpanWithDeletedState',
    props: {
      isDeleted: Boolean,
    },
    setup(props) {
      return {
        ...props,
      };
    },
  });
</script>
