<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <div class="card">
        <!--begin::Card header-->

        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >{{ $t('fes.overview.allProcessInterruptions') }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >({{ data.processInterruptions.length }})</span
              ></span
            >
          </h3>
          <!--begin::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Group actions-->
            <div
              v-if="data.checkedElements.length"
              class="d-flex justify-content-end align-items-center"
              data-kt-subscription-table-toolbar="selected"
            >
              <div class="fw-bolder me-5">
                <span
                  class="me-2"
                  data-kt-subscription-table-select="selected_count"
                  >{{ data.checkedElements.length }}</span
                >
                {{ $t('selected') }}
              </div>

              <button
                v-if="computedCurrentUserIsAdmin"
                type="button"
                class="btn btn-danger btn-sm me-3"
                @click="deleteSelected"
              >
                {{ $t('remove') }}
              </button>
            </div>
            <router-link
              to="/fes/processinterruptions/start"
              class="btn btn-sm btn-primary align-self-center"
              ><i class="fa fa-plus-square"></i>
              {{ $t('fes.form.processInterruption.new') }}</router-link
            >
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <div class="filters d-flex">
            <div class="mb-5 min-w-500px">
              <label class="form-label fw-bold"
                >{{ $t('fes.overview.location') }}:</label
              >
              <multiselect
                mode="single"
                v-model="data.filterConfig.orgUnitId"
                :options="data.orgChart"
                :searchable="true"
                :create-option="false"
                valueProp="id"
                label="name"
              >
                <template v-slot:singlelabel="{ value }">
                  <div
                    class="multiselect-single-label"
                    :title="getFullOrgUnitPath(value, data.orgChart)"
                  >
                    {{ value.name }}
                  </div>
                </template>
                <template v-slot:option="{ option }">
                  {{ getFullOrgUnitPath(option, data.orgChart) }}
                </template>
              </multiselect>
            </div>
          </div>

          <KTDatatable
            @click:row="handleRowClick"
            :key="computedProcessInterruptions.length"
            v-if="data.isLoaded === true"
            :table-data="computedProcessInterruptions"
            :table-header="data.headerConfig"
            :enable-items-per-page-dropdown="true"
          >
            <template v-slot:cell-checkbox="{ row: processInterruption }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="processInterruption.id"
                  v-model="data.checkedElements"
                />
              </div>
            </template>

            <template v-slot:cell-source="{ row: processInterruption }">
              <router-link
                v-if="processInterruption.errorCaseId"
                :to="`/fes/overview/${processInterruption.errorCaseId}`"
              >
                <span class="badge badge-success"
                  >FES / {{ processInterruption.errorCaseId }}</span
                >
              </router-link>
              <span class="badge badge-primary" v-else>{{ $t('direct') }}</span>
            </template>
            <template v-slot:cell-category="{ row: processInterruption }">
              <process-interruption-path
                :processInterruption="processInterruption"
              />
            </template>
            <template v-slot:cell-location="{ row: processInterruption }">
              <div v-if="processInterruption.locationText">
                <div :title="processInterruption.locationText">
                  <span>{{ processInterruption.locationName }}</span
                  ><br />
                  <small>{{ processInterruption.locationAreaName }}</small>
                </div>
              </div>
              <span v-else class="badge badge-light">{{ $t('unknown') }}</span>
            </template>
            <template v-slot:cell-description="{ row: processInterruption }">
              <span>{{ processInterruption.reason }}</span>
            </template>
            <template
              v-slot:cell-downtimeMachine="{ row: processInterruption }"
            >
              <span>{{ processInterruption.downtimeMachine }}</span>
            </template>
            <template v-slot:cell-downtimeWorker="{ row: processInterruption }">
              <span>{{ processInterruption.downtimeWorker }}</span>
            </template>
            <template v-slot:cell-state="{ row: processInterruption }">
              <span
                v-if="processInterruption.state === 'open'"
                class="badge badge-primary"
                >{{ $t('fes.processInterruption.states.open') }}</span
              >
              <span
                v-if="processInterruption.state === 'accepted'"
                class="badge badge-success"
                >{{ $t('fes.processInterruption.states.accepted') }}</span
              >
              <span
                v-if="processInterruption.state === 'declined'"
                class="badge badge-danger"
                >{{ $t('fes.processInterruption.states.declined') }}</span
              >
            </template>
            <template v-slot:cell-createdAt="{ row: processInterruption }">
              {{ computedDate(processInterruption.createdAt) }}
            </template>
            <template v-slot:cell-actions="{ row: processInterruption }">
              <div class="d-flex">
                <div
                  class="d-flex"
                  v-if="
                    processInterruption.state === 'open' &&
                    processInterruption.allowedToAccept === true
                  "
                >
                  <button
                    @click="acceptProcessInterruption(processInterruption)"
                    class="btn btn-icon btn-light btn-sm border-0 btn-active-light-primary"
                    title="Accept"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-check" />
                    </span>
                  </button>
                  <button
                    @click="declineProcessInterruption(processInterruption)"
                    class="btn btn-icon btn-light btn-sm border-0 ms-2 me-2 btn-active-light-primary"
                    title="Decline"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-times" />
                    </span>
                  </button>
                </div>
                <router-link
                  :to="`/fes/processInterruptions/${processInterruption.id}`"
                  class="btn btn-icon btn-light btn-sm border-0"
                >
                  <span class="svg-icon svg-icon-5 m-0">
                    <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                  </span>
                </router-link>
              </div>
            </template>
          </KTDatatable>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import ApiService from '@/core/services/ApiService';
  import { computed, defineComponent, onMounted, reactive } from 'vue';
  import KTDatatable, {
    IHeaderConfiguration,
  } from '@/components/kt-datatable/KTDatatable.vue';
  import { computedDate } from '@/core/helpers/cdf';
  import {
    ErrorCaseLocation,
    OrgChartDataPairWithCatalogs,
    ProcessInterruption,
    ProcessInterruptionFilterConfig,
    ProcessInterruptionState,
    ProcessInterruptionWithPermission,
  } from '@/core/models/fes';
  import moment from 'moment';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n';
  import ProcessInterruptionPath from '@/components/fes/ProcessInterruptionPath.vue';
  import Multiselect from '@vueform/multiselect';
  import { getFullOrgUnitPath } from '@/core/helpers/cdf';
  import { useRouter } from 'vue-router';
  import { User } from '@/store/modules/AuthModule';

  export default defineComponent({
    name: 'fesProcessInterruptionsOverview',
    components: {
      KTDatatable,
      ProcessInterruptionPath,
      Multiselect,
    },
    setup() {
      const store = useStore();
      const { t } = useI18n();
      const router = useRouter();

      const data: {
        processInterruptions: ProcessInterruptionWithPermission[];
        headerConfig: IHeaderConfiguration[];
        isLoaded: boolean;
        checkedElements: number[];
        lastUpdated: string;
        filterConfig: ProcessInterruptionFilterConfig;
        orgChart: OrgChartDataPairWithCatalogs[];
      } = reactive({
        processInterruptions: [],
        headerConfig: [
          {
            name: t('fes.processInterruption.overview.source'),
            key: 'source',
            sortable: true,
            sortingField: 'errorCaseId',
          } as IHeaderConfiguration,
          {
            name: t('fes.processInterruption.overview.category'),
            key: 'category',
            sortable: false,
          },
          {
            name: t('structure'),
            key: 'location',
            sortable: false,
          },
          {
            name: t('fes.form.processInterruption.downtimeMachine'),
            key: 'downtimeMachine',
            sortable: true,
          },
          {
            name: t('fes.form.processInterruption.downtimeWorker'),
            key: 'downtimeWorker',
            sortable: true,
          },
          {
            name: t('state.view'),
            key: 'state',
            sortable: true,
          },
          {
            name: t('createdAt'),
            key: 'createdAt',
            sortable: true,
          },
        ],
        isLoaded: false,
        checkedElements: [],
        lastUpdated: moment().toISOString(),
        filterConfig: {
          orgUnitId: null,
        },
        orgChart: [],
      });

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        if (
          currentUser.groups.includes(
            'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          )
        ) {
          return true;
        }
        return false;
      });

      if (computedCurrentUserIsAdmin.value === true) {
        data.headerConfig.unshift({
          key: 'checkbox',
        });
      }

      async function acceptProcessInterruption(
        processInterruption: ProcessInterruptionWithPermission,
      ) {
        if (processInterruption.allowedToAccept === false) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/accept/${processInterruption.id}`,
          {},
        );
        data.processInterruptions = data.processInterruptions.map(
          (loopedProcessInterruption: ProcessInterruptionWithPermission) => {
            if (loopedProcessInterruption.id === processInterruption.id) {
              loopedProcessInterruption.state =
                ProcessInterruptionState.ACCEPTED;
            }
            return loopedProcessInterruption;
          },
        );
        store.dispatch(Actions.END_LOADER);
      }

      async function declineProcessInterruption(
        processInterruption: ProcessInterruptionWithPermission,
      ) {
        if (processInterruption.allowedToAccept === false) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;

        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/decline/${processInterruption.id}`,
          {},
        );
        data.processInterruptions = data.processInterruptions.map(
          (loopedProcessInterruption: ProcessInterruptionWithPermission) => {
            if (loopedProcessInterruption.id === processInterruption.id) {
              loopedProcessInterruption.state =
                ProcessInterruptionState.DECLINED;
            }
            return loopedProcessInterruption;
          },
        );
        store.dispatch(Actions.END_LOADER);
      }

      async function deleteSelected() {
        Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              store.dispatch(Actions.START_LOADER);

              await Promise.all(
                data.checkedElements.map(async (id) => {
                  await ApiService.delete(`/fes/processinterruptions/${id}`);
                }),
              );
              data.processInterruptions = data.processInterruptions.filter(
                (processInterruption: ProcessInterruptionWithPermission) => {
                  if (!processInterruption.id) return false;
                  return !data.checkedElements.includes(processInterruption.id);
                },
              );

              data.checkedElements = [];

              store.dispatch(Actions.END_LOADER);
              Swal.fire(t('done'), t('fes.overview.bulkDeleted'), 'success');
            }
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedProcessInterruptions = computed(() => {
        return data.processInterruptions
          .slice(0)
          .filter((processInterruption: ProcessInterruptionWithPermission) => {
            if (data.filterConfig.orgUnitId !== null) {
              return processInterruption.locations.some(
                (location: ErrorCaseLocation) => {
                  return location.locationId === data.filterConfig.orgUnitId;
                },
              );
            }
            return true;
          });
      });

      function handleRowClick(processInterruption: ProcessInterruption) {
        router.push(`/fes/processInterruptions/${processInterruption.id}`);
      }

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);
        data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);
        ApiService.get('/fes/processinterruptions').then((response) => {
          data.processInterruptions = response.data.map(
            (processInterruption: ProcessInterruption) => {
              const parsedProcessInterruption: any = Object.assign(
                {},
                processInterruption,
              );

              if (processInterruption.locations.length > 0) {
                parsedProcessInterruption.locationText =
                  processInterruption.locations
                    .map((location: ErrorCaseLocation) => {
                      return location.locationName;
                    })
                    .join(' > ');
                const lastLocation =
                  processInterruption.locations[
                    processInterruption.locations.length - 1
                  ];
                parsedProcessInterruption.locationName =
                  lastLocation.locationName;
                parsedProcessInterruption.locationAreaName =
                  lastLocation.locationIdentifier;
              }
              return parsedProcessInterruption;
            },
          );

          data.isLoaded = true;
          store.dispatch(Actions.END_LOADER);
        });
      });

      return {
        data,
        computedDate,
        // computedTime,
        deleteSelected,
        acceptProcessInterruption,
        declineProcessInterruption,
        getFullOrgUnitPath,
        computedProcessInterruptions,
        handleRowClick,
      };
    },
  });
</script>
