import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-kt-drawer-width", "data-kt-drawer-close"]
const _hoisted_2 = { class: "card shadow-none w-100" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "card-title fw-bolder text-dark align-items-start flex-column mw-75" }
const _hoisted_5 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_6 = { class: "card-toolbar ms-auto" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "position-relative me-n5 pe-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.computedId,
    class: "bg-white",
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "edit",
    "data-kt-drawer-activate": "true",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": `{default:'${_ctx.computedWidth}'}`,
    "data-kt-drawer-direction": "end",
    "data-kt-drawer-toggle": "#kt_edit_toggle",
    "data-kt-drawer-close": `#${_ctx.computedId}Close`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-header d-flex justify-content-between align-items-center",
        id: `${_ctx.computedId}-header`
      }, [
        _createElementVNode("h3", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.computedTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-icon btn-active-light-primary me-n5",
            id: `${_ctx.computedId}Close`
          }, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ], 8, _hoisted_7)
        ])
      ], 8, _hoisted_3),
      _createElementVNode("div", {
        class: "card-body position-relative",
        id: `${_ctx.computedId}-body`
      }, [
        _createElementVNode("div", _hoisted_10, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 8, _hoisted_9)
    ])
  ], 8, _hoisted_1))
}