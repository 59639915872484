<template>
  <multiselect
    object
    mode="tags"
    label="name"
    value-prop="id"
    track-by="id"
    :model-value="props.modelValue"
    :options="userOptions"
    :searchable="true"
    :create-option="createEnabled"
    :filter-results="false"
    :max="max"
    @change="handleChange"
    @create="handleCreateOption"
    @search-change="handleSearchChange"
  >
    <template v-slot:tag="{ option, handleTagRemove }">
      <div class="multiselect-tag is-user">
        {{ option.name || option.mail }}
        <span
          class="multiselect-tag-remove"
          @mousedown.prevent="handleTagRemove(option, $event)"
        >
          <span class="multiselect-tag-remove-icon"></span>
        </span>
      </div>
    </template>
  </multiselect>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import Multiselect from '@vueform/multiselect';
  import ApiService from '@/core/services/ApiService';
  import { MinimalUser, User } from '@/store/modules/AuthModule';
  import { AxiosResponse } from 'axios';

  interface Props {
    modelValue: MinimalUser[];
    createEnabled?: boolean;
    max?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    max: 5,
    createEnabled: false,
  });

  const emit = defineEmits(['update:modelValue', 'create']);

  const userOptions = ref(props.modelValue.slice());

  function handleCreateOption(newUser: MinimalUser) {
    // Return false to avoid default creation of item
    if (!props.createEnabled) {
      return false;
    }
    emit('create', newUser);
    return false;
  }

  function handleChange(newUsers: MinimalUser[]) {
    emit('update:modelValue', newUsers);
  }

  async function handleSearchChange(query: string) {
    if (!query) {
      return;
    }
    const response: AxiosResponse<User[]> = await ApiService.post(
      'users/find',
      {
        data: {
          query,
        },
      },
    );
    // Set user options based on search-related users that were found
    userOptions.value = response.data.map((user) => ({
      id: user.id,
      name: user.name,
      mail: user.mail,
    }));
  }
</script>
