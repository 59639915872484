<template>
  <div>
    <span v-for="(element, index) of props.nodePath" :key="element.id">
      <span v-if="index !== 0">
        <i class="fa fa-chevron-right mx-2"></i>
      </span>
      {{ element.name }}</span
    >
  </div>
</template>
<script lang="ts">
  import { OrgChartDataPairWithCatalogs } from '@/core/models/fes';
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    name: 'cdfPath',
    components: {},
    props: {
      nodePath: {
        type: Array as PropType<OrgChartDataPairWithCatalogs[]>,
        required: true,
      },
    },
    setup(props) {
      return {
        props,
      };
    },
  });
</script>
