<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col">
      <!--begin::Tables Widget 9-->
      <div class="card">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-center justify-content-start">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              data.pageTitle
            }}</span>
            <router-link
              to="/cdf/users/overview"
              type="button"
              class="btn btn-sm btn-secondary fw-bolder ms-2 fs-8 py-1 px-3"
            >
              {{ $t('back') }}</router-link
            >
          </h3>
        </div>
        <!--end::Header-->

        <MyForm
          id="editUserForm"
          class="form"
          novalidate="novalidate"
          @submit="saveUser"
          method="post"
          :validation-schema="userValidator.config"
        >
          <!--begin::Body-->
          <div class="card-body py-3">
            <!--begin::Input group-->
            <div class="row mb-6" v-if="data.user.id">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >User ID</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div
                class="col-lg-8 fv-row d-flex align-items-center fs-5 fw-bold"
              >
                <span>{{ data.user.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >Username</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row" v-if="isNew">
                <Field
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  name="username"
                  v-model="data.user.username"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="username" />
                  </div>
                </div>
              </div>
              <!--begin::Col-->
              <div
                v-else
                class="col-lg-8 fv-row d-flex align-items-center fs-5 fw-bold"
              >
                <span>{{ data.user.mail }}</span>
              </div>
              <!--end::Col-->
              <!--end::Col-->
            </div>
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >Password</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row" v-if="isNew">
                <Field
                  type="password"
                  class="form-control form-control-lg form-control-solid"
                  name="password"
                  v-model="data.user.password"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <!--begin::Col-->
              <div
                v-else
                class="col-lg-8 fv-row d-flex align-items-center fs-5 fw-bold"
              >
                <span>{{ data.user.mail }}</span>
              </div>
              <!--end::Col-->
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >E-Mail</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row" v-if="isNew">
                <Field
                  type="email"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="max@mustermann.de"
                  name="mail"
                  v-model="data.user.mail"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="mail" />
                  </div>
                </div>
              </div>
              <!--begin::Col-->
              <div
                v-else
                class="col-lg-8 fv-row d-flex align-items-center fs-5 fw-bold"
              >
                <span>{{ data.user.mail }}</span>
              </div>
              <!--end::Col-->
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                $t('cdf.orgUnit.title')
              }}</label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  type="text"
                  name="name"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Max Mustermann"
                  v-model="data.user.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >Language</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <!--begin::Input-->
                <Field
                  as="select"
                  name="language"
                  class="form-select form-select-solid form-select-lg"
                  v-model="data.user.language"
                >
                  <option value="en">English</option>
                  <option value="de">German</option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="language" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >Active</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <!--begin::Input-->
                <Field
                  as="select"
                  name="active"
                  class="form-select form-select-solid form-select-lg"
                  v-model="data.user.active"
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="active" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>

          <!--begin::Actions-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button
              v-if="isNew"
              type="reset"
              class="btn btn-white btn-active-light-primary me-2"
            >
              Discard
            </button>
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton1"
              class="btn btn-primary"
            >
              <span class="indicator-label"> {{ $t('saveChanges') }} </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Actions-->
        </MyForm>

        <!--begin::Body-->
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import { defineComponent, onMounted, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import ApiService from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import * as Yup from 'yup';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';

  interface User {
    id?: null;
    username: null;
    password: null;
    mail: null;
    name: null;
    active: null;
    language: null;
  }

  const emptyUser: User = {
    id: null,
    username: null,
    password: null,
    mail: null,
    name: null,
    active: null,
    language: null,
  };

  export default defineComponent({
    name: 'userEdit',
    components: { ErrorMessage, Field, MyForm: Form },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      const isNew: boolean = route.params.id === '' ? true : false;

      const data = reactive({
        pageTitle: t('user.new'),
        user: Object.assign({}, emptyUser),
        isLoading: false,
      });

      async function loadUser(loadedId) {
        store.dispatch(Actions.START_LOADER);
        ApiService.get('users', String(loadedId))
          .then((result) => {
            result.data.active = result.data.active ? 'true' : 'false';
            data.user = result.data;
            data.pageTitle = t('editUser');
          })
          .catch(() => {
            Swal.fire('Error', 'Not able to load User.', 'error');
            router.push({
              name: 'usersOverview',
            });
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      const shape = {
        language: Yup.string().required().min(2).max(2).label(t('language')),
        name: Yup.string().required().label(t('cdf.orgUnit.title')),
        username: Yup.string().required().label(t('username')),
        active: Yup.bool().required().label(t('satte.view')),
      };

      if (isNew) {
        shape['mail'] = Yup.string().required().email().label(t('user.mail'));
        shape['password'] = Yup.string()
          .required()
          .min(8)
          .label(t('user.password'));
      }

      const userValidator = reactive({
        config: Yup.object().shape(shape),
      });

      const saveUser = async () => {
        store.dispatch(Actions.START_LOADER);
        let requestUrl = `users/edit`;

        const userRequestData = Object.assign({}, data.user);

        if (isNew === false) {
          requestUrl += '/' + userRequestData.id;
        }

        delete userRequestData.id;

        ApiService.put(requestUrl, {
          data: userRequestData,
        })
          .then(() => {
            router.push({
              name: 'usersOverview',
            });
            const statusMessage = isNew
              ? 'Your user has been created.'
              : 'Your user has been updated.';
            Swal.fire(t('done'), statusMessage, 'success');
          })
          .catch(() => {
            Swal.fire(t('error'), t('errors.unableToLoadUser'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });

        return false;
      };

      onMounted(async () => {
        data.user = Object.assign({}, emptyUser);

        if (!isNew) {
          const loadedId = route.params.id;
          await loadUser(loadedId);
        } else {
          store.dispatch(Actions.END_LOADER);
        }
      });

      return {
        data,
        userValidator,
        saveUser,
        isNew,
      };
    },
  });
</script>
