const CDFMenuConfig = [
  {
    pages: [
      {
        heading: 'cdf.nav.orgChart',
        route: '/cdf/org-chart',
        svgIcon: 'media/icons/duotune/graphs/gra006.svg',
        fontIcon: 'bi-sticky',
      },
      {
        heading: 'viewUserManagement',
        route: '/cdf/users/overview',
        svgIcon: 'media/icons/duotune/communication/com005.svg',
        fontIcon: 'bi-sticky',
      },
    ],
  },
];

export default CDFMenuConfig;
