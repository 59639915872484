import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.state === 'open')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(`badge badge-${_ctx.$t('spc.processconfirmation.statesBadges.open')}`)
        }, _toDisplayString(_ctx.$t('spc.processconfirmation.states.open')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'progress')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(`badge badge-${_ctx.$t(
        'spc.processconfirmation.statesBadges.progress',
      )}`)
        }, _toDisplayString(_ctx.$t('spc.processconfirmation.states.progress')), 3))
      : _createCommentVNode("", true),
    (_ctx.state === 'done')
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(`badge badge-${_ctx.$t('spc.processconfirmation.statesBadges.done')}`)
        }, _toDisplayString(_ctx.$t('spc.processconfirmation.states.done')), 3))
      : _createCommentVNode("", true)
  ]))
}