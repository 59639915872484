import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "ms-3 badge badge-sm badge-light-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.isDeleted)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('deleted')), 1))
      : _createCommentVNode("", true)
  ]))
}