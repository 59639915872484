import { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/Layout.vue';

import Dashboard from '@/views/opl/OplDashboard.vue';
import Overview from '@/views/opl/OplOverview.vue';
import Single from '@/views/opl/OplSingle.vue';
import Create from '@/views/opl/OplCreate.vue';
import Config from '@/views/opl/config/OplConfigIndex.vue';
import SingleOPL from '@/views/fes/opl/FesOplSingle.vue';
import { FES_DECIDER_ROLES } from '@/core/services/ApiService';

export default {
  path: '/opl',
  redirect: '/opl/dashboard',
  component: DefaultLayout,
  meta: {
    app: 'OPL',
    allowedGroups: [
      'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
      'Sw_DC-LT_W_P_DEVIATION_7210_QM_MEASURE_local',
      'Sw_DC-LT_W_P_DEVIATION_7210_QM_USER_local',
      ...FES_DECIDER_ROLES,
    ],
  },
  children: [
    {
      path: '/opl/dashboard',
      name: 'oplDashboard',
      component: Dashboard,
    },
    {
      path: '/opl/overview',
      name: 'oplOpenPointOverview',
      component: Overview,
    },
    {
      path: '/opl/overview/:id',
      name: 'oplSingle',
      component: Single,
    },
    {
      path: '/opl/create',
      name: 'oplCreate',
      component: Create,
    },
    {
      path: '/opl/create/:source/:sourceId',
      name: 'oplCreateFromFes',
      component: Create,
    },
    {
      path: '/opl/config',
      name: 'oplConfig',
      component: Config,
    },
    {
      path: '/opl/fes/:id',
      name: 'fesErrorSingleOPL',
      component: SingleOPL,
    },
  ],
} as RouteRecordRaw;
