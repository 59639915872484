import { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/Layout.vue';

import Dashboard from '@/views/fes/FesDashboard.vue';
import Single from '@/views/fes/errors/FesErrorsSingle.vue';
import Overview from '@/views/fes/errors/FesErrorsOverview.vue';
import ProcessInterruptionsOverview from '@/views/fes/processInterruptions/FesProcessInterruptionsOverview.vue';
import ProcessInterruptionsSingle from '@/views/fes/processInterruptions/FesProcessInterruptionsSingle.vue';
import Config from '@/views/fes/config/FesConfigIndex.vue';

import OpenPointListOverview from '@/views/fes/opl/FesOplOverview.vue';
import store from '@/store';
import { User } from '@/store/modules/AuthModule';
import {
  FES_DECIDER_ROLES,
  isProcessInterruptionsEnabled,
} from '@/core/services/ApiService';

const fesRouterConfig = {
  path: '/fes',
  redirect: () => {
    const currentUser: User = { ...store.getters.currentUser };
    if (
      currentUser.groups &&
      currentUser.groups.some((groupName: string) => {
        return [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          ...FES_DECIDER_ROLES,
        ].includes(groupName);
      })
    ) {
      return '/fes/dashboard';
    }
    return '/fes/start';
  },
  component: DefaultLayout,
  meta: {
    app: 'FES',
    allowedGroups: [
      'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
      'Sw_DC-LT_W_P_DEVIATION_7210_QM_USER_local',
      ...FES_DECIDER_ROLES,
    ],
  },
  children: [
    {
      path: '/fes/dashboard',
      name: 'fesDashboard',
      component: Dashboard,
      meta: {
        allowedGroups: [
          'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
          ...FES_DECIDER_ROLES,
        ],
      },
    },
    {
      path: '/fes/start',
      name: 'fesErrorStart',
      component: Single,
    },
    {
      path: '/fes/overview',
      name: 'fesErrorCaseOverview',
      component: Overview,
    },
    {
      path: '/fes/overview/:id',
      name: 'fesErrorSingle',
      component: Single,
    },
    {
      path: '/fes/opl/overview',
      name: 'fesOpenPointOverview',
      component: OpenPointListOverview,
    },
    {
      path: '/fes/config',
      name: 'fesConfig',
      component: Config,
      meta: {
        requiredGroup: 'Sw_DC-LT_W_P_DEVIATION_7210_QM_ADMIN_local',
      },
    },
  ],
} as RouteRecordRaw;

if (isProcessInterruptionsEnabled()) {
  fesRouterConfig.children = fesRouterConfig.children?.concat(
    {
      path: '/fes/processinterruptions/:id',
      name: 'fesProcessInterruptionsSingleLoaded',
      component: ProcessInterruptionsSingle,
    },
    {
      path: '/fes/processinterruptions/overview',
      name: 'fesProcessInterruptionsOverview',
      component: ProcessInterruptionsOverview,
    },
    {
      path: '/fes/processinterruptions/start',
      name: 'fesProcessInterruptionsSingleStart',
      component: ProcessInterruptionsSingle,
    },
  );
}

export default fesRouterConfig;
