export default {
  dashboard: 'Dashboard',
  language: 'Sprache',
  layoutBuilder: 'Layout-Builder',
  craft: 'Hergestellt',
  pages: 'Seiten',
  profile: 'Profil',
  projects: 'Projekte',
  campaigns: 'Kampagnen',
  documents: 'Unterlagen',
  connections: 'Anschlüsse',
  wizards: 'Zauberer',
  horizontal: 'Horizontal',
  vertical: 'Vertikal',
  account: 'Konto',
  overview: 'Übersicht',
  overviews: 'Übersichten',
  settings: 'Die Einstellungen',
  authentication: 'Authentifizierung',
  basicFlow: 'Grundfluss',
  signIn: 'Einloggen',
  signUp: 'Anmelden',
  passwordReset: 'Passwort zurücksetzen',
  error: 'Fehler',
  warning: 'Warnung',
  error404: 'Fehler 404',
  error500: 'Fehler 500',
  apps: 'Apps',
  chat: 'Plaudern',
  privateChat: 'Private Chat',
  groupChat: 'Privater Chat',
  drawerChat: 'Gruppenchat Schubladen-Chat',
  widgets: 'Widgets',
  widgetsLists: 'Listen',
  widgetsStatistics: 'Statistiken',
  widgetsCharts: 'Diagramme',
  widgetsMixed: 'Gemischt',
  widgetsTables: 'Tabellen',
  widgetsFeeds: 'Einspeisungen',
  changelog: 'Änderungsprotokoll',
  docsAndComponents: 'Dokumente & Komponenten',
  megaMenu: 'Mega-Menü',
  exampleLink: 'Beispiellink',
  modals: 'Modale',
  general: 'Allgemeines',
  inviteFriends: 'Freunde Einladen',
  viewUsers: 'Benutzer Anzeigen.',
  upgradePlan: 'Upgrade-Plan',
  shareAndEarn: 'Teilen & Verdienen',
  forms: 'Formen',
  newTarget: 'Neues Ziel',
  newCard: 'Neue Karte',
  newAddress: 'Neue Adresse',
  createAPIKey: 'Api-Key Erstellen',
  twoFactorAuth: 'Zwei Faktor Auth.',
  createApp: 'App Erstellen',
  createAccount: 'Benutzerkonto Erstellen',
  activity: 'Aktivität',
  documentation: 'Dokumentation',
  components: 'Bauteile',
  resources: 'Ressourcen',
  customers: 'Kunden',
  gettingStarted: 'Einstieg',
  customersListing: 'Kundenauflistung',
  customerDetails: 'Kundenangaben',
  calendarApp: 'Kalender',
  subscriptions: 'Abonnements',
  getStarted: 'Einstieg',
  subscriptionList: 'Abonnementliste',
  addSubscription: 'Subskription Hinzufügen.',
  viewSubscription: 'Abonnement Anzeigen.',
  allUsers: 'Alle Nutzer',
  createUser: 'Benutzerkonto erstellen',
  viewUserManagement: 'Nutzerverwaltung',
  sureQuestionHeadline: 'Sind sie sicher?',
  cancel: 'Abbrechen',
  save: 'Speichern',
  saveChanges: 'Speichern',
  saving: 'Speichert ...',
  done: 'Erledigt',
  continue: 'Weiter',
  passwordsDoNotMatch: 'Passwörter stimmen nicht überein',
  passwordChanged: 'Passwort wurde geändert',
  passwordTooShort: 'Passwort ist zu kurz',
  loginWithNewCredentials:
    'Sie können nun mit Ihren neuen Anmeldedaten einloggen',
  clientVersion: 'Client Version',
  username: 'Benutzername',
  viewOrgChart: 'Organisationsstruktur',
  tags: 'Tags',
  newNode: 'Neuer Knoten',
  removeNode: 'Knoten entfernen',
  remove: 'Löschen',
  responsiblePeople: 'Verantwortliche Personen',
  noResponsiblePeople: 'Keine verantwortlichen Personen',
  filterOptions: 'Filter Optionen',
  filter: 'Filter',
  resetFilters: 'Filter zurücksetzen',
  reset: 'Zurücksetzen',
  edit: 'Bearbeiten',
  loadingData: 'Laden Daten...',
  administration: 'Administration',
  viewOPLOverview: 'Maßnahmenübersicht',
  pleaseSelect: 'Bitte auswählen',
  errorAlertCaption: 'Es gab einen Fehler',
  errorCaseNotFound: 'Fehlermappe wurde nicht gefunden',
  processInterruptionNotFound: 'Prozessunterbrechung wurde nicht gefunden',
  openPointNotFound: 'Maßnahme wurde nicht gefunden',
  send: 'Abschicken',
  release: 'Freigabe',
  delete: 'Löschen',
  none: 'Keine',
  direct: 'Direkt',
  depth: 'Tiefe',
  selected: 'ausgewählt',
  inMinutes: '(in Minuten)',
  minutes: 'Minuten',
  createdAt: 'Erstellt am',
  actions: 'Aktionen',
  accept: 'Akzeptieren',
  decline: 'Ablehnen',
  noOne: 'Keiner',
  configuration: 'Konfiguration',
  deleted: 'Gelöscht',
  unknown: 'Unbekannt',
  backToStart: 'Zurück zum Start',
  structure: 'Struktur',
  all: 'Alle',
  xSelected: '{x} ausgewählt',
  savesAutomatically: 'Speichert automatisch',
  noEntries: 'Keine Einträge',
  tabs: {
    fes: 'Fehlererfassung',
    opl: 'Maßnahmen',
    spc: 'SPC',
    cdf: 'CDF',
  },
  cdf: {
    nav: {
      orgChart: 'Organisationsstruktur',
    },
    orgUnit: {
      title: 'Name',
      removeQuestion:
        'Wollen Sie das Element {name}, alle Verbindungen und Kindelemente löschen?',
      assignTagsDownwardsQuestion:
        'Möchten Sie die Tags {tags} auch auf alle darunter liegenden Strukturelemente vergeben?',
    },
    orgChart: {
      responsibles: {
        singular: 'Verantwortlicher',
        plural: 'Verantwortliche',
        none: 'Keine Verantwortlichen',
      },
      tags: {
        singular: 'Tag',
        plural: 'Tags',
        none: 'Keine Tags',
      },
      buttons: {
        openAllNodes: 'Öffne alle Knoten',
        closeAllNodes: 'Schließe alle Knoten',
      },
    },
    hierarchyNames: {
      headline: 'Hierarchie Bezeichnungen',
    },
  },
  fes: {
    nav: {
      dashboard: 'FES Dashboard',
      start: 'Fehler',
      startPi: 'Prozessunterbrechung',
      catalogs: 'Kataloge',
      collection: 'Erfassung',
      decisions: 'Entscheidungskatalog',
      riskrisks: 'Risikokatalog',
      causes: 'Ursachenkatalog',
      interuptions: 'Störungskatalog',
      overview: 'Übersichten',
      processInterruptions: 'Prozessunterbrechungen',
    },
    barcodeModal: {
      enableManualButton: 'Manuelle Eingabe aktivieren',
    },
    form: {
      riskLevel: 'Risiko Level',
      new: 'Neue Fehlermappe',
      errorLocation: 'Fehlerplatz',
      errorArea: 'Bereich',
      barcode: 'Barcode',
      barcodes: 'Barcodes',
      noBarcodes: 'Keine Barcodes',
      qrCode: 'QR-Code',
      addCode: 'Barcode hinzufügen',
      errorCase: 'Fehlermappe',
      errorCaseDetail: 'Fehler-Details',
      back: 'Zurück',
      next: 'Weiter',
      continueWorkFinal:
        'Arbeit kann fortgesetzt werden. Teile sind freigegeben.',
      continueWork: 'Arbeit kann fortgesetzt werden.',
      messageAfterWorkerProcess:
        'Fehler wurde gespeichert. Bitte kleben Sie den Barcode auf die fehlerhaften Teile.',
      messageNoBarcode: 'Es muss mindestens 1 Barcode hinzugefügt werden.',
      decisionEdits: 'Anzahl Änderungen: {count}',
      details: {
        heading: 'Angaben zu fehlerhaftem Teil',
        subtext: 'Bitte geben Sie die Angaben zum fehlerhaften Teil an.',
        partName: 'Teile-Benennung',
        partReference: 'Teile-Nummer',
        jobReference: 'Auftrags-Nummer',
        amounts: 'Mengen',
        amountTotal: 'Auftrags-Menge',
        amountChecked: 'Geprüfte Menge',
        amountFlawed: 'Fehlerhafte Menge',
        materialName: 'Bezeichnung',
        materialReference: 'Materialnummer ',
        faufReference: 'FA- oder FAUF-Nummer',
        faufReferenceShort: 'FA-Nummer',
        customerReference: 'Kundennummer',
        customerOrderReference: 'Kundenauftrags-Nr.',
        checkReference: 'Prüflosnummer',
        sapReference: 'SAP-Charge',
        errorCaseNumber: 'Fehlermappen Nummer',
      },
      moreDetails: {
        heading: 'Angaben zum Fehler',
        subtext: 'Bitte geben Sie die Fehlerinformationen an.',
        category: 'Fehlerkategorie',
        description: 'detaillierte Fehlerbeschreibung',
        locationRiskCategory: 'Fehlerkategorie',
        errorPlace: 'Fehlerort',
        errorStyle: 'Fehlerart',
        riskLevel: 'Risiko-Level',
        productCategory: 'Produktkategorie',
        productCategoryShort: 'Kategorie',
        productSubCategory: 'Produktunterkategorie',
        productSubCategoryShort: 'Unterkategorie',
      },
      processInterruption: {
        new: 'Neue Prozessunterbrechung',
        self: 'Prozessunterbrechung',
        heading: 'Angaben zur Prozessunterbrechung',
        subtext: 'Bitte geben Sie die Prozessunterbrechungsinformationen an.',
        category: 'Hauptgruppe',
        addCategory: 'Neue Hauptgruppe',
        addSubCategory: 'Neue Untergruppe',
        subCategory: '1. Untergruppe',
        subSubCategory: '2. Untergruppe',
        reason: 'Grund Prozessunterbrechung',
        none: 'Keine Prozessunterbrechung',
        interruptionType: 'Art der Prozessunterbrechung',
        downtimeMachine: 'Unterbrechung Machine',
        downtimeWorker: 'Unterbrechung Arbeiter',
        connectedError: 'Verbundene Fehlermappe',
        connectedErrorDetails: '#{id} mit {style} bei {place}',
        interruptionTypes: {
          isMachineBlocked: 'Maschine blockiert',
          isWorkerBlocked: 'Arbeiter blockiert',
        },
      },
      productCategory: {
        addCategory: 'Neue Produktkategorie',
        addSubCategory: 'Neue Produktunterkategorie',
      },
      decision: {
        title: 'Entscheidung & Maßnahme',
        heading: 'Angaben zur Entscheidung',
        subtext: 'Bitte geben Sie die Entscheidungsinformationen an.',
        decider: 'Entscheider',
        decision: 'Entscheidung',
        user: 'Nutzer',
        name: 'Name',
        department: 'Abteilung',
        phone: 'Telefon',
        mail: 'E-Mail',
        pastSplitDecisions: 'Bisherige Entscheidungen',
        upcomingSplitDecisions: 'Neue Entscheidungen',
        upcomingSplitDecisionAlertTitle: 'Noch nicht gespeichert!',
        upcomingSplitDecisionAlertText:
          'Bitte bestätigen Sie den Schritt Maßnahmen.',
        splitAmount: 'Entscheidung für geteilte Menge?',
        splitAmountLabel: 'Menge',
        splitAmountAdd: 'Teilmenge hinzufügen',
        splitAmountRemove: 'Teilmenge löschen',
        splitAmountErrors: {
          moreThanOne: 'Es muss mindestens eine Menge zugeteilt werden.',
          moreThanTotal:
            'Die Menge darf nicht größer als die Gesamtmenge sein.',
        },
      },
      causes: {
        heading: 'Angaben zur Ursache',
        subtext: 'Bitte geben Sie die Ursacheninformationen an.',
        cause: 'Ursache',
        causeReason: 'Verursacher',
      },
      errors: {
        missingBarcode: 'Bitte geben Sie mindestens einen Barcode an.',
        duplicateBarcode: 'Der Barcode ist bereits in der aktuellen Liste.',
        duplicateBarcodeSaved: 'Der Barcode ist bereits in Benuztung.',
        barcodeNotFound: 'Der Barcode wurde nicht gefunden.',
        missingProductCategory: 'Bitte geben Sie eine Produktkategorie an.',
        missingProductSubCategory:
          'Bitte geben Sie eine Produktunterkategorie an.',
        missingLocationRiskCategory: 'Bitte geben Sie eine Fehlerkategorie an.',
        missingErrorPlace: 'Bitte geben Sie einen Fehlerort an.',
        missingErrorStyle: 'Bitte geben Sie eine Fehlerart an.',
        missingDescription:
          'Bitte geben Sie eine detaillierte Fehlerbeschreibung an.',
        missingPartName: 'Bitte geben Sie die Teile-Benennung an.',
        missingPartReference: 'Bitte geben Sie die Teile-Nummer an.',
        missingJobReference: 'Bitte geben Sie die Auftrags-Nummer an.',
        missingAmountTotal: 'Bitte geben Sie die Auftrags-Menge an.',
        missingAmountChecked: 'Bitte geben Sie die geprüfte Menge an.',
        amountCheckedTooHigh:
          'Die geprüfte Menge darf nicht größer als die Auftrags-Menge sein.',
        missingAmountFlawed: 'Bitte geben Sie die fehlerhafte Menge an.',
        amountFlawedTooHigh:
          'Die fehlerhafte Menge darf nicht größer als die geprüfte Menge sein.',
        missingDecision: 'Bitte geben Sie eine Entscheidung an.',
        missingCause: 'Bitte geben Sie eine Ursache an.',
        missingCauseReason: 'Bitte geben Sie einen Verursacher an.',
        missingMaterialReference: 'Bitte geben Sie die Materialnummer an.',
        missingFaufReference: 'Bitte geben Sie die FA- oder FAUF-Nummer an.',
        processInterruption: {
          missingCategory:
            'Bitte geben Sie eine Hauptgruppe für die Prozessunterbrechung an.',
          missingSubCategory:
            'Bitte geben Sie die 1. Untergruppe der Prozessunterbrechung an.',
          missingSubSubCategory:
            'Bitte geben Sie die 2. Untergruppe der Prozessunterbrechung an.',
          missingReason:
            'Bitte geben Sie einen Grund für die Prozessunterbrechung an.',
          missingDonwtimeMachine:
            'Bitte geben Sie die Unterbrechungszeit der Maschine an.',
          missingDowntimeWorker:
            'Bitte geben Sie die Unterbrechungszeit des Arbeiters an.',
        },
        missingComment: 'Bitte geben Sie einen Kommentar an.',
      },
      barcodeHint: {
        button: 'Scan Barcode',
        heading: 'Jetzt Barcode scannen',
        subtext: 'Bitte scannen Sie einen Barcode.',
        generateLhdCode: 'LHD-Nummer erzeugen',
      },
      steps: {
        1: {
          title: 'Fehler-Details',
          detail: 'Angaben zu fehlerhaftem Teil',
        },
        2: {
          title: 'Fehler-Kategorie',
          detail: 'Angaben zum Fehler',
        },
        3: {
          title: 'Barcodes',
        },
        4: {
          title: 'Prozessunterbrechung',
          detail: 'Angaben zur Prozessunterbrechung',
        },
        5: {
          title: 'Entscheidung & Ursache',
          detail: 'Angaben zur Entscheidung & Ursache',
        },
        6: {
          title: 'Maßnahmen',
          detail: 'Angaben zu Maßnahmen',
        },
      },
      scanMode: 'Scan Modus',
      on: 'Ein',
      off: 'Aus',
      simpleLevelWarning: {
        title: 'Der Fehler ist freigegeben.',
        text: 'Bitte tragen Sie auf den roten Barcodeaufkleber unter Austeller und Freigabe Ihren Namen ein.',
      },
      blockedAlert: 'Diese Fehlermappe wird aktuell von {name} bearbeitet.',
      releaseBlockedErrorButton: 'Freigeben',
      openPointsOnError: 'Neue Maßnahmen zur Fehlermappe',
    },
    catalog: {
      decisions: {
        subtext: 'Mehr Infos zum Entscheidungskatalog',
        selectStructure: '1. Bitte wählen Sie eine Struktur aus',
      },
    },
    config: {
      catalogs: 'Kataloge',
      useFromTemplate: 'Als Vorlage verwenden',
      linkButtonNew: 'Jetzt zuweisen',
      noDecisionCatalog: 'Kein Entscheidungskatalog',
      individualDecisionCatalog: 'Individueller Entscheidungskatalog',
      selectedIndividualDecisionCatalog:
        'Entscheidungskatalog #{id} vom {date} mit {amount} vefügbaren Entscheidungen',
      inheritDecisionCatalog: 'Vererbter Entscheidungskatalog',
      availableDecisionsInCatalog: 'Verfügbare Entscheidungen in,',
      allDecisions: 'Alle Entscheidungen',
      noCauseCatalog: 'Kein Ursachenkatalog',
      individualCauseCatalog: 'Individueller Ursachenkatalog',
      selectedIndividualCauseCatalog:
        'Ursachenkatalog #{id} vom {date} mit {amount} vefügbaren Ursachen',
      inheritCauseCatalog: 'Vererbter Ursachenkatalog',
      availableCausesInCatalog: 'Verfügbare Ursachen in,',
      allCauses: 'Alle Ursachen',
      noCauseReasonCatalog: 'Kein Verursacherkatalog',
      individualCauseReasonCatalog: 'Individueller Verursacherkatalog',
      selectedIndividualCauseReasonCatalog:
        'Verursacherkatalog #{id} vom {date} mit {amount} vefügbaren Verursachern',
      inheritCauseReasonCatalog: 'Vererbter Verursacherkatalog',
      availableCauseReasonsInCatalog: 'Verfügbare Verursacher in,',
      allCauseReasons: 'Alle Verursacher',
      noRiskCatalog: 'Kein Risikokatalog',
      individualRiskCatalog: 'Individueller Risikokatalog',
      selectedIndividualRiskCatalog:
        'Risikokatalog #{id} vom {date} mit {amount} vefügbaren Risko-Levels',
      inheritRiskCatalog: 'Vererbter Risikokatalog',
      missingCategoriesRiskCatalog:
        'Sie müssen mindestens eine Fehlerkategorie zuweisen.',
      missingErrorPlaceRiskCatalog:
        'Es muss mindestens ein Fehlerort und eine Fehlerart ausgewählt werden.',
      allErrorPlaces: 'Alle Fehlerorte',
      allErrorStyles: 'Alle Fehlerarten',
      selectProcessInterruptionCatalogHeadline: 'Haupt- und Untergruppen',
      selectProductCategoryCatalogHeadline:
        'Produktkategorien und Produktunterkategorien',
      noProcessInterruptionCatalog: 'Kein Prozessunterbrechungskatalog',
      individualProcessInterruptionCatalog:
        'Individueller Prozessunterbrechungskatalog',
      inheritProcessInterruptionCatalog:
        'Vererbter Prozessunterbrechungskatalog',
      noProductCategoryCatalog: 'Kein Produktkategoriekatalog',
      individualProductCategoryCatalog: 'Individueller Produktkategoriekatalog',
      inheritProductCategoryCatalog: 'Vererbter Produktkategoriekatalog',
      decisionCatalog: 'Entscheidungskatalog',
      causeCatalog: 'Ursachenkatalog',
      causeReasonCatalog: 'Verursacherkatalog',
      riskCatalog: 'Risikokatalog',
      riskCatalogLevels: 'Risiko-Level-Zuweisung',
      allErrorCategories: 'Alle Fehlerkategorien',
      errorCategoryNeeded: 'Sie müssen erst eine Fehlerkategorie auswählen.',
      processInterruptionCatalog: 'Prozessunterbrechungskatalog',
      productCategoryCatalog: 'Produktkategoriekatalog',
      selectedProductCatalog:
        'Produktkategoriekatalog #{id} vom {date} mit {amount} vefügbaren Produktkategorien',
      connect: 'Zuweisen',
      connectAll: 'Alle Zuweisen',
      disconnect: 'Lösen',
      decisions: 'Entscheidungen',
      causes: 'Ursachen',
      riskLevel: 'Risiko-Level Konfiguration',
      newDecision: 'Neue Entscheidung',
      addDecision: 'Neue Entscheidung hinzufügen',
      addRiskLevel: 'Neues Risiko-Level hinzufügen',
      addCause: 'Neue Ursache hinzufügen',
      addCauseReason: 'Neuen Verursacher hinzufügen',
      addErrorStyle: 'Neue Fehlerart hinzufügen',
      addErrorPlace: 'Neuen Fehlerort hinzufügen',
      addLocationRiskCategory: 'Neue Fehlerkategorie hinzufügen',
      removeRiskLevelQuestion: 'Wollen Sie das Risiko-Level wirklich löschen?',
      disconnectLocationRiskCategoryQuestion:
        'Wenn Sie die Fehlerkategorie löschen, dann werden alle Risiko-Levels in dieser Fehlerkategorie gelöscht. Wollen Sie die Fehlerkategorie wirklich löschen?',
      disconnectErrorStyleQuestion:
        'Wenn Sie diese Fehlerart lösen, dann werden alle Risiko-Level mit dieser Fehlerart gelöscht. Wollen Sie die Fehlerart wirklich löschen?',
      disconnectErrorPlaceQuestion:
        'Wenn Sie diesen Fehlerort lösen, dann werden alle Risiko-Level mit diesem Fehlerort gelöscht. Wollen Sie den Fehlerort wirklich löschen?',
      deleteDecisionQuestion:
        'Sind Sie sicher, dass Sie die Entscheidung löschen möchten? In anderen Elementen wird die Entscheidung auch gelöscht.',
      deleteProcessInterruptionCategoryQuestion:
        'Sind Sie sicher, dass Sie die Gruppe löschen möchten?',
      deleteProductCategoryQuestion:
        'Sind Sie sicher, dass Sie die Produktkategorie löschen möchten?',
      deleteProcessInterruptionSubCategoryQuestion:
        'Sind Sie sicher, dass Sie die Untergruppe löschen möchten?',
      deleteProductSubCategoryQuestion:
        'Sind Sie sicher, dass Sie die Produktunterkategorie löschen möchten?',
      deleteCauseQuestion:
        'Sind Sie sicher, dass Sie diesen Verursacher löschen möchten? In anderen Elementen wird der Verursacher auch gelöscht.',
      deleteCauseReasonQuestion:
        'Sind Sie sicher, dass Sie die Ursache löschen möchten? In anderen Elementen wird die Ursache auch gelöscht.',
      deleteErrorPlaceQuestion:
        'Sind Sie sicher, dass Sie den Fehlerort löschen möchten? In anderen Elementen wird der Fehlerort auch gelöscht.',
      deleteErrorStyleQuestion:
        'Sind Sie sicher, dass Sie die Fehlerart löschen möchten? In anderen Elementen wird die Fehlerart auch gelöscht.',
      deleteConnectedNodeHint:
        'Es bestehen noch Verbindungen zu folgenden {amount} Elementen:',
      deleteDecisionCatalogQuestion:
        'Wollen Sie diesen Entscheidungskatalog wirklich löschen?',
      deleteCauseCatalogQuestion:
        'Wollen Sie diesen Ursachenkatalog wirklich löschen?',
      deleteRiskCatalogQuestion:
        'Wollen Sie diesen Risikokatalog wirklich löschen?',
      deleteProcessInterruptionCatalogQuestion:
        'Wollen Sie diesen Prozessunterbrechungskatalog wirklich löschen?',
      decisionNeeded: 'Entscheidung & Ursache erforderlich',
      locationRiskCategory: {
        add: 'Neue Kategorie hinzufügen',
        edit: 'Kategorie bearbeiten',
        newName: 'Neuer Name',
      },
      noCauseReasonSelected: 'Keine Verursacher ausgewählt.',
      noCauseSelected: 'Keine Ursachen ausgewählt.',
      noDecisionSelected: 'Keine Entscheidung ausgewählt.',
      connectAllErrorPlaces: 'Allen Fehlerkategorien zuweisen',
      allProcessInterruptionsInCatalog: 'Alle Prozessunterbrechungen in',
      noProcessInterruptionAssigned: 'Keine Prozessunterbrechungen zugewiesen.',
      allProductCategoriesInCatalog: 'Alle Produktkategorien in',
      noProductCategoryAssigned: 'Keine Produktkategorien zugewiesen.',
      missing: {
        title: 'Fehlende Konfiguration',
        text: 'Gehen Sie in die Konfiguration und stellen Sie sicher, dass es eine vollständig konfiguriertre Organisationseinheit vorhanden ist.',
      },
      openPointResponsibles: 'Maßnahmenverantwortliche',
    },
    overview: {
      bulkDeleted: 'Elemente wurden gelöscht.',
      risk: 'Risiko',
      details: 'Details',
      amounts: 'Mengen',
      location: 'Fehlerplatz',
      errorLocation: 'Fehlerort / Fehlerart',
      processInterruption: 'Prozessunterbrechung',
      allProcessInterruptions: 'Alle Prozessunterbrechungen',
      decision: 'Entscheidung',
      states: {
        open: 'Offen',
        notNeeded: 'Nicht notwendig',
      },
      all: 'Alle Fehlermappen',
      lockedTooltip:
        'Diese Fehlermappe befindet sich gerade in Bearbeitung durch einen anderen Mitarbeiter.',
      progress: 'Fortschritt',
      newOpenPoint: 'Neue Maßnahme zur Fehlermappe',
    },
    notifications: {
      finalizedError: {
        title: 'Erledigt',
        text: 'Die Fehlermappe wurde entschieden.',
      },
    },
    processInterruption: {
      states: {
        open: 'Offen',
        accepted: 'Akzeptiert',
        declined: 'Abgelehnt',
      },
      stateActions: {
        accept: 'Akzeptieren',
        decline: 'Ablehnen',
      },
      form: {
        created: 'Die Prozessunterbrechung wurde erstellt.',
      },
      overview: {
        source: 'Quelle',
        category: 'Kategorie',
        description: 'Beschreibung',
      },
    },
    opl: {
      overview: 'Alle Maßnahmen vom FES',
    },
    noTaksOnErrorCase: 'Keine Maßnahmen am Fehler.',
  },
  opl: {
    form: {
      self: 'Maßnahme',
      plural: 'Maßnahmen',
      source: 'Quelle',
      title: 'Betreff Maßnahme',
      heading: 'Neue Maßnahmen',
      headingSub: 'Neue Untermaßnahmen',
      subtext: 'Bitte erfassen Sie alle Maßnahmen',
      none: 'Keine Maßnahmen',
      immediate: 'Sofortmaßnahme',
      immediateShort: 'SM',
      accidentPrevention: 'Unfallvorbeugung',
      accidentPreventionShort: 'UV',
      assignee: 'Verantwortlicher',
      assignees: 'Verantwortliche(r)',
      assigneeMaxHint: 'Maximal 3 Verantwortliche',
      newAssignee: 'Neuer Verantwortlicher',
      newAssignees: 'Neue Verantwortliche',
      watcher: 'Beobachter',
      dueDate: 'Umsetzung bis',
      comment: 'Bemerkung',
      comments: 'Bemerkungen',
      newComment: 'Neue Bemerkung verfassen',
      attachments: 'Bilder / Dokumente',
      additional: 'weitere Maßnahme',
      additionalChild: 'weitere Untermaßnahme',
      assign: 'Delegieren',
      postpone: 'Termin verschieben',
      complete: 'Umsetzen',
      decline: 'Ablehnen',
      messageHere: 'Geben Sie hier eine Bemerkung ein',
      create: 'Maßnahme erstellen',
      new: 'Neue Maßnahme',
      createBtn: {
        single: 'Maßnahme erstellen',
        plural: 'Maßnahmen erstellen',
      },
      created: 'Maßnahmen wurden erstellt',
      reason: 'Begründung',
      activities: 'Aktivitäten',
      lastEdit: 'Letzte Aktivität',
      children: {
        self: 'Untermaßnahme',
        plural: 'Untermaßnahmen',
      },
      parent: {
        self: 'Ursprungsmaßnahme',
        plural: 'Ursprungsmaßnahmen',
      },
      connectedErrorCase: {
        title: 'Verknüpfte Fehlermappe',
        text: 'Diese Maßnahme wird mit der Fehlermappe {errorCaseId} verknüpft.',
      },
    },
    errors: {
      missingTitle: 'Bitte geben Sie einen Betreff für die Maßnahme an.',
      missingComment: 'Bitte geben Sie einen Kommentar an.',
      missingDueDate: 'Bitte geben Sie ein Fälligkeitsdatum an.',
      missingAssignee: 'Bitte geben Sie mindestens einen Verantwortlichen an.',
      missingMessage: 'Bitte geben Sie eine Nachricht an.',
      missingReason: 'Bitte geben Sie eine Begründung an.',
      sameAssignee: 'Der Verantwortliche darf nicht gleich sein.',
      missingAttachment: 'Bitte geben Sie mindestens ein Bild an.',
      dueDateNotInFuture: 'Das Fälligkeitsdatum muss in der Zukunft liegen.',
    },
    assigned: 'Sie sind aktuell für diese Maßnahme verantwortlich.',
    assignedOther:
      '<span class="badge badge-primary me-3">{name}</span> ist aktuell für diese Maßnahme verantwortlich.',
    assignedNone: 'Die Maßnahme hat keinen Verantwortlichen.',
    assignedInitial: 'Die Maßnahme hat aktuell folgende Verantwortliche:',
    openOpenPoints: 'Offene Maßnahmen',
    changeAssigneeHint: 'Legen Sie hier einen neuen Verantwortlichen fest.',
    changeDueDateHint: 'Legen Sie hier ein neues Fälligkeitsdatum fest.',
    declineHint: 'Geben Sie hier eine Begründung ein.',
    changes: {
      assignee:
        'Der Verantwortliche wurde von <span class="badge badge-light-primary mx-3" title="{beforeUsername}">{before}</span> zu <span class="badge badge-primary mx-3" title="{afterUsername}">{after}</span> geändert.',
      additionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> wurde der Maßnahme als Verantwortlicher hinzugefügt.',
      removeAdditionalAssignee:
        '<span class="badge badge-light-primary me-3" title="{email}">{name}</span> wurde von der Maßnahme als Verantwortlicher entfernt.',
      state:
        'Der Status wurde von <span class="badge {beforeClass} mx-3" title="{beforeUsername}">{before}</span> zu <span class="badge {afterClass} mx-3" title="{afterUsername}">{after}</span> geändert.',
      dueDate:
        'Das Fälligkeitsdatum wurde vom <span class="badge badge-light-primary mx-3" title="{beforeUsername}">{before}</span> zum <span class="badge badge-primary mx-3" title="{afterUsername}">{after}</span> geändert.',
    },
    states: {
      open: 'Offen',
      progress: 'In Bearbeitung',
      done: 'Umgesetzt',
      closed: 'Abgeschlossen',
      declined: 'Abgelehnt',
      postponed: 'Verschoben',
    },
    statesBadges: {
      open: 'primary',
      progress: 'warning',
      done: 'success',
      closed: 'light',
      declined: 'danger',
      postponed: 'warning',
    },
    stateChanges: {
      dueDate: 'Verschiebung',
      completed: 'Umsetzung',
    },
    stateChangesDecisions: {
      waiting: '{type} muss noch entschieden werden.',
      accepted: '{type} wurde am {date} von {decider} akzeptiert.',
      declined: '{type} wurde am {date} von {decider} abgelehnt.',
    },
    overview: {
      source: 'Quelle',
      creator: 'Ersteller',
      creatorFES: 'Fehlermappen Ersteller',
      comments: 'Bemerkungen',
      state: 'Status',
      errorLocation: 'Fehlerort / Fehlerart',
      processInterruption: 'Prozessunterbrechung',
      decision: 'Entscheidung',
      states: {
        open: 'Offen',
        notNeeded: 'Nicht notwendig',
      },
      module: 'Modul',
    },
    watchers: {
      manage: {
        title: 'Beobachter verwalten',
        hint: 'Benutzer können über ihren Namen gefunden werden. Alternativ kann eine Email-Adresse angegeben werden.',
      },
    },
    goToErrorCaseLink: 'zur Fehlermeldung',
  },
  spc: {
    nav: {
      overview: 'Übersicht',
      processconfirmation: 'Prozessbestätigungen',
      audit: 'Audit',
      collection: 'Erstellung',
      auditOverview: 'Auditübersicht',
      auditPlans: 'Geplante Audits',
      managementLevel: 'Führungsebene {level}',
    },
    processconfirmation: {
      audit: 'Audit',
      audits: 'Audits',
      onlyAudit: 'Nur Audits',
      filterAll: 'Alle',
      name: 'Titel',
      description: 'Beschreibung',
      new: 'Neue Prozessbestätigung',
      newAudit: 'Neues Audit',
      backToOverview: 'zurück zur Übersicht',
      single: 'Prozessbestätigung',
      multiple: 'Prozessbestätigungen',
      onlySingle: 'Nur Prozessbestätigungen',
      location: 'Erfassungsort',
      amountOfQuestions: 'Fragenanzahl',
      create: 'Prozessbestätigung erstellen',
      createAudit: 'Audit erstellen',
      createAuditHint:
        'Erstellen Sie ein Audit oder eine Prozessbestätigung in einer ausgewählten Kategorie.',
      created: 'Prozessbestätigung wurde erstellt',
      createdAudit: 'Audit wurde erstellt',
      createNow: 'Jetzt erstellen',
      startRandom: 'Zufällige Prozessbestätigung starten',
      state: 'Status',
      comment: 'Kommentar',
      progress: 'Fortschritt',
      managementLevel: 'Führungsebene',
      openPointNeeded: 'Maßnahme(n) verknüpfen',
      newOpenPoints: 'Neue Maßnahmen',
      connectExistingOpenPoint: 'Bestehende Maßnahme auswählen',
      noOpenPointsConnected: 'Keine Maßnahme verknüpft.',
      connectedOpenPoints: 'Verknüpfte Maßnahmen',
      selectOpenPoints: 'Maßnahme auswählen',
      or: 'oder',
      and: 'und',
      deselectOpenPointTitle: 'Wollen Sie die Maßnahme wieder lösen?',
      createOpenPointAndConnect: 'Maßnahme(n) erstellen und verknüpfen',
      noComment: 'Kein Kommentar',
      showOnlyErrors: 'Nur Abweichungen anzeigen',
      amount: 'Anzahl',
      completedAmount:
        '{amount} Audits und Prozessbestatigungen abgeschlossen.',
      errors: {
        notFound: 'Prozessbestätigung wurde nicht gefunden',
        noDescription: 'Keine Beschreibung vorhanden.',
        missingName: 'Bitte geben Sie einen Namen an.',
        missingAmount: 'Bitte geben Sie eine Anzahl an.',
        missingCatalog: 'Bitte wählen Sie einen Katalog aus.',
        missingManagementLevel: 'Bitte wählen Sie eine Führungsebene aus.',
        noOpenPoint: {
          title: 'Keine Maßnahme verknüpft.',
          text: 'Bitte verknüpfen Sie mindestens eine Maßnahme.',
        },
      },
      states: {
        open: 'Offen',
        progress: 'In Bearbeitung',
        done: 'Abgeschlossen',
      },
      statesBadges: {
        open: 'primary',
        progress: 'warning',
        done: 'success',
      },
      answers: {
        yes: 'Ja',
        no: 'Nein',
        none: 'Nicht zutreffend',
      },
      success: {
        started: {
          title: 'Gestartet',
          text: 'Die Prozessbestätigung wurde erfolgreich gestartet.',
          textAudit: 'Das Audit wurde erfolgreich gestartet.',
        },
        created: {
          title: 'Erstellt',
          text: 'Die Prozessbestätigung wurde erfolgreich erstellt.<br /><a href="{url}">Jetzt Starten</a>',
          textAudit:
            'Das Audit wurde erfolgreich erstellt.<br /><a href="{url}">Jetzt Starten</a>',
        },
        createdMultiple: {
          title: 'Erstellt',
          text: 'Die Prozessbestätigungen wurden erfolgreich erstellt.',
          textAudit: 'Die Audits wurden erfolgreich erstellt.',
        },
        completed: {
          title: 'Abgeschlossen',
          text: 'Die Prozessbestätigung wurde erfolgreich abgeschlossen.',
          textAudit: 'Das Audit wurde erfolgreich abgeschlossen.',
        },
      },
    },
    auditPlan: {
      intervalRangeSeparator: 'bis',
      dateRange: 'Zeitraum',
      createAuditPlanCheckbox: '{type} in regelmäßigen Abständen erstellen',
      noEndDate: 'Kein Enddatum',
      interval: 'Intervall',
      errors: {
        missingStartDate: 'Bitte geben Sie ein Startdatum an.',
        missingInterval: 'Bitte geben Sie ein Intervall an.',
        loadingError: 'Der Auditplan konnte nicht geladen werden.',
      },
      startDatePlaceholder: 'Startdatum',
      endDatePlaceholder: 'Enddatum',
      intervals: {
        daily: 'Täglich',
        weekly: 'Wöchentlich',
        monthly: 'Monatlich',
      },
      success: {
        created: {
          title: 'Erstellt',
          text: 'Der Auditplan wurde erfolgreich erstellt.',
        },
        saved: {
          title: 'Gespeichert',
          text: 'Der Auditplan wurde erfolgreich geändert.',
        },
        removed: {
          title: 'Gelöscht',
          text: 'Der Auditplan wurde erfolgreich gelöscht.',
        },
      },
      calendar: 'Kalender',
      month: 'Monat',
      week: 'Woche',
      day: 'Tag',
      new: 'Neuer Auditplan',
      edit: 'Auditplan bearbeiten',
      create: 'Auditplan erstellen',
      saveChanges: 'Änderungen speichern',
    },
    questionCatalog: {
      all: 'Fragenkataloge',
      single: 'Fragenkatalog',
      new: 'Neuer Fragenkatalog',
      none: 'Keine Fragenkataloge',
      questionSummarized: 'Unterschiedliche Fragen',
      questionTotal: 'Gesamtanzahl Fragen',
      managementLevel: 'Führungsebene',
      managementLevels: 'Führungsebenen',
      allManagementLevels: 'Alle Führungsebenen',
      noManagementLevelsTitle: 'Keine Führungsebenen',
      noManagementLevelsText: 'Wählen Sie eine Führungsebene aus.',
      noQuestionsTitle: 'Keine Fragen',
      noQuestionsText: 'Wählen Sie eine Frage aus.',
      noQuestionCatalogs: 'Keine Fragenkataloge',
      managementLevelEdit: {
        add: 'Neue Kategorie hinzufügen',
        edit: 'Kategorie bearbeiten',
        newName: 'Neuer Name',
      },
      disconnectManagementLevelQuestion:
        'Sind Sie sicher, dass Sie diese Führungsebene trennen möchten?',
      addManagementLevel: 'Führungsebene hinzufügen',
      addQuestion: 'Frage hinzufügen',
      allQuestions: 'Alle Fragen',
      deleteQuestionQuestion:
        'Sind Sie sicher, dass Sie diese Frage löschen möchten?',
      deleteMangementLevelQuestion:
        'Sind Sie sicher, dass Sie diese Führungsebene löschen möchten?',
      catalogByManagementLevel: 'Fragenkatalog nach Führungsebene',
      deleteQuestion:
        'Sind Sie sicher, dass Sie diesen Fragenkatalog löschen möchten?',
      image: 'Bild',
      searchQuestion: 'Frage suchen...',
      searchUser: 'User suchen...',
      deactivated: 'Deaktiviert',
      inherited: 'Vererbt',
      inheritedUntil: 'Vererbt bis {orgUnitName}',
      managementLevelAmount: '{name} mit {amount} Fragen',
      overwrittenCatalog: 'Überschriebener Katalog',
      copiedAlert: {
        title: 'Vererbter Fragenkatalog',
        text: 'Die Vorlage von diesem Fragenkatalog stammt von {catalog}.',
      },
      connectedCatalogs: 'Verknüpfte Kataloge',
    },
    userManagement: {
      responsibles: 'Verantwortliche',
      workers: 'Mitarbeiter',
      availableWorkers: 'Verfügbare Mitarbeiter',
      selectedWorkers: 'Verknüpfte Mitarbeiter',
      connectWorker: 'verbinden',
      disconnectWorker: 'lösen',
    },
    question: {
      all: 'Alle Fragen',
      title: 'Frage',
      type: 'Fragentyp',
      multiple: 'Fragen',
      images: 'Bilder',
      hint: 'Hinweis',
      externalLink: 'Externer Link',
      noneAllowed: 'Nicht zutreffend erlaubt',
      openPointNeeded: 'Maßnahme erforderlich',
      onlyFromToDates: 'Zeitraum',
      onlyFromToDatesFrom: 'Von',
      onlyFromToDatesTo: 'Bis',
      types: {
        default: 'Normale Frage',
        mandatory: 'Pflichtfrage',
        pinned: 'Standardfrage',
      },
      moreDetails: 'Mehr Informationen',
      editHint:
        'Diese Frage ist ebenfalls noch in folgenden Fragenkatalogen verknüpft. Änderungen an der Frage wirken sich somit auf den Inhalt dieser Kataloge aus.',
    },
  },
  state: {
    view: 'Status',
    active: 'Aktiv',
    inactive: 'Inaktiv',
  },
  user: {
    new: 'Neuer Benutzer',
    mail: 'E-Mail',
    groups: 'Gruppen',
    idAndName: 'ID & Name',
    removed: 'Benutzer wurde entfernt',
    password: 'Passwort',
    settings: 'Benutzereinstellungen',
    mailSettings: 'E-Mail-Einstellungen',
    mails: {
      newErrorCase: 'Mail bei neuer Fehlermeldung',
      afterComplete: 'Mail bei Umsetzung einer Maßnahme',
      afterCompleteAccept: 'Mail bei Annahme einer Umsetzung der Maßnahme',
      afterCompleteDecline: 'Mail bei Ablehnung einer Umsetzung der Maßnahme',
      afterDecline: 'Mail bei Ablehnung einer Maßnahme',
      afterPostpone: 'Mail bei Verschiebung einer Maßnahme',
      afterPostponeAccept: 'Mail bei Annahme einer Verschiebung der Maßnahme',
      afterPostponeDecline:
        'Mail bei Ablehnung einer Verschiebung der Maßnahme',
      // afterRequestDecision: 'Nach Anfrage einer Entscheidung',
      // afterResolve: 'Mail bei Umsetzung einer Maßnahme',
      newAssignee: 'Mail bei Änderung Maßnahmenverantwortlicher',
      newComment: 'Mail bei neuer Bemerkung in Maßnahme',
      newOpenPoint: 'Mail bei neuer Maßnahme',
      afterWatcherAssignment: 'Mail bei Zuweisung der Beobachter-rolle',
      afterWatcherDismissal: 'Mail beim Entfernen der Beobachter-rolle',
    },
  },
  errors: {
    unableToLoadUser: 'Benutzer konnte nicht geladen werden.',
    missingConfig: {
      title: 'Fehlende Konfiguration',
      text: 'Gehen Sie in die Konfiguration und stellen Sie sicher, dass es eine vollständig konfigurierte Organisationseinheit vorhanden ist.',
    },
    missingManagementLevel: {
      title: 'Fehlende Führungsebene',
      text: 'Bitte wenden Sie sich an Ihren Administrator. Zur Nutzung der App muss eine Führungsebene vorhanden sein.',
    },
    missingManagementArea: {
      title: 'Fehlender zugewiesener Bereich',
      text: 'Bitte wenden Sie sich an Ihren Administrator. Zur Nutzung der App muss ein zugewiesener Bereich vorhanden sein.',
    },
    deleteManagementLevelNotAllowed: {
      title: 'Löschen nicht möglich',
      text: 'Es können keine Führungsebenen gelöscht werden, die noch in anderen Katalogen verbunden sind.',
    },
  },
  location: {
    selectAll: 'Alle',
  },
  dateFormat: 'DD.MM.YYYY',
};
