<template>
  <!--begin::Dashboard-->
  <div class="row">
    <div class="col-12">
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap mb-5"
      >
        <h1
          class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0"
        >
          {{ $t('user.settings') }}
        </h1>
      </div>

      <UserMailSettings />
    </div>
  </div>
  <!--end::Dashboard-->
</template>
<script lang="ts">
  import { defineComponent, onMounted } from 'vue';
  import UserMailSettings from './UserMailSettings.vue';

  export default defineComponent({
    name: 'userSettings',
    components: {
      UserMailSettings,
    },
    setup() {
      onMounted(() => {
        // const store = useStore();
        // const currentUser = reactive({ ...store.getters.currentUser });
      });
    },
  });
</script>
