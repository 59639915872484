<template>
  <errors-overview url="/fes/errors/open" :title="$t('fes.nav.dashboard')" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import errorsOverview from './errors/FesErrorsOverview.vue';

  export default defineComponent({
    name: 'fesErrorCaseDashboard',
    components: {
      errorsOverview,
    },
    props: {},
    setup() {
      return {};
    },
  });
</script>
