<template>
  <div class="fes-config-container">
    <div class="row">
      <div class="col-12 mb-3">
        <config-hierarchy-names
          :hierarchyNames="data.errorCaseCdfNames"
          moduleName="fes"
          @updated="updateOrgChartData"
        />
      </div>

      <div class="col-12 mb-3">
        <config-risk-levels
          :risk-levels="data.riskLevels"
          :all-groups="data.allGroups"
          @updated="loadOrgChart"
        />
      </div>
      <div class="col-12">
        <div class="card">
          <div
            class="card-header border-0"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#orgChartForm"
            aria-expanded="true"
            aria-controls="orgChartForm"
          >
            <h3 class="card-title align-items-center justify-content-start">
              <span class="card-label fw-bolder fs-3 mb-1"
                >{{ $t('viewOrgChart') }} FES</span
              >
            </h3>
          </div>
          <div id="orgChartForm" class="collapse show">
            <div class="card-body py-3">
              <div class="row mb-3">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.expandAll"
                  >
                    {{ $t('cdf.orgChart.buttons.openAllNodes') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="data.orgChart.collapseAll"
                  >
                    {{ $t('cdf.orgChart.buttons.closeAllNodes') }}
                  </button>
                </div>
              </div>

              <div id="fesCdfNamesConfigChart" :key="data.orgChartTmpId"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <side-drawer
      id="fesConfigDrawer"
      :title="computedDrawerTitle"
      :node="data.currentNode"
      width="50vw"
      :path="computedNodePathString"
    >
      <org-unit-path
        :nodePath="computedNodePath"
        :areaNames="data.errorCaseCdfNames"
        :levelSubstract="1"
      />

      <h2 class="fs-2 fw-bold my-2">
        {{ $t('fes.config.productCategoryCatalog') }}
      </h2>
      <product-category-catalog-drawer
        class="mb-15"
        :node-path="computedNodePath"
        :area-names="data.errorCaseCdfNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
      />
      <h2 class="fs-2 fw-bold my-2 mt-3">
        {{ $t('fes.config.riskCatalog') }}
      </h2>
      <risk-catalog-drawer
        class="mb-15"
        :node-path="computedNodePath"
        :area-names="data.errorCaseCdfNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
        :risk-levels="data.riskLevels"
      />
      <div v-if="enableProcessInterruption">
        <h2 class="fs-2 fw-bold my-2">
          {{ $t('fes.config.processInterruptionCatalog') }}
        </h2>
        <process-interruption-catalog-drawer
          class="mb-15"
          :node-path="computedNodePath"
          :area-names="data.errorCaseCdfNames"
          :update-function="handleCatalogUpdate"
          :current-node="data.currentNode"
          :org-chart-data="data.orgChartData"
        />
      </div>
      <h2 class="fs-2 fw-bold my-2">
        {{ $t('fes.config.decisionCatalog') }}
      </h2>
      <decision-catalog-drawer
        class="mb-15"
        :node-path="computedNodePath"
        :area-names="data.errorCaseCdfNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
      />
      <h2 class="fs-2 fw-bold my-2">
        {{ $t('fes.config.causeCatalog') }}
      </h2>
      <cause-catalog-drawer
        class="mb-15"
        :node-path="computedNodePath"
        :area-names="data.errorCaseCdfNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
      />

      <h2 class="fs-2 fw-bold my-2">
        {{ $t('fes.config.causeReasonCatalog') }}
      </h2>
      <cause-reason-catalog-drawer
        class="mb-15"
        :node-path="computedNodePath"
        :area-names="data.errorCaseCdfNames"
        :update-function="handleCatalogUpdate"
        :current-node="data.currentNode"
        :org-chart-data="data.orgChartData"
      />

      <div
        v-if="
          data.currentNode &&
          data.orgChartData &&
          calculateDepth(data.currentNode, data.orgChartData) === 3
        "
      >
        <h2 class="fs-2 fw-bold my-2">
          {{ $t('fes.config.riskLevel') }}
        </h2>
        <div class="col-12 mb-3">
          <div id="riskLevelForm">
            <div class="row" v-if="data.currentNode.riskLevelCatalogs === 0">
              <div class="col">
                <p>Keine Risko Level hinterlegt.</p>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(riskLevelCatalog, index) of computedRiskLevelCatalogs"
                :key="index"
              >
                <div
                  class="row mb-6"
                  v-if="computedIsNeededRiskLevel(riskLevelCatalog)"
                >
                  <div class="col-3 d-flex align-items-center">
                    <span class="badge badge-primary"
                      >{{ $t('fes.form.riskLevel') }}:
                      {{ riskLevelCatalog.riskValue }}</span
                    >
                  </div>

                  <div class="col-9">
                    <label class="form-label">{{
                      $t('responsiblePeople')
                    }}</label>
                    <multiselect
                      :ref="
                        (el) => {
                          riskLevelUserMultiselects[index] = el;
                        }
                      "
                      mode="tags"
                      v-model="riskLevelCatalog.userIds"
                      :options="riskLevelCatalog.usersToSelect"
                      :searchable="true"
                      :create-option="false"
                      :filterResults="false"
                      valueProp="id"
                      label="name"
                      @select="
                        (option) => {
                          handleUserAdd(option, riskLevelCatalog);
                        }
                      "
                      @deselect="
                        (option) => {
                          handleUserRemove(option, riskLevelCatalog);
                        }
                      "
                      @change="handleRiskLevelChange(riskLevelCatalog)"
                      @search-change="
                        (query) => {
                          handleUserSearch(
                            query,
                            riskLevelCatalog,
                            riskLevelCatalog.riskValue,
                          );
                        }
                      "
                    >
                      <template v-slot:tag="{ option, handleTagRemove }">
                        <div class="multiselect-tag is-user">
                          {{ option.name }}
                          <span
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col fv-row" v-if="data.currentNode">
        <h2 class="fs-2 fw-bold my-2">
          {{ $t('fes.config.openPointResponsibles') }}
        </h2>
        <el-transfer
          v-model="data.currentNode.userAreas"
          filterable
          :filter-placeholder="$t('spc.questionCatalog.searchUser')"
          :data="computedSelectableUsers"
          :titles="[
            $t('spc.userManagement.availableWorkers'),
            $t('spc.userManagement.selectedWorkers'),
          ]"
          :button-texts="[
            $t('spc.userManagement.disconnectWorker'),
            $t('spc.userManagement.connectWorker'),
          ]"
          style="
            --el-transfer-panel-width: 270px;
            --el-transfer-panel-body-height: 320px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
          @change="handleWorkerChange"
        />
      </div>
    </side-drawer>
  </div>
</template>

<script lang="ts">
  import { useI18n } from 'vue-i18n';
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    ref,
  } from 'vue';
  // import moment from 'moment';
  // import { useRoute, useRouter } from 'vue-router';
  import ApiService, {
    isProcessInterruptionsEnabled,
  } from '@/core/services/ApiService';
  import Swal from 'sweetalert2';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { OrgChart } from 'd3-org-chart';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import Multiselect from '@vueform/multiselect';
  import { DrawerComponent, MenuComponent } from '@/assets/ts/components/index';
  import SideDrawer from '@/components/SideDrawer.vue';

  import ConfigHierarchyNames from '@/components/HierarchyNames.vue';
  import ConfigRiskLevels from './FesConfigRiskLevels.vue';

  // import { useRouter } from 'vue-router';
  // import * as yup from 'yup';
  // import { hideModal } from '@/core/helpers/dom';
  import DecisionCatalogDrawer from '@/components/fes/DecisionCatalogDrawer.vue';
  import RiskCatalogDrawer from '@/components/fes/RiskCatalogDrawer.vue';
  import CauseCatalogDrawer from '@/components/fes/CauseCatalogDrawer.vue';
  import CauseReasonCatalogDrawer from '@/components/fes/CauseReasonCatalogDrawer.vue';
  import ProcessInterruptionCatalogDrawer from '@/components/fes/ProcessInterruptionCatalogDrawer.vue';
  import ProductCategoryCatalogDrawer from '@/components/fes/ProductCategoryCatalogDrawer.vue';
  import {
    computedDate,
    openSideDrawer,
    calculateDepth,
    getNodePath,
    isReadyOrgUnit,
    getNearestCatalog,
  } from '@/core/helpers/cdf';
  import {
    OrgChartDataPairWithCatalogs,
    ErrorCaseCDFName,
    FESConfig,
    RiskLevel,
    RiskLevelEdit,
    RiskLevelWithUsers,
    RiskLevelCatalog,
  } from '@/core/models/fes';
  import { OrgChartDataPair } from '@/core/models/cdf';

  import { User } from '@/store/modules/AuthModule';

  interface Option {
    key: string;
    label: string;
  }

  export default defineComponent({
    name: 'viewOrgChart',
    components: {
      // ErrorMessage,
      SideDrawer,
      // SimpleModal,
      DecisionCatalogDrawer,
      RiskCatalogDrawer,
      CauseCatalogDrawer,
      CauseReasonCatalogDrawer,
      Multiselect,
      ProcessInterruptionCatalogDrawer,
      ProductCategoryCatalogDrawer,
      OrgUnitPath,
      ConfigHierarchyNames,
      ConfigRiskLevels,
    },
    setup() {
      const enableProcessInterruption = isProcessInterruptionsEnabled();
      const { t } = useI18n();
      const store = useStore();

      const riskLevelUserMultiselects = ref([]);

      const data: {
        pageTitle: string;
        orgChartData: OrgChartDataPairWithCatalogs[];
        orgChart: any;
        orgChartTmpId: number;
        currentNode: OrgChartDataPairWithCatalogs | null;
        errorCaseCdfNames: ErrorCaseCDFName[];
        riskLevels: RiskLevelEdit[];
        currentRiskLevels: RiskLevelWithUsers[];
        allGroups: string[];
        currentSelectableUsersOriginal: User[];
        currentSelectableUsers: Option[];
        currentSelectedUsers: string[];
      } = reactive({
        pageTitle: t('viewOrgChart'),
        orgChartData: [] as OrgChartDataPairWithCatalogs[],
        orgChart: new OrgChart(),
        orgChartTmpId: 1,
        currentNode: null,
        errorCaseCdfNames: [] as ErrorCaseCDFName[],
        riskLevels: [] as RiskLevelEdit[],
        currentRiskLevels: [] as RiskLevelWithUsers[],
        allGroups: [] as string[],
        currentSelectableUsersOriginal: [] as User[],
        currentSelectableUsers: [] as Option[],
        currentSelectedUsers: [] as string[],
      });

      const computedRiskLevelCatalogs = computed(() => {
        if (data.currentNode === null) return [];

        return data.currentNode.riskLevelCatalogs
          .map((riskLevelCatalog: RiskLevelCatalog) => {
            riskLevelCatalog.users = riskLevelCatalog.users.filter((user) => {
              return user !== null;
            });
            riskLevelCatalog.userIds = riskLevelCatalog.userIds.filter(
              (loopedUserId) => {
                return (
                  riskLevelCatalog.users.findIndex((availableUser: User) => {
                    return availableUser.id === loopedUserId;
                  }) !== -1
                );
              },
            );
            riskLevelCatalog.usersToSelect = riskLevelCatalog.users;
            return riskLevelCatalog;
          })
          .slice(0)
          .sort(
            (
              riskLevelCatalogA: RiskLevelCatalog,
              riskLevelCatalogB: RiskLevelCatalog,
            ) => {
              return riskLevelCatalogA.riskValue - riskLevelCatalogB.riskValue;
            },
          );
      });

      function getResponsibles(riskLevelCatalogs: RiskLevelCatalog[]): string {
        const outputArray: string[] = [];

        const outputRiskLevelCatalogs: RiskLevelCatalog[] = data.riskLevels.map(
          (riskLevel: RiskLevel) => {
            const filledRiskLevelCatalog = riskLevelCatalogs.find(
              (riskLevelCatalog: RiskLevelCatalog) => {
                return riskLevelCatalog.riskValue === riskLevel.level;
              },
            );

            if (filledRiskLevelCatalog) return filledRiskLevelCatalog;

            return {
              riskValue: riskLevel.level,
              orgUnitId: data.currentNode?.id,
              userIds: [],
              users: [],
              usersToSelect: [],
            } as RiskLevelCatalog;
          },
        );

        outputRiskLevelCatalogs
          .sort(
            (
              riskLevelCatalogA: RiskLevelCatalog,
              riskLevelCatalogB: RiskLevelCatalog,
            ) => {
              return riskLevelCatalogA.riskValue - riskLevelCatalogB.riskValue;
            },
          )
          .filter((riskLevelCatalog: RiskLevelCatalog) => {
            return computedIsNeededRiskLevel.value(riskLevelCatalog);
          })
          .forEach((riskLevelCatalog: RiskLevelCatalog) => {
            const responsibles = riskLevelCatalog.users
              .filter((user: User) => {
                return user !== null;
              })
              .map((user: User): string => {
                return user.name;
              });
            let responsibleText = t('none');
            if (responsibles.length) {
              responsibleText = responsibles.join(', ');
            }

            let badgeClass = 'primary';
            if (riskLevelCatalog.riskValue === 2) {
              badgeClass = 'warning';
            }
            if (riskLevelCatalog.riskValue > 2) {
              badgeClass = 'danger';
            }

            outputArray.push(
              `<div class="mb-3"><span class="badge badge-${badgeClass} ${
                responsibles.length === 0 ? 'opacity-25' : ''
              }">${riskLevelCatalog.riskValue}</span> ${responsibleText}</div>`,
            );
          });

        if (outputArray.length === 0) {
          outputArray.push('<span>Keine Verantwortlichen</span>');
        }
        // const responsiblesStringElements: string[] = [];

        // responsibles.forEach((responsible) => {
        //   responsiblesStringElements.push(
        //     `<span class="badge-light-danger badge fs-7 fw-bolder" title="Username: ${responsible.username}">${responsible.name} • ✉ ${responsible.mail}</span>`,
        //   );
        // });

        // if (responsibles.length === 0)
        //   return (
        //     '<small class="text-muted">' + t('noResponsiblePeople') + '</small>'
        //   );

        return outputArray.join('');
      }

      function getCatalogsHtml(
        orgUnit: OrgChartDataPairWithCatalogs,
        orgUnits: OrgChartDataPairWithCatalogs[],
      ): CatalogBubbleConfig {
        const result: CatalogBubbleConfig = {
          riskCatalog: '',
          decisionCatalog: '',
          causeCatalog: '',
          causeReasonCatalog: '',
          productCategoryCatalog: '',
        };

        const nameArray: CatalogBubbleConfig = {
          riskCatalog: 'RK',
          decisionCatalog: 'EK',
          causeCatalog: 'UK',
          causeReasonCatalog: 'VK',
          productCategoryCatalog: 'PK',
        };

        if (isProcessInterruptionsEnabled()) {
          result.processInterruptionCatalog = '';
          nameArray.processInterruptionCatalog = 'PUK';
        }

        const countPath = {
          riskCatalog: 'locationRisks',
          processInterruptionCatalog: 'subSubCategories',
          decisionCatalog: 'decisions',
          causeCatalog: 'causes',
          causeReasonCatalog: 'causeReasons',
          productCategoryCatalog: 'subCategories',
        };

        Object.keys(result).forEach((catalogKey: string) => {
          const titleSlug =
            catalogKey.charAt(0).toUpperCase() + catalogKey.slice(1);

          if (orgUnit[catalogKey] !== null) {
            result[catalogKey] = renderIndividualCatalogBubble(
              nameArray[catalogKey],
              t(`fes.config.individual${titleSlug}`),
              orgUnit[catalogKey][countPath[catalogKey]].length,
            );
          } else {
            const nearestCatalog = getNearestCatalog(
              orgUnit,
              orgUnits,
              catalogKey,
            );
            if (nearestCatalog !== false) {
              result[catalogKey] = renderInheritCatalogBubble(
                nameArray[catalogKey],
                t(`fes.config.inherit${titleSlug}`),
              );
            } else {
              result[catalogKey] = renderUnsetCatalogBubble(
                nameArray[catalogKey],
                t(`fes.config.no${titleSlug}`),
              );
            }
          }
        });
        return result;
      }

      const computedOrgChartData = computed(() => {
        return data.orgChartData.slice(0) || [];
      });

      // function closeSideDrawer(id: string) {
      //   const drawerInstance = DrawerComponent.getInstance(id);
      //   if (typeof drawerInstance !== 'undefined') {
      //     drawerInstance.hide();
      //   }
      // }

      const computedDrawerTitle = computed(() => {
        if (data.currentNode === null) return '';
        return data.currentNode.name;
      });

      async function updateUserSelect() {
        const response = await ApiService.post('users/find', {
          data: {
            query: '*',
          },
        });

        data.currentSelectableUsersOriginal = response.data;
        data.currentSelectableUsers = response.data
          .filter((user: User) => {
            return user.groups.includes(
              'Sw_DC-LT_W_P_DEVIATION_7210_QM_MEASURE_local',
            );
          })
          .map((user: User): Option => {
            return {
              key: user.id,
              label: user.name,
            };
          });
      }

      const computedSelectableUsers = computed(() => {
        if (data.currentNode === null) return [];
        return data.currentSelectableUsers.filter((user: Option) => {
          return !isUserAlreadyAssignedAbove(user.key);
        });
      });

      function isUserAlreadyAssignedAbove(userId: string, offset = 0) {
        if (data.currentNode === null) return false;
        const nodePath = getNodePath(data.currentNode, data.orgChartData).slice(
          offset,
        );
        nodePath.pop();
        return nodePath.some((orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.userAreas.includes(userId);
        });
      }

      const handleWorkerChange = async (newUserIds: string[]) => {
        if (data.currentNode === null) return;
        const response = await ApiService.post('fes/config/userarea', {
          data: {
            orgUnitId: data.currentNode.id,
            userIds: newUserIds,
          },
        });
        data.currentNode.userAreas = response.data;
      };

      async function handleNodeClick(nodeId) {
        const currentNode = data.orgChartData.find((item: OrgChartDataPair) => {
          return item.id === nodeId;
        });

        if (typeof currentNode !== 'undefined') {
          data.currentNode = {} as OrgChartDataPairWithCatalogs;
          if (typeof currentNode !== 'undefined') {
            data.currentNode = currentNode;

            data.riskLevels
              .filter((riskLevel: RiskLevel) => {
                return riskLevel.needsDecision === true;
              })
              .forEach((riskLevel: RiskLevel) => {
                if (data.currentNode === null) return;
                const alreadyExistentRiskLevelCatalog =
                  data.currentNode.riskLevelCatalogs.find(
                    (riskLevelCatalog: RiskLevelCatalog) => {
                      return riskLevelCatalog.riskValue === riskLevel.level;
                    },
                  );

                if (typeof alreadyExistentRiskLevelCatalog === 'undefined') {
                  data.currentNode.riskLevelCatalogs.push({
                    orgUnitId: data.currentNode.id,
                    riskValue: riskLevel.level,
                    userIds: [],
                    users: [],
                    usersToSelect: [],
                  });
                } else {
                  alreadyExistentRiskLevelCatalog.usersToSelect =
                    alreadyExistentRiskLevelCatalog.users.slice(0);
                }
              });

            // data.currentRiskLevels = data.riskLevels
            //   .filter((riskLevel: RiskLevel) => {
            //     return riskLevel.needsDecision === true;
            //   })
            //   .map((riskLevel: RiskLevel) => {
            //     return {
            //       id: riskLevel.id,
            //       level: riskLevel.level,
            //       needsDecision: riskLevel.needsDecision,
            //       userIds: [],
            //       users: [],
            //       usersToSelect: [],
            //     } as RiskLevelWithUsers;
            //   });
          }
        }

        openSideDrawer('fesConfigDrawer');
      }

      const openDecisionCatalog = () => {
        openSideDrawer('fesConfigDrawerDescisionCatalog');
      };

      function getInfoBox(item: OrgChartDataPairWithCatalogs, orgChartData) {
        // if (item.parentId === '') return '';
        return `<div class="info-box">
        <span class="badge badge-circle badge-light me-1" title="${t('depth')}">
          ${calculateDepth(item, orgChartData) + 1}
        </span>
        ${getNodeStateBadge(item, orgChartData)}
      </div>`;
      }

      function getNodeStateBadge(
        orgUnit: OrgChartDataPairWithCatalogs,
        orgUnits: OrgChartDataPairWithCatalogs[],
      ) {
        const stateClass = isReadyOrgUnit(orgUnit, orgUnits, data.riskLevels)
          ? 'badge-success'
          : 'badge-warning';
        const icon = isReadyOrgUnit(orgUnit, orgUnits, data.riskLevels)
          ? '<i class="fa fa-check"></i>'
          : '<i class="fa fa-exclamation"></i>';

        return `<span class="badge badge-circle ${stateClass}">
          ${icon}
        </span>`;
      }

      type CatalogBubbleConfig = {
        decisionCatalog: string;
        causeCatalog: string;
        causeReasonCatalog: string;
        riskCatalog: string;
        productCategoryCatalog: string;
        processInterruptionCatalog?: string;
      };

      function renderIndividualCatalogBubble(
        name: string,
        title: string,
        amount: number,
      ) {
        return `<div class="alert alert-success bg-success d-flex align-items-center p-4 mb-10" title="${title}">
              <span class="position-absolute top-0 start-100 translate-middle badge badge-sm badge-circle badge-secondary">${amount}</span>
              <span class="svg-icon svg-icon-2hx svg-icon-success">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
            <div class="d-flex flex-column">
              <h4 class="mb-0 text-light">${name}</h4>
            </div>
          </div>`;
      }
      function renderUnsetCatalogBubble(name: string, title: string) {
        return `<div class="alert alert-secondary bg-secondary d-flex align-items-center p-4 mb-10" title="${title}">
              <span class="svg-icon svg-icon-2hx svg-icon-secondary">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
            <div class="d-flex flex-column">
              <h4 class="mb-0 text-muted">${name}</h4>
            </div>
          </div>`;
      }
      function renderInheritCatalogBubble(name: string, title: string) {
        return `<div class="alert bg-light-primary border border-primary border-1 border-dashed d-flex align-items-center p-4 mb-10" title="${title}">
              <span class="svg-icon svg-icon-2hx svg-icon-primary ">
                <inline-svg src="media/icons/duotune/general/gen048.svg" />
              </span>
            <div class="d-flex flex-column">
              <h4 class="mb-0 text-primary ">${name}</h4>
            </div>
          </div>`;
      }

      async function renderChart() {
        data.orgChart
          .container('#fesCdfNamesConfigChart')
          .data(computedOrgChartData.value.slice(0))
          .nodeWidth(() => 400)
          .nodeHeight((node) => {
            if (node.depth === 3) return 370;
            return 250;
          })
          .buttonContent(({ node }) => {
            return `<a href="javascript:" class="nodeMoreButton">${
              node.children
                ? `<i class="fas fa-chevron-up"></i>`
                : `<i class="fas fa-chevron-down"></i>`
            } ${node.data._directSubordinates}</a>`;
          })
          .onNodeClick((d) => handleNodeClick(d.id))
          .nodeContent((d) => {
            const responsiblesHtml =
              calculateDepth(d.data, data.orgChartData) === 3
                ? getResponsibles(d.data.riskLevelCatalogs)
                : '';
            const cdfNameHtml = getCdfNameHtml(d.data);
            const catalogsHtml: CatalogBubbleConfig = getCatalogsHtml(
              d.data,
              data.orgChartData,
            );

            return `<div class="orgChartNode"
    style="border-radius:2px;overflow:visible"
  >
    <div
      class="orgChartNodeContainer"
    >
      ${getInfoBox(d.data, data.orgChartData)}
      
      <div
        style="background-color:#00CCFF;height:10px;width:100%;border-radius:1px"
      ></div>

      ${cdfNameHtml}
      <div style="padding: 16px; text-align: center">
        <div style="color: #002B49; font-size: 16px; font-weight: bold">
          ${d.data.name}
        </div>
      </div>

      ${
        responsiblesHtml !== ''
          ? `<div class="row nodeResponsibles">
              <div class="py-3 col">
                ${responsiblesHtml}
              </div>
            </div>`
          : ''
      }
      

      <div class="p-3" >
        <div class="col-12 mb-3">
          <strong>${t('fes.config.catalogs')}</strong>
        </div>
        <div class="d-flex justify-content-between">
          ${catalogsHtml.productCategoryCatalog}
          ${catalogsHtml.riskCatalog}
          ${
            enableProcessInterruption
              ? catalogsHtml.processInterruptionCatalog
              : ''
          }
          ${catalogsHtml.decisionCatalog}
          ${catalogsHtml.causeCatalog}
          ${catalogsHtml.causeReasonCatalog}
        </div>
      </div>

    </div>
  </div>`;
          })
          .render();
      }

      function getCdfNameHtml(orgUnit: OrgChartDataPair) {
        const level = calculateDepth(orgUnit, data.orgChartData);

        if (typeof data.errorCaseCdfNames[level] === 'undefined') return '';
        return `<div class="row nodeResponsibles">
        <div class="py-3 pb-0 col text-center">
          <span class="badge badge-light-primary">${data.errorCaseCdfNames[level].name}</span>
        </div>
      </div>`;
      }

      async function updateOrgChartData() {
        data.orgChart.data(computedOrgChartData.value);
        data.orgChart.render();
      }

      const computedNodePathString = computed(() => {
        if (computedNodePath.value.length === 1) return '';
        return computedNodePath.value
          .map((node) => {
            return node.name;
          })
          .join(' > ');
      });

      const computedNodePath = computed((): OrgChartDataPairWithCatalogs[] => {
        if (data.currentNode === null) return [];
        return getNodePath(data.currentNode, data.orgChartData).sort(
          (
            orgUnit1: OrgChartDataPairWithCatalogs,
            orgUnit2: OrgChartDataPairWithCatalogs,
          ) => {
            return calculateDepth(orgUnit1, data.orgChartData) >
              calculateDepth(orgUnit2, data.orgChartData)
              ? 1
              : -1;
          },
        );
      });

      async function loadOrgChart() {
        store.dispatch(Actions.START_LOADER);

        try {
          data.orgChartData = await store.dispatch(Actions.GET_ORG_CHART);

          const allDepths = data.orgChartData.map((orgUnit) => {
            return calculateDepth(orgUnit, data.orgChartData);
          });

          const fesConfig: FESConfig = await store.dispatch(
            Actions.GET_FES_CONFIG,
          );

          data.errorCaseCdfNames = fesConfig.hierarchyLevels.slice(0);

          const maxDepth = Math.max(...allDepths) + 1;

          // data.errorCaseCdfNames = [];
          data.errorCaseCdfNames = fesConfig.hierarchyLevels.slice(0);
          for (let i = 0; i < maxDepth; i++) {
            if (typeof data.errorCaseCdfNames[i] === 'undefined') {
              data.errorCaseCdfNames.push({
                name: '',
                pos: i + 1,
              });
            }
          }

          data.riskLevels = fesConfig.riskLevels
            .slice(0)
            .map((riskLevel: RiskLevel) => {
              return Object.assign(riskLevel, {
                isNew: false,
              }) as RiskLevelEdit;
            });

          data.allGroups = fesConfig.allGroups;

          renderChart();
        } catch (error) {
          Swal.fire('Error', 'Not able to load Org Chart.', 'error');
        }

        store.dispatch(Actions.END_LOADER);
      }

      function initChart() {
        data.orgChartTmpId++;
        data.orgChartData = [];
        data.orgChart = new OrgChart();
        data.errorCaseCdfNames = [] as ErrorCaseCDFName[];
      }

      function handleCatalogUpdate(
        orgChartData: OrgChartDataPairWithCatalogs[],
      ) {
        data.orgChartData = orgChartData.slice(0);
        store.commit(Mutations.SET_ORG_CHART, data.orgChartData);
        updateOrgChartData();
      }

      function handleUserAdd(userId: string, riskLevel: RiskLevelCatalog) {
        const userObjectToAdd = riskLevel.usersToSelect.find((user: User) => {
          return user.id === userId;
        });
        if (userObjectToAdd) {
          riskLevel.users.push(userObjectToAdd);
        }
      }
      function handleUserRemove(userId: string, riskLevel: RiskLevelCatalog) {
        // riskLevel.usersToSelect = [];
        riskLevel.userIds = riskLevel.userIds.filter((loopedUserId: string) => {
          return loopedUserId !== userId;
        });
        riskLevel.usersToSelect = riskLevel.users = riskLevel.users.filter(
          (user: User) => {
            return user.id !== userId;
          },
        );
      }

      async function handleRiskLevelChange(riskLevelCatalog: RiskLevelCatalog) {
        store.dispatch(Actions.START_LOADER);
        setTimeout(async () => {
          if (data.currentNode === null) return;
          const riskLevelCatalogToUpdate = {
            id: riskLevelCatalog.id,
            orgUnitId: data.currentNode?.id,
            riskValue: riskLevelCatalog.riskValue,
            userIds: riskLevelCatalog.userIds,
          };
          const response = await ApiService.post(
            'fes/config/catalog/risklevel',
            {
              data: riskLevelCatalogToUpdate,
            },
          );

          riskLevelCatalog.id = response.data.id;
          if (riskLevelCatalog.userIds.length === 0) {
            riskLevelCatalog.usersToSelect = [];
            riskLevelCatalog.userIds = [];
            riskLevelCatalog.users = [];
          }

          store.dispatch(Actions.END_LOADER);
          updateOrgChartData();
        }, 250);
      }

      async function handleUserSearch(
        search: string,
        riskLevelCatalog: RiskLevelCatalog,
        riskValue: number,
      ) {
        if (search.length < 1) return;

        const response = await ApiService.post('users/find/decider', {
          data: {
            query: search,
            riskLevel: riskValue,
          },
        });

        riskLevelCatalog.usersToSelect = [...response.data];

        riskLevelUserMultiselects.value.forEach(
          (riskLevelUserMultiselect: Ref) => {
            riskLevelUserMultiselect.value?.refreshOptions();
          },
        );
      }

      const computedIsNeededRiskLevel = computed(() => {
        return (riskLevelCatalog: RiskLevelCatalog) => {
          const riskLevel = data.riskLevels.find((riskLevel: RiskLevel) => {
            return riskLevel.level === riskLevelCatalog.riskValue;
          });

          if (riskLevel) {
            return riskLevel.needsDecision;
          }

          return false;
        };
      });

      // function initRiskLevelUsersOptions(users = [] as User[], index: number) {
      //   if (!data.currentOptions[index]) {
      //     data.currentOptions[index] = Object.assign({}, emptyOpenPointOptions);
      //   }
      //   data.currentOptions[index].assignees.length = 0;
      //   data.currentOptions[index].assignees = users || [];
      // }

      onMounted(async () => {
        initChart();
        DrawerComponent.reinitialization();
        MenuComponent.reinitialization();
        loadOrgChart();
        updateUserSelect();
      });

      return {
        data,
        handleUserAdd,
        handleUserRemove,
        handleRiskLevelChange,
        handleUserSearch,
        updateOrgChartData,
        computedOrgChartData,
        computedDrawerTitle,
        computedRiskLevelCatalogs,
        openSideDrawer,
        openDecisionCatalog,
        computedNodePath,
        computedNodePathString,
        handleCatalogUpdate,
        riskLevelUserMultiselects,
        // computedRiskLevelUserOptions,
        computedDate,
        calculateDepth,
        // computedCurrentResponsibles,
        loadOrgChart,
        computedIsNeededRiskLevel,
        enableProcessInterruption,
        computedSelectableUsers,
        handleWorkerChange,
      };
    },
  });
</script>

<style lang="scss">
  .fes-config-container {
    #fesCdfNamesConfigChart {
      width: 100%;
      height: 1200px;
      overflow: hidden;
      background-color: #fcfcfc;
    }
    .orgChartNode {
      width: 400px !important;
      height: 250px;
    }
    .orgChartNodeContainer {
      height: inherit;
      border-radius: 10px;
      overflow: hidden;
    }
    .nodeMoreButton {
      width: 100%;
      text-align: center;
      border-radius: 3px;
      padding: 10px 5px;
      font-size: 12px;
      margin: auto auto;
      background-color: white;
      border: 1px solid lightgray;
    }
    .node-button-foreign-object:hover a.nodeMoreButton {
      border-color: lightblue;
    }
    .orgChartNodeContainer {
      padding-top: 0px;
      background-color: white;
      border: 1px solid lightgray;
    }
    g {
      cursor: default;
    }
    g.node-button-g,
    .orgChartNode {
      cursor: pointer;
    }
    .nodeTags,
    .nodeResponsibles {
      padding: 0 10px;
    }
    .info-box {
      display: flex;
      justify-content: flex-end;
      width: 64px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
    }
    .badge.badge-circle.badge-warning i,
    .badge.badge-circle.badge-success i {
      color: white;
    }
    .multiselect {
      padding: 0.66rem;
    }
    .node-foreign-object[height='370'] .orgChartNode {
      height: 370px;
    }
    /* .ekNode {
    background-color: #002b49;
  }
  .pukNode {
    background-color: #df0024;
  }
  .rkNode {
    background-color: #00ccff;
  } */
  }
</style>
