<template>
  <div>
    <div v-if="computedIsLoadedError">
      <div class="col-12">
        <div class="d-flex align-items-center mb-6">
          <div class="d-block me-3">
            <router-link
              :to="computedBackLink"
              class="btn btn-secondary btn-sm"
            >
              <i class="fa fa-chevron-left"></i>
            </router-link>
          </div>
          <div>
            <h3 class="fw-bolder my-2">
              {{ $t('fes.form.errorCase') }}
              <span class="fs-6 text-gray-400 fw-bold ms-1"
                >#{{ data.errorCase.id }}</span
              >
            </h3>
            <small>{{ computedTime(data.errorCase.createdAt) }}</small>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!data.errorLocation.isLoading" class="row mb-3">
      <div class="col" v-if="isErrorLocationComplete && !computedIsLoadedError">
        <org-unit-path
          :node-path="selectedOrgLevels"
          :area-names="data.errorCaseCdfNames"
        >
          <button
            v-if="data.currentStep === 1"
            type="button"
            class="btn btn-light-primary btn-icon position-absolute position-sm-relative top-0 end-0 ms-sm-auto me-2"
            @click="releaseErrorLocationForm"
          >
            <span class="svg-icon svg-icon-1">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </span>
          </button>
        </org-unit-path>
      </div>
      <div
        class="col"
        v-if="!computedHasErrorCaseIdParam && !isErrorLocationComplete"
      >
        <div class="card">
          <div class="card-header border-0">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3">{{
                $t('fes.form.errorLocation')
              }}</span>
            </h3>
            <button class="btn btn-sm btn-secondary" @click="resetLocation">
              {{ $t('reset') }}
            </button>
          </div>

          <div class="card-body py-3">
            <loading
              v-model:active="data.isOrgLevelsLoading"
              :can-cancel="false"
              :is-full-page="false"
            />
            <div class="row" v-if="!computedHasValidConfig">
              <div class="col">
                <div
                  class="alert alert-danger d-flex align-items-center p-5 mb-10"
                >
                  <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                    <inline-svg src="media/icons/duotune/general/gen025.svg" />
                  </span>
                  <div class="d-flex flex-column">
                    <h4 class="mb-1 text-danger">
                      {{ $t('fes.config.missing.title') }}
                    </h4>
                    <span>{{ $t('fes.config.missing.text') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="row mb-6"
              v-for="(orgLevel, index) of data.orgLevels"
              :key="`${orgLevel}${index}`"
            >
              <label class="col-lg-4 col-form-label required fw-bold fs-6">{{
                computedOrgLevelHierarchy(index)
              }}</label>

              <div class="col-lg-8 fv-row">
                <Field
                  as="select"
                  name="orgUnit"
                  class="form-select form-select-solid form-select-lg"
                  v-model="data.orgLevels[index]"
                  @change="handleOrgUnitChange(index)"
                >
                  <option selected value="all" v-if="index !== 0">
                    {{ $t('all') }}
                  </option>
                  <option
                    v-for="(orgOption, orgIndex) of computedOrgOptions(index)"
                    :key="orgIndex"
                    :value="orgOption.id"
                  >
                    {{ orgOption.name }}
                  </option>
                </Field>
              </div>
            </div>
          </div>

          <div
            v-if="
              computedHasValidConfig &&
              data.errorLocation.isReady &&
              !data.errorLocation.isComplete
            "
            class="card-footer d-flex justify-content-end py-6 px-9"
          >
            <button
              @click="handleErrorLocationChange"
              :disabled="!computedIsOrgUnitSelectable"
              type="submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> {{ $t('continue') }}</span>
            </button>
          </div>

          <div v-if="data.errorLocation.isComplete" class="card-body py-3">
            <div
              class="row mb-6"
              v-for="(orgLevel, index) of selectedOrgLevels"
              :key="index"
            >
              <div class="col-lg-4 fw-bold fs-6">
                {{ computedOrgLevelHierarchy(index) }}
              </div>

              <div class="col-lg-8 fv-row">
                <span>{{ orgLevel.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        (isErrorLocationComplete || computedIsLoadedError) && !isErrorCaseNull
      "
      class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
    >
      <!--begin::Aside-->
      <div
        class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9"
      >
        <!--begin::Wrapper-->
        <div class="px-6 px-lg-10 px-xxl-12 py-20">
          <!--begin::Nav-->
          <div class="stepper-nav col-xxl-3 position-xxl-fixed w-xxl-300px">
            <a
              v-for="step of data.steps"
              :key="step.id"
              href="javascript:"
              @click="goToStep(step.id, false)"
              :class="computedStepNavClass(step.id)"
              v-show="computedStepNavVisible(step.id)"
              data-kt-stepper-element="nav"
            >
              <!--begin::Line-->
              <div class="stepper-line w-40px"></div>
              <!--end::Line-->

              <!--begin::Icon-->
              <div class="stepper-icon w-40px h-40px">
                <i class="stepper-check fas fa-check"></i>
                <span class="stepper-number">{{
                  computedStepIndexNumber(step.id)
                }}</span>
              </div>
              <!--end::Icon-->

              <!--begin::Label-->
              <div class="stepper-label">
                <h3 class="stepper-title">{{ step.name }}</h3>

                <div class="stepper-desc fw-bold">
                  {{ step.detail }}
                </div>
              </div>
              <!--end::Label-->
            </a>
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--begin::Aside-->

      <div class="w-100">
        <div class="col" v-if="computedIsLoadedError">
          <simple-org-unit-path
            :node-path="data.errorCase.locations"
            :area-names="data.errorCaseCdfNames"
          />
        </div>
        <div
          v-if="hasCompletedStep1"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.steps.1.title')
                }}</span>
              </h3>
              <button
                v-if="computedUserIsDecider"
                class="btn btn-sm btn-secondary"
                @click="startEditStep(1)"
              >
                {{ $t('edit') }}
              </button>
            </div>

            <div class="card-body py-3">
              <div class="row" v-if="data.errorCase.detail.materialName">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.materialName')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  {{ data.errorCase.detail.materialName }}
                </div>
              </div>
              <div
                class="row"
                v-if="
                  data.errorCase.detail.materialReference ||
                  data.errorCase.detail.faufReference
                "
              >
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.materialReference')
                  }}</label>
                </div>
                <div class="col-9 col-lg-3 d-flex align-items-center">
                  {{ data.errorCase.detail.materialReference }}
                </div>
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.faufReference')
                  }}</label>
                </div>
                <div class="col-9 col-lg-3 d-flex align-items-center">
                  {{ data.errorCase.detail.faufReference }}
                </div>
              </div>

              <div class="row" v-if="data.errorCase.productCategory">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.productCategory')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  {{ data.errorCase.productCategory.name }}
                </div>
              </div>
              <div class="row" v-if="data.errorCase.productSubCategory">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.productSubCategory')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  {{ data.errorCase.productSubCategory.name }}
                </div>
              </div>

              <div
                class="row"
                v-if="
                  data.errorCase.detail.checkReference ||
                  data.errorCase.detail.checkReference
                "
              >
                <div class="col-3" v-if="data.errorCase.detail.checkReference">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.checkReference')
                  }}</label>
                </div>
                <div
                  class="col-9 col-lg-3 d-flex align-items-center"
                  v-if="data.errorCase.detail.checkReference"
                >
                  {{ data.errorCase.detail.checkReference }}
                </div>
                <div class="col-3" v-if="data.errorCase.detail.sapReference">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.sapReference')
                  }}</label>
                </div>
                <div
                  class="col-9 col-lg-3 d-flex align-items-center"
                  v-if="data.errorCase.detail.sapReference"
                >
                  {{ data.errorCase.detail.sapReference }}
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12">
                  <h4>{{ $t('fes.form.details.amounts') }}</h4>
                </div>
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.amountTotal')
                  }}</label>
                </div>
                <div class="col-9 col-lg-3 d-flex align-items-center">
                  {{ data.errorCase.detail.amountTotal }}
                </div>

                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.amountChecked')
                  }}</label>
                </div>
                <div class="col-9 col-lg-3 d-flex align-items-center">
                  {{ data.errorCase.detail.amountChecked }}
                </div>

                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.details.amountFlawed')
                  }}</label>
                </div>
                <div class="col-9 col-lg-3 d-flex align-items-center">
                  {{ data.errorCase.detail.amountFlawed }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasCompletedStep2"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.steps.2.title')
                }}</span>
              </h3>
              <button
                v-if="computedUserIsDecider"
                class="btn btn-sm btn-secondary"
                @click="startEditStep(2)"
              >
                {{ $t('edit') }}
              </button>
            </div>

            <div class="card-body py-3">
              <div class="row">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.category')
                  }}</label>
                </div>
                <div
                  v-if="data.errorCase.locationRisk.style"
                  class="col-9 d-flex align-items-center"
                >
                  <span-with-deleted-state
                    :isDeleted="
                      data.errorCase.locationRisk.style.category.isDeleted
                    "
                    >{{ data.errorCase.locationRisk.style.category.name }}
                  </span-with-deleted-state>
                </div>
                <div v-else>
                  {{ $t('unknown') }}
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.errorPlace')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  <span-with-deleted-state
                    v-if="data.errorCase.locationRisk.place"
                    :isDeleted="data.errorCase.locationRisk.place.isDeleted"
                    >{{ data.errorCase.locationRisk.place.name }}
                  </span-with-deleted-state>
                  <span v-else>{{ $t('unknown') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.errorStyle')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  <span-with-deleted-state
                    v-if="data.errorCase.locationRisk.style"
                    :isDeleted="data.errorCase.locationRisk.style.isDeleted"
                    >{{ data.errorCase.locationRisk.style.name }}
                  </span-with-deleted-state>
                  <span v-else>{{ $t('unknown') }}</span>
                  <span
                    class="badge badge-primary ms-3"
                    v-if="
                      isCurrentUserDeciderForLocation && computedIsLoadedError
                    "
                    >{{ $t('fes.form.riskLevel') }}:
                    {{ data.errorCase.locationRisk.riskValue }}</span
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.moreDetails.description')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  <span v-if="data.errorCase.detail.description">{{
                    data.errorCase.detail.description
                  }}</span>
                  <span v-else>{{ $t('none') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasCompletedStep3"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.barcodes')
                }}</span>
              </h3>
            </div>

            <div class="card-body py-3">
              <div class="row mb-5">
                <div class="col-3">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.barcodes')
                  }}</label>
                </div>
                <div class="col-9 d-flex align-items-center">
                  <ul class="fs-6 fw-bold me-3 pt-3 default-list">
                    <li
                      v-for="barcode in data.errorCase.barcodes"
                      :key="barcode.code"
                    >
                      {{ barcode.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasCompletedStep4"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.steps.4.title')
                }}</span>
              </h3>
              <div
                v-if="
                  data.errorCase.processInterruption !== null &&
                  isCurrentUserDeciderForLocation
                "
                class="card-toolbar"
              >
                <span
                  v-if="data.errorCase.processInterruption.state === 'open'"
                  class="badge badge-primary"
                  >{{ $t('fes.processInterruption.states.open') }}</span
                >
                <span
                  v-if="data.errorCase.processInterruption.state === 'accepted'"
                  class="badge badge-success"
                  >{{ $t('fes.processInterruption.states.accepted') }}</span
                >
                <span
                  v-if="data.errorCase.processInterruption.state === 'declined'"
                  class="badge badge-danger"
                  >{{ $t('fes.processInterruption.states.declined') }}</span
                >
                <div
                  class="ms-3"
                  v-if="data.errorCase.processInterruption.state === 'open'"
                >
                  <button
                    @click="acceptProcessInterruption()"
                    class="btn btn-icon btn-light btn-sm border-0 btn-active-light-primary"
                    :title="$t('fes.processInterruption.stateActions.accept')"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-check" />
                    </span>
                  </button>
                  <button
                    @click="declineProcessInterruption()"
                    class="btn btn-icon btn-light btn-sm border-0 ms-3 btn-active-light-primary"
                    :title="$t('fes.processInterruption.stateActions.decline')"
                  >
                    <span class="svg-icon svg-icon-5 m-0">
                      <i class="fa fa-times" />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body py-3 pb-5">
              <div
                class="row"
                v-if="data.errorCase.processInterruption !== null"
              >
                <div class="col-4">
                  <label class="col-form-label fw-bold fs-6">{{
                    $t('fes.form.processInterruption.category')
                  }}</label>
                </div>
                <div class="col-8 d-flex align-items-center">
                  <process-interruption-path
                    :processInterruption="data.errorCase.processInterruption"
                  />
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.downtimeMachine')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <div>
                        {{ data.errorCase.processInterruption.downtimeMachine }}
                        Minuten
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.downtimeWorker')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <div>
                        {{ data.errorCase.processInterruption.downtimeWorker }}
                        Minuten
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.reason')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        {{ data.errorCase.processInterruption.reason }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col">
                  <span>{{ $t('fes.form.processInterruption.none') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="computedShowSplitDecisions"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-end">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.decision.pastSplitDecisions')
                }}</span>
              </h3>
            </div>

            <div class="card-body py-3">
              <decision-single
                class="row mb-3 border border-secondary rounded border-2 p-3"
                v-for="(splitDecision, index) of computedSplitDecisions"
                :splitDecision="splitDecision"
                :errorCase="data.errorCase"
                :orgChart="data.orgChart"
                :key="index"
              />
            </div>
          </div>
        </div>

        <div
          v-if="computedHasSplitDecisionsToSave"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-end">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.decision.upcomingSplitDecisions')
                }}</span>
              </h3>
            </div>

            <div class="card-body py-3">
              <div
                class="alert alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen051.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-primary">
                    {{
                      $t('fes.form.decision.upcomingSplitDecisionAlertTitle')
                    }}
                  </h4>
                  <span>{{
                    $t('fes.form.decision.upcomingSplitDecisionAlertText')
                  }}</span>
                </div>
              </div>

              <div
                class="row mb-3 border border-primary rounded border-2 p-3"
                v-for="(
                  splitDecisionToSave, saveIndex
                ) of computedSplitDecisionsToSave"
                :key="saveIndex"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <span class="text-muted fw-bold fs-7"
                        >Entscheidung #{{
                          data.errorCase.splitDecisions.length + saveIndex + 1
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.causes.cause')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="splitDecisionToSave.splitCause.isDeleted"
                          >{{ splitDecisionToSave.splitCause.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.causes.causeReason')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="
                            splitDecisionToSave.splitCauseReason.isDeleted
                          "
                          >{{ splitDecisionToSave.splitCauseReason.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.decision.decision')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="
                            splitDecisionToSave.splitDecision.isDeleted
                          "
                          >{{ splitDecisionToSave.splitDecision.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.decision.splitAmountLabel')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        {{
                          splitDecisionToSave.amount ||
                          data.errorCase.detail.amountFlawed
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.decision.decider')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <span class="badge badge-light-primary">
                        <i class="bi bi-person-check me-2"></i>
                        {{ splitDecisionToSave.decider.name }} ({{
                          splitDecisionToSave.decider.username
                        }})
                      </span>
                      <span
                        v-if="splitDecisionToSave.decisionDate"
                        class="badge badge-light ms-3"
                      >
                        <i class="bi bi-clock-history me-2"></i>
                        {{ computedTime(splitDecisionToSave.decisionDate) }}
                      </span>
                      <span
                        v-if="splitDecisionToSave.decisionEdits > 0"
                        class="badge badge-warning text-gray-600 ms-3"
                      >
                        <i class="bi bi-pencil-fill me-2"></i>
                        {{ splitDecisionToSave.decisionEdits }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasCompletedStep5 && !computedHasSplitDecisions"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-end">
                <span class="card-label fw-bolder fs-3">{{
                  $t('fes.form.steps.5.title')
                }}</span>
              </h3>
              <div class="card-toolbar">
                <span
                  v-if="data.errorCase.decider === null"
                  class="badge badge-light-primary"
                >
                  <i class="bi bi-person-check me-2"></i>
                  {{ computedCurrentUser.name }} ({{
                    computedCurrentUser.username
                  }})
                </span>
                <span class="badge badge-light-primary" v-else>
                  <i class="bi bi-person-check me-2"></i>
                  {{ data.errorCase.decider.name }} ({{
                    data.errorCase.decider.username
                  }})
                </span>
                <span
                  v-if="data.errorCase.decisionDate"
                  class="badge badge-light ms-3"
                >
                  <i class="bi bi-clock-history me-2"></i>
                  {{ computedTime(data.errorCase.decisionDate) }}
                </span>
                <span
                  v-if="data.errorCase.decisionEdits > 0"
                  class="badge badge-warning text-gray-600 ms-3"
                >
                  <i class="bi bi-pencil-fill me-2"></i>
                  {{ data.errorCase.decisionEdits }}
                </span>
              </div>
            </div>

            <decision-single-deprecated
              v-if="!computedHasSplitDecisions"
              :errorCase="data.errorCase"
              :orgChart="data.orgChart"
              :selectedOrgLevels="selectedOrgLevels"
              :availableOrgLevels="computedAvailableOrgLevels"
            />
            <div class="card-body py-3" v-else>
              <div
                class="row mb-3"
                v-for="(splitDecision, index) of data.errorCase.splitDecisions"
                :key="index"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.causes.cause')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="splitDecision.splitCause.isDeleted"
                          >{{ splitDecision.splitCause.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="splitDecision.splitCauseReason">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.causes.causeReason')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="splitDecision.splitCauseReason.isDeleted"
                          >{{ splitDecision.splitCauseReason.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.decision.decision')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        <span-with-deleted-state
                          :isDeleted="splitDecision.splitDecision.isDeleted"
                          >{{ splitDecision.splitDecision.name }}
                        </span-with-deleted-state>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.decision.splitAmountLabel')
                      }}</label>
                    </div>
                    <div class="col-8 d-flex align-items-center pt-3">
                      <div>
                        {{ splitDecision.amount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasCompletedStep6"
          class="d-flex flex-row-fluid align-content-start bg-white rounded mb-5"
        >
          <div class="card w-100">
            <div class="card-header border-0">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3">{{
                  $t('opl.form.plural')
                }}</span>
              </h3>
            </div>

            <div class="card-body py-3">
              <div v-if="data.errorCase.openPoints.length === 0" class="row">
                <div class="col">
                  <p>{{ $t('fes.noTaksOnErrorCase') }}</p>
                </div>
              </div>
              <div v-else class="scroll-y me-n5 pe-5">
                <oplOpenPointOverview
                  :openPoints="data.errorCase.openPoints"
                  :stickyHeader="false"
                  :disableCheckbox="true"
                  :disableItemsPerPageDropdown="true"
                  :disableHeader="true"
                  cardClasses="p-0"
                  :errorCaseId="data.errorCase.id"
                />
              </div>
            </div>
          </div>
        </div>

        <!--begin::Content-->
        <div
          class="d-flex flex-row-fluid align-content-start bg-white rounded"
          v-if="!computedShowErrorForms"
        >
          <MyForm
            :validation-schema="errorCaseStep1Validator"
            @submit="handleStep1Submit"
            v-if="computedShowErrorFormStep1"
            v-show="data.currentStep === 1"
            novalidate="novalidate"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            action="javascript:"
            method="post"
            ref="step1Form"
          >
            <div class="current">
              <div class="row">
                <div class="col-12 mb-5">
                  <div class="card">
                    <div class="card-header border-0">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3">{{
                          $t('fes.form.steps.1.title')
                        }}</span>
                        <span class="text-muted fw-bold fs-7">{{
                          $t('fes.form.steps.1.detail')
                        }}</span>
                      </h3>
                      <div class="card-toolbar">
                        <div
                          class="ms-3 d-flex justify-content-center align-items-center"
                        >
                          <button
                            @click="
                              isBarcodeCodeScanActive = !isBarcodeCodeScanActive
                            "
                            type="button"
                            :class="`btn btn-ico btn-sm ${
                              isBarcodeCodeScanActive
                                ? 'btn-light-success'
                                : 'btn-light'
                            } btn-active-light-success`"
                          >
                            <i class="fa fa-barcode" aria-hidden="true"></i>
                            <span class="ms-1 me-2">{{
                              $t('fes.form.scanMode')
                            }}</span>
                            <span
                              class="badge badge-success"
                              v-if="isBarcodeCodeScanActive"
                              >{{ $t('fes.form.on') }}</span
                            >
                            <span class="badge badge-light-danger" v-else>{{
                              $t('fes.form.off')
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="card-body py-3">
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.details.materialReference')
                          }}</label>
                        </div>
                        <div
                          class="col-9 col-lg-3 d-flex align-items-start flex-column"
                        >
                          <Field
                            tabindex="1"
                            type="text"
                            name="materialReference"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.errorCase.detail.materialReference"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="materialReference" />
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.details.faufReference')
                          }}</label>
                        </div>
                        <div
                          class="col-9 col-lg-3 d-flex align-items-start flex-column"
                        >
                          <Field
                            tabindex="2"
                            type="text"
                            name="faufReference"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.errorCase.detail.faufReference"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="faufReference" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.moreDetails.productCategory')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="3"
                            as="select"
                            name="productCategory"
                            class="form-select form-select-solid form-select-lg"
                            v-model="data.errorCaseForm.productCategoryId"
                            @change="handleProductCategoryChange"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="productCategory in computedProductCategories"
                              :key="productCategory.id"
                              :value="productCategory.id"
                            >
                              {{ productCategory.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="productCategory" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.moreDetails.productSubCategory')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="3"
                            as="select"
                            name="productSubCategory"
                            class="form-select form-select-solid form-select-lg"
                            v-model="data.errorCaseForm.productSubCategoryId"
                            @change="handleProductSubCategoryChange"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="productSubCategory in computedProductSubCategories"
                              :key="productSubCategory.id"
                              :value="productSubCategory.id"
                            >
                              {{ productSubCategory.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="productSubCategory" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="data.errorCase.detail.materialName"
                        class="row mb-3"
                      >
                        <div class="col-3">
                          <label class="col-form-label fw-bold fs-6">{{
                            $t('fes.form.details.materialName')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="3"
                            type="text"
                            name="materialName"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.errorCase.detail.materialName"
                          />
                        </div>
                      </div>

                      <div class="row mb-5">
                        <div class="col-3">
                          <label class="col-form-label fw-bold fs-6">{{
                            $t('fes.form.details.checkReference')
                          }}</label>
                        </div>
                        <div
                          class="col-9 col-lg-3 d-flex align-items-start flex-column"
                        >
                          <Field
                            tabindex="6"
                            type="text"
                            name="checkReference"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.errorCase.detail.checkReference"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="checkReference" />
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="col-form-label fw-bold fs-6">{{
                            $t('fes.form.details.sapReference')
                          }}</label>
                        </div>
                        <div
                          class="col-9 col-lg-3 d-flex align-items-start flex-column"
                        >
                          <Field
                            tabindex="7"
                            type="text"
                            name="sapReference"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="data.errorCase.detail.sapReference"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="sapReference" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 class="mb-3">{{ $t('fes.form.details.amounts') }}</h4>
                      <div class="row mb-3">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-6">
                              <label
                                class="col-form-label required fw-bold fs-6"
                                >{{ $t('fes.form.details.amountTotal') }}</label
                              >
                            </div>
                            <div
                              class="col-6 d-flex align-items-start flex-column"
                            >
                              <Field
                                tabindex="8"
                                type="number"
                                name="amountTotal"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="data.errorCase.detail.amountTotal"
                              />
                              <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                  <ErrorMessage name="amountTotal" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-6">
                              <label
                                class="col-form-label required fw-bold fs-6"
                                >{{
                                  $t('fes.form.details.amountChecked')
                                }}</label
                              >
                            </div>
                            <div
                              class="col-6 d-flex align-items-start flex-column"
                            >
                              <Field
                                tabindex="9"
                                type="number"
                                name="amountChecked"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="data.errorCase.detail.amountChecked"
                              />
                              <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                  <ErrorMessage name="amountChecked" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-6">
                              <label
                                class="col-form-label required fw-bold fs-6"
                                >{{
                                  $t('fes.form.details.amountFlawed')
                                }}</label
                              >
                            </div>
                            <div
                              class="col-6 d-flex align-items-start flex-column"
                            >
                              <Field
                                tabindex="10"
                                type="number"
                                name="amountFlawed"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="data.errorCase.detail.amountFlawed"
                              />
                              <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                  <ErrorMessage name="amountFlawed" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <error-form-step-navigation
              :back-allowed="false"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <MyForm
            :validation-schema="errorCaseStep2Validator"
            @submit="handleStep2Submit"
            v-if="computedShowErrorFormStep1"
            v-show="data.currentStep === 2"
            novalidate="novalidate"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            action="javascript:"
            method="post"
            ref="step2Form"
          >
            <div class="current">
              <div class="row">
                <div class="col-12 mb-5">
                  <div class="card">
                    <div class="card-header border-0">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3">{{
                          $t('fes.form.steps.2.title')
                        }}</span>
                        <span class="text-muted fw-bold fs-7">{{
                          $t('fes.form.steps.2.detail')
                        }}</span>
                      </h3>
                    </div>

                    <div class="card-body py-3">
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.moreDetails.locationRiskCategory')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="7"
                            as="select"
                            name="locationRiskCategory"
                            class="form-select form-select-solid form-select-lg"
                            v-model="data.errorCaseForm.locationRiskCategoryId"
                            @change="handleLocationRiskCategoryChange"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="locationRiskCategory in computedLocationRiskCategories"
                              :key="locationRiskCategory.id"
                              :value="locationRiskCategory.id"
                            >
                              {{ locationRiskCategory.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="locationRiskCategory" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.moreDetails.errorPlace')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="8"
                            as="select"
                            name="errorPlace"
                            class="form-select form-select-solid form-select-lg"
                            v-model="data.errorCaseForm.errorPlaceId"
                            @change="handleErrorPlaceChange"
                            :disabled="!computedIsLocationRiskCategorySelected"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="errorPlace in computedErrorPlaces"
                              :key="errorPlace.id"
                              :value="errorPlace.id"
                            >
                              {{ errorPlace.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="errorPlace" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.moreDetails.errorStyle')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            tabindex="9"
                            as="select"
                            name="errorStyle"
                            class="form-select form-select-solid form-select-lg"
                            v-model="data.errorCaseForm.errorStyleId"
                            @change="handleErrorRiskChange"
                            :disabled="!computedIsErrorPlaceSelected"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="errorStyle in computedErrorStyles"
                              :key="errorStyle.id"
                              :value="errorStyle.id"
                            >
                              {{ errorStyle.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="errorStyle" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Field
                        name="riskLevel"
                        class="form-control form-control-solid"
                        v-model="data.errorCase.locationRisk.riskValue"
                        v-show="false"
                      />

                      <div
                        v-show="computedIsErrorStyleSelected"
                        class="row mb-3"
                      >
                        <div class="col-12">
                          <label
                            :class="`col-form-label fw-bold fs-6 ${
                              data.errorCase.locationRisk.riskValue > 1
                                ? 'required'
                                : ''
                            }`"
                            >{{ $t('fes.form.moreDetails.description') }}</label
                          >
                        </div>
                        <div
                          class="col-12 d-flex align-items-start flex-column"
                        >
                          <Field
                            tabindex="10"
                            as="textarea"
                            name="description"
                            class="form-control form-control-solid"
                            v-model="data.errorCase.detail.description"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage name="description" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex flex-stack pt-10 px-9"
              v-if="computedIsLoadedError"
            >
              <!--begin::Wrapper-->
              <div class="mr-2">
                <button
                  type="button"
                  class="btn btn-lg btn-light-primary me-3"
                  @click="discardFirstStepsEdit"
                >
                  {{ $t('cancel') }}
                </button>
              </div>
              <!--end::Wrapper-->

              <!--begin::Wrapper-->
              <div>
                <button type="submit" class="btn btn-lg btn-primary">
                  {{ $t('save') }}
                </button>
              </div>
              <!--end::Wrapper-->
            </div>
            <error-form-step-navigation
              v-else
              :back-allowed="!computedIsLoadedError"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <MyForm
            @submit="handleStep3Submit"
            v-if="isReadyForErrorForm && !computedIsLoadedError"
            v-show="data.currentStep === 3"
            novalidate="novalidate"
            action="javascript:"
            method="post"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            ref="step3Form"
          >
            <div class="col-12 mb-5">
              <div class="card">
                <div class="card-header border-0">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3">{{
                      $t('fes.form.steps.3.title')
                    }}</span>
                  </h3>
                </div>

                <div class="card-body py-3">
                  <div v-show="data.errorCase.barcodes.length" class="row mb-5">
                    <div
                      class="col-12 d-flex align-items-start flex-column p-3"
                    >
                      <Field
                        v-show="0"
                        v-model="data.errorCase.barcodes"
                        name="barcodes"
                      />
                      <div
                        class="alert bg-light-primary border border-primary border-1 border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-5"
                        v-for="(barcode, index) in data.errorCase.barcodes"
                        :key="barcode.code"
                      >
                        <span
                          class="svg-icon svg-icon-2hx svg-icon-primary me-4 d-flex align-items-center"
                        >
                          <inline-svg
                            src="media/icons/duotune/ecommerce/ecm010.svg"
                          />
                        </span>
                        <div class="d-flex align-items-center">
                          <h4 class="mb-1 text-primary">
                            {{ barcode.code }}
                          </h4>
                        </div>
                        <button
                          type="button"
                          class="btn btn-light-danger position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 ms-sm-auto"
                          @click="removeBarcode(index)"
                        >
                          <span class="svg-icon svg-icon-1 me-0">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <template v-if="!data.errorCase.barcodes.length">
                    <div
                      v-if="isLhdCodeGenerationAllowed"
                      :class="`d-flex flex-center`"
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="generateLhdCode"
                      >
                        {{ $t('fes.form.barcodeHint.generateLhdCode') }}
                      </button>
                    </div>
                    <div
                      v-else
                      class="alert bg-light-primary d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                    >
                      <span class="svg-icon svg-icon-5tx svg-icon-primary mb-5">
                        <inline-svg
                          src="media/icons/duotune/ecommerce/ecm010.svg"
                        />
                      </span>
                      <!--begin::Content-->
                      <div class="text-center text-dark">
                        <h1 class="fw-bolder mb-5">
                          {{ $t('fes.form.barcodeHint.heading') }}
                        </h1>
                        <div
                          class="separator separator-dashed border-primary opacity-25 mb-5"
                        ></div>
                        <div class="mb-9">
                          {{ $t('fes.form.barcodeHint.subtext') }}
                        </div>
                      </div>
                      <!--end::Content-->
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <error-form-step-navigation
              :back-allowed="!computedIsLoadedError"
              :next-allowed="computedHasBarcode"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <MyForm
            :validation-schema="errorCaseStep4Validator"
            @submit="handleStep4Submit"
            v-if="
              isReadyForErrorForm &&
              !computedIsLoadedError &&
              computedIsProcessInterruptionEnabled
            "
            v-show="data.currentStep === 4"
            novalidate="novalidate"
            action="javascript:"
            method="post"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            ref="step4Form"
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3">{{
                      $t('fes.form.steps.4.title')
                    }}</span>
                    <span class="text-muted fw-bold fs-7">{{
                      $t('fes.form.steps.4.detail')
                    }}</span>
                  </h3>
                </div>

                <div class="card-body py-3">
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="col-form-label fw-bold fs-6">{{
                        $t('fes.form.processInterruption.category')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                      <Field
                        tabindex="11"
                        as="select"
                        name="processInterruptionCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.errorCaseForm.processInterruptionCategoryId
                        "
                        @change="handleProcessInterruptionCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('fes.form.processInterruption.none') }}
                        </option>
                        <option
                          v-for="processInterruptionCategory in computedProcessInterruptionCategories"
                          :key="processInterruptionCategory.id"
                          :value="processInterruptionCategory.id"
                        >
                          {{ processInterruptionCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="processInterruptionCategory" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="computedFilledProcessInterruptionCategory"
                  >
                    <div class="col-12">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.subCategory')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-start flex-column">
                      <Field
                        tabindex="12"
                        as="select"
                        name="processInterruptionSubCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.errorCaseForm.processInterruptionSubCategoryId
                        "
                        @change="handleProcessInterruptionSubCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('pleaseSelect') }}
                        </option>
                        <option
                          v-for="processInterruptionSubCategory in computedProcessInterruptionSubCategories"
                          :key="processInterruptionSubCategory.id"
                          :value="processInterruptionSubCategory.id"
                        >
                          {{ processInterruptionSubCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="processInterruptionSubCategory" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="computedFilledProcessInterruptionSubCategory"
                  >
                    <div class="col-12">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.subSubCategory')
                      }}</label>
                    </div>
                    <div class="col-12 d-flex align-items-start flex-column">
                      <Field
                        tabindex="13"
                        as="select"
                        name="processInterruptionSubSubCategory"
                        class="form-select form-select-solid form-select-lg"
                        v-model="
                          data.errorCaseForm.processInterruptionSubSubCategoryId
                        "
                        @change="handleProcessInterruptionSubSubCategoryChange"
                      >
                        <option value="" selected>
                          {{ $t('pleaseSelect') }}
                        </option>
                        <option
                          v-for="processInterruptionSubSubCategory in computedProcessInterruptionSubSubCategories"
                          :key="processInterruptionSubSubCategory.id"
                          :value="processInterruptionSubSubCategory.id"
                        >
                          {{ processInterruptionSubSubCategory.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage
                            name="processInterruptionSubSubCategory"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-if="
                      computedFilledProcessInterruptionCategory &&
                      computedFilledProcessInterruptionSubCategory &&
                      computedFilledProcessInterruptionSubSubCategory
                    "
                  >
                    <div class="col-6">
                      <label class="col-form-label required fw-bold fs-6">{{
                        $t('fes.form.processInterruption.reason')
                      }}</label>
                      <Field
                        tabindex="14"
                        as="textarea"
                        name="reason"
                        class="form-control form-control-solid"
                        v-model="data.errorCase.processInterruption.reason"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="reason" />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-8">
                          <label class="col-form-label required fw-bold fs-6"
                            >{{
                              $t('fes.form.processInterruption.downtimeMachine')
                            }}
                            {{ $t('inMinutes') }}</label
                          >
                        </div>
                        <div class="col-4">
                          <Field
                            type="number"
                            name="processInterruptionDowntimeMachine"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="
                              data.errorCase.processInterruption.downtimeMachine
                            "
                          />
                        </div>
                        <div class="col-12">
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                name="processInterruptionDowntimeMachine"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <label class="col-form-label required fw-bold fs-6"
                            >{{
                              $t('fes.form.processInterruption.downtimeWorker')
                            }}
                            {{ $t('inMinutes') }}</label
                          >
                        </div>
                        <div class="col-4">
                          <Field
                            type="number"
                            name="processInterruptionDowntimeWorker"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="
                              data.errorCase.processInterruption.downtimeWorker
                            "
                          />
                        </div>
                        <div class="col-12">
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                name="processInterruptionDowntimeWorker"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <error-form-step-navigation
              :back-allowed="!computedIsLoadedError"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <MyForm
            :validation-schema="errorCaseStep5Validator"
            @submit="handleStep5Submit"
            v-if="
              isReadyForErrorForm &&
              isCurrentUserDeciderForLocation &&
              !computedIsErrorCaseFinalized &&
              isCurrentErrorCaseBlockedForCurrentUser === false
            "
            v-show="data.currentStep === 5"
            novalidate="novalidate"
            action="javascript:"
            method="post"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            ref="step5Form"
          >
            <Field
              name="availableDecisionPoints"
              type="hidden"
              v-model="computedAvailableDecisionPoints"
            />
            <Field
              name="availableDecisionPointsExceeded"
              type="hidden"
              v-model="data.errorCaseForm.availableDecisionPointsExceeded"
            />
            <Field
              name="hasSplitAmount"
              type="hidden"
              v-model="data.errorCaseForm.splitDecision"
            />
            <button v-if="0" @click="sendMail">Send Mail</button>
            <div class="row mb-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header border-0">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bolder fs-3">{{
                        $t('fes.form.steps.5.title')
                      }}</span>
                      <span class="text-muted fw-bold fs-7">{{
                        $t('fes.form.steps.5.detail')
                      }}</span>
                    </h3>
                    <div class="card-toolbar">
                      <span class="badge badge-light-primary">
                        <i class="bi bi-person-check me-2"></i>
                        {{ computedCurrentUser.name }} ({{
                          computedCurrentUser.username
                        }})
                      </span>
                    </div>
                  </div>

                  <div class="card-body py-3">
                    <div
                      class="border border-primary rounded p-3 mb-3"
                      v-for="(splitDecision, index) of data.errorCaseForm
                        .splitDecisions"
                      :key="index"
                    >
                      <Field
                        :name="`splitDecisions[${index}].availableDecisionPoints`"
                        type="hidden"
                        v-model="computedAvailableDecisionPoints"
                      />
                      <div class="row">
                        <div class="col-12">
                          <span class="text-muted fw-bold fs-7"
                            >Entscheidung #{{
                              data.errorCase.splitDecisions.length + index + 1
                            }}</span
                          >
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.causes.cause')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            as="select"
                            :name="`splitDecisions[${index}].splitCause`"
                            class="form-select form-select-solid form-select-lg"
                            v-model="splitDecision.splitCause"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="errorCaseCause in computedAvailableErrorCaseCauses"
                              :key="errorCaseCause.id"
                              :value="errorCaseCause.id"
                            >
                              {{ errorCaseCause.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                :name="`splitDecisions[${index}].splitCause`"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.causes.causeReason')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            as="select"
                            :name="`splitDecisions[${index}].splitCauseReason`"
                            class="form-select form-select-solid form-select-lg"
                            v-model="splitDecision.splitCauseReason"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="errorCaseCauseReason in computedAvailableErrorCaseCauseReasons"
                              :key="errorCaseCauseReason.id"
                              :value="errorCaseCauseReason.id"
                            >
                              {{ errorCaseCauseReason.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                :name="`splitDecisions[${index}].splitCauseReason`"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.decision.decision')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            as="select"
                            :name="`splitDecisions[${index}].splitDecision`"
                            class="form-select form-select-solid form-select-lg"
                            v-model="splitDecision.splitDecision"
                          >
                            <option value="" selected>
                              {{ $t('pleaseSelect') }}
                            </option>
                            <option
                              v-for="errorCaseDecision in computedAvailableErrorCaseDecisions"
                              :key="errorCaseDecision.id"
                              :value="errorCaseDecision.id"
                            >
                              {{ errorCaseDecision.name }}
                            </option>
                          </Field>
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                :name="`splitDecisions[${index}].splitDecision`"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-if="computedSplitDecisionAmountIsMandatory"
                      >
                        <div class="col-3">
                          <label class="col-form-label required fw-bold fs-6">{{
                            $t('fes.form.decision.splitAmountLabel')
                          }}</label>
                        </div>
                        <div class="col-9 d-flex align-items-start flex-column">
                          <Field
                            type="number"
                            :name="`splitDecisions[${index}].amount`"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            v-model="splitDecision.amount"
                            @input="handleSplitDecisionAmountChange"
                          />
                          <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                              <ErrorMessage
                                :name="`splitDecisions[${index}].amount`"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="index > 0">
                        <div class="col-12 text-end">
                          <button
                            class="btn btn-light-danger btn-sm"
                            type="button"
                            @click="removeSplitDecision(index)"
                          >
                            {{ $t('fes.form.decision.splitAmountRemove') }}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage
                              name="availableDecisionPointsExceeded"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 text-end"
                        v-if="data.errorCase.detail.amountFlawed > 1"
                      >
                        <button
                          class="btn btn-light-primary"
                          type="button"
                          @click="addEmptySplitDecision"
                        >
                          {{ $t('fes.form.decision.splitAmountAdd') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <error-form-step-navigation
              v-if="!computedDecisionReadyForEdit"
              :back-allowed="!computedIsLoadedError"
              :prev-step-function="previousStep"
            />
            <error-form-step-navigation
              v-else
              :back-allowed="true"
              :prev-step-function="endDecisionEdit"
            />
          </MyForm>

          <MyForm
            :validation-schema="errorCaseStep6Validator"
            @submit="handleStep6Submit"
            v-if="
              isReadyForErrorForm &&
              isCurrentUserDeciderForLocation &&
              !computedIsErrorCaseFinalized &&
              isCurrentErrorCaseBlockedForCurrentUser === false
            "
            v-show="data.currentStep === 6"
            novalidate="novalidate"
            action="javascript:"
            method="post"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            ref="step6Form"
          >
            <Field
              name="hasOpenPoints"
              type="hidden"
              v-model="computedHasOpenPoints"
            />
            <open-point-collection-form
              add-initial-value
              source="fes"
              :headline="$t('fes.form.openPointsOnError')"
              :children-allowed="false"
              :source-id="computedErrorCaseId"
              :model-value="data.errorCaseForm.openPoints"
              @update:modelValue="handleUpdateOpenPoints"
            />
            <!-- <Field v-show="0" v-model="data.errorCase.openPoints" name="openPoints" /> -->

            <error-form-step-navigation
              :back-allowed="true"
              :next-allowed="computedIsNextAllowedForOpenPoints"
              :prev-step-function="previousStep"
            />
          </MyForm>

          <div
            ref="formBottom"
            class="py-20 w-100 px-9 border border-3 border-primary rounded"
            v-show="
              data.currentStep === LAST_STEP_INDEX && !computedIsLoadedError
            "
          >
            <div data-kt-stepper-element="content" class="current">
              <!--begin::Wrapper-->
              <div class="w-100">
                <!--begin::Heading-->
                <div class="pb-8 pb-lg-10">
                  <!--begin::Title-->
                  <h2 class="fw-bolder text-dark">Erledigt!</h2>
                </div>
                <!--end::Heading--><!--begin::Body-->
                <div class="mb-5">
                  <!--begin::Text-->
                  <div class="fs-6 text-gray-600 mb-5">
                    {{ computedFinalStepMessage }}
                  </div>
                </div>

                <button class="btn btn-primary" @click="resetErrorForm">
                  {{ $t('backToStart') }}
                </button>
                <!--end::Body-->
              </div>
              <!--end::Wrapper-->
            </div>
          </div>
        </div>

        <div class="d-flex">
          <el-alert
            v-if="
              computedIsLoadedError && isCurrentErrorCaseBlockedForCurrentUser
            "
            :title="computedBlockedErrorCaseAlertText"
            type="warning"
            :closable="false"
          />

          <el-button
            v-if="
              computedIsLoadedError &&
              isCurrentErrorCaseBlockedForCurrentUser &&
              computedCurrentUserIsAdmin
            "
            class="ms-3"
            type="primary"
            size="small"
            @click="onReleaseErrorCaseClicked"
          >
            {{ $t('fes.form.releaseBlockedErrorButton') }}
          </el-button>
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import * as _ from 'lodash';
  import {
    computed,
    ComputedRef,
    defineComponent,
    onMounted,
    reactive,
    ref,
    watch,
  } from 'vue';
  import { configure, ErrorMessage, Field, Form } from 'vee-validate';
  import ApiService, {
    isProcessInterruptionsEnabled,
  } from '@/core/services/ApiService';
  import { useStore } from 'vuex';
  import { Actions, Mutations } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n';
  import Swal from 'sweetalert2';
  import {
    SetErrorLocationContext,
    User,
    UserGroup,
  } from '@/store/modules/AuthModule';
  import * as yup from 'yup';
  import Loading from 'vue-loading-overlay';
  import {
    computedDate,
    computedTime,
    getNearestRiskLevelCatalogs,
    parseCurrentOrgLevels,
  } from '@/core/helpers/cdf';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import SimpleOrgUnitPath from '@/components/fes/SimpleOrgUnitPath.vue';
  import ErrorFormStepNavigation from '@/components/fes/errorForm/StepNavigation.vue';
  import {
    Barcode,
    CauseCatalog,
    CauseReasonCatalog,
    DecisionCatalog,
    ErrorCase,
    ErrorCaseCause,
    ErrorCaseCauseReason,
    ErrorCaseCDFName,
    ErrorCaseDecision,
    ErrorCaseDecisionDto,
    ErrorCaseDecisionUpdateDto,
    ErrorCaseDetail,
    ErrorCaseSplitDecision,
    ErrorLocationRisk,
    ErrorPlace,
    ErrorStyle,
    FESConfig,
    OrgChartDataPairWithCatalogs,
    ProcessInterruptionCatalog,
    ProcessInterruptionCategory,
    ProcessInterruptionState,
    ProcessInterruptionSubCategory,
    ProcessInterruptionSubSubCategory,
    ProductCategory,
    ProductCategoryCatalog,
    ProductSubCategory,
    QRCodeData,
    RiskLevel,
    RiskLevelCatalog,
  } from '@/core/models/fes';
  // import UserIcon from '../../../components/UserIcon.vue';
  import {
    calculateDepth,
    getNearestCatalog,
    hasReadyChildren,
    hasReadyParents,
    isReadyOrgUnit,
  } from '@/core/helpers/cdf';
  import { OpenPoint, OpenPointLocation } from '@/core/models/opl';
  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
  import { AxiosResponse } from 'axios';
  import { useKeypress } from 'vue3-keypress';
  import ProcessInterruptionPath from '@/components/fes/ProcessInterruptionPath.vue';
  import SpanWithDeletedState from '@/components/SpanWithDeletedState.vue';
  import { ElAlert, ElNotification } from 'element-plus';
  import oplOpenPointOverview from '@/views/opl/OplOverview.embed.vue';
  import DecisionSingle from '@/components/fes/DecisionSingle.vue';
  import DecisionSingleDeprecated from '@/components/fes/DecisionSingleDeprecated.vue';
  import OpenPointCollectionForm from '@/components/opl/OpenPointCollectionForm.vue';
  // import { ScrollComponent } from '@/assets/ts/components';

  const enabledProcessInterruption = isProcessInterruptionsEnabled();

  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: false,
  });

  type Step = {
    id: number;
    name: string;
    detail?: string;
  };

  export default defineComponent({
    name: 'errorsEdit',
    components: {
      OpenPointCollectionForm,
      Field,
      MyForm: Form,
      ErrorMessage,
      OrgUnitPath,
      SimpleOrgUnitPath,
      // UserIcon,
      ErrorFormStepNavigation,
      ProcessInterruptionPath,
      SpanWithDeletedState,
      ElAlert,
      oplOpenPointOverview,
      DecisionSingle,
      DecisionSingleDeprecated,
      Loading,
    },
    props: {
      id: {
        type: Number,
        required: false,
      },
    },
    setup() {
      const store = useStore();
      const { t } = useI18n();
      const route = useRoute();
      const router = useRouter();

      const step1Form = ref(Form);
      const step2Form = ref(Form);
      const step3Form = ref(Form);
      const step4Form = ref(Form);
      const step5Form = ref(Form);
      const step6Form = ref(Form);

      const formBottom = ref(HTMLElement);

      const LAST_STEP_INDEX = 7;

      const data: {
        currentStep: number;
        currentStepBefore: number | null;
        currentHighestStep: number;
        orgChart: OrgChartDataPairWithCatalogs[];
        orgLevels: string[];
        isOrgLevelsLoading: boolean;
        errorLocation: {
          isLoading: boolean;
          isReady: boolean;
          isComplete: boolean;
        };
        errorCase: ErrorCase | null;
        errorCaseForm: {
          locationRiskCategoryId: string;
          errorPlaceId: string;
          errorStyleId: string;
          productCategoryId: string;
          productSubCategoryId: string;
          productCategory: ProductCategory;
          productSubCategory: ProductSubCategory;
          processInterruptionCategory: ProcessInterruptionCategory;
          processInterruptionSubCategory: ProcessInterruptionSubCategory;
          processInterruptionSubSubCategory: ProcessInterruptionSubSubCategory;
          processInterruptionCategoryId: string;
          processInterruptionSubCategoryId: string;
          processInterruptionSubSubCategoryId: string;
          errorCaseDecisionId: string;
          errorCaseCauseId: string;
          errorCaseCauseReasonId: string;
          errorCaseDetailsReady: boolean;
          splitDecision: boolean;
          splitDecisionFixed: boolean;
          splitDecisionAmount: number;
          splitDecisions: ErrorCaseSplitDecision[];
          usedSplitDecisionAmount: number;
          availableDecisionPointsExceeded: boolean;
          openPoints: OpenPoint[];
        };
        selects: {
          processInterruptionCategories: ProcessInterruptionCategory[];
          productCategories: ProductCategory[];
        };
        barcodeInput: string;
        qrCodeInput: string;
        errorCaseCdfNames: ErrorCaseCDFName[];
        currentRiskLevels: RiskLevel[];
        currentProcessInterruptionCategory: ProcessInterruptionCategory | null;
        steps: Step[];
        isLoadedError: boolean;
        testTimerStart: number;
        testTimerEnd: number;
      } = reactive({
        currentStep: 1,
        currentStepBefore: null,
        currentHighestStep: 0,
        orgChart: [],
        orgLevels: [],
        isOrgLevelsLoading: false,
        errorLocation: {
          isLoading: true,
          isReady: false,
          isComplete: false,
        },
        errorCase: null,
        errorCaseForm: {
          locationRiskCategoryId: '',
          errorPlaceId: '',
          errorStyleId: '',
          productCategoryId: '',
          productSubCategoryId: '',
          productCategory: {} as ProductCategory,
          productSubCategory: {} as ProductSubCategory,
          processInterruptionCategory: {} as ProcessInterruptionCategory,
          processInterruptionSubCategory: {} as ProcessInterruptionSubCategory,
          processInterruptionSubSubCategory:
            {} as ProcessInterruptionSubSubCategory,
          processInterruptionCategoryId: '',
          processInterruptionSubCategoryId: '',
          processInterruptionSubSubCategoryId: '',
          errorCaseDecisionId: '',
          errorCaseCauseId: '',
          errorCaseCauseReasonId: '',
          errorCaseDetailsReady: false,
          splitDecision: false,
          splitDecisionFixed: false,
          splitDecisionAmount: 0,
          splitDecisions: [] as ErrorCaseSplitDecision[],
          usedSplitDecisionAmount: 0,
          availableDecisionPointsExceeded: false,
          openPoints: [] as OpenPoint[],
        },
        selects: {
          processInterruptionCategories: [],
          productCategories: [],
        },
        barcodeInput: '',
        qrCodeInput: '',
        errorCaseCdfNames: [] as ErrorCaseCDFName[],
        currentRiskLevels: [] as RiskLevel[],
        currentProcessInterruptionCategory: null,
        steps: [
          {
            id: 1,
            name: t('fes.form.steps.1.title'),
            detail: t('fes.form.steps.1.detail'),
          } as Step,
          {
            id: 2,
            name: t('fes.form.steps.2.title'),
            detail: t('fes.form.steps.2.detail'),
          },
          {
            id: 3,
            name: t('fes.form.steps.3.title'),
          },
          {
            id: 5,
            name: t('fes.form.steps.5.title'),
            detail: t('fes.form.steps.5.detail'),
          },
          {
            id: 6,
            name: t('fes.form.steps.6.title'),
            detail: t('fes.form.steps.6.detail'),
          },
          {
            id: 7,
            name: t('done'),
          },
        ],
        isLoadedError: false,
        testTimerStart: 0,
        testTimerEnd: 0,
      });

      if (enabledProcessInterruption) {
        data.steps.push({
          id: 4,
          name: t('fes.form.steps.4.title'),
          detail: t('fes.form.steps.4.detail'),
        });
        data.steps = data.steps.sort((a, b) => a.id - b.id);
      }

      const computedIsProcessInterruptionEnabled = computed(
        () => enabledProcessInterruption,
      );

      const resetLocation = () => {
        data.orgLevels = [''];
        data.errorLocation.isReady = false;
        data.errorLocation.isComplete = false;
      };

      const errorCaseStep1Validator = yup.object().shape({
        materialReference: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingMaterialReference'))
          .label(t('fes.form.details.materialReference')),
        faufReference: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingFaufReference'))
          .label(t('fes.form.details.faufReference')),
        productCategory: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingProductCategory'))
          .label(t('fes.form.moreDetails.productCategory')),
        productSubCategory: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingProductSubCategory'))
          .label(t('fes.form.moreDetails.productSubCategory')),
        amountTotal: yup
          .number()
          .min(1)
          .required(t('fes.form.errors.missingAmountTotal'))
          .label(t('fes.form.details.amountTotal'))
          .typeError(t('fes.form.errors.missingAmountTotal')),
        amountChecked: yup
          .number()
          .min(1)
          .max(
            yup.ref('amountTotal'),
            t('fes.form.errors.amountCheckedTooHigh'),
          )
          .required(t('fes.form.errors.missingAmountChecked'))
          .label(t('fes.form.details.amountChecked'))
          .typeError(t('fes.form.errors.missingAmountChecked')),
        // .lessThan(, 'Max should be > min'),
        amountFlawed: yup
          .number()
          .min(1)
          .max(
            yup.ref('amountChecked'),
            t('fes.form.errors.amountFlawedTooHigh'),
          )
          .required(t('fes.form.errors.missingAmountFlawed'))
          .label(t('fes.form.details.amountFlawed'))
          .typeError(t('fes.form.errors.missingAmountFlawed')),
        // 'details.amountTotal': yup.string().required(),
      });

      const errorCaseStep2Validator = yup.object().shape({
        locationRiskCategory: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingLocationRiskCategory'))
          .label(t('fes.form.moreDetails.locationRiskCategory')),
        errorPlace: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingErrorPlace'))
          .label(t('fes.form.moreDetails.errorPlace')),
        errorStyle: yup
          .string()
          .min(1)
          .required(t('fes.form.errors.missingErrorStyle'))
          .label(t('fes.form.moreDetails.errorStyle')),
        description: yup
          .string()
          .when('riskLevel', {
            is: (value) => {
              return value > 1;
            },
            then: yup
              .string()
              .min(1)
              .required(t('fes.form.errors.missingDescription')),
          })
          .label(t('fes.form.moreDetails.description')),
      });

      const errorCaseStep3Validator = yup.object().shape({
        barcodes: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                code: yup.string().required().label('Barcode'),
              })
              .required(),
          )
          .min(1, t('fes.form.errors.missingBarcode'))
          .required()
          .strict(),
      });

      const errorCaseStep4Validator = computed(() => {
        if (data.errorCaseForm.processInterruptionCategoryId !== '') {
          return yup.object().shape({
            reason: yup
              .string()
              .min(1)
              .required(t('fes.form.errors.processInterruption.missingReason'))
              .label(t('fes.form.processInterruption.reason'))
              .typeError(
                t('fes.form.errors.processInterruption.missingReason'),
              ),
            processInterruptionSubSubCategory: yup
              .number()
              .required()
              .label(t('fes.form.processInterruption.subCategory'))
              .typeError(
                t('fes.form.errors.processInterruption.missingSubSubCategory'),
              ),
            processInterruptionDowntimeMachine: yup
              .number()
              .required()
              .label(t('fes.form.processInterruption.downtimeMachine'))
              .typeError(
                t('fes.form.errors.processInterruption.missingDonwtimeMachine'),
              ),
            processInterruptionDowntimeWorker: yup
              .number()
              .required()
              .label(t('fes.form.processInterruption.downtimeWorker'))
              .typeError(
                t('fes.form.errors.processInterruption.missingDowntimeWorker'),
              ),
          });
        }

        return yup.object().shape({});
      });

      const errorCaseStep5Validator = yup.object().shape({
        availableDecisionPointsExceeded: yup
          .boolean()
          .oneOf(
            [false],
            t('fes.form.decision.splitAmountErrors.moreThanTotal'),
          ),
        hasSplitAmount: yup.boolean(),
        splitDecisions: yup
          .array()
          .of(
            yup.object().shape({
              availableDecisionPoints: yup.number(),
              splitDecision: yup
                .string()
                .min(1)
                .required(t('fes.form.errors.missingDecision'))
                .label(t('fes.form.decision.decision')),
              splitCause: yup
                .string()
                .min(1)
                .required(t('fes.form.errors.missingCause'))
                .label(t('fes.form.causes.cause')),
              splitCauseReason: yup
                .string()
                .min(1)
                .required(t('fes.form.errors.missingCauseReason'))
                .label(t('fes.form.causes.causeReason')),
              amount: yup
                .number()
                .min(1, t('fes.form.decision.splitAmountErrors.moreThanOne')),
            }),
          )
          .required(),
      });

      const errorCaseStep6Validator = yup.object().shape({
        hasOpenPoints: yup.boolean(),
        openPoints: yup
          .array()
          .of(
            yup.object().shape({
              title: yup
                .string()
                .min(1)
                .required(t('opl.errors.missingTitle'))
                .typeError(t('opl.errors.missingTitle')),
              comment: yup
                .string()
                .when('isUrgent', {
                  is: (value) => {
                    return !value;
                  },
                  then: yup
                    .string()
                    .min(1)
                    .required(t('fes.form.errors.missingComment')),
                })

                .typeError(t('opl.errors.missingComment')),
              initialAssignees: yup.array().when('isUrgent', {
                is: false,
                then: yup
                  .array()
                  .min(1, t('opl.errors.missingAssignee'))
                  .required(t('opl.errors.missingAssignee'))
                  .typeError(t('opl.errors.missingAssignee')),
              }),
              isUrgent: yup.boolean(),
              dueDate: yup
                .string()
                .label(t('opl.form.dueDate'))
                .when('isUrgent', {
                  is: false,
                  then: yup
                    .string()
                    .required(t('opl.errors.missingDueDate'))
                    .typeError(t('opl.errors.missingDueDate')),
                })
                .test(
                  'isDateInFuture',
                  t('opl.errors.dueDateNotInFuture'),
                  (value) => {
                    if (value) {
                      const date = new Date(value);
                      date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
                      const now = new Date();
                      now.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
                      return date >= now; // >= instead of > to allow same day
                    }
                    return true;
                  },
                ),
            }),
          )
          .when('hasOpenPoints', {
            is: false,
            then: yup
              .array()
              .min(1, t('opl.errors.missingOpenPoint'))
              .required(t('opl.errors.missingOpenPoint'))
              .typeError(t('opl.errors.missingOpenPoint')),
          }),
      });

      const handleSplitDecisionAmountChange = () => {
        data.errorCaseForm.usedSplitDecisionAmount =
          data.errorCaseForm.splitDecisions.reduce(
            (acc: number, current: any) => {
              return acc + parseInt(String(current.amount));
            },
            0,
          );
        data.errorCaseForm.availableDecisionPointsExceeded =
          data.errorCaseForm.usedSplitDecisionAmount >
          computedAvailableDecisionPoints.value;
      };

      const isReadyForQrCode = computed(() => {
        return data.errorLocation.isComplete === true;
      });

      const isReadyForErrorForm = computed(() => {
        return data.errorCase !== null;
      });

      const computedCurrentLocation = computed(() => {
        if (data.errorCase !== null && data.errorCase.locations.length > 0) {
          const lastLocation = data.errorCase.locations.slice(0).pop();
          if (lastLocation) {
            return data.orgChart.find(
              (orgUnit: OrgChartDataPairWithCatalogs) => {
                return lastLocation.locationId === orgUnit.id;
              },
            );
          }
        }
        return selectedOrgLevels.value
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          })
          .map((orgLevel) => {
            return orgLevel;
          })
          .slice(0)
          .pop();
      });

      watch(
        () => data.currentStep,
        (newValue) => {
          if (newValue > data.currentHighestStep) {
            data.currentHighestStep = newValue;
          }
        },
      );

      const startEditStep = (step: number) => {
        data.currentStepBefore = data.currentStep;
        data.currentStep = step;

        if (data.errorCase) {
          data.errorCaseForm.productCategoryId = String(
            data.errorCase.productCategory?.id,
          );
          data.errorCaseForm.productSubCategoryId = String(
            data.errorCase.productSubCategory?.id,
          );

          data.errorCaseForm.locationRiskCategoryId = String(
            data.errorCase.locationRisk.style.category.id,
          );
          data.errorCaseForm.errorPlaceId = String(
            data.errorCase.locationRisk.place.id,
          );
          data.errorCaseForm.errorStyleId = String(
            data.errorCase.locationRisk.style.id,
          );
        }
      };

      const discardFirstStepsEdit = () => {
        if (data.currentStepBefore === null) return;
        data.currentStep = data.currentStepBefore;
        data.currentStepBefore = null;
      };

      const computedDecisionBlockedDueDelete = computed(() => {
        return typeof computedCurrentLocation.value === 'undefined';
      });

      const computedDecisionReadyForEdit = computed(() => {
        if (
          computedCurrentUserIsCurrentDecider.value === true ||
          computedCurrentUserIsAdmin.value === true
        ) {
          if (typeof data.errorCase?.decider?.id !== 'undefined') {
            return true;
          }
        }
        return false;
      });

      const computedCurrentUserIsCurrentDecider = computed(() => {
        if (data.errorCase === null) return false;
        if (data.errorCase.decision === null) return false;
        if (data.errorCase.decider === null) return false;
        return computedCurrentUser.value.id === data.errorCase.decider.userId;
      });

      const computedStepNavClass = computed(() => {
        return (step) => {
          const classes = ['stepper-item'];

          if (
            data.currentHighestStep >= step &&
            data.currentStep !== step &&
            data.currentStep !== LAST_STEP_INDEX &&
            !computedIsLoadedError.value
          ) {
            classes.push('cursor-pointer');
          }

          if (data.currentStep > step && data.currentStep !== step) {
            classes.push('completed');
          } else if (data.currentStep === step) {
            classes.push('current');
            classes.push('cursor-default');
          }

          if (
            !classes.includes('cursor-default') &&
            !classes.includes('cursor-pointer')
          ) {
            classes.push('cursor-default');
          }

          return classes.join(' ');
        };
      });

      const computedStepNavVisible = computed(() => {
        return (step) => {
          if (step <= 3) return true;

          if (data.currentHighestStep > 2) {
            if (step === 4 || step === 5 || step === 6) {
              return (
                isCurrentUserDeciderForLocation.value &&
                computedIsCurrentErrorCaseDecisionNeeded.value
              );
            }
          }

          return step === LAST_STEP_INDEX;
        };
      });

      const computedStepIndexNumber = computed(() => {
        return (stepIndex) => {
          if (stepIndex <= 2) {
            return stepIndex;
          }

          let stepAdd = 0;
          if (
            stepIndex > 4 &&
            stepIndex < LAST_STEP_INDEX &&
            !enabledProcessInterruption
          ) {
            stepAdd = -1;
          }

          return (
            data.steps.slice(0, stepIndex).filter((step: Step) => {
              return computedStepNavVisible.value(step.id) === true;
            }).length + stepAdd
          );
        };
      });

      const selectedOrgLevels = computed(() => {
        return data.orgLevels
          .map((orgLevelId, index) => {
            const currentOptions = computedOrgOptions(index);

            return currentOptions.find((orgOption) => {
              return orgLevelId === orgOption.id;
            });
          })
          .filter((orgLevel) => {
            return typeof orgLevel !== 'undefined';
          });
      });

      const getFirstLevelElementsOfParent = (parentId, currentTree) => {
        const result: OrgChartDataPairWithCatalogs[] = [];
        for (const element of currentTree) {
          if (element.parentId === parentId) {
            result.push(element);
          }
        }
        return result;
      };

      const computedAvailableErrorCaseDecisions = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const decisionCatalog: DecisionCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'decisionCatalog',
        );

        if (decisionCatalog) {
          return decisionCatalog.decisions.sort(
            (decisionA: ErrorCaseDecision, decisionB: ErrorCaseDecision) => {
              if (decisionA.name < decisionB.name) {
                return -1;
              }
              if (decisionA.name > decisionB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseCauses = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeCatalog: CauseCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'causeCatalog',
        );

        if (causeCatalog) {
          return causeCatalog.causes.sort(
            (causeA: ErrorCaseCause, causeB: ErrorCaseCause) => {
              if (causeA.name < causeB.name) {
                return -1;
              }
              if (causeA.name > causeB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedAvailableErrorCaseCauseReasons = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        const causeReasonCatalog: CauseReasonCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'causeReasonCatalog',
        );

        if (causeReasonCatalog) {
          return causeReasonCatalog.causeReasons.sort(
            (
              causeReasonA: ErrorCaseCauseReason,
              causeReasonB: ErrorCaseCauseReason,
            ) => {
              if (causeReasonA.name < causeReasonB.name) {
                return -1;
              }
              if (causeReasonA.name > causeReasonB.name) {
                return 1;
              }
              return 0;
            },
          );
        }

        return [];
      });

      const computedProcessInterruptionCategories = computed(
        (): ProcessInterruptionCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionCategoriesToSelect: ProcessInterruptionCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        processInterruptionCategoriesToSelect.findIndex(
                          (
                            processInterruptionCategory: ProcessInterruptionCategory,
                          ) => {
                            return (
                              category.id === processInterruptionCategory.id
                            );
                          },
                        ) === -1
                      ) {
                        processInterruptionCategoriesToSelect.push(category);
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionCategory,
              categoryB: ProcessInterruptionCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const computedProductCategories = computed((): ProductCategory[] => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) {
          return [];
        }

        const productCategoryCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'productCategoryCatalog',
        ) as ProductCategoryCatalog;

        const currentSubCategoryIds = productCategoryCatalog.subCategories.map(
          (subCategory: ProductSubCategory) => {
            return subCategory.id;
          },
        );

        const productCategoriesToSelect: ProductCategory[] = [];

        data.selects.productCategories.forEach((category: ProductCategory) => {
          category.subCategories.forEach((subCategory: ProductSubCategory) => {
            if (
              currentSubCategoryIds.includes(subCategory.id) &&
              productCategoriesToSelect.findIndex(
                (productCategory: ProductCategory) => {
                  return category.id === productCategory.id;
                },
              ) === -1
            ) {
              productCategoriesToSelect.push(category);
            }
          });
        });

        return productCategoriesToSelect.sort(
          (categoryA: ProductCategory, categoryB: ProductCategory) => {
            if (categoryA.name < categoryB.name) {
              return -1;
            }
            if (categoryA.name > categoryB.name) {
              return 1;
            }
            return 0;
          },
        );
      });

      const computedProcessInterruptionSubCategories = computed(
        (): ProcessInterruptionSubCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionSubCategoriesToSelect: ProcessInterruptionSubCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        category.id ===
                          parseInt(
                            data.errorCaseForm.processInterruptionCategoryId,
                          ) &&
                        processInterruptionSubCategoriesToSelect.findIndex(
                          (
                            processInterruptionSubCategory: ProcessInterruptionSubCategory,
                          ) => {
                            return (
                              subCategory.id ===
                              processInterruptionSubCategory.id
                            );
                          },
                        ) === -1
                      ) {
                        processInterruptionSubCategoriesToSelect.push(
                          subCategory,
                        );
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionSubCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionSubCategory,
              categoryB: ProcessInterruptionSubCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const computedProcessInterruptionSubSubCategories = computed(
        (): ProcessInterruptionSubSubCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const processInterruptionCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'processInterruptionCatalog',
          ) as ProcessInterruptionCatalog;

          const currentSubSubCategoryIds =
            processInterruptionCatalog.subSubCategories.map(
              (subSubCategory: ProcessInterruptionSubSubCategory) => {
                return subSubCategory.id;
              },
            );

          const processInterruptionSubSubCategoriesToSelect: ProcessInterruptionSubSubCategory[] =
            [];

          data.selects.processInterruptionCategories.forEach(
            (category: ProcessInterruptionCategory) => {
              category.subCategories.forEach(
                (subCategory: ProcessInterruptionSubCategory) => {
                  subCategory.subSubCategories.forEach(
                    (subSubCategory: ProcessInterruptionSubSubCategory) => {
                      if (
                        currentSubSubCategoryIds.includes(subSubCategory.id) &&
                        subCategory.id ===
                          parseInt(
                            data.errorCaseForm.processInterruptionSubCategoryId,
                          )
                      ) {
                        processInterruptionSubSubCategoriesToSelect.push(
                          subSubCategory,
                        );
                      }
                    },
                  );
                },
              );
            },
          );

          return processInterruptionSubSubCategoriesToSelect.sort(
            (
              categoryA: ProcessInterruptionSubSubCategory,
              categoryB: ProcessInterruptionSubSubCategory,
            ) => {
              if (categoryA.name < categoryB.name) {
                return -1;
              }
              if (categoryA.name > categoryB.name) {
                return 1;
              }
              return 0;
            },
          );
        },
      );

      const computedProductSubCategories = computed(
        (): ProductSubCategory[] => {
          const currentOrgUnit =
            computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

          if (currentOrgUnit === null) {
            return [];
          }

          const productCategoryCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'productCategoryCatalog',
          ) as ProductCategoryCatalog;

          const currentSubCategoryIds =
            productCategoryCatalog.subCategories.map(
              (subCategory: ProductSubCategory) => {
                return subCategory.id;
              },
            );

          const productSubCategoriesToSelect: ProductSubCategory[] = [];

          data.selects.productCategories.forEach(
            (category: ProductCategory) => {
              category.subCategories.forEach(
                (subCategory: ProductSubCategory) => {
                  if (!currentSubCategoryIds.includes(subCategory.id)) return;
                  if (
                    currentSubCategoryIds.includes(subCategory.id) &&
                    category.id ===
                      parseInt(data.errorCaseForm.productCategoryId)
                  ) {
                    productSubCategoriesToSelect.push(subCategory);
                  }
                },
              );
            },
          );

          return productSubCategoriesToSelect
            .slice(0)
            .sort(
              (
                categoryA: ProductSubCategory,
                categoryB: ProductSubCategory,
              ) => {
                if (categoryA.name < categoryB.name) {
                  return -1;
                }
                if (categoryA.name > categoryB.name) {
                  return 1;
                }
                return 0;
              },
            );
        },
      );

      const computedLocationRiskCategories = computed(() => {
        return _.sortBy(
          _.uniqBy(
            allComputedErrorStyles.value.map((errorStyle: ErrorStyle) => {
              return errorStyle.category;
            }),
            'id',
          ),
          'name',
        );
      });

      const computedErrorPlaces = computed((): ErrorPlace[] => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) {
          return [];
        }

        if (!computedIsLocationRiskCategorySelected.value) {
          return [];
        }

        const riskCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'riskCatalog',
        );

        if (riskCatalog !== null) {
          const availableErrorPlaces: ErrorPlace[] = [];
          riskCatalog.locationRisks.forEach(
            (locationRisk: ErrorLocationRisk) => {
              const isAlreadyExistentPlace = availableErrorPlaces.find(
                (errorPlace: ErrorPlace) => {
                  return (
                    errorPlace.id === locationRisk.place.id &&
                    locationRisk.riskValue > 0 &&
                    locationRisk.style.category.id ===
                      parseInt(data.errorCaseForm.locationRiskCategoryId)
                  );
                },
              );

              if (
                !isAlreadyExistentPlace &&
                locationRisk.riskValue > 0 &&
                locationRisk.style.category.id ===
                  parseInt(data.errorCaseForm.locationRiskCategoryId)
              ) {
                availableErrorPlaces.push(locationRisk.place);
              }
            },
          );
          return _.sortBy(_.uniqBy(availableErrorPlaces, 'id'), 'name');
        }

        return [];
      });

      const allComputedErrorStyles = computed(() => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) {
          return [];
        }

        const riskCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'riskCatalog',
        );

        if (riskCatalog !== null) {
          const availableErrorStyles: ErrorStyle[] = [];
          riskCatalog.locationRisks.forEach(
            (locationRisk: ErrorLocationRisk) => {
              if (locationRisk.riskValue > 0) {
                availableErrorStyles.push(locationRisk.style);
              }
            },
          );
          return _.sortBy(availableErrorStyles, 'name');
        }

        return [];
      });

      function startDecisionEdit() {
        if (data.errorCase === null) return;
        if (
          data.errorCase.cause === null ||
          data.errorCase.causeReason === null ||
          data.errorCase.decision === null
        )
          return;
        data.errorCaseForm.errorCaseDecisionId = String(
          data.errorCase.decision.id,
        );
        data.errorCaseForm.errorCaseCauseId = String(data.errorCase.cause.id);
        data.errorCaseForm.errorCaseCauseReasonId = String(
          data.errorCase.causeReason.id,
        );
        data.currentStep = 5;
      }

      function endDecisionEdit() {
        if (data.errorCase === null) return;
        data.errorCaseForm.errorCaseDecisionId = '';
        data.errorCaseForm.errorCaseCauseId = '';
        data.errorCaseForm.errorCaseCauseReasonId = '';
        data.currentStep = LAST_STEP_INDEX;
      }

      const computedErrorStyles = computed((): ErrorStyle[] => {
        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) {
          return [];
        }

        if (
          !computedIsErrorPlaceSelected.value ||
          !computedIsLocationRiskCategorySelected.value
        ) {
          return [];
        }

        const riskCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'riskCatalog',
        );

        if (riskCatalog !== null) {
          const availableErrorStyles: ErrorStyle[] = [];
          riskCatalog.locationRisks.forEach(
            (locationRisk: ErrorLocationRisk) => {
              if (
                String(data.errorCaseForm.errorPlaceId) ===
                  String(locationRisk.place.id) &&
                locationRisk.style.category.id ===
                  parseInt(data.errorCaseForm.locationRiskCategoryId) &&
                locationRisk.riskValue > 0
              ) {
                availableErrorStyles.push(locationRisk.style);
              }
            },
          );
          return _.sortBy(availableErrorStyles, 'name');
        }

        return [];
      });

      const handleErrorLocationChange = async () => {
        if (
          data.errorLocation.isReady === false ||
          typeof computedCurrentLocation.value === 'undefined'
        ) {
          return;
        }

        const isValid = isReadyOrgUnit(
          computedCurrentLocation.value,
          data.orgChart,
          data.currentRiskLevels,
        );

        if (isValid === false) {
          data.errorLocation.isComplete = false;
          // data.errorLocation.isReady = false;
          return;
        }

        data.errorLocation.isComplete = true;
        store.commit(Mutations.SET_ERRORLOCATION, {
          orgLevels: data.orgLevels,
          identifier: 'currentOrgLevelsFES',
        } as SetErrorLocationContext);
        initEmptyErrorCase();
      };

      const releaseErrorLocationForm = () => {
        data.errorLocation.isComplete = false;
      };

      const isErrorLocationComplete = computed(() => {
        return data.errorLocation.isComplete === true;
      });

      const isErrorCaseNull = computed(() => {
        return data.errorCase === null;
      });

      const getRawOrgChart = (): OrgChartDataPairWithCatalogs[] => {
        const rawData = JSON.stringify(computedAvailableOrgLevels.value);
        return JSON.parse(rawData);
      };

      const computedOrgOptions = (index) => {
        const orgUnits: OrgChartDataPairWithCatalogs[] = getRawOrgChart();

        return orgUnits.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          if (orgUnit.depth !== index || orgUnit.visible !== true) {
            return false;
          }

          if (orgUnit.parentId && index > 0) {
            return data.orgLevels.includes(orgUnit.parentId);
          }

          return true;
        });
      };

      const hasOrgUnitChildren = (
        orgUnit: OrgChartDataPairWithCatalogs,
      ): boolean => {
        const rawDataParsed = getRawOrgChart();
        return rawDataParsed.some((element) => {
          return element.parentId === orgUnit.id;
        });
      };

      const handleOrgUnitChange = (index) => {
        data.isOrgLevelsLoading = true;
        setTimeout(() => {
          try {
            if (data.orgLevels.length > index + 1) {
              data.orgLevels.splice(
                index + 1,
                data.orgLevels.length - index - 1,
              );
            }

            const currentId = String(data.orgLevels[index]);

            const rawDataParsed: OrgChartDataPairWithCatalogs[] =
              getRawOrgChart();
            const currentNode = rawDataParsed.find(
              (element: OrgChartDataPairWithCatalogs) =>
                element.id === currentId,
            );

            if (currentNode) {
              if (hasOrgUnitChildren(currentNode)) {
                data.orgLevels.push('');
                data.errorLocation.isReady = false;
              } else {
                data.errorLocation.isReady = true;
              }
            } else {
              data.errorLocation.isReady = currentId === 'all';
            }
          } catch (error) {
            console.error(error);
          } finally {
            data.isOrgLevelsLoading = false;
          }
        }, 100);
      };

      const computedOrgLevelHierarchy = (index): string => {
        return data.errorCaseCdfNames[index]
          ? data.errorCaseCdfNames[index].name
          : `${t('depth')} ${index + 1}`;
      };

      const initEmptyErrorCase = (): ErrorCase => {
        const calculatedLocations =
          selectedOrgLevels.value.map((orgLevel, index) => {
            const orgLevelIdentifier = computedOrgLevelHierarchy(index);
            return {
              locationIdentifier: orgLevelIdentifier,
              locationName: String(orgLevel?.name),
              locationId: String(orgLevel?.id),
              pos: index,
            };
          }) || [];

        data.errorCase = {
          detail: {} as ErrorCaseDetail,
          barcodes: [] as Barcode[],
          processInterruption: null,
          responsibleUser: {} as User,
          openPoints: [] as OpenPoint[],
          decision: null,
          cause: null,
          locations: calculatedLocations,
          locationRisk: {} as ErrorLocationRisk,
          reporterUserId: String(store.getters.currentUser.id),
        } as ErrorCase;

        // data.errorCase.barcodes = [
        //   { code: `foobar-${Math.round(Math.random() * 10000)}` },
        // ] as Barcode[];
        // data.errorCase.processInterruption = null;

        // data.errorCase.responsibleUser = {} as User;
        // data.errorCase.openPoints = [] as OpenPoint[];

        // data.errorCase.decision = null;
        // data.errorCase.cause = null;

        // data.errorCase.locations =
        //   ;

        // data.errorCase.locationRisk = {} as ErrorLocationRisk;

        // data.errorCase.reporterUserId = String(store.getters.currentUser.id);
        isBarcodeCodeScanActive.value = true;
        return data.errorCase;
      };

      const handleQrCodeInput = () => {
        if (data.errorCase === null) return;

        // read data from code string and set values in errorCase
        const code = data.barcodeInput;

        const codeSplit = code.split('+');

        if (codeSplit.length !== 6) {
          data.qrCodeInput = '';
          return Swal.fire(t('error'), t('errorAlertCaption'), 'error');
        }

        const codeData: QRCodeData = {
          partName: codeSplit[2],
          partReference: codeSplit[5], // FA-Nummer
          jobReference: codeSplit[1], // Materialnummer
        };

        // const currentErrorCase = initEmptyErrorCase();

        data.errorCase.detail.faufReference = codeData.partReference;
        // data.errorCase.detail. = codeData.partReference;

        // currentErrorCase.detail.partName = codeData.partName;
        // currentErrorCase.detail.partReference = codeData.partReference;
        // currentErrorCase.detail.jobReference = codeData.jobReference;
        // data.currentStep = 1;
      };

      // const mockQrCodeWithClick = () => {
      //   data.qrCodeInput = String(
      //     'A+R151134047+KUG GEWINDESPINDEL 32X5RX3,5+0006104093+000513883953+1011302094/000020',
      //   );
      //   handleQrCodeInput();
      // };

      // const mockQrCode = () => {
      //   Swal.fire({
      //     title: 'QR-Code',
      //     icon: 'question',
      //     input: 'text',
      //     showCancelButton: true,
      //     allowOutsideClick: false,
      //     showLoaderOnConfirm: true,
      //   })
      //     .then(async (result: SweetAlertResult) => {
      //       if (!result.isConfirmed) {
      //         return;
      //       }
      //       data.qrCodeInput = String(result.value);
      //       handleQrCodeInput();
      //     })
      //     .catch(() => {
      //       Swal.fire(t('error'), t('errorAlertCaption'), 'error');
      //     });
      // };

      const handleProcessInterruptionCategoryChange = () => {
        if (data.errorCase === null) return;
        if (data.errorCaseForm.processInterruptionCategoryId === '') {
          data.errorCase.processInterruption = null;
        } else {
          const foundProcessInterruptionCategory =
            data.selects.processInterruptionCategories.find(
              (element: ProcessInterruptionCategory) => {
                return (
                  String(element.id) ===
                  String(data.errorCaseForm.processInterruptionCategoryId)
                );
              },
            );
          if (foundProcessInterruptionCategory) {
            data.errorCaseForm.processInterruptionCategory =
              foundProcessInterruptionCategory;
          }
        }
        data.errorCaseForm.processInterruptionSubCategoryId = '';
        data.errorCaseForm.processInterruptionSubSubCategoryId = '';
      };

      const handleProcessInterruptionSubCategoryChange = () => {
        if (data.errorCase === null) return;
        if (data.errorCaseForm.processInterruptionSubCategoryId === '') {
          data.errorCase.processInterruption = null;
        } else {
          const foundProcessInterruptionSubCategory =
            computedProcessInterruptionSubCategories.value.find(
              (element: ProcessInterruptionSubCategory) => {
                return (
                  String(element.id) ===
                  String(data.errorCaseForm.processInterruptionSubCategoryId)
                );
              },
            );
          if (foundProcessInterruptionSubCategory) {
            data.errorCaseForm.processInterruptionSubCategory =
              foundProcessInterruptionSubCategory;
          }
        }
        data.errorCaseForm.processInterruptionSubSubCategoryId = '';
      };

      const handleProcessInterruptionSubSubCategoryChange = () => {
        if (data.errorCase === null) return;
        if (data.errorCaseForm.processInterruptionCategory === null) return;

        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) return;

        const processInterruptionCatalog = getNearestCatalog(
          currentOrgUnit,
          data.orgChart,
          'processInterruptionCatalog',
        ) as ProcessInterruptionCatalog;

        const foundProcessInterruptionSubSubCategory =
          processInterruptionCatalog.subSubCategories.find(
            (element: ProcessInterruptionSubSubCategory) => {
              return (
                String(element.id) ===
                String(data.errorCaseForm.processInterruptionSubSubCategoryId)
              );
            },
          );
        if (foundProcessInterruptionSubSubCategory) {
          data.errorCaseForm.processInterruptionSubSubCategory =
            foundProcessInterruptionSubSubCategory;
          data.errorCase.processInterruption = {
            category: data.errorCaseForm.processInterruptionCategory,
            subCategory: data.errorCaseForm.processInterruptionSubCategory,
            subSubCategory: foundProcessInterruptionSubSubCategory,
            downtimeMachine: null,
            downtimeWorker: null,
            reason: '',
            locations: selectedOrgLevels.value.map((orgLevel, index) => {
              const orgLevelIdentifier = computedOrgLevelHierarchy(index);
              return {
                locationIdentifier: orgLevelIdentifier,
                locationName: String(orgLevel?.name),
                locationId: String(orgLevel?.id),
                pos: index,
              };
            }),
            error: null,
            state: ProcessInterruptionState.OPEN,
          };
        }
      };

      const handleLocationRiskCategoryChange = () => {
        data.errorCaseForm.errorPlaceId = '';
        handleErrorRiskChange();
      };
      const handleProductCategoryChange = () => {
        data.errorCaseForm.productSubCategoryId = '';

        if (data.errorCase === null) return;
        if (data.errorCaseForm.productCategoryId === '') {
          data.errorCase.productCategory = null;
        } else {
          const foundProductCategory = data.selects.productCategories.find(
            (element: ProductCategory) => {
              return (
                String(element.id) ===
                String(data.errorCaseForm.productCategoryId)
              );
            },
          );
          if (foundProductCategory) {
            data.errorCase.productCategory = foundProductCategory;
          }
        }
      };
      //       const handleProductSubCategoryChange = () => {
      //   if (data.errorCase === null) return;
      //   if (data.errorCaseForm.processInterruptionSubCategoryId === '') {
      //     data.errorCase.processInterruption = null;
      //   } else {
      //     const foundProcessInterruptionSubCategory =
      //       computedProcessInterruptionSubCategories.value.find(
      //         (element: ProcessInterruptionSubCategory) => {
      //           return (
      //             String(element.id) ===
      //             String(data.errorCaseForm.processInterruptionSubCategoryId)
      //           );
      //         },
      //       );
      //     if (foundProcessInterruptionSubCategory) {
      //       data.errorCaseForm.processInterruptionSubCategory =
      //         foundProcessInterruptionSubCategory;
      //     }
      //   }
      //   data.errorCaseForm.processInterruptionSubSubCategoryId = '';
      // };
      const handleProductSubCategoryChange = () => {
        if (data.errorCase === null) return;
        if (data.errorCaseForm.productSubCategoryId === '') {
          data.errorCase.productSubCategory = null;
        } else {
          const foundProductSubCategory =
            computedProductSubCategories.value.find(
              (element: ProductSubCategory) => {
                return (
                  String(element.id) ===
                  String(data.errorCaseForm.productSubCategoryId)
                );
              },
            );
          if (foundProductSubCategory) {
            data.errorCaseForm.productSubCategory = foundProductSubCategory;
            data.errorCase.productSubCategory = foundProductSubCategory;
          }
        }
      };

      const handleErrorPlaceChange = () => {
        data.errorCaseForm.errorStyleId = '';
        handleErrorRiskChange();
      };

      const handleErrorRiskChange = () => {
        if (
          data.errorCaseForm.locationRiskCategoryId === '' ||
          data.errorCaseForm.errorPlaceId === '' ||
          data.errorCaseForm.errorStyleId === ''
        ) {
          if (data.errorCase !== null) {
            data.errorCase.locationRisk = {} as ErrorLocationRisk;
          }
          return;
        }

        const currentOrgUnit =
          computedCurrentLocation.value as OrgChartDataPairWithCatalogs;

        if (currentOrgUnit === null) {
          return [];
        }

        if (
          data.errorCase &&
          currentOrgUnit &&
          typeof data.errorCase.locationRisk !== 'undefined'
        ) {
          const riskCatalog = getNearestCatalog(
            currentOrgUnit,
            data.orgChart,
            'riskCatalog',
          );
          const locationRisk = riskCatalog.locationRisks.find(
            (locationRisk: ErrorLocationRisk) => {
              const errorPlaceId = String(data.errorCaseForm.errorPlaceId);
              const errorStyleId = String(data.errorCaseForm.errorStyleId);
              return (
                errorPlaceId === String(locationRisk.place.id) &&
                errorStyleId === String(locationRisk.style.id)
              );
            },
          );

          if (locationRisk) {
            data.errorCase.locationRisk = locationRisk as ErrorLocationRisk;
          }
          return;
        }
      };

      const previousStep = () => {
        data.currentStep--;

        isBarcodeCodeScanActive.value = data.currentStep === 3;

        if (data.currentStep <= 0) {
          resetErrorForm();
        }
      };

      const computedFilledProcessInterruptionCategory = computed(() => {
        return data.errorCaseForm.processInterruptionCategoryId !== '';
      });

      const computedFilledProcessInterruptionSubCategory = computed(() => {
        return data.errorCaseForm.processInterruptionSubCategoryId !== '';
      });
      const computedFilledProcessInterruptionSubSubCategory = computed(() => {
        return (
          data.errorCaseForm.processInterruptionSubSubCategoryId !== '' &&
          data.errorCase !== null &&
          data.errorCase.processInterruption !== null
        );
      });

      const computedIsCriticalError = computed(() => {
        return (
          computedIsErrorCaseDetailsLocked.value &&
          data.errorCase !== null &&
          data.errorCase?.locationRisk.riskValue > 1
        );
      });

      const computedIsErrorCaseDetailsLocked = computed((): boolean => {
        return data.errorCaseForm.errorCaseDetailsReady;
      });

      const computedIsErrorCaseExistent = computed(() => {
        return (
          data.errorCase !== null &&
          typeof data.errorCase.id !== 'undefined' &&
          data.errorCase.id > 0
        );
      });

      const handleStep1Submit = () => {
        data.currentStep = 2;
      };
      const handleStep2Submit = async () => {
        if (computedIsLoadedError.value) {
          await handleErrorCaseUpdate();
          return;
        }
        await handleErrorCaseInitSave();
      };

      const handleErrorCaseFinish = () => {
        data.currentStep = LAST_STEP_INDEX;
      };

      const handleErrorCaseUpdate = async () => {
        if (data.errorCase === null) return false;
        store.dispatch(Actions.START_LOADER);

        try {
          await errorCaseStep2Validator.validate({
            locationRiskCategory: data.errorCaseForm.locationRiskCategoryId,
            errorPlace: data.errorCaseForm.errorPlaceId,
            errorStyle: data.errorCaseForm.errorStyleId,
            description: data.errorCase.detail.description,
            riskLevel: data.errorCase.locationRisk.riskValue,
          });

          const updatedErrorCase = await ApiService.post('fes/errors/update', {
            data: data.errorCase,
          });

          data.errorCase.detail = updatedErrorCase.data
            .detail as ErrorCaseDetail;
          data.errorCase.locationRisk = updatedErrorCase.data.locationRisk;
          data.errorCase.productCategory =
            updatedErrorCase.data.productCategory;
          data.errorCase.productSubCategory =
            updatedErrorCase.data.productSubCategory;
          data.errorCase.splitDecisions = updatedErrorCase.data.splitDecisions;

          data.errorCase.locations = data.errorCase.locations.sort((a, b) => {
            return a.pos - b.pos;
          });

          if (
            !computedIsCurrentErrorCaseDone.value &&
            computedIsCurrentErrorCaseDecisionNeeded.value &&
            isCurrentUserDeciderForLocation.value &&
            (data.currentStepBefore === null ||
              data.currentStepBefore === LAST_STEP_INDEX)
          ) {
            data.currentHighestStep = 5;
            await goToStep(5, true);
          } else if (data.currentStepBefore !== null) {
            data.currentStep = data.currentStepBefore;
            data.currentStepBefore = null;
          } else {
            data.currentStep = LAST_STEP_INDEX;
          }
        } catch (e) {
          console.log('Error', e);
          return false;
        } finally {
          store.dispatch(Actions.END_LOADER);
        }
      };

      const handleErrorCaseInitSave = async () => {
        if (data.errorCase === null) return false;
        store.dispatch(Actions.START_LOADER);

        // if (!computedIsCurrentErrorCaseDecisionNeeded.value) {
        //   ApiService.post('fes/errors/save', {
        //     data: data.errorCase,
        //   })
        //     .then(() => {
        //       handleErrorCaseFinish();
        //     })
        //     .catch(() => {
        //       Swal.fire(t('error'), t('errorAlertCaption'), 'error');
        //     })
        //     .finally(() => {
        //       store.dispatch(Actions.END_LOADER);
        //     });
        // } else {
        await goToStep(3, true);
        data.errorCaseForm.errorCaseDetailsReady = true;
        data.errorCase.processInterruption = null;
        store.dispatch(Actions.END_LOADER);
        // }
      };

      const handleStep3Submit = async () => {
        if (data.errorCase === null) return false;

        if (data.errorCase.barcodes.length === 0) {
          return false;
        }

        if (!computedIsCurrentErrorCaseDecisionNeeded.value) {
          ApiService.post('fes/errors/save', {
            data: data.errorCase,
          })
            .then(() => {
              handleErrorCaseFinish();
              Swal.fire(
                t('fes.form.simpleLevelWarning.title'),
                t('fes.form.simpleLevelWarning.text'),
                'warning',
              );
              resetErrorForm();
            })
            .catch(() => {
              Swal.fire(t('error'), t('errorAlertCaption'), 'error');
            })
            .finally(() => {
              store.dispatch(Actions.END_LOADER);
            });
        } else {
          if (enabledProcessInterruption) {
            await goToStep(4, true);
          } else {
            await handleCriticalErrorCaseSave();
          }
        }
      };

      const handleStep4Submit = async () => {
        await handleCriticalErrorCaseSave();
      };

      const computedSplitDecisionAmountIsMandatory = computed(() => {
        if (data.errorCaseForm.splitDecisions.length > 1) return true;
        return (
          data.errorCase !== null && data.errorCase.splitDecisions.length > 0
        );
      });

      const handleStep5Submit = async () => {
        if (data.errorCase === null) return false;
        if (
          data.errorCase.decider !== null &&
          typeof data.errorCase.decider.id !== 'undefined'
        ) {
          return handleDecisionUpdate();
        }

        if (
          data.errorCase.splitDecisions.length === 0 &&
          data.errorCaseForm.splitDecisions.length === 1
        ) {
          data.errorCaseForm.splitDecisions[0].amount =
            data.errorCase.detail.amountFlawed;
        }

        await goToStep(6, true);
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        );
      };

      const handleStep6Submit = async () => {
        await handleDecisionSubmit();
      };

      function handleDecisionUpdate() {
        if (data.errorCase === null) return;
        if (!data.errorCase.id) return;
        if (!data.errorCase.decision) return;
        if (!data.errorCase.cause) return;
        if (!data.errorCase.causeReason) return;

        const decisionUpdate: ErrorCaseDecisionUpdateDto = {
          id: data.errorCase.id,
          decision: data.errorCase.decision,
          cause: data.errorCase.cause,
          causeReason: data.errorCase.causeReason,
        };

        store.dispatch(Actions.START_LOADER);

        ApiService.post('fes/errors/decision/update', {
          data: decisionUpdate,
        })
          .then((response: AxiosResponse) => {
            data.errorCase = response.data as ErrorCase;
            endDecisionEdit();
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      }

      const computedCurrentUserIsAdmin = computed(() => {
        const currentUser: User = { ...store.getters.currentUser };
        return currentUser.groups.includes(UserGroup.Admin);
      });

      const isCurrentUserDeciderForLocation = computed(() => {
        if (!data.errorCase.locations?.length) {
          return false;
        }

        if (computedCurrentUserIsAdmin.value) {
          return true;
        }

        const currentUserIsInDeciderGroup =
          computedCurrentUser.value.groups.some((groupName: string) => {
            return groupName.indexOf('Sw_DC-LT_W_P_DEVIATION_7210_QM_UD') === 0;
          });

        if (!currentUserIsInDeciderGroup) {
          return false;
        }

        const currentLastLocation = data.errorCase.locations.slice().pop();
        const currentOrgUnit = data.orgChart.find(
          (orgUnit: OrgChartDataPairWithCatalogs) => {
            return orgUnit.id === currentLastLocation?.locationId;
          },
        );

        if (!currentOrgUnit) return false;

        const riskLevelCatalogs: RiskLevelCatalog[] =
          getNearestRiskLevelCatalogs(currentOrgUnit, data.orgChart);

        if (
          data.errorCase.locationRisk !== null &&
          typeof data.errorCase.locationRisk.riskValue !== 'undefined'
        ) {
          return (
            riskLevelCatalogs.filter((riskLevelCatalog: RiskLevelCatalog) => {
              if (data.errorCase === null) return false;
              return (
                riskLevelCatalog.riskValue ===
                  data.errorCase.locationRisk.riskValue &&
                riskLevelCatalog.userIds.includes(computedCurrentUser.value.id)
              );
            }).length > 0
          );
        } else {
          return false;
        }
      });

      const handleCriticalErrorCaseSave = async () => {
        if (data.errorCase === null) return false;

        store.dispatch(Actions.START_LOADER);

        const errorToSave = Object.assign({}, data.errorCase);
        errorToSave.openPoints = [] as OpenPoint[];

        ApiService.post('fes/errors/save', {
          data: data.errorCase,
        })
          .then((result) => {
            const finalErrorCase = result.data as ErrorCase;
            router.push({
              name: 'fesErrorSingle',
              params: { id: finalErrorCase.id },
            });
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      };

      const computedFilledProcessInterruption = computed((): boolean => {
        return !!data.errorCase?.processInterruption;
      });

      const handleProcessInterruptionTypeChange = () => {
        return true;
      };

      const dynamicSelectValues = computed(() => {
        return {
          decision: computedAvailableErrorCaseDecisions,
          cause: computedAvailableErrorCaseCauses,
          causeReason: computedAvailableErrorCaseCauseReasons,
        };
      });

      const resetErrorForm = () => {
        data.errorCase = null;
        data.errorCaseForm.locationRiskCategoryId = '';
        data.errorCaseForm.errorPlaceId = '';
        data.errorCaseForm.errorStyleId = '';
        data.errorCaseForm.processInterruptionCategory =
          {} as ProcessInterruptionCategory;
        data.errorCaseForm.processInterruptionSubCategory =
          {} as ProcessInterruptionSubCategory;
        data.errorCaseForm.processInterruptionSubSubCategory =
          {} as ProcessInterruptionSubSubCategory;
        data.errorCaseForm.processInterruptionCategoryId = '';
        data.errorCaseForm.processInterruptionSubCategoryId = '';
        data.errorCaseForm.processInterruptionSubSubCategoryId = '';
        data.errorCaseForm.errorCaseDetailsReady = false;

        data.errorCaseForm.errorCaseDecisionId = '';
        data.errorCaseForm.errorCaseCauseId = '';
        data.errorCaseForm.errorCaseCauseReasonId = '';

        data.barcodeInput = '';
        data.qrCodeInput = '';

        if (!computedIsLoadedError.value) {
          initEmptyErrorCase();
        }

        data.currentStep = 1;
        data.currentHighestStep = 1;

        resetForms();
      };

      const resetForms = () => {
        if (typeof step1Form.value.resetForm === 'function')
          step1Form.value.resetForm();
        if (typeof step2Form.value.resetForm === 'function')
          step2Form.value.resetForm();
        if (typeof step3Form.value.resetForm === 'function')
          step3Form.value.resetForm();
        if (typeof step4Form.value.resetForm === 'function')
          step4Form.value.resetForm();
        if (
          typeof step5Form.value !== 'undefined' &&
          typeof step5Form.value.resetForm === 'function'
        )
          step5Form.value.resetForm();
        if (
          typeof step6Form.value !== 'undefined' &&
          typeof step6Form.value.resetForm === 'function'
        )
          step6Form.value.resetForm();
      };

      const handleDecisionSubmit = () => {
        if (data.errorCase === null) return;

        // const hasSingleDecisionToSave =
        //   computedSplitDecisionsToSave.value.length === 1 &&
        //   data.errorCase.decision === null;

        if (!data.errorCase.id) return;

        const decisionUpdate: ErrorCaseDecisionDto = {
          id: data.errorCase.id,
          decision: null,
          cause: null,
          causeReason: null,
          responsibleUserId: computedCurrentUser.value.id,
          splitDecisions: computedSplitDecisionsToSave.value,
          openPoints: [],
        };

        // if (hasSingleDecisionToSave) {
        //   decisionUpdate.decision = computedSplitDecisionsToSave.value[0]
        //     .splitDecision as ErrorCaseDecision;
        //   decisionUpdate.cause = computedSplitDecisionsToSave.value[0]
        //     .splitCause as ErrorCaseCause;
        //   decisionUpdate.causeReason = computedSplitDecisionsToSave.value[0]
        //     .splitCauseReason as ErrorCaseCauseReason;
        // }

        if (
          data.errorCaseForm.openPoints !== null &&
          data.errorCaseForm.openPoints.length > 0
        ) {
          decisionUpdate.openPoints = data.errorCaseForm.openPoints.map(
            (openPoint) => {
              const locations = data.errorCase?.locations.slice(
                0,
              ) as OpenPointLocation[];

              if (!openPoint.dueDate) {
                openPoint.dueDate = null;
              }
              openPoint.locations = locations.slice(0);
              openPoint.children = openPoint.children.map(
                (child: OpenPoint) => {
                  child.locations = locations.slice(0);
                  return child;
                },
              );
              return openPoint;
            },
          );
        }

        store.dispatch(Actions.START_LOADER);

        ApiService.post('fes/errors/finalize', {
          data: decisionUpdate,
        })
          .then((response: AxiosResponse) => {
            const finalizedError = response.data as ErrorCase;
            data.errorCase = finalizedError;
            data.errorCaseForm.splitDecisions = [];
            handleErrorCaseFinish();
            router.push('/fes/dashboard');
            ElNotification({
              title: t('fes.notifications.finalizedError.title'),
              message: t('fes.notifications.finalizedError.text'),
              type: 'success',
            });
          })
          .catch(() => {
            Swal.fire(t('error'), t('errorAlertCaption'), 'error');
          })
          .finally(() => {
            store.dispatch(Actions.END_LOADER);
          });
      };

      const computedAvailableOrgLevels = computed(() => {
        return data.orgChart.filter((orgUnit: OrgChartDataPairWithCatalogs) => {
          return orgUnit.visible;
        });
      });

      const computedIsOrgUnitSelectable = computed(() => {
        return computedCurrentLocation.value?.selectable === true;
      });

      const computedIsLocationRiskCategorySelected = computed(() => {
        return data.errorCaseForm.locationRiskCategoryId !== '';
      });

      const computedIsErrorPlaceSelected = computed(() => {
        return data.errorCaseForm.errorPlaceId !== '';
      });

      const computedIsErrorStyleSelected = computed(() => {
        return data.errorCaseForm.errorStyleId !== '';
      });

      const computedCurrentUser: ComputedRef<User> = computed(() => {
        return store.getters.currentUser;
      });

      const computedCurrentUserRiskLevels = computed(() => {
        return [];
      });

      const hasCompletedStep1 = computed(() => {
        return data.currentStep > 1;
      });
      const hasCompletedStep2 = computed(() => {
        return data.currentStep > 2;
      });
      const hasCompletedStep3 = computed(() => {
        return data.currentStep > 3 && data.errorCase !== null;
      });
      const hasCompletedStep4 = computed(() => {
        // return false;
        return (
          data.currentStep > 4 &&
          data.errorCase !== null &&
          computedIsCurrentErrorCaseDecisionNeeded.value === true &&
          enabledProcessInterruption
        );
      });
      const hasCompletedStep5 = computed(() => {
        return (
          data.currentStep > 5 &&
          data.errorCase !== null &&
          data.errorCase.decision !== null &&
          data.errorCase.cause !== null &&
          computedIsCurrentErrorCaseDecisionNeeded.value === true
        );
      });
      const hasCompletedStep6 = computed(() => {
        return (
          data.currentStep > 6 &&
          data.errorCase !== null &&
          computedIsCurrentErrorCaseDone.value === true &&
          data.errorCase.openPoints !== null &&
          computedIsCurrentErrorCaseDecisionNeeded.value === true
        );
      });

      const goToStep = async (step: number, force = false) => {
        if (data.currentStep === LAST_STEP_INDEX) return;
        if (step === data.currentStep) return;
        if (step > data.currentHighestStep && !force) return;
        if (computedIsLoadedError.value && !force) return;

        if (
          force === false &&
          step > data.currentStep &&
          data.errorCase !== null
        ) {
          try {
            switch (data.currentStep) {
              case 1:
                await errorCaseStep1Validator.validate({
                  materialReference: data.errorCase.detail.materialReference,
                  faufReference: data.errorCase.detail.faufReference,
                  amountTotal: data.errorCase.detail.amountTotal,
                  amountChecked: data.errorCase.detail.amountChecked,
                  amountFlawed: data.errorCase.detail.amountFlawed,
                });
                break;
              case 2:
                await errorCaseStep2Validator.validate({
                  locationRiskCategory:
                    data.errorCaseForm.locationRiskCategoryId,
                  errorPlace: data.errorCaseForm.errorPlaceId,
                  errorStyle: data.errorCaseForm.errorStyleId,
                  description: data.errorCase.detail.description,
                  riskLevel: data.errorCase.locationRisk.riskValue,
                });
                break;
              case 3:
                await errorCaseStep3Validator.validate({
                  barcodes: data.errorCase.barcodes,
                });
                break;
            }
          } catch (e) {
            return false;
          }
        }

        isBarcodeCodeScanActive.value = step === 3;

        if (
          step === 5 &&
          ((data.errorCase !== null &&
            data.errorCase.splitDecisions.length === 0 &&
            data.errorCase.decision === null) ||
            computedSplitDecisionRestAmount.value > 0)
        ) {
          addEmptySplitDecision();
        }
        data.currentStep = step;
      };

      const computedSplitDecisionRestAmount = computed(() => {
        if (data.errorCase === null) return 0;
        if (data.errorCase.splitDecisions.length === 0) return 0;
        return data.errorCase.splitDecisions.reduce(
          (acc: number, splitDecision: ErrorCaseSplitDecision) => {
            return acc + splitDecision.amount;
          },
          0,
        );
      });

      const createEmptySplitDecision = () => {
        const decider = {
          userId: computedCurrentUser.value.id,
          name: computedCurrentUser.value.name,
          mail: computedCurrentUser.value.mail,
          username: computedCurrentUser.value.username,
        };

        return {
          amount: 0,
          splitDecision: '',
          splitCause: '',
          splitCauseReason: '',
          decider,
        } as ErrorCaseSplitDecision;
      };

      const addEmptySplitDecision = () => {
        data.errorCaseForm.splitDecisions.push(createEmptySplitDecision());
        handleSplitDecisionAmountChange();
        updateSplitDecisionState();
      };

      const removeSplitDecision = (index: number) => {
        data.errorCaseForm.splitDecisions.splice(index, 1);
        handleSplitDecisionAmountChange();
        updateSplitDecisionState();
      };

      const updateSplitDecisionState = () => {
        data.errorCaseForm.splitDecision =
          data.errorCaseForm.splitDecisions.length > 1;
      };

      const computedAvailableDecisionPoints = computed(() => {
        if (typeof data.errorCase === 'undefined' || data.errorCase === null)
          return 0;
        if (
          typeof data.errorCase.detail === 'undefined' ||
          data.errorCase.detail === null
        )
          return 0;

        const amountFlawed = data.errorCase.detail.amountFlawed;
        const splitDecisions = data.errorCase?.splitDecisions || [];
        if (splitDecisions.length === 0) {
          return amountFlawed;
        }

        return (
          amountFlawed -
          splitDecisions.reduce(
            (acc: number, curr) => acc + parseInt(String(curr.amount)),
            0,
          )
        );
      });

      const computedAvailableDecisionPointsExceeded = computed(() => {
        const usedDecisionPoints = data.errorCaseForm.splitDecisions.reduce(
          (acc: number, curr) => {
            return acc + parseInt(String(curr.amount));
          },
          0,
        );
        return usedDecisionPoints > computedAvailableDecisionPoints.value;
      });

      const computedShowSplitDecisions = computed(() => {
        if (data.currentStep <= 2) return false;
        return computedHasSplitDecisions.value;
      });

      const computedHasSplitDecisions = computed(() => {
        if (data.errorCase === null) return false;
        if (typeof data.errorCase.splitDecisions === 'undefined') return false;
        return data.errorCase.splitDecisions.length > 0;
      });
      const computedHasSplitDecisionsToSave = computed(() => {
        return (
          data.errorCaseForm.splitDecisions.length > 0 && data.currentStep > 5
        );
      });

      const computedSplitDecisionsToSave = computed(
        (): ErrorCaseSplitDecision[] => {
          const splitDecisionsJson = JSON.stringify(
            data.errorCaseForm.splitDecisions,
          );
          return JSON.parse(splitDecisionsJson).map((splitDecision) => {
            splitDecision.splitCause =
              dynamicSelectValues.value.cause.value.find(
                (splitCause) =>
                  splitCause.id ===
                  parseInt(splitDecision.splitCause as string),
              ) as ErrorCaseCause;
            splitDecision.splitCauseReason =
              dynamicSelectValues.value.causeReason.value.find(
                (splitCauseReason) =>
                  splitCauseReason.id ===
                  parseInt(splitDecision.splitCauseReason as string),
              ) as ErrorCaseCauseReason;
            splitDecision.splitDecision =
              dynamicSelectValues.value.decision.value.find(
                (loopedSplitDecision) =>
                  loopedSplitDecision.id ===
                  parseInt(splitDecision.splitDecision as string),
              ) as ErrorCaseDecision;

            return splitDecision;
          });
        },
      );

      const computedProcessInterruptionCategoryName = computed(() => {
        if (data.errorCase === null) return '';
        if (data.errorCase.processInterruption === null) return '';
        if (data.errorCase.processInterruption.category === null) return '';
        const currentProcessInterruptionSubCategoryId =
          data.errorCase.processInterruption.category.id;
        const foundProcessInterruptionCategory =
          data.selects.processInterruptionCategories.find(
            (processInterruptionCategory: ProcessInterruptionCategory) => {
              return processInterruptionCategory.subCategories.some(
                (subCategory) => {
                  return (
                    subCategory.id === currentProcessInterruptionSubCategoryId
                  );
                },
              );
            },
          );
        return foundProcessInterruptionCategory
          ? foundProcessInterruptionCategory.name
          : '';
      });

      const handleUpdateOpenPoints = (newOpenPoints) => {
        data.errorCaseForm.openPoints = newOpenPoints;
      };

      const computedErrorCaseId = computed(() => {
        if (data.errorCase === null) return '';
        if (typeof data.errorCase.id === 'undefined') return '';
        return String(data.errorCase.id);
      });

      const computedHasOpenPoints = computed(() => {
        if (data.errorCase === null) return false;
        if (typeof data.errorCase.openPoints === 'undefined') return false;
        if (data.errorCase.openPoints === null) return false;
        return data.errorCase.openPoints.length > 0;
      });

      async function removeBarcode(index) {
        if (data.errorCase === null) return;
        if (data.errorCase.barcodes === null) return;
        if (data.errorCase.barcodes.length === 0) return;
        if (typeof data.errorCase.barcodes[index] === 'undefined') return;
        data.errorCase.barcodes.splice(index, 1);
      }

      const computedBlockedErrorCaseAlertText = computed(() => {
        return t('fes.form.blockedAlert', {
          name: data.errorCase?.blockedUser?.name,
        });
      });

      const isCurrentUserBlockingUser = computed(() => {
        if (isCurrentErrorCaseBlockedForCurrentUser.value === true)
          return false;
        if (data.errorCase === null) return false;
        if (data.errorCase.blockedUserId === null) return false;
        return data.errorCase.blockedUserId === computedCurrentUser.value.id;
      });

      const isCurrentErrorCaseBlockedForCurrentUser = computed(() => {
        if (isCurrentUserDeciderForLocation.value === false) return false;
        // if (typeof data.errorCase === 'undefined') return false;

        if (data.errorCase !== null && data.errorCase.blockedUserId !== null) {
          return computedCurrentUser.value.id !== data.errorCase.blockedUserId;
        }
        return false;
      });

      const computedUserIsDecider = computed(() => {
        if (computedCurrentUserIsAdmin.value === true) return true;
        return computedCurrentUser.value.groups.some((groupName: string) => {
          return groupName.indexOf('Sw_DC-LT_W_P_DEVIATION_7210_QM_UD') === 0;
        });
      });

      async function blockErrorCase(errorCaseId) {
        if (data.errorCase === null) return;
        await ApiService.get(`fes/errors/block/${errorCaseId}`);
        data.errorCase.blockedUserId = computedCurrentUser.value.id;
        data.errorCase.blockedUser = computedCurrentUser.value;
      }

      async function onReleaseErrorCaseClicked() {
        store.dispatch(Actions.START_LOADER);
        await releaseErrorCase();
        store.dispatch(Actions.END_LOADER);
      }

      async function releaseErrorCase() {
        if (!data.errorCase?.id) return;
        await ApiService.get(`fes/errors/release/${data.errorCase.id}`);
        data.errorCase.blockedUserId = null;
        data.errorCase.blockedUser = null;
      }

      onBeforeRouteLeave(() => {
        if (isCurrentUserBlockingUser.value === false) return;
        if (isCurrentUserDeciderForLocation.value === false) return;

        releaseErrorCase();
        window.onbeforeunload = null;
      });

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);

        const fesConfig: FESConfig = (await ApiService.get('fes/config')).data;
        const processInterruptionCategories: ProcessInterruptionCategory[] = (
          await ApiService.get('fes/config/processinterruption/categories')
        ).data;
        const productCategories: ProductCategory[] = (
          await ApiService.get('fes/config/products/categories')
        ).data;

        data.errorCaseCdfNames = fesConfig.hierarchyLevels.slice(0);
        data.currentRiskLevels = fesConfig.riskLevels.slice(0);
        data.selects.processInterruptionCategories =
          processInterruptionCategories.slice(0);
        data.selects.productCategories = productCategories.slice(0);

        data.orgChart = await store.dispatch(Actions.GET_ORG_CHART);
        data.orgChart = data.orgChart.map((orgUnit) => {
          return {
            ...orgUnit,
            depth: calculateDepth(orgUnit, data.orgChart),
            selectable: isReadyOrgUnit(
              orgUnit,
              data.orgChart,
              data.currentRiskLevels,
            ),
            visible:
              isReadyOrgUnit(orgUnit, data.orgChart, data.currentRiskLevels) ||
              hasReadyChildren(
                orgUnit,
                data.orgChart,
                data.currentRiskLevels,
              ) ||
              hasReadyParents(orgUnit, data.orgChart, data.currentRiskLevels),
          };
        });

        data.orgLevels = parseCurrentOrgLevels(
          data.orgChart,
          computedCurrentLocation.value,
          data.currentRiskLevels,
          'currentOrgLevelsFES',
        );

        data.errorLocation.isReady = true;
        await handleErrorLocationChange();

        const errorLoadId = route.params.id;
        if (errorLoadId) {
          await loadErrorCase(errorLoadId);

          if (isCurrentErrorCaseBlockedForCurrentUser.value === false) {
            if (
              data.errorCase !== null &&
              data.errorCase.blockedUserId === null &&
              isCurrentUserDeciderForLocation.value === true
            ) {
              await blockErrorCase(errorLoadId);
            }
          }

          if (
            !computedIsCurrentErrorCaseDone.value &&
            computedIsCurrentErrorCaseDecisionNeeded.value &&
            isCurrentUserDeciderForLocation.value
          ) {
            data.currentHighestStep = 5;
            await goToStep(5, true);
          } else {
            data.currentHighestStep = LAST_STEP_INDEX;
            await goToStep(LAST_STEP_INDEX, true);
          }
        } else {
          initEmptyErrorCase();
        }

        data.errorLocation.isLoading = false;
        store.dispatch(Actions.END_LOADER);

        if (
          isCurrentUserDeciderForLocation.value === true ||
          isCurrentUserBlockingUser.value === true
        ) {
          window.onbeforeunload = function () {
            releaseErrorCase();
            return;
          };
        }
      });

      const computedSplitDecisions = computed(() => {
        if (data.errorCase === null) return [];
        const sortedData = data.errorCase.splitDecisions.slice(0);
        sortedData.sort(
          (a: ErrorCaseSplitDecision, b: ErrorCaseSplitDecision) => {
            return (a.id || 0) - (b.id || 0);
          },
        );
        return sortedData;
      });

      const computedShowErrorFormStep1 = computed(() => {
        if (
          [1, 2].includes(data.currentStep) &&
          computedIsLoadedError.value === true
        ) {
          return true;
        }
        return isReadyForErrorForm.value && !computedIsLoadedError.value;
      });

      const computedShowErrorForms = computed(() => {
        if (
          [1, 2].includes(data.currentStep) &&
          computedIsLoadedError.value === true
        ) {
          return false;
        }

        return computedIsErrorCaseFinalized.value;
      });

      const computedIsErrorCaseFinalized = computed(() => {
        if (data.errorCase === null) return false;
        return (
          data.errorCase.decision !== null &&
          data.errorCase.cause !== null &&
          typeof data.errorCase.id !== 'undefined' &&
          data.currentStep === LAST_STEP_INDEX
        );
      });

      async function loadErrorCase(id) {
        try {
          const loadedErrorCase: ErrorCase = await (
            await ApiService.get(`fes/errors/${id}`)
          ).data;

          if (
            loadedErrorCase.decision !== null &&
            loadedErrorCase.cause !== null
          ) {
            data.currentStep = LAST_STEP_INDEX;
          }

          data.errorCase = Object.assign({}, loadedErrorCase) as ErrorCase;

          data.errorCase.locations = data.errorCase.locations.sort((a, b) => {
            return a.pos - b.pos;
          });

          data.isLoadedError = true;

          data.errorCaseForm.splitDecisionFixed = false;
          if (data.errorCase.splitDecisions.length > 0) {
            data.errorCaseForm.splitDecision = true;
            data.errorCaseForm.splitDecisionFixed = true;
          }
          store.dispatch(Actions.END_LOADER);
        } catch (error) {
          store.dispatch(Actions.END_LOADER);
          router.push('/fes/overview');
          return Swal.fire(t('error'), t('errorCaseNotFound'), 'error');
        }
      }

      const computedIsCurrentErrorCaseDecisionNeeded = computed(() => {
        if (data.errorCase === null) return false;
        if (data.errorCase.locationRisk === null) return false;
        const errorCase = data.errorCase;
        return (
          data.currentRiskLevels.filter((riskLevel: RiskLevel) => {
            return (
              riskLevel.needsDecision === true &&
              riskLevel.level === errorCase.locationRisk.riskValue
            );
          }).length > 0
        );
      });

      const computedIsCurrentErrorCaseDone = computed(() => {
        if (data.errorCase === null) return false;
        return (
          (data.errorCase.decision !== null && data.errorCase.cause !== null) ||
          (data.errorCase.splitDecisions.length &&
            data.errorCase.splitDecisions.reduce((total, splitDecision) => {
              const splitDecisionAmount = parseInt(
                String(splitDecision.amount),
              );
              return total + splitDecisionAmount;
            }, 0) === data.errorCase.detail.amountFlawed)
        );
      });

      const computedIsLoadedError = computed(() => {
        return data.isLoadedError === true;
      });

      const computedHasBarcode = computed(() => {
        if (data.errorCase === null) return false;
        return data.errorCase.barcodes.length > 0;
      });

      const computedFinalStepMessage = computed(() => {
        if (data.errorCase === null) return '';
        if (data.errorCase.locationRisk === null) return '';
        if (data.errorCase.locationRisk.riskValue === 1) {
          return t('fes.form.continueWorkFinal');
        }
        return t('fes.form.continueWork');
      });

      const computedBackLink = computed(() => {
        if (window.history && window.history.state) {
          if (window.history.state.back !== null) {
            return window.history.state.back;
          }
        }

        return '/fes/dashboard';
      });

      const computedHasErrorCaseIdParam = computed(() => {
        return typeof route.params.id !== 'undefined';
      });

      async function acceptProcessInterruption() {
        if (data.errorCase === null) return;
        if (data.errorCase?.processInterruption === null) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;
        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/accept/${data.errorCase.processInterruption.id}`,
          {},
        );
        data.errorCase.processInterruption.state =
          ProcessInterruptionState.ACCEPTED;
        store.dispatch(Actions.END_LOADER);
      }

      async function declineProcessInterruption() {
        if (data.errorCase === null) return;
        if (data.errorCase?.processInterruption === null) return;
        const result = await Swal.fire({
          title: t('sureQuestionHeadline'),
          icon: 'question',
          showCancelButton: true,
        });
        if (!result.isConfirmed) return;
        store.dispatch(Actions.START_LOADER);
        await ApiService.post(
          `/fes/processinterruptions/decline/${data.errorCase.processInterruption.id}`,
          {},
        );
        data.errorCase.processInterruption.state =
          ProcessInterruptionState.DECLINED;
        store.dispatch(Actions.END_LOADER);
      }

      const computedIsOrgUnitForAll = computed(() => {
        return (index) => {
          if (computedCurrentLocation.value) {
            return isReadyOrgUnit(
              selectedOrgLevels[index - 1],
              data.orgChart,
              data.currentRiskLevels,
            );
          }
          return false;
        };
      });

      async function handleBarcodeInput() {
        const barcodeInput = String(data.barcodeInput);

        if (barcodeInput.indexOf('A+') === 0) return handleQrCodeInput();

        // if (barcodeInput.length !== 13) return false;

        const isAlreadyInList = data.errorCase?.barcodes.some(
          (barcode) => barcode.code === barcodeInput,
        );

        if (isAlreadyInList) {
          setTimeout(() => {
            Swal.fire(
              t('error'),
              t('fes.form.errors.duplicateBarcode'),
              'error',
            );
          }, 250);
          data.barcodeInput = '';
          return;
        }

        store.dispatch(Actions.START_LOADER);

        const foundErrorCase = await ApiService.get(
          'fes/errors/code/' + barcodeInput,
        );

        if (!foundErrorCase.data) {
          data.errorCase?.barcodes.push({
            code: barcodeInput,
          } as Barcode);
        } else {
          Swal.fire(
            t('error'),
            t('fes.form.errors.duplicateBarcodeSaved'),
            'error',
          );
        }

        store.dispatch(Actions.END_LOADER);
        data.barcodeInput = '';
      }

      function handleMaterialReferenceInput() {
        const barcodeInput = String(data.barcodeInput);
        if (barcodeInput.length < 5 || data.errorCase === null) return false;

        if (barcodeInput.length >= 19) {
          data.errorCase.detail.materialReference = barcodeInput.substring(
            0,
            10,
          );
          data.errorCase.detail.faufReference = barcodeInput.substring(10, 19);
        }

        if (barcodeInput.length === 10) {
          data.errorCase.detail.materialReference = barcodeInput.substring(
            0,
            10,
          );
        }

        if (barcodeInput.length === 9) {
          data.errorCase.detail.faufReference = barcodeInput.substring(0, 9);
        }
        if (barcodeInput.length === 8 && barcodeInput[0] === '1') {
          data.errorCase.detail.faufReference = `0${barcodeInput.substring(
            0,
            8,
          )}`;
        }

        data.barcodeInput = '';

        if (
          data.errorCase.detail.materialReference.length > 0 &&
          data.errorCase.detail.faufReference.length > 0
        ) {
          isBarcodeCodeScanActive.value = false;
        }
      }

      const handleErrorCaseBarcodeInput = ({ key, keyCode }) => {
        if (keyCode !== 13 && key.length !== 1) return;

        if (data.currentStep === 1) {
          if (keyCode === 13) {
            data.testTimerEnd = new Date().getTime();
            data.testTimerStart = 0;
            data.testTimerEnd = 0;
            return handleMaterialReferenceInput();
          }
        }

        if (data.currentStep === 3) {
          if (keyCode === 13) {
            data.testTimerEnd = new Date().getTime();
            data.testTimerStart = 0;
            data.testTimerEnd = 0;
            return handleBarcodeInput();
          }
        }

        if (data.currentStep === 1 || data.currentStep === 3) {
          if (data.barcodeInput.length === 0) {
            data.testTimerStart = new Date().getTime();
            setTimeout(() => {
              data.barcodeInput = '';
            }, 100);
          }

          data.barcodeInput += String.fromCharCode(keyCode);
        }
      };

      const computedIsNextAllowedForOpenPoints = computed(() => {
        if (data.errorCase === null) return false;
        if (data.errorCase.openPoints === null) return false;
        if (data.errorCase.openPoints.length > 0) return true;
        return data.errorCaseForm.openPoints.length > 0;
      });

      const sendMail = () => {
        if (data.errorCase !== null)
          ApiService.get(`/fes/errors/${data.errorCase.id}/sendmail`);
      };

      const isBarcodeCodeScanActive = ref(false);

      const onAnyKeyCallback = ({ event }) => {
        if (
          data.errorCase !== null &&
          typeof data.errorCase.id === 'undefined' &&
          isErrorLocationComplete.value === true
        ) {
          event.preventDefault();
          handleErrorCaseBarcodeInput(event);
        }
      };

      const computedHasValidConfig = computed(() => {
        return computedOrgOptions(0).length > 0;
      });

      useKeypress({
        keyEvent: 'keydown',
        keyBinds: [],
        isActive: isBarcodeCodeScanActive,
        onAnyKey: onAnyKeyCallback,
      });

      const generateLhdCode = async () => {
        if (!data.errorCase) {
          return;
        }
        const barcode = await ApiService.post(
          '/fes/errors/code/generate/lhd',
          {},
        );
        data.errorCase.barcodes.push(barcode.data);
      };

      const isLhdCodeGenerationAllowed = computed(
        () => data.errorCase?.locationRisk?.riskValue === 10,
      );

      return {
        data,
        getFirstLevelElementsOfParent,
        computedOrgLevelHierarchy,
        computedOrgOptions,
        handleOrgUnitChange,
        handleErrorLocationChange,
        releaseErrorLocationForm,
        handleErrorRiskChange,
        handleErrorPlaceChange,
        handleLocationRiskCategoryChange,
        handleProductCategoryChange,
        isReadyForQrCode,
        selectedOrgLevels,
        isReadyForErrorForm,
        handleErrorCaseInitSave,
        handleCriticalErrorCaseSave,
        computedFilledProcessInterruptionSubCategory,
        computedFilledProcessInterruptionSubSubCategory,
        handleProcessInterruptionCategoryChange,
        resetErrorForm,
        computedIsErrorCaseExistent,
        computedIsErrorCaseDetailsLocked,
        computedSplitDecisions,
        // mockQrCode,
        // mockQrCodeWithClick,
        handleQrCodeInput,
        computedLocationRiskCategories,
        computedErrorPlaces,
        computedErrorStyles,
        computedProductCategories,
        computedProductSubCategories,
        computedIsCriticalError,
        computedFilledProcessInterruption,
        computedFilledProcessInterruptionCategory,
        handleProcessInterruptionTypeChange,
        errorCaseStep1Validator,
        errorCaseStep2Validator,
        errorCaseStep3Validator,
        errorCaseStep4Validator,
        errorCaseStep5Validator,
        errorCaseStep6Validator,
        handleDecisionSubmit,
        computedAvailableOrgLevels,
        computedIsLocationRiskCategorySelected,
        computedIsErrorPlaceSelected,
        computedIsErrorStyleSelected,
        computedCurrentLocation,
        computedIsOrgUnitSelectable,
        computedAvailableErrorCaseDecisions,
        computedAvailableErrorCaseCauses,
        computedAvailableErrorCaseCauseReasons,
        computedCurrentUserRiskLevels,
        computedCurrentUser,
        computedProcessInterruptionCategories,
        computedProcessInterruptionSubCategories,
        computedProcessInterruptionSubSubCategories,
        handleProcessInterruptionSubCategoryChange,
        handleProcessInterruptionSubSubCategoryChange,
        computedSplitDecisionAmountIsMandatory,
        handleSplitDecisionAmountChange,
        handleProductSubCategoryChange,
        isErrorLocationComplete,
        addEmptySplitDecision,
        removeSplitDecision,
        isErrorCaseNull,
        previousStep,
        handleStep1Submit,
        handleStep2Submit,
        handleStep3Submit,
        handleStep4Submit,
        handleStep5Submit,
        handleStep6Submit,
        hasCompletedStep1,
        hasCompletedStep2,
        hasCompletedStep3,
        hasCompletedStep4,
        hasCompletedStep5,
        hasCompletedStep6,
        computedStepNavClass,
        computedStepNavVisible,
        computedStepIndexNumber,
        computedIsErrorCaseFinalized,
        computedAvailableDecisionPoints,
        computedAvailableDecisionPointsExceeded,
        computedHasSplitDecisions,
        computedHasSplitDecisionsToSave,
        computedShowSplitDecisions,
        computedSplitDecisionsToSave,
        computedHasOpenPoints,
        removeBarcode,
        goToStep,
        computedIsLoadedError,
        computedHasBarcode,
        computedProcessInterruptionCategoryName,
        handleUpdateOpenPoints,
        computedErrorCaseId,
        LAST_STEP_INDEX,
        computedDate,
        computedTime,
        computedFinalStepMessage,
        computedBackLink,
        computedHasErrorCaseIdParam,
        isCurrentUserDeciderForLocation,
        computedCurrentUserIsCurrentDecider,
        handleBarcodeInput,
        acceptProcessInterruption,
        declineProcessInterruption,
        computedIsOrgUnitForAll,
        sendMail,
        isBarcodeCodeScanActive,
        computedIsNextAllowedForOpenPoints,
        step1Form,
        step2Form,
        step3Form,
        step4Form,
        step5Form,
        step6Form,
        formBottom,
        startDecisionEdit,
        endDecisionEdit,
        computedDecisionReadyForEdit,
        computedDecisionBlockedDueDelete,
        computedHasValidConfig,
        computedBlockedErrorCaseAlertText,
        isCurrentErrorCaseBlockedForCurrentUser,
        isCurrentUserBlockingUser,
        computedCurrentUserIsAdmin,
        onReleaseErrorCaseClicked,
        computedIsProcessInterruptionEnabled,
        resetLocation,
        startEditStep,
        discardFirstStepsEdit,
        computedShowErrorForms,
        computedShowErrorFormStep1,
        computedUserIsDecider,
        generateLhdCode,
        isLhdCodeGenerationAllowed,
      };
    },
  });
</script>

<style scoped>
  .stepper {
    min-height: 640px;
  }

  a.stepper-item.cursor-pointer:hover .stepper-title,
  a.stepper-item.cursor-pointer:hover .stepper-desc {
    color: #009ef7 !important;
  }

  .card .card-header {
    min-height: 50px;
    align-items: end;
  }
</style>
