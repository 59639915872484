import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "card-header border-0",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#hierarchyNamesForm",
  "aria-expanded": "false",
  "aria-controls": "hierarchyNamesForm"
}
const _hoisted_2 = { class: "card-title align-items-center justify-content-start" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = {
  id: "hierarchyNamesForm",
  class: "collapse"
}
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_7 = { class: "col-lg-8 fv-row" }
const _hoisted_8 = { class: "fv-plugins-message-container" }
const _hoisted_9 = { class: "fv-help-block" }
const _hoisted_10 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_11 = {
  type: "submit",
  id: "kt_account_profile_details_submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_12 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_MyForm = _resolveComponent("MyForm")!

  return (_openBlock(), _createBlock(_component_MyForm, {
    onSubmit: _ctx.handleConfigSubmit,
    "validation-schema": _ctx.hierarchyNamesValidator,
    novalidate: "novalidate",
    action: "javascript:",
    class: "card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('cdf.hierarchyNames.headline')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.names, (name) => {
            return (_openBlock(), _createElementBlock("div", {
              key: name.pos,
              class: "row mb-6"
            }, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('depth')) + " " + _toDisplayString(name.pos), 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Field, {
                  type: "text",
                  class: "form-control form-control-lg form-control-solid",
                  modelValue: name.name,
                  "onUpdate:modelValue": ($event: any) => ((name.name) = $event),
                  name: `names[${name.pos}].name`
                }, null, 8, ["modelValue", "onUpdate:modelValue", "name"]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ErrorMessage, {
                      name: `names[${name.pos}].name`
                    }, null, 8, ["name"])
                  ])
                ])
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('save')), 1)
          ], 512)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema"]))
}