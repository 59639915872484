<template>
  <!--begin::Dashboard-->
  <div>
    <ConfettiExplosion
      :stageWidth="computedWindowWidth"
      :stageHeight="computedWindowHeight"
      :duration="1500"
      v-if="data.showConfetti"
    />
    <div v-if="computedIsReady">
      <div class="row">
        <div class="col">
          <org-unit-path
            :node-path="computedProcessConfirmationLocations"
            :area-names="data.hierarchyNames"
          />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <!--begin::Tables Widget 9-->

          <router-link
            :to="computedBackLink"
            class="d-inline-block align-items-center text-muted mb-3"
          >
            <i class="fa fa-chevron-left me-2" />
            {{ $t('spc.processconfirmation.backToOverview') }}
          </router-link>
          <div class="card">
            <!--begin::Header-->

            <div v-if="data.processConfirmation" class="card-body">
              <div class="row p-md-3 pb-5">
                <div class="col-12 col-md-9 mb-3">
                  <div class="d-flex align-items-center">
                    <span
                      v-if="data.processConfirmation.questionCatalog"
                      class="me-3"
                    >
                      <span
                        class="svg-icon svg-icon-3hx svg-icon-primary"
                        v-if="!data.processConfirmation.questionCatalog.image"
                      >
                        <inline-svg
                          src="media/icons/duotune/general/gen046.svg"
                        />
                      </span>
                      <img
                        v-else
                        class="mw-50px mh-50px"
                        :src="
                          computedFileUrl(
                            data.processConfirmation.questionCatalog.image
                              .fileName,
                          )
                        "
                      />
                    </span>
                    <h3
                      class="card-title align-items-center justify-content-start mb-0"
                    >
                      <span class="card-label fw-bolder fs-3 me-3">{{
                        data.processConfirmation.name
                      }}</span>
                      <el-tooltip
                        v-if="data.processConfirmation.type === 'audit'"
                        class="box-item"
                        effect="light"
                        :content="$t('spc.processconfirmation.audit')"
                        placement="top"
                      >
                        <span
                          class="badge badge-primary badge-circle w-10px h-10px me-2"
                        ></span>
                      </el-tooltip>

                      <el-tooltip
                        v-if="
                          data.processConfirmation.type ===
                          'process_confirmation'
                        "
                        class="box-item"
                        effect="light"
                        :content="$t('spc.processconfirmation.single')"
                        placement="top"
                      >
                        <span
                          class="badge badge-info badge-circle w-10px h-10px me-2"
                        ></span>
                      </el-tooltip>
                    </h3>
                  </div>
                </div>
                <div
                  v-if="computedHasAssignee"
                  class="col-12 order-2 order-md-1 col-md-3 justify-content-start justify-content-md-end align-items-center d-flex"
                >
                  <span class="ms-3 me-md-0 me-0 order-2 order-md-1">{{
                    data.processConfirmation.assignee.name
                  }}</span>
                  <user-icon :user="data.processConfirmation.assignee" />
                </div>
                <div
                  v-if="data.processConfirmation.description"
                  class="col-12 mb-3 md-md-0 order-1 order-md-2 align-items-center order-1 order-md-2"
                >
                  <p class="text-gray-400 fw-bold fs-5">
                    <span>{{ data.processConfirmation.description }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end::Tables Widget 9-->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <!--begin::Tables Widget 9-->
          <div class="card">
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body py-3">
              <div class="row">
                <div class="col my-3">
                  <div class="d-block">
                    <a
                      href="javascript:"
                      :class="computedStepClass(questionAnswer)"
                      v-for="questionAnswer of computedQuestionAnswers"
                      :key="questionAnswer.id"
                      @click.prevent="handleStepClick(questionAnswer)"
                    >
                      <span class="step-number">{{ questionAnswer.pos }}</span>
                    </a>
                  </div>
                </div>
                <div class="col-auto d-sm-flex align-items-center d-none">
                  <div class="min-w-250px">
                    <el-progress
                      :percentage="computedProgressPercentage"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
            <!--begin::Body-->
          </div>
          <!--end::Tables Widget 9-->
        </div>
      </div>
      <div v-if="computedShowProcessView">
        <MyForm
          class="row mb-5"
          v-if="data.currentStep"
          @submit="handleAnswerSubmit"
        >
          <div class="col">
            <!--begin::Tables Widget 9-->
            <div class="card">
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body py-3">
                <a
                  v-if="computedHasCurrentQuestionMoreDetails"
                  href="javascript:"
                  data-bs-toggle="modal"
                  data-bs-target="#questionInfoModal"
                >
                  <i
                    class="fa fa-4x fa-info-circle position-absolute top-0 end-0 mt-5 me-5"
                  ></i>
                </a>
                <h1 class="p-10 text-center">
                  {{ data.currentStep.question.name }}
                </h1>
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-lg-4 mb-3">
                    <button
                      type="button"
                      :class="computedAnswerButtonClass('yes')"
                      @click="handleAnswerYes"
                    >
                      {{ $t('spc.processconfirmation.answers.yes') }}
                    </button>
                  </div>
                  <div class="col-12 col-lg-4 mb-3">
                    <button
                      ref="noButton"
                      type="button"
                      :class="computedAnswerButtonClass('no')"
                      @click="handleAnswerNo"
                    >
                      {{ $t('spc.processconfirmation.answers.no') }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-if="computedAnswerNoneAvailable"
                    class="col-12 col-lg-8 mb-3 mx-auto"
                  >
                    <button
                      type="button"
                      :class="computedAnswerButtonClass('none')"
                      @click="handleAnswerNone"
                    >
                      {{ $t('spc.processconfirmation.answers.none') }}
                    </button>
                  </div>
                </div>
                <div class="row mb-5">
                  <label class="col-12 col-form-label fw-bold fs-6">{{
                    $t('spc.processconfirmation.comment')
                  }}</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field
                      as="textarea"
                      type="text"
                      name="comment"
                      class="form-control form-control-lg form-control-solid"
                      v-model="data.currentStep.comment"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="comment" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="computedOpenPointNeeded">
                  <div class="row">
                    <label class="col-12 col-form-label fw-bold fs-6">{{
                      $t('spc.processconfirmation.openPointNeeded')
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-12 fv-row">
                      <p
                        class="mb-3 py-3"
                        v-if="data.currentStep.openPoints.length === 0"
                      >
                        {{
                          $t('spc.processconfirmation.noOpenPointsConnected')
                        }}
                      </p>
                      <div class="padding-cut" v-else>
                        <oplOpenPointOverview
                          ignoreLocalStorage
                          :disableHeader="true"
                          :disableCheckbox="true"
                          :disableItemsPerPageDropdown="true"
                          :onClick="handleOpenOpenPoint"
                          :rowsPerPage="5"
                          :openPoints="computedCurrentOpenPoints"
                          :translatedKey="data.lastEditCounter"
                        />
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="selectOpenPoints"
                      >
                        <i class="fa fa-plus"></i>
                        {{ $t('spc.processconfirmation.selectOpenPoints') }}
                      </button>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="comment" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-5">
                    <div class="col text-end">
                      <button
                        type="submit"
                        :disabled="!computedIsCurrentStepReady"
                        href="javascript:"
                        class="btn btn-primary"
                      >
                        {{ $t('continue') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--begin::Body-->
            </div>
            <!--end::Tables Widget 9-->
          </div>
        </MyForm>
      </div>
      <div v-if="computedShowResults">
        <div class="row mb-5" v-if="showOnlyErrorsCheckbox">
          <div class="col text-end">
            <el-checkbox
              v-model="data.showOnlyErrors"
              :label="$t('spc.processconfirmation.showOnlyErrors')"
            />
          </div>
        </div>
        <div
          class="row mb-5"
          v-for="questionAnswer of computedCompletedQuestionAnswers"
          :key="questionAnswer.id"
        >
          <div class="col">
            <!--begin::Tables Widget 9-->
            <div :class="computedAnswerClass(questionAnswer)">
              <!--begin::Header-->

              <div class="card-body">
                <div class="row mb-md-10">
                  <div class="col-6 col-md-1 d-flex align-items-center">
                    <span :class="computedStepClass(questionAnswer)">
                      <span class="step-number">{{ questionAnswer.pos }}</span>
                    </span>
                  </div>
                  <div
                    class="col-12 order-2 order-md-1 col-md-9 d-flex flex-column mt-5 mt-md-0"
                  >
                    <span class="fs-1 mb-3">{{
                      questionAnswer.question.name
                    }}</span>
                    <div>
                      <p class="text-muted" v-if="questionAnswer.comment">
                        {{ questionAnswer.comment }}
                      </p>
                      <span v-else class="badge badge-light">{{
                        $t('spc.processconfirmation.noComment')
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="col-6 order-1 order-md-2 col-md-2 d-flex flex-column align-items-end justify-content-center"
                  >
                    <div class="d-none d-md-block">
                      <span class="badge badge-light-primary mb-3">
                        <i class="fa fa-clock fs-8 text-primary me-2"></i>
                        {{ computedTime(questionAnswer.startedAt) }}
                      </span>
                    </div>
                    <div class="d-none d-md-block">
                      <span class="badge badge-primary mb-3">
                        <i class="fa fa-clock fs-8 text-white me-2"></i>
                        {{ computedTime(questionAnswer.completedAt) }}
                      </span>
                    </div>
                    <div>
                      <span class="badge badge-light">
                        <i class="fa fa-clock fs-8 me-2"></i>
                        {{ computedDuration(questionAnswer) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="computedShowOpenPoints(questionAnswer)">
                  <div class="col">
                    <el-divider />
                    <h5>
                      {{ $t('spc.processconfirmation.connectedOpenPoints') }}
                    </h5>
                    <div class="padding-cut">
                      <oplOpenPointOverview
                        ignoreLocalStorage
                        :disableHeader="true"
                        :disableCheckbox="true"
                        :disableItemsPerPageDropdown="true"
                        :onClick="handleOpenOpenPoint"
                        :rowsPerPage="5"
                        :openPoints="questionAnswer.openPoints"
                        :translatedKey="data.lastEditCounter"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Tables Widget 9-->
          </div>
        </div>
      </div>
    </div>
    <side-drawer
      id="spcOpenPointDrawer"
      :title="$t('spc.processconfirmation.connectedOpenPoints')"
      :width="computedSideDrawerWidth"
    >
      <org-unit-path
        v-if="computedIsOpenPointSelected"
        :nodePath="computedProcessConfirmationLocations"
        :areaNames="data.hierarchyNames"
      />

      <div class="row" v-if="computedReadyToLoadOpenPoints">
        <div class="col">
          <oplOpenPointOverview
            :onlyLocation="true"
            :onlyManagementLevel="true"
            :disableHeader="true"
            :disableCheckbox="true"
            :disableItemsPerPageDropdown="true"
            :onClick="handleOpenPointSelect"
            :rowsPerPage="5"
            :filterFunction="computedSelectableOpenPoints"
          />
        </div>
      </div>
      <div class="row row-cols-auto" v-if="data.selectedOpenPointId !== null">
        <div class="col">
          <oplSingleView
            disable-edit
            :backAction="handleBackSelectedOpenPoint"
            :openPointId="data.selectedOpenPointId"
          />
          <button
            v-if="!computedProcessConfirmationIsCompleted"
            class="btn btn-primary mt-4"
            @click="handleConnectOpenPoint"
          >
            Diese Maßnahme verknüpfen
          </button>
        </div>
      </div>

      <div v-show="data.selectedOpenPointId === null">
        <el-divider class="mb-20">
          {{ $t('spc.processconfirmation.and') }} /
          {{ $t('spc.processconfirmation.or') }}
        </el-divider>

        <MyForm
          class="row mb-5"
          @submit="handleCreateOpenPoints"
          :validation-schema="createOpenPointsValidator"
        >
          <open-point-collection-form
            :headline="$t('spc.processconfirmation.newOpenPoints')"
            :model-value="data.createdOpenPoints"
            @update:modelValue="handleUpdateOpenPoints"
          />
          <div
            v-show="data.createdOpenPoints.length"
            class="col-12 text-end pe-12"
          >
            <button type="submit" class="btn btn-primary">
              {{ $t('spc.processconfirmation.createOpenPointAndConnect') }}
            </button>
          </div>
        </MyForm>
      </div>
    </side-drawer>

    <side-drawer
      id="spcOpenPointCreateDrawer"
      title="Neue Maßnahme"
      :width="computedSideDrawerWidth"
    >
      <org-unit-path
        :nodePath="computedProcessConfirmationLocations"
        :areaNames="data.hierarchyNames"
      />
      <div class="row">
        <div class="col">
          <div class="padding-cut">
            <open-point-collection-form
              :headline="$t('spc.processconfirmation.newOpenPoints')"
              :model-value="data.createdOpenPoints"
              @update:modelValue="handleUpdateOpenPoints"
            />
          </div>
        </div>
      </div>
    </side-drawer>

    <side-drawer
      id="spcOpenPointViewDrawer"
      :title="computedCurrentOpenPointTitle"
      :width="computedSideDrawerWidth"
    >
      <div class="row row-cols-auto">
        <div class="col" v-if="computedHasCurrentOpenPoint">
          <oplSingleView
            disable-edit
            :key="data.currentOpenPointId"
            :backAction="handleBackCurrentOpenPoint"
            :openPointId="data.currentOpenPointId"
          />
          <button
            v-if="!computedProcessConfirmationIsCompleted"
            class="btn btn-danger mt-10"
            @click="handleDisconnectOpenPoint"
          >
            Diese Maßnahme lösen
          </button>
        </div>
      </div>
    </side-drawer>

    <div
      class="modal fade"
      id="questionInfoModal"
      ref="questionInfoModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_create_api_key_header">
            <!--begin::Modal title-->
            <h2>{{ $t('spc.question.moreDetails') }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body p-10" v-if="computedCurrentStepId !== -1">
            <div class="row mb-5 fw-bold fs-5">
              <div class="col-3">{{ $t('spc.question.title') }}:</div>
              <div class="col">{{ computedCurrentQuestion.name }}</div>
            </div>
            <div class="row mb-5" v-if="computedCurrentQuestion.externalLink">
              <div class="col-3">{{ $t('spc.question.externalLink') }}:</div>
              <div class="col">
                <a :href="computedCurrentQuestion.externalLink" target="_blank"
                  >{{ computedCurrentQuestion.externalLink }}
                  <i class="fa fa-external-link-alt"
                /></a>
              </div>
            </div>
            <div
              class="row mb-5"
              v-if="computedCurrentQuestion.attachments.length"
            >
              <div class="col-3">{{ $t('spc.question.images') }}:</div>
              <div class="col">
                <el-badge :value="computedCurrentQuestion.attachments.length">
                  <el-image
                    style="width: 120px; height: 120px"
                    :src="computedCurrentPreviewImageUrl"
                    :preview-src-list="computedCurrentPreviewList"
                    fit="cover"
                    :preview-teleported="true"
                  />
                </el-badge>
              </div>
            </div>
            <div class="row mb-5" v-if="computedCurrentQuestion.hint">
              <div class="col-3">Hinweis:</div>
              <div class="col" v-html="computedCurrentQuestion.hint"></div>
            </div>
          </div>
          <!--end::Modal body-->

          <!--end::Modal footer-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import {
    ConnectOpenPointDto,
    HierarchyName,
    ProcessConfirmation,
    ProcessConfirmationLocation,
    ProcessConfirmationState,
    ProcessConfirmationType,
    QuestionAnswer,
    QuestionAnswerDto,
    QuestionAnswerValue,
    QuestionAttachment,
    SPCConfig,
  } from '@/core/models/spc';
  import { OpenPoint } from '@/core/models/opl';
  import { ElNotification } from 'element-plus';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute, useRouter } from 'vue-router';
  import ApiService from '@/core/services/ApiService';
  import { useStore } from 'vuex';
  import Swal from 'sweetalert2';
  import { useI18n } from 'vue-i18n';
  import { AxiosResponse } from 'axios';
  import OrgUnitPath from '@/components/fes/OrgUnitPath.vue';
  import * as yup from 'yup';
  import oplOpenPointOverview from '@/views/opl/OplOverview.vue';
  import SideDrawer from '@/components/SideDrawer.vue';
  import {
    closeSideDrawer,
    computedTime,
    openSideDrawer,
  } from '@/core/helpers/cdf';
  import oplSingleView from '@/views/opl/OplSingle.vue';
  import moment from 'moment';
  import ConfettiExplosion from 'vue-confetti-explosion';
  import UserIcon from '@/components/UserIcon.vue';
  import OpenPointCollectionForm from '@/components/opl/OpenPointCollectionForm.vue';

  export default defineComponent({
    name: 'processConfirmationSingle',
    components: {
      OpenPointCollectionForm,
      MyForm: Form,
      Field,
      ErrorMessage,
      OrgUnitPath,
      oplOpenPointOverview,
      oplSingleView,
      // OpenPointRow,
      SideDrawer,
      ConfettiExplosion,
      UserIcon,
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { t } = useI18n();

      const data: {
        processConfirmation: ProcessConfirmation | null;
        currentStep: QuestionAnswer | null;
        hierarchyNames: HierarchyName[];
        selectedOpenPoint: OpenPoint | null;
        selectedOpenPointId: number | null;
        selectableOpenPoints: OpenPoint[];
        createdOpenPoints: OpenPoint[];
        currentOpenPoint: OpenPoint | null;
        currentOpenPointId: number | null;
        highestStepNumber: number;
        lastEditCounter: number;
        showConfetti: boolean;
        showOnlyErrors: boolean;
      } = reactive({
        processConfirmation: null,
        currentStep: null,
        hierarchyNames: [],
        selectedOpenPoint: null,
        selectedOpenPointId: null,
        selectableOpenPoints: [],
        createdOpenPoints: [],
        currentOpenPoint: null,
        currentOpenPointId: null,
        highestStepNumber: 0,
        lastEditCounter: 0,
        showConfetti: false,
        showOnlyErrors: true,
      });

      const noButton = ref<HTMLButtonElement>();

      const computedHasCurrentQuestionMoreDetails = computed(() => {
        if (data.currentStep === null) return false;
        const currentQuestion = data.currentStep.question;
        return (
          (currentQuestion.hint && currentQuestion.hint.length > 0) ||
          currentQuestion.attachments.length > 0 ||
          (currentQuestion.externalLink &&
            currentQuestion.externalLink.length > 0)
        );
      });

      const computedBackLink = computed(() => {
        if (
          typeof window.history !== 'undefined' &&
          typeof window.history.state !== 'undefined' &&
          typeof window.history.state.back !== 'undefined' &&
          window.history.state.back
        )
          return window.history.state.back;
        return '/spc';
      });

      const computedAnswerClass = computed(() => {
        return (questionAnswer: QuestionAnswer) => {
          if (questionAnswer.answer === QuestionAnswerValue.YES)
            return 'card border border-success';
          if (questionAnswer.answer === QuestionAnswerValue.NO)
            return 'card border border-danger';
          if (questionAnswer.answer === QuestionAnswerValue.NONE)
            return 'card border border-gray';
          return 'card';
        };
      });

      // :src="computedCurrentPreviewImageUrl"
      // :preview-src-list="computedCurrentPreviewList"

      const computedCurrentPreviewImageUrl = computed(() => {
        if (data.currentStep === null) return '';
        const firstAttachment = data.currentStep.question.attachments[0];
        return computedQuestionImageFileUrl.value(firstAttachment.fileName);
      });

      const computedCurrentPreviewList = computed(() => {
        if (data.currentStep === null) return [];
        return data.currentStep.question.attachments.map(
          (attachment: QuestionAttachment) => {
            return computedQuestionImageFileUrl.value(attachment.fileName);
          },
        );
      });

      const computedCurrentQuestion = computed(() => {
        if (data.currentStep === null) return null;
        return data.currentStep.question;
      });

      const i18n = useI18n();
      const computedCurrentLanguage = computed(() => {
        return i18n.locale.value;
      });

      const computedDuration = computed(() => {
        return (questionAnswer: QuestionAnswer) => {
          const duration = moment.duration(
            moment(questionAnswer.completedAt).diff(questionAnswer.startedAt),
          );
          return duration.locale(computedCurrentLanguage.value).humanize();
        };
      });

      const computedShowOpenPoints = computed(() => {
        return (questionAnswer: QuestionAnswer) => {
          return (
            questionAnswer.answer === QuestionAnswerValue.NO &&
            questionAnswer.openPointIds.length > 0
          );
        };
      });

      const computedIsReady = computed(() => {
        return data.processConfirmation !== null;
      });

      const computedCurrentOpenPoint = computed(() => {
        return data.currentOpenPoint;
      });

      const computedCurrentOpenPointTitle = computed((): string => {
        if (data.currentOpenPoint === null) return '';
        return data.currentOpenPoint.title;
      });

      const computedHasCurrentOpenPoint = computed(() => {
        return data.currentOpenPoint !== null;
      });

      const computedWindowWidth = computed(() => {
        return window.innerWidth - 50;
      });

      const computedWindowHeight = computed(() => {
        return window.innerHeight - 50;
      });

      const computedSideDrawerWidth = computed(() => {
        if (computedWindowWidth.value < 1024) {
          return '100vw';
        }
        return '75vw';
      });

      const showOnlyErrorsCheckbox = computed(() => {
        if (data.processConfirmation === null) return false;
        return (
          computedHasNoAnswers.value &&
          !data.processConfirmation.answers.every(
            (questionAnswer: QuestionAnswer) => {
              return questionAnswer.answer === QuestionAnswerValue.NO;
            },
          )
        );
      });

      const computedHasNoAnswers = computed(() => {
        if (data.processConfirmation === null) return false;
        return data.processConfirmation.answers.some(
          (questionAnswer: QuestionAnswer) => {
            return questionAnswer.answer === QuestionAnswerValue.NO;
          },
        );
      });

      async function loadProcessConfirmation(processConfirmationId) {
        try {
          const loadedProcessConfirmation: ProcessConfirmation = await (
            await ApiService.get(`spc/audit/${processConfirmationId}`)
          ).data;

          data.processConfirmation = loadedProcessConfirmation;
          if (
            loadedProcessConfirmation.state === ProcessConfirmationState.OPEN &&
            loadedProcessConfirmation.assignee === null
          ) {
            await startProcessConfirmation();

            const textSlug =
              data.processConfirmation.type ===
              ProcessConfirmationType.PROCESS_CONFIRMATION
                ? t('spc.processconfirmation.success.started.text')
                : t('spc.processconfirmation.success.started.textAudit');

            ElNotification({
              title: t('spc.processconfirmation.success.started.title'),
              message: textSlug,
              type: 'success',
            });
          }

          if (computedProcessConfirmationIsCompleted.value === true) {
            data.showOnlyErrors = computedHasNoAnswers.value !== false;
          }
        } catch (error) {
          console.error('ERROR: ', error);
          router.push('/spc/audit/overview');
          return Swal.fire(
            t('error'),
            t('spc.processconfirmation.errors.notFound'),
            'error',
          );
        }
      }

      const computedSelectableOpenPoints = computed(() => {
        return (openPoints: OpenPoint[]) => {
          if (data.currentStep === null) return [];
          return openPoints.filter((openPoint: OpenPoint) => {
            if (typeof openPoint.id === 'undefined') return false;
            if (openPoint.isUrgent === true) return false;
            if (data.currentStep === null) return false;
            return (
              data.currentStep.openPointIds.findIndex((openPointId: number) => {
                return String(openPointId) === String(openPoint.id);
              }) === -1
            );
          });
        };
      });

      const computedProgressPercentage = computed(() => {
        if (data.processConfirmation === null) return 0;
        let answeredQuestions = 0;

        data.processConfirmation.answers.forEach(
          (questionAnswer: QuestionAnswer) => {
            if (
              questionAnswer.answer !== null &&
              questionAnswer.completedAt !== null
            )
              answeredQuestions++;
          },
        );

        return Math.round(
          (answeredQuestions / data.processConfirmation.answers.length) * 100,
        );
      });

      const computedCurrentOpenPoints = computed(() => {
        if (data.currentStep === null) return [];
        return data.currentStep.openPoints.filter(
          (openPoint: OpenPoint) => !!openPoint.id,
        );
      });

      const computedCompletedQuestionAnswers = computed(
        (): QuestionAnswer[] => {
          return computedQuestionAnswers.value.filter(
            (questionAnswer: QuestionAnswer) => {
              return data.showOnlyErrors
                ? questionAnswer.answer === QuestionAnswerValue.NO
                : true;
            },
          );
        },
      );

      const computedQuestionAnswers = computed((): QuestionAnswer[] => {
        if (data.processConfirmation === null) return [];
        return data.processConfirmation.answers;
      });

      const computedAnswerNoneAvailable = computed(() => {
        if (data.currentStep === null) return false;
        return data.currentStep.question.noneAllowed;
      });

      const computedOpenPointNeeded = computed(() => {
        if (data.currentStep === null) return false;
        return data.currentStep.answer === QuestionAnswerValue.NO;
      });

      const computedHasAssignee = computed(() => {
        return data.processConfirmation?.assignee !== null;
      });

      const computedIsStarted = computed((): boolean => {
        if (data.processConfirmation === null) return false;
        if (data.processConfirmation.state === 'progress') return true;
        return false;
      });

      const computedStepClass = computed(() => {
        return (questionAnswer: QuestionAnswer): string => {
          const classes = ['step-badge'];
          if (data.currentStep !== null) {
            if (questionAnswer.pos === data.currentStep.pos) {
              classes.push('active pe-none');
              return classes.join(' ');
            }
            if (questionAnswer.pos > data.highestStepNumber) {
              classes.push('pe-none');
            }
          }
          if (questionAnswer.answer === QuestionAnswerValue.YES) {
            classes.push('success');
          }
          if (questionAnswer.answer === QuestionAnswerValue.NO) {
            classes.push('danger');
          }
          if (questionAnswer.answer === QuestionAnswerValue.NONE) {
            classes.push('bg-light');
          }

          return classes.join(' ');
        };
      });

      const computedCurrentStepId = computed(() => {
        if (data.processConfirmation === null) return -1;
        if (data.currentStep === null) return -1;
        return data.processConfirmation.answers.findIndex(
          (questionAnswer: QuestionAnswer) => {
            return questionAnswer.id === data.currentStep?.id;
          },
        );
      });

      const computedShowResults = computed(() => {
        if (data.processConfirmation === null) return false;
        if (data.processConfirmation.assignee === null) return false;
        if (computedProcessConfirmationIsCompleted.value === true) return true;
        return false;
      });

      const computedShowProcessView = computed(() => {
        if (computedProcessConfirmationIsCompleted.value === true) {
          return false;
        }

        if (data.processConfirmation === null) {
          return false;
        }

        if (data.processConfirmation.assignee === null) {
          return false;
        }

        return true;
      });

      const computedIsCurrentStepReady = computed(() => {
        if (data.currentStep === null) return false;
        if (data.currentStep.answer === null) return false;
        if (
          data.currentStep.openPoints.length === 0 &&
          data.currentStep.question.openPointNeeded === true
        )
          return false;
        return true;
      });

      const computedAnswerButtonClass = computed(() => {
        return (type: string) => {
          const classes = ['btn btn-light w-100'];
          switch (type) {
            case 'yes': {
              classes.push(
                'btn-active-light-success min-h-150px fs-1 fw-bolder',
              );
              if (
                data.currentStep !== null &&
                data.currentStep.answer === QuestionAnswerValue.YES
              ) {
                classes.push('active');
              }
              // else if (
              //   data.currentStep !== null &&
              //   data.currentStep.answer !== null
              // ) {
              //   classes.push('disabled');
              // }
              break;
            }
            case 'no': {
              classes.push(
                'btn-active-light-danger min-h-150px fs-1 fw-bolder',
              );
              if (
                data.currentStep !== null &&
                data.currentStep.answer === QuestionAnswerValue.NO
              ) {
                classes.push('active');
              }
              // else if (
              //   data.currentStep !== null &&
              //   data.currentStep.answer !== null
              // ) {
              //   classes.push('disabled');
              // }
              break;
            }
            case 'none': {
              if (
                data.currentStep !== null &&
                data.currentStep.answer === QuestionAnswerValue.NONE
              ) {
                classes.push('active');
              }
              // else if (
              //   data.currentStep !== null &&
              //   data.currentStep.answer !== null
              // ) {
              //   classes.push('disabled');
              // }
              break;
            }
          }
          return classes.join(' ');
        };
      });

      const computedCurrentAnswerIsNo = computed(() => {
        if (data.currentStep === null) return false;
        return data.currentStep.answer === QuestionAnswerValue.NO;
      });

      const computedProcessConfirmationLocations = computed(() => {
        if (data.processConfirmation === null) return [];
        return data.processConfirmation.locations.map(
          (processConfirmationLocation: ProcessConfirmationLocation) => {
            return {
              ...processConfirmationLocation,
              name: processConfirmationLocation.locationName,
            };
          },
        );
      });

      const computedIsOpenPointSelected = computed(() => {
        return data.selectedOpenPoint === null;
      });

      const computedFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/questioncatalog/image/' +
            fileName
          );
        };
      });

      const computedQuestionImageFileUrl = computed(() => {
        return (fileName) => {
          return (
            ApiService.vueInstance.axios.defaults.baseURL +
            '/spc/attachment/' +
            fileName
          );
        };
      });

      const computedOrgLevelHierarchy = (index): string => {
        return data.hierarchyNames[index]
          ? data.hierarchyNames[index].name
          : `Level ${index}`;
      };

      const computedProcessConfirmationIsCompleted = computed(() => {
        if (data.processConfirmation === null) return false;
        if (data.processConfirmation.state === ProcessConfirmationState.DONE)
          return true;
        return false;
      });

      const computedReadyToLoadOpenPoints = computed(() => {
        if (data.currentStep === null) return false;
        if (data.selectedOpenPointId !== null) return false;
        if (data.currentStep.answer === null) return false;
        if (data.currentStep.answer === QuestionAnswerValue.NO) return true;
        return false;
      });

      const createOpenPointsValidator = yup.object().shape({
        openPoints: yup.array().of(
          yup.object().shape({
            title: yup
              .string()
              .min(1)
              .required(t('opl.errors.missingTitle'))
              .typeError(t('opl.errors.missingTitle')),
            comment: yup
              .string()
              .when('isUrgent', {
                is: (value) => {
                  return !value;
                },
                then: yup
                  .string()
                  .min(1)
                  .required(t('fes.form.errors.missingComment')),
              })

              .typeError(t('opl.errors.missingComment')),
            initialAssignees: yup.array().when('isUrgent', {
              is: false,
              then: yup
                .array()
                .min(1, t('opl.errors.missingAssignee'))
                .required(t('opl.errors.missingAssignee'))
                .typeError(t('opl.errors.missingAssignee')),
            }),
            isUrgent: yup.boolean(),
            dueDate: yup
              .string()
              .label(t('opl.form.dueDate'))
              .when('isUrgent', {
                is: false,
                then: yup
                  .string()
                  .required(t('opl.errors.missingDueDate'))
                  .typeError(t('opl.errors.missingDueDate')),
              }),
            children: yup.array().of(
              yup.object().shape({
                title: yup
                  .string()
                  .min(1)
                  .required(t('opl.errors.missingTitle'))
                  .typeError(t('opl.errors.missingTitle')),
                comment: yup
                  .string()
                  .when('isUrgent', {
                    is: (value) => {
                      return !value;
                    },
                    then: yup
                      .string()
                      .min(1)
                      .required(t('fes.form.errors.missingComment')),
                  })

                  .typeError(t('opl.errors.missingComment')),
                initialAssignees: yup.array().when('isUrgent', {
                  is: false,
                  then: yup
                    .array()
                    .min(1, t('opl.errors.missingAssignee'))
                    .required(t('opl.errors.missingAssignee'))
                    .typeError(t('opl.errors.missingAssignee')),
                }),
                isUrgent: yup.boolean(),
                dueDate: yup
                  .string()
                  .label(t('opl.form.dueDate'))
                  .when('isUrgent', {
                    is: false,
                    then: yup
                      .string()
                      .required(t('opl.errors.missingDueDate'))
                      .typeError(t('opl.errors.missingDueDate')),
                  }),
              }),
            ),
          }),
        ),
      });

      function selectOpenPoints() {
        data.selectedOpenPoint = null;
        data.selectedOpenPointId = null;
        openSideDrawer('spcOpenPointDrawer');
      }

      async function handleOpenOpenPoint(openPoint: OpenPoint) {
        if (typeof openPoint.id === 'undefined') return;
        data.currentOpenPoint = openPoint;
        data.currentOpenPointId = openPoint.id;
        openSideDrawer('spcOpenPointViewDrawer');
      }

      function handleUpdateOpenPoints(openPoints: OpenPoint[]) {
        const currentLocations = computedProcessConfirmationLocations.value.map(
          (orgLevel, index) => {
            const orgLevelIdentifier = computedOrgLevelHierarchy(index);
            return {
              locationIdentifier: orgLevelIdentifier,
              locationName: String(orgLevel?.name),
              locationId: String(orgLevel?.id),
              pos: index,
            };
          },
        );
        data.createdOpenPoints = openPoints.map((openPoint: OpenPoint) => {
          openPoint.locations = currentLocations.slice(0);
          openPoint.children.map((openPointChild: OpenPoint) => {
            openPointChild.locations = currentLocations.slice(0);
            return openPointChild;
          });
          return openPoint;
        });
      }

      function setStep(id: number) {
        if (data.processConfirmation === null) return;

        data.currentStep = Object.assign(
          {},
          data.processConfirmation.answers[id],
        );
        data.highestStepNumber =
          data.currentStep.pos > data.highestStepNumber
            ? data.currentStep.pos
            : data.highestStepNumber;

        if (data.currentStep.openPoints.length) {
          data.selectedOpenPoint = data.currentStep.openPoints[0];
          data.selectedOpenPointId = data.currentStep.openPoints[0]
            .id as number;
        } else {
          data.selectedOpenPoint = null;
          data.selectedOpenPointId = null;
        }
        data.lastEditCounter++;
      }

      function goToNextStep() {
        const nextStepId = computedCurrentStepId.value + 1;

        if (
          data.processConfirmation !== null &&
          nextStepId === data.processConfirmation.answers.length
        ) {
          const textSlug =
            data.processConfirmation.type ===
            ProcessConfirmationType.PROCESS_CONFIRMATION
              ? t('spc.processconfirmation.success.completed.text')
              : t('spc.processconfirmation.success.completed.textAudit');

          ElNotification({
            title: t('spc.processconfirmation.success.completed.title'),
            message: textSlug,
            type: 'success',
          });

          if (computedHasNoAnswers.value === false) {
            data.showOnlyErrors = false;
          }

          data.currentStep = null;
          data.showConfetti = true;
          // return;
          return setTimeout(() => {
            data.showConfetti = false;
            // router.push({
            //   path: '/spc/processconfirmation/overview',
            // });
          }, 1500);
        }

        setStep(nextStepId);
      }

      async function handleAnswerNo() {
        if (data.processConfirmation === null) return false;
        if (data.currentStep === null) return false;
        data.currentStep.answer = QuestionAnswerValue.NO;
      }

      function handleBackSelectedOpenPoint() {
        data.selectedOpenPoint = null;
        data.selectedOpenPointId = null;
      }

      function handleBackCurrentOpenPoint() {
        data.currentOpenPoint = null;
        data.currentOpenPointId = null;
        closeSideDrawer('spcOpenPointViewDrawer');
      }

      async function handleAnswerYes(event: MouseEvent) {
        if (data.processConfirmation === null) return false;
        if (data.currentStep === null) return false;

        store.dispatch(Actions.START_LOADER);

        data.currentStep.answer = QuestionAnswerValue.YES;

        const answerRequest: AxiosResponse<ProcessConfirmation> =
          await ApiService.post(`spc/audit/answer/${data.currentStep.id}`, {
            data: {
              answer: QuestionAnswerValue.YES,
              comment: data.currentStep.comment || null,
            },
          });

        data.processConfirmation = answerRequest.data as ProcessConfirmation;

        store.dispatch(Actions.END_LOADER);
        goToNextStep();

        const target = event.target as HTMLElement;
        target.blur();
      }

      function handleStepClick(questionAnswer: QuestionAnswer) {
        if (questionAnswer.pos > data.highestStepNumber) {
          return false;
        }
        setStep(questionAnswer.pos - 1);
      }

      async function handleAnswerNone(event: MouseEvent) {
        if (data.processConfirmation === null) return false;
        if (data.currentStep === null) return false;

        store.dispatch(Actions.START_LOADER);

        const answerRequest: AxiosResponse<ProcessConfirmation> =
          await ApiService.post(`spc/audit/answer/${data.currentStep.id}`, {
            data: {
              answer: QuestionAnswerValue.NONE,
              comment: data.currentStep.comment || null,
            },
          });

        data.processConfirmation = answerRequest.data as ProcessConfirmation;

        store.dispatch(Actions.END_LOADER);
        goToNextStep();

        const target = event.target as HTMLElement;
        target.blur();
      }

      async function startProcessConfirmation() {
        if (data.processConfirmation === null) return;

        store.dispatch(Actions.START_LOADER);

        const startRequest = await ApiService.get(
          `spc/audit/${data.processConfirmation.id}/start`,
        );
        const startedProcessConfirmation: ProcessConfirmation =
          startRequest.data;

        data.processConfirmation = startedProcessConfirmation;

        setStep(0);

        store.dispatch(Actions.END_LOADER);
      }

      async function handleCreateOpenPoints() {
        if (data.currentStep === null) return false;
        if (data.createdOpenPoints.length === 0) return false;
        store.dispatch(Actions.START_LOADER);
        const response = await ApiService.post('opl/create', {
          data: data.createdOpenPoints,
        });

        response.data.forEach((openPoint: OpenPoint) => {
          if (data.currentStep !== null) {
            data.currentStep.openPoints.push(openPoint);
          }
        });

        data.createdOpenPoints = [];

        await submitCurrentAnswer(false);

        store.dispatch(Actions.END_LOADER);
        closeSideDrawer('spcOpenPointDrawer');
      }

      async function submitCurrentAnswer(redirect = true) {
        if (data.processConfirmation === null) return false;
        if (data.currentStep === null) return false;

        store.dispatch(Actions.START_LOADER);

        const answerRequest: AxiosResponse<ProcessConfirmation> =
          await ApiService.post(`spc/audit/answer/${data.currentStep.id}`, {
            data: {
              answer: data.currentStep.answer,
              comment: data.currentStep.comment,
              openPoints: data.currentStep.openPoints,
            } as QuestionAnswerDto,
          });

        data.processConfirmation = answerRequest.data as ProcessConfirmation;

        store.dispatch(Actions.END_LOADER);

        if (redirect === true) {
          goToNextStep();
        }
      }

      async function handleAnswerSubmit() {
        if (data.processConfirmation === null) return false;
        if (data.currentStep === null) return false;

        if (data.currentStep.answer === QuestionAnswerValue.NO) {
          if (
            data.currentStep.openPoints.length === 0 &&
            data.currentStep.question.openPointNeeded === true
          ) {
            ElNotification({
              title: t('spc.processconfirmation.errors.noOpenPoint.title'),
              message: t('spc.processconfirmation.errors.noOpenPoint.text'),
              type: 'error',
            });
            return false;
          }
        }

        await submitCurrentAnswer();

        if (noButton.value) {
          noButton.value.blur();
        }
      }

      async function handleDisconnectOpenPoint() {
        if (data.currentStep === null) return;
        if (data.currentOpenPoint === null) return;

        data.currentStep.openPoints = data.currentStep.openPoints.filter(
          (openPoint: OpenPoint) => {
            return openPoint.id !== data.currentOpenPoint?.id;
          },
        );
        data.currentStep.openPointIds = data.currentStep.openPoints
          .filter((openPoint) => {
            typeof openPoint.id !== 'undefined';
          })
          .map((openPoint: OpenPoint) => {
            return openPoint.id;
          }) as number[];

        store.dispatch(Actions.START_LOADER);

        await ApiService.post(`spc/audit/disconnect/openpoint`, {
          data: {
            openPoint: data.currentOpenPoint,
            answer: data.currentStep,
          } as ConnectOpenPointDto,
        });

        store.dispatch(Actions.END_LOADER);

        data.currentOpenPoint = null;
        closeSideDrawer('spcOpenPointViewDrawer');
        data.lastEditCounter++;
      }

      async function handleConnectOpenPoint() {
        if (data.currentStep === null) return;
        if (data.selectedOpenPoint === null) return;

        const openPoint = Object.assign({}, data.selectedOpenPoint);

        if (typeof openPoint.id === 'undefined') return;

        data.currentStep.openPoints.push(openPoint);
        data.currentStep.openPointIds.push(openPoint.id);

        store.dispatch(Actions.START_LOADER);

        await ApiService.post(`spc/audit/connect/openpoint`, {
          data: {
            openPoint: data.selectedOpenPoint,
            answer: data.currentStep,
          } as ConnectOpenPointDto,
        });

        store.dispatch(Actions.END_LOADER);

        data.selectedOpenPoint = null;
        closeSideDrawer('spcOpenPointDrawer');
        data.lastEditCounter++;
      }

      function handleOpenPointSelect(openPoint: OpenPoint) {
        if (data.currentStep === null) return;
        if (typeof openPoint.id === 'undefined') return;
        data.selectedOpenPoint = openPoint;
        data.selectedOpenPointId = openPoint.id;
      }

      onMounted(async () => {
        store.dispatch(Actions.START_LOADER);

        const spcConfig: SPCConfig = (await ApiService.get('spc/config')).data;
        data.hierarchyNames = spcConfig.hierarchyNames.slice(0);

        const processConfirmationId = route.params.id;

        await loadProcessConfirmation(processConfirmationId);

        if (data.processConfirmation !== null) {
          const firstUnansweredQuestion = data.processConfirmation.answers.find(
            (questionAnswer: QuestionAnswer) => {
              return questionAnswer.answer === null;
            },
          );
          if (firstUnansweredQuestion !== undefined) {
            data.highestStepNumber = firstUnansweredQuestion.pos;
            data.currentStep = firstUnansweredQuestion;
          }
        }

        store.dispatch(Actions.END_LOADER);
      });

      const computeds = {
        computedIsReady,
        computedQuestionAnswers,
        computedCompletedQuestionAnswers,
        computedIsStarted,
        computedProgressPercentage,
        computedStepClass,
        computedAnswerNoneAvailable,
        computedAnswerButtonClass,
        computedCurrentAnswerIsNo,
        computedOpenPointNeeded,
        computedHasAssignee,
        computedSelectableOpenPoints,
        computedIsCurrentStepReady,
        computedProcessConfirmationLocations,
        computedFileUrl,
        computedQuestionImageFileUrl,
        computedCurrentOpenPoints,
        computedProcessConfirmationIsCompleted,
        computedSideDrawerWidth,
        computedReadyToLoadOpenPoints,
        computedCurrentOpenPointTitle,
        computedHasCurrentOpenPoint,
        computedCurrentOpenPoint,
        computedIsOpenPointSelected,
        computedCurrentStepId,
        computedShowOpenPoints,
        computedDuration,
        computedTime,
        computedWindowWidth,
        computedWindowHeight,
        computedBackLink,
        computedCurrentQuestion,
        computedCurrentPreviewImageUrl,
        computedCurrentPreviewList,
        computedAnswerClass,
        computedHasCurrentQuestionMoreDetails,
        computedShowProcessView,
        computedShowResults,
        computedHasNoAnswers,
        showOnlyErrorsCheckbox,
      };

      const methods = {
        startProcessConfirmation,
        handleAnswerYes,
        handleAnswerNo,
        handleAnswerNone,
        handleUpdateOpenPoints,
        handleOpenOpenPoint,
        handleAnswerSubmit,
        handleStepClick,
        handleOpenPointSelect,
        selectOpenPoints,
        handleBackSelectedOpenPoint,
        handleBackCurrentOpenPoint,
        handleConnectOpenPoint,
        handleDisconnectOpenPoint,
        handleCreateOpenPoints,
      };

      return {
        data,
        ...computeds,
        ...methods,
        createOpenPointsValidator,
      };
    },
  });
</script>

<style scoped>
  .step-badge {
    font-weight: 600;
    color: #009ef7 !important;
    font-size: 1.25rem;
    border-radius: 0.475rem;
    background-color: #f1faff;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .step-badge.active {
    background-color: #009ef7;
  }
  .step-badge.success {
    background-color: #e8fff3;
  }
  .step-badge.active .step-number {
    color: #fff;
  }
  .step-badge.success .step-number {
    color: #50cd89;
  }
  .step-badge.danger {
    background-color: #fff5f8;
  }
  .step-badge.danger .step-number {
    color: #f1416c;
  }
  .step-badge.bg-light .step-number {
    color: #7e8299 !important;
  }
  .step-badge:not(:last-of-type) {
    margin-right: 1rem;
  }
  .padding-cut {
    margin-left: -2.25em;
    margin-right: -2.25em;
  }
</style>
