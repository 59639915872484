<template>
  <process-confirmation-overview :onlyOpen="true" title="SPC Dashboard" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import processConfirmationOverview from './SpcOverview.vue';

  export default defineComponent({
    name: 'processConfirmationDashboard',
    components: {
      processConfirmationOverview,
    },
    props: {},
    setup() {
      return {};
    },
  });
</script>
